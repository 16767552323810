<template>
  <div class="error_page_wrapper">
    <div class="error_wrap">
      <div class="container text-center">
        <div class="error_heading text-center">
          <h3 class="headline font-danger">403</h3>
        </div>
        <h3 class="text-uppercase error-subtitle">Forbidden !</h3>
        <div class="col-md-8 offset-md-2 text-center">
          <p>권한이 없는 요청입니다..</p>
          <p>관리자에게 문의 하시기바랍니다.</p>
        </div>
        <div class="error_btn text-center">
          <a class="btn btn-danger" href="javascript:void(0)" @click="$router.push('/login')">Back Home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error403',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
