import Vue from 'vue'

// 결제유형/결제수단(M13)
Vue.filter('payKind', (value) => {
  switch (value) {
    case 1: return '신용카드'
    case 2: return '가상계좌'
    case 3: return '휴대폰'
    // case 7: return '간편결제' // 하나원큐
    default: return value
  }
})

// 결제상태(M12)
Vue.filter('payState', (value) => {
  switch (value) {
    case 1: return '입금대기'
    case 2: return '입금대기취소'
    case 3: return '결제완료'
    // case 4: return '구매완료대기'
    // case 5: return '결제취소/환불요청'
    // case 6: return '결제취소/환불완료'
    // case 7: return '입금대기취소'
    // case 8: return '반품/환불요청'
    // case 9: return '반품/환불완료'
    // case 10: return '교환요청'
    // case 11: return '교환완료'
    default: return value
  }
})

// 주문상태
Vue.filter('ordStatus', (value) => {
  switch (value) {
    case 2: return '입금대기'
    case 3: return '결제완료'
    case 7: return '입금대기취소'
    default: return value
  }
})

// 취소상태
Vue.filter('cancelStatus', (value) => {
  switch (value) {
    case 1: return '취소접수'
    case 2: return '취소완료'
    case 3: return '취소불가'
    default: return value
  }
})

// 교환상태
Vue.filter('exchangeStatus', (value) => {
  switch (value) {
    case 11: return '교환접수'
    case 12: return '교환처리중[수거전]'
    case 13: return '교환처리중[수거완료]'
    case 14: return '교환처리중[배송중]'
    case 15: return '교환완료'
    case 16: return '교환불가'
    case 17: return '교환접수취소'
    default: return value
  }
})

// 반품상태
Vue.filter('returnStatus', (value) => {
  switch (value) {
    case 21: return '반품접수'
    case 22: return '반품처리중[수거전]'
    case 23: return '반품처리중[수거완료]'
    case 24: return '반품불가'
    case 25: return '반품완료'
    case 26: return '반품접수취소'
    default: return value
  }
})

// 환불상태
Vue.filter('refundStatus', (value) => {
  switch (value) {
    case 0: return ''
    case 1: return '환불전'
    case 2: return '환불완료'
    default: return value
  }
})

// 배송상태
Vue.filter('odvStatus', (value) => {
  switch (value) {
    case 0: return ''
    case 1: return '배송준비'
    case 2: return '배송중'
    case 3: return '배송완료'
    default: return value
  }
})

// 배송지연여부
Vue.filter('isDelay', (value) => {
  switch (value) {
    case 0: return ''
    case 1: return '배송지연'
    default: return value
  }
})

// 클레임구분
Vue.filter('claimTypeCode', (value) => {
  switch (value) {
    case 'cancel': return '취소'
    case 'exchange': return '교환'
    case 'change': return '교환'
    case 'return': return '반품'
    case 'refund': return '환불'
    default: return value
  }
})

Vue.filter('claimServerType', (value) => {
  switch (value) {
    case 'CANCEL': return '취소'
    case 'CHANGE': return '교환'
    case 'RETURN': return '반품'
    case 'REFUND': return '환불'
    default: return value
  }
})

Vue.filter('isSaleCode', (value) => {
  switch (value) {
    case -1: return '판매중지'
    case 1: return '판매중'
    default: return value
  }
})

// 전시상태구분
Vue.filter('isDisplay', (value) => {
  switch (value) {
    case 0: return '전시중'
    case 1: return '전시중지'
    default: return value
  }
})

export default {
  name: 'order-filter',
  data () {
    return {
    }
  }
}
