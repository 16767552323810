<template>
    <div class="main-card mb-3 card">
        <div class="card-header font-size-lg">컬렉션</div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="float-left">
                        <div class="row form-group">
                            <div class="col-12 m-2">
                                <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-150"><span class="required">*</span>타이틀</h5>
                                <select class="custom-select wd-200 mr-1" v-model="collectionIdx" :disabled="this.idx !== ''">
                                    <option value="">선택</option>
                                    <option v-for="(collect, selectIdx) in collectTypeList" :value="collect.idx" :key="selectIdx">{{ collect.collectionName }}</option>
                                </select>
                            </div>
                            <div class="col-12 m-2">
                                <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-150"><span class="required">*</span>PC 대표이미지</h5>
                                <upload-photo v-if="pcImg.filename === ''" branch="visual" @changed="changeImage($event, pcImg)" />
                                <img v-else :src="getImagePath(pcImg.path + '/' + pcImg.filename)" class="images" v-image-error v-viewer style="width: 120px;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <general-collection-table title="상세 이미지(PC)" :list.sync="collList1" :section-type="1" @reload="getDataList" />

            <general-collection-table title="상세 이미지(Mobile)" :list="collList2" :section-type="2" @reload="getDataList" />

        </div>
        <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
                <div class="float-right">
                    <button class="btn btn-success ml-1 wd-100" @click="onSave">저장</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from '@/components/common/UploadPhoto'
import GeneralCollectionTable from "./GeneralCollectionTable";

export default {
    name: 'GeneralCollection',
    mixins: [ CommonMixin, InterfaceMixin ],
    components: {
        GeneralCollectionTable,
        UploadPhoto
    },
    data () {
        return {
            chkList: [],
            list: [],
            collectTypeList: [],
            title: '',

            visualIdx: '',

            collectionIdx: '',
            pcImg: { idx: '', filename: '', path: '' },
            collList1: [],
            collList2: [],
        }
    },
    validations: {
        title: {
          required
        },
        collectionIdx: {
            required
        },
        pcImg: {
            required,
            filename: {
                required
            },
        },
        collList1: {
            required,
            $each: {
                image1: {
                    filename: { required }
                },
                image2: {
                    filename: { required }
                },
            }
        },
        collList2: {
            required,
            $each: {
                image1: {
                    filename: { required }
                },
                // image2: {
                //     filename: { required }
                // },
            }
        },
    },
    props: {
        itemType: {
            type: Number | String,
            default: 0
        },
        idx: {
            type: Number | String,
            default: ''
        }
    },
    computed: {
        chkAll: {
            get () {
                return this.list.length ? this.chkList.length === this.list.length : false
            },
            set (value) {
                let selected = []

                if (value) {
                    this.list.map(item => {
                        selected.push(item.idx)
                    })
                }
                this.chkList = selected
            }
        }
    },
    created () {
        this.getCollectionList()

        this.visualIdx = this.idx
        if (this.idx) {
            this.getDataList()
        }
    },
    mounted () {
    },
    methods: {
        getCollectionList() {
            this.$api.api.getCollectionMainList({isDisplay: ''}).then(resp => {
                if (resp.data.result) {
                    this.collectTypeList = resp.data.data
                } else {
                    console.log('getCollectionList() ERROR');
                }
            })
        },
        getDataList () {
            return this.$api.api.getMngVisualCommonTemplate(this.visualIdx).then(resp => {
              console.log('## detail::: ', resp.data.data)
                this.collectionIdx = resp.data.data.collectionIdx
                this.pcImg = resp.data.data.pcImg
                this.pcImg.path = ''
                this.collList1 = resp.data.data.collList.filter(f => { if (f.sectionType === 1) { f.image1.path = ''; f.image2.path = ''; return f } })
                this.collList2 = resp.data.data.collList.filter(f => { if (f.sectionType === 2) { f.image1.path = ''; return f } })
                this.title = this.collectTypeList.find(f => f.idx === this.collectionIdx).collectionName
                this.list = []
            }).catch(error => {
                console.log(error)
            })
        },
        // 이미지 업로드
        changeImage (file, thisObj) {
            thisObj.path = file.path
            thisObj.filename = file.name
            thisObj.width = file.w
            thisObj.height = file.h
        },
        _isInvalidateInputForm() {
            if (this.$v.$invalid) {
                if (!this.$v.collectionIdx.required) {
                    this.alert('타이틀을 선택해주세요.')
                    return false
                }
                if (!this.$v.pcImg.filename.required) {
                    this.alert('PC 대표 이미지를 입력해주세요.')
                    return false
                }
                for(let key in this.$v.collList1.$each.$iter) {
                    let tmp = this.$v.collList1.$each.$iter[key]
                    if (!tmp.image1.filename.required) {
                        this.alert('상세이미지(PC) 이미지1를 등록해주세요.')
                        return false
                    }
                    if (!tmp.image2.filename.required) {
                        this.alert('상세이미지(PC) 이미지2를 등록해주세요..')
                        return false
                    }
                }
                for(let key in this.$v.collList2.$each.$iter) {
                    let tmp = this.$v.collList2.$each.$iter[key]
                    if (!tmp.image1.filename.required) {
                        this.alert('상세이미지(Mobile) 이미지1를 등록해주세요.')
                        return false
                    }
                    // if (!tmp.image2.filename.required) {
                    //     this.alert('상세이미지(Mobile) 이미지2를 등록해주세요..')
                    //     return false
                    // }
                }
            }
            if (this.collList1.length <= 0) {
                this.alert('상세 이미지(PC)는 1개 이상 필수로 등록 되어야 합니다.')
                return false
            }
            if (this.collList2.length <= 0) {
                this.alert('상세 이미지(Mobile)는 1개 이상 필수로 등록 되어야 합니다.')
                return false
            }
            return true
        },
        onSave () {
            if (!this._isInvalidateInputForm()) {
                console.log('[MCKS] 프로모션 정보 등록 실패 입력 FORM 오류')
                return false
            }
            this.confirm('저장 하시겠습니까?', () => {
                let reqBody = {
                    idx: this.visualIdx,
                    itemType: this.itemType,
                    pcImg: { idx: this.pcImg.idx, filename: this.pcImg.filename },
                    collectionIdx: this.collectionIdx,
                    collList: []
                }
                this.collList1.map(col => {
                    col.image1 = {
                        idx: col.image1.idx,
                        filename: col.image1.filename
                    }
                    col.image2 = {
                        idx: col.image2.idx,
                        filename: col.image2.filename
                    }
                    reqBody.collList.push(col)
                })
                this.collList2.map(col => {
                    col.image1 = {
                        idx: col.image1.idx,
                        filename: col.image1.filename
                    }
                    col.image2 = {
                        idx: '',
                        filename: ''
                    }
                    reqBody.collList.push(col)
                })
                console.log('## reqBody::: ', reqBody)

                this.blockUI()
                this.$api.api.saveMngVisualCommonTemplate(reqBody).then(resp => {
                    if (resp.status === 200) {
                        this.visualIdx = resp.data.data
                        this.alert('저장 되었습니다.', () => {
                            this.$nextTick(() => {
                                this.$router.replace({path: '/home/general_add', query: {idx: this.visualIdx, nowTime: this.nowTime}})
                            })
                        })
                    } else {
                        this.alert('저장에 실패하였습니다')
                    }
                }).finally(() => {
                    this.unBlockUI()
                })
            })
        },
    },
}
</script>
