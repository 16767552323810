var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "비주얼관리",
            "nav-list": [
              "맥케이슨관리",
              "비주얼관리",
              "홈화면 노출 관리",
              "일반 영역"
            ]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("div", { staticClass: "main-card mb-3 card general-area" }, [
            _vm._m(0),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
                _c("div", { staticClass: "float-left" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title float-left mb-0 mt-2 mr-2 pr-2"
                    },
                    [_vm._v("템플릿 선택")]
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.curTab,
                          expression: "curTab"
                        }
                      ],
                      staticClass: "custom-select wd-200 mr-1",
                      attrs: { disabled: !!_vm.visualIdx },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.curTab = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                      _vm._l(_vm.tabList, function(tab, tabIdx) {
                        return _c(
                          "option",
                          { key: tabIdx, domProps: { value: tab.code } },
                          [_vm._v(_vm._s(tab.name))]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "float-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary mr-0 wd-100",
                      on: { click: _vm.goPrevPage }
                    },
                    [_vm._v("목록으로")]
                  )
                ])
              ])
            ])
          ]),
          _vm.curTab === 1
            ? [
                _c("general-promotion-common", {
                  on: { changed: _vm.changeBg }
                }),
                _c("general-promotion", {
                  attrs: {
                    "bg-list": _vm.bgList.filter(function(f) {
                      return f.idx !== ""
                    }),
                    "item-type": _vm.curTab,
                    idx: _vm.visualIdx
                  }
                })
              ]
            : _vm._e(),
          _vm.curTab === 2
            ? [
                _c("general-category", {
                  attrs: {
                    "item-type": _vm.curTab,
                    idx: _vm.visualIdx,
                    cat1: _vm.cat1Idx
                  }
                })
              ]
            : _vm._e(),
          _vm.curTab === 3
            ? [
                _c("general-product-expose", {
                  attrs: { "item-type": _vm.curTab, idx: _vm.visualIdx }
                })
              ]
            : _vm._e(),
          _vm.curTab === 4
            ? [
                _c("general-collection", {
                  attrs: { "item-type": _vm.curTab, idx: _vm.visualIdx }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header font-size-lg" }, [
      _c("span", {}, [_vm._v("일반영역 항목 등록")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }