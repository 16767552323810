<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th>번호</th>
              <th>송장번호</th>
              <th>상품별 주문번호</th>
              <th>상품명</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!innerProducts.length">
              <td class="text-center" colspan="4">선택된 상품이 존재하지 않습니다.</td>
            </tr>
            <tr v-for="(product, index) in innerProducts">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">
                <select type="select" class="custom-select wd-150 d-inline-block" v-model="product.companyCode">
                  <option value="">택배사</option>
                  <option v-for="(item, idx) in courierList" :value="item.companyCode" :key="idx">{{ item.companyName }}</option>
                </select>
                <input type="text" class="mx-input wd-200" placeholder="송장번호" v-model="product.deliveryNo" maxlength="20">
              </td>
              <td class="text-center">{{ product.ordNo }}_{{ product.opoIdx }}</td>
              <td class="text-left">{{ product.ordProduct }}/{{product.opoColName}}/{{product.opoSize}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100 mr-2" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100" @click="complete">적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'PaymentPopup',
  data () {
    return {
      isOpen: false,
      courierList: [],
      innerProducts: []
    }
  },
  validations: {
    innerProducts: {
      required,
      $each: {
        deliveryNo: {
          required, minLength: minLength(5), maxLength: maxLength(20)
        },
        companyCode: {
          required
        }
      }
    }
  },
  components: {
  },
  mixins: [
    CommonMixin,
    InterfaceMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  created () {
    this.getCourierList()
    this.products.map(product => {
      let temp = {
        ordNo: product.ordNo,
        opoIdx: product.opoIdx,
        ordProduct: product.ordProduct,
        opoColName: product.opoColName,
        opoSize: product.opoSize,
        companyCode: product.odvDeliCom == null? '' : product.odvDeliCom,
        deliveryNo: product.odvDeliNo
      }
      this.innerProducts.push(temp)
    })
  },
  mounted () {
  },
  methods: {
    onCancle () {
      this.$emit('close')
    },
    complete (result) {
      if (!this._isInvalidateInputForm()) {
         console.log('[MCKS] 송장번호 정보 등록/수정 실패 입력 FORM 오류')
         return
      }

      if(this.type == 'exchange') this.updateExBundleInvoice(result)
      if(this.type == 'delivery') this.updateDeliveryInvoice(result)
    },
    updateDeliveryInvoice(result) {
      this.confirm('송장번호를 변경 하시겠습니까?', () => {
        let params = {
          changeDeliveryNos: this.innerProducts.map(data => {
            return {
              odvDeliCom: data.companyCode,
              odvDeliNo: data.deliveryNo,
              opoIdx: data.opoIdx
            }
          })
        }
        this.$api.api.changeDeliveryNo(params).then(resp => {
          if (resp.data.result) {
            this.alert('송장번호가 등록/수정 되었습니다.')
            this.$emit('close', result)
          } else {
            this.alert(resp.data.errMsg)
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },
    updateExBundleInvoice() {
      this.confirm('송장번호를 변경 하시겠습니까?', () => {
        let params = {
          exChangeDeliveryNos: this.innerProducts.map(data => {
            return {
              cdvDeliCom: data.companyCode,
              cdvDeliNo: data.deliveryNo,
              cpoIdx: data.opoIdx
            }
          })
        }
        this.$api.api.changeExchangeNo(params).then(resp => {
          if (resp.data.result) {
            this.alert('송장번호가 등록/수정 되었습니다.')
            this.$emit('close', true)
          } else {
            this.alert(resp.data.errMsg)
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    getCourierList() {
      return this.$api.api.getCourierList().then(resp => {
        this.courierList = resp.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        for(let key in this.$v.innerProducts.$each.$iter) {
            let tmp = this.$v.innerProducts.$each.$iter[key];
            if (!tmp.companyCode.required) {
              this.alert('택배사를 선택해주세요.')
            }else if (!tmp.deliveryNo.required) {
              this.alert('송장번호를 입력해주세요.')
            }else if (!tmp.deliveryNo.minLength) {
              this.alert('송장번호는 최소 5자리 이상 입력해주세요.')
            }else if (!tmp.deliveryNo.maxLength) {
              this.alert('송장번호는 최대 20 자리 이상 입력할 수 없습니다.')
            }
        }
        return false
      }
      return true
    }
  }
}

</script>
