var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("공통")]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: { click: _vm.callAddrSearchPop }
              },
              [_vm._v("주소검색")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: { click: _vm.callRgbPop }
              },
              [_vm._v("색상선택")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h5", { staticClass: "card-title" }, [_vm._v("샘플")]),
              _vm._l(_vm.imageList, function(item, index) {
                return [
                  _c("img", {
                    directives: [{ name: "viewer", rawName: "v-viewer" }],
                    key: index,
                    staticClass: "images",
                    attrs: { src: item.src, width: "100" }
                  })
                ]
              })
            ],
            2
          )
        ])
      ]),
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("주문")]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callOrderDetailPop(10)
                  }
                }
              },
              [_vm._v("주문정보팝업_ordIdx")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callPaymentPop(10)
                  }
                }
              },
              [_vm._v("결제정보팝업_ordIdx")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callMemoPop(10, "123412341234")
                  }
                }
              },
              [_vm._v("메모팝업_ordIdx")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: { click: _vm.callRecipientPop }
              },
              [_vm._v("정보수정_receptien")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: { click: _vm.callProdSearchPop }
              },
              [_vm._v("상품검색")]
            ),
            _c("br"),
            _c("br"),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callClaimPop("cancel", 10)
                  }
                }
              },
              [_vm._v("취소신청")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callClaimPop("exchange", 10)
                  }
                }
              },
              [_vm._v("교환신청")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callClaimPop("return", 10)
                  }
                }
              },
              [_vm._v("반품신청")]
            ),
            _c("br"),
            _c("br"),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callClaimDetailPop("cancel", "10", "C10000")
                  }
                }
              },
              [_vm._v("취소정보")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callClaimDetailPop("exchange", "10", "E10000")
                  }
                }
              },
              [_vm._v("교환신청")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-150",
                on: {
                  click: function($event) {
                    return _vm.callClaimDetailPop("return", "10", "R10000")
                  }
                }
              },
              [_vm._v("반품신청")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-lg-6" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h5", { staticClass: "card-title" }, [_vm._v("에디터")]),
              _vm._v(" " + _vm._s(_vm.content) + " "),
              _c("html-editor", {
                attrs: { content: _vm.content },
                on: { "change-content": _vm.updateContent }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-page-title" }, [
      _c("div", { staticClass: "page-title-wrapper" }, [
        _c("div", { staticClass: "page-title-heading" }, [
          _c("div", { staticClass: "page-title-icon" }, [
            _c("i", { staticClass: "pe-7s-car icon-gradient bg-mean-fruit" })
          ]),
          _c("div", [
            _vm._v("Analytics Dashboard "),
            _c("div", { staticClass: "page-title-subheading" }, [
              _vm._v(
                "This is an example dashboard created using build-in elements and components."
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 col-xl-3" }, [
        _c("div", { staticClass: "card mb-3 widget-content" }, [
          _c("div", { staticClass: "widget-content-wrapper" }, [
            _c("div", { staticClass: "widget-content-left" }, [
              _c("div", { staticClass: "widget-heading" }, [
                _vm._v("등록매장현황")
              ]),
              _c("div", { staticClass: "widget-subheading" }, [
                _vm._v("Last year expenses")
              ])
            ]),
            _c("div", { staticClass: "widget-content-right" }, [
              _c("div", { staticClass: "widget-numbers text-warning" }, [
                _c("span", [_vm._v("1896")])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-6 col-xl-3" }, [
        _c("div", { staticClass: "card mb-3 widget-content" }, [
          _c("div", { staticClass: "widget-content-wrapper" }, [
            _c("div", { staticClass: "widget-content-left" }, [
              _c("div", { staticClass: "widget-heading" }, [
                _vm._v("주문현황")
              ]),
              _c("div", { staticClass: "widget-subheading" }, [
                _vm._v("Total Clients Profit")
              ])
            ]),
            _c("div", { staticClass: "widget-content-right" }, [
              _c("div", { staticClass: "widget-numbers text-warning" }, [
                _c("span", [_vm._v("$ 568")])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-6 col-xl-3" }, [
        _c("div", { staticClass: "card mb-3 widget-content" }, [
          _c("div", { staticClass: "widget-content-wrapper" }, [
            _c("div", { staticClass: "widget-content-left" }, [
              _c("div", { staticClass: "widget-heading" }, [
                _vm._v("상품현황")
              ]),
              _c("div", { staticClass: "widget-subheading" }, [
                _vm._v("People Interested")
              ])
            ]),
            _c("div", { staticClass: "widget-content-right" }, [
              _c("div", { staticClass: "widget-numbers text-warning" }, [
                _c("span", [_vm._v("46%")])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-6 col-xl-3" }, [
        _c("div", { staticClass: "card mb-3 widget-content" }, [
          _c("div", { staticClass: "widget-content-wrapper" }, [
            _c("div", { staticClass: "widget-content-left" }, [
              _c("div", { staticClass: "widget-heading" }, [
                _vm._v("주문현황")
              ]),
              _c("div", { staticClass: "widget-subheading" }, [
                _vm._v("Revenue streams")
              ])
            ]),
            _c("div", { staticClass: "widget-content-right" }, [
              _c("div", { staticClass: "widget-numbers text-warning" }, [
                _c("span", [_vm._v("$14M")])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }