import '@babel/polyfill'
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Plugins from './plugins'

import VueToast from 'vue-toast-notification'
import VueSimpleAlert from 'vue-simple-alert'
import Vuelidate from 'vuelidate'
import VueDaumPostcode from 'vue-daum-postcode'
import VueJsModal from 'vue-js-modal'
import VueTheMask from 'vue-the-mask'
import Vue2Editor from 'vue2-editor'
import VueLoading from 'vue-loading-overlay'
import VueViewer from 'v-viewer'

Vue.config.productionTip = false

Vue.use(Plugins)
Vue.use(VueToast, {
  // message      String    --            Message text/html (required)
  // type         String    success       One of success, info, warning, error, default
  // position     String    bottom-right  One of top, bottom, top-right, bottom-right,top-left, bottom-left
  // duration     Number    3000          Visibility duration in milliseconds
  // dismissible  Boolean   true          Allow user close by clicking
  // onClick      Function  --            Do something when user clicks
  // onClose      Function  --            Do something after toast gets dismissed
  // queue        Boolean   false         Wait for existing to close before showing new
  // pauseOnHover Boolean   true          Pause the timer when mouse on over a toast
  position: 'top-right'
})
Vue.use(VueSimpleAlert, {
  reverseButtons: true,
  customClass: {
    cancelButton: 'btn btn-secondary wd-100',
    confirmButton: 'btn btn-primary wd-100'
  },
  cancelButtonText: '취소',
  confirmButtonText: '확인'
})
Vue.use(Vuelidate)
Vue.use(VueDaumPostcode)
Vue.use(VueJsModal)
Vue.use(VueTheMask)
Vue.use(Vue2Editor)
Vue.use(VueLoading, { color: 'red' })
Vue.use(VueViewer)

import 'vue-toast-notification/dist/theme-default.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import './assets/css/main.css'
import './assets/css/custom.css'

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
