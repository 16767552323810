<template>
  <div class="app-sidebar sidebar-shadow">
<!--  <div class="app-sidebar sidebar-shadow bg-premium-dark sidebar-text-light">-->
    <div class="app-header__logo">
      <div class="logo-src"></div>
      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
          <span class="btn-icon-wrapper">
            <i class="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
    <div class="scrollbar-sidebar">
      <div class="app-sidebar__inner" style="overflow-y:scroll; height: calc(100vh);">
        <ul class="vertical-nav-menu">
          <li class="app-sidebar__heading">맥케이슨관리</li>
          <li :class="{ 'mm-active': menuLv1 === '01' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)"  @click="toggleOpenMenu('menu01')">
              <i class="metismenu-icon pe-7s-diamond" :class="{ 'font-weight-bolder': menuLv1 === '01' }"></i>기본정보관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu01')"></i>
            </a>
            <ul :class="isOpended('menu01')">
              <li @click="goMenuUrl('/myInfo')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '08' }"><i class="metismenu-icon"></i>내 정보 관리</a></li>
              <li @click="goMenuUrl('/admin/list')" v-if="isSuperAdmin"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '06' }"><i class="metismenu-icon"></i>관리자관리</a></li>
              <li @click="goMenuUrl('/store/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '01' }"><i class="metismenu-icon"></i>매장관리</a></li>
              <li @click="goMenuUrl('/fee/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '02' }"><i class="metismenu-icon"></i>배송비관리</a></li>
              <li @click="goMenuUrl('/sizeClass/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '03' }"><i class="metismenu-icon"></i>사이즈구분관리</a></li>
              <li @click="goMenuUrl('/size/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '04' }"><i class="metismenu-icon"></i>사이즈관리</a></li>
              <li @click="goMenuUrl('/color/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '05' }"><i class="metismenu-icon"></i>색상관리</a></li>
              <li @click="goMenuUrl('/brochure/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '06' }"><i class="metismenu-icon"></i>브로슈어관리</a></li>
              <li @click="goMenuUrl('/version/list')" v-if="isSuperAdmin"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '01' && menuLv2 === '07' }"><i class="metismenu-icon"></i>버전관리</a></li>
            </ul>
          </li>
          <!--
          <li :class="{ 'mm-active': menuLv1 === '02' }">
            <a href="javascript:void(0)"  @click="toggleOpenMenu('menu02')">
              <i class="metismenu-icon pe-7s-car" :class="{ 'font-weight-bolder': menuLv1 === '02' }"></i>전시관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu02')"></i>
            </a>
            <ul :class="isOpended('menu02')">
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '02' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>전시관리-1</a></li>
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '02' &&  menuLv2 === '02' }"><i class="metismenu-icon"></i>전시관리-2</a></li>
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '02' &&  menuLv2 === '03' }"><i class="metismenu-icon"></i>전시관리-3</a></li>
            </ul>
          </li>
          -->
          <li :class="{ 'mm-active': menuLv1 === '03' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu03')">
              <i class="metismenu-icon pe-7s-cart" :class="{ 'font-weight-bolder': menuLv1 === '03' }"></i>주문관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu03')"></i>
            </a>
            <ul :class="isOpended('menu03')">
              <li @click="goMenuUrl('/order/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>주문관리</a></li>
              <li @click="goMenuUrl('/delivery/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '02' }"><i class="metismenu-icon"></i>배송관리</a></li>
              <li @click="goMenuUrl('/cancel/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '03' }"><i class="metismenu-icon"></i>취소관리</a></li>
              <li @click="goMenuUrl('/exchange/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '04' }"><i class="metismenu-icon"></i>교환관리</a></li>
              <li @click="goMenuUrl('/return/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '05' }"><i class="metismenu-icon"></i>반품관리</a></li>
              <li @click="goMenuUrl('/refund/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '06' }"><i class="metismenu-icon"></i>환불관리</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '04' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu04')">
              <i class="metismenu-icon pe-7s-box2" :class="{ 'font-weight-bolder': menuLv1 === '04' }"></i>상품관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu04')"></i>
            </a>
            <ul :class="isOpended('menu04')">
              <li @click="goMenuUrl('/product/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '04' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>상품관리</a></li>
              <li @click="goMenuUrl('/displayProduct/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '04' &&  menuLv2 === '02' }"><i class="metismenu-icon"></i>상품전시관리</a></li>
              <li @click="goMenuUrl('/discountProduct/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '04' &&  menuLv2 === '03' }"><i class="metismenu-icon"></i>상품할인율관리</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '05' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu05')">
              <i class="metismenu-icon pe-7s-note"></i>게시판관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu05')"></i>
            </a>
            <ul :class="isOpended('menu05')">
              <li @click="goMenuUrl('/promotion/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '05' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>프로모션관리</a></li>
              <li @click="goMenuUrl('/faq/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '05' &&  menuLv2 === '02' }"><i class="metismenu-icon"></i>FAQ관리</a></li>
<!--              <li @click="goMenuUrl('/event/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '05' &&  menuLv2 === '03' }"><i class="metismenu-icon"></i>이벤트관리</a></li>-->
              <li @click="goMenuUrl('/prdQna/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '05' &&  menuLv2 === '04' }"><i class="metismenu-icon"></i>상품문의관리</a></li>
              <li @click="goMenuUrl('/purchaseReview/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '05' &&  menuLv2 === '05' }"><i class="metismenu-icon"></i>구매후기관리</a></li>
<!--              <li @click="goMenuUrl('/prohibit/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '05' &&  menuLv2 === '06' }"><i class="metismenu-icon"></i>금칙어관리</a></li>-->
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '06' }">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu06')" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
              <i class="metismenu-icon pe-7s-photo-gallery"></i>비주얼관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu06')"></i>
            </a>
            <ul :class="isOpended('menu06')">
              <li @click="goMenuUrl('/home/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '06' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>홈화면 노출 관리</a></li>
              <li @click="goMenuUrl('/display/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '06' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>기타 노출 관리</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '07' }" v-if="this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu07')">
              <i class="metismenu-icon pe-7s-users"></i>사내판매관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu07')"></i>
            </a>
            <ul :class="isOpended('menu07')">
              <li @click="goMenuUrl('/member/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '07' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>회원관리</a></li>
              <li @click="goMenuUrl('/history/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '07' &&  menuLv2 === '02' }"><i class="metismenu-icon"></i>판매내역관리</a></li>
              <li @click="goMenuUrl('/restriction/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '07' &&  menuLv2 === '03' }"><i class="metismenu-icon"></i>할인제한관리</a></li>
<!--              <li @click="goMenuUrl('/discountInHouse/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '07' &&  menuLv2 === '04' }"><i class="metismenu-icon"></i>사내판매할인율관리</a></li>-->
              <li @click="goMenuUrl('/discountInHouseCat/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '07' &&  menuLv2 === '05' }"><i class="metismenu-icon"></i>사내판매할인율관리</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '08' }" v-if="this.$store.getters.userData.level !== 3">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu08')">
              <i class="metismenu-icon pe-7s-users"></i>매장판매관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu08')"></i>
            </a>
            <ul :class="isOpended('menu08')">
              <li @click="goMenuUrl('/store/member/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '08' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>회원관리</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '09' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu09')">
              <i class="metismenu-icon pe-7s-users" :class="{ 'font-weight-bolder': menuLv1 === '09' }"></i>회원관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu09')"></i>
            </a>
            <ul :class="isOpended('menu09')">
              <li @click="goMenuUrl('/client/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '03' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>회원관리</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '10' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu10')">
              <i class="metismenu-icon pe-7s-note"></i>컬렉션관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu10')"></i>
            </a>
            <ul :class="isOpended('menu10')">
              <li @click="goMenuUrl('/collection/list')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '10' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>컬렉션관리</a></li>
            </ul>
          </li>
<!--          <li :class="{ 'mm-active': menuLv1 === '11' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">-->
<!--            <a href="javascript:void(0)" @click="toggleOpenMenu('menu11')">-->
<!--              <i class="metismenu-icon pe-7s-users"></i>블랙리스트관리-->
<!--              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu11')"></i>-->
<!--            </a>-->
<!--            <ul :class="isOpended('menu11')">-->
<!--              <li @click="goMenuUrl('/blacklist/list/')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '11' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>블랙리스트관리</a></li>-->
<!--            </ul>-->
<!--          </li>-->
          <li :class="{ 'mm-active': menuLv1 === '12' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu12')">
              <i class="metismenu-icon pe-7s-graph3"></i>통계관리
              <i class="metismenu-state-icon caret-lef" :class="getIconStyle('menu12')"></i>
            </a>
            <ul :class="isOpended('menu12')">
              <li @click="goMenuUrl('/statOrder/list/')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '12' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>주문통계</a></li>
            </ul>
            <ul :class="isOpended('menu12')">
              <li @click="goMenuUrl('/statSales/list/')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '12' &&  menuLv2 === '02' }"><i class="metismenu-icon"></i>매출통계</a></li>
            </ul>
          </li>

          <li :class="{ 'mm-active': menuLv1 === '13' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu13')">
              <i class="metismenu-icon pe-7s-users"></i>마일리지관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu13')"></i>
            </a>
            <ul :class="isOpended('menu13')">
              <li @click="goMenuUrl('/point/totalList/')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '13' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>마일리지관리</a></li>
            </ul>
          </li>

          <!--
          <li :class="{ 'mm-active': menuLv1 === '06' }">
            <a href="javascript:void(0)">
              <i class="metismenu-icon pe-7s-calculator"></i>포인트관리
              <i class="metismenu-state-icon caret-left" :class="[ menuLv1 === '06' ? 'pe-7s-angle-down' : 'pe-7s-angle-up' ]"></i>
            <li :class="{ 'mm-active': menuLv1 === '12' }" v-if="this.$store.getters.userData.level !== 3 && this.$store.getters.userData.level !== 4">
            <a href="javascript:void(0)" @click="toggleOpenMenu('menu12')">
              <i class="metismenu-icon pe-7s-users"></i>포인트관리
              <i class="metismenu-state-icon caret-left" :class="getIconStyle('menu12')"></i>
            </a>
            <ul>
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '06' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>포인트관리-1</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '07' }">
            <a href="javascript:void(0)">
              <i class="metismenu-icon pe-7s-graph3"></i>통계관리
              <i class="metismenu-state-icon caret-lef" :class="[ menuLv1 === '07' ? 'pe-7s-angle-down' : 'pe-7s-angle-up' ]"></i>
            </a>
            <ul>
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '07' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>통계관리-1</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '08' }">
            <a href="javascript:void(0)">
              <i class="metismenu-icon pe-7s-mute"></i>블랙리스트관리
              <i class="metismenu-state-icon caret-left" :class="[ menuLv1 === '08' ? 'pe-7s-angle-down' : 'pe-7s-angle-up' ]"></i>
            </a>
            <ul>
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '08' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>블랙리스트-1</a></li>
            </ul>
          </li>
          <li :class="{ 'mm-active': menuLv1 === '09' }">
            <a href="javascript:void(0)">
              <i class="metismenu-icon pe-7s-users"></i>회원관리
              <i class="metismenu-state-icon caret-left" :class="[ menuLv1 === '09' ? 'pe-7s-angle-down' : 'pe-7s-angle-up' ]"></i>
            </a>
            <ul>
              <li @click="$toast.warning('준비중입니다.')"><a href="javascript:void(0)" :class="{ 'mm-active': menuLv1 === '08' &&  menuLv2 === '01' }"><i class="metismenu-icon"></i>회원관리-1</a></li>
            </ul>
          </li>
          -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import {actionType} from '@/store/const/StoreType'
  import moment from 'moment'

  export default {
    name: 'SideMenu',
    data() {
      return {
        nowTime: '',
        menuLv1: '',
        menuLv2: '',
        openStatusList: {
          'menu01': false,
          'menu02': false,
          'menu03': false,
          'menu04': false,
          'menu05': false,
          'menu06': false,
          'menu07': false,
          'menu08': false,
          'menu09': false,
          'menu10': false,
          'menu11': false,
          'menu12': false,
          'menu13': false
        }
      }
    },
    components: {},
    computed: {
      getIconStyle() {
        return (step) => {
          if (this.openStatusList[step]) {
            return 'pe-7s-angle-up'
          } else {
            return 'pe-7s-angle-down'
          }
        }
      },
      isOpended() {
        return (step) => {
          if (this.openStatusList[step]) {
            return 'mm-show'
          } else {
            return 'mm-collapse'
          }
        }
      },
      isSuperAdmin() {
        return this.$store.getters.userData.level === 1
      }
    },
    mounted() {
      // let index = 0
      let timerId = setInterval(() => {
        // console.log('[USED_MARKET] 경매상품 째깍')
        this.nowTime = moment()
      }, 500)
      this.$once('hook:beforeDestroy', () => {
        setTimeout(() => {
          clearInterval(timerId)
        })
      })
    },
    methods: {
      goMenuUrl(path) {
        this.$router.push({path: path + '?nowTime=' + this.nowTime})
      },
      doLogout() {
        this.$store.dispatch(actionType.ACTION_LOGOUT).then(() => {
          this.alert('로그아웃 되었습니다.')
          this.$router.replace({path: '/'})
        }).catch((e) => {
          console.log(e)
          this.alert('로그아웃에 실패했습니다.')
        })
      },
      toggleOpenMenu(menuIdx) {
        this.openStatusList[menuIdx] = !this.openStatusList[menuIdx]
      }
    }
  }
</script>
