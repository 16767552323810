<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'금칙어관리'" :nav-list="['맥케이슨관리', '게시판관리', '금칙어관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title float-left" v-if="!isEdit">금칙어 등록</div>
            <div class="card-header-title float-left" v-if="isEdit">금칙어 수정</div>
          </div>
          <div class="card-body">
            <div class="row position-relative form-group">
              <label class="col-sm-2 col-form-label text-center"><span class="required">*</span>금칙어</label>
              <div class="col-sm-8">
                <input type="text" class="form-control d-inline-block w-75" v-model="reqPrhbInfo.prhbWord" placeholder="금칙어를 입력해주세요." maxlength="30" />
                <label class="ml-2">30자 이내 ({{ reqPrhbInfo.prhbWord.length }}/30)</label>
              </div>
            </div>
            <div class="row position-relative form-group">
              <label class="col-sm-2 col-form-label text-center"><span class="required">*</span>적용메뉴</label>
              <div class="col-sm-10 pt-2">
                <div class="custom-checkbox custom-control custom-control-inline">
                  <input type="checkbox" class="custom-control-input" @click="chkAllMenu" v-model="chkAll" id="prhbMenu_all"><label class="custom-control-label" for="prhbMenu_all">전체</label>
                </div>
                <div class="custom-checkbox custom-control custom-control-inline">
                  <input type="checkbox" class="custom-control-input" :checked="reqPrhbInfo.prhbSrch" v-model="reqPrhbInfo.prhbSrch" id="prhbMenu_srch" @change="chkMenu"><label class="custom-control-label" for="prhbMenu_srch">검색</label>
                </div>
                <div class="custom-checkbox custom-control custom-control-inline">
                  <input type="checkbox" class="custom-control-input" :checked="reqPrhbInfo.prhbDscr" v-model="reqPrhbInfo.prhbDscr" id="prhbMenu_dscr" @change="chkMenu"><label class="custom-control-label" for="prhbMenu_dscr">상품설명</label>
                </div>
                <div class="custom-checkbox custom-control custom-control-inline">
                  <input type="checkbox" class="custom-control-input" :checked="reqPrhbInfo.prhbReview" v-model="reqPrhbInfo.prhbReview" id="prhbMenu_review" @change="chkMenu"><label class="custom-control-label" for="prhbMenu_review">구매후기</label>
                </div>
                <div class="custom-checkbox custom-control custom-control-inline">
                  <input type="checkbox" class="custom-control-input" :checked="reqPrhbInfo.prhbQna" v-model="reqPrhbInfo.prhbQna" id="prhbMenu_qna" @change="chkMenu"><label class="custom-control-label" for="prhbMenu_qna">상품문의</label>
                </div>
              </div>
            </div>
            <div class="row position-relative form-group">
              <label class="col-sm-2 col-form-label text-center"><span class="required">*</span>사용여부</label>
              <div class="col-sm-10 pt-2">
                <div class="custom-radio custom-control custom-control-inline">
                  <input type="radio" class="custom-control-input" value=0 v-model="reqPrhbInfo.prhbIsUse" id="prhbIsUse_yes"><label class="custom-control-label" for="prhbIsUse_yes">사용</label>
                </div>
                <div class="custom-radio custom-control custom-control-inline">
                  <input type="radio" class="custom-control-input" value=1 v-model="reqPrhbInfo.prhbIsUse" id="prhbIsUse_no"><label class="custom-control-label" for="prhbIsUse_no">사용안함</label>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="text-center m-3">
            <button class="btn btn-lg btn-secondary ml-3 mb-3 wd-100" @click="onList">목록</button>
            <button class="btn btn-lg btn-primary ml-3 mb-3 wd-100" @click="onCreate" v-show="!isEdit">저장</button>
            <button class="btn btn-lg btn-primary ml-3 mb-3 wd-100" @click="onUpdate" v-show="isEdit">수정</button>
          </div>
          <div class="main-card card" v-show="isEdit">
            <div class="card-body">
              <div class="position-relative row form-group">
                <label class="col-sm-1 col-form-label">등록일</label>
                <div class="col-sm-5"><input type="text" class="form-control" v-model="reqPrhbInfo.rgdt" disabled></div>
                <label class="col-sm-1 col-form-label">작성자</label>
                <div class="col-sm-5"><input type="text" class="form-control" v-model="reqPrhbInfo.rgAdmin" disabled></div>
              </div>
              <div class="position-relative row form-group mb-0">
                <label class="col-sm-1 col-form-label">수정일</label>
                <div class="col-sm-5"><input type="text" class="form-control" v-model="reqPrhbInfo.updt" disabled></div>
                <label class="col-sm-1 col-form-label">수정자</label>
                <div class="col-sm-5"><input type="text" class="form-control" v-model="reqPrhbInfo.upAdmin" disabled></div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import {CommonMixin} from "@/components/mixins/CommonMixin";
import CodeMixin from "@/mixins/code-mixin";

export default {
  name: "ProhibitRegister",
  components: { NavigationBar },
  mixins: [ InterfaceMixin, CommonMixin, CodeMixin ],
  data () {
    return {
      datas: [],
      reqPrhbInfo: {
        prhbIdx: '',
        prhbWord: '',
        prhbSrch: '',
        prhbDscr: '',
        prhbReview: '',
        prhbQna: '',
        prhbIsUse: '',
        rgdt: '',
        updt: '',
        rgAdmin: '',
        upAdmin: '',
      },
      chkAll: false,
      detailData: {},
      isEdit: false,
    }
  },
  created() {
    console.log("prhbIdx >>> " + this.$route.query.prhbIdx)
    let prhbIdx = this.$route.query.prhbIdx
    this.init()
    if (prhbIdx) {
      this.isEdit = true
      this.reqPrhbInfo.prhbIdx = prhbIdx
      this.getDetail()
    }
  },
  mounted() {
    console.log("prhbIdx >>> " + this.$route.query.prhbIdx)
  },
  methods: {
    init () {
      if (this.$route.query.prhbIdx) {
        this.detailData.prhbIdx = this.$route.query.prhbIdx
        this.getDetail()
      }
    },
    chkAllMenu () {
      if (!this.chkAll) {
        this.reqPrhbInfo.prhbSrch = true;
        this.reqPrhbInfo.prhbDscr = true;
        this.reqPrhbInfo.prhbReview = true;
        this.reqPrhbInfo.prhbQna = true;
      } else {
        this.reqPrhbInfo.prhbSrch = false;
        this.reqPrhbInfo.prhbDscr = false;
        this.reqPrhbInfo.prhbReview = false;
        this.reqPrhbInfo.prhbQna = false;
      }
    },
    chkMenu() {
      if (!this.reqPrhbInfo.prhbSrch || !this.reqPrhbInfo.prhbDscr || !this.reqPrhbInfo.prhbReview || !this.reqPrhbInfo.prhbQna) {
        this.chkAll = false;
      } else if (this.reqPrhbInfo.prhbSrch && this.reqPrhbInfo.prhbDscr && this.reqPrhbInfo.prhbReview && this.reqPrhbInfo.prhbQna) {
        this.chkAll = true;
      }
    },
    getDetail () {
      this.$api.api.getProhibitDetail({prhbIdx: this.$route.query.prhbIdx}).then(resp => {
        let res = resp.data
        if (res.result) {
          this.detailData = res.data

          this.reqPrhbInfo.prhbIdx = this.detailData.prhbIdx
          this.reqPrhbInfo.prhbWord = this.detailData.prhbWord
          this.reqPrhbInfo.prhbSrch = this.detailData.prhbSrch === 0 ? true : false;
          this.reqPrhbInfo.prhbDscr = this.detailData.prhbDscr === 0 ? true : false;
          this.reqPrhbInfo.prhbReview = this.detailData.prhbReview === 0 ? true : false;
          this.reqPrhbInfo.prhbQna = this.detailData.prhbQna === 0 ? true : false;
          this.reqPrhbInfo.prhbIsUse = this.detailData.prhbIsUse
          this.reqPrhbInfo.rgdt = this.detailData.rgdt
          this.reqPrhbInfo.updt = this.detailData.updt
          this.reqPrhbInfo.rgAdmin = this.detailData.rgAdmin
          this.reqPrhbInfo.upAdmin = this.detailData.upAdmin

          console.log("detailData >> " + JSON.stringify(this.detailData))
          console.log("reqPrhbInfo >> " + JSON.stringify(this.reqPrhbInfo))
        }
      })
    },
    onList() {
      this.$router.push({name: 'ProhibitList'})
    },
    onCreate () {
      if (!this.checkValidation()) {
        console.log("금칙어 등록 :: 입력값 오류")
        return
      }
      this.confirm("저장 하시겠습니까?", () => {
        let params = {
          prhbWord: this.reqPrhbInfo.prhbWord,
          prhbSrch: this.reqPrhbInfo.prhbSrch ? 0 : 1,
          prhbDscr: this.reqPrhbInfo.prhbDscr ? 0 : 1,
          prhbReview: this.reqPrhbInfo.prhbReview ? 0 : 1,
          prhbQna: this.reqPrhbInfo.prhbQna ? 0 : 1,
          prhbIsUse: this.reqPrhbInfo.prhbIsUse
        }
        return this.$api.api.regiProhibit(params).then(resp => {
          if (resp.status === 409) {
            this.alert("이미 등록된 금칙어입니다.")
            return false
          } else if (resp.status === 200) {
            this.alert("저장 되었습니다.")
            this.$router.go(-1)
          } else {
            this.alert("저장에 실패 하였습니다.")
          }
        })
      })
    },
    onUpdate() {
      if (!this.reqPrhbInfo.prhbIdx) {
        this.alert("금칙어 IDX를 확인해주세요.")
      }
      if (!this.checkValidation()) {
        console.log("금칙어 수정 :: 입력값 오류")
        return
      }
      this.confirm("수정 하시겠습니까?", () => {
        let params = {
          prhbIdx: this.reqPrhbInfo.prhbIdx,
          prhbWord: this.reqPrhbInfo.prhbWord,
          prhbSrch: this.reqPrhbInfo.prhbSrch ? 0 : 1,
          prhbDscr: this.reqPrhbInfo.prhbDscr ? 0 : 1,
          prhbReview: this.reqPrhbInfo.prhbReview ? 0 : 1,
          prhbQna: this.reqPrhbInfo.prhbQna ? 0 : 1,
          prhbIsUse: this.reqPrhbInfo.prhbIsUse
        }
        this.$api.api.updateProhibit(params).then(resp => {
          if (resp.status === 409) {
            this.alert("이미 등록된 금칙어입니다.")
            return false
          } else if (resp.data.result) {
            this.alert("수정 되었습니다.")
            this.$router.go(-1)
          } else {
            this.alert("수정에 실패 하였습니다.")
          }
        })
      })
    },
    checkValidation() {
      if(!this.reqPrhbInfo.prhbWord) {
        this.alert("금칙어를 입력 해주세요.")
        return false;
      }
      if (this.reqPrhbInfo.prhbWord.length > 30) {
        this.alert("금칙어는 30자를 초과하여 입력할 수 없습니다.")
        return false;
      }
      if(!this.reqPrhbInfo.prhbSrch && !this.reqPrhbInfo.prhbDscr && !this.reqPrhbInfo.prhbReview && !this.reqPrhbInfo.prhbQna) {
        this.alert("적용할 메뉴를 1개 이상 선택 해주세요.");
        return false;
      }
      if(this.reqPrhbInfo.prhbIsUse === '') {
        this.alert("사용 여부를 선택 해주세요.");
        return false;
      }
      return true;
    }
  },
}
</script>

<style scoped>

</style>
