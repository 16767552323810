<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'관리자관리'" :nav-list="['맥케이슨관리', '기본정보관리', '내 정보 관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
<!--        <div class="alert alert-warning fade show" role="alert" v-if="admin.level === 1">-->
<!--          <p class="mb-0">superadmin 계정은 수정이 불가능합니다.</p>-->
<!--        </div>-->

        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">내 정보 관리</h5>
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15"><span class="required"> * </span>ID</th>
                  <td class="w-35">{{ admin.userId }}</td>
                  <th class="w-15"><span class="required"> * </span>이름</th>
                  <td class="w-35">{{ admin.userName }}</td>
                </tr>
                <tr>
                  <th class="w-15"><span class="required"> * </span>휴대폰번호</th>
                  <td class="w-35">{{ admin.userPhone }}</td>
                  <th class="w-15"><span class="required"> * </span>이메일</th>
                  <td class="w-35">{{ admin.userEmail }}</td>
                </tr>
                <tr>
                  <th class="w-15"><span class="required"> * </span>비밀번호</th>
                  <td class="w-75" colspan="3">
                    <div class="form-group d-inline-block">
                      <label>비밀번호</label>
                      <input type="password" class="form-control wd-300" v-model="plainPassword" placeholder="">
                    </div>
                    <div class="form-group d-inline-block ml-2">
                      <label>비밀번호 확인</label>
                      <input type="password" class="form-control wd-300" v-model="repeatPassword" placeholder="">
                    </div>
                    <label class="d-block"><span class="required"> * </span>숫자/대문자/소문자/특수문자 3가지 이상 조합 8자리 이상 15자리 이하</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-success wd-100 m-1" @click="onUpdate">수정</button>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength, sameAs, helpers } from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import NavigationBar from '@/page/components/NavigationBar'
import CryptoJS from 'crypto-js'
import { actionType } from '@/store/const/StoreType'
const alphabetRegExp = helpers.regex('alpha',  /^[A-Za-z0-9+]*$/)
const emailRegExp = helpers.regex('email', /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)

export default {
  name: 'OrderList',
  data () {
    return {
      search: '',
      admin: {
        idx: '',
        userId: '',
        userName: '',
        userPhone: '',
        userEmail: '',
        level: 2
      },
      plainPassword: '',
      repeatPassword: ''
    }
  },
  validations: {
    admin: {
      idx: { required },
      password: { required }
    },
    plainPassword: { required, minLength: minLength(8), maxLength: maxLength(16) },
    repeatPassword: { sameAsPassword: sameAs('plainPassword') }
  },
  components: {
    NavigationBar
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
  },
  created () {
    console.log('[MCKAYSON] AdminDetail::: created ', this.$store.getters.userData.idx)
    let idx = this.$store.getters.userData.idx
    this.admin.idx = idx
    this.getDetail()

    let search = this.$route.query.search
    this.search = search
  },
  mounted () {
  },
  methods: {
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (!this.isEdit || (this.isEdit && (this.plainPassword !== '' || this.repeatPassword !== ''))) {
          if (!this.$v.plainPassword.required) {
            this.alert('비밀번호를 입력해주세요.')
            return false
          }
          if (!this.$v.plainPassword.minLength) {
            this.alert('비밀번호는 최소 8자리 이상 입력해주세요.')
            return false
          }
          if (!this.$v.plainPassword.maxLength) {
            this.alert('비밀번호는 최대 16자리 이상 입력할 수 없습니다.')
            return false
          }
          // if (!this.checkPassword(this.plainPassword)) {
          //   this.alert('비밀번호는 영문, 숫자, 특수문자 조합으로 생성해 주세요.')
          //   return false
          // }
          let num = this.plainPassword.search(/[0-9]/g)
          let eng1 = this.plainPassword.search(/[a-z]/g)
          let eng2 = this.plainPassword.search(/[A-Z]/g)
          let spe = this.plainPassword.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi)
          if ((num < 0 && eng1 < 0) || (num < 0 && eng2 < 0) || (num < 0 && spe < 0) || (eng1 < 0 && eng2 < 0) || (eng1 < 0 && spe < 0) || (eng2 < 0 && spe < 0)) {
            this.alert('비밀번호는 숫자/대문자/소문자/특수문자 3가지이상 조합으로 생성해 주세요.')
            return false
          }
          if (!this.$v.repeatPassword.sameAsPassword) {
            this.alert('비밀번호가 일치하지 않습니다.')
            return false
          }
        }
      }
      return true
    },
    getDetail () {
      console.log('[MCKAYSON] getDetail::: ', this.admin.idx)
      let params = {
        idx: this.admin.idx
      }
      return this.$api.api.getAdminDetail(params).then(resp => {
        this.admin = resp.data.data
      }).catch(() => {
      })
    },
    onUpdate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 관리자 정보 수정 실패 입력 FORM 오류')
        return
      }
      this.confirm('수정 하시겠습니까?', () => {
        let params = {
          idx: this.admin.idx,
          id: this.admin.userId,
          userEmail: this.admin.userEmail,
          userName: this.admin.userName,
          userPhone: this.admin.userPhone,
          level: this.admin.level,
          password: this.repeatPassword === '' ? '' : CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(this.repeatPassword))
        }
        this.$api.api.reqAdminModification(params).then(resp => {
          if (resp.data.result) {
            this.alert('수정되었습니다.', () => {
              // this.$router.push({path: '/admin/list'})

              this.$store.dispatch(actionType.ACTION_LOGOUT).then(() => {
                this.$toast.info( this.$store.getters.userData.userName+ '님 로그아웃')
                this.$router.replace({ path: '/login' })
              }).catch((err) => {
                console.log(err)
                this.alert('로그아웃에 실패했습니다.')
              })


            })
          } else {
            this.alert('수정에 실패 했습니다.')
          }
        })
      })
    },
    onDelete () {
      this.confirm('삭제 하시겠습니까?', () => {
        let params = {
          idxList: []
        }
        params.idxList.push(this.admin.idx)
        this.$api.api.reqAdminDeletation(params).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.$router.push({path: '/admin/list'})
            })
          } else {
            this.alert('삭제에 실패 했습니다.')
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
