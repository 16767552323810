<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'할인제한관리'" :nav-list="['맥케이슨관리', '사내판매관리', '할인제한관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">진행상태</label>
                  <select class="form-control" v-model="search.srchRstStatus">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchRstStatusList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>
                  <th class="text-center">번호</th>
                  <th class="text-center">할인제한 진행기간</th>
                  <th class="text-center">제목</th>
                  <th class="text-center">진행상태</th>
                  <th class="text-center">등록일</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!itemList.length">
                  <td colspan="6" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="chkList" :value="data.nonIdx" :id="data.nonIdx" @click="select"/></td>
                  <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                  <td class="text-center">
                    <label>{{ dateFormat(data.nonBegin, 'YYYY-MM-DD') }} ~ {{ dateFormat(data.nonEnd, 'YYYY-MM-DD') }}</label>
                  </td>
                  <td class="text-center"><a href="javascript:void(0)" @click="goDetail(data.nonIdx)">{{ data.nonTitle }}</a></td>
                  <td class="text-center">{{ data.nonStatus === 1 ? '진행전' : data.nonStatus === 2 ? '진행중' : '종료' }}</td>
                  <td class="text-center">{{ dateFormat(data.regDate, 'YYYY-MM-DD') }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="itemList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from "@/page/components/Pagination"
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: "RestrictionList",
  data () {
    return {
      search: {
        srchRstStatus: '',
        srchPage: 1,
        srchPageCnt: 10
      },
      srchRstStatusList: [
        { value: 1, text: '진행전' },
        { value: 2, text: '진행중' },
        { value: 3, text: '종료' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      allSelected: false,
      totalCnt: 0,
      itemList: [],
      chkList: []
    }
  },
  components: {
    NavigationBar,
    Pagination,
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.itemList.length ? this.chkList.length === this.itemList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.itemList.map(item => {
            selected.push(item)
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {
  },
  mounted () {
    this.getItemList()
  },
  methods: {
    onReset () {
      this.search.srchRstStatus = ''
      this.search.srchPage = 1
      this.search.srchPageCnt = 10
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getItemList () {
      let param = {
        srchType: this.search.srchRstStatus,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      return this.$api.api.getDiscountRestrictionList(param).then(resp => {
        this.totalCnt = resp.data.total
        this.itemList = resp.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail (nonIdx) {
      this.$router.push({path: '/restriction/modify', query: {restrictionIdx: nonIdx}})
    },
    onCreate () {
      this.$router.push({path: '/restriction/modify'})
    },
    onDelete () {
      console.log('this.chkList ::: ', this.chkList)
      if (this.chkList.length === 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }
      this.confirm('삭제 하시겠습니까?', () => {
        let body = {
          nonIdxes: this.chkList
        }
        this.$api.api.delDiscountRestriction(body).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.onReset()
              this.getItemList()
              this.allSelected = false
            })
          } else {
            this.alert('삭제 실패')
            this.onReset()
            this.getItemList()
            this.allSelected = false
          }
        })
      })
    },
    checkedAll: function () {
      this.chkList = []
      if (!this.allSelected) {
        for (var i = 0; i < this.itemList.length; i++) {
          this.chkList.push(this.itemList[i].nonIdx)
        }
      }
    },
    select: function () {
      this.allSelected = false
    },
    setStartDate (value) {
      this.search.srchDateStart = value
    },
    setEndDate (value) {
      this.search.srchDateEnd = value
    }
  }
}
</script>

<style scoped>

</style>
