<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'FAQ관리'" :nav-list="['맥케이슨관리', '게시판관리', 'FAQ관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">FAQ 정보</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>카테고리</label>
              <div class="col-sm-10">
                <select class="form-control w-15 d-inline" v-model="request.category">
                  <option v-for="(item,index) in catTypeList" :value="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>Q</label>
              <div class="col-sm-10">
                <textarea class="form-control" v-model="request.question"></textarea>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>A</label>
              <div class="col-sm-10">
                <textarea class="form-control" v-model="request.answer"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="request.faqIdx > 0 " @click="onUpdate">수정</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="request.faqIdx == 0" @click="onCreate">등록</button>
      </div>
    </div>

    <!--    <div class="row">-->
    <!--      <div class="col-lg-12">-->
    <!--        <div class="main-card mb-3 card">-->
    <!--          <div class="card-body">-->
    <!--            <table class="mb-0 table table-detail">-->
    <!--              <tbody>-->
    <!--                <tr>-->
    <!--                  <th class="w-15">등록일</th>-->
    <!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="dateFormat(prmInfo.regiPersonData.rgdt, 'YYYY-MM-DD HH:mm')"></td>-->
    <!--                  <th class="w-15">작성자</th>-->
    <!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="prmInfo.regiPersonData.rgAdmId"></td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="w-15">수정일</th>-->
    <!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="dateFormat(prmInfo.regiPersonData.updt, 'YYYY-MM-DD HH:mm')"></td>-->
    <!--                  <th class="w-15">수정자</th>-->
    <!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="prmInfo.regiPersonData.upAdmId"></td>-->
    <!--                </tr>-->
    <!--              </tbody>-->
    <!--            </table>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>
</template>

<script>
  import {required, minLength, maxLength} from 'vuelidate/lib/validators'
  import { CommonMixin } from '@/components/mixins/CommonMixin'
  import NavigationBar from '@/page/components/NavigationBar'
  import InterfaceMixin from '@/mixins/userInterface-mixin'

  import moment from 'moment'
  import CodeMixin from "@/mixins/code-mixin";

  export default {
    name: "FaqRegistration",
    data() {
      return {
        catTypeList : [
          {text : "선택", value : 0},
          {text : "결제/주문", value : 1},
          {text : "배송", value : 2},
          {text : "취소/교환/반품", value : 3},
          {text : "A/S", value : 4},
          {text : "마일리지", value : 6},
          {text : "기타", value : 5},
        ],
        request: {
          faqIdx: 0,
          question: '',
          answer: '',
          category: 0,
        },

        resultData: {},
      }
    },
    components: {
      NavigationBar,
    },
    mixins: [
      CommonMixin,
      InterfaceMixin,
      CodeMixin
    ],
    computed: {},
    created() {
      this.init()
    },
    mounted() {
    },
    methods: {
      init() {
        if(this.$route.params.faqIdx){
          this.request.faqIdx = this.$route.params.faqIdx
          this.onSearch()
        }
      },
      onSearch() {
        this.$api.api.getFaqDetail({faqIdx : this.request.faqIdx }).then(res =>{
          let resData = res.data
          if(resData.result){
            this.resultData = resData.data
            this.request.faqIdx = this.resultData.faqIdx
            this.request.category = this.resultData.category
            this.request.question = this.resultData.question
            this.request.answer = this.resultData.answer
          }
        })
      },
      onList() {
        this.$router.push({name: 'FaqList'})
      },
      onCreate() {
        if(!this.checkValid()){
          console.log("[faq regi fail!!]")
          return
        }
        this.$api.api.addFaq(this.request).then(res => {
          if (res.data.result) {
            this.alert("FAQ가 등록 되었습니다.")
            this.$router.go(-1)
          } else {
            this.alert("등록에 실패했습니다.")
          }
        })
      },
      onUpdate() {
        if(!this.request.faqIdx){
          this.alert("faqIdx를 확인해주세요.")
          return
        }
        if(!this.checkValid()){
          console.log("[faq regi fail!!]")
          return
        }
        this.$api.api.updateFaq(this.request).then(res =>{
          let resData = res.data
          if(resData.result){
            this.alert("FAQ가 수정되었습니다.")
            this.$router.go(-1)
          }
        })
      },

      checkValid(){
        if(!this.request.category){
          this.alert("카테고리를 선택해주세요.")
          return false
        }
        if(!this.request.question){
          this.alert("질문을 입력해주세요.")
          return false
        }
        if(!this.request.answer){
          this.alert("답변을 입력해주세요.")
          return false
        }
        return true
      }
    }
  }
</script>

<style scoped>

</style>
