<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'사이즈관리'" :nav-list="['맥케이슨관리', '기본정보관리', '사이즈관리']" />
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">사이즈 등록</div>
          <div class="card-body">

            <div class="row">
              <div class="col-md-2">
                <div class="card-title"><span class="required">*</span>구분 선택</div>
                <div class="form-group">
                  <select class="mb-2 form-control" v-model="form.sizeClaIdx" :disabled="isUpdate">
                    <option value=0>구분을 선택해주세요</option>
                    <option v-for="(item, idx) in sizeData" :value="item.sizeClaIdx" :key="idx">{{item.genCode | genCodeKeyMap(item.genCode)}}_{{ item.claName }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-title pl-3"><span class="required">*</span>사이즈 등록</div>
                <div class="col-sm-2 d-inline-block">
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[0]" name="inputBox[]" :value="inputBox[0]" @input="inputBox[0]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[1]" name="inputBox[]" :value="inputBox[1]" @input="inputBox[1]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[2]" name="inputBox[]" :value="inputBox[2]" @input="inputBox[2]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[3]" name="inputBox[]" :value="inputBox[3]" @input="inputBox[3]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                </div>
                <div class="col-sm-2 d-inline-block">
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[4]" name="inputBox[]" :value="inputBox[4]" @input="inputBox[4]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[5]" name="inputBox[]" :value="inputBox[5]" @input="inputBox[5]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[6]" name="inputBox[]" :value="inputBox[6]" @input="inputBox[6]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[7]" name="inputBox[]" :value="inputBox[7]" @input="inputBox[7]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                </div>
                <div class="col-sm-2 d-inline-block">
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[8]" name="inputBox[]" :value="inputBox[8]" @input="inputBox[8]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[9]" name="inputBox[]" :value="inputBox[9]" @input="inputBox[9]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[10]" name="inputBox[]" :value="inputBox[10]" @input="inputBox[10]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[11]" name="inputBox[]" :value="inputBox[11]" @input="inputBox[11]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                </div>
                <div class="col-sm-2 d-inline-block">
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[12]" name="inputBox[]" :value="inputBox[12]" @input="inputBox[12]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[13]" name="inputBox[]" :value="inputBox[13]" @input="inputBox[13]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[14]" name="inputBox[]" :value="inputBox[14]" @input="inputBox[14]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[15]" name="inputBox[]" :value="inputBox[15]" @input="inputBox[15]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                </div>
                <div class="col-sm-2 d-inline-block">
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[16]" name="inputBox[]" :value="inputBox[16]" @input="inputBox[16]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[17]" name="inputBox[]" :value="inputBox[17]" @input="inputBox[17]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[18]" name="inputBox[]" :value="inputBox[18]" @input="inputBox[18]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" :disabled="isUpdate && inputBox[19]" name="inputBox[]" :value="inputBox[19]" @input="inputBox[19]=$event.target.value" maxlength="10" v-number-and-english-input/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-secondary wd-200" @click="$router.push('/sizeClass/list')">사이즈 구분 등록</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate" v-show="!isUpdate">등록</button>
                <button class="btn btn-success wd-100" @click="onUpdate" v-show="isUpdate">수정</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">사이즈</div>
          <div class="table-responsive">
            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
              <thead>
              <tr>
                <!--                <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>-->
                <th class="text-center">구분코드</th>
                <th class="text-center">구분값</th>
                <th class="text-center">사이즈</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!datas.length">
                <td colspan="4" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in datas" :key="idx" v-else>
                <!--                  <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" :value="data.sizeClaIdx" :id="data.idx" v-model="selectedList" @click="select"></td>-->
                <th class="text-center" scope="row">{{ data.sizeClaIdx }}</th>
                <td class="text-center"><a href="javascript:void(0)" @click="dataSetting(data)">{{data.genCode | genCodeKeyMap(data.genCode)}}_{{data.claName}}</a></td>
                <td class="text-center">
                  <div v-for="(item, idx) in data.sizeList" class="d-inline-block" :key="idx">
                    <span class="float-left">{{item.size}}</span>
                    <span class="float-left" v-show="!(idx == data.sizeList.length-1)">,&nbsp;</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <!--                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body">
        <div class="position-relative row form-group">
          <label class="col-sm-1 col-form-label">수정일</label>
          <div class="col-sm-5"><input type="text" v-model="regiPersonData.updt" readonly class="form-control"></div>
          <label class="col-sm-1 col-form-label">수정자</label>
          <div class="col-sm-5"><input type="text" v-model="regiPersonData.upAdmId" readonly class="form-control"></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import NavigationBar from '@/page/components/NavigationBar'
  import CodeMixin from '@/mixins/code-mixin'
  import InterfaceMixin from '@/mixins/userInterface-mixin'

  export default {
    name: 'Size',
    data () {
      return {
        form: {
          sizeClaIdx: 0,
          sizeList: []
        },
        regiPersonData: {
          upAdmId: '',
          updt: ''
        },
        inputBox: [],
        inputBoxObj: [],
        datas: [],
        sizeData: [],
        isUpdate: false
      }
    },
    components: {
      NavigationBar
    },
    mixins: [
      CodeMixin, InterfaceMixin
    ],
    mounted () {
      this.getClassBoxList()
      this.getSizeList()
    },
    computed: {
    },
    methods: {
      getClassBoxList: function () {
        this.$api.api.getSelectBox().then(resp => {
          if (resp.data.result) {
            this.sizeData = resp.data.data
          } else {
            this.alert('사이즈구분 조회 실패')
          }
        })
      },
      getSizeList: function () {
        this.$api.api.getSizeList().then(resp => {
          if (resp.data.result) {
            this.datas = resp.data.data.data
            this.regiPersonData = resp.data.data.regiPersonData
          }
        })
      },
      _isInvalidateInputForm () {
        if (!this.form.sizeClaIdx) {
          this.alert('구분을 선택해주세요.')
          return false
        }
        if (!this.isUpdate && this.inputBox.length == 0) {
          this.alert('사이즈를 입력해주세요.')
          return false
        }

        if (this.isUpdate && this.inputBox.length == this.inputBoxObj.length) {
          this.alert('사이즈를 입력해주세요.')
          return false
        }
        return true
      },
      onCreate: function () {
        if (!this._isInvalidateInputForm()) {
          console.log('[MCKS] 색상 정보 등록 실패 입력 FORM 오류')
          return
        }

        this.confirm('등록 하시겠습니까?', () => {
          this.form.sizeList = this.inputBox
                  .filter(value => {
                    if (value) return true
                  })

          this.$api.api.registerSize(this.form).then(resp => {
            if (resp.data.result) {
              this.alert('등록되었습니다.', () => {
                this.inputBox = []
                this.getSizeList()
              })
            } else {
              this.alert(resp.data.errMsg)
            }
          })
        })
      },
      onDelete: function () {
        this.confirm('삭제 하시겠습니까?', () => {

          if (this.selectedList.length <= 0) {
            this.alert('삭제할 대상을 선택해주세요.')
            return
          }

          this.$api.api.deleteSize({sizeClaIdxList: this.selectedList}).then(resp => {
            if (resp.data.result) {
              this.alert('삭제되었습니다.', () => {
                this.$router.go(0)
              })
            } else {
              this.alert(resp.data.errMsg)
            }
          })
        })
      },
      dataSetting: function (data) {
        this.isUpdate = true
        this.form.sizeClaIdx = data.sizeClaIdx

        this.inputBox = []
        data.sizeList.map(el => {
          this.inputBoxObj.push(el)
          this.inputBox.push(el.size)
        })
      },
      onUpdate: function () {
        if (!this._isInvalidateInputForm()) {
          console.log('[MCKS] 색상 정보 등록 실패 입력 FORM 오류')
          return
        }

        this.confirm('수정 하시겠습니까?', () => {
          let sizeList = []
          for (let i=this.inputBoxObj.length; i<this.inputBox.length; i++) {
            sizeList.push(this.inputBox[i])
          }

          let reqParam = {
            sizeClaIdx: this.form.sizeClaIdx,
            sizeList: sizeList
          }

          // 기존꺼 제외하고 등록
          this.$api.api.registerSize(reqParam).then(resp => {
            if (resp.data.result) {
              this.alert('수정되었습니다.', () => {
                this.$router.go(0)
                // this.inputBox = []
                // this.getSizeList()
              })
            } else {
              this.alert(resp.data.errMsg)
            }
          })
        })
      },
    }

  }
</script>

<style scoped>

</style>
