import Vue from 'vue'
import Router from 'vue-router'
import routes from './routing'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const createRouter = () => new Router({
  mode: 'history',
  base: '/',
  routes: routes
})
const router = createRouter()
export default router

// 일단 로그인 체크 아래로 퉁침
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    if (!sessionStorage.getItem('userData') || !sessionStorage.getItem('accessToken')) {
      console.log('## 인증정보를 찾을 수 없어 로그인 페이지로 이동')
      next('/login')

    } else {

      let userData = JSON.parse(sessionStorage.getItem('userData'))

      // memberType 3 일때 사내판매관리만 접근 가능
      if (userData.level == 3 && to.name != 'MemberList' && to.name != 'HistoryList') {
        console.log('## 접근 권한이 없습니다.')
        next('/member/list')
      }

      if ((to.name === 'AdminList' || to.name === 'AdminDetail') && userData.level !== 1) {
        console.log('## 접근 권한이 없습니다.')
        next('/login')
      }
      next()
    }
  } else {
    next()
  }
})
