<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-header">
                    <h5 class="card-title mb-0 font-size-lg">메인영역</h5>
                    <div class="mt-auto ml-auto mb-auto mr-0 right position-relative">
                        <button class="btn btn-primary wd-100" @click="onAddList(mainInfoList)"><i class="fa fa-plus mr-1"></i>추가</button>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-detail">
                        <thead>
                        <tr>
                            <th class="text-center wd-75"><input type="checkbox" v-model="selectAll" /></th>
                            <th class="text-center wd-125">순서변경</th>
                            <th class="text-center wd-125">구분</th>
                            <th class="text-center w-auto" colspan="2">내용</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!mainInfoList.length">
                            <td colspan="4" class="text-center">내역이 존재하지 않습니다.</td>
                        </tr>
                        <tr v-for="(data, dataIdx) in mainInfoList">
                            <td class="text-center">
                                <input v-if="data.idx !== ''" type="checkbox" v-model="chkList" :value="data.idx" />
                                <button v-if="data.idx === ''" class="btn btn-shadow pt-0" @click="deleteRow(mainInfoList, dataIdx)" style="font-weight: bold; font-size: 1.5em;">&#x00D7;</button>
                            </td>
                            <td class="text-center">
                                <select class="form-control text-center" v-model="data.seq" @change="onChangeSeq($event, dataIdx)">
                                    <option v-for="i in mainInfoList.length" :value="i">{{i}}</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <div>
                                    <div class="custom-radio custom-control" v-for="(radio, radioIdx) in contentTypeList">
                                        <input type="radio" :id="`contentType_${dataIdx}_${radioIdx}`" :name="`customRadio_${dataIdx}`" class="custom-control-input" :value="radio.value" v-model="data.contentType">
                                        <label class="custom-control-label" :for="`contentType_${dataIdx}_${radioIdx}`">{{ radio.text }}</label>
                                    </div>
                                </div>
                            </td>
                            <td class="text-left">
                                <template v-if="data.contentType === 1">
                                    <ul style="display: inline-flex;" class="pl-2">
                                        <div class="mr-3 text-center">
                                            <li><label class="font-weight-bold">PC 이미지</label></li>
                                            <li>
                                                <upload-photo v-if="data.pcImgInfo.filename === '' || data.pcImgInfo.filename === null" branch="visual" @changed="changeImagePc($event, data.pcImgInfo)" />
                                                <img v-else :src="getImagePath(data.pcImgInfo.path ? data.pcImgInfo.path + '/' + data.pcImgInfo.filename : data.pcImgInfo.filename)" class="images" v-viewer v-image-error style="width: 120px; height: 120px;" />
                                            </li>
                                        </div>
                                        <div class="text-center">
                                            <li><label class="font-weight-bold">Mobile 이미지</label></li>
                                            <li>
                                                <upload-photo v-if="data.mobileImgInfo.filename === '' || data.mobileImgInfo.filename === null" branch="visual" @changed="changeImagePc($event, data.mobileImgInfo)" />
                                                <img v-else :src="getImagePath(data.mobileImgInfo.path ? data.mobileImgInfo.path + '/' + data.mobileImgInfo.filename : data.mobileImgInfo.filename)" class="images" v-viewer v-image-error style="width: 120px; height: 120px;" />
                                            </li>
                                        </div>
                                    </ul>
                                    <div style="display: grid;">
                                        <div class="custom-control custom-control-inline m-1 pl-0">
                                            <label class="col-form-label w-15 pr-2 font-weight-bold">문구</label>
                                            <input type="text" class="form-control w-75 mx-input" maxlength="20" placeholder="텍스트를 입력하세요.(선택)" v-model="data.etc">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="custom-radio custom-control custom-control-inline m-2" v-for="(radio, radioIdx) in linkTypeList">
                                            <input type="radio" :id="`linkType_${dataIdx}_${radioIdx}`" :name="`linkType_${dataIdx}`" class="custom-control-input" :value="radio.value" v-model="data.linkType">
                                            <label class="custom-control-label pr-2 font-weight-bold" :for="`linkType_${dataIdx}_${radioIdx}`">{{ radio.text }}</label>
                                        </div>
                                        <div>
                                            <input type="text" class="form-control w-75 mx-input" maxlength="100" placeholder="내부 URL을 입력하세요. ex) /product/detail/AAA333" v-model="data.linkUrl" v-if="data.linkType === 1">
                                            <input type="text" class="form-control w-75 mx-input" maxlength="100" placeholder="외부 URL을 입력하세요. ex) https://naver.com" v-model="data.linkUrl" v-else>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="data.contentType === 2">
                                    <div style="display: grid;">
                                        <div class="custom-control custom-control-inline m-1 pl-0">
                                            <label class="col-form-label w-25 pr-2 font-weight-bold">PC 동영상 URL</label>
                                            <input type="text" class="form-control w-100 mr-2" v-model="data.pcVideoUrl" placeholder="동영상(PC) URL을 입력해주세요." />
                                            <button type="button" class="btn btn-secondary wd-125" @click="callDisplayPop(data.pcVideoUrl)">미리보기</button>
                                        </div>
                                        <div class="custom-control custom-control-inline m-1 pl-0">
                                            <label class="col-form-label w-25 pr-2 font-weight-bold">Mobile 동영상 URL</label>
                                            <input type="text" class="form-control w-100 mr-2" v-model="data.mobileVideoUrl" placeholder="동영상(Mobile) URL을 입력해주세요." />
                                            <button type="button" class="btn btn-secondary wd-125" @click="callDisplayPop(data.mobileVideoUrl)">미리보기</button>
                                        </div>
                                    </div>
                                    <div class="custom-radio custom-control custom-control-inline m-2" v-for="(radio, radioIdx) in linkTypeList">
                                        <input type="radio" :id="`linkType_${dataIdx}_${radioIdx}`" :name="`linkType_${dataIdx}`" class="custom-control-input" :value="radio.value" v-model="data.linkType">
                                        <label class="custom-control-label pr-2 font-weight-bold" :for="`linkType_${dataIdx}_${radioIdx}`">{{ radio.text }}</label>
                                    </div>
                                    <div>
                                        <input type="text" class="form-control w-75 mx-input" maxlength="100" placeholder="내부 URL을 입력하세요. ex) /product/detail/AAA333" v-model="data.linkUrl" v-if="data.linkType === 1">
                                        <input type="text" class="form-control w-75 mx-input" maxlength="100" placeholder="외부 URL을 입력하세요. ex) https://naver.com" v-model="data.linkUrl" v-else>
                                    </div>
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="col-12 pr-0 pl-0">
                        <div class="float-left">
                            <button class="btn btn-danger ml-1 mr-1 wd-100" @click="onDelete">선택삭제</button>
                        </div>
                        <div class="float-right">
                            <button class="btn btn-success wd-100" @click="onSave">저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { CommonMixin } from '@/components/mixins/CommonMixin'
  import InterfaceMixin from "@/mixins/userInterface-mixin"
  import DisplayPop from "@/page/visual/display/components/DisplayPop";
  import UploadPhoto from '@/components/common/UploadPhoto'
  import {maxLength, minLength, required} from "vuelidate/lib/validators";

  export default {
    name: 'HomeTop',
    components: { DisplayPop, UploadPhoto },
    mixins: [ CommonMixin, InterfaceMixin ],
    data () {
      return {
        contentTypeList: [
          {value: 1, text: '이미지'},
          {value: 2, text: '동영상'},
        ],
        linkTypeList: [
          {value: 1, text: '내부 링크'},
          {value: 2, text: '외부 링크'},
        ],
        mainInfoList: [],
        chkList: []
      }
    },
    computed: {
      selectAll: {
        get: function () {
          return this.mainInfoList.length ? this.chkList.length === this.mainInfoList.length : false;
        },
        set: function (value) {
          const selected = [];
          if (value) {
            this.mainInfoList.map((data, idx) => {
              selected.push(data.idx);
            });
          }
          this.chkList = selected;
        },
      },
    },
    created () {
      this.getVisualMngMainList()
    },
    mounted () {
    },
    methods: {
      // 목록조회
      getVisualMngMainList() {
        return this.$api.api.getMngVisualMainInfo().then(resp => {
          if (resp.status === 200) {
            this.mainInfoList = []
            resp.data.data.mainInfoList.map(m => {
              if (m.contentType === 1) {
                m.pcImgInfo.path = ''
                m.mobileImgInfo.path = ''
              }
              if (!m.linkType) {
                m.linkType = 1
                m.linkUrl = ''
              }
              this.mainInfoList.push(m)
            })
          }
        }).catch(error => {
          console.log(error)
        })
      },
      checkUrl (strUrl) {
        let expUrl = /^http[s]?\:\/\//i
        return expUrl.test(strUrl)
      },
      // 저장
      onSave () {
        // if (!this._isInvalidateInputForm()) {
        //   console.log('[MCKS] 메인영역 등록 실패 입력 FORM 오류')
        //   return false
        // }
        this.confirm("저장 하시겠습니까?", () => {
          let params = this.mainInfoList.map(data => {
            // 이미지
            if (data.contentType === 1) {
              if (!data.pcImgInfo.filename) {
                this.alert("PC 이미지를 첨부 해주세요.")
                return false
              } else if (!data.mobileImgInfo.filename) {
                this.alert("Mobile 이미지를 첨부 해주세요.")
                return false
              }
            }
            // 비디오
            else if (data.contentType === 2) {
              if (!data.pcVideoUrl) {
                this.alert("PC URL을 입력 해주세요.")
                return false
              } else if (!data.mobileVideoUrl) {
                this.alert("Mobile URL을 입력 해주세요.")
                return false
              } else if (!this.checkUrl(data.pcVideoUrl)) {
                this.alert("[PC] 정확한 URL 형식으로 입력해주세요.")
                return false
              } else if (!this.checkUrl(data.mobileVideoUrl)) {
                this.alert("[Mobile] 정확한 URL 형식으로 입력해주세요.")
                return false
              }
            }

            if (data.linkType === 2 && data.linkUrl.trim() !== '') {
              if (!this.checkUrl(data.linkUrl)) {
                this.alert("외부링크 URL 형식이 올바르지 않습니다. 정확한 URL 형식으로 입력해주세요.")
                return false
              }
            }

            return {
              idx: data.idx,
              seq: data.seq,
              etc: data.etc,
              contentType: data.contentType,
              linkType: data.linkType,
              linkUrl: data.linkUrl,
              pcVideoUrl: data.pcVideoUrl,
              mobileVideoUrl: data.mobileVideoUrl,
              pcImgInfo: {
                idx: data.pcImgInfo.idx,
                filename: data.pcImgInfo.filename
              },
              mobileImgInfo: {
                idx: data.mobileImgInfo.idx,
                filename: data.mobileImgInfo.filename
              }
            }
          })
          this.blockUI()
          this.$api.api.saveMngVisualMain({dataList: params}).then(resp => {
            if (resp.data.result) {
              this.alert("저장 되었습니다.")
              this.getVisualMngMainList();
            } else {
              this.alert("저장에 실패하였습니다.")
            }
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            this.unBlockUI()
          })
        })
      },
      // 선택삭제
      onDelete() {
        if (this.chkList.length <= 0) {
          this.alert("삭제할 대상을 선택해 주세요.")
          return
        }
        let reqBody = {
          idxList: this.chkList
        }
        this.confirm("삭제 하시겠습니까?", () => {
          this.$api.api.delSelectedMngVisualMain(reqBody).then(resp => {
            if (resp.status === 200) {
              this.alert("삭제 되었습니다.");
              this.getVisualMngMainList();
            } else {
              this.alert("삭제에 실패하였습니다.")
            }
          })
        })
      },
      // 순서변경
      onChangeSeq (event, idx) {
        let temp = this.mainInfoList[idx]
        this.mainInfoList.splice(idx, 1)
        this.mainInfoList.splice(event.target.value - 1, 0, temp);

        this.mainInfoList.forEach((item, idx) => {
          item.seq = idx + 1
        })
      },
      // 행 삭제
      deleteRow(list, idx) {
        list.splice(idx, 1)
        this.reOrdering(list)
      },
      reOrdering(list) {
        list.map((item, i) => {
          item.seq = i + 1
        });
      },
      // 행 추가
      onAddList(list) {
        let pcImgInfo = { idx: '', filename: '', path: '' }
        let mobileImgInfo = { idx: '', filename: '', path: '' }
        let addObj = {
          idx: '',
          seq: list.length + 1,
          contentType: 1,
          etc: '',
          pcVideoUrl: '',
          mobileVideoUrl: '',
          pcImgInfo,
          mobileImgInfo
        }
        list.push(addObj);
      },
      // 동영상 미리보기 팝업
      callDisplayPop (displayUrl) {
        this.$modal.show(
          DisplayPop,
          { text: '미리보기', displayUrl: displayUrl },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
            }
          }
        )
      },
      // 이미지 업로드
      changeImagePc (file, thisObj) {
        thisObj.path = file.path
        thisObj.filename = file.name
        thisObj.width = file.w
        thisObj.height = file.h
      },
      changeImageMobile(file, list, idx) {
        let imgData = list[idx]
        imgData.mobileImgInfo.filename = file.name
      }
    },
  }
</script>

<style scoped>
    ul, li { list-style: none; padding-left: 0; }
</style>
