import http from '@/apis/http'
import apis from '@/apis/api'
import { mutationType, actionType } from '@/store/const/StoreType'

const Apis = apis(http)

const state = {
  authToken: '',
  userData: {
    user_idx: '',
    user_name: '',
    user_email: '',
    user_role_name: '',
    user_roles: []
  }
}

const mutations = {
  [ mutationType.SET_AUTH_TOKEN ] (state, accessToken) {
    console.log('[MCKAYSON]SET_AUTH_TOKEN::: ', accessToken)

    state.accessToken = accessToken
    sessionStorage.accessToken = accessToken
  },

  [ mutationType.RESET_AUTH_TOKEN ] (state) {
    sessionStorage.removeItem('accessToken')
  },

  [ mutationType.RESET_USER_DATA ] (state) {
    sessionStorage.removeItem('userData')
  },
  [ mutationType.SET_USER_DATA ]  (state, params) {
    if (params) {
      console.log('[MCKAYSON]SET_USER_DATA::: ', params)

      const jsonStr = JSON.stringify(params)
      state.userData = JSON.parse(jsonStr)
      sessionStorage.setItem('userData', jsonStr)
    }
  }
}

const actions = {
  [ actionType.SET_USER_DATA ] ({commit}, params) {
    commit(mutationType.SET_USER_DATA, params)
  },
  [ actionType.ACTION_LOGIN ] ({commit}, params) {
      let reqData = {
        id: params.username,
        password: params.password
      }
      return new Promise((resolve, reject) => {
        Apis.doLoginProcess(reqData).then(resp => {
          commit(mutationType.SET_USER_DATA, resp.data.info)
          commit(mutationType.SET_AUTH_TOKEN, resp.headers.authorization)
          resolve(resp)
        }).catch(error => {
          reject(error)
        })
      })
  },
  [ actionType.ACTION_LOGOUT ] ({commit}) {
    return new Promise((resolve, reject) => {
      console.log('[MCKAYSON] doLogout::: ')
      commit(mutationType.RESET_USER_DATA)
      commit(mutationType.RESET_AUTH_TOKEN)
      resolve()
    })
  }
}

const getters = {
  userData: state => state.userData
}

const enhanceAccessToken = () => {
  const {accessToken} = sessionStorage
  if (!accessToken) return
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}
export default {
  state,
  mutations,
  actions,
  getters,
  enhanceAccessToken
}
