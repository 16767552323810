<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ claimType | claimTypeCode }} {{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div style="max-height: 800px; overflow-y: scroll; width: 100%;">
          <div class="card-body">
            <h5 class="card-title">유입경로</h5>
            <table class="mb-0 table table-detail">
              <thead>
                <tr>
                  <th>유입경로</th>
                  <th>주문일(결제일)</th>
                  <th>주문번호</th>
                  <th>결제금액</th>
                  <th>결제수단</th>
                  <th>주문자</th>
                  <th>수령자</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{ order.admOrderDetailInfo.accessMethod }}</td>
                  <td class="text-center">{{ dateFormat(order.admOrderDetailInfo.ordRgdt, "YYYY-MM-DD HH:mm") }} ({{ dateFormat(order.admOrderDetailInfo.payDt, 'YYYY-MM-DD HH:mm') }})</td>
                  <td class="text-center">{{ order.admOrderDetailInfo.ordNo }}</td>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payAmount | comma }}원</td>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payKind | payKind }}</td>
                  <td class="text-center">{{ maskName(order.admOrderDetailOrder.userName) }}</td>
                  <td class="text-center">{{ maskName(order.admOrderDetailRecepient.recipient) }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(order.admOrderDetailInfo.ordIdx, order.admOrderDetailInfo.ordNo)">{{ order.admOrderDetailInfo.memoCnt | comma }}</a></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <div class="col-12 pr-0 pl-0 mb-2">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">상품정보</h5>
              </div>
              <div class="float-right">
                <button class="btn btn-secondary mb-2 mr-2 wd-150" @click="removeProduct()">선택 상품 삭제</button>
                <button class="btn btn-secondary mb-2 mr-2 wd-150" @click="addProduct()">{{ claimType | claimTypeCode }} 상품 추가</button>
              </div>
            </div>
            <div style="height: 282px; overflow-y: scroll; width: 100%;">
              <table class="mb-0 table table-detail">
                <thead>
                  <tr>
                    <th><input type="checkbox" v-model="chkAll" /></th>
                    <th>상품별 주문번호 <template v-if="claimType != 'cancel'"> <br/>/송장번호</template></th>
                    <th class="wd-100">상품번호</th>
                    <th class="w-50">상품명/옵션</th>
                    <th>수량</th>
                    <th v-if="claimType != 'exchange'">{{ claimType | claimTypeCode }}수량</th>
                    <th>판매가</th>
                    <th>상품금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, index) in itemList" :key="index">
                    <td class="text-center"><input type="checkbox" v-model="chkList" :value="product" /></td>
                    <td class="text-center wd-200">{{ `${product.ordNo}_${product.opoIdx}` }} <template v-if="claimType != 'cancel'"><br/>({{ product.deliveryComp }} / {{ product.deliveryNo }})</template></td>
                    <td class="text-center wd-150">{{ product.prdNo }}</td>
                    <td class="text-left">
                      <p class="mb-0">{{ product.ordProduct }}</p>
                        <span class="mb-0 bg-arielle-smile">옵션1 : {{ product.opoColName }}<template v-if="product.opoSize"> / 옵션2 : {{ product.opoSize }}</template></span>
                        <div v-if="claimType === 'exchange'">
                          <select class="custom-select wd-150 mr-1" v-model="product.opt1Data" @change="changeOpt1($event, product.prdIdx, index,privatePrdOptMapList[index],product )">
                            <option value="">선택해주세요.</option>
                            <template v-if="privatePrdOptMapList[index][product.prdIdx]">
                            <option v-for="(opt1, index) in privatePrdOptMapList[index][product.prdIdx].opt1List" :key="opt1.optIdx" :value="opt1">{{ opt1.colName }}</option>
                            </template>
                          </select>
                          <select class="custom-select wd-100 ml-1" v-model="product.opt2Data" @change="changeOpt2($event, product.prdIdx, index, privatePrdOptMapList[index])">
                            <option value="">선택해주세요.</option>
                            <template v-if="privatePrdOptMapList[index][product.prdIdx]">
                            <option v-for="opt2 in privatePrdOptMapList[index][product.prdIdx].opt2List" :value="opt2">{{ opt2.size }}</option>
                            </template>
                          </select>
                          <select class="custom-select wd-100 ml-1" v-model="product.exchangeCount">
                            <option :value="0" v-if="product.exchangeMax < 1">0</option>
                            <option v-for="count in product.exchangeMax" :value="count" v-if="product.exchangeMax > 0">{{ count }}</option>
                          </select>
                          <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="addExchange(index)">교환선택</button>
                          <p class="mb-0" v-for="(exchangeProduct, exchangeIdx) in product.exchangeProductList">
                            {{exchangeProduct.colName}} / {{exchangeProduct.size}} {{exchangeProduct.count}}개 <button class="btn ml-1 mr-1" :style="{padding: '0px 5px 0px 5px', color: 'red'}" @click="removeExchange(index, exchangeIdx)">X</button>
                           </p>
                        </div>
                    </td>
                    <td class="text-center wd-200">{{ product.quantity | comma }}</td>
                    <td class="text-center wd-200" v-if="claimType != 'exchange'">
                      <template v-if="product.quantityAvail > 0 && (product.count? product.count : product.count = 1)">
                        <select class="custom-select wd-75 ml-1" v-model="product.count" @change="refundInfo = null">
                            <option :value="product.quantityAvail" v-if="claimType == 'cancel' && order.isPartialCancel != 1">{{ product.quantityAvail }}</option>
                            <option v-for="count in product.quantityAvail" :value="count" v-if="claimType != 'cancel' || order.isPartialCancel == 1">{{ count }}</option>
                        </select>
                      </template>
                    </td>
                    <td class="text-center wd-200">{{ product.optPriceSale | comma }}</td>
                    <td class="text-center wd-200">{{ product.prdAmount | comma }}</td>
                  </tr>
                  <tr v-if="giftList.length > 0">
                    <td class="text-center" colspan="8" style="color:#B40404">
                      [사은품] {{ strGift }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-body mb-0 pb-0">
            <h5 class="card-title">{{ claimType | claimTypeCode }} 사유</h5>
            <table class="mb-0 table table-detail">
              <thead>
                <tr>
                  <th colspan="2">{{ claimType | claimTypeCode }} 사유</th>
                  <th>책임선택</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="w-25 text-center">
                    <select class="form-control" v-model="claimInfo.claimCode" @change="changeClaimCode">
                      <option value="0">선택해주세요</option>
                      <option v-for="item in getReasonList" :value="item.value" :key="item.value">{{ item.text }}</option>
                    </select>
                  </td>
                  <td class="w-50 text-center"><input type="text" class="form-control" placeholder="상세사유를 작성해주세요." :maxlength="60" v-model="claimInfo.reason"/></td>
                  <td class="w-25 text-center">
                    <div class="custom-radio custom-control custom-control-inline">
                        <input type="radio" id="ctype1" class="custom-control-input" :value="1" v-model="claimInfo.claimCharge" disabled/>
                        <label class="custom-control-label" for="ctype1" disabled>고객책임</label>
                    </div>
                    <div class="custom-radio custom-control custom-control-inline">
                        <input type="radio" id="ctype2" class="custom-control-input" :value="2" v-model="claimInfo.claimCharge" disabled/>
                        <label class="custom-control-label" for="ctype2" disabled>판매자책임</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body mb-0 pb-0" v-if="claimType === 'return'">
            <h5 class="card-title">주소 정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th class="wd-150" rowspan="2">회수 주소</th>
                <th class="wd-150">성명</th>
                <td class="text-left">
                  <label class="col-form-label mr-1" v-text="claim.returnAddress.name">홍길동</label>
                  <button type="button" class="btn btn-primary ml-1" @click="callRecipientPop(claim.returnAddress)">정보수정</button>
                </td>
                <th class="wd-150">연락처</th>
                <td class="wd-300">
                  <the-mask type="tel" :mask="['###-####-####', '###-###-####']" pattern="\d*" maxlength="13" class="form-control" v-model="claim.returnAddress.phone"></the-mask>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td>({{claim.returnAddress.postalCode}}) {{claim.returnAddress.addr1}} {{claim.returnAddress.addr2}}</td>
                <th>요청사항</th>
                <td><input type="text" class="form-control" v-model="claim.returnAddress.comment" :maxlength="20"/></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body" v-if="claimType === 'exchange'">
            <h5 class="card-title">주소 정보</h5>
            <table class="table table-detail">
              <tbody>
                <tr>
                  <th class="wd-150" rowspan="2">회수주소</th>
                  <th class="wd-150">성명</th>
                  <td class="text-left">
                    <label class="col-form-label mr-1" v-text="claim.returnAddress.name">홍길동</label>
                    <button type="button" class="btn btn-primary ml-1" @click="callRecipientPop(claim.returnAddress)">정보수정</button>
                  </td>
                  <th class="wd-150">연락처</th>
                  <td class="wd-300">
                    <the-mask type="tel" :mask="['###-####-####', '###-###-####']" pattern="\d*" maxlength="13" class="form-control" v-model="claim.returnAddress.phone"></the-mask>
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>({{claim.returnAddress.postalCode}}) {{claim.returnAddress.addr1}} {{claim.returnAddress.addr2}}</td>
                  <th>요청사항</th>
                  <td><input type="text" class="form-control" v-model="claim.returnAddress.comment" :maxlength="20"/></td>
                </tr>
              </tbody>
            </table>
            <table class="table table-detail">
              <tbody>
              <tr>
                <th class="wd-150" rowspan="2">교환<br/>배송 주소</th>
                <th class="wd-150">성명</th>
                <td class="text-left">
                  <label class="col-form-label mr-1" v-text="claim.exchangeAddress.name">홍길동</label>
                  <button type="button" class="btn btn-primary ml-1" @click="callRecipientPop(claim.exchangeAddress)">정보수정</button>
                </td>
                <th class="wd-150">연락처</th>
                <td class="wd-300">
                  <the-mask type="tel" :mask="['###-####-####', '###-###-####']" pattern="\d*" maxlength="13" class="form-control" v-model="claim.exchangeAddress.phone"></the-mask>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td>({{claim.exchangeAddress.postalCode}}) {{claim.exchangeAddress.addr1}} {{claim.exchangeAddress.addr2}}</td>
                <th>요청사항</th>
                <td><input type="text" class="form-control" v-model="claim.exchangeAddress.comment" :maxlength="20"/></td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body" v-if="claimType === 'return'">
              <h5 class="card-title">반품 배송비</h5>
              <table class="table table-detail">
                <tbody>
                  <tr>
                    <th class="wd-300">배송비</th>
                    <td class="text-center5"><input type="text" maxlength="10" class="form-control" v-model="claim.returnDeliFee"/></td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="card-body d-block text-center clearfix">
            <template v-if="claimType === 'cancel'">
              <button class="btn btn-primary wd-200" @click="calRefundAmount">환불예정금액 계산</button>
            </template>
            <template v-if="claimType === 'return'">
              <button class="btn btn-primary wd-200" @click="calRefundAmount">환불예정금액 계산</button>
            </template>
          </div>

          <div class="card-body pt-2" v-if="(claimType === 'cancel' || claimType === 'return') && refundInfo">
            <table class="mb-0 table table-detail">
              <thead>
              <tr>
                <th class="wd-150">환불 예정 수단</th>
                <td :class="{'wd-250' : refundInfo.isRefundAccountInput}" :colspan="{3 : !refundInfo.isRefundAccountInput}">{{refundInfo.refundMethodStr}}</td>
                <th class="wd-150" v-if="refundInfo.isRefundAccountInput">환불계좌정보</th>
                <td v-if="refundInfo.isRefundAccountInput">
                  <select class="form-control wd-150 d-inline" v-model="refund.bankName">
                    <option value="">은행선택</option>
                    <option v-for="bank in bankList" :value="bank.bankName" :key="bank.code">{{ bank.bankName }}</option>
                  </select>
                  <input type="text" class="form-control wd-250 d-inline" :maxlength="30" v-model="refund.accountNumber" placeholder="계좌번호"/>
                  <input type="text" class="form-control wd-150 d-inline" :maxlength="10" v-model="refund.accountHolder" placeholder="예금주명"/>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>상품 금액 합</th>
                <td colspan="3">{{ refundInfo.productAmount | comma }}</td>
              </tr>
              <tr>
                <th>기본배송비</th>
                <td colspan="3">{{ refundInfo.deliFee | comma }}</td>
              </tr>
              <tr>
                <th>도서산간 배송비</th>
                <td colspan="3">{{ refundInfo.deliAddfee | comma }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th>환불 예상 금액</th>
                <td colspan="3">{{ refundInfo.refundAmount | comma }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100 mr-2" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100" @click="complete">{{ claimType | claimTypeCode }} 신청</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import MemoPop from '@/page/order/components/MemoPop'
import RecipientPop from '@/page/order/components/RecipientPop'
import ProductAddPop from '@/page/order/components/ProductAddPop'
import {required, minLength, maxLength, between, numeric, minValue} from 'vuelidate/lib/validators'
import _ from "lodash";

export default {
  name: 'ClaimReqPopup',
  data () {
    return {
      isOpen: false,
      reasonList: [
        { value: 1, text: '단순변심'},
        { value: 2, text: '구매의사없음'},
        { value: 3, text: '주문을 잘못함'},
        { value: 4, text: '품절취소'}
      ],
      chkList: [],
      itemList: this.chkPrdList.filter(data => {
        switch(this.claimType) {
        case 'cancel' : {
            if(data.odvStatus == 0 || data.odvStatus == 1) {
                if(this.order.isPartialCancel != 1) data.count = data.quantityAvail
                return true
            }else return false
        }
        case 'exchange' :
        case 'return' : return data.odvStatus == 3
        }
      }),
      prdOptMap: {},
      bankList: [],

      recipient: {
        name: '홍길동',
        phone: '010-4336-1813',
        zonecode: '1234',
        address1: '서울시 강남구 테헤란로 98길',
        address2: '14층',
        comment: '오늘 꼭 배송해주세요.'
      },
      claim: {
        claim: {
            claimCharge: 0,
            claimCode: 0,
            claimType: this.getClaimType(),
            clmWorker: 'CS',
            ordIdx: this.order.ordIdx,
            reason: ''
        },
        products: [
            {count: 0, opoIdx: 0}
        ],
        refund: {
            accountHolder: '',
            accountNumber: '',
            bankName: ''
        },
        returnAddress: {
            clmIdx: 0,
            addr1: this.order.admOrderDetailRecepient.addr1,
            addr2: this.order.admOrderDetailRecepient.addr2,
            addrTitle: '사용안함',
            comment: this.order.admOrderDetailRecepient.comment,
            name: this.order.admOrderDetailRecepient.recipient,
            phone: this.order.admOrderDetailRecepient.recipientPhone,
            postalCode: this.order.admOrderDetailRecepient.postalCode
        },
        exchangeAddress: {
            clmIdx: 0,
            addr1: this.order.admOrderDetailRecepient.addr1,
            addr2: this.order.admOrderDetailRecepient.addr2,
            addrTitle: '사용안함',
            comment: this.order.admOrderDetailRecepient.comment,
            name: this.order.admOrderDetailRecepient.recipient,
            phone: this.order.admOrderDetailRecepient.recipientPhone,
            postalCode: this.order.admOrderDetailRecepient.postalCode
        },
        returnDeliFee: 0
      },
      claimInfo: {
        claimCharge: 0,
        claimCode: 0,
        claimType: this.getClaimType(),
        clmWorker: 'CS',
        ordIdx: this.order.ordIdx,
        reason: ''
      },
      refund: {
          accountHolder: '',
          accountNumber: '',
          bankName: ''
      },
      refundInfo: null,
      strGift: '',
      privatePrdOptMapList : []
    }
  },
  validations: {
    itemList: {
      required, minLength: minLength(1)
    },
    claimInfo: {
      claimCharge: {
        between: between(1,2)
      },
      claimCode: {
        minValue: minValue(1)
      }
    },
    refund: {
      accountHolder: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(10)
      },
      accountNumber: {
        required,
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(30)
      },
      bankName: {required}
    }
  },
  watch: {
    itemList: {
        deep: true,
        handler(itemList) {
            console.log('handler')
            console.log(itemList)
            /* itemList.forEach(data => {
                if(!data.opt1List) {
                    let param = {idx: data.prdIdx}
                    this.$api.api.getProductDetail(param).then(resp => {
                        data.opt1List = resp.data.data.optionList
                        data.opt2List = []
                        console.log(data)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }) */
        }
    },
    refund: {
        deep: true,
        handler(refund) {
            refund.accountNumber = refund.accountNumber.replace(/[^0-9]/g, '')
        }
    },
    'claim.returnDeliFee': function(newVal, oldVal) {
        this.claim.returnDeliFee = newVal.replace(/[^0-9]/g, '')
    }
  },
  components: {
    MemoPop,
    RecipientPop,
    ProductAddPop
  },
  mixins: [
    CommonMixin,
    InterfaceMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    claimType: {
      type: String,
      default: () => {
        return ''
      }
    },
    ordIdx: {
      type: String,
      default: (value) => {
        return ''
      }
    },
    order: {
        type: Object,
        default: () => {
            return {};
        }
    },
    chkPrdList: {
        type: Array,
        default: () => {
            return [];
        }
    },
    giftList: {
        type: Array,
        default: () => {
            return [];
        }
    }
  },
  computed: {
    chkAll: {
      get () {
        return this.itemList.length ? this.chkList.length === this.itemList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.itemList.map((prd, idx) => {
            selected.push(prd)
          })
        }
        this.chkList = selected
      }
    },
    getReasonList () {
      console.log(this.claimType)
      switch (this.claimType) {
        case 'cancel':
          return this.reasonList
        case 'exchange':
          return this.reasonList
        case 'return':
          return this.reasonList
      }
    }
  },
  async created () {
    //this.$toast.info(this.ordIdx)
  },
  mounted () {
    this.getBankList()
    if(this.claimType == 'exchange') this.getPrdOptMap()
    this.getReasonMngList()
    this.getEventData()
  },
  methods: {
    getEventData () {
      let prdCnt = 0
      let reverseGift = this.giftList.reverse()
      this.chkPrdList.forEach(data => {
        if (data.cat2Idx !== 14 && data.cat2Idx !== 19) {
          prdCnt += data.quantityAvail
        }
      })

      this.strGift = ''
      for (let i = 0; i < prdCnt; i++) {
        if (i === prdCnt -1) {
          this.strGift += reverseGift[i]
        } else {
          this.strGift += reverseGift[i] + ', '
        }
      }
    },
    onCancle () {
      this.$emit('close')
    },
    complete () {
      if(this.$v.itemList.$invalid) {
          this.alert(this.getClaimTypeStr() + '할 상품이 없습니다.')
          return false
      }
      if(this.$v.claimInfo.claimCode.$invalid) {
          this.alert(this.getClaimTypeStr() + ' 사유를 선택해주세요.')
          return false
      }
      if(this.claimType == 'exchange') {
        let products = []
        this.itemList.forEach(item => {
            if(Array.isArray(item.exchangeProductList) && item.exchangeProductList.length > 0) {
                products = products.concat(item.exchangeProductList.map(exprd => {
                    return {
                        chgOptIdx2: exprd.chgOptIdx2,
                        count: exprd.count,
                        opoIdx: exprd.opoIdx
                    }
                }))
            }
        })
        console.log(products)
        this.claim.products = products
      }else {
          if(this.order.admOrderDetailPayInfo.payKind != 1 && this.refundInfo == null) {
            this.alert('환불예정금액 계산을 해주세요.')
            return false
          }else if(this.refundInfo != null && this.refundInfo.isRefundAccountInput){
            if(this.$v.refund.bankName.$invalid) {
              this.alert('환불계좌정보에 은행을 선택해주세요.')
              return false
            }
            if(this.$v.refund.accountNumber.$invalid) {
              this.alert('환불계좌정보에 올바른 계좌번호를 넣어주세요.')
              return false
            }
            if(this.$v.refund.accountHolder.$invalid) {
              this.alert('환불계좌정보에 올바른 예금주명 넣어주세요.')
              return false
            }
          }
          this.claim.products = this.itemList.filter(data => {return data.count}).map(data => {return {count: data.count, opoIdx: data.opoIdx}})
      }
      this.claim.claim = this.claimInfo
      this.claim.refund = this.refund
      if(this.claim.products.length < 1) {
        this.alert(this.getClaimTypeStr() + '할 상품이 없습니다.')
        return false
      }
      this.confirm(this.getClaimTypeStr() + '신청 하시겠습니까?',() => {
          if(!this.claim.returnDeliFee) this.claim.returnDeliFee = 0
          this.$api.api.requestClaim(this.claim).then(resp => {
              console.log(resp)
              if(resp.status == 200) {
                  if(resp.data.result) {
                    this.refundInfo = resp.data.data
                    this.alert('신청되었습니다.')
                    this.$emit('close')
                    return
                  }else {
                    this.alert(resp.data.errMsg)
                    return
                  }
              }
              this.alert(this.getClaimTypeStr() + ' 신청이 실패하였습니다.')
          }).catch(error => {
              console.log(error)
              this.alert(this.getClaimTypeStr() + ' 신청이 실패하였습니다.')
          })
       })
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    getClaimType() {
      switch (this.claimType) {
        case 'cancel':
          return 1
        case 'exchange':
          return 2
        case 'return':
          return 3
      }
    },
    getClaimTypeStr() {
      switch (this.claimType) {
        case 'cancel':
          return '취소'
        case 'exchange':
          return '교환'
        case 'return':
          return '반품'
      }
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            this.getDetail()
          }
        }
      )
    },
    callRecipientPop (address) {
      this.$modal.show(
          RecipientPop,
          { text: '정보수정', claimType: this.claimType, recipientInfo: address, username: this.recipient.name },
          { draggable: false, resizable: false, height: 'auto' },
          {
            'before-close': event => {
              console.log(event.params)
              address.addr1 = event.params.address1
              address.addr2 = event.params.address2
              address.comment = event.params.comment
              address.phone = event.params.phone
              address.name = event.params.username
              address.postalCode = event.params.zonecode
            }
          }
      )
    },
    calRefundAmount() {
        if(this.$v.itemList.$invalid) {
            this.alert(this.getClaimTypeStr() + '할 상품이 없습니다.')
            return false
        }
        this.claim.products = this.itemList.filter(data => {return data.count}).map(data => {return {count: data.count, opoIdx: data.opoIdx}})
        if(this.claim.products.length < 1) {
            this.alert(this.getClaimTypeStr() + '할 상품이 없습니다.')
            return false
        }
        if(!this.claim.returnDeliFee) this.claim.returnDeliFee = 0
        this.$api.api.claimRefund(this.claim).then(resp => {
            console.log(resp)
            if(resp.status == 200) {
                if(resp.data.result) {
                    this.refundInfo = resp.data.data
                    //if(this.getClaimType() == 3 && order.admOrderDetailPayInfo.payKind != 1) this.refundInfo.isRefundAccountInput = 1
                }else this.alert(resp.data.errMsg)
            }
        }).catch(error => {
            console.log(error)
        })
    },
    removeProduct() {
        if(this.claimType == 'cancel' && this.order.isPartialCancel === 0) {
            this.alert('부분 취소가 불가능 합니다.')
            return
        }
        if(this.chkList != null && this.chkList.length > 0) {
            this.confirm('삭제하시겠습니까?', () => {
                this.itemList = this.itemList.filter(data => {
                    return !this.chkList.includes(data)
                })
                this.chkList = []
                this.refundInfo = null
                this.alert('삭제되었습니다.')
            })
        }else this.alert('상품을 선택해주세요.')
    },
    addProduct() {
        if(this.claimType == 'cancel' && this.order.isPartialCancel === 0) {
            this.alert('부분 취소가 불가능 합니다.')
            return
        }
        this.$modal.show(
            ProductAddPop,
            { text: '상품 추가', claimType: this.claimType, order: this.order, chkPrdList: this.itemList },
            { draggable: false, resizable: false, height: 'auto' },
            {
                'before-close': event => {
                    if(Array.isArray(event.params)) {
                        if(this.itemList.length != event.params.length) this.refundInfo = null
                        else {
                            for(let i = 0; i < event.params.length;i++) {
                                if(this.itemList[i].opoIdx != event.params[i]) {
                                    this.refundInfo = null
                                    break
                                }
                            }
                        }
                        if(this.refundInfo == null) {
                            this.itemList = this.order.admOrderDetailOptions.filter(data => {
                                return event.params.includes(data.opoIdx)
                            })
                        }
                    }
                }
            }
        )
    },
    getBankList() {
        this.$api.api.getBankList().then(resp => {
            this.bankList = resp.data.data.BankList
        }).catch(error => {
            console.log(error)
        })
    },
    async getPrdOptMap() {
      let optCnt = this.order.admOrderDetailOptions.length; //옵션의 개수
      for(let i=0; i<this.order.admOrderDetailOptions.length; i++){
        try {
          let excPrd = this.order.admOrderDetailOptions[i]
          excPrd.opt1Data = ''
          excPrd.opt2Data = ''
          //data.exchangeCount = 1
          excPrd.exchangeCount = excPrd.quantityAvail
          excPrd.exchangeMax = excPrd.quantityAvail
          excPrd.exchangeProductList = []
          if (this.prdOptMap[excPrd.prdIdx]) continue
          this.prdOptMap[excPrd.prdIdx] = {opt1List: null, opt2List: null}
          let param = {idx: excPrd.prdIdx}
          let resp = await this.$api.api.getProductDetail(param)
          this.prdOptMap[resp.data.data.prdIdx].opt1List = resp.data.data.optionList
          this.prdOptMap[resp.data.data.prdIdx].opt2List = []
          this.itemList = this.itemList.filter(data => {
            return true
          })
        }
        catch (e) {
          console.log(e)
        }

      }
      //각 옵션별로 prdOptMap을 생성
      for(let j=0; j<optCnt; j++){
        this.privatePrdOptMapList.push(_.cloneDeep(this.prdOptMap))
      }
      console.log(this.prdOptMap)
    },
    changeOpt1(event, prdIdx,index,prdOptMap, chgOpt) {
        //console.log(event)
        chgOpt.opt2Data = ''
        if(event.target.selectedIndex) {
            let index = event.target.selectedIndex - 1
            this.$set(
                prdOptMap[prdIdx],
                'opt2List',
                prdOptMap[prdIdx].opt1List[index].opt2List.filter(data => {
                    return data.optIsUse == 1 && data.optStock > 0
                }))
        }else this.$set(prdOptMap[prdIdx], 'opt2List', [])
        this.itemList[index].opt2Data = ''
        //console.log(this.prdOptMap[prdIdx].opt2List)
        this.itemList = this.itemList.filter(data => {return true})
    },
    changeOpt2(event, prdIdx, index, prdOptMap) {
        console.log(event)
        let max = this.itemList[index].quantityAvail, cnt = max
        if(event.target.selectedIndex > 0) {
            let opt2 = prdOptMap[prdIdx].opt2List[event.target.selectedIndex - 1]
            console.log(opt2)
            cnt = opt2.optStock
            this.itemList.forEach(item => {
                item.exchangeProductList.forEach(product => {
                    if(product.chgOptIdx2 == opt2.optIdx) cnt -= product.count
                })
            })
            this.itemList[index].exchangeProductList.forEach(data => {
                max -= data.count
            })
        }//if
        this.itemList[index].exchangeMax = cnt < max? cnt : max
        //if(this.itemList[index].exchangeMax > 0) this.itemList[index].exchangeCount = 1
        if(this.itemList[index].exchangeMax > 0) this.itemList[index].exchangeCount = this.itemList[index].exchangeMax
        else this.itemList[index].exchangeCount = 0
        console.log(this.itemList[index])
        this.itemList = this.itemList.filter(data => {return true})
    },
    getReasonMngList() {
        this.$api.api.getReasonMngList({}).then(resp => {
            let reasonList = []
            switch(this.getClaimType()) {
                case 1 : {
                    reasonList = resp.data.data.resultCancleData
                } break
                case 2 : {
                    reasonList = resp.data.data.resultChangeData
                } break
                case 3 : {
                    reasonList = resp.data.data.resultReturnData
                } break
            }
            reasonList = reasonList.filter(reason => {return reason.codeClass == 0 || reason.codeClass == 1})
            reasonList.sort((a,b) => { return a.rmSeq - b.rmSeq})
            console.log(reasonList)
            this.reasonList = reasonList.map(reason => {return {
                value: reason.rmIdx,
                text: reason.reasonSubtitle,
                chargeValue: reason.chargeValue
            }})
        }).catch(error => {
            console.log(error)
        })
    },
    changeClaimCode() {
        for(let i = 0; i < this.reasonList.length; i++) {
            if(this.claimInfo.claimCode == this.reasonList[i].value) {
                this.claimInfo.claimCharge = this.reasonList[i].chargeValue
                return
            }
        }
    },
    addExchange(index) {
        let opt1Data = this.itemList[index].opt1Data
        let opt2Data = this.itemList[index].opt2Data
        let exchangeCnt = this.itemList[index].exchangeCount
        if(opt1Data && opt2Data && exchangeCnt > 0) {
            this.itemList[index].exchangeProductList.push({
                colName: opt1Data.colName,
                size: opt2Data.size,
                chgOptIdx2: opt2Data.optIdx,
                count: exchangeCnt,
                opoIdx: this.itemList[index].opoIdx
            })
            let max = this.itemList[index].quantityAvail
            this.itemList[index].exchangeProductList.forEach(data => {
                max -= data.count
            })
            this.itemList[index].exchangeMax = max
            //if(max > 0) this.itemList[index].exchangeCount = 1
            if(max > 0) this.itemList[index].exchangeCount = max
            else this.itemList[index].exchangeCount = 0
            this.itemList[index].opt1Data = ''
            this.itemList[index].opt2Data = ''
        }else {
            this.alert('교환 선택 할 수 없습니다.')
        }
        this.itemList = this.itemList.filter(data => {return true})
    },
    removeExchange(index, exIdx) {
        let max = this.itemList[index].quantityAvail
        let exProduct = this.itemList[index].exchangeProductList[exIdx]
        this.itemList[index].opt1Data = ''
        this.itemList[index].opt2Data = ''
        this.itemList[index].exchangeProductList.splice(exIdx,1)
        this.itemList[index].exchangeProductList.forEach(data => {
            max -= data.count
        })
        this.itemList[index].exchangeMax = max
        //this.itemList[index].exchangeCount = 1
        this.itemList[index].exchangeCount = max
        this.itemList = this.itemList.filter(data => {return true})
    }
  }
}

</script>
