var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card mb-3 card" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-body" }, [
          _c("table", { staticClass: "mb-0 table table-detail" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "text-left wd-200" }, [
                  _vm._v("PC 동영상 URL")
                ]),
                _c("td", { staticClass: "text-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.homeFooterVideoUrlPc,
                        expression: "homeFooterVideoUrlPc",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "홈화면 하단 동영상(PC) URL을 입력해주세요."
                    },
                    domProps: { value: _vm.homeFooterVideoUrlPc },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.homeFooterVideoUrlPc = $event.target.value.trim()
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _c("td", { staticClass: "text-center wd-100" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary w-100",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.callDisplayPop(_vm.homeFooterVideoUrlPc)
                        }
                      }
                    },
                    [_vm._v("미리보기")]
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-left wd-200" }, [
                  _vm._v("Mobile 동영상 URL")
                ]),
                _c("td", { staticClass: "text-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.homeFooterVideoUrlMobile,
                        expression: "homeFooterVideoUrlMobile",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder:
                        "홈화면 하단 동영상(Mobile) URL을 입력해주세요."
                    },
                    domProps: { value: _vm.homeFooterVideoUrlMobile },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.homeFooterVideoUrlMobile = $event.target.value.trim()
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _c("td", { staticClass: "text-center wd-100" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary w-100",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.callDisplayPop(
                            _vm.homeFooterVideoUrlMobile
                          )
                        }
                      }
                    },
                    [_vm._v("미리보기")]
                  )
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
            _c("div", { staticClass: "float-left" }),
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success wd-100",
                  on: { click: _vm.onSave }
                },
                [_vm._v("저장")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header font-size-lg" }, [
      _c("span", [_vm._v("하단 동영상")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }