var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "card-header-title" }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _c("div", { staticClass: "btn-actions-pane-right" }, [
            _c(
              "button",
              {
                staticClass: "close float-none",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "position-relative row form-group" }, [
            _c("label", { staticClass: "col-sm-3 col-form-label" }, [
              _vm._v("주문번호")
            ]),
            _c("div", { staticClass: "col-sm-9" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "text", disabled: "" },
                domProps: { value: _vm.ordNo }
              })
            ])
          ]),
          _c("div", { staticClass: "position-relative row form-group" }, [
            _c("div", { staticClass: "col-sm-10" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.memoInfo.memo,
                    expression: "memoInfo.memo"
                  }
                ],
                staticClass: "form-control",
                domProps: { value: _vm.memoInfo.memo },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.memoInfo, "memo", $event.target.value)
                  }
                }
              })
            ]),
            _c("label", { staticClass: "col-sm-2 col-form-label p-0 pr-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary w-100 he-100",
                  on: { click: _vm.onSave }
                },
                [_vm._v("저장")]
              )
            ])
          ]),
          _c("div", { staticClass: "scroll-area-sm" }, [
            _c("div", { staticClass: "scrollbar-container" }, [
              _c(
                "ul",
                {
                  staticClass:
                    "rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                },
                _vm._l(_vm.memoList, function(memo, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "list-group-item memo-content" },
                    [
                      _c("div", { staticClass: "widget-content p-0" }, [
                        _c("div", { staticClass: "widget-content-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "widget-content-left col-12" },
                            [
                              _c("div", { staticClass: "widget-subheading" }, [
                                _c("strong", { staticClass: "text-left" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dateFormat(
                                        memo.rgdt,
                                        "YYYY-MM-DD HH:MM"
                                      )
                                    ) +
                                      " / " +
                                      _vm._s(memo.admId)
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "widget-heading" }, [
                                _vm._v(_vm._s(memo.memo))
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success wd-100",
                  on: { click: _vm.complete }
                },
                [_vm._v("확인")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }