<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label">주문번호</label>
            <div class="col-sm-9"><input type="text" class="form-control" :value="ordNo" disabled /></div>
          </div>
          <div class="position-relative row form-group">
            <div class="col-sm-10"><textarea class="form-control" v-model="memoInfo.memo"></textarea></div>
            <label class="col-sm-2 col-form-label p-0 pr-3">
              <button class="btn btn-primary w-100 he-100" @click="onSave">저장</button>
            </label>
          </div>
          <div class="scroll-area-sm">
            <div class="scrollbar-container">
              <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                <li class="list-group-item memo-content" v-for="(memo, index) in memoList" :key="index">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left col-12">
                        <div class="widget-subheading">
                          <strong class="text-left">{{ dateFormat(memo.rgdt, 'YYYY-MM-DD HH:MM') }} / {{ memo.admId }}</strong>
                        </div>
                        <div class="widget-heading">{{ memo.memo }}</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-success wd-100" @click="complete">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'MemoPopup',
  data () {
    return {
      isOpen: false,
      memoInfo: {
        ordIdx: '',
        memo: ''
      },
      memoList: []
    }
  },
  validations: {
    memoInfo: {
      memo: {
        required, minLength: minLength(1), maxLength: maxLength(100)
      }
    }
  },
  components: {
  },
  mixins: [
    CommonMixin,
    InterfaceMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    ordNo: {
      type: String,
      default: () => {
        return ''
      }
    },
    ordIdx: {
      type: Number,
      default: () => {
        return ''
      }
    }
  },
  created () {
    console.log('[MCKS] MemoPop::: ordIdx=', this.ordIdx)
    this.memoInfo.ordIdx = this.ordIdx
    this.getMemoList()
  },
  mounted () {
  },
  methods: {
    complete () {

      this.$emit('close')
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    getMemoList () {
      let params = {
        ordIdx: this.ordIdx
      }
      return this.$api.api.getMemoList(params).then(resp => {
        if (resp.data.result) {
          this.memoList = resp.data.data
          this.$bus.$emit('memoUpdate', {amount: this.memoList.length , ordIdx: this.ordIdx})
        }
      }).catch(error => {
        console.log(error)
      })
    },
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (!this.$v.memoInfo.memo.required) {
          this.alert('메모 내용을 입력해주세요.')
          return false
        }
        if (!this.$v.memoInfo.memo.minLength) {
          this.alert('메모 내용은 최소 1자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.memoInfo.memo.maxLength) {
          this.alert('메모 내용은 최대 10자리 이상 입력할 수 없습니다.')
          return false
        }
      }
      return true
    },
    onSave () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 메모 정보 등록 실패 입력 FORM 오류')
        return
      }
      this.confirm('등록 하시겠습니까?', () => {
        this.$api.api.sendMemoRegistration(this.memoInfo).then(resp => {
          if (resp.data.result) {
            this.memoInfo.memo = ''
            this.getMemoList()
          }
        })
      })
    }
  }
}

</script>
