var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "구매후기관리",
            "nav-list": ["맥케이슨관리", "게시판관리", "구매후기관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _vm._m(0),
          _vm.detail.pcsImgListData.length
            ? _c("div", { staticClass: "card-body" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      directives: [{ name: "viewer", rawName: "v-viewer" }],
                      staticClass: "images"
                    },
                    _vm._l(_vm.detail.pcsImgListData, function(src, index) {
                      return _c("img", {
                        key: index,
                        staticClass: "image",
                        attrs: {
                          src: _vm.getImageFullpath(src.path, src.thumb1)
                        }
                      })
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "mb-0 table table-detail" }, [
              _c("tbody", [
                _c("tr", [
                  _c("th", { staticClass: "w-15" }, [_vm._v("주문번호")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm._v(_vm._s(_vm.detail.ordNo))
                  ]),
                  _c("th", { staticClass: "w-15" }, [_vm._v("상품명")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm._v(_vm._s(_vm.detail.oprPrdName)),
                    _c("br"),
                    _vm._v(_vm._s(_vm.detail.opoPrdName))
                  ])
                ]),
                _c("tr", [
                  _c("th", { staticClass: "w-15" }, [_vm._v("회원명")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm._v(_vm._s(_vm.detail.userName))
                  ]),
                  _c("th", { staticClass: "w-15" }, [_vm._v("회원ID")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm._v(_vm._s(_vm.detail.id))
                  ])
                ]),
                _c("tr", [
                  _c("th", { staticClass: "w-15" }, [_vm._v("구매후기 점수")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm._v(_vm._s(_vm.detail.score))
                  ]),
                  _c("th", { staticClass: "w-15" }, [
                    _vm._v("구매후기 등록일")
                  ]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm._v(_vm._s(_vm.detail.ordRgdt))
                  ])
                ]),
                _c("tr", [
                  _c("th", { staticClass: "w-15" }, [_vm._v("구매후기 내용")]),
                  _c("td", {
                    staticClass: "w-85 text-left",
                    domProps: { innerHTML: _vm._s(_vm.detail.contents) }
                  })
                ]),
                _c("tr", [
                  _c("th", { staticClass: "w-15" }, [_vm._v("노출여부")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.detail.pcsIsUse,
                          expression: "detail.pcsIsUse"
                        }
                      ],
                      staticClass: "custom-radio",
                      attrs: {
                        type: "radio",
                        id: "isUse1",
                        name: "isUse",
                        value: "1"
                      },
                      domProps: {
                        checked: _vm.detail.pcsIsUse === 1,
                        checked: _vm._q(_vm.detail.pcsIsUse, "1")
                      },
                      on: {
                        change: [
                          function($event) {
                            return _vm.$set(_vm.detail, "pcsIsUse", "1")
                          },
                          function($event) {
                            return _vm.changeIsUse("1")
                          }
                        ]
                      }
                    }),
                    _c(
                      "label",
                      { staticClass: "ml-1", attrs: { for: "isUse1" } },
                      [_vm._v("전시")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.detail.pcsIsUse,
                          expression: "detail.pcsIsUse"
                        }
                      ],
                      staticClass: "custom-radio ml-5",
                      attrs: {
                        type: "radio",
                        id: "isUse0",
                        name: "isUse",
                        value: "0"
                      },
                      domProps: {
                        checked: _vm.detail.pcsIsUse === 0,
                        checked: _vm._q(_vm.detail.pcsIsUse, "0")
                      },
                      on: {
                        change: [
                          function($event) {
                            return _vm.$set(_vm.detail, "pcsIsUse", "0")
                          },
                          function($event) {
                            return _vm.changeIsUse("0")
                          }
                        ]
                      }
                    }),
                    _c(
                      "label",
                      { staticClass: "ml-1", attrs: { for: "isUse0" } },
                      [_vm._v("미전시")]
                    )
                  ]),
                  _c("th", { staticClass: "w-15" }, [_vm._v("포인트 적립")]),
                  _c("td", { staticClass: "w-35 text-left" }, [
                    _vm.orgPoint > 0
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.detail.point,
                              expression: "detail.point"
                            }
                          ],
                          staticClass: "form-control d-inline-block w-75",
                          attrs: {
                            type: "number",
                            placeholder: "포인트를 입력해주세요.",
                            disabled: ""
                          },
                          domProps: { value: _vm.detail.point },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.detail, "point", $event.target.value)
                            }
                          }
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.detail.point,
                              expression: "detail.point"
                            }
                          ],
                          staticClass: "form-control d-inline-block w-75",
                          attrs: {
                            type: "number",
                            placeholder: "포인트를 입력해주세요."
                          },
                          domProps: { value: _vm.detail.point },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.detail, "point", $event.target.value)
                            }
                          }
                        })
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 text-center mb-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary wd-100 mr-1",
            on: { click: _vm.onList }
          },
          [_vm._v("목록")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary wd-100 ml-1",
            on: { click: _vm.onUpdate }
          },
          [_vm._v("수정")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("구매후기 상세")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }