var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "버전관리",
            "nav-list": ["맥케이슨관리", "기본정보관리", "버전관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("태블릿 버전 관리")
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "mb-0 table table-detail" }, [
              _c("tbody", [
                _c("tr", [
                  _vm._m(0),
                  _c("td", { staticClass: "text-center w-auto" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.version,
                          expression: "version"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "버전을 입력해주세요."
                      },
                      domProps: { value: _vm.version },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.version = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("td", { staticClass: "text-center wd-125" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success w-100",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.onSave()
                          }
                        }
                      },
                      [_vm._v("저장")]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _vm._m(1)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-left wd-150" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("버전")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-footer" }, [
      _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
        _c("div", { staticClass: "float-left" }),
        _c("div", { staticClass: "float-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }