import Vue from 'vue'
import directives from '@/components/directives'
import apis from '@/apis'
import filters from '@/components/filters'
import utils from '@/components/utils'
import EventBus from '@/lib/eventBus'

export default {
  install (Vue) {
    Object.keys(filters).map(name => Vue.filter(name, filters[name]))
    Object.keys(directives).map(name => Vue.directive(name, directives[name]))
    Vue.prototype.$api = apis.apis
    Vue.prototype.$utils = utils
    Vue.prototype.$bus = EventBus
  }
}
