<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <table class="mb-0 table">
            <tbody>
              <tr v-if="popupType === 'CLIENTADDR'">
                <th class="w-25"><span class="required">*</span>주소별칭</th>
                <td class="w-85"><input type="text" class="form-control" v-model="recipient.addrNickname" /></td>
              </tr>
              <tr>
                <th class="w-25"><span class="required">*</span>수령자</th>
                <td class="w-85"><input type="text" class="form-control" v-model="recipient.username" /></td>
              </tr>
              <tr>
                <th class="w-25"><span class="required">*</span>연락처</th>
                <td class="w-85"><the-mask type="tel" :mask="['###-####-####', '###-###-####']" pattern="\d*" maxlength="13" class="form-control" v-model="recipient.phone"/></td>
              </tr>
              <tr>
                <th class="w-25"><span class="required">*</span>배송주소</th>
                <td class="w-85">
                  <div class="position-relative row form-group">
                    <div class="col-sm-12">
                      <div class="position-relative">
                        <input type="text" class="form-control w-25 d-inline mb-2" disabled v-model="recipient.zonecode"><button class="btn btn-primary wd-150 mb-2 ml-2 form-control" @click="callAddrSearchPop">우편번호검색</button>
                        <input type="text" class="form-control d-inline mb-2" disabled v-model="recipient.address1" />
                        <input type="text" class="form-control d-inline" v-model="recipient.address2" placeholder="상세주소를 입력하세요" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="popupType !== 'CLIENTADDR'">
                <th class="w-25">배송요청사항</th>
                <td class="w-85"><input type="text" class="form-control" v-model="recipient.comment" :maxlength="20"/></td>
              </tr>
              <tr v-if="popupType === 'CLIENTADDR'" class="text-right align-text-bottom">
                <th class="w-25"></th>
                <td class="w-85">
                  <label>기본배송지 여부</label>
                  <input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="recipient.isDefault" :true-value=1 :false-value=0 />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100 ml-1" @click="complete">{{ getButtonText }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DaumPostalPop from '@/components/popup/DaumPostalPop'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'RecipientPop',
  data () {
    return {
      isOpen: false,
      recipient: {
        username: this.recipientInfo.name ? this.recipientInfo.name : (this.recipientInfo.recipient? this.recipientInfo.recipient : ''),
        phone: this.recipientInfo.phone ? this.recipientInfo.phone : (this.recipientInfo.recipientPhone? this.recipientInfo.recipientPhone : ''),
        zonecode: this.recipientInfo.postalCode ? this.recipientInfo.postalCode : '',
        address1: this.recipientInfo.addr1 ? this.recipientInfo.addr1 : '',
        address2: this.recipientInfo.addr2 ? this.recipientInfo.addr2 : '',
        comment: this.recipientInfo.comment ? this.recipientInfo.comment : '',
        addrNickname : this.recipientInfo.addrNickname ?? '',
        isDefault : this.recipientInfo.isDefault ?? 0
      }
    }
  },
  validations: {
    recipient: {
      addrNickname: { required, minLength: minLength(2), maxLength: maxLength(10) },
      username: { required, minLength: minLength(2), maxLength: maxLength(10) },
      phone: { required, minLength: minLength(10), maxLength: maxLength(11) },
      zonecode: { required, minLength: minLength(1), maxLength: maxLength(10) },
      address1: { required, minLength: minLength(1), maxLength: maxLength(100) },
      address2: { required, minLength: minLength(1), maxLength: maxLength(100) }
    }
  },
  components: {
    DaumPostalPop
  },
  mixins: [
      InterfaceMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    recipientInfo: {
      type: Object,
      default: () => {
        return {
          clmIdx: 0,
          name: '',
          phone: '',
          postalCode: '',
          addr1: '',
          addr2: '',
          addrTitle: '',
          comment: '',
          isDefault: 0,
          addrNickname: '',
          cliAddrIdx: 0
        }
      }
    },
    username: {
      type: String,
      default: () => {
        return ''
      }
    },
    /**
     * prop : 호출 시 값이 존재하면 Client관리에서 호출
     */
    popupType: {
      type: String,
      default: () => {
        return ''
      },
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    onCancle () {
      this.$emit('close')
    },
    complete () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 정보 수정 등록 실패 입력 FORM 오류')
        return
      }
      // this.alert('저장되었습니다.')
      this.$emit('close', this.recipient)
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    // 우편번호검색 팝업
    callAddrSearchPop () {
      this.$modal.show(
        DaumPostalPop,
        { text: '우편번호검색' },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            let addrObj = event.params
            console.log(addrObj)
            this.recipient.zonecode = event.params.zonecode
            this.recipient.address1 = event.params.address
            this.recipient.address2 = ''
            //this.recipient.comment = ''
          }
        }
      )
    },
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (this.popupType === 'CLIENTADDR' && !this.$v.recipient.addrNickname.required) {
          this.alert('주소별칭을 입력해주세요.')
          return false
        }
        if (!this.$v.recipient.username.required) {
          this.alert('수령자명을 입력해주세요.')
          return false
        }
        if (!this.$v.recipient.phone.required) {
          this.alert('휴대폰번호를 입력해주세요.')
          return false
        }
        if (!this.$v.recipient.zonecode.required) {
          this.alert('우편번호를 입력해주세요.')
          return false
        }
        if (!this.$v.recipient.address1.required) {
          this.alert('배송주소를 입력해주세요.')
          return false
        }
        if (!this.$v.recipient.address2.required) {
          this.alert('상세주소를 입력해주세요.')
          return false
        }
      }
      return true
    }
  },
  computed: {
    getButtonText(){
      return ( this.popupType.length > 0 && this.recipientInfo.cliAddrIdx > 0) ? '수정' : '적용'
    }
  }
}
</script>
