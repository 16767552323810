<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'마일리지관리'" :nav-list="['맥케이슨관리', '마일리지관리', '마일리지관리']"/>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setStartDate" @inputEndDate="setEndDate" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 120, unit: 'M'}"/>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">마일리지현황 : {{userName}}</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th class="w-15">마일리지</th>
                <td class="text-center">{{availPoint | comma}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangePageSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center">번호</th>
                <th class="text-center">처리일시</th>
                <th class="text-center">마일리지유형</th>
                <th class="text-center">구분</th>
                <th class="text-center">금액</th>
                <th class="text-center">주문번호</th>
                <th class="text-center">유효기간</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!resultDataList.length">
                <td colspan="11" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(item, idx) in resultDataList" :key="idx" v-else>
                <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                <td class="text-center">{{ item.ordRgdt }}</td>
                <td class="text-center">{{ item.description }}</td>
                <td class="text-center">{{ item.pointTypeStr }}</td>
                <td class="text-center">{{ item.pointType === 2 ? item.point : item.point === 0 ? item.point : '-' + item.point | comma }}</td>
                <td class="text-center">{{ item.ordNo }}</td>
                <td class="text-center">{{ item.pointValidityDt }}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="resultDataList.length"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center m-3">
      <button class="btn btn-lg btn-secondary ml-3 wd-150" @click="onList">목록</button>
    </div>
  </div>
</template>

<script>
  import { CommonMixin } from '@/components/mixins/CommonMixin'
  import NavigationBar from '@/page/components/NavigationBar'
  import Pagination from "@/page/components/Pagination"
  import DatePickerButton from "@/components/common/DatePickerButton"
  import InterfaceMixin from '@/mixins/userInterface-mixin'
  import moment from 'moment'


  export default {
    name: "BlackListList",
    data () {
      return {
        listSearch: {
          srchDateStart: '',
          srchDateEnd: '',
          srchPage: 1,
          srchPageCnt: 10,
        },
        search: {
          cliIdx: '',
          srchDateStart: '',
          srchDateEnd: '',
          srchPage: 1,
          srchPageCnt: 10,
        },
        srchPageSizeList: [
          { value: 10, text: '10개' },
          { value: 20, text: '20개' },
          { value: 30, text: '30개' },
          { value: 40, text: '40개' },
          { value: 50, text: '50개' }
        ],
        userName: '',
        resultDataList: [],
        availPoint : 0,
        totalCnt: 0,
      }
    },
    components: {
      NavigationBar,
      Pagination,
      DatePickerButton
    },
    mixins: [
      CommonMixin, InterfaceMixin
    ],
    computed: {
    },
    created () {
    },
    mounted () {
      this.search.cliIdx = this.$route.query.cliIdx;
      this.userName = this.$route.query.userName;
      this.listSearch = this.objectDeserialize(this.$route.query.search);
      this.search.srchDateStart = this.listSearch.srchDateStart;
      this.search.srchDateEnd = this.listSearch.srchDateEnd;
      this.onSearch()
    },
    methods: {
      init() {
        this.initDate()
        this.onSearch()
        this.getAvailPoint()
      },
      onSearch(){
        this.getAdmClientPointDetail()
        this.getAvailPoint()
      },
      getAdmClientPointDetail(){
        this.$api.api.getAdmClientPointDetail(this.search).then(res => {
          if (res.data.totalCnt < 1) this.availPoint = 0
          if(res.data.result){
            this.resultDataList = res.data.data
            this.totalCnt = res.data.totalCnt
          }
        }).catch(err => {
          console.log("err",err)
        })
      },
      getAvailPoint(){
        this.$api.api.getAdmClientAvailPoint(this.search).then(res => {
          if(res.data.result){
            this.availPoint = res.data.data
          }
        }).catch(err => {
          console.log("err",err)
        })
      },
      initDate(){
        this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
        this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      },
      onReset () {
        this.initDate()
        this.search.userName = ''
        this.search.userPhone = ''
      },
      setStartDate (value) {
        this.search.srchDateStart = value
      },
      setEndDate (value) {
        this.search.srchDateEnd = value
      },

      onPage (srchPage) {
        this.search.srchPage = srchPage
        this.onSearch()
      },
      onChangePageSize(){
        this.search.srchPage = 1
        this.onSearch()
      },
      onList() {
        this.$router.push({
          path: "/point/totalList",
          query: { search: this.objectSerialize(this.listSearch) }
        });
      },
    }
  }
</script>

<style scoped>
  .faq-accordion-combo__content{
    background: #0b5885;
    border: 1px solid black;
  }
</style>
