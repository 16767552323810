<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'관리자관리'" :nav-list="['맥케이슨관리', '기본정보관리', '관리자관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" name="checkAll" v-model="checkAll" />
                  <th class="text-center">No</th>
                  <th class="text-center">이름</th>
                  <th class="text-center">휴대폰</th>
                  <th class="text-center">이메일</th>
                  <th class="text-center">아이디</th>
                  <th class="text-center">등록일</th>
                  <th class="text-center">수정일</th>
                </tr>
              </thead>
              <tbody>
              <tr v-if="!itemList.length">
                <td colspan="8" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                <td class="text-center"><input type="checkbox" v-model="chkList" :value="data.idx" v-if="data.level !== 1" /></td>
                <th class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</th>
                <td class="text-center"><a href="javascript:void(0)" @click="goDetail(data.idx)">{{ data.userName }}</a></td>
                <td class="text-center">{{ data.userPhone | dash}}</td>
                <td class="text-center">{{ data.userEmail }}</td>
                <td class="text-center">{{ data.userId }}</td>
                <td class="text-center">{{ dateFormat(data.regDate, 'yyyy-MM-dd') }}</td>
                <td class="text-center">{{ dateFormat(data.upDate, 'yyyy-MM-dd') }}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="itemList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100 m-1" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100 m-1" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'OrderList',
  data () {
    return {
      search: {
        srchPage: 1,
        srchPageCnt: 10,
        srchType: '',
        srchKeyword: ''
      },
      srchTypeList: [
        { value: 1, text: '이름' },
        { value: 2, text: '휴대폰' },
        { value: 3, text: '이메일' },
        { value: 4, text: '아이디' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      totalCnt: 0,
      itemList: [],
      chkList: []
    }
  },
  components: {
    NavigationBar,
    Pagination
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    checkAll: {
      get () {
        let total = []
        this.itemList.map((item, idx) => {
            if (item.level != 1) {
              total.push(item)
            }
        })
        return total.length ? this.chkList.length === total.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.itemList.map((item, idx) => {
            if (item.level != 1) {
              selected.push(item.idx)
            }
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let qs = this.$route.query.search

      if(qs) {
        let search=  this.objectDeserialize(qs)
        if(search.srchType != undefined) this.search.srchType = search.srchType
        if(search.srchKeyword != undefined) this.search.srchKeyword = search.srchKeyword
        this.search.srchPage = Number(search.srchPage)
        this.search.srchPageCnt = Number(search.srchPageCnt)
      }else this.onReset()

      this.getItemList()
    },
    onReset () {
      this.search.srchType = ''
      this.search.srchKeyword = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.onList()
    },
    onList(){
      // this.$router.push({path: '/admin/list', query: { search: this.objectSerialize(this.search)}})
      this.getItemList()
    },
    getItemList () {
      return this.$api.api.getAdminList(this.search).then(resp => {
        this.chkList = []
        this.itemList = resp.data.data
        this.totalCnt = resp.data.totalCnt
      }).catch(() => {
        this.isLoad = false
      })
    },
    onCreate () {
      this.$router.push({path: '/admin/detail'})
    },
    onDelete () {
      if (this.chkList.length <= 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }
      this.confirm('삭제 하시겠습니까?', () => {
        let param = {
          idxList: this.chkList
        }
        this.$api.api.reqAdminDeletation(param).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.$router.go(0)
            })
          } else {
            this.alert('삭제에 실패하였습니다.')
          }
        }).catch(() => {
          this.isLoad = false
        })
      })
    },
    goDetail (idx) {
      this.$router.push({path: '/admin/detail', query: { idx: idx, search: this.objectSerialize(this.search) }})
    }
  }
}
</script>

<style scoped>

</style>
