<template>
  <div class="app-header header-shadow">
    <div class="app-header__logo">
      <!--
      <div class="logo-src"></div>
      -->
      <div class="fsize-3" @click="goMenuUrl()"><a href="javascript:void(0)"><img src="../../assets/images/logo.png" class="w-100"/></a></div>
    </div>
    <div class="app-header__content">
      <div class="app-header-left">
      </div>
      <div class="app-header-right">
        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left  ml-3 header-user-info">
                <div class="widget-heading">{{ accessDate }}</div>
                <div class="widget-heading">{{ $store.getters.userData.id }}님 안녕하세요</div>
                <div class="widget-subheading" @click="onLogout">로그아웃</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { actionType } from '@/store/const/StoreType'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import VueJwtDecode from 'vue-jwt-decode'
import moment from 'moment'

export default{
  name: 'TopMenu',
  data () {
    return {
      nowTime: ''
    }
  },
  mixins: [
    InterfaceMixin
  ],
  computed: {
    accessDate () {
      // accessToken regDate
      // let token = sessionStorage.getItem('accessToken')
      // if (token !== null) {
      //   let json = JSON.stringify(VueJwtDecode.decode(token))
      //   return moment(JSON.parse(json).regDate).format('yyyy.MM.DD')
      // }
      // return ''

      // thisDate
      return moment().format('yyyy.MM.DD')
    }
  },
  methods: {
    onLogout () {
      this.confirm('로그아웃 하시겠습니까?', () => {
        this.$store.dispatch(actionType.ACTION_LOGOUT).then((resp) => {
          this.$toast.info( this.$store.getters.userData.userName+ '님 로그아웃')
          this.$router.replace({ path: '/login' })
        }).catch((err) => {
          console.log(err)
          this.alert('로그아웃에 실패했습니다.')
        })
      })
    },
    goMenuUrl () {
      var path = '/order/list';

      if(this.$store.getters.userData.level == 3) {
        path = '/member/list'
      }

      let nowTime = moment().valueOf()
      this.$router.push({path: path + '?nowTime=' + nowTime})
    }
  }
}
</script>
