import DefaultLayout from '@/layout/DefaultLayout'

export default {
  path: '/basic',
  component: DefaultLayout,
  redirect: '/basic',
  children: [
    {
      path: '/myInfo',
      name: 'MyInfo',
      props: (route) => ({ query: route.query}),
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/MyInfo/MyInfo')
    },
    {
      path: '/admin/list/:params?',
      name: 'AdminList',
      props: (route) => ({ query: route.query }),
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Admin/AdminList')
    },
    {
      path: '/admin/detail/:params?',
      name: 'AdminDetail',
      props: (route) => ({ query: route.query }),
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Admin/AdminDetail')
    },
    {
      path: '/store/list/:params?',
      name: 'StoreList',
      props: (route) => ({ query: route.query }),
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Store/StoreList')
    },
    {
      path: '/store/modify/:params?',
      name: 'StoreDetail',
      props: (route) => ({ query: route.query }),
      // props: true,
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Store/StoreRegistration')
    },
    {
      path: '/fee/list',
      name: 'FeeList',
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Fee/FeeList')
    },
    {
      path: '/sizeClass/list',
      name: 'SizeClassList',
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/SizeClass/SizeClassList')
    },
    {
      path: '/size/list',
      name: 'Size',
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Size/Size')
    },
    {
      path: '/color/list',
      name: 'ColorList',
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Color/ColorList')
    },
    {
      path: '/brochure/list',
      name: 'BrochureList',
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Brochure/BrochureList')
    },
    {
      path: '/version/list',
      name: 'VersionList',
      component: () => import(/* webpackChunkName: 'Basic' */'@/page/basic/Version/VersionList')
    }
  ]
}
