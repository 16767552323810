<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div style="max-height: 800px; overflow-y: scroll; width: 100%;">
          <div class="card-body">
            <h5 class="card-title">유입경로</h5>
            <table class="mb-0 table table-detail">
              <thead>
                <tr>
                  <th>유입경로</th>
                  <th>주문일(결제일)</th>
                  <th>주문번호</th>
                  <th>결제금액</th>
                  <th>결제수단</th>
                  <th>주문자</th>
                  <th>수령자</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{ order.admOrderDetailInfo.accessMethod }}</td>
                  <td class="text-center">{{ dateFormat(order.admOrderDetailInfo.ordRgdt, "YYYY-MM-DD HH:mm") }}<br>({{ dateFormat(order.admOrderDetailInfo.payDt, 'YYYY-MM-DD HH:mm') }})</td>
                  <td class="text-center">{{ order.admOrderDetailInfo.ordNo }}</td>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payAmount | comma }}원</td>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payKind | payKind }}</td>
                  <td class="text-center">{{ maskName(order.admOrderDetailOrder.userName) }}</td>
                  <td class="text-center">{{ maskName(order.admOrderDetailRecepient.recipient) }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(order.ordIdx, order.admOrderDetailInfo.ordNo)">{{ order.admOrderDetailInfo.memoCnt }}</a></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <h5 class="card-title">상품정보</h5>
            <div style="height: 250px; overflow-y: scroll; width: 100%;">
              <table class="mb-0 table table-detail">
                <thead>
                  <tr>
                    <th>상품별 주문번호</th>
                    <th>상품번호</th>
                    <th class="w-25">상품명/옵션</th>
                    <th>수량</th>
                    <th>판매가</th>
                    <th>상품금액</th>
                    <th>결제상태</th>
                    <th>배송상태</th>
                    <th>취소상태</th>
                    <th>교환상태</th>
                    <th>반품상태</th>
                    <th>환불상태</th>
                    <th>확정취소</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, idx) in order.admOrderDetailOptions">
                    <td class="text-center">{{ `${product.ordNo}_${product.opoIdx}` }}</td>
                    <td class="text-center">{{ product.prdNo }}</td>
                    <td class="text-left">
                      <p class="mb-0">{{ product.ordProduct }}</p>
                      <span class="mb-0 font-color-blue">옵션1 : {{ product.opoColName }}<template v-if="product.opoSize"> / 옵션2 : {{ product.opoSize }}</template></span>
                    </td>
                    <td class="text-center">{{ product.quantity | comma }}</td>
                    <td class="text-center">{{ product.optPriceSale | comma }}원</td>
                    <td class="text-center" :style="order.admOrderDetailInfo.memIdx? 'color:#B40404': ''">{{ product.prdAmount | comma }}원</td>
                    <td class="text-center">{{ product.ordStatus | ordStatus }}</td>
                    <td class="text-center">{{ (product.opoPurchaseConfirm === 'Y'? '구매확정' : product.odvStatus) | odvStatus }}</td>
                    <td class="text-center">{{ product.cancelStatus | cancelStatus }}</td>
                    <td class="text-center">{{ product.exchangeStatus | exchangeStatus }}</td>
                    <td class="text-center">{{ product.returnStatus | returnStatus }}</td>
                    <td class="text-center">{{ product.refundStatus | refundStatus }}</td>
                    <td class="text-center"><button v-if="product.opoPurchaseConfirm === 'Y'" class="btn btn-danger wd-75" @click="doPurchaseReset(product)">적용</button></td>
                  </tr>
                  <tr v-if="giftList.length > 0">
                    <td class="text-center" colspan="13" style="color:#B40404">
                      [사은품] {{ strGift }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-body">
            <h5 class="card-title">결제정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th colspan="2">결제정보</th>
                  <th colspan="2">상품정보</th>
                  <th colspan="2">배송비</th>
                </tr>
                <tr>
                  <th>결제금액</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payAmount | comma }}원</td>
                  <th class="text-center" rowspan="3">상품 금액 합</th>
                  <td class="text-center" rowspan="3">{{ order.admOrderDetailPayInfo.onlyPrdAmount | comma }}원</td>
                  <th class="text-center">배송비 합</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.deliFeeSum | comma }}원</td>
                </tr>
                <tr>
                  <th>결제수단</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payKind | payKind }}</td>
                  <th class="text-center">기본배송비</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.deliFee | comma }}원</td>
                </tr>
                <tr>
                  <th>세부정보</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.accountName }} / {{ order.admOrderDetailPayInfo.accountNo }}</td>
                  <th class="text-center">도서산간</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.deliAddfee  | comma }}원</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <h5 class="card-title">주문자 정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">성명</th>
                  <td class="w-35">{{ maskName(order.admOrderDetailOrder.userName) }}</td>
                  <th class="w-15">구매횟수</th>
                  <td class="w-35">{{ order.admOrderDetailOrder.buyCnt | comma }}</td>
                </tr>
                <tr>
                  <th class="w-15">전화번호</th>
                  <td class="w-35">{{ maskPhone(order.admOrderDetailOrder.userPhone) | dash}}</td>
                  <th class="w-15">이메일</th>
                  <td class="w-13">{{ maskEmail(order.admOrderDetailOrder.userEmail) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <h5 class="card-title">수령자 정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">성명</th>
                  <td class="w-35">{{ maskName(order.admOrderDetailRecepient.recipient) }}</td>
                  <th class="w-15">연락처</th>
                  <td class="w-35">{{ maskPhone(order.admOrderDetailRecepient.recipientPhone) | dash}}</td>
                </tr>
                <tr>
                  <th class="w-15">배송지</th>
                  <td class="w-35">({{ order.admOrderDetailRecepient.postalCode }}) {{ order.admOrderDetailRecepient.addr1 }} {{ order.admOrderDetailRecepient.addr2 }}</td>
                  <th class="w-15">요청사항</th>
                  <td class="w-35">{{ order.admOrderDetailRecepient.comment }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100" @click="onCancle">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import MemoPop from '@/page/order/components/MemoPop'

export default {
  name: 'OrderDetailPopup',
  data () {
    return {
      isOpen: false,
      order: {
        ordIdx: '',
        admOrderDetailInfo: {},
        admOrderDetailOptions: [],
        admOrderDetailPayInfo: {},
        admOrderDetailOrder: {},
        admOrderDetailRecepient: {},
        admOrderDetailClaimSummaries: []
      },
      giftList: [],
      strGift: ''
    }
  },
  components: {
    MemoPop
  },
  mixins: [
    CommonMixin,
    InterfaceMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    ordIdx: {
      type: Number|String,
      default: (value) => {
        return ''
      }
    }
  },
  computed: {
  },
  async created () {
    this.$bus.$on('memoUpdate', value => {
      this.order.admOrderDetailInfo.memoCnt = value.amount
    })

    this.order.ordIdx = parseInt(this.ordIdx)
    this.getDetail();
  },

  mounted () {
  },
  methods: {
    getGiftList () {
      let params = {
        ordNo: this.order.admOrderDetailInfo.ordNo
      }
      this.$api.api.getGiftList(params).then(resp => {
        console.log('resp ::: ', resp)
        if (resp.data.result) {
          this.giftList = resp.data.data.giftList
          this.strGift = ''
          this.giftList.forEach((gift, idx) => {
            if (this.giftList.length -1 === idx) {
              this.strGift += gift
            } else {
              this.strGift += gift + ', '
            }
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getDetail () {
      console.log('[MCKAYSON] getDetail::: ', this.order.ordIdx)
      let params = {
        ordIdx: this.order.ordIdx
      }
      this.$api.api.getOrderDetail(params).then(resp => {
        if (resp.data.result) {
          this.order.admOrderDetailInfo = resp.data.data.admOrderDetailInfo
          this.order.admOrderDetailOptions = resp.data.data.admOrderDetailOptions
          this.order.admOrderDetailPayInfo = resp.data.data.admOrderDetailPayInfo
          this.order.admOrderDetailOrder = resp.data.data.admOrderDetailOrder
          this.order.admOrderDetailRecepient = resp.data.data.admOrderDetailRecepient
          this.order.admOrderDetailClaimSummaries = resp.data.data.admOrderDetailClaimSummaries
        }

        this.getGiftList();
      }).catch(error => {
        console.log(error)
      })
    },
    onCancle () {
      this.$emit('close')
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo},
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
          }
        }
      )
    },
    doPurchaseReset (item) {
      this.confirm('구매자의 구매확정을 취소 하시겠습니까?', () => {
        let reqBody = {
          ordIdx: item.ordIdx,
          opoIdx: item.opoIdx
        }
        this.$api.api.sendPurchaseReset(reqBody).then(resp => {
          if (resp.data.result) {
            this.getDetail()
          }else {
            this.alert(resp.data.errMsg)
          }
        }).catch(error => {
          console.log(error)
        })
      })
    }
  }
}

</script>
