import { render, staticRenderFns } from "./VersionList.vue?vue&type=template&id=b0454a52&scoped=true&"
import script from "./VersionList.vue?vue&type=script&lang=js&"
export * from "./VersionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0454a52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/MCKAYSON/REAL/MCKAYSON_REAL_ADMIN/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b0454a52')) {
      api.createRecord('b0454a52', component.options)
    } else {
      api.reload('b0454a52', component.options)
    }
    module.hot.accept("./VersionList.vue?vue&type=template&id=b0454a52&scoped=true&", function () {
      api.rerender('b0454a52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/basic/Version/VersionList.vue"
export default component.exports