<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body text-center modal-body">
          <video preload="auto" playsinline="" autoplay="" loop="" muted="" class="w-100">
            <source :src="displayUrl" type="video/mp4">
            <!-- <source src="https://image.mckayson.com/mckayson/pc/movie/main.webm" type="video/mp4"> -->
          </video>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100 mr-2" @click="onCancle">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'

export default {
  name: 'DisplayPopup',
  data () {
    return {
      isOpen: false
    }
  },
  components: {
  },
  mixins: [
    CommonMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    displayUrl: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    onCancle () {
      this.$emit('close')
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    }
  }
}

</script>
<style>
  .modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
</style>
