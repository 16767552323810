<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'이벤트관리'" :nav-list="['맥케이슨관리', '게시판관리', '이벤트관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">이벤트 정보</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>제목</label>
              <div class="col-sm-10">
                <input type="text" class="form-control d-inline-block w-75" placeholder="제목을 입력해주세요." v-model="request.evtName" maxlength="30" />
<!--                <label class="ml-2">30자 이내 ({{ prmInfo.prmName.length }}/30)</label>-->
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>게시기간</label>
              <div class="col-sm-10">
                <date-picker v-model="request.evtSdt" @change="chgSdt" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" :disabled-date="notAfterToday" />
                <label class="col-form-label ml-2 mr-2">~</label>
                <date-picker v-model="request.evtEdt" @change="chgEdt" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" :disabled-date="notBeforeToday" />
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>이미지</label>
              <div class="col-sm-10">
                <button class="btn btn-warning wd-125 mr-1" @click="addImgItem">이미지 항목 추가</button>
              </div>
            </div>
            <div class="position-relative row form-group" v-for="(item,idx) in resultImgDataList">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>이미지{{ idx + 1  }} <span style="color: red">(660 * 850)</span></label>
              <div class="col-sm-10">
                <upload-photo :file="{src: getImagePath(getImgSrc(item)), w: 500, h:500}" :branch="'event'" @changed="changedOk($event , idx)" v-slot:deleteButton>
                    <button class="btn btn-primary mt-2 ml-2" @click="deleteImg(idx)">삭제</button>
                </upload-photo>
<!--                <img v-else :src="getImagePath(getImgSrc(item))" class="preview-img-item wd-150" v-image-error @click="viewImage(image)" />-->
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>전시여부</label>
              <div class="col-sm-10">
                <div class="custom-radio custom-control custom-control-inline"><input type="radio" :checked="request.evtIsUse === 1" class="custom-control-input" value="1" v-model="request.evtIsUse" id="prmIsUse_yes"><label class="custom-control-label" for="prmIsUse_yes">전시</label></div>
                <div class="custom-radio custom-control custom-control-inline"><input type="radio" :checked="request.evtIsUse === 0" class="custom-control-input" value="0" v-model="request.evtIsUse" id="prmIsUse_no"><label class="custom-control-label" for="prmIsUse_no">비전시</label></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="request.evtIdx > 0 " @click="onUpdate">수정</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="request.evtIdx == 0" @click="onCreate">등록</button>
      </div>
    </div>

<!--    <div class="row">-->
<!--      <div class="col-lg-12">-->
<!--        <div class="main-card mb-3 card">-->
<!--          <div class="card-body">-->
<!--            <table class="mb-0 table table-detail">-->
<!--              <tbody>-->
<!--                <tr>-->
<!--                  <th class="w-15">등록일</th>-->
<!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="dateFormat(prmInfo.regiPersonData.rgdt, 'YYYY-MM-DD HH:mm')"></td>-->
<!--                  <th class="w-15">작성자</th>-->
<!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="prmInfo.regiPersonData.rgAdmId"></td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <th class="w-15">수정일</th>-->
<!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="dateFormat(prmInfo.regiPersonData.updt, 'YYYY-MM-DD HH:mm')"></td>-->
<!--                  <th class="w-15">수정자</th>-->
<!--                  <td class="w-35"><input type="text" class="form-control" disabled :value="prmInfo.regiPersonData.upAdmId"></td>-->
<!--                </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import moment from 'moment'
import CodeMixin from "@/mixins/code-mixin";
import UploadPhoto from '@/components/common/UploadPhoto'

export default {
  name: "EventRegistration",
  data () {
    return {
      request: {
        evtIdx : 0,
        evtName : '',
        evtIsUse : 1,
        evtSdt : '',
        evtEdt : '',
        imgList : [],
      },

      resultData : {},
      resultImgDataList: []
    }
  },
  components: {
    UploadPhoto,
    NavigationBar,
    DatePicker,
  },
  mixins: [
    CommonMixin,
    InterfaceMixin,
    CodeMixin
  ],
  computed: {

  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init(){
      if(this.$route.params.evtIdx){
        this.request.evtIdx =  this.$route.params.evtIdx
        this.onSearch()
      }
    },
    onSearch(){
      this.$api.api.getEventDetail({evtIdx : this.request.evtIdx} ).then(res=>{
        let resultRes = res.data
        if(resultRes.result){
          this.resultData = resultRes.data
          this.resultImgDataList = this.resultData.evtImgListData

          //등록및 수정을 위한 Setting
          this.request.evtName = this.resultData.evtName
          this.request.evtSdt = this.resultData.evtSdt
          this.request.evtEdt = this.resultData.evtEdt
          this.request.evtIsUse = this.resultData.evtIsUse
        }
      })
    }
    ,
    onList () {
      this.$router.push({name: 'EventList'})
    },
    onCreate () {
      this.resultImgDataList =  this.resultImgDataList.filter(item=> item.filename !== '')

      if (!this.checkValid()) {
        console.log('[MCKS] 이벤트 등록 입력 FORM 오류')
        return
      }

      let imgList = this.resultImgDataList.map(item => {
        return item.filename
      })

      this.request.imgList = imgList
      this.$api.api.addEvent(this.request).then(res => {
        if(res.data.result){
          this.alert("이벤트가 등록 되었습니다.")
          this.$router.go(-1)
        }
        else{
          this.alert("등록에 실패했습니다.")
        }
      })
    },
    onUpdate () {
      if(this.request.evtIdx <= 0 ){
        this.alert("이벤트 idx를 확인해주세요")
        return false
      }

      this.resultImgDataList =  this.resultImgDataList.filter(item=> item.filename !== '')
      this.request.imgList = this.getItems()

      if (!this.checkValid()) {
        console.log('[MCKS] 이벤트 등록 입력 FORM 오류')
        return
      }


      this.$api.api.updateEvent(this.request).then(res => {
        if(res.data.result){
          this.alert("수정되었습니다.")
          this.onSearch()
        }else{
          this.alert("수정에 실패했습니다.")
        }
      })
    },
    getItems () {
      let imgList = this.resultImgDataList.map(item => {
                  if(!item.path.includes("temp")){
                    return this.getImgSrc(item)
                  }
                  else{
                    return item.filename
                  }
                })
      console.log("imgList" + imgList)
      return imgList
    },
    deleteImg(idx){
      this.alert("삭제")
      this.resultImgDataList.splice(idx,1)
    },

    addImgItem(){
      let imgData = {
        imgIdx: 0,
        filename: '',
        path: ''
      }
      this.resultImgDataList.push(imgData)
    },

    getImgSrc(item){
      return item.filename ? item.path+'/'+item.filename : ''
    },

    notAfterToday (date) {
       return moment(date).format('YYYY-MM-DD') > moment(this.request.evtEdt).format('YYYY-MM-DD')
    },
    notBeforeToday (date) {
      return moment(date).format('YYYY-MM-DD') < moment(this.request.evtSdt).format('YYYY-MM-DD')
    },
    changedOk (file,idx) {
      let imgData = this.resultImgDataList[idx]
      imgData.filename = file.name
      imgData.path = file.path
    },
    chgSdt() {
      this.request.evtSdt = moment(this.request.evtSdt).format('YYYY-MM-DD')
    },
    // chgSt() {
    //   this.request.evtSdt = moment(this.request.evtSdt).format('YYYY-MM-DD')
    // },
    chgEdt() {
      this.request.evtEdt = moment(this.request.evtEdt).format('YYYY-MM-DD')
    },
    // chgEt() {
    //   this.request.evtEdt = moment(this.tmpEdt).set({"hour": moment(this.tmpEt).format('HH')}).format('YYYY-MM-DD HH:mm:ss')
    // },
    checkValid(){
      if(!this.request.evtName){
        this.alert("이벤트 명을 입력해주세요")
        return false
      }
      if(!this.request.evtSdt || !this.request.evtEdt){
        this.alert("게시기간을 입력해주세요")
        return false
      }

      if(this.request.evtSdt > this.request.evtEdt ){
        this.alert("시작일 종료일을 넘길 수 없습니다.")
        return false
      }

      if(this.resultImgDataList.length>0){
        if(!this.resultImgDataList[0].filename){
          this.alert("이미지가 존재해야 합니다.")
          return false
        }
      }else{
          this.alert("이미지가 존재해야 합니다.")
          return false
      }
      return true
    }
  },
}
</script>

<style scoped>

</style>
