<template>
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon" v-if="navTitle"><i class="pe-7s-drawer icon-gradient bg-happy-itmeo"></i></div>
      <div>
        <strong>{{ navTitle }}</strong>
        <div class="page-title-subheading">
          <template v-for="(nav, index) in navList">
            <template v-if="navList.length !== (index+1)">
              <!--
              <a href="javascript:void(0)" :key="index">{{ nav }}</a> &gt;
              -->
              {{ nav }} &gt;
            </template>
            <template v-else>
              <strong :key="index">{{ nav }}</strong>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavigationBar',
  data () {
    return {
    }
  },
  props: {
    navTitle: {
      type: String,
      default: () => {
        return ''
      }
    },
    navList: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
