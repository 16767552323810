var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "비주얼관리",
            "nav-list": ["맥케이슨관리", "비주얼관리", "기타 노출 관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "mb-0 table table-detail" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "text-left wd-200" }, [
                    _vm._v("스토어 동영상 URL (PC)")
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.pcVideoUrl,
                          expression: "pcVideoUrl",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder:
                          "홈화면 하단 동영상(PC) URL을 입력해주세요."
                      },
                      domProps: { value: _vm.pcVideoUrl },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.pcVideoUrl = $event.target.value.trim()
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _c("td", { staticClass: "text-center wd-100" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-100",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.callDisplayPop(_vm.pcVideoUrl)
                          }
                        }
                      },
                      [_vm._v("미리보기")]
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", { staticClass: "text-left wd-200" }, [
                    _vm._v("스토어 하단 동영상 URL (Mobile)")
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.mobileVideoUrl,
                          expression: "mobileVideoUrl",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder:
                          "홈화면 하단 동영상(Mobile) URL을 입력해주세요."
                      },
                      domProps: { value: _vm.mobileVideoUrl },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.mobileVideoUrl = $event.target.value.trim()
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _c("td", { staticClass: "text-center wd-100" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-100",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.callDisplayPop(_vm.mobileVideoUrl)
                          }
                        }
                      },
                      [_vm._v("미리보기")]
                    )
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
              _c("div", { staticClass: "float-left" }),
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success wd-100",
                    on: {
                      click: function($event) {
                        return _vm.onSave()
                      }
                    }
                  },
                  [_vm._v("저장")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }