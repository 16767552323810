
export default {
  name: 'code-mixin',
  filters: {
    genCodeKeyMap (value) {
      switch (value) {
        case 0: return '공용'
        case 1: return '남'
        case 2: return '여'
      }
    },
    isUseKeyMap (value) {
      switch (value) {
        case 0: return '미사용'
        case 1: return '사용'
      }
    },
    isSaleKeyMap (value) {
      switch (value) {
        case 1: return '판매중'
        case -1: return '판매중지'
      }
    },
    memberType (value) {
      switch (value) {
        case 1: return 'VIP'
        case 2: return '직원'
      }
    }
  }
}
