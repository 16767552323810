<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'프로모션관리'" :nav-list="['맥케이슨관리', '게시판관리', '프로모션관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">프로모션 정보</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group" v-if="isEdit">
              <label class="col-sm-2 col-form-label">고유번호</label>
              <div class="col-sm-10">
                <label class="col-form-label">{{ prmInfo.prmIdx }}</label>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>제목</label>
              <div class="col-sm-10">
                <input type="text" class="form-control d-inline-block w-75" placeholder="제목을 입력해주세요." v-model="prmInfo.prmName" maxlength="30" />
                <label class="ml-2">30자 이내 ({{ prmInfo.prmName.length }}/30)</label>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>진행 기간</label>
              <div class="col-sm-10">
                <date-picker v-model="tmpSdt" @change="chgSdt" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" :disabled-date="notAfterToday" />
                <date-picker v-model="tmpSt" @change="chgSt" class="wd-75 mr-1" :time-picker-options="getTimePickerOptions" format="HH" type="time" placeholder="HH" :editable="false" :clearable="false" />시
                <label class="col-form-label ml-2 mr-2">~</label>
                <date-picker v-model="tmpEdt" @change="chgEdt" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" :disabled-date="notBeforeToday" />
                <date-picker v-model="tmpEt"  @change="chgEt" class="wd-75 mr-1" :time-picker-options="getTimePickerOptions" format="HH" type="time" placeholder="HH" :editable="false" :clearable="false" />시
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>대표이미지</label>
              <div class="col-sm-10">
                <upload-photo :file="{src: getImagePath(imgSrc), w: prmInfo.prmImgInfo.width, h:prmInfo.prmImgInfo.height}" branch="promotion" @changed="changedOk" />
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>내용</label>
              <div class="col-sm-10">
                <html-editor :content="prmInfo.prmDesc" :branch="'promotion'" @change-content="updateContent"/>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>전시여부</label>
              <div class="col-sm-10">
                <div class="custom-radio custom-control custom-control-inline"><input type="radio" :checked="prmInfo.prmIsUse === 1" class="custom-control-input" value="1" v-model="prmInfo.prmIsUse" id="prmIsUse_yes"><label class="custom-control-label" for="prmIsUse_yes">전시</label></div>
                <div class="custom-radio custom-control custom-control-inline"><input type="radio" :checked="prmInfo.prmIsUse === 0" class="custom-control-input" value="0" v-model="prmInfo.prmIsUse" id="prmIsUse_no"><label class="custom-control-label" for="prmIsUse_no">비전시</label></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">프로모션 상품</div>
          <div class="table-responsive">
            <div class="card-body">
              <table class="mb-0 table table-detail">
                <thead>
                <tr>
                  <th><input type="checkbox" v-model="chkAll"/></th>
                  <th>순서 지정</th>
                  <th>상품 카테고리</th>
                  <th>대표이미지</th>
                  <th>상품번호</th>
                  <th>상품명</th>
                  <th>판매가</th>
                  <th>판매여부</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!prmInfo.prmPrdList.length">
                  <td colspan="8" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(product, idx) in prmInfo.prmPrdList" v-else>
                  <td class="text-center"><input type="checkbox" v-model="chkList" :value="product" /></td>
                  <td class="text-center">
                    <select class="form-control" v-model="product.order" @change="changeOrder(idx, $event.target.value)">
                      <option v-for="i in prmInfo.prmPrdList.length" :value="i">{{i}}</option>
                    </select>
                  </td>
                  <td class="text-center">{{product.cat1Name}} > {{product.cat2Name}}</td>
                  <td class="text-center"><img class="images" :src="getImagePath(product.prdMainImgThumb1)" style="width: 100px; height: 100px" v-image-error v-viewer /></td>
                  <td class="text-center">{{ product.prdNo }}</td>
                  <td class="text-center">{{ product.prdName }}</td>
                  <td class="text-center">{{ product.prdSalePrice | comma }}원</td>
                  <td class="text-center">{{ product.prdSaleStatus | isSaleCode }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="callProdSearchPop(null)">상품검색</button>
              </div>
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">선택 삭제</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="isEdit" @click="onUpdate">수정</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="!isEdit" @click="onCreate">등록</button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">등록일</th>
                  <td class="w-35"><input type="text" class="form-control" disabled :value="dateFormat(prmInfo.regiPersonData.rgdt, 'YYYY-MM-DD HH:mm')"></td>
                  <th class="w-15">작성자</th>
                  <td class="w-35"><input type="text" class="form-control" disabled :value="prmInfo.regiPersonData.rgAdmId"></td>
                </tr>
                <tr>
                  <th class="w-15">수정일</th>
                  <td class="w-35"><input type="text" class="form-control" disabled :value="dateFormat(prmInfo.regiPersonData.updt, 'YYYY-MM-DD HH:mm')"></td>
                  <th class="w-15">수정자</th>
                  <td class="w-35"><input type="text" class="form-control" disabled :value="prmInfo.regiPersonData.upAdmId"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import HtmlEditor from '@/components/common/HtmlEditor'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import moment from 'moment'
import SrchProductPop from "@/page/product/components/SrchProductPop"
import CodeMixin from "@/mixins/code-mixin";
import UploadPhoto from '@/components/common/UploadPhoto'

export default {
  name: "PromRegistration",
  data () {
    return {
      isEdit: false,
      prmInfo: {
        prmIdx: '',     // 고유번호
        prmName: '',    // 제목
        prmSdt: '',     // 진행시작일시
        prmEdt: '',     // 진행종료일시
        prmDesc: '',    // 내용[에디터]
        prmIsUse: 1,   // 전시여부
        prmPrdList: [],  // 상품목록
        prmImgInfo: {   // 대표이미지
          path: '',     //  - 경로
          filename: '', //  - 원본파일명
          thumb1: ''    //  - 섬네일명
        },
        regiPersonData: {
          rgdt: '',
          updt: ''
        }
      },
      tmpSdt: '',
      tmpEdt: '',
      tmpSt: '',
      tmpEt: '',
      newImg: '',
      chkList: [],
      datas: [],
      search: ''
    }
  },
  components: {
    UploadPhoto,
    NavigationBar,
    HtmlEditor,
    DatePicker,
  },
  mixins: [
    CommonMixin,
    InterfaceMixin,
    CodeMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.prmInfo.prmPrdList.length ? this.chkList.length === this.prmInfo.prmPrdList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.prmInfo.prmPrdList.map(item => {
            selected.push(item)
          })
        }
        this.chkList = selected
      }
    },
    imgSrc() {
      if(this.prmInfo.prmImgInfo.filename) {
        return this.prmInfo.prmImgInfo.path+'/'+this.prmInfo.prmImgInfo.filename
      }
    },
    getTimePickerOptions () {
      return {
        start: '00:00',
        step: '01:00',
        end: '23:00'
      }
    }
  },
  created () {
    console.log('[MCKS] PromRegistration::: created::: ', this.$route.query.prmIdx)
    let prmIdx = this.$route.query.prmIdx
    if (prmIdx) {
      this.isEdit = true
      this.prmInfo.prmIdx = prmIdx
      this.getDetail()
    }
    let search = this.$route.query.search
    this.search = search

  },
  mounted () {
  },
  methods: {
    _isInvalidateInputForm () {
      if (!this.prmInfo.prmName) {
        this.alert('제목을 입력해주세요.')
        return false
      }
      if (this.prmInfo.prmName.length > 30) {
        this.alert('제목은 최대 30자리 이상 입력할 수 없습니다.')
        return false
      }
      if (!this.tmpSdt) {
        this.alert('시작일자를 입력해주세요.')
        return false
      }
      if (!this.tmpSt) {
        this.alert('시작시간을 입력해주세요.')
        return false
      }
      if (!this.tmpEdt) {
        this.alert('종료일자를 입력해주세요.')
        return false
      }
      if (!this.tmpEt) {
        this.alert('종료시간을 입력해주세요.')
        return false
      }
      if (this.prmInfo.prmSdt >= this.prmInfo.prmEdt) {
        this.alert('시작일보다 종료일이 빠릅니다. 다시 입력해주세요.')
        return false
      }
      if (!this.prmInfo.prmDesc) {
        this.alert('내용을 입력해주세요.')
        return false
      }
      if (!this.prmInfo.prmImgInfo.filename) {
        this.alert('대표이미지를 등록해주세요.')
        return false
      }
      if (!this.prmInfo.prmDesc) {
        this.alert('내용을 입력해주세요.')
        return false
      }
      if (!this.prmInfo.prmDesc) {
        this.alert('내용은 최소 1자리 이상 입력해주세요.')
        return false
      }
      return true
    },
    getDetail () {
      let params = {
        prmIdx: this.prmInfo.prmIdx
      }
      this.$api.api.getPromotionDetail(params).then(resp => {
        if (resp.data.result) {
          this.prmInfo = resp.data.data

          this.prmInfo.prmPrdList = resp.data.data.prmPrdList
            .map( el => {
            return {
              order: el.orderSeq,
              prdNo: el.prdNo,
              prdName: el.prdName,
              prdSalePrice: el.prdSalePrice,
              prdSaleStatus: el.prdSaleStatus,
              prdIdx: el.prdIdx,
              cat1Name: el.cat1Name,
              cat2Name: el.cat2Name,
              prdMainImgThumb1: el.path+'/'+el.filename
            }
          })

          this.tmpSdt = !this.prmInfo.prmSdt? null : moment(this.prmInfo.prmSdt).format('YYYY-MM-DD')
          this.tmpSt = !this.prmInfo.prmSdt? null :new Date(this.prmInfo.prmSdt)
          this.tmpEdt =!this.prmInfo.prmEdt? null : moment(this.prmInfo.prmEdt).format('YYYY-MM-DD')
          this.tmpEt = !this.prmInfo.prmEdt? null : new Date(this.prmInfo.prmEdt)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    updateContent (value) {
      this.prmInfo.prmDesc = value
    },
    onList () {
      this.$router.push({path: '/promotion/list', query: {search: this.search}})
    },
    onCreate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 프로모션 등록 실패 입력 FORM 오류')
        return
      }

      this.confirm('등록 하시겠습니까?', () => {
        let reqBody = this.getItems()

        this.$api.api.registerPromotion(reqBody).then(resp => {
          if (resp.data.result) {
            this.alert('등록되었습니다.', () => {
              this.$router.go(-1)
            })
          } else {
            this.alert('등록 실패')
          }
        })
      })
    },
    onUpdate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 프로모션 수정 실패 입력 FORM 오류')
        return
      }

      this.confirm('수정 하시겠습니까?', () => {
        let reqBody = this.getItems()
        this.$api.api.updatePromotion(reqBody).then(resp => {
          if(resp.data.result) {
            this.alert('수정되었습니다.', () => {
              this.$router.go(-1)
            })
          } else {
          this.alert('수정 실패')
          }
        })
      })
    },
    getItems () {
      if (this.newImg) {
        this.prmInfo.prmImgInfo.filename = this.newImg
      } else {
        this.prmInfo.prmImgInfo.filename = this.imgSrc
      }

      let reqParam = {
        fileName: this.prmInfo.prmImgInfo.filename,
        prmDesc: this.prmInfo.prmDesc,
        prmEdt: !this.tmpEdt ? null : this.prmInfo.prmEdt,
        prmSdt: !this.tmpSdt ? null : this.prmInfo.prmSdt,
        prmIdx: this.prmInfo.prmIdx,
        prmIsUse: this.prmInfo.prmIsUse,
        prmName: this.prmInfo.prmName,
        productList: []
      }
      reqParam.productList = this.prmInfo.prmPrdList.map(el => {
         return {
          orderSeq: el.order,
          prdIdx: el.prdIdx
        }
      })
      return reqParam
    },

    changeOrder(index, order) {
      Array.prototype.swap = function (i, k) {
        let tmp = '';
        tmp = this[i]
        this[i] = this[k]
        this[k] = tmp
      }

      let arr = this.prmInfo.prmPrdList
      arr.swap(index, order - 1)

      arr.map((el, i) => el.order = i + 1)

      this.prmInfo.prmPrdList = []
      arr.map(el => {
        this.prmInfo.prmPrdList.push(el)
      })
    },
    upload (callback) {
      let fm = new FormData();
      fm.append('imageFiles', this.image)
      this.$api.api.uploadFile(fm, 'promotion').then(resp => {
        if (resp.data.result) {
          this.form.fileName = resp.data.data[0].filename
          this.form.thumbName = resp.data.data[0].thumb1
          return callback()
        } else {
          this.alert('파일 업로드 실패')
          return
        }
      })
    },
    notAfterToday (date) {
       return moment(date).format('YYYY-MM-DD') > moment(this.tmpEdt).format('YYYY-MM-DD')
    },
    notBeforeToday (date) {
      return moment(date).format('YYYY-MM-DD') < moment(this.tmpSdt).format('YYYY-MM-DD')
    },
    callProdSearchPop(cnt) {
      this.$modal.show(
          SrchProductPop,
          {quantityLimit: cnt},
          {draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200},
          {
            'before-close': event => {
              event.params.map(
                el => {
                  this.prmInfo.prmPrdList.map((item, i) => {
                    if (item.prdIdx === el.prdIdx) {
                      this.prmInfo.prmPrdList.splice(i, 1)
                    }
                  })
                  this.prmInfo.prmPrdList.push(el)
                }
              )
              this.ordering()
            }
          }
      )
    },
    onDelete() {
      if (this.chkList.length <= 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }

      this.confirm('삭제 하시겠습니까?', () => {
        this.chkList.map(tg => {
          this.prmInfo.prmPrdList.map((el, i) => {
            if( tg.prdIdx == el.prdIdx) {
              this.prmInfo.prmPrdList.splice(i,  1)
            }
          })
        })
        this.ordering()
      })
    },
    ordering() {
      this.prmInfo.prmPrdList.map ((el, i) => {
        el.order = i+1
      })
    },
    changedOk (file) {
      this.newImg = file.name
      this.prmInfo.prmImgInfo.filename = file.name
      this.prmInfo.prmImgInfo.path = file.path
      this.prmInfo.prmImgInfo.width = file.w
      this.prmInfo.prmImgInfo.height = file.h
    },
    chgSdt() {
      this.prmInfo.prmSdt = moment(this.tmpSdt).set(
          {'hour': moment(
                    this.tmpSt ? this.tmpSt : new Date()
                        .setHours(0,0,0,0))
                    .format('HH')}).format('YYYY-MM-DD HH:mm:ss')
    },
    chgSt() {
      this.prmInfo.prmSdt = moment(this.tmpSdt).set({"hour": moment(this.tmpSt).format('HH')}).format('YYYY-MM-DD HH:mm:ss')
    },
    chgEdt() {
      this.prmInfo.prmEdt = moment(this.tmpEdt).set(
          {'hour': moment(
                this.tmpEt ? this.tmpEt : new Date()
                    .setHours(0,0,0,0))
                .format('HH')}).format('YYYY-MM-DD HH:mm:ss')
    },
    chgEt() {
      this.prmInfo.prmEdt = moment(this.tmpEdt).set({"hour": moment(this.tmpEt).format('HH')}).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  // watch: {
  //   tmpSdt (value) {
  //     this.prmInfo.prmSdt = moment(new Date(value).setHours(moment(this.prmSdt).format('HH'))).format('YYYY-MM-DD HH:mm:ss')
  //   },
  //   tmpSt (value) {
  //     this.prmInfo.prmSdt = moment(new Date(this.prmInfo.prmSdt).setHours(moment(value).format('HH'))).format('YYYY-MM-DD HH:mm:ss')
  //   },
  //   tmpEdt (value) {
  //     this.prmInfo.prmEdt = moment(new Date(value).setHours(moment(this.tmpEdt).format('HH'))).format('YYYY-MM-DD HH:mm:ss')
  //   },
  //   tmpEt (value) {
  //     this.prmInfo.prmEdt = moment(new Date(this.prmInfo.prmEdt).setHours(moment(value).format('HH'))).format('YYYY-MM-DD HH:mm:ss')
  //   }
  // }
}
</script>

<style scoped>

</style>
