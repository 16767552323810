<template>











  <ul class="pagination" v-if="totalPages" :class="className">
    <li class="page-item" @click="prevChunk" :class="allowedChunk(-1) ? '' : 'disabled'"><a href="javascript:void(0);" class="page-link" aria-label="First"><span aria-hidden="true"> &lt;&lt; </span><span class="sr-only">처음</span></a></li>
    <li class="page-item" @click="prev" :class="allowedPage(page-1) ? '' : 'disabled'"><a href="javascript:void(0);" class="page-link" aria-label="Previous"><span aria-hidden="true"> &lt; </span><span class="sr-only">이전</span></a></li>
    <li class="page-item" @click="setPage(page)" v-for="page in pages" :class="isActive(page) ? 'active' : ''" :key="page"><a href="javascript:void(0);" class="page-link">{{ page }}</a></li>
    <li class="page-item" @click="next" :class="allowedPage(page+1) ? '' : 'disabled'"><a href="javascript:void(0);" class="page-link" aria-label="Next"><span aria-hidden="true"> &gt; </span><span class="sr-only">다음</span></a></li>
    <li class="page-item" @click=nextChunk :class="allowedChunk(1) ? '' : 'disabled'"><a href="javascript:void(0);" class="page-link" aria-label="Last"><span aria-hidden="true"> &gt;&gt; </span><span class="sr-only">끝</span></a></li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  data () {
    return {
      page: 1
    }
  },
  props: {
    className: {
      type: String,
      default: () => {
        return ''
      }
    },
    for: {
      type: String,
      required: true
    },
    records: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 25
    },
    curPage: {
      type: Number
    },
    chunk: {
      type: Number,
      required: false,
      default: 10
    },
    pagenum: {
      type: Number,
      required: false,
      default: 1
    }
  },
  computed: {
    pages: function () {
      if (!this.records) return []
      return this.range(this.paginationStart, this.pagesInCurrentChunk)
    },
    totalPages: function () {
      return this.records ? Math.ceil(this.records / this.perPage) : 1
    },
    totalChunks: function () {
      return Math.ceil(this.totalPages / this.chunk)
    },
    currentChunk: function () {
      return Math.ceil(this.page / this.chunk)
    },
    paginationStart: function () {
      return ((this.currentChunk - 1) * this.chunk) + 1
    },
    pagesInCurrentChunk: function () {
      return this.paginationStart + this.chunk <= this.totalPages ? this.chunk : this.totalPages - this.paginationStart + 1
    }
  },
  methods: {
    setPage: function (page) {
      if (this.allowedPage(page)) {
        this.page = page
        this.$emit('vue-pagination::' + this.for, page)
        return true
      }
      return false
    },
    next: function () {
      return this.setPage(this.page + 1)
    },
    prev: function () {
      return this.setPage(this.page - 1)
    },
    nextChunk: function () {
      return this.setChunk(1)
    },
    prevChunk: function () {
      return this.setChunk(-1)
    },
    setChunk: function (direction) {
      this.setPage((((this.currentChunk - 1) + direction) * this.chunk) + 1)
    },
    allowedPage: function (page) {
      return page >= 1 && page <= this.totalPages
    },
    allowedChunk: function (direction) {
      return (direction === 1 && this.currentChunk < this.totalChunks) || (direction === -1 && this.currentChunk > 1)
    },
    isActive: function (page) {
      return this.page === page
    },
    range: function (start, count) {
      return Array.apply(0, Array(count))
        .map(function (element, index) {
          return index + start
        })
    }
  },
  watch: {
    curPage: function () {
      this.setPage(this.curPage)
    },
    pagenum: function (n) {
      this.page = n
    }
  }
}
</script>

<style scoped>
</style>
