<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">색상 및 사이즈 구분 선택</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body mb-0 pb-0 ">
          <h5 class="card-title float-left"><span class="required">*</span>색상 선택</h5>
          <div style="max-height: 650px; overflow-y: scroll; width: 100%;">
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>
              <th>색상코드</th>
              <th>색상</th>
              <th>색상명</th>
              <th>약식 색상코드</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!colors.length">
              <td colspan="5" class="text-center">내역이 존재하지 않습니다.</td>
            </tr>
            <tr v-else v-for="(color, idx) in colors" :key="idx">
              <td class="text-center"><input type="checkbox" data-toggle="toggle" :value="color" data-onstyle="success"
                                             :id="color.colIdx" v-model="selectedColorList" @click="selectAllItem"></td>
              <td class="text-center">{{ color.colCode }}</td>
              <td class="text-center"><p style="width: 37px; height: 37px" :style="{'background-color':  color.colCode }">&nbsp;</p></td>
              <td class="text-center">{{ color.colName }}</td>
              <td class="text-center">{{ color.colShortCode }}</td>
            </tr>
            </tbody>
          </table>
          </div>
          <div class="pt-3 pb-3">
            <h5 class="card-title d-inline pr-2"><span class="required">*</span>사이즈구분 선택</h5>
            <select class="form-control d-inline w-25 pr-2" @change="showSizeList()" v-model="selectedSizeClass" :disabled="selectedSizeClassIdx > 0">
              <option value=''>구분을 선택해주세요</option>
              <option v-for="(sizeClass, idx) in this.sizeClasses" :value="sizeClass" :key="idx" >{{sizeClass.genCode | genCodeKeyMap(sizeClass.genCode)}}_{{ sizeClass.claName }}</option>
            </select>
            <h6 class="d-inline pl-4" style="color: red">{{this.sizeList.toString()}}</h6>
          </div>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="text-center">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100 ml-1" @click="complete">선택적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeMixin from '@/mixins/code-mixin'
import InterfaceMaxin from "@/mixins/userInterface-mixin";

export default {
  name: "srch-color-size-pop",
  mixins: [CodeMixin, InterfaceMaxin],
  created() {
    // 색상 조회
    this.getColorList();

    // 사이즈 구분 조회
    this.getSizeClassList();
  },
  data() {
    return {
      colors: [],
      sizeClasses: [],
      selectedColorList: [],
      allSelected: false,
      selectedSizeClass: '',
      sizeList: [],

    }
  },
  props: {
    selectedSizeClassIdx: {
      type: Number
    }
  },
  methods: {
    getSelectedSizeClass () {
      for(let sizeClass of this.sizeClasses) {
        if(sizeClass.sizeClaIdx == this.selectedSizeClassIdx) {
          this.selectedSizeClass = sizeClass;
        } else continue;
      }
    },
    getColorList: function () {
      this.$api.api.getColorList().then(resp => {
        if (resp.data.result) {
          this.colors = resp.data.data.data;

        }
      })
    },

    getSizeClassList: function () {
      this.$api.api.getSizeList().then(resp => {
        if (resp.data.result) {
          this.sizeClasses = resp.data.data.data;

          if(this.selectedSizeClassIdx) {
            this.getSelectedSizeClass();
            this.showSizeList();
          }
        }
      })
    },
    showSizeList: function () {
      this.sizeList = [];

      let sizes = this.selectedSizeClass.sizeList;
      for(let size of sizes) {
        this.sizeList.push(size.size);
      }
    },
    selectAllItem: function () {
      this.allSelected = false
    },
    checkedAll: function () {
      this.selectedColorList = []
      if (!this.allSelected) {
        for (let i = 0; i < this.colors.length; i++) {
          this.selectedColorList.push(this.colors[i])
        }
      }
    },

    complete () {
      if (!this.selectedColorList.length) {
        this.alert('색상을 선택해주세요.')
        return
      }

      if (!this.selectedSizeClass) {
        this.alert('사이즈구분을 선택해주세요.')
        return
      }

      let _this = this;
      let optionList = this.selectedColorList.map(function (obj, index) {
        let colorOption =  {
          colCode: obj.colCode,
          colName: obj.colName,
          colShortCode: obj.colShortCode,
          colIdx: obj.colIdx,
          optOrder: index + 1,
          opt2List: [],
          imgList: [],
          imgArrSize: 1,
          img0: {},
          img1: {},
          img2: {},
          img3: {},
          img4: {},
          img5: {},
          img6: {},
          img7: {},
          img8: {},
        }

        _this.selectedSizeClass.sizeList.forEach(e => {
          colorOption.opt2List.push({
            optStock: 999,
            optIsUse: 1,
            sizeClaIdx: _this.selectedSizeClass.sizeClaIdx,
            sizeIdx: e.sizeIdx,
            size: e.size
          })
        })

        colorOption.rowspan = colorOption.opt2List.length

        return colorOption;
      })

      this.$emit('close', {
        optionList: optionList
      })
    },

    onCancle: function () {
      this.$emit('close')
    },
  }
}
</script>

<style scoped>
  .text-center.center {
    vertical-align: middle;
    width: 10%;
  }
</style>
