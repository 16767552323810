import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    redirect: '/',
    children: [
        {
            path: '/collection/list',
            name: 'CollectionList',
            component: () => import('@/page/collection/CollectionList')
        },
        {
            path: '/collection/:params?',
            // path: '/collection/type/:params?',
            name: 'CollectionType',
            props: (route) => ({ query: route.query }),
            component: () => import('@/page/collection/CollectionType')
        },
    ]
}
