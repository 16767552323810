<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <h5 class="card-title">주문번호 : {{ payInfo.ordNo }}</h5>
          <table class="mb-0 table table-detail">
            <tbody>
              <tr>
                <td class="text-center">상품 금액 합</td>
                <td class="text-right pr-4">{{ payInfo.prdAmount | comma }}원</td>
              </tr>
              <tr>
                <td class="text-center">기본배송비</td>
                <td class="text-right pr-4">{{ payInfo.deliFee | comma }}원</td>
              </tr>
              <tr>
                <td class="text-center">도사산간 배송비</td>
                <td class="text-right pr-4">{{ payInfo.deliAddfee | comma }}원</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-center">결제금액</th>
                <th class="text-right pr-4">{{ payInfo.payAmount | comma }}원</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-success wd-100" @click="complete">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'

export default {
  name: 'PaymentPopup',
  data () {
    return {
      isOpen: false,
      payInfo: {}
    }
  },
  components: {
  },
  mixins: [
    CommonMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    ordIdx: {
      type: Number,
      default: () => {
        return ''
      }
    }
  },
  created () {
    console.log('[MCKS] PaymentPop::: ordIdx=', this.ordIdx)
    this.getPayDetail()
  },
  mounted () {
  },
  methods: {
    complete (result) {
      this.$emit('close', result)
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    getPayDetail () {
      let params = {
        ordIdx: this.ordIdx
      }
      return this.$api.api.getPayInfo(params).then(resp => {
        console.log(resp.data)
        if (resp.data.result) {
          this.payInfo = resp.data.data
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}

</script>
