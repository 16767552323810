<template>
  <div class="error_page_wrapper">
    <div class="error_wrap">
      <div class="container text-center">
        <div class="error_heading text-center">
          <h3 class="headline font-danger">401</h3>
        </div>
        <h3 class="text-uppercase error-subtitle">Unauthorized !</h3>
        <div class="col-md-8 offset-md-2 text-center">
          <p>세션이 만료되었거나 유효하지 않은 요청입니다.</p>
          <p>다시 로그인 하시기바랍니다.</p>
        </div>
        <div class="error_btn text-center">
          <a class="btn btn-danger" href="javascript:void(0)" @click="$router.push('/login')">Back Home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error401',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
