<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">상품검색</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div class="card-body pt-2">
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th class="text-center center">검색</th>
              <td class="text-center">
                <select class="form-control  d-inline w-25" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
                <input type="text" class="form-control d-inline w-75" placeholder="Search..." v-model="search.srchKeyword" @keyup.enter="onSearch"/>
              </td>
              <td colspan="2">
                <button class="btn btn-primary d-inline mr-2" @click="onSearch">검색</button>
                <button class="btn btn-light d-inline" @click="init">초기화</button>
              </td>
            </tr>
            </thead>
          </table>
        </div>
        <div class="card-body mb-0 pb-0">
          <h5 class="card-title float-left">접수 : {{totalCnt}}건</h5>
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th></th>
              <th>번호</th>
              <th>회원명</th>
              <th>회원ID</th>
              <th>이메일</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!resultDataList.length">
              <td colspan="8" class="text-center">내역이 존재하지 않습니다.</td>
            </tr>
            <tr v-for="(item, idx) in resultDataList" :key="idx" v-else>
              <td class="text-center"><input type="radio" class="custom-radio" :value="item" v-model="clientInfo" name="choose"></td>
              <td class="text-center">{{ item.cliIdx }}</td>
              <td class="text-center">{{ item.userName }}</td>
              <td class="text-center">{{ item.id}}</td>
              <td class="text-center">{{ item.email }}</td>
            </tr>
            </tbody>
          </table>
          <div class="text-center mt-2 mb-3">
            <pagination :per-page="search.srchPageCnt" :pagenum="search.srchPage" :records="totalCnt" for="datas" v-on:vue-pagination::resultDataList="onPage" v-show="resultDataList.length"></pagination>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="text-center">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100 ml-1" @click="onComplete">적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Pagination from "@/page/components/Pagination";
import CodeMixin from '@/mixins/code-mixin';
import {CommonMixin} from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import Pagination from "../../components/Pagination"
export default {
  name: "SrchClientPopup",
  components: {
    Pagination
  },
  mixins: [
    InterfaceMixin, CodeMixin, CommonMixin
  ],
  data () {
    return {
      srchTypeList: [
        { value: 1, text: '회원ID' },
        { value: 2, text: '회원명' },
      ],


      search: {
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        cliStatus: ''
      },
      clientInfo : {},
      totalCnt: 0,
      resultDataList : []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.onSearch()
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    onSearch () {
      this.$api.api.getClientList(this.search).then(res => {
        if(res.data.result){
          this.resultDataList = res.data.data
          this.totalCnt = res.data.totalCnt
        }
      })
    },
    onComplete () {
      console.log("this.clientInfo"+ JSON.stringify(this.clientInfo))
      this.$emit('close', this.clientInfo)
    },
    onCancle () {
      this.$emit('close')
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
  }
}
</script>

<style scoped>
  .text-center.center {
    vertical-align: middle;
    width: 10%;
  }
</style>
