import DefaultLayout from '@/layout/DefaultLayout'

export default {
  path: '/',
  component: DefaultLayout,
  children: [
    {
      path: '/display/list',
      name: 'displayList',
      component: () => import(/* webpackChunkName: 'Visual' */ '@/page/visual/display/DisplayList')
    },
    {
      path: '/home/list',
      name: 'homeList',
      component: () => import(/* webpackChunkName: 'Visual' */ '@/page/visual/home/HomeList')
    },
    {
      path: '/home/general_add',
      name: 'homeGeneralRegistration',
      component: () => import(/* webpackChunkName: 'Visual' */ '@/page/visual/home/HomeGeneralRegistration')
    }
  ]
}
