<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'취소관리'" :nav-list="['맥케이슨관리', '주문관리', '취소관리']" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 12, unit: 'M'}" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">접수처</label>
                  <select class="form-control" v-model="search.clmWorker">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPlaceList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">취소사유</label>
                  <select class="form-control" v-model="search.clmCd">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchReasonList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">결제수단</label>
                  <select class="form-control" v-model="search.payKind">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPayKindList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">결제상태</label>
                  <select class="form-control" v-model="search.ordStatus">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPayStateList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">환불수단</label>
                  <select class="form-control" v-model="search.refundMethod">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchRefundMethodList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">환불상태</label>
                  <select class="form-control" v-model="search.refundStatus">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchRefundStateList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-150 ml-1 mr-1" @click="exportExcel">엑셀다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">NO</th>
                  <th class="text-center">접수일<br/>/접수처</th>
                  <th class="text-center">취소 완료일</th>
                  <th class="text-center">회원명</th>
                  <th class="text-center">회원ID</th>
                  <th class="text-center">주문번호</th>
                  <th class="text-center">클레임번호</th>
                  <th class="text-center">주문자</th>
                  <th class="text-center">휴대폰번호</th>
                  <th class="text-center">상품번호</th>
                  <th class="text-center">상품명</th>
                  <th class="text-center">취소수량</th>
                  <th class="text-center">판매가</th>
                  <th class="text-center">상품금액</th>
                  <th class="text-center">배송비</th>
                  <th class="text-center">취소사유</th>
                  <th class="text-center">결제수단</th>
                  <th class="text-center">결제상태</th>
                  <th class="text-center">환불수단</th>
                  <th class="text-center">환불예정금액</th>
                  <th class="text-center">환불정보</th>
                  <th class="text-center">환불상태</th>
                  <th class="text-center">환불예정일<br/>/환불완료일</th>
                  <th class="text-center">메모</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!itemList.length">
                  <td colspan="22" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                  <td class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</td>
                  <td class="text-center">{{ dateFormat(data.reqDate, 'YYYY.MM.DD HH:mm') }}<br/>/{{data.clmWorker}}</td>
                  <td class="text-center">{{ dateFormat(data.cplDate, 'YYYY.MM.DD HH:mm') }}</td>
                  <td class="text-center">{{ data.cliName }}</td>
                  <td class="text-center">{{ data.id }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callOrderDetailPop(data.ordIdx)">{{ data.ordNo }}</a></td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callClaimDetailPop(data.ordIdx, data.clmIdx, data.clmNo)">{{data.clmNo}}</a></td>
                  <td class="text-center">{{ data.userName }}</td>
                  <td class="text-center">{{ data.userPhone | dash }}</td>
                  <td class="text-center">{{ data.prdNo }}</td>
                  <td class="text-left">{{ data.ordProduct }}/{{data.opoColName}}/{{data.opoSize}}</td>
                  <td class="text-center">{{ data.cpoQuantity}}</td>
                  <td class="text-center">{{ data.opoOptPriceSale | comma}}원</td>
                  <td class="text-center" :style="data.memIdx? 'color:#B40404': ''">{{ data.opoOnlyPrdAmount | comma }}원</td>
                  <td class="text-center">{{ data.deliFee | comma }}원</td>
                  <td class="text-center">{{ data.clmCdStr }}</td>
                  <td class="text-center">{{ data.payKindStr }}</td>
                  <td class="text-center">{{ data.ordStatusStr}}</td>
                  <td class="text-center">{{ data.refundMethodStr }}</td>
                  <td class="text-center">{{ data.expectRefundAmount | comma }}원</td>
                  <td class="text-center">
                    <span v-if="data.refundBankMaster">{{data.refundBankMaster}}/</span>
                    <span v-if="data.refundBankName">{{data.refundBankName}}/</span>
                    <span v-if="data.refundBankNo">{{data.refundBankNo}}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="text-center">{{ data.refundStatusStr }}</td>
                  <td class="text-center">{{ dateFormat(data.expectRefundDate, 'YYYY.MM.DD HH:mm')}}<br/>/{{ dateFormat(data.refundDate, 'YYYY.MM.DD HH:mm') }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(data.ordIdx, data.ordNo)"><i class="pe-7s-comment pr-2"></i>{{ data.memoCnt | comma }}</a></td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-if="itemList.length>0"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import DatePickerButton from '@/components/common/DatePickerButton'
import OrderDetailPop from '@/page/order/components/OrderDetailPop'
import ClaimDetailPop from '@/page/order/components/ClaimDetailPop'
import MemoPop from '@/page/order/components/MemoPop'
import moment from 'moment'

export default {
  name: 'CancelList',
  data () {
    return {
      search: {
        clmCd: '',
        clmWorker: '',
        ordStatus: '',
        payKind: '',
        refundMethod: '',
        refundStatus: '',
        srchDateEnd: '',
        srchDateStart: '',
        srchKeyword: '',
        srchType: '',
        srchPage: 1,
        srchPageCnt: 10
      },
      srchPlaceList: [
        { value: 'PC', text: 'PC'},
        { value: 'MC', text: 'MC'},
        { value: 'CS', text: 'CS'}
      ],
      srchReasonList: [
        { value: 2, text: '단순변심'},
        { value: 1, text: '구매의사없음'},
        { value: 3, text: '주문을 잘못함'},
        { value: 4, text: '품절취소'}
      ],
      srchPayKindList: [
        { value: 1, text: '신용카드'},
        { value: 2, text: '가상계좌'},
        { value: 3, text: '휴대폰'}
      ],
      srchPayStateList: [
        { value: 2, text: '입금대기'},
        { value: 7, text: '입금대기취소'},
        { value: 3, text: '결제완료'}
      ],
      srchRefundMethodList: [
        { value: 1, text: '결제취소'},
        { value: 2, text: '현금환불'}
      ],
      srchRefundStateList: [
        { value: 1, text: '환불전'},
        { value: 2, text: '환불완료'}
      ],
      srchTypeList: [
        { value: 1, text: '주문번호' },
        { value: 2, text: '클레임번호' },
        { value: 3, text: '주문자명' },
        { value: 4, text: '휴대폰번호' },
        { value: 5, text: '상품번호' },
        { value: 6, text: '상품명' },
        { value: 7, text: '예금주' },
        { value: 8, text: '은행명' },
        { value: 9, text: '계좌번호' },
        { value: 10, text: '회원명' },
        { value: 11, text: '회원ID' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' },
        { value: 100, text: '100개' }
      ],
      totalCnt: 0,
      itemList: []
    }
  },
  components: {
    NavigationBar,
    DatePickerButton,
    Pagination,
    OrderDetailPop,
    ClaimDetailPop,
    MemoPop
  },
  mixins: [
    CommonMixin
  ],
  computed: {
  },
  created () {
    this.$bus.$on('memoUpdate', value => {
      this.itemList.map((element, idx) => {
        if(element.ordIdx == value.ordIdx) {
          this.itemList[idx].memoCnt = value.amount
        }
      })
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.onReset()
      this.onSearch()
    },
    onReset () {
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      this.search.clmCd = ''
      this.search.clmWorker = ''
      this.search.ordStatus = ''
      this.search.payKind = ''
      this.search.refundMethod = ''
      this.search.refundStatus = ''
      this.search.srchKeyword = ''
      //this.search.srchPage = 1
      //this.search.srchPageCnt = 10
      this.search.srchType = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getItemList () {
      return this.$api.api.getCancelList(this.search).then(resp => {
        if (resp.data.result) {
          this.itemList = resp.data.data.admOrderCancels
          this.totalCnt = resp.data.data.seachCount
        } else {
          this.alert('취소관리 목록 조회 실패')
        }

      }).catch(error => {
        console.log(error)
      })
    },
    goDetail (ordIdx) {
      this.$router.push({path: '/delivery/detail', query: { ordIdx: ordIdx }})
    },
    exportExcel () {
      let params = {
        clmType: this.search.clmType,     // 시작일
        clmWorker: this.search.clmWorker,         // 종료일
        payKind: this.search.payKind,   // 유입경로
        refundMethod: this.search.refundMethod,             // 결제수단
        refundStatus: this.search.refundStatus,         // 결제상태
        srchDateEnd: this.search.srchDateEnd,               // 검색조건
        srchDateStart: this.search.srchDateStart,         // 검색어
        srchKeyword: this.search.srchKeyword,               // 불필요
        srchPage: this.search.srchPage,          // 불필요
        srchPageCnt: this.search.srchPageCnt,          // 불필요
        srchType: this.search.srchType          // 불필요
      }

      return this.$api.api.exportCancelList(params).then(resp => {
        if (resp.status === 200) {
          this.$toast.success('엑셀 다운로드 성공')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    callOrderDetailPop (ordIdx) {
      this.$modal.show(
          OrderDetailPop,
          { text: '주문정보', ordIdx: ordIdx },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event.params)
            }
          }
      )
    },
    callClaimDetailPop (ordIdx, clmIdx, clmNo) {
      this.$modal.show(
          ClaimDetailPop,
          { text: '취소', ordIdx: Number(ordIdx), clmIdx: clmIdx, clmNo: clmNo, claimType: 'cancel'},
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
            }
          }
      )
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, height: 'auto' },
        {
          'before-close': event => {
            this.getItemList()
          }
        }
      )
    },
    setDateStart (value) {
      this.search.srchDateStart = value
    },
    setDateEnd (value) {
      this.search.srchDateEnd = value
    }
  }
}
</script>

<style scoped>

</style>
