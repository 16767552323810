const prefixStr = '_SS_MC_'
const makeKey = key => `${prefixStr}${key}`
export default {
  setItem (key, value) {
    let val = typeof (value) !== 'string' ? JSON.stringify(value) : value
    window.localStorage.setItem(makeKey(key), val)
  },
  getItem (key, jsonParse = false) {
    return jsonParse ? JSON.parse(localStorage.getItem(makeKey(key))) : localStorage.getItem(makeKey(key))
  },
  removeItem (key) {
    localStorage.removeItem(makeKey(key))
  },
  setSesItem (key, val) {
    sessionStorage.setItem(key, val)
  },
  getSesItem (key) {
    return sessionStorage.getItem(key)
  },
  getLocalStorageData (key, param) {
    let obj = localStorage.getItem(key)
    obj = JSON.parse(obj)
    return obj ? (param ? obj[param] : obj) : {}
  },
  setLocalStorageData (key, param, val) {
    let obj = localStorage.getItem(key)

    if (!obj) {
      obj = {}
    } else {
      obj = JSON.parse(obj)
    }

    if (val !== undefined) {
      if (typeof (param) === 'object') {
        if (obj[param[0]] === undefined) obj[param[0]] = {}
        obj[param[0]][param[1]] = val
      } else {
        obj[param] = val
      }
    } else {
      obj = param
    }

    localStorage.setItem(key, JSON.stringify(obj))
  }
}
