import Vue from 'vue'

// 진행상태
Vue.filter('isDefault', (value) => {
  switch (value) {
    case 0: return '-'
    case 1: return '기본'
    default: return value
  }
})

export default {
  name: 'client-filter',
  data () {
    return {
    }
  }
}
