<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'사이즈구분관리'" :nav-list="['맥케이슨관리', '기본정보관리', '사이즈구분관리']" />
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">사이즈구분 등록</div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label pt-4">구분(최대 10자)</label>
              <div class="col-sm-1 pt-1">
                <div class="custom-radio custom-control" v-for="(value, idx) in genCodeOption" :key="idx">
                  <input type="radio" :id="'exampleCustomRadio_'+value.key" name="customRadio" class="custom-control-input" :value="value.key" v-model="form.genCode">
                  <label class="custom-control-label" :for="'exampleCustomRadio_'+value.key">{{value.value}}</label>
                </div>
              </div>
              <div class="col-sm-8 pt-4">
                <input type="text" class="form-control d-inline" minLength="1" maxLength="10" required v-model="form.claName">
              </div>
            </div>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">사이즈구분</div>
          <div class="table-responsive">
            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
              <thead>
              <tr>
<!--                <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>-->
                <th class="text-center">구분코드</th>
                <th class="text-center">구분값</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!datas.length">
                <td colspan="3" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in datas" :key="idx" v-else>
<!--                <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" :value="data.sizeClaIdx" :id="data.idx" v-model="selectedList" @click="select"></td>-->
                <th class="text-center" scope="row">{{ data.sizeClaIdx }}</th>
                <td class="text-center">{{ data.genCode | genCodeKeyMap(data.genCode) }}_{{data.claName}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
<!--                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-card mb-3 card">
      <div class="card-body">
        <div class="position-relative row form-group">
          <label class="col-sm-1 col-form-label">수정일</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="updateDate" readonly>
          </div>
          <label class="col-sm-1 col-form-label">수정자</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="regiPersonData.upAdmId" readonly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength, sameAs} from 'vuelidate/lib/validators'
import NavigationBar from '@/page/components/NavigationBar'
import CodeMixin from '@/mixins/code-mixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'SizeClassList',
  data() {
    return {
      datas: [],
      regiPersonData: {
        upAdmId: '',
        updt: ''
      },
      selectedList: [],
      allSelected: false,
      form: {
        genCode: 1,
        claName: ''
      },
      genCodeOption: [
        {key: 0, value: '공용'},
        {key: 1, value: '남'},
        {key: 2, value: '여'}
      ],
    }
  },
  validations: {
    form: {
      claName: { required, minLength: minLength(1), maxLength: maxLength(10) }
    }
  },
  mixins: [
    CodeMixin, InterfaceMixin
  ],
  computed: {
    updateDate: function () {
      return this.regiPersonData.updt
      // return new Date(this.regiPersonData.updt).format('yyyy-MM-dd')
    }
  },
  components: {
    NavigationBar
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList: function () {
      this.$api.api.getSizeClassList().then(resp => {
        if (resp.data.result) {
          this.datas = resp.data.data.data
          this.regiPersonData = resp.data.data.regiPersonData
        } else {
          // this.alert(resp.data.msg)
          this.alert(resp.data.msg)
        }
      }).catch(() => {
        this.alert('Internal Server Error')
      })
    },
    onCreate: function () {
      if (!this._isInvalidateInputForm())return;

      this.$api.api.registerSizeClass(this.form).then(resp => {
        if (resp.data.result) {
          this.alert('등록되었습니다.', () => {
            this.$router.go(0)
          })
        } else {
          this.alert(resp.data.errMsg)
        }
      }).catch(() => {
        this.alert('Internal Server Error')
      })
    },
    onDelete: function () {
      this.confirm('삭제 하시겠습니까?', () => {
        if (this.selectedList.length <= 0) {
          this.alert('삭제할 대상을 선택해주세요.')
          return
        }

        this.$api.api.deleteSizeClass({sizeClaIdxList: this.selectedList}).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.$router.go(0)
            })
          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    },
    checkedAll: function () {
      this.selectedList = []
      if (!this.allSelected) {
        for (var i = 0; i < this.datas.length; i++) {
          this.selectedList.push(this.datas[i].sizeClaIdx)
        }
      }
    },
    select: function () {
      this.allSelected = false
    },
    _isInvalidateInputForm: function () {
      if (this.$v.$invalid) {
        if (!this.$v.form.claName.required) {
          this.alert('구분값을 입력해주세요.')
          return false
        }
        if (!this.$v.form.claName.minLength) {
          this.alert('구분값은 최소 1자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.form.claName.maxLength) {
          this.alert('구분값은 최대 10자리 이상 입력할 수 없습니다.')
          return false
        }
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
