<template>
  <app-main />
</template>

<script>
import AppMain from './common/AppMain'

export default {
  name: 'ErrorLayout',
  components: {
    AppMain
  }
}
</script>

<style scoped>
</style>
