var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.innerFile.src
        ? _c("div", [
            _c("img", {
              directives: [
                { name: "image-error", rawName: "v-image-error" },
                { name: "viewer", rawName: "v-viewer" }
              ],
              ref: "imagePreview",
              staticClass: "images",
              style: "height: " + _vm.height + "px; width: auto",
              attrs: { src: _vm.innerFile.src }
            })
          ])
        : _vm._e(),
      _c("input", {
        ref: "imageInput",
        attrs: {
          type: "file",
          hidden: "",
          accept: ".jpg, .png, .jpeg, .bmp, .gif"
        },
        on: { change: _vm.chgImage }
      }),
      _c(
        "button",
        {
          staticClass: "btn btn-primary mt-2",
          on: {
            click: function($event) {
              return _vm.$refs.imageInput.click()
            }
          }
        },
        [_c("i", { staticClass: "fa fa-plus mr-2" }), _vm._v("Select files")]
      ),
      _vm._t("deleteButton")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }