<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'회원관리'" :nav-list="['맥케이슨관리', '매장판매관리', '회원관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">회원타입</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value=0>전체</option>
                    <option v-for="(item, idx) in srchTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="form-row">
                  <div class="col-md-2">
                    <div class="position-relative">
                      <label class="">검색조건</label>
                      <select class="form-control" v-model="search.lookupType">
                        <option v-for="(item, idx) in lookupTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="position-relative mt-2">
                      <label class="">&nbsp;</label>
                      <input type="text" v-model.trim="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">이름</th>
                  <th class="text-center">전화번호</th>
                  <th class="text-center">구매한도액</th>
                  <th class="text-center">(현)구매한도 잔여</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!memberList.length">
                  <td colspan="4" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in memberList" :key="idx" v-else>
                  <td class="text-center">{{ data.memberName }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="regiPop(data.idx)">{{ data.memberPhone | dash }}</a></td>
                  <td class="text-center">{{ data.purchaseLimit | comma }}</td>
                  <td class="text-center">{{ data.remainLimit | comma }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="total" for="memberList" v-on:vue-pagination::memberList="onPage" v-show="memberList.length"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import codeMixin from '@/mixins/code-mixin'
import Pagination from "../../components/Pagination";
import RegiStoreDiscountPop from "@/page/store/Member/components/RegiStoreDiscountPop";

export default {
  name: "MemberList",
  data() {
    return {
      memberList: [],
      total: 0,
      search: {
        lookupType: 1,
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: 0
      },
      srchTypeList: [
        {value: 1, text: 'VIP'},
        {value: 2, text: '직원'}
      ],
      lookupTypeList: [
        {value: 1, text: '회원명'},
        {value: 2, text: '전화번호'}
      ]
    }
  },
  validations:{
  },
  mixins: [
    InterfaceMixin,
    CommonMixin,
    codeMixin
  ],
  watch: {
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  components: {
    NavigationBar,
    Pagination
  },
  methods: {
    onPage(page) {
      this.search.srchPage = page
      this.getList()
    },
    onSearch() {
      this.search.srchPage = 1
      this.getList()
    },
    getList() {
      this.search.srchKeyword = this.search.srchKeyword.replaceAll('-', '');
      this.$api.api.getOfflineMemberList(this.search)
          .then(resp => {
            console.log(resp)
            this.memberList = resp.data.data
            this.srchPageCnt = resp.data.srchPageCnt
            this.total = resp.data.total
          })
          .catch(error => {
            console.log('getList-error:::', error)
          })
    },
    regiPop(idx) {
      this.$modal.show(
        RegiStoreDiscountPop,
        {memberIdx: idx, type: 'store'},
        {draggable: false, resizable: false, height: 'auto', width: 1200},
        {
          'before-close': event => {
            if (event.params) {
              this.getList()
            }
          }
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
