<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'주문통계'" :nav-list="['맥케이슨관리', '통계관리', '주문통계']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                    <div>
                      <label v-for="obj in statTypeList" style="display: inline; padding-right: 15px;">
                          <input type="radio" name="statType" :value="obj.value" v-model="search.srchStatType" style="margin-bottom: 18px;margin-top: 10px;" @change="setDate(obj.value)" /> {{obj.text}}
                      </label>
                    </div>

                    <div v-if="search.srchStatType === 1">
                      <date-picker v-model="search.srchDateStartDay" value-type="format" format="YYYY-MM-DD" placeholder="시작일" :editable="false" :clearable="false" :disabled-date="notAfterToday" />
                      <label class="m-1">~</label>
                      <date-picker v-model="search.srchDateEndDay" value-type="format" format="YYYY-MM-DD" placeholder="종료일" :editable="false" :clearable="false" :disabled-date="notBeforeToday" />
                    </div>

                    <div v-if="search.srchStatType === 2">
                      <select v-model="search.srchDateStartYear" class="custom-select wd-125 mr-2">
                          <option v-for="item in yearList" :value="item.value">{{item.text}}</option>
                      </select>
                      <select v-model="search.srchDateStartMonth" class="custom-select wd-75">
                          <option v-for="item in monthList" :value="item.value">{{item.text}}</option>
                      </select>
                       ~
                      <select v-model="search.srchDateEndYear" class="custom-select wd-125 mr-2">
                          <option v-for="item in yearList" :value="item.value">{{item.text}}</option>
                      </select>
                      <select v-model="search.srchDateEndMonth" class="custom-select wd-75">
                          <option v-for="item in monthList" :value="item.value">{{item.text}}</option>
                      </select>
                    </div>

                    <div v-if="search.srchStatType === 3">
                      <select v-model="search.srchDateStartYear" class="custom-select wd-125 mr-2">
                          <option v-for="item in yearList" :value="item.value">{{item.text}}</option>
                      </select>
                      <select v-model="search.srchDateStartQuarter" class="custom-select wd-100">
                          <option v-for="item in quarterList" :value="item.value">{{item.text}}</option>
                      </select>
                       ~
                      <select v-model="search.srchDateEndYear" class="custom-select wd-125 mr-2">
                          <option v-for="item in yearList" :value="item.value">{{item.text}}</option>
                      </select>
                      <select v-model="search.srchDateEndQuarter" class="custom-select wd-100">
                          <option v-for="item in quarterList" :value="item.value">{{item.text}}</option>
                      </select>
                    </div>

                    <div v-if="search.srchStatType === 4">
                      <select v-model="search.srchDateStartYear" class="custom-select wd-125">
                          <option v-for="item in yearList" :value="item.value">{{item.text}}</option>
                      </select>
                       ~
                      <select v-model="search.srchDateEndYear" class="custom-select wd-125">
                          <option v-for="item in yearList" :value="item.value">{{item.text}}</option>
                      </select>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="getStatOrderList">검색</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                  <button @click="exportExcel" class="btn btn-primary">엑셀다운로드</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">일자</th>
                  <th class="text-center">주문건수</th>
                  <th class="text-center">주문수량</th>
                  <th class="text-center">취소건수</th>
                  <th class="text-center">취소수량</th>
                  <th class="text-center">반품건수</th>
                  <th class="text-center">반품수량</th>
                  <th class="text-center">교환건수</th>
                  <th class="text-center">교환수량</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="resultDataList.length > 0">
                  <tr v-for="(item, idx) in resultDataList" :key="idx">
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.statDay }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.orderCnt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.orderQt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.cancelCnt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.cancelQt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.returnCnt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.returnQt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.changeCnt | comma }}</td>
                    <td class="text-center" :class="{'font-weight-bold': idx === resultDataList.length - 1}">{{ item.changeQt | comma }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="9" class="text-center">내역이 존재하지 않습니다.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import moment from 'moment'


export default {
  name: "StatOrderList",
  data () {
    return {
      search: {
        srchDateStartDay: '',
        srchDateEndDay: '',
        srchDateStartYear: '',
        srchDateEndYear: '',
        srchDateStartMonth: '',
        srchDateEndMonth: '',
        srchDateStartQuarter: '',
        srchDateEndQuarter: '',
        srchStatType: 1
      },
      statTypeList: [
        { value: 1, text: '일자별'},
        { value: 2, text: '월별'},
        { value: 3, text: '분기별'},
        { value: 4, text: '년도별'}
      ],
      resultDataList: [],
      totalCnt: 0,
      yearList: [],
      monthList: [],
      quarterList: [],

    }
  },
  components: {
    NavigationBar,
    DatePicker
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
  },
  created () {

  },
  mounted () {
    this.initDate()
    this.getStatOrderList()
  },
  methods: {
    initDate (){
      this.search.srchDateStartDay = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')
      this.search.srchDateEndDay = moment(new Date()).format('YYYY-MM-DD')
      // 년도 셋팅
      let cDate = new Date();
      let cYear = cDate.getFullYear();
      for (let i=(cYear-5); i<= cYear; i++) this.yearList.push({ value: i, text: i + '년 ' })
      for (let i=1; i<=12; i++) this.monthList.push({ value: i, text: i + '월'})
      for (let i=1; i<=4; i++) this.quarterList.push({ value: i, text: i + '분기'})
    },
    notAfterToday (date) {
       return moment(date).format('YYYY-MM-DD') > moment(this.search.srchDateEndDay).format('YYYY-MM-DD')
    },
    notBeforeToday (date) {
      return moment(date).format('YYYY-MM-DD') < moment(this.search.srchDateStartDay).format('YYYY-MM-DD')
    },
    setDate(type) {
      let now = new Date()
      switch (type) {
        case 1:
          this.search.srchDateStartDay = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')
          this.search.srchDateEndDay = moment(new Date()).format('YYYY-MM-DD')
          break;
        case 2:
          this.search.srchDateStartYear = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('YYYY')
          this.search.srchDateEndYear = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('YYYY')
          this.search.srchDateStartMonth = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('M')
          this.search.srchDateEndMonth = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('M')
          break;
        case 3:
          this.search.srchDateStartYear = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('YYYY')
          this.search.srchDateEndYear = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('YYYY')
          this.search.srchDateStartQuarter = Math.ceil(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('MM') / 3)
          this.search.srchDateEndQuarter = Math.ceil(moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('MM') / 3)
          break;
        case 4:
          this.search.srchDateStartYear = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('YYYY')
          this.search.srchDateEndYear = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format('YYYY')
          break;
      }
    },
    _isInvalidateInputForm(type) {
      switch (type) {
        case 1: {
          if (this.search.srchDateStartDay <= this.search.srchDateEndDay) return true
        } break
        case 2: {
          if ( this.search.srchDateStartYear + this.search.srchDateStartMonth.toString().padStart(2,'0') <= this.search.srchDateEndYear + this.search.srchDateEndMonth.toString().padStart(2,'0') ) return true
        } break
        case 3: {
          if (this.search.srchDateStartYear + this.search.srchDateStartQuarter.toString() <= this.search.srchDateEndYear + this.search.srchDateEndQuarter.toString()) return true
        } break
        case 4: {
          if (this.search.srchDateStartYear <= this.search.srchDateEndYear) return true
        } break
      }
    },
    getStatOrderList (){
      if (!this._isInvalidateInputForm(this.search.srchStatType)) {
        console.log('기간검색 오류')
        this.alert('시작일보다 종료일이 빠릅니다.')
        return false
      }
      this.$api.api.getStatOrderList(this.search).then(res => {
        if (res.data.result) {
          this.resultDataList = res.data.data
          this.totalCnt = res.data.totalCnt
        } else {
          this.alert(res.data.errMsg)
        }
      })
    },
    exportExcel () {
      this.$api.api.exportStatOrderExcelDown(this.search).then(resp => {
          this.$toast.success('엑셀 다운로드 성공')
      })
      .catch(e => {
        this.alert('엑셀 다운로드 실패')
        console.log(e)
      })
    }
  }
}
</script>

<style scoped>
  .faq-accordion-combo__content{
    background: #0b5885;
    border: 1px solid black;
  }
</style>
