<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">지역별 배송비 등록</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <table class="mb-0 table">
            <tbody>
            <tr>
              <th class="w-18" v-if="!form.type"><span class="required">*</span>지역명</th>
              <th class="w-18" v-else><span class="required">*</span>도서산간명</th>
              <td class="w-85"><input type="text" class="form-control" placeholder="도서산간명을 입력해주세요" v-model="form.regionName" maxlength="20" /></td>
            </tr>
            <tr>
              <th class="w-18"><span class="required">*</span>우편번호 범위</th>
              <td class="w-100">
                <div class="col-12">
                  <input type="radio" class="custom-radio" :value="0" v-model="form.type" @change="chgType"> 지역등록
                  <input type="radio" class="custom-radio ml-5" :value="1" v-model="form.type" @change="chgType"> 직접등록
                </div>
                <div v-if="form.type === 0">
                  <select class="form-control d-inline w-25 mr-1" v-model="form.addr1">
                    <option value="">선택</option>
                    <option v-for="item in area1List" :value="item.areaName1">{{item.areaName1}}</option>
                  </select>
                  <select class="form-control d-inline w-25 mr-2" v-model="form.addr2" @change="getPostcode">
                    <option value="">선택</option>
                    <option v-for="item in area2List" :value="item.signguCd">{{item.signguCd}}</option>
                  </select>
                  <input type="text" class="form-control d-inline" style="width: 70px" v-model="form.scode" readonly>
                  <p class="d-inline mr-1">부터</p>
                  <input type="text" class="form-control d-inline" style="width: 70px" v-model="form.ecode" readonly>
                  <p class="d-inline">까지</p>
                </div>
                <div v-else>
                  <input type="text" class="form-control d-inline w-35" v-model="form.scode" minlength="5" maxlength="5" v-number-input>
                  <p class="d-inline mr-1">부터</p>
                  <input type="text" class="form-control d-inline w-35" v-model="form.ecode" minlength="5" maxlength="5" v-number-input>
                  <p class="d-inline">까지</p>
                  <p class="mt-1 mb-0 font-color-blue">* 우편번호를 입력해주세요</p>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="required">*</span>지역 추가배송비</th>
              <td class="w-85">
                <input type="text" class="form-control w-50 d-inline" v-model="form.addfee" @input="getComma(form.addfee)" maxlength="7" />
                <span class="d-inline">원</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="text-center">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100 ml-1" @click="complete" v-if="!isEdit">등록</button>
              <button class="btn btn-success wd-100 ml-1" @click="onUpdate" v-else-if="isEdit">수정</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin} from '@/components/mixins/CommonMixin'
import InterfaceMaxin from '@/mixins/userInterface-mixin'

export default {
  name: "FeePop",
  props: {
    feeIdx: {
      type: Number,
      default: 0
    },
  },
  created() {
    this.getAreaCode1()
  },
  mounted() {
    if (this.feeIdx) {
      this.isEdit= true
      this.getFeeDetail()
    }
  },
  data() {
    return {
      isEdit: false,
      area1List: '',
      area2List: '',
      form: {
        addfee: '',
        addr1: '',
        addr2: '',
        idx: '',
        regionName: '',
        scode: '',
        ecode: '',
        type: 0
      }
    }
  },
  watch: {
    'form.type' : function () {
      if(!this.form.addr1 && !this.form.addr2){
        this.form.addr1 = ''
        this.form.addr2 = ''
      }
    },
    'form.addr1': function () {
      if (this.form.addr1) {
        this.getAreaCode2()
      }
    }
  },
  components: {
  },
  mixins: [InterfaceMaxin, CommonMixin],
  methods: {
    getFeeDetail() {
      this.$api.api.getFeeDetail({idx: this.feeIdx}).then((resp) => {
        if (resp.data.result) {
          let data = resp.data.data
          // this.form.addfee = data.addfee
          this.getComma(data.addfee)
          this.form.addr1 = data.addr1
          this.form.addr2 = data.addr2
          this.form.idx = data.idx
          this.form.regionName = data.regionName
          this.form.scode = data.scode
          this.form.ecode = data.ecode
          this.form.type = data.type
        }
      })
    },
    getAreaCode1() {
      this.$api.api.getAreaCode().then(resp=> {
        this.area1List = resp.data.data
      })
    },
    getPostcode() {
      if (this.form.addr2 !== '') {
        this.blockUI()
        this.$api.api.getPostcode({"gugun": this.form.addr2}).then(resp => {
          if (resp.data.result) {
            this.form.scode = resp.data.data.min_post
            this.form.ecode = resp.data.data.max_post
            this.unBlockUI()
          } else {
            this.alert('조회 실패')
            this.unBlockUI()
          }
        }).catch(error => {
          this.unBlockUI()
        })
      } else {
        this.form.scode = ''
        this.form.ecode = ''
      }
    },
    getAreaCode2() {
      this.area2List = []
      this.$api.api.getSearchArea2({'sido': this.form.addr1}).then(resp => {
        if (resp.data.result) {
          this.area2List = resp.data.data
        }
      })
    },
    _isInvalidateInputForm () {
      if (!this.form.regionName) {
        this.alert('지역명을 입력해주세요.')
        return false
      }
      if (this.form.type === 0) {
        if (!this.form.addr1 || !this.form.addr2) {
          this.alert('지역을 선택해주세요.')
          return false
        }
      }
      if (!this.form.scode || !this.form.ecode) {
        this.alert('우편번호를 입력해주세요.')
        return false
      }
      if (this.form.type === 1) {
        if (this.form.scode.length < 5 || this.form.ecode.length < 5) {
          this.alert('우편번호를 5자리이상 입력해주세요.')
          return false
        }
        if (this.form.scode >= this.form.ecode) {
          this.alert('우편번호 범위를 정확히 입력해주세요.')
          return false
        }
      }
      if (!this.form.addfee) {
        this.alert('지역 추가배송비를 입력해주세요.')
        return false
      }
      return true
    },
    _isInvalidateAddFee () {
      if (this.form.addfee < 1000) {
        this.alert('지역 추가배송비는 최소 1000원 이상으로 입력해주세요')
        return false
      }
      if (this.form.addfee % 100 > 0) {
        this.alert('지역 추가배송비는 최소 100원단위 이상으로 입력해주세요')
        return false
      }
      return true
    },
    complete() {
      if (!this._isInvalidateInputForm()){
        return
      }
      this.form.addfee = parseInt(this.form.addfee.replace(/[^0-9]/g, ''))
      if (!this._isInvalidateAddFee()){
        return
      }
      this.confirm('등록 하시겠습니까?', () => {
        this.$api.api.registerRegionFee(this.form).then(resp => {
          if (resp.data.result) {
            this.alert('등록되었습니다.', () => {
              this.$emit('close', true)
            })
          } else {
            this.alert(resp.data.errMsg)
            this.getComma(this.form.addfee)
          }
        })
      })
    },
    onCancle() {
      this.$emit('close')
    },
    onUpdate() {
      if(!this._isInvalidateInputForm()){
        return
      }
      this.form.addfee = parseInt(this.form.addfee.replace(/[^0-9]/g, ''))
      if (!this._isInvalidateAddFee()){
        return
      }
      this.confirm('수정 하시겠습니까?', () => {
        this.$api.api.updateRegionFee(this.form).then(resp => {
          if(resp.data.result) {
            this.alert('수정되었습니다.', () => {
              this.$emit('close', true)
            })
          } else {
            this.alert(resp.data.errMsg)
            this.getComma(this.form.addfee)
          }
        })
      })
    },
    chgType () {
      this.form.addr1 = ''
      this.form.addr2 = ''
      this.form.scode = ''
      this.form.ecode = ''
    },
    numberComma (value) {
      let reg = /(^[+-]?\d+)(\d{3})/
      value += ''
      value = value.replace(/,/gi, '')
      while (reg.test(value)) {
        value = value.replace(reg, '$1' + ',' + '$2')
      }
      return value
    },
    getComma (value) {
      let checkNum = value.toString().replace(/[^0-9]/g, '')
      this.form.addfee = this.numberComma(checkNum)
    }
  }
}
</script>

<style scoped>
.w-18{
  width: 20% !important;
}
</style>
