<template>
  <div>
    <div class="app-page-title">
      <navigation-bar
        :nav-title="'사내판매할인율관리'"
        :nav-list="['맥케이슨관리', '사내판매관리', '사내판매할인율관리']"
      />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h6 class="card-title mb-0">사내 판매 상품 할인율 등록</h6>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <!--기간-->
              <label class="col-sm-2 col-form-label label-wrapper">
                <span class="required">*</span>
                <span>기간</span>
              </label>
              <div class="col-sm-10">
                <date-picker
                  style="width:50%"
                  v-model="addForm.pickedDate"
                  format="YYYY-MM-DD HH"
                  value-type="format"
                  :editable="false"
                  :clearable="false"
                  type="datetime"
                  :range="true"
                  :confirm="true"
                />
              </div>
              <!--할인율-->
              <label class="col-sm-2 col-form-label label-wrapper">
                <span class="required">*</span>
                <span>할인율</span>
              </label>
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control d-inline-block w-50 mx-input mr-2 mb-2 mt-2"
                  v-model="addForm.discountPercentage"
                  placeholder="숫자만 입력하세요"
                  maxlength="3"
                  v-number-input
                />
                <span class="notice-title">* 100이하의 정수</span>
                <div class="velidateMessage" v-if="validateNum">
                  숫자만 입력 가능합니다
                </div>
                <div class="velidateMessage" v-if="rangeOverNum">
                  100이하의 정수만 입력 가능합니다.
                </div>
              </div>
              <!--적용 품범리스트-->
              <label class="col-sm-2 col-form-label label-wrapper">
                <span class="required">*</span>
                <span>적용품번리스트</span>
              </label>
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control d-inline-block w-50 mx-input mr-2 mb-2 mt-2"
                  placeholder="쉼표(,)로 나열해주세요"
                  v-model="addForm.itemList"
                />
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-right">
                <button
                  class="btn btn-success wd-100 ml-1"
                  @click="registDiscountInHouse"
                >
                  등록
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h6 class="card-title mb-0">건수 : {{ discountList.length }} 건</h6>
          </div>
          <div class="card-body" style="min-height: 300px;">
            <div>
              <table
                class="table table-striped table-hover"
                style="table-layout: fixed"
              >
                <thead>
                  <tr>
                    <th class="text-center" style="width:5%">
                      선택
                    </th>
                    <th class="text-center" style="width:50%">적용품번</th>
                    <th class="text-center" style="width:14%">시작시간</th>
                    <th class="text-center" style="width:14%">종료시간</th>
                    <th class="text-center" style="width:7%">할인율</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="discountList.length === 0">
                    <td colspan="6" class="text-center">
                      내역이 존재하지 않습니다.
                    </td>
                  </tr>
                  <tr v-else v-for="(item, index) in discountList" :key="index">
                    <td class="text-center text-muted" style="width:5%">
                      <input
                        type="checkbox"
                        data-toggle="toggle"
                        :id="item.disIdx"
                        :value="item.disIdx"
                        data-onstyle="success"
                        @change="selectedChange(item.disIdx)"
                        v-model.lazy="selectList"
                      />
                    </td>
                    <td
                      class="text-center "
                      style="width:50%; text-overflow: ellipsis;overflow: hidden;white-space: nowrap"
                      :title="item.prdNoList"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="showDetail(item.disIdx)"
                      >
                        {{ item.prdNoList }}
                      </a>
                    </td>
                    <td class="text-center " style="width:15%">
                      {{ item.strtDt }}
                    </td>
                    <td class="text-center " style="width:15%">
                      {{ item.endDt }}
                    </td>
                    <td class="text-center " style="width:7%">
                      {{ item.disRate }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-right">
                <button class="btn btn-danger wd-100" @click="deleteDiscountInHouse">
                  선택삭제
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko.js";
import moment from "moment";
import { CommonMixin } from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import DiscountInHousePop from "./components/DiscountInHousePop";

export default {
  name: "DiscountInHouseList.vue",
  components: {
    DatePicker,
    NavigationBar
  },
  mixins: [CommonMixin, InterfaceMixin],
  data() {
    return {
      addForm: {
        pickedDate: [],
        discountPercentage: "",
        itemList: "",
        applyMethed: 0
      },
      discountList: [],
      selectList: [],
      allSelected: false
    };
  },
  computed: {
    validateNum() {
      return isNaN(this.addForm.discountPercentage);
    },
    rangeOverNum() {
      return Number(this.addForm.discountPercentage) > 100;
    }
  },
  mounted() {
    this.getDiscountInHouseList();
  },
  methods: {
    showDetail(disIndx) {
      let target = this.discountList.find(item => {
        return item.disIdx === disIndx;
      });
      this.$modal.show(
              DiscountInHousePop,
        { discountInfo: target },
        {},
        {
          "before-close": event => {
            if (event.params) {
              this.getDiscountInHouseList();
            }
          }
        }
      );
    },
    selectedChange(disIdx) {
      if (this.selectList.length === 0) this.selectList = [];
      else this.selectList = [disIdx];
    },
    getDiscountInHouseList() {
      this.$api.api
        .getDiscountInHouseList()
        .then(res => {
          if (res.data.result) {
            this.discountList = res.data.data;
          }
        })
        .catch(err => {
          console.error(`axios catch err [registDiscountInHouse]`, err.response);
        });
    },
    deleteDiscountInHouse() {
      if (this.selectList.length === 0) {
        this.alert("선택하신 항목이 없습니다");
      } else {
        this.$api.api
          .deleteDiscountInHouse({ disIdx: this.selectList[0] })
          .then(res => {
            if (res.data.result) {
              this.selectList = [];
              this.alert(
                "선택하신 할인정보가 삭제되었습니다",
                this.getDiscountInHouseList()
              );
            }
          });
      }
    },
    registDiscountInHouse() {
      let isValid = this.checkValidate();

      if (isValid) {
        let messageBody = {
          disRate: parseInt(this.addForm.discountPercentage),
          strtDt: moment(this.addForm.pickedDate[0]).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endDt: moment(this.addForm.pickedDate[1]).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          prdNoList: this.addForm.itemList
        };
        this.$api.api
          .registDiscountInHouse(messageBody)
          .then(res => {
            if (res.data.result) {
              this.getDiscountInHouseList();
              this.alert("할인정보가 등록되었습니다", this.resetForm());
            } else {
              this.alert(res.data.msg);
            }
          })
          .catch(err => {
            console.error(`axios catch err [registDiscountInHouse]`, err.response);
          });
      }
    },
    resetForm() {
      this.addForm = {
        pickedDate: [],
        discountPercentage: "",
        itemList: ""
      };
    },
    checkValidate() {
      if (this.addForm.pickedDate.length === 0) {
        this.alert("기간을 선택해주세요.");
        return false;
      }
      if (!Number.isInteger(parseFloat(this.addForm.discountPercentage))) {
        this.alert("할인율은 정수를 입력해주세요");
        return false;
      }
      if (this.addForm.discountPercentage < 0) {
        this.alert("할인율은 0보다 큰 값을 입력해주세요.");
        return false;
      }
      if (this.addForm.discountPercentage > 100) {
        this.alert("할인율은 100이하의 값을 입력해주세요.");
        return false;
      }
      if (this.addForm.itemList === "") {
        this.alert("상품을 선택해주세요.");
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped>
.label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.velidateMessage {
  margin-left: 5px;
  font-size: smaller;
  color: red;
}
.notice-title {
  color: red;
  font-size: smaller;
}
</style>
