<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'금칙어관리'" :nav-list="['맥케이슨관리', '게시판관리', '금칙어관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group mt-1">
                  <label class="">기간검색</label>
                  <select class="form-control" v-model="search.keyWordType">
                    <option value=0>등록일</option>
                    <option value=1>수정일</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative form-group">
                  <label class="mb-4"></label>
                  <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.startDay" :end-date="search.endDay"
                                      :button-use="true" button-type="year" :search-period="{amount: 3, unit: 'M'}" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">사용여부</label>
                  <select type="select" class="form-control" v-model="search.prhbIsUse">
                    <option value="">전체</option>
                    <option value=0>사용</option>
                    <option value=1>미사용</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">검색영역</label>
                  <select class="form-control" v-model="search.keyWordType">
                    <option value=0>전체</option>
                    <option value=1>검색</option>
                    <option value=2>상품문의</option>
                    <option value=3>구매후기</option>
                    <option value=4>상품설명</option>
                  </select>
                </div>
              </div>
              <div class="col-md-7">
                <div class="position-relative form-group">
                  <label class="mb-4"></label>
                  <input type="text" class="mr-2 form-control" placeholder="Search..." v-model="search.keyWord" @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch(1)">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2 mr-2">총 {{ totalCnt }} 건 </h5>
              </div>
              <div class="float-right">
                <button class="btn btn-success" @click="exportExcel">엑셀 다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" v-model="selectAll"></th>
                <th class="text-center">No.</th>
                <th class="text-center">금칙어</th>
                <th class="text-center">적용메뉴</th>
                <th class="text-center">등록일</th>
                <th class="text-center">수정일</th>
                <th class="text-center">사용여부</th>
              </tr>
              </thead>
              <tbody>
                <tr v-if="!prohibitList.length">
                  <td colspan="6" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in prohibitList" :key="idx" v-else >
                  <td class="text-center"><input type="checkbox" v-model="chkList" :value="data.prhbIdx" /></td>
                  <td class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</td>
                  <td class="text-center"><a class="font-weight-bold" href="javascript:void(0)" @click="goDetail(data.prhbIdx)">{{ data.prhbWord }}</a></td>
                  <td class="text-center">{{ data.prohibitMenu }}</td>
                  <td class="text-center">{{ dateFormat(data.rgdt, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center">{{ dateFormat(data.updt, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center">{{ data.prhbIsUse | prhbIsUse }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center mt-4">
              <pagination :per-page="search.srchPageCnt" :pagenum="search.srchPage" :records="totalCnt" for="prohibitList" v-on:vue-pagination::prohibitList="onPage" v-show="prohibitList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100 ml-1" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-primary wd-100 ml-1" @click="onCreate">추가</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from "@/page/components/Pagination";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import DatePickerButton from '@/components/common/DatePickerButton'
import { CommonMixin } from "@/components/mixins/CommonMixin";
import moment from "moment";

export default {
  name: "ProhibitList",
  components: { NavigationBar, DatePickerButton, Pagination },
  mixins: [ InterfaceMixin, CommonMixin ],
  data () {
    return {
      datas: [],
      search: {
        endDay: '',
        startDay: '',
        keyWordType: 0,
        prhbIsUse: '',
        keyWord: '',
        srchPage: 1,
        srchPageCnt: 10,
      },
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      totalCnt: 0,
      chkList: [],
      prohibitList: [],
    }
  },
  computed: {
    selectAll: {
      get: function () {
        return this.prohibitList.length ? this.chkList.length === this.prohibitList.length : false;
      },
      set: function (value) {
        const selected = [];
        if (value) {
          this.prohibitList.map((data, idx) => {
            selected.push(data.prhbIdx);
          });
        }
        this.chkList = selected;
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init () {
      let qs = this.$route.query.search

      if(qs) {
        let search = this.objectDeserialize(qs)
        console.log(search)
        if (search.startDay != undefined) this.search.startDay = search.startDay
        if (search.endDay != undefined) this.search.endDay = search.endDay
        if (search.keyWordType != undefined) this.search.keyWordType = search.keyWordType
        if (search.prhbIsUse != undefined) this.search.prhbIsUse = search.prhbIsUse
        if (search.keyWord != undefined) this.search.keyWord = search.keyWord
        this.search.srchPage = Number(search.srchPage)
        this.search.srchPageCnt = Number(search.srchPageCnt)
      } else {
        this.onReset()
      }
      this.getProhibitList()
    },
    onReset () {
      this.search.endDay = moment(new Date()).format('YYYY-MM-DD')
      this.search.startDay = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      this.search.keyWordType = 0
      this.search.prhbIsUse = 0
      this.search.keyWord = ''
    },
    onSearch () {
      this.search.srchPage = 1
      this.getProhibitList()
    },
    onList() {
      // this.$router.push({path: '/prohibit/list', query: { search: this.objectSerialize(this.search)}})
      this.getProhibitList()
    },
    getProhibitList () {
      let params = {
        startDay: this.search.startDay,
        endDay: this.search.endDay,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt,
        keyWordType: this.search.keyWordType,
        prhbIsUse: this.search.prhbIsUse,
        keyWord: this.search.keyWord
      }
      return this.$api.api.getProhibitList(params).then(resp => {
        if (resp.data.result) {
          this.prohibitList = resp.data.data
          this.totalCnt = resp.data.totalCnt
          console.log("prohibitList >> " + JSON.stringify(this.prohibitList))
        } else {
          this.alert("목록 조회 실패")
        }
      }).catch(error => {
        console.log(error)
      })
    },
    setDateStart (value) {
      this.search.startDay = value
    },
    setDateEnd (value) {
      this.search.endDay = value
    },
    onChangePageSize(){
      this.search.srchPage = 1
      this.onSearch()
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getProhibitList()
    },
    goDetail (prhbIdx) {
      this.$router.push({path: '/prohibit/register', query: { prhbIdx: prhbIdx } })
    },
    onCreate () {
      this.$router.push({name: 'ProhibitRegister'})
    },
    onDelete () {
      if (this.chkList.length <= 0) {
        this.alert("삭제할 대상을 선택해주세요.")
        return
      }
      let reqBody = {
        idxList: this.chkList
      }
      this.confirm("삭제 하시겠습니까?", () => {
        this.$api.api.deleteProhibit(reqBody).then(resp => {
          if (resp.status === 200) {
            this.alert("삭제 되었습니다.", () => {
              this.init()
            })
          } else {
            this.alert("금칙어 삭제 실패")
          }
        })
      })
    },
    exportExcel () {
      let params = {
        endDay: this.search.endDay,
        startDay: this.search.startDay,
        keyWordType: this.search.keyWordType,
        prhbIsUse: this.search.prhbIsUse,
        keyWord: this.search.keyWord,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      return this.$api.api.exportProhibitList(params).then(resp => {
        if (resp.status === 200) {
          this.alert("엑셀 파일이 다운로드 되었습니다.")
        }
      }).catch(error => {
        console.log(error)
      })
    },

  }
}
</script>

<style scoped>
</style>
