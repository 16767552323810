<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'컬렉션관리'" :nav-list="['맥케이슨관리', '컬렉션관리', '컬렉션관리']"/>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-body pt-2">
            <div class="row form-group pr-0 pl-0 mb-2">
              <div class="col-12 pt-2 pb-2">
                <div class="float-left">
                  <h5 class="card-title float-left text-center mb-0 mt-2 mr-2 wd-100">전시상태</h5>
<!--                  <select class="chgStatus custom-select wd-275 mr-3" v-model="search.srchIsDisplay" :disabled="isNew || isEdit" @change="onSearch()">-->
                  <select class="chgStatus custom-select wd-175 mr-1" v-model="search.srchIsDisplay" :disabled="isNew || isEdit" @change="onSearch()">
                    <option value="">전체</option>
                    <option value="0">전시중</option>
                    <option value="1">전시중지</option>
                  </select>
                  <button class="btn btn-secondary wd-100" @click="reset()">초기화</button>
                </div>
                <div class="float-right ml-2 mb-0 mb-2"></div>
              </div>
              <div class="col-12">
                <div class="float-left">
                  <h5 class="card-title float-left text-center mb-0 mt-2 mr-2 wd-100">총 {{ chkList.length }}건</h5>
                  <select class="displayStatus custom-select wd-175 mr-1" v-model="isDisplay" :disabled="isEdit || isNew">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in search.srchColctIsUseList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                  <button class="btn btn-secondary wd-100" @click="onChangeStatus" :disabled="isNew || isEdit">선택변경</button>
                </div>
                <div class="float-right ml-2 mb-0 mb-2">
                  <button class="btn btn-primary wd-100" @click="onAddListData(colctTypeList)" :disabled="search.srchIsDisplay !== ''"><i class="fa fa-plus mr-1"></i>추가</button>
                </div>
              </div>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center wd-50"><input type="checkbox" v-model="selectAll" /></th>
                  <th class="text-center wd-100">순서</th>
                  <th class="text-center wd-300">컬렉션명</th>
                  <th class="text-center wd-150">전시상태</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!colctTypeList.length">
                  <td colspan="4" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-else v-for="(data, idx) in colctTypeList" :key="idx">
                  <template v-if="data.idx">
                    <td class="text-center">
                      <input type="checkbox" v-model="chkList" :value="data.idx" />
                    </td>
                    <td class="text-center">
                      <select class="form-control text-center disabled w-100" v-model="data.mainOrder" @change="changeOrder(colctTypeList, idx)" :disabled="search.srchIsDisplay !== ''">
                        <option v-for="idx in colctTypeList.length" :value="idx">{{idx}}</option>
                      </select>
                    </td>
                    <td class="colct text-center font-weight-bold">
                      <a href="javascript:void(0)" @click="goColctMenu(data.idx, data.collectionName)" v-if="!isEdit">{{ data.collectionName }}</a>
                      <input type="text" class="form-control text-center w-100" placeholder="컬렉션명을 입력해주세요." maxlength="20" v-model="data.collectionName" v-else-if="isEdit" />
                    </td>
                    <td class="text-center">{{ data.isDisplay | isDisplay }}</td>
                  </template>
                  <template v-if="!data.idx">
                    <td class="text-center">
                      <button class="btn btn-shadow pt-0" @click="deleteRow(colctTypeList, idx)" style="font-weight: bold; font-size: 1.5em;">&#x00D7;</button>
                    </td>
                    <td class="text-center">
                      <select class="form-control text-center disabled" v-model="data.mainOrder" @change="changeOrder(colctTypeList, idx)" :disabled="search.srchIsDisplay !== ''" >
                        <option v-for="idx in colctTypeList.length" :value="idx">{{idx}}</option>
                      </select>
                    </td>
                    <td class="text-center">
                      <input type="text" class="form-control text-center w-100" placeholder="컬렉션명을 입력해주세요." maxlength="20" v-model="data.collectionName" />
                    </td>
                    <td class="text-center" colspan="2">
                      <div class="custom-radio custom-control custom-control-inline">
                        <input type="radio" :value="0" v-model="data.isDisplay" class="custom-control-input" :id="`radio_yes_${idx}`" >
                        <label class="custom-control-label" :for="`radio_yes_${idx}`">전시중</label>
                      </div>
                      <div class="custom-radio custom-control custom-control-inline">
                        <input type="radio" :value="1" v-model="data.isDisplay" class="custom-control-input" :id="`radio_no_${idx}`" >
                        <label class="custom-control-label" :for="`radio_no_${idx}`">전시중지</label>
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger ml-1 wd-100" @click="onDelete" :disabled="isNew || isEdit">선택삭제</button>
              </div>
                <div class="float-right">
                  <template v-if="!isEdit">
                    <button class="btn btn-warning mr-1 wd-100" @click="onUpdate" :disabled="isNew">수정</button>
                  </template>
                  <template v-else-if="isEdit">
                    <button class="btn btn-danger mr-1 wd-100" @click="onCancel">취소</button>
                  </template>
                  <button class="btn btn-success wd-100" @click="onSave">저장</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar";
import { CommonMixin } from "@/components/mixins/CommonMixin";
import InterfaceMixin from '@/mixins/userInterface-mixin';
import CryptoJS from "crypto-js";

export default {
  name: "CollectionList",
  components: { NavigationBar },
  mixins: [ CommonMixin, InterfaceMixin ],
  computed: {
    selectAll: {
      get: function () {
        return this.colctTypeList.length ? this.chkList.length === this.colctTypeList.length : false;
      },
      set: function (value) {
        const selected = [];
        if (value) {
          this.colctTypeList.map((data, idx) => {
            selected.push(data.idx);
          });
        }
        this.chkList = selected;
      },
    },
  },
  created() {
    // this.getCollectionList()
    // this.getList()
  },
  mounted() {
    this.init()
  },
  data () {
    return {
      totalCnt: 0,
      itemList: [],
      chkList: [],
      list: [],
      colctTypeList: [],
      orgList: [],
      tmpList: [],
      isDisplay: '',
      search: {
        srchPage: 1,
        srchColctIsUse: '',
        srchColctIsUseList: [
          { value: 0, text: '전시중' },
          { value: 1, text: '전시중지' }
        ],
        srchIsDisplay: ''
      },
      isEdit: false,
      isNew: false
    }
  },
  methods: {
    init () {
      let qs = this.$route.query.search
      if(qs) {
        let search = this.objectDeserialize(qs)
        console.log(search)
        if (search.isDisplay !== undefined) {
          this.search.srchIsDisplay = search.srchIsDisplay
        }
      }
      this.onSearch()
      // this.getList()
      // this.getCollectionList()
    },
    reset() {
      this.isNew = false
      this.isEdit = false
      this.chkList = []
      this.search.srchIsDisplay = ''
      this.$api.api.getCollectionMainList({isDisplay: ''}).then(resp => {
        if (resp.data.result) {
          this.colctTypeList = resp.data.data
          this.totalCnt = resp.data.totalCnt
        } else if (resp.status === 200) {
          this.colctTypeList = resp.data
        } else {
          console.log('컬렉션 목록 조회 실패');
        }
      })
    },
    _isInvalidateInputForm (list) {
      if (list.filter(data => data.collectionName === '').length > 0) {
        console.log("컬렉션명 미입력(1) ::: " + (list.filter(data => data.collectionName === '').length) + " 건")
        this.alert('컬렉션명을 입력해주세요.')
        return false
      }
      if (list.filter(data => data.isDisplay === '').length > 0) {
        console.log("전시상태 미입력(1) ::: " + (list.filter(data => data.isDisplay === '').length) + " 건")
        this.alert('전시상태를 선택해주세요.')
        return false
      }
    },
    onSearch () {
      this.isNew = false
      this.isEdit = false
      this.chkList = []
      this.isDisplay = ''
      this.getCollectionList()
    },
    // 목록 조회
    getCollectionList() {
      this.isEdit = false
      this.$api.api.getCollectionMainList({isDisplay: this.search.srchIsDisplay}).then(resp => {
          if (resp.data.result) {
              this.colctTypeList = resp.data.data
              this.orgList = resp.data.data
              this.totalCnt = resp.data.totalCnt
          } else if (resp.status === 200) {
              this.colctTypeList = resp.data
          } else {
              console.log('컬렉션 목록 조회 실패');
          }
      })
    },
    getList() {
      this.$api.api.getCollectionMainList({isDisplay: ''}).then(resp => {
        if (resp.data.result) {
          this.itemList = resp.data.data
        } else if (resp.status === 200) {
          this.itemList = resp.data
        } else {
          console.log("..")
        }
      })
    },
    onAddListData(list) {
      if (!this.colctTypeList.length) {
        this.onAddTmpListData(this.tmpList)
      } else {
        let addObj = {
          idx: 0,
          isDisplay: 0,
          collectionName: '',
          mainOrder: list.length + 1
        }
        list.push(addObj);
        this.isNew = true
      }
    },
    onAddTmpListData(list) {
      let addObj = {
        idx: 0,
        isDisplay: 0,
        collectionName: '',
        mainOrder: list.length + 1
        // mainOrder: this.totalCnt + list.length + 1
      }
      list.push(addObj);
      this.initTmpList(list)
    },
    initTmpList (tmpList) {
      this.colctTypeList = tmpList
      // this.tmpList = []
      this.isNew = true
    },
    onSave() {
      let _this = this;
      if (_this.colctTypeList.filter(data => data.collectionName === "").length) {
        this.alert("컬렉션명을 입력해 주세요.");
        return false
      }
      if (_this.colctTypeList.filter(data => data.isDisplay === "").length) {
        this.alert("전시상태를 선택해 주세요.");
        return false
      }
      this.confirm("저장 하시겠습니까?", () => {
        let mainItemList = this.colctTypeList.map(data => {
          return {
            idx: data.idx,
            collectionName: data.collectionName,
            isDisplay: data.isDisplay,
            mainOrder: data.mainOrder
          }
        })
        this.$api.api.regiCollectionMain({mainList : mainItemList}).then(resp => {
          if (resp.status === 200) {
            this.alert("저장 되었습니다.");
            this.init()
          } else if (resp.status === 500) {
            this.alert("저장에 실패하였습니다.");
          }
        })
      })
    },
    onUpdate() { this.isEdit = true },
    onCancel() {
      this.getCollectionList()
    },
    deleteRow (list, idx) {
      list.splice(idx, 1)
      this.reOrdering(list)

      let tmp = this.colctTypeList.map(m => { return m.idx })

      if ( tmp.includes(0) ) {
        this.isNew = true
      } else {
        this.isNew = false
      }
    },
    reOrdering(list) {
      list.map((item, index) => {
        item.mainOrder = index + 1
      });
    },
    onDelete() {
      if (this.chkList.length <= 0) {
        this.alert("삭제할 대상을 선택해주세요.")
        return
      }
      if ( (this.itemList.length - this.chkList.length) < 1 ) {
        this.alert("전시중인 컬렉션이 최소 1개 이상 등록되어 있어야 합니다.")
        return false
      }

      let reqParam = { idxList: this.chkList }
      this.confirm("해당 컬렉션에 등록된 모든 정보가 함께 삭제됩니다. 삭제 하시겠습니까?", () => {
        this.$api.api.deleteCollectionMain(reqParam).then(resp => {
          if (resp.status === 200) {
            this.alert("삭제 되었습니다.", () => {
              this.init();
            })
          } else {
            this.alert("삭제 실패");
          }
        })
      })
    },
    onChangeStatus () {
      if (this.chkList.length <= 0) {
        this.alert("변경할 대상을 선택해주세요.");
        return
      }
      if (this.isDisplay == null || this.isDisplay === "") {
        this.alert("변경 적용할 전시상태를 선택해주세요.");
        return
      }
      let isDisplay = document.querySelector('.displayStatus');
      let text = isDisplay.options[isDisplay.selectedIndex].text

      let reqBody = {
        idxList: this.chkList,
        isDisplay: this.isDisplay
      }
      this.$api.api.changeDisplayStatus(reqBody).then(resp => {
        if (resp.data.result) {
          this.alert(text + "(으)로 변경 되었습니다.", () => {
            this.getCollectionList()
          })
        } else {
          this.alert("전시상태 변경 오류")
        }
      })
      this.getList()
      this.chkList = []
      this.isDisplay = ''
      this.search.srchIsDisplay = ''
    },
    changeOrder(list, index) {
      let temp = list[index];
      let target = list[index].mainOrder;
      list.splice(index, 1)
      list.splice(target - 1, 0, temp)

      list.forEach((item, index) => {
        item.mainOrder = index + 1
      })
    },
    goColctMenu(idx, collectionName) {
      this.$router.push({path: '/collection/', query: { idx: idx, collectionName: collectionName } })
    },
  },
  watch: {
  }
}
</script>

<style scoped>
</style>
