import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    children: [
        {
            path: '/blacklist/list',
            name: 'BlackListList',
            component: () => import('@/page/blacklist/BlackListList')
        },
                {
            path: '/blacklist/modify/:cliIdx?',
            name: 'BlackListRegistration',
            // props: (route) => ({query: route.query}),
            component: () => import('@/page/blacklist/BlackListRegistration')
        },
    ]
}
