<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <!-- <photoshop-picker v-model="colors" /> -->
          <!-- <chrome-picker v-model="colors" style="width:100%" /> -->
          <!-- <swatches-picker v-model="colors" style="width:100%" /> -->
          <sketch-picker v-model="colors" :disable-alpha="true" style="width: 100%; padding: 0px; box-shadow: none;" />
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100 ml-1" @click="complete">적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ColorPicker } from 'vue-accessible-color-picker'
// import { Photoshop } from 'vue-color'
// import { Chrome } from 'vue-color'
// import { Swatches } from 'vue-color'
import { Sketch } from 'vue-color'

export default {
  name: 'RgbSearchPop',
  data () {
    return {
      isOpen: false,
      colors: ''
    }
  },
  components: {
    // 'photoshop-picker': Photoshop
    // 'chrome-picker': Chrome
    // 'swatches-picker': Swatches
    'sketch-picker': Sketch
    // 'material-picker': material
    // 'compact-picker': compact
    // 'slider-picker': slider
  },
  props: {
    text: {
      type: String,
      default: () => {
        return '색상표'
      }
    },
    dColor: {
      type: String,
      default: () => {
        return '#FFFFFF'
      }
    }
  },
  created () {
    this.colors = this.dColor
  },
  mounted () {
  },
  methods: {
    onCancle () {
      this.$emit('close')
    },
    complete () {
      this.$emit('close', this.colors)
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    }
  }
}
</script>
