<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'컬렉션관리'" :nav-list="['맥케이슨관리', '컬렉션관리', '컬렉션관리']"/>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <h5 class="pt-3 pl-4 pb-2 font-weight-bold">{{ this.$route.query.collectionName }}</h5>
        </div>
      </div>
    </div>
    <!-- Top -->
    <div class="card-header">
      <h5 class="card-title mb-0 font-size-lg">상단(롤링)</h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-sm-12 pr-0 pl-0 mb-2">
                <div class="float-left">
                  <h5 class="card-title float-left mb-0 mt-2 mr-2">총 {{ topTotalCnt }} 건 </h5>
                </div>
                <div class="float-right">
                  <button class="btn btn-primary wd-100" @click="onAddListData(topList)"><i class="fa fa-plus mr-2"></i>추가</button>
                </div>
              </div>
              <table class="table table-detail">
                <thead>
                <tr>
                  <th><input type="checkbox" v-model="chkAllTop" /></th>
                  <th>순서</th>
                  <th>이미지</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!topList.length">
                  <td colspan="3" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-else v-for="(data, index) in topList" >
                  <td class="text-center">
                    <input type="checkbox" v-model="chkListTop" :value="data.colctIdx" v-if="data.colctIdx !== 0" />
                    <button class="btn btn-shadow mt-2 ml-2" @click="deleteRow(topList, index)" style="font-weight: bold; font-size: 1.5em;" v-if="data.colctIdx === 0">&#x00D7;</button>
                  </td>
                  <td class="text-center">
                    <select class="form-control w-100 text-center" v-model="data.colctOrder" @change="changeOrder(topList, index)">
                      <option v-for="index in topList.length" :value="index">{{index}}</option>
                    </select>
                  </td>
                  <td class="text-center">
                    <upload-photo v-if="data.filename === ''" branch="collection" :file="{src: getImagePath(getImgSrcBody(data)), w: 150, h: 150}" @changed="changedOk($event, topList, index, 0)" v-slot:deleteButton />
                    <img v-else :src="getImagePath(data.path + '/' + data.filename)" class="images" v-viewer style="max-height: 120px; max-width: 120px;" v-image-error />
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="col-12 pr-0 pl-0 mb-2">
                <div class="float-left">
                  <button class="btn btn-danger wd-100" @click="onDelete('top')">선택삭제</button>
                </div>
                <div class="float-right">
                  <button class="btn btn-success mb-2 wd-100" @click="onCreate('top', topList)">저장</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body -->
    <div class="card-header">
      <h5 class="card-title mb-0 font-size-lg">하단(상세)</h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-sm-12 pr-0 pl-0 mb-2">
                <div class="float-left">
                  <h5 class="card-title float-left mb-0 mt-2 mr-2">총 {{ bodyTotalCnt }} 건 </h5>
                </div>
                <div class="float-right">
                  <button class="btn btn-primary ml-2 mb-2 wd-100" @click="openRegiPopup(bodyList)"><i class="fa fa-plus mr-2"></i>추가</button>
                </div>
              </div>
              <table class="table table-detail">
                <thead>
                <tr>
                  <th><input type="checkbox" v-model="chkAllBody" /></th>
                  <th>순서</th>
                  <th>이미지</th>
                  <th>타이틀</th>
                  <th>상품번호</th>
                  <th>상품명</th>
                  <th>상품명(한글)</th>
                  <th>판매가</th>
                  <th>색상</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!bodyList.length">
                  <td colspan="10" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <template v-for="(data, index) in bodyList" >
                  <tr v-for="(item, i) in data.productList" >
                    <td class="text-center" v-if="i==0" :rowspan="data.productList.length">
                      <input type="checkbox" v-model="chkListBody" :value="data.colctIdx" v-if="data.colctIdx !== 0" />
                      <button class="btn btn-shadow mt-2 ml-2" @click="deleteRow(bodyList, index)" style="font-weight: bold; font-size: 1.5em;" v-if="data.colctIdx === 0">&#x00D7;</button>
                    </td>
                    <td class="text-center" v-if="i==0" :rowspan="data.productList.length">
                      <select class="form-control w-100 text-center" v-model="data.colctOrder" @change="changeOrder(bodyList, index)">
                        <option v-for="index in bodyList.length" :value="index">{{index}}</option>
                      </select>
                    </td>
                    <td class="text-center" v-if="i==0" :rowspan="data.productList.length">
                      <upload-photo v-if="data.filename === ''" branch="collection" :file="{src: getImagePath(getImgSrcBody(data)), w: 150, h: 150}" @changed="changedOk($event, bodyList, index, 0)" v-slot:deleteButton />
                      <img v-else :src="getImagePath(data.path + '/' + data.filename)" class="images" v-viewer style="max-height: 120px; max-width: 120px;" v-image-error />
                    </td>
                    <td class="text-center">{{ item.titleName }}</td>
                    <td class="text-center">{{ item.prdNo }}</td>
                    <td class="text-center">{{ item.prdName }}</td>
                    <td class="text-center">{{ item.prdNameKor }}</td>
                    <td class="text-center">₩ {{ item.prdSalePrice | comma }}</td>
                    <td class="text-center">
                      <template v-for="(col, i) in item.colorList">
                        <span>{{ col.colName }}</span>
                        <span v-if="i !== item.colorList.length-1">, </span>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <div class="col-12 pr-0 pl-0 mb-2">
                <div class="float-left">
                  <button class="btn btn-danger mb-2 wd-100" @click="onDelete('body')">선택삭제</button>
                </div>
                <div class="float-right">
                  <button class="btn btn-success mb-2 wd-100" @click="onCreate('body', bodyList)">저장</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar";
import {CommonMixin} from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import UploadPhoto from "@/components/common/UploadPhoto";
import RegiCollectionPopup from "@/page/collection/components/RegiCollectionPopup";
import DisplayPop from "@/page/visual/display/components/DisplayPop";
import SrchProductPop from "@/page/product/components/SrchProductPop";

export default {
  name: "CollectionType",
  components: {NavigationBar, UploadPhoto, RegiCollectionPopup, SrchProductPop, DisplayPop },
  mixins: [CommonMixin, InterfaceMixin],
  computed: {
    chkAllTop: {
      get: function () {
        return this.topList.length ? this.chkListTop.length === this.topList.length : false;
      },
      set: function (value) {
        const selectedTop = [];
        if (value) {
          this.topList.map((data, index) => {
            selectedTop.push(data.colctIdx);
          });
        }
        this.chkListTop = selectedTop;
      }
    },
    chkAllBody: {
      get: function () {
        return this.bodyList.length ? this.chkListBody.length === this.bodyList.length : false;
      },
      set: function (value) {
        const selectedBody = [];
        if (value) {
          this.bodyList.map((data, index) => {
            selectedBody.push(data.colctIdx);
          });
        }
        this.chkListBody = selectedBody;
      }
    },
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      colctType: this.$route.query.idx,
      chkListTop: [],
      chkListBody: [],
      topList: [],
      bodyList: [],
      topTotalCnt: 0,
      bodyTotalCnt: 0
    }
  },
  props: {},
  created() {
    console.log(this.$route.query.idx)
    console.log(this.$route.query.collectionName)
    // this.init()
  },
  methods: {
    init() {
      this.getTopList()
      this.getBodyList()
    },
    getImgSrcBody(item) {
      return item.filename ? item.path+'/'+item.filename : ''
    },
    getTopList() {
      return this.$api.api.getCollectionTopList(this.$route.query.idx).then(resp => {
        console.log("TOP LIST OK")
        this.topList = resp.data.data.topList
        this.topTotalCnt = resp.data.topTotalCnt
      }).catch(err => {
        console.log("상단  " + err)
      })
    },
    getBodyList() {
      return this.$api.api.getCollectionBodyList(this.$route.query.idx).then(resp => {
        console.log("BODY LIST OK")
        this.bodyList = resp.data.data.bodyList
        this.bodyTotalCnt = resp.data.bodyTotalCnt
      }).catch(err => {
        console.log("본문  " + err)
      })
    },
    onAddListData(list) {
      let imgObj = {
        imgIdx: 0,
        filename: '',
        path: ''
      }
      let obj = {
        colctIdx: 0,
        filename: '',
        colctOrder: list.length + 1,
        path: '',
        imgObj
      }
      list.push(obj);
    },
    changedOk(file, list, index) {
      let imgData = list[index]
      imgData.path = file.path
      imgData.filename = file.name
      imgData.width = file.w
      imgData.height = file.h
    },
    onCreate(area) {
      // 상단
      if (area === 'top') {
        let topListData = this.topList;
        if (topListData.filter(data => data.filename === '').length > 0) {
          this.alert("이미지를 첨부해주세요.")
          return false
        }
        this.confirm("저장 하시겠습니까?", () => {
          let topItemList = this.topList.map(data =>{
            return {
              colctIdx : data.colctIdx,
              filename : data.filename,
              colctType : this.colctType,
              colctOrder : data.colctOrder
            }
          })
          this.$api.api.regiCollectionTop({topList : topItemList}).then(res => {
            if (res.data.result) {
              this.alert("저장 되었습니다.")
              this.init()
            } else {
              console.log("저장오류")
              // this.alert("저장할 내용이 없습니다.")
            }
          })
        })
      }
      // 하단
      if (area === 'body') {
        let bodyListData = this.bodyList;
        if (bodyListData.filter(data => data.filename === '').length > 0) {
          this.alert("이미지를 첨부해주세요.")
          return false
        }
        this.confirm("저장 하시겠습니까?", () => {
          let bodyItemList = bodyListData.map(data => {
            if (data.filename) {
              return {
                colctIdx: data.colctIdx,
                filename: data.filename,
                colctType: this.colctType,
                colctOrder: data.colctOrder,
                productList: data.productList
              }
            }
          })
          this.$api.api.regiCollectionBody({bodyList: bodyItemList}).then(res => {
            if(res.data.result) {
              this.alert("저장 되었습니다.")
              this.init()
            } else {
              console.log("저장오류")
              // this.alert("저장할 내용이 없습니다.")
            }
          })
        })
      }
    },
    deleteRow (list, idx) {
      list.splice(idx, 1)
      this.reOrdering(list)
    },
    onDelete(location) {
      if (location === "top") {
        if (this.chkListTop.length <= 0) {
          this.alert("삭제할 대상을 선택해주세요.")
          return
        }
        let reqBody = {
          idxList: this.chkListTop
        }
        this.confirm('삭제 하시겠습니까?', () => {
          this.$api.api.deleteCollection(reqBody).then(resp => {
            if (resp.status === 200) {
              this.alert('삭제 되었습니다.', () => {
                this.init()
                this.chkListTop = []
              })
            } else {
              this.alert("삭제 실패")
            }
          })
        })
        this.reOrdering(this.topList)
      }
      if (location === "body") {
        if (this.chkListBody.length <= 0) {
          this.alert("삭제할 대상을 선택해주세요.")
          return
        }
        let reqBody = {
          idxList: this.chkListBody
        }
        this.confirm('삭제 하시겠습니까?', () => {
          this.$api.api.deleteCollection(reqBody).then(resp => {
            if (resp.status === 200) {
              this.alert('삭제 되었습니다.', () => {
                this.init()
                this.chkListBody = []
              })
            } else {
              this.alert("삭제 실패")
            }
          })
        })
        this.reOrdering(this.bodyList)
      }
    },
    reOrdering(list) {
      list.map((item, index) => {
        item.colctOrder = index + 1
      })
    },
    changeOrder(list, index) {
      let temp = list[index];
      let target = list[index].colctOrder;
      list.splice(index, 1);
      list.splice(target - 1, 0, temp);
      this.reOrdering(list);
    },
    openRegiPopup(bodyList, idx) {
      let index = bodyList[idx];
      this.$modal.show(
        RegiCollectionPopup,
        {text: '컬렉션 등록', index: index},
        {draggable: false, resizable: false, minWidth: 900, height: 'auto', adaptive: true},
        {
        'before-close': event => {
          if (event.params) {
            this.bodyList.push(event.params.optList);
            this.bodyList.map((item, index) => {
              item.colctOrder = index + 1
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
