var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "상품관리",
            "nav-list": ["맥케이슨관리", "상품관리", "상품관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdNo,
                      expression: "form.prdNo"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "d-inline-block w-75 mx-input mr-2 mb-2 mt-2": _vm.isEdit
                  },
                  attrs: { type: "text", minlength: "1" },
                  domProps: { value: _vm.form.prdNo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdNo", $event.target.value)
                    }
                  }
                }),
                _vm.isEdit
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-light wd-100",
                        staticStyle: { height: "37px" },
                        on: { click: _vm.onCopy }
                      },
                      [_vm._v("상품복사")]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(2),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdName,
                      expression: "form.prdName"
                    }
                  ],
                  staticClass:
                    "form-control d-inline-block w-75 mx-input mr-2 mb-2 mt-2",
                  attrs: { type: "text", minlength: "1", maxlength: "30" },
                  domProps: { value: _vm.form.prdName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdName", $event.target.value)
                    }
                  }
                }),
                _c("label", { staticClass: "col-form-label" }, [
                  _vm._v(
                    "30자 이내 (" +
                      _vm._s(_vm.form.prdName ? _vm.form.prdName.length : 0) +
                      "/30)"
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(3),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdNameKor,
                      expression: "form.prdNameKor"
                    }
                  ],
                  staticClass:
                    "form-control d-inline-block w-75 mx-input mr-2 mb-2 mt-2",
                  attrs: { type: "text", minlength: "1", maxlength: "30" },
                  domProps: { value: _vm.form.prdNameKor },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdNameKor", $event.target.value)
                    }
                  }
                }),
                _c("label", { staticClass: "col-form-label" }, [
                  _vm._v(
                    "30자 이내 (" +
                      _vm._s(
                        _vm.form.prdNameKor ? _vm.form.prdNameKor.length : 0
                      ) +
                      "/30)"
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(4),
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", { staticClass: "col-6 pl-0 pr-0 float-left" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.cat1Idx,
                          expression: "form.cat1Idx"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "cat1Idx",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            _vm.form.cat2Idx = ""
                          }
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                      _vm._l(_vm.cat1List, function(item) {
                        return _c(
                          "option",
                          { domProps: { value: item.cat1Idx } },
                          [_vm._v(_vm._s(item.cat1Name))]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "col-6 pl-0 pr-0 float-left" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.cat2Idx,
                          expression: "form.cat2Idx"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "cat2Idx",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.chgPrdInfo
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                      _vm._l(_vm.cat2List, function(item) {
                        return _vm.form.cat1Idx === item.cat1Idx
                          ? _c(
                              "option",
                              {
                                attrs: { "data-name": item.cat2Name },
                                domProps: { value: item.cat2Idx }
                              },
                              [_vm._v(_vm._s(item.cat2Name))]
                            )
                          : _vm._e()
                      })
                    ],
                    2
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _c("html-editor", {
                    attrs: { content: _vm.content, branch: "product" },
                    on: { "change-content": _vm.updateContent }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(6),
              _vm.prdNotiTitle.length == 0
                ? _c("div", { staticClass: "col-sm-10" }, [
                    _c("p", [_vm._v("카테고리를 선택해주세요")])
                  ])
                : _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    _vm._l(_vm.prdNotiTitle, function(keys, idx) {
                      return _c("div", { staticClass: "input-group pb-2" }, [
                        _c(
                          "label",
                          { staticClass: "col-sm-3 col-form-label" },
                          [_vm._v(_vm._s(keys))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.prdNotiValue[idx],
                              expression: "prdNotiValue[idx]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.prdNotiValue[idx] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.prdNotiValue,
                                idx,
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    }),
                    0
                  )
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(7),
              _vm.form.cat2Idx === ""
                ? _c("div", { staticClass: "col-sm-10" }, [
                    _c("p", [_vm._v("카테고리를 선택해주세요")])
                  ])
                : _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "input-group pb-2" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v("핏")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.fit,
                                expression: "form.addInfo.fit"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "radio", id: "fit_0", name: "fit" },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.form.addInfo.fit, 0)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "fit", 0)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "fit_0" }
                            },
                            [_vm._v("해당없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.fit,
                                expression: "form.addInfo.fit"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "radio", id: "fit_1", name: "fit" },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.form.addInfo.fit, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "fit", 1)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "fit_1" }
                            },
                            [_vm._v("슬림핏")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.fit,
                                expression: "form.addInfo.fit"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "radio", id: "fit_2", name: "fit" },
                            domProps: {
                              value: 2,
                              checked: _vm._q(_vm.form.addInfo.fit, 2)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "fit", 2)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "fit_2" }
                            },
                            [_vm._v("스탠다드핏")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.fit,
                                expression: "form.addInfo.fit"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "radio", id: "fit_3", name: "fit" },
                            domProps: {
                              value: 3,
                              checked: _vm._q(_vm.form.addInfo.fit, 3)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "fit", 3)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "fit_3" }
                            },
                            [_vm._v("루즈핏")]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "input-group pb-2" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v("신축성")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.flexibility,
                                expression: "form.addInfo.flexibility"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "flexibility_0",
                              name: "flexibility"
                            },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.form.addInfo.flexibility, 0)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "flexibility",
                                  0
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "flexibility_0" }
                            },
                            [_vm._v("해당없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.flexibility,
                                expression: "form.addInfo.flexibility"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "flexibility_1",
                              name: "flexibility"
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.form.addInfo.flexibility, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "flexibility",
                                  1
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "flexibility_1" }
                            },
                            [_vm._v("없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.flexibility,
                                expression: "form.addInfo.flexibility"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "flexibility_2",
                              name: "flexibility"
                            },
                            domProps: {
                              value: 2,
                              checked: _vm._q(_vm.form.addInfo.flexibility, 2)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "flexibility",
                                  2
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "flexibility_2" }
                            },
                            [_vm._v("조금 있음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.flexibility,
                                expression: "form.addInfo.flexibility"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "flexibility_3",
                              name: "flexibility"
                            },
                            domProps: {
                              value: 3,
                              checked: _vm._q(_vm.form.addInfo.flexibility, 3)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "flexibility",
                                  3
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "flexibility_3" }
                            },
                            [_vm._v("있음")]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "input-group pb-2" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v("비침")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.seethrough,
                                expression: "form.addInfo.seethrough"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "seethrough_0",
                              name: "seethrough"
                            },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.form.addInfo.seethrough, 0)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "seethrough",
                                  0
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "seethrough_0" }
                            },
                            [_vm._v("해당없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.seethrough,
                                expression: "form.addInfo.seethrough"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "seethrough_1",
                              name: "seethrough"
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.form.addInfo.seethrough, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "seethrough",
                                  1
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "seethrough_1" }
                            },
                            [_vm._v("없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.seethrough,
                                expression: "form.addInfo.seethrough"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "seethrough_2",
                              name: "seethrough"
                            },
                            domProps: {
                              value: 2,
                              checked: _vm._q(_vm.form.addInfo.seethrough, 2)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "seethrough",
                                  2
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "seethrough_2" }
                            },
                            [_vm._v("약간")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.seethrough,
                                expression: "form.addInfo.seethrough"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "seethrough_3",
                              name: "seethrough"
                            },
                            domProps: {
                              value: 3,
                              checked: _vm._q(_vm.form.addInfo.seethrough, 3)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "seethrough",
                                  3
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "seethrough_3" }
                            },
                            [_vm._v("있음")]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "input-group pb-2" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v("두께")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.thickness,
                                expression: "form.addInfo.thickness"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "thickness_0",
                              name: "thickness"
                            },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.form.addInfo.thickness, 0)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "thickness",
                                  0
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "thickness_0" }
                            },
                            [_vm._v("해당없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.thickness,
                                expression: "form.addInfo.thickness"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "thickness_1",
                              name: "thickness"
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.form.addInfo.thickness, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "thickness",
                                  1
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "thickness_1" }
                            },
                            [_vm._v("얇음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.thickness,
                                expression: "form.addInfo.thickness"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "thickness_2",
                              name: "thickness"
                            },
                            domProps: {
                              value: 2,
                              checked: _vm._q(_vm.form.addInfo.thickness, 2)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "thickness",
                                  2
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "thickness_2" }
                            },
                            [_vm._v("보통")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.thickness,
                                expression: "form.addInfo.thickness"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "thickness_3",
                              name: "thickness"
                            },
                            domProps: {
                              value: 3,
                              checked: _vm._q(_vm.form.addInfo.thickness, 3)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.form.addInfo,
                                  "thickness",
                                  3
                                )
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "thickness_3" }
                            },
                            [_vm._v("두꺼움")]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "input-group pb-2" }, [
                      _c("label", { staticClass: "col-sm-3 col-form-label" }, [
                        _vm._v("안감")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.lining,
                                expression: "form.addInfo.lining"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "lining_0",
                              name: "lining"
                            },
                            domProps: {
                              value: 0,
                              checked: _vm._q(_vm.form.addInfo.lining, 0)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "lining", 0)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "lining_0" }
                            },
                            [_vm._v("해당없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.lining,
                                expression: "form.addInfo.lining"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "lining_1",
                              name: "lining"
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.form.addInfo.lining, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "lining", 1)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "lining_1" }
                            },
                            [_vm._v("없음")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.lining,
                                expression: "form.addInfo.lining"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "lining_2",
                              name: "lining"
                            },
                            domProps: {
                              value: 2,
                              checked: _vm._q(_vm.form.addInfo.lining, 2)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "lining", 2)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "lining_2" }
                            },
                            [_vm._v("부분안감")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.addInfo.lining,
                                expression: "form.addInfo.lining"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "lining_3",
                              name: "lining"
                            },
                            domProps: {
                              value: 3,
                              checked: _vm._q(_vm.form.addInfo.lining, 3)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form.addInfo, "lining", 3)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "lining_3" }
                            },
                            [_vm._v("전체안감")]
                          )
                        ]
                      )
                    ])
                  ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "main-card mb-3 card" }, [
              _vm._m(8),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "position-relative row form-group" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.prdPriceOrg,
                          expression: "form.prdPriceOrg"
                        },
                        { name: "number-input", rawName: "v-number-input" }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", min: "0" },
                      domProps: { value: _vm.form.prdPriceOrg },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "prdPriceOrg", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._m(10),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.prdSalePrice,
                          expression: "form.prdSalePrice"
                        },
                        { name: "number-input", rawName: "v-number-input" }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", min: "0" },
                      domProps: { value: _vm.form.prdSalePrice },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "prdSalePrice",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                    _vm._v("할인율")
                  ]),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.discountRate,
                          expression: "discountRate"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: _vm.discountRate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.discountRate = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "position-relative row form-group" }, [
                  _vm._m(11),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.maxSaleCnt,
                          expression: "form.maxSaleCnt"
                        },
                        { name: "number-input", rawName: "v-number-input" }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", min: "0" },
                      domProps: { value: _vm.form.maxSaleCnt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "maxSaleCnt", $event.target.value)
                        }
                      }
                    })
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "main-card mb-3 card" }, [
              _vm._m(12),
              _c("div", { staticClass: "card-body" }, [
                _c("table", { staticClass: "mb-0 table table-detail" }, [
                  _vm._m(13),
                  _c(
                    "tbody",
                    [
                      !_vm.form.optionList.length
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "6" }
                              },
                              [_vm._v("내역이 존재하지 않습니다.")]
                            )
                          ])
                        : _vm._e(),
                      _vm._l(_vm.form.optionList, function(option, i) {
                        return _vm._l(option.opt2List, function(option2, j) {
                          return _c("tr", [
                            j === 0
                              ? _c(
                                  "td",
                                  { attrs: { rowspan: option.rowspan } },
                                  [
                                    i === 0
                                      ? _c(
                                          "p",
                                          { staticClass: "text-center" },
                                          [_vm._v("대표색상")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: option.optOrder,
                                            expression: "option.optOrder"
                                          }
                                        ],
                                        staticClass: "form-control mb-2",
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                option,
                                                "optOrder",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function($event) {
                                              return _vm.changeOrderOption(
                                                i,
                                                $event.target.value
                                              )
                                            }
                                          ]
                                        }
                                      },
                                      _vm._l(_vm.itemCnt, function(num) {
                                        return _c(
                                          "option",
                                          { domProps: { value: num } },
                                          [_vm._v(_vm._s(num))]
                                        )
                                      }),
                                      0
                                    ),
                                    i !== 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-light w-100",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteOption(i)
                                              }
                                            }
                                          },
                                          [_vm._v("삭제")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            j === 0
                              ? _c(
                                  "td",
                                  { attrs: { rowspan: option.rowspan } },
                                  [
                                    _c("div", [
                                      _c("div", {
                                        staticClass: "d-inline-block mr-2",
                                        staticStyle: {
                                          width: "15px",
                                          height: "15px",
                                          "vertical-align": "middle"
                                        },
                                        style: {
                                          "background-color": option.colCode
                                        }
                                      }),
                                      _c("label", {}, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(option.colName) +
                                            "/" +
                                            _vm._s(option.colShortCode) +
                                            ")"
                                        )
                                      ])
                                    ]),
                                    _vm.form.optionList.length > 0
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "draggable",
                                              [
                                                _c(
                                                  "transition-group",
                                                  {
                                                    class:
                                                      option.colIdx + "-wrapper"
                                                  },
                                                  [
                                                    option.img0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img0",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-0"
                                                            }
                                                          },
                                                          [
                                                            option.img0.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img0
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "float-left pl-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href:
                                                                            "javascript:void(0)"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs: {
                                                                              for:
                                                                                "add-file-" +
                                                                                i +
                                                                                "-" +
                                                                                j +
                                                                                "-0"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "첨부"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "float-right pr-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href:
                                                                            "javascript:void(0)"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteColorItem(
                                                                              $event,
                                                                              i,
                                                                              0,
                                                                              "delete"
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "삭제"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c("input", {
                                                                  staticClass:
                                                                    "form-control-file",
                                                                  attrs: {
                                                                    type:
                                                                      "file",
                                                                    hidden: "",
                                                                    accept:
                                                                      "image/jpeg, image/jpg, image/png",
                                                                    id:
                                                                      "add-file-" +
                                                                      i +
                                                                      "-" +
                                                                      j +
                                                                      "-0"
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.previewFileImage(
                                                                        $event,
                                                                        i,
                                                                        0,
                                                                        "add"
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img1",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-1"
                                                            }
                                                          },
                                                          [
                                                            option.img1.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img1
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-1"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            1,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-1"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      1,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img2",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-2"
                                                            }
                                                          },
                                                          [
                                                            option.img2.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img2
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-2"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            2,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-2"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      2,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img3
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img3",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-3"
                                                            }
                                                          },
                                                          [
                                                            option.img3.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img3
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-3"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            3,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-3"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      3,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img4
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img4",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-4"
                                                            }
                                                          },
                                                          [
                                                            option.img4.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img4
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-4"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            4,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-4"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      4,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img5
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img5",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-5"
                                                            }
                                                          },
                                                          [
                                                            option.img5.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img5
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-5"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            5,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-5"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      5,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img6
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img6",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-6"
                                                            }
                                                          },
                                                          [
                                                            option.img6.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img6
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            6,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-6"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      6,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img7
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img7",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-7"
                                                            }
                                                          },
                                                          [
                                                            option.img7.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img7
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-7"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            7,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-7"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      7,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    option.img8
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: "img8",
                                                            staticClass:
                                                              "d-inline-block mr-2 mb-2 img-element",
                                                            attrs: {
                                                              id:
                                                                option.colIdx +
                                                                "-8"
                                                            }
                                                          },
                                                          [
                                                            option.img8.preview
                                                              ? _c("img", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "viewer",
                                                                      rawName:
                                                                        "v-viewer"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "popupImageItem images",
                                                                  attrs: {
                                                                    src:
                                                                      option
                                                                        .img8
                                                                        .preview
                                                                  }
                                                                })
                                                              : _c("img", {
                                                                  staticClass:
                                                                    "popupImageItem",
                                                                  attrs: {
                                                                    src: require("@/assets/images/no-image.png")
                                                                  }
                                                                }),
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-left pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs: {
                                                                            for:
                                                                              "add-file-" +
                                                                              i +
                                                                              "-" +
                                                                              j +
                                                                              "-8"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "첨부"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "float-right pr-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:void(0)"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteColorItem(
                                                                            $event,
                                                                            i,
                                                                            8,
                                                                            "delete"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "삭제"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                staticClass:
                                                                  "form-control-file",
                                                                attrs: {
                                                                  type: "file",
                                                                  hidden: "",
                                                                  accept:
                                                                    "image/jpeg, image/jpg, image/png",
                                                                  id:
                                                                    "add-file-" +
                                                                    i +
                                                                    "-" +
                                                                    j +
                                                                    "-8"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewFileImage(
                                                                      $event,
                                                                      i,
                                                                      8,
                                                                      "add"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(option2.size))
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: option2.optStock,
                                    expression: "option2.optStock"
                                  },
                                  {
                                    name: "number-input",
                                    rawName: "v-number-input"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  maxlength: "4",
                                  disabled: option2.optIsUse === 0
                                },
                                domProps: { value: option2.optStock },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      option2,
                                      "optStock",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: option2.optIsUse,
                                    expression: "option2.optIsUse"
                                  }
                                ],
                                staticClass: "custom-radio m-1",
                                attrs: {
                                  type: "radio",
                                  name: "radio_" + i + "_" + j
                                },
                                domProps: {
                                  value: 1,
                                  checked: _vm._q(option2.optIsUse, 1)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(option2, "optIsUse", 1)
                                  }
                                }
                              }),
                              _c("label", [_vm._v("판매중")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: option2.optIsUse,
                                    expression: "option2.optIsUse"
                                  }
                                ],
                                staticClass: "custom-radio m-1",
                                attrs: {
                                  type: "radio",
                                  name: "radio_" + i + "_" + j
                                },
                                domProps: {
                                  value: 0,
                                  checked: _vm._q(option2.optIsUse, 0)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(option2, "optIsUse", 0)
                                  }
                                }
                              }),
                              _c("label", [_vm._v("판매중지")])
                            ])
                          ])
                        })
                      })
                    ],
                    2
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "position-relative row form-group pt-3" },
                  [
                    _c(
                      "label",
                      { staticClass: "col-sm-1 col-form-label text-center" },
                      [_vm._v("상품 동영상 URL ")]
                    ),
                    _c("div", { staticClass: "col-sm-11" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.videoUrl,
                            expression: "form.videoUrl"
                          }
                        ],
                        staticClass: "form-control w-100",
                        attrs: { type: "text", maxlength: "250" },
                        domProps: { value: _vm.form.videoUrl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "videoUrl", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "d-block text-center card-footer" }, [
                _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
                  _c("div", { staticClass: "float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success wd-200",
                        on: { click: _vm.callClassifyPop }
                      },
                      [_vm._v("색상 및 사이즈 구분 선택")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "main-card mb-3 card" }, [
              _vm._m(14),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "position-relative row form-group" }, [
                  _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                    _vm._v("개별배송여부")
                  ]),
                  _c("div", { staticClass: "col-sm-11 pt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.prdDeliFeeIsEach,
                              expression: "form.prdDeliFeeIsEach"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "prdDeliFeeIsEachRadio_no"
                          },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.form.prdDeliFeeIsEach, 0)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "prdDeliFeeIsEach", 0)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "prdDeliFeeIsEachRadio_no" }
                          },
                          [_vm._v("묶음 배송")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.prdDeliFeeIsEach,
                              expression: "form.prdDeliFeeIsEach"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "prdDeliFeeIsEachRadio_yes"
                          },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.form.prdDeliFeeIsEach, 1)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "prdDeliFeeIsEach", 1)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "prdDeliFeeIsEachRadio_yes" }
                          },
                          [_vm._v("개별 배송")]
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "position-relative row form-group" }, [
                  _vm._m(15),
                  _c("div", { staticClass: "col-sm-11" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.prdDeliFeeIsFree,
                            expression: "form.prdDeliFeeIsFree"
                          }
                        ],
                        staticClass: "form-control d-inline wd-100 mr-4",
                        attrs: { disabled: _vm.onlyPaid },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "prdDeliFeeIsFree",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("유료")
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("무료")
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("조건")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.prdDeliFeeIsFree == 0,
                            expression: "form.prdDeliFeeIsFree == 0"
                          }
                        ],
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.paidFlag,
                              expression: "paidFlag"
                            }
                          ],
                          staticClass: "custom-control-input d-inline",
                          attrs: { type: "radio", id: "basicFeeRadio" },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.paidFlag, 0)
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.paidFlag = 0
                              },
                              _vm.chgPaidFlag
                            ]
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label d-inline",
                            attrs: { for: "basicFeeRadio" }
                          },
                          [_vm._v("기본 배송비")]
                        )
                      ]
                    ),
                    _vm.form.prdDeliFeeIsFree == 0
                      ? _c("input", {
                          staticClass: "form-control d-inline wd-100 mr-2",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: _vm.basicFee }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.prdDeliFeeIsFree == 0,
                            expression: "form.prdDeliFeeIsFree == 0"
                          }
                        ],
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.paidFlag,
                              expression: "paidFlag"
                            }
                          ],
                          staticClass: "custom-control-input d-inline",
                          attrs: { type: "radio", id: "inputFeeRadio" },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.paidFlag, 1)
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.paidFlag = 1
                              },
                              _vm.chgPaidFlag
                            ]
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label d-inline",
                            attrs: { for: "inputFeeRadio" }
                          },
                          [_vm._v("직접입력")]
                        )
                      ]
                    ),
                    _vm.form.prdDeliFeeIsFree == 0
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputFee,
                              expression: "inputFee"
                            },
                            { name: "number-input", rawName: "v-number-input" }
                          ],
                          staticClass: "form-control d-inline wd-100 mr-2",
                          attrs: { type: "number" },
                          domProps: { value: _vm.inputFee },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.inputFee = $event.target.value
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.form.prdDeliFeeIsFree == 2
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.prdDeliFeeFreeCondi,
                                expression: "form.prdDeliFeeFreeCondi"
                              }
                            ],
                            staticClass: "form-control w-25 d-inline",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "prdDeliFeeFreeCondi",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "30000" } }, [
                              _vm._v("30,000")
                            ]),
                            _c("option", { attrs: { value: "50000" } }, [
                              _vm._v("50,000")
                            ]),
                            _c("option", { attrs: { value: "100000" } }, [
                              _vm._v("100,000")
                            ]),
                            _c("option", { attrs: { value: "150000" } }, [
                              _vm._v("150,000")
                            ]),
                            _c("option", { attrs: { value: "200000" } }, [
                              _vm._v("200,000")
                            ]),
                            _c("option", { attrs: { value: "250000" } }, [
                              _vm._v("250,000")
                            ]),
                            _c("option", { attrs: { value: "300000" } }, [
                              _vm._v("300,000")
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.form.prdDeliFeeIsFree == 2
                      ? _c("p", { staticClass: "d-inline" }, [
                          _vm._v("원 이상 무료")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "position-relative row form-group" }, [
                  _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                    _vm._v("도서산간 배송비")
                  ]),
                  _c("div", { staticClass: "col-sm-11 pt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.prdDeliFeeIsAdd,
                              expression: "form.prdDeliFeeIsAdd"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "prdDeliFeeIsAddRadio_yes"
                          },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.form.prdDeliFeeIsAdd, 1)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "prdDeliFeeIsAdd", 1)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "prdDeliFeeIsAddRadio_yes" }
                          },
                          [_vm._v("사용")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.prdDeliFeeIsAdd,
                              expression: "form.prdDeliFeeIsAdd"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "prdDeliFeeIsAddRadio_no"
                          },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.form.prdDeliFeeIsAdd, 0)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "prdDeliFeeIsAdd", 0)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "prdDeliFeeIsAddRadio_no" }
                          },
                          [_vm._v("사용안함")]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "main-card mb-3 card" }, [
              _vm._m(16),
              _c("div", { staticClass: "card-body" }, [
                _c("table", { staticClass: "mb-0 table table-detail" }, [
                  _vm._m(17),
                  _c(
                    "tbody",
                    [
                      _vm.lookProductList.length <= 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "8" }
                              },
                              [_vm._v("선택된 상품이 없습니다.")]
                            )
                          ])
                        : _vm._l(_vm.lookProductList, function(item, idx) {
                            return _c("tr", { key: "look_" + idx }, [
                              _c("td", { staticClass: "text-center" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedList,
                                      expression: "selectedList"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: item.prdIdx,
                                    checked: Array.isArray(_vm.selectedList)
                                      ? _vm._i(_vm.selectedList, item.prdIdx) >
                                        -1
                                      : _vm.selectedList
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.selectedList,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = item.prdIdx,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.selectedList = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.selectedList = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.selectedList = $$c
                                      }
                                    }
                                  }
                                })
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.order,
                                        expression: "item.order",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "text-center form-control",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return _vm._n(val)
                                            })
                                          _vm.$set(
                                            item,
                                            "order",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.changeOrderItem(
                                            idx,
                                            $event.target.value
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  _vm._l(_vm.lookProductList.length, function(
                                    i
                                  ) {
                                    return _c("option", { attrs: { val: i } }, [
                                      _vm._v(_vm._s(i))
                                    ])
                                  }),
                                  0
                                )
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(item.cat1Name) +
                                    " > " +
                                    _vm._s(item.cat2Name)
                                )
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "image-error",
                                      rawName: "v-image-error"
                                    },
                                    { name: "viewer", rawName: "v-viewer" }
                                  ],
                                  staticClass: "images",
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px"
                                  },
                                  attrs: {
                                    src: _vm.getImagePath(item.prdMainImgThumb1)
                                  }
                                })
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.prdNo))
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.prdName))
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(_vm._f("comma")(item.prdSalePrice))
                                )
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("isSaleKeyMap")(
                                      item.prdSaleStatus,
                                      item.prdSaleStatus
                                    )
                                  )
                                )
                              ])
                            ])
                          })
                    ],
                    2
                  )
                ])
              ]),
              _c("div", { staticClass: "d-block text-center card-footer" }, [
                _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
                  _c("div", { staticClass: "float-left" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger wd-100",
                        on: { click: _vm.deleteLook }
                      },
                      [_vm._v("선택삭제")]
                    )
                  ]),
                  _c("div", { staticClass: "float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success wd-100",
                        on: {
                          click: function($event) {
                            return _vm.callProdSearchPop(3, "look")
                          }
                        }
                      },
                      [_vm._v("상품검색")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "main-card mb-3 card" }, [
              _vm._m(18),
              _c("div", { staticClass: "card-body" }, [
                _c("table", { staticClass: "mb-0 table table-detail" }, [
                  _vm._m(19),
                  _c(
                    "tbody",
                    [
                      _vm.itemProductList.length <= 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "8" }
                              },
                              [_vm._v("선택된 상품이 없습니다.")]
                            )
                          ])
                        : _vm._l(_vm.itemProductList, function(item, idx) {
                            return _c("tr", { key: "look_" + idx }, [
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v("-")
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v("-")
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(item.cat1Name) +
                                    " > " +
                                    _vm._s(item.cat2Name)
                                )
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "image-error",
                                      rawName: "v-image-error"
                                    },
                                    { name: "viewer", rawName: "v-viewer" }
                                  ],
                                  staticClass: "images",
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px"
                                  },
                                  attrs: {
                                    src: _vm.getImagePath(item.prdMainImgThumb1)
                                  }
                                })
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.prdNo))
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.prdName))
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(_vm._f("comma")(item.prdSalePrice))
                                )
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("isSaleKeyMap")(
                                      item.prdSaleStatus,
                                      item.prdSaleStatus
                                    )
                                  )
                                )
                              ])
                            ])
                          })
                    ],
                    2
                  )
                ])
              ]),
              _c("div", { staticClass: "d-block text-center card-footer" }, [
                _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
                  _c("div", { staticClass: "float-left" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger wd-100",
                        on: { click: _vm.deleteItem }
                      },
                      [_vm._v("삭제")]
                    )
                  ]),
                  _c("div", { staticClass: "float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success wd-100",
                        on: {
                          click: function($event) {
                            return _vm.callProdSearchPop(1, "item")
                          }
                        }
                      },
                      [_vm._v("상품검색")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "main-card mb-3 card" }, [
              _vm._m(20),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "position-relative row form-group" }, [
                  _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                    _vm._v("판매여부")
                  ]),
                  _c("div", { staticClass: "col-sm-11 pt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.prdSaleStatus,
                              expression: "form.prdSaleStatus"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "prdSaleStatusRadio_yes"
                          },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.form.prdSaleStatus, 1)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "prdSaleStatus", 1)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "prdSaleStatusRadio_yes" }
                          },
                          [_vm._v("판매중")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-radio custom-control custom-control-inline"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.prdSaleStatus,
                              expression: "form.prdSaleStatus"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: { type: "radio", id: "prdSaleStatusRadio_no" },
                          domProps: {
                            value: -1,
                            checked: _vm._q(_vm.form.prdSaleStatus, -1)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "prdSaleStatus", -1)
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "prdSaleStatusRadio_no" }
                          },
                          [_vm._v("판매중지")]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "text-center m-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-lg btn-secondary ml-3 wd-150",
              on: { click: _vm.onList }
            },
            [_vm._v("목록")]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEdit,
                  expression: "!isEdit"
                }
              ],
              staticClass: "btn btn-lg btn-primary ml-3 wd-150",
              on: { click: _vm.onCreate }
            },
            [_vm._v("등록")]
          ),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEdit,
                  expression: "isEdit"
                }
              ],
              staticClass: "btn btn-lg btn-primary ml-3 wd-150",
              on: { click: _vm.onUpdate }
            },
            [_vm._v("수정")]
          )
        ]),
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "position-relative row form-group" }, [
              _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                _vm._v("등록일")
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdRgdt,
                      expression: "form.prdRgdt"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.form.prdRgdt },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdRgdt", $event.target.value)
                    }
                  }
                })
              ]),
              _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                _vm._v("작성자")
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdRgdtAdmId,
                      expression: "form.prdRgdtAdmId"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.form.prdRgdtAdmId },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdRgdtAdmId", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                _vm._v("수정일")
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdLastUpdDt,
                      expression: "form.prdLastUpdDt"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.form.prdLastUpdDt },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdLastUpdDt", $event.target.value)
                    }
                  }
                })
              ]),
              _c("label", { staticClass: "col-sm-1 col-form-label" }, [
                _vm._v("수정자")
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.prdLastUpdId,
                      expression: "form.prdLastUpdId"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.form.prdLastUpdId },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "prdLastUpdId", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("상품정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("상품번호")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("상품명")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("상품명 (한글)")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("카테고리")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("상품설명")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("상품정보제공고시")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("상품추가정보")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("가격정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-1 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("정가")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-1 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("판매가")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-1 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("1회 최대 "),
      _c("br"),
      _vm._v(" 판매가능수량")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("옵션설정")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center center wd-100", attrs: { rowspan: "2" } },
          [_vm._v(" 노출순서 ")]
        ),
        _c(
          "th",
          { staticClass: "text-center center", attrs: { rowspan: "2" } },
          [
            _c("span", { staticClass: "required" }, [_vm._v("*")]),
            _vm._v("색상 별 이미지 등록"),
            _c("br"),
            _vm._v(
              " ! 색상별 이미지는 최소 1개 ~ 최대 9개까지 등록 가능합니다. "
            )
          ]
        ),
        _c(
          "th",
          { staticClass: "text-center center", attrs: { colspan: "4" } },
          [
            _c("span", { staticClass: "required" }, [_vm._v("*")]),
            _vm._v("사이즈별 옵션 설정 ")
          ]
        )
      ]),
      _c("tr", [
        _c("th", { staticStyle: { "min-width": "60px" } }, [_vm._v("사이즈")]),
        _c("th", [
          _c("span", { staticClass: "required" }, [_vm._v("*")]),
          _vm._v("재고수량")
        ]),
        _c("th", [_vm._v("판매여부")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("배송비 설정")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-1 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("배송비")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _vm._v(" 아래의 룩과 함께 (최대 3개) ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-center" }),
        _c("th", { staticClass: "text-center" }, [_vm._v("순서지정")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("상품 카테고리")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("대표이미지")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("상품번호")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("상품명")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("판매가")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("판매여부")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _vm._v(" 아래의 아이템과 함께 (최대 1개) ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-center" }),
        _c("th", { staticClass: "text-center" }, [_vm._v("순서지정")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("상품 카테고리")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("대표이미지")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("상품번호")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("상품명")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("판매가")]),
        _c("th", { staticClass: "text-center" }, [_vm._v("판매여부")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("판매설정")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }