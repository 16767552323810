var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-title-wrapper" }, [
    _c("div", { staticClass: "page-title-heading" }, [
      _vm.navTitle
        ? _c("div", { staticClass: "page-title-icon" }, [
            _c("i", {
              staticClass: "pe-7s-drawer icon-gradient bg-happy-itmeo"
            })
          ])
        : _vm._e(),
      _c("div", [
        _c("strong", [_vm._v(_vm._s(_vm.navTitle))]),
        _c(
          "div",
          { staticClass: "page-title-subheading" },
          [
            _vm._l(_vm.navList, function(nav, index) {
              return [
                _vm.navList.length !== index + 1
                  ? [_vm._v(" " + _vm._s(nav) + " > ")]
                  : [_c("strong", { key: index }, [_vm._v(_vm._s(nav))])]
              ]
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }