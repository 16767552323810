<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'회원관리'" :nav-list="['맥케이슨관리', '회원관리', '회원관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
<!--            <div class="form-row">-->
<!--              <div class="col-md-12">-->
<!--                <div class="position-relative form-group">-->
<!--                  <label class="">기간검색</label>-->
<!--                  <date-picker-button @inputStartDate="setStartDate" @inputEndDate="setEndDate" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="month" :search-period="{amount: 6, unit: 'M'}"/>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangePageSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" v-model="chkAll"/></th>
                  <th class="text-center">번호</th>
                  <th class="text-center">회원명</th>
                  <th class="text-center">회원ID</th>
                  <th class="text-center">휴대폰번호</th>
                  <th class="text-center">이메일</th>
                  <th class="text-center">탈퇴일자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!resultDataList.length">
                  <td colspan="11" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(item, idx) in resultDataList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="chkList" :value="item.cliIdx" :id="item.cliIdx"/></td>
                  <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                  <td class="text-center">{{ item.userName }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="goDetail(item.cliIdx)">{{ item.id }}</a></td>
                  <td class="text-center">{{ item.userPhone }}</td>
                  <td class="text-center">{{ item.email }}</td>
                  <td class="text-center">{{ getBlankToHipen(item.withdrawalDt) }}</td>

                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="resultDataList" v-on:vue-pagination::resultDataList="onPage" v-show="resultDataList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from "@/page/components/Pagination"
import DatePickerButton from "@/components/common/DatePickerButton"
import InterfaceMixin from '@/mixins/userInterface-mixin'
import moment from 'moment'


export default {
  name: "Client",
  data () {
    return {
      search: {
        srchDateStart: '',
        srchDateEnd: '',
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        // cliStatus: 1
      },
      srchTypeList: [
        { value: 1, text: '회원ID' },
        { value: 2, text: '회원명' },
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      resultDataList: [],
      totalCnt: 0,
      chkList: [],
    }
  },
  components: {
    NavigationBar,
    Pagination,
    DatePickerButton
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.resultDataList.length ? this.chkList.length === this.resultDataList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.resultDataList.map(item => {
            selected.push(item.cliIdx)
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.initDate()
      this.onSearch()
    },
    onSearch(){
      this.$api.api.getClientList(this.search).then(res => {
        this.resultDataList = res.data.data
        this.totalCnt = res.data.totalCnt
        console.log(this.resultDataList)
      })
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onSearch()
    },
    initDate(){
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
    },
    onReset () {
      this.initDate()
      this.search.srchKeyword = ''
      this.search.srchType = ''
    },
    onChangePageSize(){
      this.search.srchPage = 1
      this.onSearch()
    },
    setStartDate (value) {
      this.search.srchDateStart = value
    },
    setEndDate (value) {
      this.search.srchDateEnd = value
    },
    getBlankToHipen(value) {
      return value ?? '-'
    },
    goDetail (cliIdx) {
      this.$router.push({name: 'ClientDetail', params:{cliIdx}})
    },
  }
}
</script>

<style scoped>
  .faq-accordion-combo__content{
    background: #0b5885;
    border: 1px solid black;
  }
</style>
