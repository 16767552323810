export default {
    name: 'interface-mixin',
    data () {
        return {
        }
    },
    methods: {
        confirm (message, callback1 = undefined, callback2 = undefined) {
            this.$confirm(message).then(
                // OK
                () => { if (callback1 !== undefined) callback1() },
                // Cancel
                () => { if (callback2 !== undefined) callback2() }
            )
        },
        alert (message, callback = undefined) {
            this.$alert(message).then(
                // OK
                () => { if (callback !== undefined) callback() }
            )
        },
    }
}
