<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'프로모션관리'" :nav-list="['맥케이슨관리', '게시판관리', '프로모션관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setStartDate" @inputEndDate="setEndDate" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="month" :search-period="{amount: 6, unit: 'M'}"/>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">진행상태</label>
                  <select class="form-control" v-model="search.srchPrmStatus">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPrmStatusList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">전시여부</label>
                  <select class="form-control" v-model="search.srchPrmIsUse">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPrmIsUseList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>
                  <th class="text-center">번호</th>
                  <th class="text-center">고유번호</th>
                  <th class="text-center">등록일</th>
                  <th class="text-center">대표이미지</th>
                  <th class="text-center">제목</th>
                  <th class="text-center">진행기간</th>
                  <th class="text-center">상품수</th>
                  <th class="text-center">진행상태</th>
                  <th class="text-center">전시여부</th>
                  <th class="text-center">조회수</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!itemList.length">
                  <td colspan="11" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="chkList" :value="data.prmIdx" :id="data.prmIdx"@click="select"/></td>
                  <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                  <td class="text-center">{{ data.prmIdx }}</td>
                  <td class="text-center">{{ dateFormat(data.rgdt, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center"><img class="images" :src="getImagePath(data.prmImgInfo.path + '/' + data.prmImgInfo.thumb1)" style="width: 100px; height: 100px" v-image-error v-viewer /></td>
                  <td class="text-center"><a href="javascript:void(0)" @click="goDetail(data.prmIdx)">{{ data.prmName }}</a></td>
                  <td class="text-center">
                    <label v-show="data.prmSdt">{{ dateFormat(data.prmSdt, 'YYYY-MM-DD HH:mm') }} ~</label>
                    <label>{{ dateFormat(data.prmEdt, 'YYYY-MM-DD HH:mm') }}</label>
                  </td>
                  <td class="text-center">{{ data.prmPrdCnt	| comma }}</td>
                  <td class="text-center">{{ data.prmStatus | prmStatus }}</td>
                  <td class="text-center">{{ data.prmIsUse | prmIsUse }}</td>
                  <td class="text-center">{{ data.prmViewCnt | comma }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="itemList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from "@/page/components/Pagination"
import DatePickerButton from "@/components/common/DatePickerButton"
import InterfaceMixin from '@/mixins/userInterface-mixin'
import moment from 'moment'


export default {
  name: "PromList",
  data () {
    return {
      search: {
        srchDateStart: '',
        srchDateEnd: '',
        srchPrmStatus: '',
        srchPrmIsUse: '',
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10
      },
      srchPrmStatusList: [
        { value: 1, text: '진행전' },
        { value: 2, text: '진행중' },
        { value: 3, text: '종료' }
      ],
      srchPrmIsUseList: [
        { value: 1, text: '전시' },
        { value: 0, text: '비전시' }
      ],
      srchTypeList: [
        { value: 1, text: '고유번호' },
        { value: 2, text: '제목' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      allSelected: false,
      totalCnt: 0,
      itemList: [],
      chkList: []
    }
  },
  components: {
    NavigationBar,
    Pagination,
    DatePickerButton,
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.itemList.length ? this.chkList.length === this.itemList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.itemList.map(item => {
            selected.push(item)
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let qs = this.$route.query.search

      if(qs) {
        let search=  this.objectDeserialize(qs)
        if(search.srchDateStart != undefined) this.search.srchDateStart = search.srchDateStart
        if(search.srchDateEnd != undefined) this.search.srchDateEnd = search.srchDateEnd
        if(search.srchPrmStatus != undefined) this.search.srchPrmStatus = search.srchPrmStatus
        if(search.srchPrmIsUse != undefined) this.search.srchPrmIsUse = search.srchPrmIsUse
        if(search.srchType != undefined) this.search.srchType = search.srchType
        if(search.srchKeyword != undefined) this.search.srchKeyword = search.srchKeyword

        this.search.srchPage = Number(search.srchPage)
        this.search.srchPageCnt = Number(search.srchPageCnt)
      }else this.onReset()

      this.getItemList()
    },
    onReset () {
      this.search.srchDateStart = moment(new Date()).add(-3, 'M').format('YYYY-MM-DD')
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      this.search.srchPrmStatus = ''
      this.search.srchPrmIsUse = ''
      this.search.srchType = ''
      this.search.srchKeyword = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.onList()
    },
    onList() {
      // this.$router.push({path: '/promotion/list', query: { search: this.objectSerialize(this.search)}})
      this.getItemList()
    },
    getItemList () {
      let param = {
        srchDateStart: this.search.srchDateStart,
        srchDateEnd: this.search.srchDateEnd,
        prmStatus: this.search.srchPrmStatus,
        prmIsUse: this.search.srchPrmIsUse,
        srchType: this.search.srchType,
        srchKeyword: this.search.srchKeyword,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      return this.$api.api.getPromotionList(param).then(resp => {
        this.totalCnt = resp.data.totalCnt
        this.itemList = resp.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail (prmIdx) {
      this.$router.push({path: '/promotion/modify', query: { prmIdx: prmIdx, search: this.objectSerialize(this.search)}})
    },
    onCreate () {
      this.$router.push({path: '/promotion/modify'})
    },
    onDelete () {
      if (this.chkList == 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }
      this.confirm('삭제 하시겠습니까?', () => {
        this.$api.api.deletePromotion({prmIdxList:  this.chkList}).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.init()
            })
          } else {
            this.alert('삭제 실패')
          }
        })
      })
    },
    checkedAll: function () {
      this.chkList = []
      if (!this.allSelected) {
        for (var i = 0; i < this.itemList.length; i++) {
          this.chkList.push(this.itemList[i].prmIdx)
        }
      }
    },
    select: function () {
      this.allSelected = false
    },
    setStartDate (value) {
      this.search.srchDateStart = value
    },
    setEndDate (value) {
      this.search.srchDateEnd = value
    }
  }
}
</script>

<style scoped>

</style>
