<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'블랙리스트관리'" :nav-list="['맥케이슨관리', '블랙리스트관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">블랙리스트 등록</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th colspan="1" class="text-center">회원 검색</th>
                <td colspan="1">
                  <input type="text" class="form-control w-100" placeholder="Search..." :disabled="blIdx != 0">
                </td>
                <td colspan="4"><button class="btn btn-primary wd-100 mr-1" @click="onSrchPopup" :disabled="blIdx != 0">검색</button> </td>
              </tr>
              <tr v-if="clientInfoList.length == 0"></tr>
              <tr v-else v-for="(item,index) in clientInfoList" :key="index">
                <th class="text-center w-15">회원ID</th>
                <td class="text-center w-15 ">{{ item.id }}</td>
                <th class="text-center w-15">회원명</th>
                <td class="text-center w-15">{{ item.userName }}</td>
                <th class="text-center w-15">이메일</th>
                <td class="text-center w-15">{{ item.email }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">블랙리스트 유형정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr v-if="historyReasonList.length > 0">
                <th colspan="1" class="text-center">사유히스토리</th>
                <td>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="(item,idx) in historyReasonList" style="border:none">
                        {{item}}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th colspan="1" class="text-center">등록사유</th>
                <td>
                  <textarea class="form-control" v-model="request.reason"></textarea>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <!--        <button class="btn btn-primary wd-100 ml-1" v-if="request.evtIdx > 0 " @click="onUpdate">수정</button>-->
        <button class="btn btn-primary wd-100 ml-1"  @click="onCreate" v-if="blIdx == 0">등록</button>
        <button class="btn btn-primary wd-100 ml-1"  @click="onUpdate" v-else>수정</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {required, minLength, maxLength} from 'vuelidate/lib/validators'
  import { CommonMixin } from '@/components/mixins/CommonMixin'
  import NavigationBar from '@/page/components/NavigationBar'
  import InterfaceMixin from '@/mixins/userInterface-mixin'
  import CodeMixin from "@/mixins/code-mixin"
  import SrchClientPopup from "./components/SrchClientPopup"


  export default {
    name: "BlackListRegistration",
    data () {
      return {
        request: {
          cliIdx : 0,
          reason : ''
        },

        blIdx : 0,
        clientInfoList : [],
        historyReasonList : []
      }
    },
    components: {
      NavigationBar,
      SrchClientPopup,
    },
    mixins: [
      CommonMixin,
      InterfaceMixin,
      CodeMixin
    ],
    computed: {

    },
    created () {
      this.init()
    },
    mounted () {
    },
    methods: {
      init(){
        console.log("[init___biIdx]" + this.$route.params.cliIdx )
        console.log("[init___find]" + this.$route.params.find )
        if(this.$route.params.cliIdx){
          this.blIdx = this.$route.params.blIdx
          this.request.cliIdx =  this.$route.params.cliIdx
          this.onSearch()
        }
      },
      onSearch(){
        this.$api.api.getBlackDetail({cliIdx : this.request.cliIdx}).then(res => {
          let resData = res.data
          if(resData.result){
            this.request.reason = resData.data.reason
            this.historyReasonList = resData.data.hisReasonList
            this.clientInfoList.push({
              id : resData.data.id,
              userName: resData.data.userName,
              email : resData.data.email
            })
          }
        })
      }
      ,
      onSrchPopup(){
        this.$modal.show(
                SrchClientPopup,
                {},
                {draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200},
                {
                  'before-close': event => {
                    let clientInfo = event.params
                    if(!this.isEmpty(clientInfo)){
                      this.clientInfoList = []
                      this.clientInfoList.push(clientInfo)
                      this.request.cliIdx = this.clientInfoList[0].cliIdx
                    }
                  }
                }
        )
      },
      onCreate(){
        if(!this.checkValid()){
          return false
        }

        let insertRequest = {
          cliIdx : this.request.cliIdx,
          reason : this.request.reason
        }

        this.$api.api.addBlackList(insertRequest).then(res =>{
          let resData = res.data
          if(resData.result){
            this.alert("등록되었습니다.")
            this.$router.push({name: 'BlackListList'})
          }else{
            this.alert(resData.errMsg)
          }
        })
      },
      isEmpty(object){
        return Object.keys(object).length === 0
      },
      onList () {
        this.$router.push({name: 'BlackListList'})
      },
      onUpdate(){
        if(!this.checkValid()){
          return false
        }

        let updateRequest = {
          cliIdx : this.request.cliIdx,
          reason : this.request.reason
        }

        this.$api.api.updateBlackList(updateRequest).then(res =>{
          let resData = res.data
          if(resData.result){
            this.alert("수정되었습니다.")
            this.$router.push({name: 'BlackListList'})
          }else{
            this.alert(resData.errMsg)
          }
        })
      },
      checkValid(){
        if(!this.request.cliIdx){
          this.$alert("회원을 검색해주세요")
          return
        }
        if(!this.request.reason){
          this.$alert("사유를 입력해주세요")
          return
        }
        return true
      }
    },
  }
</script>

<style scoped>

</style>
