<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'회원관리'" :nav-list="['회원관리', '상세']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">회원정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th class="w-15">회원ID</th>
                <td class="text-center">{{clientData.id}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">회원상세정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th colspan="6" class="text-center">회원 기본정보</th>
              </tr>
              <tr>
                <th class="text-center w-15">회원명</th>
                <td class="text-center w-15 ">{{ clientData.userName }}</td>
                <th class="text-center w-15">성별</th>
                <td class="text-center w-15">{{ clientData.genderStr }}</td>
                <th class="text-center w-15">생년월일</th>
                <td class="text-center">
                  <date-picker v-model="clientData.birthDt " value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" disabled/>
                </td>
              </tr>
              <tr>
                <th class="text-center w-15 ">회원상태</th>
                <td class="text-center w-15">{{ clientData.cliStatusStr}}</td>
                <th class="text-center w-15">회원탈퇴사유</th>
                <td class="text-center w-35" colspan="2" >
                  <div class="col-6 pl-0 pr-0 float-left">
                    <select class="form-control"  v-model="clientData.withdrawalCode">
                      <option v-for="(item,idx) in withdrawalList" :value="item.value"> {{item.text}} </option>
                    </select>
                  </div>
                  <button class="btn btn-primary ml-1" @click="onWithdrawal">회원탈퇴</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">회원 연락처 정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th colspan="6" class="text-center">회원 연락처 정보</th>
              </tr>
              <tr>
                <th class="text-center">회원 연락처 정보</th>
                <td class="w-25">
                  <select class="form-control w-25 d-inline-block" v-model="clientData.phoneCorp">
                    <option v-for="(item,index) in newsAgencyList" :value="item.value" :key="index"> {{item.text}} </option>
                  </select>
                  <input type="text" class="form-control w-75 d-inline-block" v-model="clientData.userPhone">
                </td>
                <th class="text-center"> SMS 수신여부</th>
                <td>
                  <select class="form-control w-75 d-inline-block" v-model="clientData.smsAgree">
                    <option v-for="(item,index) in smsAgreeList" :value="item.value" :key="index"> {{item.text}} </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th class="text-center">E-mail</th>
                <td>
                  <input type="text" class="form-control mx-input d-inline-block" v-model="clientData.email">
                </td>
                <th class="text-center">E-mail 수신여부</th>
                <td>
                  <select class="form-control w-75 d-inline-block" v-model="clientData.emailAgree">
                    <option v-for="(item,index) in emailAgreeList" :value="item.value" :key="index"> {{item.text}} </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th class="text-center">우편번호</th>
                <td>
                  <input type="text" class="form-control wd-200 d-inline-block" v-model="clientData.postalCode">
                  <button class="btn btn-primary ml-1" @click="callAddrSearchPop">우편번호 검색</button>
                </td>
                <th class="text-center">주소</th>
                <td>{{ clientData.addr1 }}</td>
                <th class="text-center">상세주소</th>
                <td><input type="text" class="form-control d-inline-block" v-model="clientData.addr2"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">포인트 현황</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th colspan="6" class="text-center">포인트 현황</th>
              </tr>
              <tr>
                <th class="text-center w-35">포인트</th>
                <td class="text-center">
                  {{availPoint}}P
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">배송지 정보 (건수 : {{clientAddrTotalCnt}} 건)</h5>
              </div>
              <div class="float-right">
                <!--                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="">-->
                <!--                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>-->
                <!--                </select>-->
              </div>
            </div>
          </div>
          <div class="card-body">
            <button @click="callRecipientPop" class="btn btn-success wd-100">등록</button>
            <button @click="removeRecipient" class="btn btn-danger wd-100 ml-1">삭제</button>
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" v-model="chkAddrAll"/></th>
                <th class="text-center">번호</th>
                <th class="text-center">기본여부</th>
                <th class="text-center">주소별칭</th>
                <th class="text-center">수취인</th>
                <th class="text-center">우편번호</th>
                <th class="text-center">도로명주소</th>
                <th class="text-center">도로명 상세주소</th>
                <th class="text-center">휴대폰번호</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,idx) in clientAddrData" :key="idx">
                <td class="text-center"><input type="checkbox" v-model="chkAddrList" :value="item"/></td>
                <td class="text-center">{{ item.cliAddrIdx }}</td>
                <td class="text-center">{{ item.isDefault | isDefault }}</td>
                <td class="text-center">{{ item.addrNickname}}</td>
                <td class="text-center">{{ item.recipient }}</td>
                <td class="text-center">{{ item.cliPostalCode }}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="callRecipientPop(item)">{{ item.cliAddr1 }}</a></td>
                <td class="text-center">{{ item.cliAddr2 }}</td>
                <td class="text-center">{{ item.recipientPhone }}</td>
              </tr>
              </tbody>
            </table>
            <!--            <div class="text-center">-->
            <!--              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="itemList.length"></pagination>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12">
<!--        <div class="main-card card">-->
<!--          <div class="card-header">-->
<!--            <div class="col-12 pr-0 pl-0">-->
<!--              <div class="float-left">-->
<!--                <h5 class="card-title float-left mb-0 mt-2">환불계좌정보</h5>-->
<!--              </div>-->
<!--              <div class="float-right">-->
<!--                &lt;!&ndash;                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>&ndash;&gt;-->
<!--                &lt;!&ndash;                </select>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="card-body">-->
<!--            <table class="table table-striped">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th class="text-center">은행명</th>-->
<!--                <th class="text-center">예금주</th>-->
<!--                <th class="text-center">계좌번호</th>-->
<!--                <th class="text-center">등록일자</th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr v-if="!clientData.accountHolder">-->
<!--                <td colspan="11" class="text-center">내역이 존재하지 않습니다.</td>-->
<!--              </tr>-->
<!--              <tr v-else>-->
<!--                <td class="text-center">{{ clientData.bankName }}</td>-->
<!--                <td class="text-center">{{ clientData.accountHolder }}</td>-->
<!--                <td class="text-center">{{ clientData.accountNumber }}</td>-->
<!--                <td class="text-center">{{ clientData.cliAddrIdx }}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--            &lt;!&ndash;            <div class="text-center">&ndash;&gt;-->
<!--            &lt;!&ndash;              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="itemList.length"></pagination>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
          <div class="row">
            <div class="col-lg-12 text-center mb-4">
              <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
              <button class="btn btn-danger wd-100" @click="updateClint">수정</button>
            </div>
          </div>
          <!--          <div class="card-footer">-->
          <!--            <div class="col-12 pr-0 pl-0">-->
          <!--              <div class="float-right">-->
          <!--                <button class="btn btn-danger wd-100" @click="updateClint">수정</button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
</template>

<script>
  import {required, minLength, maxLength, sameAs, helpers } from 'vuelidate/lib/validators'
  import {CommonMixin} from '@/components/mixins/CommonMixin'
  import InterfaceMixin from '@/mixins/userInterface-mixin'
  import NavigationBar from '@/page/components/NavigationBar'
  import RecipientPop from '@/page/order/components/RecipientPop'
  import DaumPostalPop from '@/components/popup/DaumPostalPop'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import 'vue2-datepicker/locale/ko.js'

  const emailRegExp = helpers.regex('email', /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)

  export default {
    name: 'OrderList',
    data() {
      return {
        /*)/개인정보유출/사이트이용불만/고객서비스불만/상품불만/이용빈도저조/기타*/
        withdrawalList: [
          {text: '선택', value: null},
          {text: '개인정보유출', value: 1},
          {text: '사이트이용불만', value: 2},
          {text: '고객서비스불만', value: 3},
          {text: '상품불만', value: 4},
          {text: '이용빈도저조', value: 5},
          {text: '기타', value: 6},
          {text: '사용자 요청 탈퇴', value: 7},
        ],
        newsAgencyList: [
          {text: '선택', value: ''},
          {text: 'SKT', value: 'SKT'},
          {text: 'KT', value: 'KTF'},
          {text: 'LGU+', value: 'LGT'},
          {text: 'SKM', value: 'SKT mvno'},
          {text: 'KTM', value: 'KT mvno'},
          {text: 'LGM', value: 'LG U+ mvno'},
        ],
        smsAgreeList: [
          {text: '선택', value: null},
          {text: '동의', value: 1},
          {text: '거부', value: 0},
        ],
        emailAgreeList: [
          {text: '선택', value: null},
          {text: '수신동의', value: 1},
          {text: '거부', value: 0},
        ],
        clientData: {},
        clientAddrData:[],
        clientAddrTotalCnt : 0,
        chkAddrList: [],
        cliIdx: 0,
        availPoint : 0
      }
    },
    components: {
      NavigationBar,
      DatePicker,
      RecipientPop
    },
    mixins: [
      CommonMixin,
      InterfaceMixin
    ],
    validations: {
      clientData: {
        userPhone: { required },
        email:{required,emailRegExp},
        postalCode:{required},
        addr1: {required},
        addr2: {required}
      }
    },

    computed: {
      chkAddrAll: {
        get() {
          return this.clientAddrData.length ? this.chkAddrList.length === this.clientAddrData.length : false
        },
        set(value) {
          let selected = []
          if (value) {
            this.clientAddrData.map(item => {
              selected.push(item)
            })
          }
          this.chkAddrList = selected
        }
      },
    },
    created() {
      console.log('[MCKAYSON] ClientDetail::: created ', this.$route.query.ordIdx)
      this.cliIdx = this.$route.params.cliIdx
      this.init()

    },
    mounted() {
    },
    methods: {
      init(){
        this.onSearch()
        this.onAddrList()
        this.getAvailPoint()
      },
      onSearch(){
        this.$api.api.getClientDetail({ cliIdx : this.cliIdx}).then(res =>{
          if(res.data.result){
            this.clientData = res.data.data
          }
        }).catch(err =>{
          console.log("err::"+err)
        })
      },
      onAddrList(){
        this.$api.api.getClientAddrList({ cliIdx : this.cliIdx}).then(res =>{
          this.clientAddrData = res.data.data
          this.clientAddrTotalCnt = res.data.totalCnt
        }).catch(err =>{
          console.log("err::"+err)
        })
      },
      onList () {
        this.$router.push({name: 'ClientList'})
      },

      getAvailPoint(){
        this.$api.api.getAdmClientAvailPoint({cliIdx : this.cliIdx}).then(res => {
          if(res.data.result){
            this.availPoint = res.data.data
          }
        }).catch(err => {
          console.log("err",err)
        })
      },

      removeRecipient(){
        if(this.chkAddrList.length <= 0 ){
          this.alert('선택된 값이 없습니다.')
          return
        }
        let removeItem = this.chkAddrList.map(item =>{
          return item.cliAddrIdx
        })

        this.$api.api.deleteClientAddr({ cliAddrIdxList : removeItem }).then(res =>{
          console.log(JSON.stringify(res))
          this.alert('배송지 정보가 삭제되었습니다.')
          this.onAddrList()


        }).catch(err =>{
          console.log("err::"+err)
        })

      },
      updateClint(){
        if (!this.checkClientValid()) {
          console.log('[MCKS] 회원 입력폼 부족')
          return
        }

        let request = {
          cliIdx : this.clientData.cliIdx,
          userName : this.clientData.userName,
          userPhone : this.clientData.userPhone,
          postalCode : this.clientData.postalCode,
          addr1 : this.clientData.addr1,
          addr2 : this.clientData.addr2,
          email : this.clientData.email,
          withdrawalCode : this.clientData.withdrawalCode,
          emailAgree : this.clientData.emailAgree,
          smsAgree : this.clientData.smsAgree,
          phoneCorp : this.clientData.phoneCorp,
        }
        this.$api.api.updateClient(request).then(resp => {
          if (resp.data.result){
            this.alert(resp.data.msg)

          }else{
            this.alert(resp.data.errMsg)
          }
        }).catch(error => {
          console.log(error)
        })
      },
      // 정보수정 팝업
      callRecipientPop(addrInfo) {
        let title = addrInfo.cliAddrIdx ? '배송지 수정' : '배송지 등록'
        let recipient =  {
          name: addrInfo.recipient,
          phone: addrInfo.recipientPhone,
          postalCode: addrInfo.cliPostalCode,
          addr1: addrInfo.cliAddr1,
          addr2: addrInfo.cliAddr2,
          addrNickname :addrInfo.addrNickname,
          isDefault : addrInfo.isDefault,
          cliAddrIdx : addrInfo.cliAddrIdx
        }

        this.$modal.show(
                RecipientPop,
                {text: title, recipientInfo: recipient , username: '',  popupType:'CLIENTADDR' },
                {draggable: false, resizable: false, height: 'auto'},
                {
                  'before-close': event => {
                    if (event.params) {
                      let params = {
                        cliIdx : this.cliIdx,
                        cliAddr1: event.params.address1,
                        cliAddr2: event.params.address2,
                        cliPostalCode: event.params.zonecode,
                        recipient: event.params.username,
                        recipientPhone: this.getRemoveHipen(event.params.phone),
                        addrNickname: event.params.addrNickname,
                        isDefault : event.params.isDefault,
                      }
                      this.blockUI()
                      // 배송지 정보 등록
                      if(!addrInfo.cliAddrIdx){
                        this.$api.api.regiClientAddr(params).then(resp => {
                          if (resp.data.result) {
                            this.alert('배송지 정보가 등록되었습니다.')
                            this.onAddrList()
                          }else{
                            this.alert(resp.data.errMsg)
                          }
                        }).catch(error => {
                          console.log(error)
                        }).finally(final=>{
                          console.log(final)
                          this.unBlockUI()
                        })
                      }
                      else {
                        //배송지 정보 수정
                        params = {...params, cliAddrIdx : addrInfo.cliAddrIdx }
                        this.$api.api.updateClientAddr(params).then(resp => {
                          if (resp.data.result) {
                            this.alert('배송지 정보가 수정되었습니다.')
                            this.onAddrList()
                          }else{
                            this.alert(resp.data.errMsg)
                          }
                        }).catch(error => {
                          console.log(error)
                        }).finally(final=>{
                          console.log(final)
                          this.unBlockUI()
                        })
                      }
                    }
                  }
                }
        )
      },
      // 우편번호검색 팝업
      callAddrSearchPop () {
        this.$modal.show(
                DaumPostalPop,
                { text: '우편번호검색' },
                { draggable: false, resizable: false, height: 'auto' },
                {
                  'before-close': event => {
                    let addrObj = event.params
                    console.log(addrObj)
                    this.clientData.postalCode = event.params.zonecode
                    this.clientData.addr1 = event.params.address
                    // this.recipient.address2 = ''
                    //this.recipient.comment = ''
                  }
                }
        )
      },

      checkClientValid () {
        if (this.$v.$invalid) {
          if (!this.$v.clientData.userPhone.required) {
            this.alert('핸드폰번호를 입력해주세요')
            return false
          }
          if (!this.$v.clientData.email.required) {
            this.alert('이메일을 입력해주세요')
            return false
          }
          if (!this.$v.clientData.email.emailRegExp) {
            this.alert('이메일 형식을 확인해주세요')
            return false
          }

          if (!this.$v.recipient.postalCode.required) {
            this.alert('우편번호를 입력해주세요.')
            return false
          }

          if (!this.$v.recipient.addr1.required) {
            this.alert('주소를 입력해주세요')
            return false
          }
          if (!this.$v.recipient.addr2.required) {
            this.alert('상세주소를 입력해주세요.')
            return false
          }
        }
        return true
      },
      getRemoveHipen(val){
        return val.replaceAll('-','')
      },

      onWithdrawal(){
        this.$confirm("회원탈퇴 하시겠습니까?").then(() => {
          this.$api.api.withdrawalClient( { cliIdx : this.cliIdx , withdrawalCode : this.clientData.withdrawalCode }).then(res => {
            if(res.data.result){
              this.alert("회원탈퇴 되었습니다.")
              this.$router.push({name : "ClientList" })
            }
            else{
              this.alert(res.data.errMsg)
            }
          }).catch(err => {
            console.log("err",err)
          })
        })
      }
    }
  }
</script>

<style scoped>
  .phone-box{
    margin-left: 10px;
    display: inline-block;
  }
</style>

