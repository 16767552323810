var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.chkSeason === 4
        ? _c("Snowf", {
            attrs: {
              amount: 50,
              size: 5,
              speed: 1.5,
              wind: 0,
              opacity: 0.8,
              swing: 1,
              image: null,
              zIndex: null,
              resize: true,
              color: "#000"
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header"
        },
        [
          _c("top-menu"),
          _c(
            "div",
            { staticClass: "app-main" },
            [
              _c("side-menu"),
              _c(
                "div",
                { staticClass: "app-main__outer" },
                [
                  _c(
                    "div",
                    { staticClass: "app-main__inner" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [_c("app-main")],
                        1
                      )
                    ],
                    1
                  ),
                  _c("footer-menu")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }