<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'주문관리'" :nav-list="['맥케이슨관리', '주문관리', '주문관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 12, unit: 'M'}" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">유입경로</label>
                  <select class="form-control" v-model="search.srchAccessMethod">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchAccessMethodList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">결제수단</label>
                  <select class="form-control" v-model="search.srchPayKind">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPayKindList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">결제상태</label>
                  <select class="form-control" v-model="search.ordStatus">
                    <option value="">전체</option>
                    <option v-for="item in ordStatusList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt | comma }} 건 / 신규주문 : {{ newCnt | comma }} 건</h5>
                <span class="float-left mb-0 mt-1 mr-2 ml-2">|</span>
                <h5 class="card-title float-left mb-0 mt-2 required">정가총액 : {{ totalPrdPriceOrg | comma }}원 / 결제총액 : {{ totalPayAmount | comma }}원</h5>
                <span class="float-left mb-0 mt-1 mr-2 ml-2">|</span>
                <h5 class="card-title float-left mb-0 mt-2" style="color: #3f6ad8;">(사용)마일리지 총액 : {{ totalPoint | comma }}M</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-150 mr-1">
                  <option>배송준비</option>
                </select>
                <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="onChangeStatus">변경저장</button>
                <button class="btn btn-success wd-150 ml-1 mr-1" @click="exportExcel">엑셀다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                  <th class="text-center">NO</th>
                  <th class="text-center">주문일<br/>(결제일)</th>
                  <th class="text-center">유입경로</th>
                  <th class="text-center">회원명</th>
                  <th class="text-center">회원ID</th>
                  <th class="text-center">주문번호</th>
                  <th class="text-center">상품번호</th>
                  <th class="text-center">상품명</th>
                  <th class="text-center">주문자</th>
                  <th class="text-center">연락처</th>
                  <th class="text-center">마일리지(사용)</th>
                  <th class="text-center">정가</th>
                  <th class="text-center">결제금액</th>
                  <th class="text-center">상품금액</th>
                  <th class="text-center">결제수단</th>
                  <th class="text-center">결제상태</th>
                  <th class="text-center">메모</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!itemList.length">
                  <td colspan="18" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" v-model="chkList" :value="data" v-if="data.ordStatus == 3 && data.odvStatus == 0 && data.opoQuantityAvail > 0"/></td>
                  <td class="text-center" v-bind:style="[data.ordStatus == 3 && data.odvStatus == 0? {'color': 'red'} : {}]">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                  <td class="text-center">{{ dateFormat(data.ordRgdt, 'YYYY-MM-DD HH:mm') }} <template v-if="data.payDt"><br/>({{ dateFormat(data.payDt, 'YYYY-MM-DD HH:mm') }})</template></td>
                  <td class="text-center">{{ data.accessMethod }}</td>
                  <td class="text-center">{{ data.cliName }}</td>
                  <td class="text-center">{{ data.id }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="goDetail(data.ordIdx)">{{ data.ordNo }}</a></td>
                  <td class="text-center">{{ data.prdNo }}</td>
                  <td class="text-center">{{data.ordProduct }}/{{ data.opoColName }}/{{ data.opoSize }}</td>
                  <td class="text-center">{{ data.userName }}</td>
                  <td class="text-center">{{ data.userPhone | dash }}</td>
                  <th class="text-center"><span style="color: red" v-if="data.usePoint > 0">{{ data.usePoint | comma}}M</span></th>
                  <td class="text-center">{{ data.prdPriceOrg | comma }}원</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callPaymentPop(data.ordIdx)">{{ data.payAmount | comma }}원</a></td>
                  <td class="text-center" :style="data.memIdx? 'color:#B40404': ''">{{ data.prdAmount  | comma }}원</td>
                  <td class="text-center">{{ data.payKind | payKind }}</td>
                  <td class="text-center">{{ data.ordStatus | ordStatus }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(data.ordIdx, data.ordNo)"><i class="pe-7s-comment pr-2"></i>{{ data.memoCnt | comma }}</a></td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import DatePickerButton from '@/components/common/DatePickerButton'
import PaymentPop from '@/page/order/components/PaymentPop'
import MemoPop from '@/page/order/components/MemoPop'
import moment from 'moment'

export default {
  name: 'OrderList',
  data () {
    return {
      search: {
        srchDateStart: '',
        srchDateEnd: '',
        srchAccessMethod: '',
        srchPayKind: '',
        ordStatus: '',
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10
      },
      srchAccessMethodList: [
        { value: 'PC', text: 'PC'},
        { value: 'MC', text: 'MC'}
      ],
      srchPayKindList: [
        { value: 1, text: '신용카드'},
        { value: 2, text: '가상계좌'},
        { value: 3, text: '휴대폰'}
      ],
      ordStatusList: [
        { value: 2, text: '입금대기'},
        { value: 7, text: '입금대기취소'},
        { value: 3, text: '결제완료'}
      ],
      srchTypeList: [
        { value: 1, text: '주문번호' },
        { value: 2, text: '상품번호' },
        { value: 3, text: '상품명' },
        { value: 4, text: '주문자' },
        { value: 5, text: '연락처' },
        { value: 6, text: '회원명' },
        { value: 7, text: '회원ID' },
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' },
        { value: 100, text: '100개' }
      ],
      totalCnt: 0, // 건수
      newCnt: 0, // 신규 주문
      totalPrdPriceOrg: 0, // 정가 총액
      totalPayAmount: 0, // 결제 총액
      totalPoint: 0, // 사용 마일리지
      itemList: [],
      chkList: []
    }
  },
  components: {
    NavigationBar,
    DatePickerButton,
    Pagination,
    PaymentPop,
    MemoPop
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.itemList.length ? this.chkList.length === this.itemList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          selected = this.itemList.filter(item => {
            return item.ordStatus == 3 && item.odvStatus == 0 && item.opoQuantityAvail > 0
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {
    this.$bus.$on('memoUpdate', value => {
      this.itemList.map((element, idx) => {
        if(element.ordIdx == value.ordIdx) {
          this.itemList[idx].memoCnt = value.amount
        }
      })
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let qs = this.$route.query.search

      if(qs) {
        let search=  this.objectDeserialize(qs)
        if(search.srchDateStart != undefined) this.search.srchDateStart = search.srchDateStart
        if(search.srchDateEnd != undefined) this.search.srchDateEnd = search.srchDateEnd
        if(search.ordStatus != undefined) this.search.ordStatus = search.ordStatus
        if(search.srchAccessMethod != undefined) this.search.srchAccessMethod = search.srchAccessMethod
        if(search.srchPayKind != undefined) this.search.srchPayKind = search.srchPayKind
        if(search.srchType != undefined) this.search.srchType = search.srchType
        if(search.srchKeyword != undefined) this.search.srchKeyword = search.srchKeyword
        this.search.srchPage = Number(search.srchPage)
        this.search.srchPageCnt = Number(search.srchPageCnt)
      } else this.onReset()

      this.getItemList()
    },
    onReset () {
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      // this.search.srchDateStart = moment(new Date()).format('YYYY-MM-DD')
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      this.search.srchAccessMethod = ''
      this.search.srchPayKind = ''
      this.search.ordStatus = ''
      this.search.srchType = ''
      this.search.srchKeyword = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onList()
    },
    onList() {
      // this.$router.push({path: '/order/list', query: { search: this.objectSerialize(this.search)}})
      this.getItemList()
    },

    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.onList()
    },
    getItemList () {
      let params = {
        srchDateStart: this.search.srchDateStart,     // 시작일
        srchDateEnd: this.search.srchDateEnd,         // 종료일
        accessMethod: this.search.srchAccessMethod,   // 유입경로
        payKind: this.search.srchPayKind,             // 결제수단
        ordStatus: this.search.ordStatus,         // 결제상태
        srchType: this.search.srchType,               // 검색조건
        srchKeyword: this.search.srchKeyword,         // 검색어
        srchPage: this.search.srchPage,               // 불필요
        srchPageCnt: this.search.srchPageCnt          // 불필요
      }
      return this.$api.api.getOrderList(params).then(resp => {
        this.itemList = resp.data.data.orderLists
        this.totalCnt = resp.data.data.seachCount
        this.newCnt = resp.data.data.newOrderCount
        this.totalPrdPriceOrg = resp.data.data.totalPrdPriceOrg
        this.totalPayAmount = resp.data.data.totalPayAmount
        this.totalPoint = resp.data.data.totalPoint

      }).catch(error => {
        console.log(error)
      })
    },
    goDetail (ordIdx) {
      this.$router.push({path: '/order/detail', query: { ordIdx: ordIdx, search: this.objectSerialize(this.search)}})
    },
    onChangeStatus () {
      if (!this.chkList.length) {
        //this.alert('상태 변경할 주문을 선택해주세요.')
        this.alert('상태 변경할 주문을 선택해주세요.')
        return false
      }
      this.confirm('선택하신 주문을 배송준비로 상태 변경하시겠습니까?', () => {
          let params = {
            odvStatus: 1,
            orderIdxs: []
          }
          this.chkList.map(data => {
            params.orderIdxs.push(data.ordIdx)
          })
          params.orderIdxs = Array.from(new Set(params.orderIdxs))
          this.$api.api.changeDeliveryStatus(params).then(resp => {
            if (resp.status === 200) {
                if(resp.data.result) {
                  //this.alert('배송준비상태로 변경되었습니다.')
                  this.alert('배송준비상태로 변경되었습니다.')
                  this.getItemList()
                }else{
                  this.alert(resp.data.errMsg)
                  return
                }
            }
          }).catch(error => {
            console.log(error)
          })
      })
    },
    exportExcel () {
      let params = {
        srchDateStart: this.search.srchDateStart,     // 시작일
        srchDateEnd: this.search.srchDateEnd,         // 종료일
        accessMethod: this.search.srchAccessMethod,   // 유입경로
        payKind: this.search.srchPayKind,             // 결제수단
        ordStatus: this.search.ordStatus,         // 결제상태
        srchType: this.search.srchType,               // 검색조건
        srchKeyword: this.search.srchKeyword,         // 검색어
        srchPage: this.search.srchPage,               // 불필요
        srchPageCnt: this.search.srchPageCnt          // 불필요
      }
      return this.$api.api.exportOrderList(params).then(resp => {
        if (resp.status === 200) {
          this.$toast.success('엑셀 다운로드 성공')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    callPaymentPop (ordIdx) {
      this.$modal.show(
        PaymentPop,
        { text: '결제정보', ordIdx: ordIdx },
        { draggable: false, resizable: false, height: 'auto', adaptive: true, maxWidth: 400 },
        {
          'before-close': event => {
          }
        }
      )
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            this.getItemList()
          }
        }
      )
    },
    setDateStart (value) {
      this.search.srchDateStart = value
    },
    setDateEnd (value) {
      this.search.srchDateEnd = value
    }
  },
  watch: {
    'search.srchPayKind': function () {
      if (this.search.srchPayKind === 1 || this.search.srchPayKind === 3) {
        this.search.ordStatus = ''
        this.ordStatusList = [
          { value: 3, text: '결제완료'}
        ]
      } else if (this.search.srchPayKind === 2) {
        this.search.ordStatus = ''
        this.ordStatusList = [
          { value: 2, text: '입금대기'},
          { value: 7, text: '입금대기취소'}
        ]
      } else {
        this.search.ordStatus = ''
        this.ordStatusList = [
          { value: 2, text: '입금대기'},
          { value: 7, text: '입금대기취소'},
          { value: 3, text: '결제완료'}
        ]
      }
    }
  },
}
</script>

<style scoped>

</style>
