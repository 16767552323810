<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'배송비관리'" :nav-list="['맥케이슨관리', '기본정보관리', '배송비관리']" />
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">기본 배송비 설정</div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">유료 배송비</label>
              <div class="col-sm-5">
                <input type="text" class="form-control w-50 d-inline" v-model="form.prdDeliFee" @input="getComma(form.prdDeliFee)" maxlength="7">
                <p class="w-25 d-inline">원</p>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">조건 배송비</label>
              <div class="col-sm-5">
                <select class="form-control w-50 d-inline" v-model="form.prdDeliFeeFreeCondi">
                  <option value="30000">30,000</option>
                  <option value="50000">50,000</option>
                  <option value="100000">100,000</option>
                  <option value="150000">150,000</option>
                  <option value="200000">200,000</option>
                  <option value="250000">250,000</option>
                  <option value="300000">300,000</option>
                </select>
                <p class="w-25 d-inline">원 이상 구매 시 배송비 무료</p>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100" @click="onUpdateBasic">수정</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">특수지역 추가 배송비 설정</div>
          <div class="table-responsive">
            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>
                <th class="text-center">특수지역명(도서, 산간 등)</th>
                <th class="text-center">우편번호 범위</th>
                <th class="text-center">지역 추가 배송비</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!datas.length">
                <td colspan="4" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-else v-for="item in datas">
                <td class="text-center text-muted"><input type="checkbox" data-toggle="toggle" :value="item.idx" data-onstyle="success" v-model="selectedList" @click="select"></td>
                <td class="text-center"><a href="javascript:void(0)" @click="onUpdate(item.idx)">{{item.regionName}}</a></td>
                <td class="text-center">[{{item.scode}}] 부터 [{{item.ecode}}] 까지</td>
                <td class="text-center">{{item.addfee | comma }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">선택삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavigationBar from '@/page/components/NavigationBar'
import FeePop from "@/page/basic/Fee/components/FeePop";
import {CommonMixin} from "@/components/mixins/CommonMixin";
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'FeeList',
  data() {
    return {
      datas: [],
      selectedList: [],
      allSelected: false,
      feeData: {},
      form: {
        prdDeliFee: '',
        prdDeliFeeFreeCondi: ''
      }
    }
  },
  components: {
    NavigationBar
  },
  mixins: [CommonMixin, InterfaceMixin],
  mounted() {
    this.getList()
  },
  computed: {
  },
  methods: {
    _isInvalidateInputForm () {
      if (this.form.prdDeliFee < 1000) {
        this.alert('유료배송비는 최소 1000원 이상으로 입력해주세요')
        return false
      }
      if (this.form.prdDeliFee % 100 > 0) {
        this.alert('유료배송비는 최소 100원단위 이상으로 입력해주세요')
        return false
      }
      return true
    },
    checkedAll() {
      this.selectedList = []
      if (!this.allSelected) {
        for (let i = 0; i < this.datas.length; i++) {
          this.selectedList.push(this.datas[i].idx)
        }
      }
    },
    select() {
      this.allSelected = false
    },
    getList() {
      this.$api.api.getFeeList().then(resp => {
        if (resp.data.result) {
          this.datas = resp.data.data.addFeeDataList
          this.form.prdDeliFee = resp.data.data.feeData.prdDeliFee
          this.form.prdDeliFeeFreeCondi = resp.data.data.feeData.prdDeliFeeFreeCondi
          this.getComma(this.form.prdDeliFee)
        } else {
          this.alert('조회 실패')
        }
      })
    },
    onCreate() {
      this.$modal.show(
        FeePop,
        {},
        {draggable: false, resizable: false, height: 'auto', width: 700},
        {
          'before-close': event => {
            if (event.params) {
              this.getList()
            }
          }
        }
      )
    },
    onUpdate(idx) {
      this.$modal.show(
        FeePop,
        {feeIdx: idx},
        {draggable: false, resizable: false, height: 'auto', width: 700},
        {
          'before-close': event => {
            if (event.params) {
              this.getList()
            }
          }
        }
      )
    },
    onDelete() {
      if (this.selectedList.length <= 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }

      this.confirm('삭제 하시겠습니까?', () => {
        this.$api.api.deleteFee({addFeeIdxList: this.selectedList}).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.')
            this.getList()
          }
        })
      })
    },
    onUpdateBasic() {
      if(!this.form.prdDeliFee) {
        this.alert('유료배송비를 입력해주세요.')
        return false
      }
      this.form.prdDeliFee = parseInt(this.form.prdDeliFee.replace(/[^0-9]/g, ''))
      if(!this._isInvalidateInputForm()){
        this.form.prdDeliFee = this.numberComma(this.form.prdDeliFee)
        return
      }
      this.confirm('수정 하시겠습니까?', () => {
        this.$api.api.updateBasicFee(this.form).then(resp => {
          if (resp.data.result) {
            this.alert('기본 배송비가 수정되었습니다.')
            this.getList()
          } else {
            this.alert(resp.data.errMsg)
            this.getList()
          }
        })
      })
    },
    numberComma (value) {
      let reg = /(^[+-]?\d+)(\d{3})/
      value += ''
      value = value.replace(/,/gi, '')
      while (reg.test(value)) {
        value = value.replace(reg, '$1' + ',' + '$2')
      }
      return value
    },
    getComma (value) {
      let checkNum = value.toString().replace(/[^0-9]/g, '')
      this.form.prdDeliFee = this.numberComma(checkNum)
    }
  }
}
</script>

<style scoped>

</style>
