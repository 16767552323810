import { actionType } from '@/store/const/StoreType'
import moment from 'moment'

import 'viewerjs/dist/viewer.css'

export const CommonMixin = {
  data () {
    return {
      loader: Object,
      nowTime: '',
    }
  },
  mixins: {
    // InterfaceMixin
    // Mixins
  },
  computed: {
  },
  created () {
    this.loadUserData()

    let timerId = setInterval(() => {
      this.nowTime = Number(moment())
    }, 500)
    this.$once('hook:beforeDestroy', () => {
      setTimeout(() => {
        clearInterval(timerId)
      })
    })
  },
  methods: {
    loadUserData () {
      if (sessionStorage.getItem('userData')) {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        this.$store.dispatch(actionType.SET_USER_DATA, userData).then()
      }
    },
    /**
     * 날짜형변환
     */
    dateFormat (str, fmt) {
      if (this.checkNull(str) === true) return str
      return moment(str).format(fmt.replace('yyyy', 'YYYY').replace('dd', 'DD')) // 'YYYY-MM-DD HH:mm:ss'
    },
    /**
     * 화면 block 처리
     */
    blockUI () {
      this.loader = this.$loading.show({
        container: null,
        // canCancel: false,
        onCancel: this.onCancel,
      })
    },
    /**
     * 화면 unBlock 처리
     */
    unBlockUI () {
      this.loader.hide()
    },
    /**
     * Data Null check
     */
    checkNull (value) {
      return typeof value === undefined || value === null || value === ''
    },
    /**
     * 이메일 마스킹
     * ex1) 원본 데이터 : abcdefg12345@naver.com
     *      변경 데이터 : ab**********@naver.com
     * ex2) 원본 데이터 : abcdefg12345@naver.com
     *      변경 데이터 : ab**********@nav******
     */
    maskEmail (value, length = 3) {
      let originStr = value
      // let emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
      let strLength

      /*
      if (this.checkNull(originStr) === true || this.checkNull(emailStr) === true) {
        return originStr
      } else {
        strLength = emailStr.toString().split('@')[0].length - length
        // ex1) abcdefg12345@naver.com => ab**********@naver.com
        return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*')
        //
        // ex2) abcdefg12345@naver.com => ab**********@nav******
        // return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*').replace(/.{6}$/, "******")
      }
      */
      return originStr
    },
    /**
     * 휴대폰 번호 마스킹
     * ex1) 원본 데이터 : 01012345678,
     *      변경 데이터 : 010****5678
     * ex2) 원본 데이터 : 010-1234-5678,
     *      변경 데이터 : 010-****-5678
     * ex3) 원본 데이터 : 0111234567,
     *      변경 데이터 : 011***4567
     * ex4) 원본 데이터 : 011-123-4567,
     *      변경 데이터 : 011-***-4567
     */
    maskPhone (value) {
      let originStr = value
      let phoneStr
      let maskingStr
      /*
      if (this.checkNull(originStr) === true) return originStr

      if (originStr.toString().split('-').length !== 3) {
        // 1) -가 없는 경우
        phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi)
        if (this.checkNull(phoneStr) === true) return originStr

        // 1.1) 0110000000
        if (originStr.length < 11) maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'))
        // 1.2) 01000000000
        else maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'))
      } else {
        // 2) -가 있는 경우
        phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi)
        if (this.checkNull(phoneStr) === true) return originStr

        // 2.1) 00-000-0000
        if (/-[0-9]{3}-/.test(phoneStr)) maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, "-***-"))
        // 2.2) 00-0000-0000
        else if (/-[0-9]{4}-/.test(phoneStr)) maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, "-****-"))
      }
      return maskingStr
      */
      return originStr
    },
    /**
     *  이름 마스킹
     *  ex1) 원본 데이터 : 홍길동,
     *       변경 데이터 : 홍길*
     *  ex2) 원본 데이터 : 가나다라,
     *       변경 데이터 : 가나**
     *  ex3) 원본 데이터 : 홍길,
     *       변경 데이터 : 홍*
     */
    maskName (value) {
      let originStr = value
      let maskingStr
      let strLength

      /*
      if (this.checkNull(originStr) === true) {
        return originStr
      }
      strLength = originStr.length
      if (strLength < 3) maskingStr = originStr.replace(/(?<=.{1})./gi, "*")
      else maskingStr = originStr.replace(/(?<=.{2})./gi, "*")

      return maskingStr
      */
      return originStr
    },
    ellipsis (value, length) {
      if (this.checkNull(value) === true) return value
      return value.length > length ? value.substring(0, length-1) + '...' : value
    },
    getImagePath (fileFullPath) {
      if (!fileFullPath) return ''
      if (fileFullPath.substr(0, 1) === '/') fileFullPath = fileFullPath.substr(1);
      return this.$utils.common.imageServer(fileFullPath)
    },
    getImageFullpath (filePath, filename) {
      if (!filePath || !filename) {
        return
      }
      return this.getImagePath(filePath) + '/' + filename
    },
    /**
     *  패스워드 검증
     *  영문, 숫자, 특수문자 조합(8자리 이상 15자리 이하)
     */
    checkPassword (str) {
      return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/.test(str)
    },

    /**
     * 객체 직렬화
     */

    objectSerialize (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p) && encodeURIComponent(obj[p])) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },

    objectDeserialize(qs) {
      qs = qs || location.search.slice(1);

      var pairs = qs.split('&');
      var result = {};
      pairs.forEach(function(p) {
        var pair = p.split('=');
        var key = pair[0];
        var value = decodeURIComponent(pair[1] || '');

        if( result[key] ) {
          if( Object.prototype.toString.call( result[key] ) === '[object Array]' ) {
            result[key].push( value );
          } else {
            result[key] = [ result[key], value ];
          }
        } else {
          result[key] = value;
        }
      });

      return JSON.parse(JSON.stringify(result));
    },
    isEmail(email) {
      let reg = /^([a-z0-9_\\.-]+)@([\da-z\\.-]+)\.([a-z\\.]{2,6})$/
      if (reg.test(email)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    comma: (val) => {
      if (!val) return '0'
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    dash: (val) => {
      if (!val) return '0'
      return String(val).replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")
    },
    isValidPhone (phone) {
      let regPhone = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/
      return phone ? phone.match(regPhone) !== null : false
    },
    isValidEmail (email) {
      let regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
      return email.match(regEmail) !== null
    }
  }
}
