import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    children: [
        {
            path: '/statOrder/list',
            name: 'StatOrderList',
            component: () => import('@/page/stat/StatOrderList')
        },
        {
            path: '/statSales/list',
            name: 'StatSalesList',
            component: () => import('@/page/stat/StatSalesList')
        }
    ]
}
