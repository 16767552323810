<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <h6 class="card-title mb-0">사내 판매 카테고리 할인율 수정</h6>
        </div>
        <div class="card-body">
          <div class="position-relative row form-group">
            <!--기간-->
            <label class="col-sm-3 col-form-label label-wrapper">
              <span class="required">*</span>
              <span>기간</span>
            </label>
            <div class="col-sm-9">
              <date-picker
                style="width:100%"
                v-model="modifyForm.pickedDate"
                format="YYYY-MM-DD HH"
                value-type="format"
                :editable="false"
                :clearable="false"
                type="datetime"
                :range="true"
                :confirm="true"
              />
            </div>
            <!--할인율-->
            <label class="col-sm-3 col-form-label label-wrapper">
              <span class="required">*</span>
              <span>할인율</span>
            </label>
            <div class="col-sm-9">
              <input
                type="text"
                style="width: 70%"
                class="form-control d-inline-block mx-input mr-2 mb-2 mt-2"
                v-model="modifyForm.discountPercentage"
                placeholder="숫자만 입력하세요"
                maxlength="3"
                v-number-input
              />
              <span class="notice-title">* 100이하의 정수</span>
              <div class="velidateMessage" v-if="validateNum">
                숫자만 입력가능합니다
              </div>
              <div class="velidateMessage" v-if="rangeOverNum">
                100이하의 정수만 입력 가능합니다.
              </div>
            </div>
            <!--적용 품범리스트-->
            <label class="col-sm-3 col-form-label label-wrapper">
              <span class="required">*</span>
              <span>카테고리</span>
            </label>
            <div class="col-sm-9">
              <div class="col-6 pl-0 pr-10 float-left">
                <select class="form-control" v-model="modifyForm.cat1Idx" @change="modifyForm.cat2Idx = ''">
                  <option value="">선택</option>
                  <option v-for="item in cat1List" :value="item.cat1Idx">{{item.cat1Name}}</option>
                </select>
              </div>

              <div class="col-6 pl-0 pr-0 float-left">
                <select class="form-control" v-model="modifyForm.cat2Idx">
                  <option value="">선택</option>
                  <option v-for="item in cat2List" :value="item.cat2Idx" v-if="modifyForm.cat1Idx === item.cat1Idx" :data-name="item.cat2Name">{{item.cat2Name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-left">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">
                취소
              </button>
            </div>
            <div class="float-right">
              <button
                class="btn btn-success wd-100 ml-1"
                @click="modifyDiscountInHouseCat"
              >
                수정
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko.js";
import moment from "moment";
import { CommonMixin } from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";
export default {
  name: "DiscountInHousePop.vue",
  components: {
    DatePicker
  },
  data() {
    return {
      modifyForm: {
        pickedDate: [],
        discountPercentage: '',
        cat1Idx: '',
        cat2Idx: ''
      }
    };
  },
  mixins: [CommonMixin, InterfaceMixin],
  props: {
    discountInfo: {
      type: Object
    },
    cat1List: {
      type: Array
    },
    cat2List: {
      type: Array
    }
  },
  created() {},
  mounted() {
    console.log('this.discountInfo.cat1Idx :: ', this.discountInfo.cat1Idx);
    console.log('this.discountInfo.cat2Idx :: ', this.discountInfo.cat2Idx);
    console.log('catList ::: ', this.cat1List);
    this.modifyForm = {
      pickedDate: [this.discountInfo.strtDt, this.discountInfo.endDt],
      discountPercentage: this.discountInfo.disRate,
      cat1Idx: this.discountInfo.cat1,
      cat2Idx: this.discountInfo.cat2
    };
  },
  computed: {
    validateNum() {
      return isNaN(this.modifyForm.discountPercentage);
    },
    rangeOverNum() {
      return Number(this.modifyForm.discountPercentage) > 100;
    }
  },
  methods: {
    modifyDiscountInHouseCat() {
      let isValid = this.checkValidate();

      if (isValid) {
        let messageBody = {
          disIdx: this.discountInfo.disIdx,
          disRate: parseInt(this.modifyForm.discountPercentage),
          disType: this.modifyForm.applyMethed,
          strtDt: moment(this.modifyForm.pickedDate[0]).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endDt: moment(this.modifyForm.pickedDate[1]).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          cat1: this.modifyForm.cat1Idx,
          cat2: this.modifyForm.cat2Idx === '' ? null : this.modifyForm.cat2Idx
        };
        this.$api.api.modifyDiscountInHouseCat(messageBody).then(res => {
            if (res.data.result) {
              this.alert("할인정보가 수정되었습니다", () => {
                this.resetForm();
                this.$emit("close", true);
              });
            } else {
              this.alert(res.data.errMsg);
            }
          })
          .catch(err => {
            console.error(`axios catch err [modifyDiscountInHouse]`, err.response);
          });
      }
    },
    onCancle() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.modifyForm = {
        pickedDate: [],
        discountPercentage: "",
        itemList: ""
      };
    },
    checkValidate() {
      if (!Number.isInteger(parseFloat(this.modifyForm.discountPercentage))) {
        this.alert("할인율은 정수를 입력해주세요");
        return false;
      }
      if (this.modifyForm.discountPercentage < 0) {
        this.alert("할인율은 0보다 큰 값을 입력해주세요.");
        return false;
      }
      if (this.modifyForm.discountPercentage > 100) {
        this.alert("할인율은 100이하의 값을 입력해주세요.");
        return false;
      }
      if (this.modifyForm.cat1Idx === "") {
        this.alert("카테고리1을 선택해주세요.");
        return false;
      }
      return true;
    }
  }
};
</script>

<style>
.vm--modal {
  height: auto !important;
}
.label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.velidateMessage {
  margin-left: 5px;
  font-size: smaller;
  color: red;
}
.notice-title {
  color: red;
  font-size: smaller;
}
</style>
