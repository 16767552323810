<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'할인제한관리'" :nav-list="['맥케이슨관리', '사내판매관리', '할인제한관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">할인제한 정보</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>제목</label>
              <div class="col-sm-10">
                <input type="text" class="form-control d-inline-block w-75" placeholder="제목을 입력해주세요." v-model="restrictionInfo.restrictionName" maxlength="30" />
                <label class="ml-2">30자 이내 ({{ restrictionInfo.restrictionName.length }}/30)</label>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>진행 기간</label>
              <div class="col-sm-10">
                <date-picker v-model="restrictionInfo.restrictionSd" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" :disabled-date="notAfterToday" />
                <label class="col-form-label ml-2 mr-2">~</label>
                <date-picker v-model="restrictionInfo.restrictionEd" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" :disabled-date="notBeforeToday" />
              </div>
            </div>
<!--            <div class="position-relative row form-group">-->
<!--              <label class="col-sm-2 col-form-label"><span class="required">*</span>전시여부</label>-->
<!--              <div class="col-sm-10">-->
<!--                <div class="custom-radio custom-control custom-control-inline"><input type="radio" :checked="restrictionInfo.restrictionIsUse === 1" class="custom-control-input" value="1" v-model="restrictionInfo.restrictionIsUse" id="restrictionIsUse_yes"><label class="custom-control-label" for="restrictionIsUse_yes">전시</label></div>-->
<!--                <div class="custom-radio custom-control custom-control-inline"><input type="radio" :checked="restrictionInfo.restrictionIsUse === 0" class="custom-control-input" value="0" v-model="restrictionInfo.restrictionIsUse" id="restrictionIsUse_no"><label class="custom-control-label" for="restrictionIsUse_no">비전시</label></div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="isEdit" @click="onUpdate">수정</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="!isEdit" @click="onCreate">등록</button>
      </div>
    </div>

  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import moment from 'moment'
import CodeMixin from "@/mixins/code-mixin";

export default {
  name: "RestrictionModify",
  data () {
    return {
      isEdit: false,
      restrictionInfo: {
        restrictionIdx: '',
        restrictionName: '',   // 제목
        restrictionSd: '',     // 진행시작일
        restrictionEd: ''     // 진행종료일
        // restrictionIsUse: 1    // 전시여부
      },
      tmpSd: '',
      tmpEd: '',
      datas: [],
    }
  },
  components: {
    NavigationBar,
    DatePicker,
  },
  mixins: [
    CommonMixin,
    InterfaceMixin,
    CodeMixin
  ],
  computed: {
  },
  created () {
    console.log('[MCKS] restrictionIdx::: created::: ', this.$route.query.restrictionIdx)
    let restrictionIdx = this.$route.query.restrictionIdx
    if (restrictionIdx) {
      this.isEdit = true
      this.restrictionInfo.restrictionIdx = restrictionIdx
      this.getDetail()
    }
  },
  mounted () {
  },
  methods: {
    _isInvalidateInputForm () {
      if (!this.restrictionInfo.restrictionName) {
        this.alert('제목을 입력해주세요.')
        return false
      }
      if (this.restrictionInfo.restrictionName.length > 30) {
        this.alert('제목은 최대 30자리 이상 입력할 수 없습니다.')
        return false
      }

      if (this.restrictionInfo.restrictionSd > this.restrictionInfo.restrictionEd) {
        this.alert('시작일보다 종료일이 빠릅니다. 다시 입력해주세요.')
        return false
      }

      return true
    },
    getDetail () {
      let params = {
        nonIdx: this.restrictionInfo.restrictionIdx
      }
      this.$api.api.getDiscountRestrictionDetail(params).then(resp => {
        if (resp.data.result) {
          this.restrictionInfo.restrictionIdx = resp.data.data.nonIdx
          this.restrictionInfo.restrictionName = resp.data.data.nonTitle
          this.restrictionInfo.restrictionSd = resp.data.data.nonBegin
          this.restrictionInfo.restrictionEd = resp.data.data.nonEnd
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onList () {
      this.$router.push({path: '/restriction/list'})
    },
    onCreate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 할인제한 등록 실패 입력 FORM 오류')
        return
      }

      this.confirm('등록 하시겠습니까?', () => {
        let body = {
          nonBegin: this.restrictionInfo.restrictionSd,
          nonEnd: this.restrictionInfo.restrictionEd,
          nonTitle: this.restrictionInfo.restrictionName
        }

        this.$api.api.regDiscountRestriction(body).then(resp => {
          if (resp.data.result) {
            this.alert('등록되었습니다.', () => {
              this.onList()
            })
          } else {
            this.alert('등록 실패')
          }
        })
      })
    },
    onUpdate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 프로모션 수정 실패 입력 FORM 오류')
        return
      }

      this.confirm('수정 하시겠습니까?', () => {
        let body = {
          nonIdx: this.restrictionInfo.restrictionIdx,
          nonBegin: this.restrictionInfo.restrictionSd,
          nonEnd: this.restrictionInfo.restrictionEd,
          nonTitle: this.restrictionInfo.restrictionName
        }
        this.$api.api.updDiscountRestriction(body).then(resp => {
          if(resp.data.result) {
            this.alert('수정되었습니다.', () => {
              this.onList()
            })
          } else {
          this.alert('수정 실패')
          }
        })
      })
    },
    notAfterToday (date) {
       return moment(date).format('YYYY-MM-DD') > moment(this.restrictionInfo.restrictionEd).format('YYYY-MM-DD')
    },
    notBeforeToday (date) {
      return moment(date).format('YYYY-MM-DD') < moment(this.restrictionInfo.restrictionSd).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
