var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "card-header-title" }, [
            _vm._v(
              _vm._s(_vm._f("claimTypeCode")(_vm.claimType)) +
                " " +
                _vm._s(_vm.text)
            )
          ]),
          _c("div", { staticClass: "btn-actions-pane-right" }, [
            _c(
              "button",
              {
                staticClass: "close float-none",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ])
        ]),
        _c(
          "div",
          {
            staticStyle: {
              "max-height": "800px",
              "overflow-y": "scroll",
              width: "100%"
            }
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title" }, [_vm._v("유입경로")]),
              _c("table", { staticClass: "mb-0 table table-detail" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.order.admOrderDetailInfo.accessMethod))
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.dateFormat(
                            _vm.order.admOrderDetailInfo.ordRgdt,
                            "YYYY-MM-DD HH:mm"
                          )
                        ) +
                          " (" +
                          _vm._s(
                            _vm.dateFormat(
                              _vm.order.admOrderDetailInfo.payDt,
                              "YYYY-MM-DD HH:mm"
                            )
                          ) +
                          ")"
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.order.admOrderDetailInfo.ordNo))
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(
                            _vm.order.admOrderDetailPayInfo.payAmount
                          )
                        ) + "원"
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("payKind")(
                            _vm.order.admOrderDetailPayInfo.payKind
                          )
                        )
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskName(_vm.order.admOrderDetailOrder.userName)
                        )
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskName(
                            _vm.order.admOrderDetailRecepient.recipient
                          )
                        )
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.callMemoPop(
                                _vm.order.admOrderDetailInfo.ordIdx,
                                _vm.order.admOrderDetailInfo.ordNo
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("comma")(
                                _vm.order.admOrderDetailInfo.memoCnt
                              )
                            )
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "col-12 pr-0 pl-0 mb-2" }, [
                _vm._m(1),
                _c("div", { staticClass: "float-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary mb-2 mr-2 wd-150",
                      on: {
                        click: function($event) {
                          return _vm.removeProduct()
                        }
                      }
                    },
                    [_vm._v("선택 상품 삭제")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary mb-2 mr-2 wd-150",
                      on: {
                        click: function($event) {
                          return _vm.addProduct()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm._f("claimTypeCode")(_vm.claimType)) +
                          " 상품 추가"
                      )
                    ]
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "282px",
                    "overflow-y": "scroll",
                    width: "100%"
                  }
                },
                [
                  _c("table", { staticClass: "mb-0 table table-detail" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.chkAll,
                                expression: "chkAll"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.chkAll)
                                ? _vm._i(_vm.chkAll, null) > -1
                                : _vm.chkAll
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.chkAll,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.chkAll = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.chkAll = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.chkAll = $$c
                                }
                              }
                            }
                          })
                        ]),
                        _c(
                          "th",
                          [
                            _vm._v("상품별 주문번호 "),
                            _vm.claimType != "cancel"
                              ? [_c("br"), _vm._v("/송장번호")]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("th", { staticClass: "wd-100" }, [
                          _vm._v("상품번호")
                        ]),
                        _c("th", { staticClass: "w-50" }, [
                          _vm._v("상품명/옵션")
                        ]),
                        _c("th", [_vm._v("수량")]),
                        _vm.claimType != "exchange"
                          ? _c("th", [
                              _vm._v(
                                _vm._s(_vm._f("claimTypeCode")(_vm.claimType)) +
                                  "수량"
                              )
                            ])
                          : _vm._e(),
                        _c("th", [_vm._v("판매가")]),
                        _c("th", [_vm._v("상품금액")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.itemList, function(product, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "text-center" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.chkList,
                                    expression: "chkList"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: product,
                                  checked: Array.isArray(_vm.chkList)
                                    ? _vm._i(_vm.chkList, product) > -1
                                    : _vm.chkList
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.chkList,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = product,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.chkList = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.chkList = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.chkList = $$c
                                    }
                                  }
                                }
                              })
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center wd-200" },
                              [
                                _vm._v(
                                  _vm._s(product.ordNo + "_" + product.opoIdx) +
                                    " "
                                ),
                                _vm.claimType != "cancel"
                                  ? [
                                      _c("br"),
                                      _vm._v(
                                        "(" +
                                          _vm._s(product.deliveryComp) +
                                          " / " +
                                          _vm._s(product.deliveryNo) +
                                          ")"
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c("td", { staticClass: "text-center wd-150" }, [
                              _vm._v(_vm._s(product.prdNo))
                            ]),
                            _c("td", { staticClass: "text-left" }, [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(product.ordProduct))
                              ]),
                              _c(
                                "span",
                                { staticClass: "mb-0 bg-arielle-smile" },
                                [
                                  _vm._v(
                                    "옵션1 : " + _vm._s(product.opoColName)
                                  ),
                                  product.opoSize
                                    ? [
                                        _vm._v(
                                          " / 옵션2 : " +
                                            _vm._s(product.opoSize)
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm.claimType === "exchange"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: product.opt1Data,
                                              expression: "product.opt1Data"
                                            }
                                          ],
                                          staticClass:
                                            "custom-select wd-150 mr-1",
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  product,
                                                  "opt1Data",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function($event) {
                                                return _vm.changeOpt1(
                                                  $event,
                                                  product.prdIdx,
                                                  index,
                                                  _vm.privatePrdOptMapList[
                                                    index
                                                  ],
                                                  product
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "" } },
                                            [_vm._v("선택해주세요.")]
                                          ),
                                          _vm.privatePrdOptMapList[index][
                                            product.prdIdx
                                          ]
                                            ? _vm._l(
                                                _vm.privatePrdOptMapList[index][
                                                  product.prdIdx
                                                ].opt1List,
                                                function(opt1, index) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: opt1.optIdx,
                                                      domProps: { value: opt1 }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(opt1.colName)
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: product.opt2Data,
                                              expression: "product.opt2Data"
                                            }
                                          ],
                                          staticClass:
                                            "custom-select wd-100 ml-1",
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  product,
                                                  "opt2Data",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function($event) {
                                                return _vm.changeOpt2(
                                                  $event,
                                                  product.prdIdx,
                                                  index,
                                                  _vm.privatePrdOptMapList[
                                                    index
                                                  ]
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "" } },
                                            [_vm._v("선택해주세요.")]
                                          ),
                                          _vm.privatePrdOptMapList[index][
                                            product.prdIdx
                                          ]
                                            ? _vm._l(
                                                _vm.privatePrdOptMapList[index][
                                                  product.prdIdx
                                                ].opt2List,
                                                function(opt2) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      domProps: { value: opt2 }
                                                    },
                                                    [_vm._v(_vm._s(opt2.size))]
                                                  )
                                                }
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: product.exchangeCount,
                                              expression:
                                                "product.exchangeCount"
                                            }
                                          ],
                                          staticClass:
                                            "custom-select wd-100 ml-1",
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                product,
                                                "exchangeCount",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          product.exchangeMax < 1
                                            ? _c(
                                                "option",
                                                { domProps: { value: 0 } },
                                                [_vm._v("0")]
                                              )
                                            : _vm._e(),
                                          _vm._l(product.exchangeMax, function(
                                            count
                                          ) {
                                            return product.exchangeMax > 0
                                              ? _c(
                                                  "option",
                                                  {
                                                    domProps: { value: count }
                                                  },
                                                  [_vm._v(_vm._s(count))]
                                                )
                                              : _vm._e()
                                          })
                                        ],
                                        2
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary wd-100 ml-1 mr-1",
                                          on: {
                                            click: function($event) {
                                              return _vm.addExchange(index)
                                            }
                                          }
                                        },
                                        [_vm._v("교환선택")]
                                      ),
                                      _vm._l(
                                        product.exchangeProductList,
                                        function(exchangeProduct, exchangeIdx) {
                                          return _c(
                                            "p",
                                            { staticClass: "mb-0" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    exchangeProduct.colName
                                                  ) +
                                                  " / " +
                                                  _vm._s(exchangeProduct.size) +
                                                  " " +
                                                  _vm._s(
                                                    exchangeProduct.count
                                                  ) +
                                                  "개 "
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn ml-1 mr-1",
                                                  style: {
                                                    padding: "0px 5px 0px 5px",
                                                    color: "red"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeExchange(
                                                        index,
                                                        exchangeIdx
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("X")]
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]),
                            _c("td", { staticClass: "text-center wd-200" }, [
                              _vm._v(_vm._s(_vm._f("comma")(product.quantity)))
                            ]),
                            _vm.claimType != "exchange"
                              ? _c(
                                  "td",
                                  { staticClass: "text-center wd-200" },
                                  [
                                    product.quantityAvail > 0 &&
                                    (product.count
                                      ? product.count
                                      : (product.count = 1))
                                      ? [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: product.count,
                                                  expression: "product.count"
                                                }
                                              ],
                                              staticClass:
                                                "custom-select wd-75 ml-1",
                                              on: {
                                                change: [
                                                  function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      product,
                                                      "count",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function($event) {
                                                    _vm.refundInfo = null
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _vm.claimType == "cancel" &&
                                              _vm.order.isPartialCancel != 1
                                                ? _c(
                                                    "option",
                                                    {
                                                      domProps: {
                                                        value:
                                                          product.quantityAvail
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.quantityAvail
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                product.quantityAvail,
                                                function(count) {
                                                  return _vm.claimType !=
                                                    "cancel" ||
                                                    _vm.order.isPartialCancel ==
                                                      1
                                                    ? _c(
                                                        "option",
                                                        {
                                                          domProps: {
                                                            value: count
                                                          }
                                                        },
                                                        [_vm._v(_vm._s(count))]
                                                      )
                                                    : _vm._e()
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c("td", { staticClass: "text-center wd-200" }, [
                              _vm._v(
                                _vm._s(_vm._f("comma")(product.optPriceSale))
                              )
                            ]),
                            _c("td", { staticClass: "text-center wd-200" }, [
                              _vm._v(_vm._s(_vm._f("comma")(product.prdAmount)))
                            ])
                          ])
                        }),
                        _vm.giftList.length > 0
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { color: "#B40404" },
                                  attrs: { colspan: "8" }
                                },
                                [
                                  _vm._v(
                                    " [사은품] " + _vm._s(_vm.strGift) + " "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "card-body mb-0 pb-0" }, [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm._f("claimTypeCode")(_vm.claimType)) + " 사유")
              ]),
              _c("table", { staticClass: "mb-0 table table-detail" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(_vm._f("claimTypeCode")(_vm.claimType)) + " 사유"
                      )
                    ]),
                    _c("th", [_vm._v("책임선택")])
                  ])
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "w-25 text-center" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.claimInfo.claimCode,
                              expression: "claimInfo.claimCode"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.claimInfo,
                                  "claimCode",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.changeClaimCode
                            ]
                          }
                        },
                        [
                          _c("option", { attrs: { value: "0" } }, [
                            _vm._v("선택해주세요")
                          ]),
                          _vm._l(_vm.getReasonList, function(item) {
                            return _c(
                              "option",
                              {
                                key: item.value,
                                domProps: { value: item.value }
                              },
                              [_vm._v(_vm._s(item.text))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("td", { staticClass: "w-50 text-center" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.claimInfo.reason,
                            expression: "claimInfo.reason"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "상세사유를 작성해주세요.",
                          maxlength: 60
                        },
                        domProps: { value: _vm.claimInfo.reason },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.claimInfo,
                              "reason",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("td", { staticClass: "w-25 text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claimInfo.claimCharge,
                                expression: "claimInfo.claimCharge"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "ctype1",
                              disabled: ""
                            },
                            domProps: {
                              value: 1,
                              checked: _vm._q(_vm.claimInfo.claimCharge, 1)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.claimInfo, "claimCharge", 1)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "ctype1", disabled: "" }
                            },
                            [_vm._v("고객책임")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-radio custom-control custom-control-inline"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claimInfo.claimCharge,
                                expression: "claimInfo.claimCharge"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "radio",
                              id: "ctype2",
                              disabled: ""
                            },
                            domProps: {
                              value: 2,
                              checked: _vm._q(_vm.claimInfo.claimCharge, 2)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.claimInfo, "claimCharge", 2)
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "ctype2", disabled: "" }
                            },
                            [_vm._v("판매자책임")]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm.claimType === "return"
              ? _c("div", { staticClass: "card-body mb-0 pb-0" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("주소 정보")
                  ]),
                  _c("table", { staticClass: "mb-0 table table-detail" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "wd-150", attrs: { rowspan: "2" } },
                          [_vm._v("회수 주소")]
                        ),
                        _c("th", { staticClass: "wd-150" }, [_vm._v("성명")]),
                        _c("td", { staticClass: "text-left" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label mr-1",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.claim.returnAddress.name
                                )
                              }
                            },
                            [_vm._v("홍길동")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-1",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.callRecipientPop(
                                    _vm.claim.returnAddress
                                  )
                                }
                              }
                            },
                            [_vm._v("정보수정")]
                          )
                        ]),
                        _c("th", { staticClass: "wd-150" }, [_vm._v("연락처")]),
                        _c(
                          "td",
                          { staticClass: "wd-300" },
                          [
                            _c("the-mask", {
                              staticClass: "form-control",
                              attrs: {
                                type: "tel",
                                mask: ["###-####-####", "###-###-####"],
                                pattern: "\\d*",
                                maxlength: "13"
                              },
                              model: {
                                value: _vm.claim.returnAddress.phone,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.claim.returnAddress,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression: "claim.returnAddress.phone"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("주소")]),
                        _c("td", [
                          _vm._v(
                            "(" +
                              _vm._s(_vm.claim.returnAddress.postalCode) +
                              ") " +
                              _vm._s(_vm.claim.returnAddress.addr1) +
                              " " +
                              _vm._s(_vm.claim.returnAddress.addr2)
                          )
                        ]),
                        _c("th", [_vm._v("요청사항")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claim.returnAddress.comment,
                                expression: "claim.returnAddress.comment"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", maxlength: 20 },
                            domProps: {
                              value: _vm.claim.returnAddress.comment
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.claim.returnAddress,
                                  "comment",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.claimType === "exchange"
              ? _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("주소 정보")
                  ]),
                  _c("table", { staticClass: "table table-detail" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "wd-150", attrs: { rowspan: "2" } },
                          [_vm._v("회수주소")]
                        ),
                        _c("th", { staticClass: "wd-150" }, [_vm._v("성명")]),
                        _c("td", { staticClass: "text-left" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label mr-1",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.claim.returnAddress.name
                                )
                              }
                            },
                            [_vm._v("홍길동")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-1",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.callRecipientPop(
                                    _vm.claim.returnAddress
                                  )
                                }
                              }
                            },
                            [_vm._v("정보수정")]
                          )
                        ]),
                        _c("th", { staticClass: "wd-150" }, [_vm._v("연락처")]),
                        _c(
                          "td",
                          { staticClass: "wd-300" },
                          [
                            _c("the-mask", {
                              staticClass: "form-control",
                              attrs: {
                                type: "tel",
                                mask: ["###-####-####", "###-###-####"],
                                pattern: "\\d*",
                                maxlength: "13"
                              },
                              model: {
                                value: _vm.claim.returnAddress.phone,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.claim.returnAddress,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression: "claim.returnAddress.phone"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("주소")]),
                        _c("td", [
                          _vm._v(
                            "(" +
                              _vm._s(_vm.claim.returnAddress.postalCode) +
                              ") " +
                              _vm._s(_vm.claim.returnAddress.addr1) +
                              " " +
                              _vm._s(_vm.claim.returnAddress.addr2)
                          )
                        ]),
                        _c("th", [_vm._v("요청사항")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claim.returnAddress.comment,
                                expression: "claim.returnAddress.comment"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", maxlength: 20 },
                            domProps: {
                              value: _vm.claim.returnAddress.comment
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.claim.returnAddress,
                                  "comment",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ]),
                  _c("table", { staticClass: "table table-detail" }, [
                    _c("tbody", [
                      _c("tr", [
                        _vm._m(2),
                        _c("th", { staticClass: "wd-150" }, [_vm._v("성명")]),
                        _c("td", { staticClass: "text-left" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-form-label mr-1",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.claim.exchangeAddress.name
                                )
                              }
                            },
                            [_vm._v("홍길동")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-1",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.callRecipientPop(
                                    _vm.claim.exchangeAddress
                                  )
                                }
                              }
                            },
                            [_vm._v("정보수정")]
                          )
                        ]),
                        _c("th", { staticClass: "wd-150" }, [_vm._v("연락처")]),
                        _c(
                          "td",
                          { staticClass: "wd-300" },
                          [
                            _c("the-mask", {
                              staticClass: "form-control",
                              attrs: {
                                type: "tel",
                                mask: ["###-####-####", "###-###-####"],
                                pattern: "\\d*",
                                maxlength: "13"
                              },
                              model: {
                                value: _vm.claim.exchangeAddress.phone,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.claim.exchangeAddress,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression: "claim.exchangeAddress.phone"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("주소")]),
                        _c("td", [
                          _vm._v(
                            "(" +
                              _vm._s(_vm.claim.exchangeAddress.postalCode) +
                              ") " +
                              _vm._s(_vm.claim.exchangeAddress.addr1) +
                              " " +
                              _vm._s(_vm.claim.exchangeAddress.addr2)
                          )
                        ]),
                        _c("th", [_vm._v("요청사항")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claim.exchangeAddress.comment,
                                expression: "claim.exchangeAddress.comment"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", maxlength: 20 },
                            domProps: {
                              value: _vm.claim.exchangeAddress.comment
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.claim.exchangeAddress,
                                  "comment",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.claimType === "return"
              ? _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("반품 배송비")
                  ]),
                  _c("table", { staticClass: "table table-detail" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { staticClass: "wd-300" }, [_vm._v("배송비")]),
                        _c("td", { staticClass: "text-center5" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.claim.returnDeliFee,
                                expression: "claim.returnDeliFee"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", maxlength: "10" },
                            domProps: { value: _vm.claim.returnDeliFee },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.claim,
                                  "returnDeliFee",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "card-body d-block text-center clearfix" },
              [
                _vm.claimType === "cancel"
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary wd-200",
                          on: { click: _vm.calRefundAmount }
                        },
                        [_vm._v("환불예정금액 계산")]
                      )
                    ]
                  : _vm._e(),
                _vm.claimType === "return"
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary wd-200",
                          on: { click: _vm.calRefundAmount }
                        },
                        [_vm._v("환불예정금액 계산")]
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            (_vm.claimType === "cancel" || _vm.claimType === "return") &&
            _vm.refundInfo
              ? _c("div", { staticClass: "card-body pt-2" }, [
                  _c("table", { staticClass: "mb-0 table table-detail" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "wd-150" }, [
                          _vm._v("환불 예정 수단")
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              "wd-250": _vm.refundInfo.isRefundAccountInput
                            },
                            attrs: {
                              colspan: {
                                3: !_vm.refundInfo.isRefundAccountInput
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.refundInfo.refundMethodStr))]
                        ),
                        _vm.refundInfo.isRefundAccountInput
                          ? _c("th", { staticClass: "wd-150" }, [
                              _vm._v("환불계좌정보")
                            ])
                          : _vm._e(),
                        _vm.refundInfo.isRefundAccountInput
                          ? _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.refund.bankName,
                                      expression: "refund.bankName"
                                    }
                                  ],
                                  staticClass: "form-control wd-150 d-inline",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.refund,
                                        "bankName",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("은행선택")
                                  ]),
                                  _vm._l(_vm.bankList, function(bank) {
                                    return _c(
                                      "option",
                                      {
                                        key: bank.code,
                                        domProps: { value: bank.bankName }
                                      },
                                      [_vm._v(_vm._s(bank.bankName))]
                                    )
                                  })
                                ],
                                2
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.refund.accountNumber,
                                    expression: "refund.accountNumber"
                                  }
                                ],
                                staticClass: "form-control wd-250 d-inline",
                                attrs: {
                                  type: "text",
                                  maxlength: 30,
                                  placeholder: "계좌번호"
                                },
                                domProps: { value: _vm.refund.accountNumber },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.refund,
                                      "accountNumber",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.refund.accountHolder,
                                    expression: "refund.accountHolder"
                                  }
                                ],
                                staticClass: "form-control wd-150 d-inline",
                                attrs: {
                                  type: "text",
                                  maxlength: 10,
                                  placeholder: "예금주명"
                                },
                                domProps: { value: _vm.refund.accountHolder },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.refund,
                                      "accountHolder",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("상품 금액 합")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("comma")(_vm.refundInfo.productAmount)
                            )
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("기본배송비")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(
                            _vm._s(_vm._f("comma")(_vm.refundInfo.deliFee))
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("도서산간 배송비")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(
                            _vm._s(_vm._f("comma")(_vm.refundInfo.deliAddfee))
                          )
                        ])
                      ])
                    ]),
                    _c("tfoot", [
                      _c("tr", [
                        _c("th", [_vm._v("환불 예상 금액")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(
                            _vm._s(_vm._f("comma")(_vm.refundInfo.refundAmount))
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]
        ),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning wd-100 mr-2",
                  on: { click: _vm.onCancle }
                },
                [_vm._v("취소")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-success wd-100",
                  on: { click: _vm.complete }
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("claimTypeCode")(_vm.claimType)) + " 신청"
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("유입경로")]),
        _c("th", [_vm._v("주문일(결제일)")]),
        _c("th", [_vm._v("주문번호")]),
        _c("th", [_vm._v("결제금액")]),
        _c("th", [_vm._v("결제수단")]),
        _c("th", [_vm._v("주문자")]),
        _c("th", [_vm._v("수령자")]),
        _c("th", [_vm._v("메모")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "float-left" }, [
      _c("h5", { staticClass: "card-title float-left mb-0 mt-2" }, [
        _vm._v("상품정보")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "wd-150", attrs: { rowspan: "2" } }, [
      _vm._v("교환"),
      _c("br"),
      _vm._v("배송 주소")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }