<template>
    <div>
        <div class="app-page-title">
            <navigation-bar :nav-title="'판매내역'" :nav-list="['맥케이슨관리', '사내판매관리', '판매내역']"/>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="main-card card">
                    <div class="card-header">
                        <div class="col-12 pr-0 pl-0">
                            <div class="float-left">
                                <select class="form-control" v-model="search.year" @change="changeYear()">
                                    <option v-for="year in yearMap" :value="year">{{year}}</option>
                                </select>
                            </div>
                            <div class="float-left">
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">건수 : {{ totalCnt | comma }}건</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">주문건수 : {{ totalOrderCnt | comma }}건</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">주문수량 : {{ totalOrderQuantity | comma }}개</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">판매가 : {{ totalOpoOnlyPrdAmount | comma }}원</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">원가 : {{ totalOpoPrdBasicPrice | comma }}원</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">클레임건수 : {{ totalCpoCnt | comma }}건</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">클레임수량 : {{ totalCpoQuantity | comma }}개</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">클레임 판매가 : {{ totalCpoPrdAmount | comma }}원</h5>
                                <h5 class="card-title float-left mb-0 mt-2 ml-3" style="font-size: 13px;">클레임 원가 : {{ totalCpoPrdBasicPrice | comma }}원</h5>
                            </div>
                            <div class="float-right">
<!--                                <button @click="exportExcel" class="btn btn-primary">Excel Download</button>-->
                                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                                    <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th class="text-center">타입</th>
                                <th class="text-center">이름</th>
                                <th class="text-center">1월</th>
                                <th class="text-center">2월</th>
                                <th class="text-center">3월</th>
                                <th class="text-center">4월</th>
                                <th class="text-center">5월</th>
                                <th class="text-center">6월</th>
                                <th class="text-center">7월</th>
                                <th class="text-center">8월</th>
                                <th class="text-center">9월</th>
                                <th class="text-center">10월</th>
                                <th class="text-center">11월</th>
                                <th class="text-center">12월</th>
                                <th class="text-center">주문<br/>건수/수량</th>
                                <th class="text-center">클레임<br/>건수/수량</th>
                                <th class="text-center">총 판매금액</th>
                                <th class="text-center">총 원금액</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="!list.length">
                                <td :colspan="18" class="text-center">내역이 존재하지 않습니다.</td>
                            </tr>
                            <tr v-for="(item, idx) in list" :key="idx" v-else-if="list.length > 0">
                                <td class="text-center">{{item.memberTypeStr }}</td>
                                <td class="text-center">{{item.memberName}}</td>
                                <td class="text-center">{{item.jan | comma}}</td>
                                <td class="text-center">{{item.feb | comma}}</td>
                                <td class="text-center">{{item.mar | comma}}</td>
                                <td class="text-center">{{item.apr | comma}}</td>
                                <td class="text-center">{{item.may | comma}}</td>
                                <td class="text-center">{{item.jun | comma}}</td>
                                <td class="text-center">{{item.jul | comma}}</td>
                                <td class="text-center">{{item.aug | comma}}</td>
                                <td class="text-center">{{item.sept | comma}}</td>
                                <td class="text-center">{{item.oct | comma}}</td>
                                <td class="text-center">{{item.nov | comma}}</td>
                                <td class="text-center">{{item.dec | comma}}</td>
                                <td class="text-center">{{item.memSumOrderCnt | comma}} / {{item.memSumOrderQuantity | comma}}</td>
                                <td class="text-center">{{item.memSumCpoCnt | comma}} / {{item.memSumCpoQuantity | comma}}</td>
                                <td class="text-center">{{item.memSumPrice | comma}}</td>
                                <td class="text-center">{{item.memSumBasicPrice | comma}}</td>
                            </tr>
                            <tr v-if="list.length > 0" class="totalDiv">
                                <td colspan="2" class="text-center">합계</td>
                                <td class="text-center">{{sum.jan | comma}}</td>
                                <td class="text-center">{{sum.feb | comma}}</td>
                                <td class="text-center">{{sum.mar | comma}}</td>
                                <td class="text-center">{{sum.apr | comma}}</td>
                                <td class="text-center">{{sum.may | comma}}</td>
                                <td class="text-center">{{sum.jun | comma}}</td>
                                <td class="text-center">{{sum.jul | comma}}</td>
                                <td class="text-center">{{sum.aug | comma}}</td>
                                <td class="text-center">{{sum.sept | comma}}</td>
                                <td class="text-center">{{sum.oct | comma}}</td>
                                <td class="text-center">{{sum.nov | comma}}</td>
                                <td class="text-center">{{sum.dec | comma}}</td>
                                <td class="text-center">{{sum.memSumOrderCnt | comma}} / {{sum.memSumOrderQuantity | comma}}</td>
                                <td class="text-center">{{sum.memSumCpoCnt | comma}} / {{sum.memSumCpoQuantity | comma}}</td>
                                <td class="text-center">{{sum.memSumPrice | comma}}</td>
                                <td class="text-center">{{sum.memSumBasicPrice | comma}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="text-center">
                            <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="list" v-on:vue-pagination::list="onPage" v-show="list.length"></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavigationBar from "../../components/NavigationBar";
import Pagination from "../../components/Pagination";
import CodeMixins from '@/mixins/code-mixin'
import {CommonMixin} from "@/components/mixins/CommonMixin";

  export default {
    name: "HistoryList",
    data () {
      return {
        search: {
          year: 0,
          srchPage: 1,
          srchPageCnt: 10
        },
        list: [],
        sum: {},
        yearMap: [],
        srchPageSizeList: [
          { value: 10, text: '10개' },
          { value: 20, text: '20개' },
          { value: 30, text: '30개' },
          { value: 40, text: '40개' },
          { value: 50, text: '50개' }
        ],
        totalCnt: 0,
        totalOrderCnt: 0,
        totalOrderQuantity: 0,
        totalOpoOnlyPrdAmount: 0,
        totalOpoPrdBasicPrice: 0,
        totalCpoCnt: 0,
        totalCpoQuantity: 0,
        totalCpoPrdAmount: 0,
        totalCpoPrdBasicPrice: 0,
        totalPrice: 0,
        totalBasicPrice: 0

      }
    },
    created() {
      // 2021 ~ 현재년도 (초기값: 현재년도)
      var thisYear = new Date().getFullYear()
      this.search.year = thisYear

      for(let i = 2021; i <= thisYear; i++) {
        this.yearMap.push(i)
      }
    },
    mounted() {
      this.getList()
    },
    components: {
      NavigationBar, Pagination
    },
    mixins: [CodeMixins, CommonMixin],
    methods: {
      exportExcel () {
        const reqBody = {
            srchPage: 0,
            srchPageCnt: 0,
            year: this.search.year
        }

        this.$api.api.exportSalesHistoryList(reqBody)
          .then(resp => {
            this.$toast.success('엑셀 다운로드 성공')
          })
          .catch(e => {
            this.alert('엑셀 다운로드 실패')
            console.log(e)
          })
      },
      changeYear() {
        this.search.srchPage = 1;
        this.search.srchPageCnt = 10;
        this.getList();
      },
      onPage(page) {
        this.search.srchPage = page
        this.getList()
      },
      getList() {
        this.$api.api.getSalesHistoryList(this.search)
          .then(resp => {
            if(resp.data.result) {
              this.list = resp.data.data.admSalesHistoryListData
              this.sum = resp.data.data.sumData
              this.totalCnt = resp.data.data.totalCnt
              this.totalOrderCnt = resp.data.data.totalOrderCnt
              this.totalOrderQuantity = resp.data.data.totalOrderQuantity
              this.totalOpoOnlyPrdAmount = resp.data.data.totalOpoOnlyPrdAmount
              this.totalOpoPrdBasicPrice = resp.data.data.totalOpoPrdBasicPrice
              this.totalCpoCnt = resp.data.data.totalCpoCnt
              this.totalCpoQuantity = resp.data.data.totalCpoQuantity
              this.totalCpoPrdAmount = resp.data.data.totalCpoPrdAmount
              this.totalCpoPrdBasicPrice = resp.data.data.totalCpoPrdBasicPrice
              this.totalPrice = resp.data.data.totalPrice
              this.totalBasicPrice = resp.data.data.totalBasicPrice
            }
          })
          .catch(e => console.log(e))
      },
      onChangeSize() {
        this.search.srchPage = 1
        this.getList()
      }
    }
  }
</script>

<style scoped>
    .totalDiv td {
        background-color: lemonchiffon;
        border: 1px solid white;
    }
</style>
