import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    children: [
        {
            path: '/promotion/list',
            name: 'PromList',
            component: () => import('@/page/board/Promotion/PromList')
        },
        {
            path: '/promotion/modify/:params?',
            name: 'PromDetail',
            props: (route) => ({ query: route.query }),
            component: () => import('@/page/board/Promotion/PromRegistration')
        },
        {
            path: '/event/list',
            name: 'EventList',
            props: (route) => ({query: route.query}),
            component: () => import('@/page/board/Event/EventList')
        },
        {
            path: '/event/modify/:evtIdx?',
            name: 'EventRegistration',
            // props: (route) => ({query: route.query}),
            component: () => import('@/page/board/Event/EventRegistration')
        },
        {
            path: '/prdQna/list',
            name: 'PrdQnaList',
            props: (route) => ({query: route.query}),
            component: () => import('@/page/board/PrdQna/PrdQnaList')
        },
        {
            path: '/prdQna/:qnaIdx?',
            name: 'PrdQnaDetail',
            props: (route) => ({ query: route.query }),
            component: () => import('@/page/board/PrdQna/PrdQnaDetail')
        },
        {
            path: '/prohibit/list',
            name: 'ProhibitList',
            component: () => import('@/page/board/prohibit/ProhibitList')
        },
        {
            path: '/prohibit/register/:params?',
            name: 'ProhibitRegister',
            props: (route) => ({ query: route.query }),
            component: () => import('@/page/board/prohibit/ProhibitRegister')
        },
        {
            path: '/faq/list',
            name: 'FaqList',
            props: (route) => ({query: route.query}),
            component: () => import('@/page/board/Faq/FaqList')
        },
        {
            path: '/faq/modify/:faqIdx?',
            name: 'FaqRegistration',
            props: (route) => ({query: route.query}),
            component: () => import('@/page/board/Faq/FaqRegistration')
        },
        {
            path: '/purchaseReview/list',
            name: 'PurchaseReviewList',
            component: () => import('@/page/board/PurchaseReview/PurchaseReviewList')
        },
        {
            path: '/purchaseReview/:pcsIdx?',
            name: 'PurchaseReviewDetail',
            props: (route) => ({ query: route.query }),
            component: () => import('@/page/board/PurchaseReview/PurchaseReviewDetail')
        },
    ]
}
