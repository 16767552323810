<template>
  <div class="app-container app-theme-white body-tabs-shadow">
    <div class="app-container">
      <div class="h-100 bg-plum-plate bg-animation">
        <div class="d-flex h-100 justify-content-center align-items-center">
          <div class="app-login-box col-md-8">
            <div class="app-logo-inverse mx-auto mb-3"></div>
            <div class="modal-dialog w-100 mx-auto">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="h5 modal-title text-center">
                    <h4 class="mt-2">
                      <img src="../../assets/images/logo.png" class="w-100"/>
                    </h4>
                  </div>
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="position-relative form-group">
                        <input v-model="user_id" placeholder="ID를 입력해주세요" type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="position-relative form-group">
                        <input v-model="user_pwd" placeholder="PW를 입력해주세요" type="password" class="form-control" @keypress.enter="doLogin">
                      </div>
                    </div>
                  </div>
                  <div class="text-center bold" style="color: firebrick">{{err_msg}}</div>
                  <!--
                  <div class="position-relative form-check">
                    <input name="check" id="exampleCheck" type="checkbox" class="form-check-input">
                    <label for="exampleCheck" class="form-check-label">Keep me logged in</label>
                  </div>
                  <div class="divider"></div>
                  <h6 class="mb-0">No account? <a href="javascript:void(0);" class="text-primary">Sign up now</a></h6>
                  -->
                </div>
                <div class="modal-footer clearfix">
                  <!--
                  <div class="float-left">
                    <a href="javascript:void(0);" class="btn-lg btn btn-link">Recover Password</a>
                  </div>
                  -->
                  <div class="float-right">
                    <button class="btn btn-primary btn-lg" @click="doLogin">로그인</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center text-white opacity-8 mt-3">Copyright © SmartScore 2020</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { CommonMixin} from '@/components/mixins/CommonMixin'
import { required } from 'vuelidate/lib/validators'
import { actionType } from '@/store/const/StoreType'
import CryptoJS from 'crypto-js'
import InterfaceMixin from "@/mixins/userInterface-mixin"

export default {
  name: 'Login',
  data () {
    return {
      user_id: '',
      user_pwd: '',
      err_msg: ''
    }
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  validations: {
    user_id: {
      required
    },
    user_pwd: {
      required
    }
  },
  mounted () {
  },
  methods: {
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (!this.$v.user_id.required) {
          this.alert('ID를 입력해주세요.')
          return false
        }
        if (!this.$v.user_pwd.required) {
          this.alert('PW를 입력해주세요.')
          return false
        }
      }
      return true
    },
    doLogin () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 매장 정보 등록 실패 입력 FORM 오류')
        return
      }
      let bodyData = {
        username: this.user_id,
        password: this.encrypt()
      }

      this.blockUI()
      this.$store.dispatch(actionType.ACTION_LOGIN, bodyData).then((result) => {
        if (result) {
          var defaultPath = '/order/list';
          if(this.$store.getters.userData.level == 3) {
            defaultPath = '/member/list'
          } else if(this.$store.getters.userData.level == 4) {
            defaultPath = '/store/member/list'
          }

          this.$router.push({path: defaultPath})
          this.unBlockUI()
        }
      }).catch(error => {
        this.unBlockUI()
        switch (error.status) {
          case 503: this.err_msg = 'Internal Server Error'; break;
          case 401: this.err_msg = '관리자 정보를 찾을 수 없습니다.'; break;
          case 403: this.err_msg = '관리자 정보가 유효하지 않습니다.'; break;
          default: this.err_msg = '로그인에 실패 했습니다.'
        }
      })
    },
    encrypt () {
      const hash = CryptoJS.SHA1(this.user_pwd)
      return CryptoJS.enc.Hex.stringify(hash)
    }
  }
}
</script>

<style scoped>
</style>
