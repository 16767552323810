<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'비주얼관리'" :nav-list="['맥케이슨관리', '비주얼관리', '기타 노출 관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <td class="text-left wd-200">스토어 동영상 URL (PC)</td>
                  <td class="text-center"><input type="text" class="form-control" v-model.trim="pcVideoUrl" placeholder="홈화면 하단 동영상(PC) URL을 입력해주세요." /></td>
                  <td class="text-center wd-100"><button type="button" class="btn btn-secondary w-100" @click="callDisplayPop(pcVideoUrl)">미리보기</button></td>
                </tr>
                <tr>
                  <td class="text-left wd-200">스토어 하단 동영상 URL (Mobile)</td>
                  <td class="text-center"><input type="text" class="form-control" v-model.trim="mobileVideoUrl" placeholder="홈화면 하단 동영상(Mobile) URL을 입력해주세요." /></td>
                  <td class="text-center wd-100"><button type="button" class="btn btn-secondary w-100" @click="callDisplayPop(mobileVideoUrl)">미리보기</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onSave()">저장</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import NavigationBar from '@/page/components/NavigationBar'
import DisplayPop from '@/page/visual/display/components/DisplayPop'
import UploadPhoto from '@/components/common/UploadPhoto'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
  name: 'DisplayList',
  data () {
    return {
      idx: '',
      pcVideoUrl: '',
      mobileVideoUrl: '',
    }
  },
  validations: {
    pcVideoUrl: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(1000)
    },
    mobileVideoUrl: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(1000)
    }
  },
  components: {
    NavigationBar,
    UploadPhoto,
    DisplayPop
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getMngVisualEtcInfo()
    },
    // 목록조회
    getMngVisualEtcInfo() {
      return this.$api.api.getMngVisualEtcInfo().then(resp => {
        if (resp.status === 200) {
          this.idx = resp.data.data.idx
          this.pcVideoUrl = resp.data.data.pcVideoUrl
          this.mobileVideoUrl = resp.data.data.mobileVideoUrl
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onAddImage (list) {
      if (list.length >= 7) {
        this.alert('최대 7개 까지만 등록 가능합니다.')
        return false
      }
      list.push({
        check: false,
        idx: '',
        order: list.length+1,
        img_path: 'temp/',
        filename: ''
      })
    },
    checkUrl (strUrl) {
      let expUrl = /^http[s]?\:\/\//i
      return expUrl.test(strUrl)
    },
    // 저장
    onSave () {
      if (!this.checkUrl(this.pcVideoUrl)) {
        this.alert('[PC] 정확한 URL 형식으로 입력해주세요.')
        return false
      } else if (!this.checkUrl(this.mobileVideoUrl)) {
        this.alert('[Mobile] 정확한 URL 형식으로 입력해주세요.')
        return false
      }
      this.confirm('저장 하시겠습니까?', () => {
        let params = {
          idx: this.idx,
          pcVideoUrl: this.pcVideoUrl,
          mobileVideoUrl: this.mobileVideoUrl
        }
        return this.$api.api.saveMngVisualEtcInfo(params).then(resp => {
          if (resp.status === 200) {
            this.alert('저장 되었습니다.')
            this.getMngVisualEtcInfo()
          } else {
            this.alert('저장에 실패하였습니다.')
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },
    onOrderChange (list, index) {
      let temp = list[index];
      let target = list[index].order;
      list.splice(index, 1);
      list.splice(target-1, 0, temp);
      this.reOrdering(list);
    },
    reOrdering (list) {
      list.map((item, index) => {
        item.order = index+1
      })
    },
    callDisplayPop (displayUrl) {
      this.$modal.show(
          DisplayPop,
          { text: '미리보기', displayUrl: displayUrl },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
            }
          }
      )
    },
    changedOk (file, list, index) {
      list[index].filename = file.name
      list[index].width = file.w
      list[index].height = file.h
    },
  }
}
</script>

<style scoped>

</style>
