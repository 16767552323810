import axios from 'axios'

let baseUrl = ''
baseUrl = process.env.VUE_APP_AXIOS_HOST_URL
console.log('baseUrl ::: ', baseUrl)

function headers (shouldFormData = false) {
  const token = sessionStorage.getItem('accessToken')

  // Headers : Begin
  const authHeader = token ? { 'Authorization': token } : {}
  const contentType = { 'Content-Type': shouldFormData ? 'multipart/form-data' : 'application/json' }
  return {
    headers: {
      ...authHeader,
      ...contentType
    }
  }
  // Headers: End
}

// Status 200 아니면 실패로 간주
axios.interceptors.response.use((resp) => {

  // 세션 유지를 위한 accessToken 갱신
  if(resp.headers['expire_token']) {
    sessionStorage.setItem('accessToken', resp.headers['expire_token'])
  }

  if (resp.status === 200) return resp
  // else window.location = '/login'
  if (resp.status === 401) window.location = '/login'
  // if (resp.status === 404) window.location = '/error/404'
  // if (resp.status === 500) window.location = '/error/500'
  // if (resp.status === 503) window.location = '/error/503'
  return Promise.reject(resp)
})

export default {
  getAxiosConfig (shouldFormData = false, params = {}) {
    return {
      ...headers(shouldFormData),
      params,
      validateStatus: (status) => { return status }
    }
  },
  getAxiosExportConfig (shouldFormData = false, params = {}) {
    return {
      ...headers(shouldFormData),
      params,
      validateStatus: (status) => { return status },
      responseType: 'blob'
    }
  },
  _sendHttpGetMethod (url, bodyData) {
    return new Promise((resolve, reject) => {
      if (bodyData) {
        axios.get(baseUrl + url, this.getAxiosConfig(false, bodyData)).then(resp => {
          console.log('axios.then(GET) => RESP::: ', resp)
          resolve(resp)
        }).catch(e => {
          console.log('axios.then(GET) => ERROR::: ', e)
          reject(e)
        })
      } else {
        axios.get(baseUrl + url, this.getAxiosConfig(false)).then(resp => {
          console.log('axios.then(GET) => RESP::: ', resp.data)
          resolve(resp)
        }).catch(e => {
          console.log('axios.then(GET) => ERROR::: ', e)
          reject(e)
        })
      }
    })
  },
  _sendHttpPostMethod (url, bodyData) {
    return new Promise((resolve, reject) => {
      axios.post(baseUrl + url, bodyData, this.getAxiosConfig(false)).then(resp => {
        console.log('axios.then(POST) => RESP::: ', resp)
        resolve(resp)
      }).catch(e => {
        console.log('axios.then(POST) => ERROR::: ', e)
        reject(e)
      })
    })
  },
  _sendHttpGetExportExcel (url, bodyData) {
    return new Promise((resolve, reject) => {
      if (bodyData) {
        axios.get(baseUrl + url, this.getAxiosExportConfig(false, bodyData)).then(resp => {
          console.log('axios.then(GET_EXPORT) => RESP::: ', resp)
          this.handlingBlob(resp)
        }).catch(e => {
          console.log('axios.then(GET_EXPORT) => ERROR::: ', e)
          reject(e)
        })
      } else {
        axios.get(baseUrl + url, this.getAxiosExportConfig(false)).then(resp => {
          console.log('axios.then(GET_EXPORT) => RESP::: ', resp.data)
          this.handlingBlob(resp)
        }).catch(e => {
          console.log('axios.then(GET_EXPORT) => ERROR::: ', e)
          reject(e)
        })
      }
    })
  },
  _sendHttpPostExportExcel (url, bodyData) {
    return new Promise((resolve, reject) => {
      axios.post(baseUrl + url, bodyData, this.getAxiosExportConfig(false)).then(resp => {
        console.log('axios.then(POST_EXPORT) => RESP::: ', resp)
        this.handlingBlob(resp)
        resolve(resp)
      }).catch(e => {
        console.log('axios.then(POST_EXPORT) => ERROR::: ', e)
        reject(e)
      })
    })
  },
  _sendHttpMultipartFile (url, formData) {
    return new Promise((resolve, reject) => {
      axios.post(baseUrl + url, formData, this.getAxiosConfig(true)).then(resp => {
        console.log('axios.then(POST/multi-part) => RESP::: ', resp)
        resolve(resp)
      }).catch(e => {
        console.log('axios.then(POST/multi-part) => ERROR::: ', e)
        reject(e)
      })
    })
  },
  handlingBlob (res) {
    let fileName = res.headers['content-disposition'].split('filename=')[1].split('"')[1];
    /*
    const winUrl = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
    const link = document.createElement('a');
    link.href = winUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    */
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //IE11 and the legacy version Edge support
      console.log("IE & Edge");
      let blob = new Blob([res.data], { type: "text/html" });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {// other browsers
      console.log("Other browsers");
      var bl = new Blob([res.data], { type: "text/html" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(bl);
      a.download = fileName;
      a.hidden = true;
      document.body.appendChild(a);
      a.click();
    }
  }
}
