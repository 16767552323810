import ErrorLayout from '@/layout/ErrorLayout'

export default {
  path: '/error',
  component: ErrorLayout,
  children: [
    {
      path: '404',
      name: 'error404',
      component: () => import(/* webpackChunkName: 'Error' */ '@/page/error/404'),
      hidden: true
    },
    {
      path: '403',
      name: 'error403',
      component: () => import(/* webpackChunkName: 'Error' */ '@/page/error/403'),
      hidden: true
    },
    {
      path: '401',
      name: 'error401',
      component: () => import(/* webpackChunkName: 'Error' */ '@/page/error/401'),
      hidden: true
    },
    {
      path: '500',
      name: 'error500',
      component: () => import(/* webpackChunkName: 'Error' */ '@/page/error/500'),
      hidden: true
    },
    {
      path: '503',
      name: 'error503',
      component: () => import(/* webpackChunkName: 'Error' */ '@/page/error/503'),
      hidden: true
    },
  ],
  hidden: true
}
