var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "브로슈어관리",
            "nav-list": ["맥케이슨관리", "기본정보관리", "브로슈어관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm._m(0),
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary wd-100",
                    on: { click: _vm.addData }
                  },
                  [_c("i", { staticClass: "fa fa-plus mr-2" }), _vm._v("추가")]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-11" },
                [
                  !_vm.list.length
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("등록된 정보가 존재하지 않습니다.")
                        ])
                      ])
                    : _c(
                        "draggable",
                        {
                          on: { change: _vm.reOrdering },
                          model: {
                            value: _vm.list,
                            callback: function($$v) {
                              _vm.list = $$v
                            },
                            expression: "list"
                          }
                        },
                        _vm._l(_vm.list, function(data, dataIdx) {
                          return _c(
                            "div",
                            { staticClass: "d-inline-block text-center p-2" },
                            [
                              data.idx !== ""
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "120px",
                                          height: "120px",
                                          "justify-content": "center",
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "image-error",
                                              rawName: "v-image-error"
                                            },
                                            {
                                              name: "viewer",
                                              rawName: "v-viewer"
                                            }
                                          ],
                                          staticClass: "images",
                                          staticStyle: {
                                            width: "120px",
                                            height: "120px"
                                          },
                                          attrs: {
                                            src: _vm.getImagePath(data.fileName)
                                          }
                                        })
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "fa fa-times mb-2 mt-2",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onDelete(dataIdx)
                                        }
                                      }
                                    })
                                  ]
                                : [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "120px",
                                          height: "120px",
                                          "justify-content": "center",
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        data.fileName === ""
                                          ? _c("upload-photo", {
                                              attrs: { branch: "brochure" },
                                              on: {
                                                changed: function($event) {
                                                  return _vm.changeImage(
                                                    $event,
                                                    data
                                                  )
                                                }
                                              }
                                            })
                                          : _c("img", {
                                              directives: [
                                                {
                                                  name: "viewer",
                                                  rawName: "v-viewer"
                                                },
                                                {
                                                  name: "image-error",
                                                  rawName: "v-image-error"
                                                }
                                              ],
                                              staticClass: "images",
                                              staticStyle: {
                                                width: "120px",
                                                height: "120px"
                                              },
                                              attrs: {
                                                src: _vm.getImagePath(
                                                  data.fileName
                                                )
                                              }
                                            })
                                      ],
                                      1
                                    ),
                                    _c("i", {
                                      staticClass: "fa fa-times mb-2 mt-2",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteRow(dataIdx)
                                        }
                                      }
                                    })
                                  ]
                            ],
                            2
                          )
                        }),
                        0
                      )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success ml-1 wd-100",
                    on: { click: _vm.onSave }
                  },
                  [_vm._v("저장")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "float-left" }, [
      _c("h5", { staticClass: "card-title float-left mb-0 mt-2" }, [
        _vm._v("브로슈어")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }