var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-header header-shadow" }, [
    _c("div", { staticClass: "app-header__logo" }, [
      _c(
        "div",
        {
          staticClass: "fsize-3",
          on: {
            click: function($event) {
              return _vm.goMenuUrl()
            }
          }
        },
        [_vm._m(0)]
      )
    ]),
    _c("div", { staticClass: "app-header__content" }, [
      _c("div", { staticClass: "app-header-left" }),
      _c("div", { staticClass: "app-header-right" }, [
        _c("div", { staticClass: "header-btn-lg pr-0" }, [
          _c("div", { staticClass: "widget-content p-0" }, [
            _c("div", { staticClass: "widget-content-wrapper" }, [
              _c(
                "div",
                { staticClass: "widget-content-left  ml-3 header-user-info" },
                [
                  _c("div", { staticClass: "widget-heading" }, [
                    _vm._v(_vm._s(_vm.accessDate))
                  ]),
                  _c("div", { staticClass: "widget-heading" }, [
                    _vm._v(
                      _vm._s(_vm.$store.getters.userData.id) + "님 안녕하세요"
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "widget-subheading",
                      on: { click: _vm.onLogout }
                    },
                    [_vm._v("로그아웃")]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("img", {
        staticClass: "w-100",
        attrs: { src: require("../../assets/images/logo.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }