var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card mb-3 card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h5", { staticClass: "card-title mb-0 font-size-lg" }, [
            _vm._v("메인영역")
          ]),
          _c(
            "div",
            {
              staticClass:
                "mt-auto ml-auto mb-auto mr-0 right position-relative"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary wd-100",
                  on: {
                    click: function($event) {
                      return _vm.onAddList(_vm.mainInfoList)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-plus mr-1" }), _vm._v("추가")]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("table", { staticClass: "table table-detail" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center wd-75" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectAll,
                        expression: "selectAll"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectAll)
                        ? _vm._i(_vm.selectAll, null) > -1
                        : _vm.selectAll
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.selectAll,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selectAll = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selectAll = $$c
                        }
                      }
                    }
                  })
                ]),
                _c("th", { staticClass: "text-center wd-125" }, [
                  _vm._v("순서변경")
                ]),
                _c("th", { staticClass: "text-center wd-125" }, [
                  _vm._v("구분")
                ]),
                _c(
                  "th",
                  {
                    staticClass: "text-center w-auto",
                    attrs: { colspan: "2" }
                  },
                  [_vm._v("내용")]
                )
              ])
            ]),
            _c(
              "tbody",
              [
                !_vm.mainInfoList.length
                  ? _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text-center", attrs: { colspan: "4" } },
                        [_vm._v("내역이 존재하지 않습니다.")]
                      )
                    ])
                  : _vm._e(),
                _vm._l(_vm.mainInfoList, function(data, dataIdx) {
                  return _c("tr", [
                    _c("td", { staticClass: "text-center" }, [
                      data.idx !== ""
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.chkList,
                                expression: "chkList"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: data.idx,
                              checked: Array.isArray(_vm.chkList)
                                ? _vm._i(_vm.chkList, data.idx) > -1
                                : _vm.chkList
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.chkList,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = data.idx,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.chkList = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.chkList = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.chkList = $$c
                                }
                              }
                            }
                          })
                        : _vm._e(),
                      data.idx === ""
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-shadow pt-0",
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "1.5em"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteRow(
                                    _vm.mainInfoList,
                                    dataIdx
                                  )
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: data.seq,
                              expression: "data.seq"
                            }
                          ],
                          staticClass: "form-control text-center",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  data,
                                  "seq",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function($event) {
                                return _vm.onChangeSeq($event, dataIdx)
                              }
                            ]
                          }
                        },
                        _vm._l(_vm.mainInfoList.length, function(i) {
                          return _c("option", { domProps: { value: i } }, [
                            _vm._v(_vm._s(i))
                          ])
                        }),
                        0
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "div",
                        _vm._l(_vm.contentTypeList, function(radio, radioIdx) {
                          return _c(
                            "div",
                            { staticClass: "custom-radio custom-control" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: data.contentType,
                                    expression: "data.contentType"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "radio",
                                  id: "contentType_" + dataIdx + "_" + radioIdx,
                                  name: "customRadio_" + dataIdx
                                },
                                domProps: {
                                  value: radio.value,
                                  checked: _vm._q(data.contentType, radio.value)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      data,
                                      "contentType",
                                      radio.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: {
                                    for:
                                      "contentType_" + dataIdx + "_" + radioIdx
                                  }
                                },
                                [_vm._v(_vm._s(radio.text))]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-left" },
                      [
                        data.contentType === 1
                          ? [
                              _c(
                                "ul",
                                {
                                  staticClass: "pl-2",
                                  staticStyle: { display: "inline-flex" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mr-3 text-center" },
                                    [
                                      _vm._m(0, true),
                                      _c(
                                        "li",
                                        [
                                          data.pcImgInfo.filename === "" ||
                                          data.pcImgInfo.filename === null
                                            ? _c("upload-photo", {
                                                attrs: { branch: "visual" },
                                                on: {
                                                  changed: function($event) {
                                                    return _vm.changeImagePc(
                                                      $event,
                                                      data.pcImgInfo
                                                    )
                                                  }
                                                }
                                              })
                                            : _c("img", {
                                                directives: [
                                                  {
                                                    name: "viewer",
                                                    rawName: "v-viewer"
                                                  },
                                                  {
                                                    name: "image-error",
                                                    rawName: "v-image-error"
                                                  }
                                                ],
                                                staticClass: "images",
                                                staticStyle: {
                                                  width: "120px",
                                                  height: "120px"
                                                },
                                                attrs: {
                                                  src: _vm.getImagePath(
                                                    data.pcImgInfo.path
                                                      ? data.pcImgInfo.path +
                                                          "/" +
                                                          data.pcImgInfo
                                                            .filename
                                                      : data.pcImgInfo.filename
                                                  )
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "text-center" }, [
                                    _vm._m(1, true),
                                    _c(
                                      "li",
                                      [
                                        data.mobileImgInfo.filename === "" ||
                                        data.mobileImgInfo.filename === null
                                          ? _c("upload-photo", {
                                              attrs: { branch: "visual" },
                                              on: {
                                                changed: function($event) {
                                                  return _vm.changeImagePc(
                                                    $event,
                                                    data.mobileImgInfo
                                                  )
                                                }
                                              }
                                            })
                                          : _c("img", {
                                              directives: [
                                                {
                                                  name: "viewer",
                                                  rawName: "v-viewer"
                                                },
                                                {
                                                  name: "image-error",
                                                  rawName: "v-image-error"
                                                }
                                              ],
                                              staticClass: "images",
                                              staticStyle: {
                                                width: "120px",
                                                height: "120px"
                                              },
                                              attrs: {
                                                src: _vm.getImagePath(
                                                  data.mobileImgInfo.path
                                                    ? data.mobileImgInfo.path +
                                                        "/" +
                                                        data.mobileImgInfo
                                                          .filename
                                                    : data.mobileImgInfo
                                                        .filename
                                                )
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              ),
                              _c("div", { staticStyle: { display: "grid" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-control-inline m-1 pl-0"
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-form-label w-15 pr-2 font-weight-bold"
                                      },
                                      [_vm._v("문구")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: data.etc,
                                          expression: "data.etc"
                                        }
                                      ],
                                      staticClass: "form-control w-75 mx-input",
                                      attrs: {
                                        type: "text",
                                        maxlength: "20",
                                        placeholder:
                                          "텍스트를 입력하세요.(선택)"
                                      },
                                      domProps: { value: data.etc },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            data,
                                            "etc",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                [
                                  _vm._l(_vm.linkTypeList, function(
                                    radio,
                                    radioIdx
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-radio custom-control custom-control-inline m-2"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: data.linkType,
                                              expression: "data.linkType"
                                            }
                                          ],
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            type: "radio",
                                            id:
                                              "linkType_" +
                                              dataIdx +
                                              "_" +
                                              radioIdx,
                                            name: "linkType_" + dataIdx
                                          },
                                          domProps: {
                                            value: radio.value,
                                            checked: _vm._q(
                                              data.linkType,
                                              radio.value
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                data,
                                                "linkType",
                                                radio.value
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "custom-control-label pr-2 font-weight-bold",
                                            attrs: {
                                              for:
                                                "linkType_" +
                                                dataIdx +
                                                "_" +
                                                radioIdx
                                            }
                                          },
                                          [_vm._v(_vm._s(radio.text))]
                                        )
                                      ]
                                    )
                                  }),
                                  _c("div", [
                                    data.linkType === 1
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: data.linkUrl,
                                              expression: "data.linkUrl"
                                            }
                                          ],
                                          staticClass:
                                            "form-control w-75 mx-input",
                                          attrs: {
                                            type: "text",
                                            maxlength: "100",
                                            placeholder:
                                              "내부 URL을 입력하세요. ex) /product/detail/AAA333"
                                          },
                                          domProps: { value: data.linkUrl },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                data,
                                                "linkUrl",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: data.linkUrl,
                                              expression: "data.linkUrl"
                                            }
                                          ],
                                          staticClass:
                                            "form-control w-75 mx-input",
                                          attrs: {
                                            type: "text",
                                            maxlength: "100",
                                            placeholder:
                                              "외부 URL을 입력하세요. ex) https://naver.com"
                                          },
                                          domProps: { value: data.linkUrl },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                data,
                                                "linkUrl",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                  ])
                                ],
                                2
                              )
                            ]
                          : _vm._e(),
                        data.contentType === 2
                          ? [
                              _c("div", { staticStyle: { display: "grid" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-control-inline m-1 pl-0"
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-form-label w-25 pr-2 font-weight-bold"
                                      },
                                      [_vm._v("PC 동영상 URL")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: data.pcVideoUrl,
                                          expression: "data.pcVideoUrl"
                                        }
                                      ],
                                      staticClass: "form-control w-100 mr-2",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "동영상(PC) URL을 입력해주세요."
                                      },
                                      domProps: { value: data.pcVideoUrl },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            data,
                                            "pcVideoUrl",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary wd-125",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.callDisplayPop(
                                              data.pcVideoUrl
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("미리보기")]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-control-inline m-1 pl-0"
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-form-label w-25 pr-2 font-weight-bold"
                                      },
                                      [_vm._v("Mobile 동영상 URL")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: data.mobileVideoUrl,
                                          expression: "data.mobileVideoUrl"
                                        }
                                      ],
                                      staticClass: "form-control w-100 mr-2",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "동영상(Mobile) URL을 입력해주세요."
                                      },
                                      domProps: { value: data.mobileVideoUrl },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            data,
                                            "mobileVideoUrl",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary wd-125",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.callDisplayPop(
                                              data.mobileVideoUrl
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("미리보기")]
                                    )
                                  ]
                                )
                              ]),
                              _vm._l(_vm.linkTypeList, function(
                                radio,
                                radioIdx
                              ) {
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-radio custom-control custom-control-inline m-2"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: data.linkType,
                                          expression: "data.linkType"
                                        }
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "radio",
                                        id:
                                          "linkType_" +
                                          dataIdx +
                                          "_" +
                                          radioIdx,
                                        name: "linkType_" + dataIdx
                                      },
                                      domProps: {
                                        value: radio.value,
                                        checked: _vm._q(
                                          data.linkType,
                                          radio.value
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            data,
                                            "linkType",
                                            radio.value
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "custom-control-label pr-2 font-weight-bold",
                                        attrs: {
                                          for:
                                            "linkType_" +
                                            dataIdx +
                                            "_" +
                                            radioIdx
                                        }
                                      },
                                      [_vm._v(_vm._s(radio.text))]
                                    )
                                  ]
                                )
                              }),
                              _c("div", [
                                data.linkType === 1
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: data.linkUrl,
                                          expression: "data.linkUrl"
                                        }
                                      ],
                                      staticClass: "form-control w-75 mx-input",
                                      attrs: {
                                        type: "text",
                                        maxlength: "100",
                                        placeholder:
                                          "내부 URL을 입력하세요. ex) /product/detail/AAA333"
                                      },
                                      domProps: { value: data.linkUrl },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            data,
                                            "linkUrl",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: data.linkUrl,
                                          expression: "data.linkUrl"
                                        }
                                      ],
                                      staticClass: "form-control w-75 mx-input",
                                      attrs: {
                                        type: "text",
                                        maxlength: "100",
                                        placeholder:
                                          "외부 URL을 입력하세요. ex) https://naver.com"
                                      },
                                      domProps: { value: data.linkUrl },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            data,
                                            "linkUrl",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                })
              ],
              2
            )
          ])
        ]),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
            _c("div", { staticClass: "float-left" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger ml-1 mr-1 wd-100",
                  on: { click: _vm.onDelete }
                },
                [_vm._v("선택삭제")]
              )
            ]),
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success wd-100",
                  on: { click: _vm.onSave }
                },
                [_vm._v("저장")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", { staticClass: "font-weight-bold" }, [_vm._v("PC 이미지")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("label", { staticClass: "font-weight-bold" }, [
        _vm._v("Mobile 이미지")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }