<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'매장관리'" :nav-list="['맥케이슨관리', '기본정보관리', '매장관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">매장정보</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label">고유번호</label>
              <div class="col-sm-5"><input type="text" class="form-control" v-model="storeInfo.idx" disabled></div>
              <label class="col-sm-1 col-form-label">노출여부</label>
              <div class="col-sm-5">
                <div class="position-relative form-group">
                  <div class="col-form-label">
                    <div class="custom-radio custom-control custom-control-inline"><input type="radio" id="openYn_1"
                                                                                          name="openYn"
                                                                                          class="custom-control-input"
                                                                                          v-model="storeInfo.isDisplay"
                                                                                          value="1"><label
                      class="custom-control-label" for="openYn_1">노출</label></div>
                    <div class="custom-radio custom-control custom-control-inline"><input type="radio" id="openYn_2"
                                                                                          name="openYn"
                                                                                          class="custom-control-input"
                                                                                          v-model="storeInfo.isDisplay"
                                                                                          value="0"><label
                      class="custom-control-label" for="openYn_2">비노출</label></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label"><span class="required">*</span>매장명</label>
              <div class="col-sm-5">
                <input type="text" class="form-control" v-model="storeInfo.shopName" maxlength="15" minlength="2" placeholder="매장명을 입력해주세요">
              </div>
              <label class="col-sm-1 col-form-label"><span class="required">*</span>지역</label>
              <div class="col-sm-2 pr-1">
                <select class="form-control" v-model="storeInfo.shopAreaCode1" @change="getAreaCode2">
                  <option value="">선택해주세요</option>
                  <option v-for="(item, idx) in srchLoc1List" :value="item.areaCode1" :key="idx">{{ item.areaName1 }}</option>
                </select>
              </div>
              <div class="col-sm-2 pl-1">
                <select class="form-control" v-model="storeInfo.shopAreaCode2">
                  <option value="">선택해주세요</option>
                  <option v-for="(item, idx) in srchLoc2List" :value="item.areaCode2" v-if="item.areaCode1 === storeInfo.shopAreaCode1" :key="idx">{{ item.areaName2 }}
                  </option>
                </select>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label"><!--<span class="required">*</span>-->전화번호</label>
              <div class="col-sm-5">

                <input type="text" class="form-control" v-model="storeInfo.shopPhone" placeholder="- 를 포함하여 전화번호를 입력해주세요" maxlength="30"/>
<!--                <the-mask type="tel" v-model="storeInfo.shopPhone" :mask="['###-####-####', '###-###-####', '##-###-####']" pattern="\d*" maxlength="13" class="form-control" placeholder="전화번호를 입력해주세요." />-->
              </div>
              <label class="col-sm-1 col-form-label"><span class="required">*</span>매장 타입</label>
              <div class="col-sm-4">
                <div class="col-form-label">
                    <input type="text" class="form-control w-100" v-model="storeInfo.shopType">
                </div>

              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label"><span class="required">*</span>주소</label>
              <div class="col-sm-11">
                <div class="position-relative mb-2">
                  <input type="text" class="form-control wd-75 d-inline mr-2" v-model="storeInfo.shopPostalCode" disabled style="height: 38px!important; vertical-align: middle;" />
                  <input type="text" class="form-control wd-300 d-inline mr-2" v-model="storeInfo.shopAddress" disabled style="height: 38px!important;;vertical-align: middle;" />
                  <button class="btn btn-primary wd-150 form-control mb-2 mt-2" @click="callAddrSearchPop">우편번호검색</button>
                  <input type="text" class="form-control" placeholder="상세주소를 입력해주세요" v-model="storeInfo.shopAddressDetail" maxlength="20">
                </div>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label">
                사진
                <button class="btn btn-primary d-block" @click="addData"><i class="fa fa-plus mr-2"></i>추가</button>
              </label>
              <div class="col-sm-11">
                <draggable v-model="storeInfo.shopImages" @change="reOrdering" class="d-inline-block">
                  <div class="d-inline-block text-center p-2" v-for="(data, dataIdx) in storeInfo.shopImages">
                    <template v-if="data.idx !== ''">
                      <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                        <img class="images" :src="getImagePath(data.thumb1)" style="width: 120px; height: 120px;" v-image-error v-viewer />
                      </div>
                      <i class="fa fa-times mb-2 mt-2" style="cursor: pointer;" @click="deleteRow(dataIdx)"></i>
                    </template>
                    <template v-else>
                      <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                        <upload-photo v-if="data.filename === ''" branch="shop" @changed="changeImage($event, data)"></upload-photo>
                        <img v-else :src="getImagePath(data.thumb1)" class="images" v-viewer v-image-error style="width: 120px; height: 120px;" />
                      </div>
                      <i class="fa fa-times mb-2 mt-2" style="cursor: pointer;" @click="deleteRow(dataIdx)"></i>
                    </template>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 ml-1 mr-1" @click="onList">목록</button>
            <button class="btn btn-success wd-100 ml-1 mr-1" @click="onCreate" v-if="!isEdit">등록</button>
            <button class="btn btn-danger wd-100 ml-1 mr-1" @click="onDelete" v-if="isEdit">삭제</button>
            <button class="btn btn-success wd-100 ml-1 mr-1" @click="onUpdate" v-if="isEdit">수정</button>
          </div>
        </div>

        <div class="main-card mb-3 card" v-if="isEdit">
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label">등록일</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled :value="storeInfo.shopRegDate"></div>
              <label class="col-sm-1 col-form-label">작성자</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled :value="storeInfo.shopRegId"></div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label">수정일</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled :value="storeInfo.shopUpdDate"></div>
              <label class="col-sm-1 col-form-label">수정자</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled :value="storeInfo.shopUpdId"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import {required, minLength, maxLength, helpers} from 'vuelidate/lib/validators'
import NavigationBar from '@/page/components/NavigationBar'
import DaumPostalPop from '@/components/popup/DaumPostalPop'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import draggable from "vuedraggable"
import UploadPhoto from '@/components/common/UploadPhoto'
const phoneRegExp = helpers.regex('phone', /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/)

export default {
  name: 'StoreList',
  data () {
    return {
      search: '',
      isEdit: false,
      srchLoc1List: '',
      srchLoc2List: '',
      storeInfo: {
        idx: '',
        isDisplay: 1,
        shopAddress: '',
        shopAddressDetail: '',
        shopAreaCode1: '',
        shopAreaCode2: '',
        shopName: '',
        shopPhone: '',
        shopPostalCode: '',
        shopType: '',
        shopImages: []
      }
    }
  },
  validations: {
    storeInfo: {
      shopName: {
        required, minLength: minLength(2), maxLength: maxLength(15)
      },
      shopPhone: {
        required, minLength: minLength(9), maxLength: maxLength(30), phoneRegExp
      },
      shopAreaCode1: {
        required
      },
      shopAreaCode2: {
        required
      },
      shopPostalCode: {
        required
      },
      shopAddress: {
        required
      },
      shopAddressDetail: {
        required, minLength: minLength(1), maxLength: maxLength(20)
      },
      shopType: {
        required
      }
    }
  },
  components: {
    NavigationBar,
    DaumPostalPop,
    draggable,
    UploadPhoto
  },
  mixins: [ CommonMixin, InterfaceMixin ],
  created () {
    console.log('[MCKAYSON] StoreDetail::: created::: ', this.$route.query.storeIdx)
    let storeIdx = this.$route.query.storeIdx
    if (storeIdx) {
      this.isEdit = true

      this.storeInfo.idx = storeIdx
      this.setData(storeIdx)
    }

    let search = this.$route.query.search
    this.search = search
  },
  mounted () {
    this.getAreaCode()
  },
  methods: {
    getAreaCode2 () {
      this.storeInfo.shopAreaCode2 = ''
    },
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (!this.$v.storeInfo.shopName.required) {

          this.alert('매장명을 입력해주세요.')
          return false
        }
        if (!this.$v.storeInfo.shopName.minLength) {
          this.alert('매장명은 최소 2자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.storeInfo.shopName.maxLength) {
          this.alert('매장명은 최대 15자리 이상 입력할 수 없습니다.')
          return false
        }
        if (!this.$v.storeInfo.shopAreaCode1.required || !this.$v.storeInfo.shopAreaCode2.required) {
          this.alert('지역을 선택해주세요.')
          return false
        }
        // if (!this.$v.storeInfo.shopPhone.required) {
        //   this.alert('전화번호를 입력해주세요.')
        //   return false
        // }
        if (this.storeInfo.shopPhone && !this.$v.storeInfo.shopPhone.minLength) {
          this.alert('전화번호는 최소 9자리 이상 입력해주세요.')
          return false
        }
        if (this.storeInfo.shopPhone && !this.$v.storeInfo.shopPhone.maxLength) {
          this.alert('전화번호는 최대 30자리 이상 입력할 수 없습니다.')
          return false
        }
        // if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.storeInfo.shopPhone)) {
        //   this.alert('전화번호 형식이 유효하지 않습니다.')
        //   return false
        // }
        // if (this.storeInfo.shopPhone && !this.$v.storeInfo.shopPhone.phoneRegExp) {
        //   this.alert('전화번호 형식이 유효하지 않습니다.')
        //   return false
        // }
        if (!this.$v.storeInfo.shopType.required) {
          this.alert('매장 타입을 입력해주세요.')
          return false
        }
        if (!this.$v.storeInfo.shopAddress.required) {
          this.alert('주소를 입력해주세요.')
          return false
        }
        if (!this.$v.storeInfo.shopAddressDetail.required) {
          this.alert('상세주소를 입력해주세요.')
          return false
        }
      }
      return true
    },
    getAreaCode() {
      this.$api.api.getAreaCode().then(resp=> {
        this.srchLoc1List = resp.data.data

        let arr = [];
        this.srchLoc1List.map(x=> {
          return x.areaList.map(y => {
            y.areaCode1 = x.areaCode1
            arr.push(y)
          })
        })

        this.srchLoc2List = arr
      })
    },
    setData (index) {
      let reqbody = {
        'idx': index
      }
      this.$api.api.getStoreDetail(reqbody).then(resp => {
        if (resp.data.result) {
          let info = resp.data.data
          this.storeInfo.idx = info.idx
          this.storeInfo.isDisplay = info.isDisplay
          this.storeInfo.shopAddress = info.shopAddress
          this.storeInfo.shopAddressDetail = info.shopAddressDetail
          this.storeInfo.shopAreaCode1 = info.shopAreaCode1
          this.storeInfo.shopAreaCode2 = info.shopAreaCode2
          this.storeInfo.shopName = info.shopName
          this.storeInfo.shopType = info.shopType
          this.storeInfo.shopPhone = info.shopPhone
          this.storeInfo.shopPostalCode = info.shopPostalCode
          this.storeInfo.shopRegDate = info.shopRegDate
          this.storeInfo.shopRegId = info.shopRegId
          this.storeInfo.shopUpdDate = info.shopUpdDate
          this.storeInfo.shopUpdId = info.shopUpdId
          this.storeInfo.shopImages = info.shopImages
        }
      })
    },
    onList () {
      this.$router.push({path: '/store/list', query: {search: this.search}})
    },
    onCreate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 매장 정보 등록 실패 입력 FORM 오류')
        return
      }
      this.confirm('등록 하시겠습니까?', () => {
        let tempImgList = this.storeInfo.shopImages.filter(f => { if (f.filename !== '') return f.filename }).map(img => { return img.filename })
        let reqParam = {
          isDisplay: this.storeInfo.isDisplay,
          shopAddress: this.storeInfo.shopAddress,
          shopAddressDetail: this.storeInfo.shopAddressDetail,
          shopAreaCode1: this.storeInfo.shopAreaCode1,
          shopAreaCode2: this.storeInfo.shopAreaCode2,
          shopName: this.storeInfo.shopName,
          shopPhone: this.storeInfo.shopPhone,
          shopPostalCode: this.storeInfo.shopPostalCode,
          shopType: this.storeInfo.shopType,
          shopImages: tempImgList
        }
        if (!this._isInvalidateInputForm()) return
        this.$api.api.registerStore(reqParam).then(resp => {
          if (resp.data.result) {
            this.alert('등록되었습니다.', () => {
              this.$router.push({path: '/store/list'})
            })
          }
        })
      })
    },
    onUpdate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 매장 정보 등록 실패 입력 FORM 오류')
        return
      }
      this.confirm('수정 하시겠습니까?', () => {
        let tempImgList = this.storeInfo.shopImages.filter(f => { if (f.filename !== '') return f.filename }).map(img => { return img.filename })
        let reqParam = {
          idx: this.storeInfo.idx,
          isDisplay: this.storeInfo.isDisplay,
          shopAddress: this.storeInfo.shopAddress,
          shopAddressDetail: this.storeInfo.shopAddressDetail,
          shopAreaCode1: this.storeInfo.shopAreaCode1,
          shopAreaCode2: this.storeInfo.shopAreaCode2,
          shopName: this.storeInfo.shopName,
          shopPhone: this.storeInfo.shopPhone,
          shopPostalCode: this.storeInfo.shopPostalCode,
          shopType: this.storeInfo.shopType,
          shopImages: tempImgList
        }
        this.$api.api.modifyStore(reqParam).then(resp => {
          if (resp.data.result) {
            this.alert('수정되었습니다.', () => {
              this.$router.go(0)
            })
          }
        })
      })
    },
    onDelete () {
      this.confirm('삭제 하시겠습니까?', () => {
        var reqBody = {
          idxList: [
            this.storeInfo.idx
          ]
        }
        this.$api.api.deleteStore(reqBody).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.$router.go(-1)
            })
          } else {
            this.alert('삭제 실패')
          }
        })
      })
    },
    callAddrSearchPop () {
      this.$modal.show(
        DaumPostalPop,
        {text: '우편번호검색'},
        {draggable: false, resizable: false, height: 'auto'},
        {
          'before-close': event => {
            let addrObj = event.params
            this.storeInfo.shopPostalCode = addrObj.zonecode
            this.storeInfo.shopAddress = addrObj.address
            this.storeInfo.shopAddressDetail = ''
          }
        }
      )
    },
    addData () {
      if (this.storeInfo.shopImages.length >= 10) {
        this.alert('사진은 최대 10장만 등록 가능합니다.')
        return false
      }
      this.storeInfo.shopImages.push({
        idx: '',
        filename: '',
        thumb1: '',
        seq: this.storeInfo.shopImages.length
      })
      this.reOrdering()
    },
    reOrdering () {
      this.storeInfo.shopImages.map((item, idx) => {
        item.seq = idx + 1
      })
    },
    changeImage (e, item) {
      item.filename = e.name
      item.thumb1 = e.path + '/' + e.name
    },
    deleteRow (idx) {
      this.storeInfo.shopImages.splice(idx, 1)
      this.reOrdering()
    },
  }
}
</script>

<style scoped>
</style>
