<template>
    <div>
        <div class="app-page-title">
            <navigation-bar :nav-title="'비주얼관리'" :nav-list="['맥케이슨관리', '비주얼관리', '홈화면 노출 관리', '일반 영역']"/>
        </div>

        <div class="row">
            <div class="col-md-12">
                <!-- 상단 템플릿 선택 영역 : Begin -->
                <div class="main-card mb-3 card general-area">
                    <div class="card-header font-size-lg"><span class="">일반영역 항목 등록</span></div>
                    <div class="card-body">
                        <div class="col-12 pr-0 pl-0">
                            <div class="float-left">
                                <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2">템플릿 선택</h5>
                                <select class="custom-select wd-200 mr-1" v-model="curTab" :disabled="!!visualIdx">
                                    <option value="">선택</option>
                                    <option v-for="(tab, tabIdx) in tabList" :value="tab.code" :key="tabIdx">{{ tab.name }}</option>
                                </select>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-secondary mr-0 wd-100" @click="goPrevPage">목록으로</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 상단 템플릿 선택 영역 : End -->

                <!-- 프로모션 : Begin -->
                <template v-if="curTab === 1">
                    <!-- 프로모션 공통 설정 -->
                    <general-promotion-common @changed="changeBg" />

                    <!-- 프로모션 -->
                    <general-promotion :bg-list="bgList.filter(f => f.idx !== '')" :item-type="curTab" :idx="visualIdx" />
                </template>
                <!-- 프로모션 : End -->

                <!-- 카테고리 : Begin -->
                <template v-if="curTab === 2">
                    <general-category :item-type="curTab" :idx="visualIdx" :cat1="cat1Idx" />
                </template>
                <!-- 카테고리 : End -->

                <!-- 상품노출 : Begin -->
                <template v-if="curTab === 3">
                    <general-product-expose :item-type="curTab" :idx="visualIdx" />
                </template>
                <!-- 상품노출 : End -->

                <!-- 컬렉션 : Begin -->
                <template v-if="curTab === 4">
                    <general-collection :item-type="curTab" :idx="visualIdx" />
                </template>
                <!-- 컬렉션 : End -->

            </div>
        </div>

    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import NavigationBar from '@/page/components/NavigationBar'
import GeneralPromotionCommon from '@/page/visual/home/components/GeneralPromotionCommon'
import GeneralPromotion from '@/page/visual/home/components/GeneralPromotion'
import GeneralCategory from '@/page/visual/home/components/GeneralCategory'
import GeneralProductExpose from '@/page/visual/home/components/GeneralProductExpose'
import GeneralCollection from '@/page/visual/home/components/GeneralCollection'

export default {
    name: 'HomeGeneralRegistration',
    components: {
        NavigationBar,
        GeneralPromotionCommon,
        GeneralPromotion,
        GeneralCategory,
        GeneralProductExpose,
        GeneralCollection,
    },
    mixins: [
        CommonMixin, InterfaceMixin
    ],
    data () {
        return {
            tabList: [
                {code: 1, name: '프로모션'},
                {code: 2, name: '카테고리별 이미지'},
                {code: 3, name: '상품노출'},
                {code: 4, name: '컬렉션'},
            ],
            curTab: '',
            visualIdx: '',
            cat1Idx: '',

            bgList: [],
        }
    },
    created () {
        this.visualIdx = this.$route.query.idx
        if (this.visualIdx) {
            this.getDataList()
        }
    },
    mounted () {
    },
    methods: {
        getDataList() {                                        // ┌ visualIdx
            return this.$api.api.getMngVisualCommonTemplate(this.visualIdx).then(resp => {
                this.curTab = resp.data.data.itemType
                this.cat1Idx = resp.data.data.cat1Idx
            }).catch(error => {
                console.log(error)
            })
        },

        goPrevPage () {
            this.$router.back()
        },
        changeBg (bgList) {
            this.bgList = bgList
        },
    }
}
</script>
