<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'상품문의'" :nav-list="['맥케이슨관리', '게시판관리', '상품문의관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setStartDate" @inputEndDate="setEndDate" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="month" :search-period="{amount: 6, unit: 'M'}"/>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">구분</label>
                  <select class="form-control" v-model="search.category">
                    <option value="">전체</option>
                    <option v-for="item in srchCategoryList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">처리여부</label>
                  <select class="form-control" v-model="search.state">
                    <option value="">전체</option>
                    <option v-for="item in srchStateList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-row pt-4">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="init">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="chgSrchPageCnt">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <colgroup>
                  <col width="5%" />
                  <col width="5%" />
                  <col width="10%" />
                  <col width="5%" />
                  <col width="*" />
                  <col width="400px" />
                  <col width="15%" />
                  <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" v-model="selectAll"/></th>
                  <th class="text-center">번호</th>
                  <th class="text-center">회원명</th>
                  <th class="text-center">구분</th>
                  <th class="text-center">질문제목</th>
                  <th class="text-center">질문내용</th>
                  <th class="text-center">질문등록일시</th>
                  <th class="text-center">처리여부</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!resultDataList.length">
                  <td colspan="8" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(item, idx) in resultDataList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="chkList" :value="item.qnaIdx" :id="item.qnaIdx" /></td>
                  <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                  <td class="text-center">{{ item.cliName === '' ? '비회원' : item.cliName }}</td>
                  <td class="text-center">{{ item.category === 1 ? '상품' : item.category === 2 ? '주문' : item.category === 3 ? '배송' : item.category === 4 ? '취소' : item.category === 5 ? '반품' : item.category === 6 ? '교환' : '기타' }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="goDetail(item.qnaIdx)">{{ item.title }}</a></td>
                  <td class="text-center"><div class="p" v-html="item.question.replaceAll('<br>', '\r\n')"></div></td>
                  <td class="text-center">{{ item.rgdtQna }}</td>
                  <td class="text-center">{{ item.state === 0 ? '답변대기' : '답변완료' }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="resultDataList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="delPrdQna">삭제</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from "@/page/components/Pagination"
import DatePickerButton from "@/components/common/DatePickerButton"
import InterfaceMixin from '@/mixins/userInterface-mixin'
import moment from 'moment'


export default {
  name: "PrdQna",
  data () {
    return {
      search: {
        srchDateStart: '',
        srchDateEnd: '',
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        category: '',
        state: ''
      },
      srchCategoryList: [
        { value: 1, text: '상품' },
        { value: 2, text: '주문' },
        { value: 3, text: '배송' },
        { value: 4, text: '취소' },
        { value: 5, text: '반품' },
        { value: 6, text: '교환' },
        { value: 7, text: '기타' }
      ],
      srchStateList: [
        { value: 0, text: '답변대기'},
        { value: 1, text: "답변완료"}
      ],
      srchTypeList: [
        { value: 1, text: '상품번호' },
        { value: 2, text: '주문번호' },
        { value: 3, text: '회원명' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      resultDataList: [],
      totalCnt: 0,
      chkList: [],
    }
  },
  components: {
    NavigationBar,
    Pagination,
    DatePickerButton
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    selectAll: {
      get () {
        return this.resultDataList.length ? this.chkList.length === this.resultDataList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.resultDataList.map(item => {
            selected.push(item.qnaIdx)
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {

  },
  mounted () {
    this.init();
    this.onSearch();
  },
  methods: {
    init() {
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD');
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD');
      this.search.category = '';
      this.search.state = '';
      this.search.srchType = '';
      this.search.srchKeyword = '';
      this.search.srchPage = 1;
      this.search.srchPageCnt = 10;
    },
    onSearch(){
      this.$api.api.getPrdQnaList(this.search).then(res => {
        console.log('res.data :: ', JSON.stringify(res.data));
        if (res.data.result) {
          this.resultDataList = res.data.data;
          this.totalCnt = res.data.totalCnt;
        } else {
          this.$alert('상품문의 조회에 실패하였습니다.')
        }
      })
    },
    setStartDate (value) {
      this.search.srchDateStart = value;
    },
    setEndDate (value) {
      this.search.srchDateEnd = value;
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage;
      this.onSearch();
    },
    goDetail (qnaIdx) {
      this.$router.push({name: 'PrdQnaDetail', params: { qnaIdx: qnaIdx}})
    },
    chgSrchPageCnt () {
      this.search.srchPage = 1
      this.onSearch();
    },
    delPrdQna () {
      this.$confirm('상품문의를 삭제하시겠습니까?').then(resp => {
        if (this.chkList.length === 0) {
          this.$alert('삭제할 항목을 선택하세요.')
          return
        }

        let bodyData = {
          chkList: this.chkList
        }

        this.$api.api.deletePrdQna(bodyData).then(res => {
          console.log('res.data ::: ', JSON.stringify(res.data));
          if (res.data.result) {
            this.$alert('삭제에 성공하였습니다.')
            this.onSearch()
          } else {
            this.$alert(res.errMsg)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
  .p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 380px !important;
  }
</style>
