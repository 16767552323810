<template>
    <div>
        <div class="app-page-title">
            <navigation-bar :nav-title="'비주얼관리'" :nav-list="['맥케이슨관리', '비주얼관리', '홈화면 노출 관리']" />
        </div>

        <!-- 메인 영역 -->
        <home-top />

        <!-- 하단 동영상 -->
        <home-bottom />

        <!-- 일반 영역 -->
        <home-general @add="goGeneralAdd" @detail="goGeneralDetail" />

    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import NavigationBar from '@/page/components/NavigationBar'
import HomeTop from '@/page/visual/home/components/HomeTop'
import HomeGeneral from '@/page/visual/home/components/HomeGeneral'
import HomeBottom from '@/page/visual/home/components/HomeBottom'

export default {
    name: 'HomeList',
    data () {
        return {
        }
    },
    components: {
        NavigationBar,
        HomeTop,
        HomeGeneral,
        HomeBottom,
    },
    mixins: [
        CommonMixin, InterfaceMixin
    ],
    created () {
    },
    mounted () {
    },
    methods: {
        goGeneralAdd () {
            this.$router.push({path: '/home/general_add'})
        },
        goGeneralDetail (idx) {
            this.$router.push({path: '/home/general_add', query: {idx: Number.parseInt(idx)}})
        },
    },
}
</script>
