<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'회원관리'" :nav-list="['맥케이슨관리', '사내판매관리', '회원관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">회원타입</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value=0>전체</option>
                    <option v-for="(item, idx) in srchTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="form-row">
                  <div class="col-md-2">
                    <div class="position-relative">
                      <label class="">검색조건</label>
                      <select class="form-control" v-model="search.lookupType">
                        <option v-for="(item, idx) in lookupTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-10">
                    <div class="position-relative mt-2">
                      <label class="">&nbsp;</label>
                      <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">회원추가</div>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">회원타입</label>
                  <select class="form-control" v-model="member.memberType">
                    <option v-for="(item, idx) in memberTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">이름</label>
                  <input type="text" class="mr-2 form-control" v-model="member.memberName" placeholder="이름">
                </div>
              </div>
              <div class="col-md-3">
                <div class="position-relative form-group">
                  <label class="">전화번호</label>
                  <the-mask type="tel" :mask="['###-####-####', '###-###-####']" pattern="\d*" class="form-control" placeholder="전화번호(-)없이" v-model="member.memberPhone" :key="member.idx"/>
                </div>
              </div>
              <div class="col-md-3">
                <div class="position-relative form-group">
                  <label class="">구매한도</label>
                  <input type="number" class="mr-2 form-control" v-model="member.purchaseLimit" placeholder="구매한도액(,)없이" v-number-input min="0" @keyup.enter="onCreate">
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">&nbsp;</label>
                  <div class="mt-2 text-center">
                    <button class="btn btn-success wd-100 mr-1 form-control" @click="onCreate" v-if="!isUpdate">추가</button>
                    <button class="btn btn-warning wd-100 mr-1 form-control" @click="onUpdate" v-if="isUpdate">수정</button>
                    <button class="btn btn-warning wd-100 mr-1 form-control" @click="onReset">초기화</button>
                  </div>
                </div>
              </div>
            </div>

            <!--
            <div class="position-relative row form-group">
              <div class="col-sm-2">
                <select class="form-control" v-model="member.memberType">
                  <option value="">회원타입선택</option>
                  <option v-for="(item, idx) in memberTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                </select>
              </div>
              <div class="col-sm-2">
                <input type="text" class="mr-2 form-control" v-model="member.memberName" maxlength="10" placeholder="이름">
              </div>
              <div class="col-sm-2">
                <the-mask type="tel" :mask="['###-####-####', '###-###-####']" pattern="\d*" class="form-control" placeholder="전화번호(-)없이" v-model="member.memberPhone" :key="member.idx" maxlength="13"/>
              </div>
              <div class="col-sm-2">
                <input type="text" class="mr-2 form-control" v-model="member.purchaseLimit" placeholder="구매한도액(,)없이" maxlength="9" v-number-input min="0" @keyup.enter="isUpdate ? onUpdate() : onCreate()" >
              </div>
              <div class="col-sm-3">
                <button class="btn btn-success wd-100 mr-1" @click="onCreate" v-if="!isUpdate">추가</button>
                <button class="btn btn-warning wd-100 mr-1" @click="onUpdate" v-if="isUpdate">수정</button>
                <button class="btn btn-success wd-100 mr-1" @click="onReset" v-if="isUpdate">초기화</button>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title float-left mb-0 mt-2">※구매한도액은 매년 1월초에 설정된 한도액으로 자동설정됩니다. / 구매한도 부족으로 인한 구매불가시 '인사팀장'이 수정 가능합니다.</h5>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">회원타입</th>
                  <th class="text-center">이름</th>
                  <th class="text-center">전화번호</th>
                  <th class="text-center">구매한도액</th>
                  <th class="text-center">(현)구매한도 잔여</th>
                  <th class="text-center">삭제</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!memberList.length">
                  <td colspan="6" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in memberList" :key="idx" v-else>
                  <td class="text-center">{{ data.memberType | memberType }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="setData(data)">{{ data.memberName }}</a></td>
                  <td class="text-center"><a href="javascript:void(0)" @click="regiPop(data.idx)">{{ data.memberPhone | dash }}</a></td>
                  <td class="text-center">{{ data.purchaseLimit | comma }}</td>
                  <td class="text-center">{{ data.remainLimit | comma }}</td>
                  <td class="text-center"><button class="btn btn-danger wd-100" @click="onDelete(data.idx)">삭제</button></td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="total" for="memberList" v-on:vue-pagination::memberList="onPage" v-show="memberList.length"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import codeMixin from '@/mixins/code-mixin'
import Pagination from "../../components/Pagination";
import { required, maxLength } from "vuelidate/lib/validators";
import RegiStoreDiscountPop from "@/page/store/Member/components/RegiStoreDiscountPop";

export default {
  name: "MemberList",
  data() {
    return {
      isUpdate: false,
      memberList: [],
      total: 0,
      member: {
        idx: '',
        memberType: 2,
        memberName: '',
        memberPhone: '',
        purchaseLimit: ''
      },
      search: {
        lookupType: 1,
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: 0
      },
      srchTypeList: [
        {value: 1, text: 'VIP'},
        {value: 2, text: '직원'}
      ],
      lookupTypeList: [
        {value: 1, text: '회원명'},
        {value: 2, text: '전화번호'}
      ],
      memberTypeList: [
        {value: 1, text: 'VIP'},
        {value: 2, text: '직원'}
      ]
    }
  },
  validations:{
    member: {
      idx: { required },
      memberType: { required },
      memberName: { required, maxLength: maxLength(10) },
      memberPhone: { required, maxLength: maxLength(13) },
      purchaseLimit: { required, maxLength: maxLength(9) }
    }
  },
  mixins: [
    InterfaceMixin,
    CommonMixin,
    codeMixin
  ],
  watch: {
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  components: {
    NavigationBar,
    Pagination
  },
  methods: {
    _isInvalidateInputForm() {
      // if (this.$v.$invalid) {
        if (!this.$v.member.memberType.required) {
          this.alert('회원타입을 선택해주세요.')
          return false
        }
        if (!this.$v.member.memberName.required ) {
          this.alert('회원명을 입력해주세요.')
          return false
        }
      if (!this.$v.member.memberPhone.required) {
        this.alert('전화번호를 입력해주세요.')
        return false
      }
      if (!this.$v.member.purchaseLimit.required) {
        this.alert('구매한도액을 입력해주세요.')
        return false
      }
      if (!this.$v.member.memberName.maxLength) {
        this.alert('회원명은 최대 10자리 이상 입력할 수 없습니다.')
        return false
      }
      if (!this.$v.member.memberPhone.maxLength) {
        this.alert('전화번호는 최대 12자리 이상 입력할 수 없습니다.')
        return false
      }
      if (!this.$v.member.purchaseLimit.maxLength) {
        this.alert('구매한도액은 최대 일억 단위를 초과하여 입력할 수 없습니다.')
        return false
      }
      // }
      return true
    },
    onCreate() {
      if ( !this._isInvalidateInputForm() ) {
        console.log('register-error')
        return
      }
      this.confirm('등록 하시겠습니까?', () => {
        this.$api.api.registerMember(this.member).then(resp => {
          if (resp.data.result) {
            this.alert('등록되었습니다.')
            this.getList()
          } else {
            this.alert(resp.data.errMsg)
          }
          this.onRemove()
        })
      })
    },
    setData(data) {
      this.isUpdate = true
      console.log('setData:::', data)
      this.member.idx = data.idx
      this.member.memberType = data.memberType
      this.member.memberName = data.memberName
      this.member.memberPhone = data.memberPhone
      this.member.purchaseLimit = data.purchaseLimit
    },
    onUpdate() {
      if ( !this._isInvalidateInputForm() ) {
        console.log('update-error')
        return
      }
      this.confirm('수정 하시겠습니까?', () => {
        let params = {
          idx: this.member.idx,
          memberType: this.member.memberType,
          memberName: this.member.memberName,
          memberPhone: this.member.memberPhone,
          purchaseLimit: this.member.purchaseLimit
        }
        console.log(params)
        this.$api.api.modifyMember(params).then(resp => {
          if (resp.status === 200 ) {
            if (resp.data.result) {
              // this.alert('수정되었습니다.', () => {
              this.alert('수정되었습니다.')
              this.getList()
            } else {
              this.alert(resp.data.errMsg)
            }
            this.onRemove()
          }
        })
      })
    },
    onPage(page) {
      this.search.srchPage = page
      this.getList()
    },
    onDelete(delIdx) {
      this.confirm('해당 회원을 삭제 하시겠습니까?', () => {
        var reqBody = {
          idxList: [delIdx]
        }
        this.$api.api.deleteMember(reqBody)
            .then(resp => {
              if (resp.data.result) {
                // this.alert('삭제되었습니다.', () => {
                this.alert('삭제되었습니다.')
                this.getList()
              } else {
                this.alert('삭제에 실패 하였습니다.')
              }
            })
            .catch(error => {
              console.log('delete-error:::', error)
            })
      })
    },
    onSearch() {
      this.isUpdate = false
      this.search.srchPage = 1
      this.getList()
      this.onRemove()
    },
    getList() {
      this.$api.api.getMemberList(this.search)
          .then(resp => {
            console.log(resp)
            this.memberList = resp.data.data
            this.srchPageCnt = resp.data.srchPageCnt
            this.total = resp.data.total
          })
          .catch(error => {
            console.log('getList-error:::', error)
          })
    },
    onRemove() {
      this.member.idx = ''
      this.member.memberType = 2
      this.member.memberName = ''
      this.member.memberPhone = ''
      this.member.purchaseLimit = ''
    },
    onReset() {
      this.onRemove()
      this.isUpdate = false
    },
    regiPop(idx) {
      this.$modal.show(
        RegiStoreDiscountPop,
        {memberIdx: idx, type: 'inhouse'},
        {draggable: false, resizable: false, height: 'auto', width: 1200},
        {
          'before-close': event => {
            if (event.params) {
              this.getList()
            }
          }
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
