import Vue from 'vue'
import errorImg from '@/assets/images/no-image.png'

const imgErrorCallback = e => {
    e.target.src = errorImg
}
const imgErrorBindFunc = (el, binding) => {
    if (el.tagName === 'IMG') {
        el.onerror = imgErrorCallback
    } else {
        if (binding.value.hasOwnProperty('src')) {
            el.style.backgroundImage = `url (${binding.value.src}), url (${errorImg})`
        } else {
            el.style.backgroundImage = `url (${errorImg})`
        }
    }
}

export default {
    imageError: {
        bind (el, binding) {
            imgErrorBindFunc(el, binding)
        },
        update (el, binding) {
            imgErrorBindFunc(el, binding)
        },
        unbind (el) {
            el.onerror = null
        }
    }
}

// 숫자만 입력
function numberHandler(el, ele, binding, vnode) {
    function handle() {
        ele.value = ele.value.replace(/[^\d]/g, '')
    }
    return handle
}

const numberInput = {
    bind(el, binding, vnode) {
        const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
        ele.addEventListener('input', numberHandler(el, ele, binding, vnode), false)
    }
}
Vue.directive('numberInput', numberInput)

function numberAndEnglishHandler(el, ele, binding, vnode) {
    function handle() {
        let regType = /[^A-Za-z0-9]+/;

        ele.value = ele.value.replace(regType, '')
    }

    return handle
}

const numberAndEnglishInput = {
    bind(el, binding, vnode) {
        const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
        ele.addEventListener('input', numberAndEnglishHandler(el, ele, binding, vnode), false)
    }
}
Vue.directive('numberAndEnglishInput', numberAndEnglishInput)
