export default http => {
  return {
    // ------------------------------------------------------------------------------------------------- 로그인
    doLoginProcess(bodyData) {
      return http._sendHttpPostMethod("/login", bodyData)
    },

    // ------------------------------------------------------------------------------------------------- 이미지업로드
    uploadEditorImage(formData) {
      return http._sendHttpMultipartFile("/file/upload_image", formData)
    },

    // ------------------------------------------------------------------------------------------------- 파일업로드
    uploadFile(formData, branch) {
      return http._sendHttpMultipartFile(`/file/upload_image?branch=${branch}`, formData)
    },

    getBankList() {
      return http._sendHttpGetMethod("/bank_list")
    },

    // ------------------------------------------------------------------------------------------------- 관리자 관리
    // 관리자 목록
    getAdminList(bodyData) {
      return http._sendHttpPostMethod("/mng/get_admin_list", bodyData)
    },
    // 관리자 상세조회
    getAdminDetail(bodyData) {
      return http._sendHttpPostMethod("/mng/get_admin_detail", bodyData)
    },
    // 관리자 등록
    reqAdminRegistration(bodyData) {
      return http._sendHttpPostMethod("/mng/regi_admin", bodyData)
    },
    // 관리자 수정
    reqAdminModification(bodyData) {
      return http._sendHttpPostMethod("/mng/modify_admin", bodyData)
    },
    // 관리자 삭제
    reqAdminDeletation(bodyData) {
      return http._sendHttpPostMethod("/mng/delete_admin", bodyData)
    },

    // ------------------------------------------------------------------------------------------------- 매장관리
    // 매장관리 목록
    getStoreList(bodyData) {
      return http._sendHttpPostMethod("/shop/get_shop_list", bodyData)
    },

    // 매장관리 삭제
    deleteStore(bodyData) {
      return http._sendHttpPostMethod("/shop/delete_shop", bodyData)
    },

    // 매장관리 수정
    modifyStore(bodyData) {
      return http._sendHttpPostMethod("/shop/modify_shop", bodyData)
    },

    // 매장관리 상세조회
    getStoreDetail(bodyData) {
      return http._sendHttpPostMethod("/shop/get_shop_detail", bodyData)
    },

    // 매장관리 등록
    registerStore(bodyData) {
      return http._sendHttpPostMethod("/shop/regi_shop", bodyData)
    },

    // 매장 노출여부 변경
    switchDisplayStatus(bodyData) {
      return http._sendHttpPostMethod("/shop/switch_display_shop", bodyData)
    },
    // 지역코드 정보 조회
    getAreaCode() {
      return http._sendHttpGetMethod("/shop/get_area_code_list")
    },
    // 매장 노출순서 변경
    updateShopSeq (bodyData) {
      return http._sendHttpPostMethod("/shop/update_seq", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 사이즈구분관리
    // 사이즈구분관리 목록 조회
    getSizeClassList() {
      return http._sendHttpGetMethod("/class/adm_product_size_class")
    },

    // 사이즈구분관리 등록
    registerSizeClass(bodyData) {
      return http._sendHttpPostMethod("/class/adm_product_size_class_regi", bodyData)
    },

    // 사이즈구분관리 삭제
    deleteSizeClass(bodyData) {
      return http._sendHttpPostMethod("/class/adm_product_size_class_del", bodyData)
    },

    getSelectBox(bodyData) {
      return http._sendHttpGetMethod("/class/adm_product_size_class_list")
    },
    // ------------------------------------------------------------------------------------------------- 사이즈관리
    // 사이즈관리 등록
    registerSize(bodyData) {
      return http._sendHttpPostMethod("/size/adm_product_size_regi", bodyData)
    },

    // 사이즈관리 삭제
    deleteSize(bodyData) {
      return http._sendHttpPostMethod("/size/adm_product_size_del", bodyData)
    },

    // 사이즈관리 조회
    getSizeList() {
      return http._sendHttpGetMethod("/size/adm_product_size")
    },

    // ------------------------------------------------------------------------------------------------- 색상관리
    // 색상관리 조회
    getColorList() {
      return http._sendHttpGetMethod("/color/adm_color")
    },

    // 색상관리 등록
    registerColor(bodyData) {
      return http._sendHttpPostMethod("/color/adm_color_regi", bodyData)
    },

    // 색상관리 삭제
    deleteColor(bodyData) {
      return http._sendHttpPostMethod("/color/adm_color_del", bodyData)
    },

    // 색상관리 수정
    updateColor(bodyData) {
      return http._sendHttpPostMethod("/color/adm_color_update", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 배송비 관리
    // 배송비관리 조회
    getFeeList() {
      return http._sendHttpGetMethod("/delivery/adm_delivery_fee_mng")
    },
    // 배송비관리 상세조회
    getFeeDetail(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_delivery_fee", bodyData)
    },
    // 배송비관리 삭제
    deleteFee(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_delivery_fee_delete", bodyData)
    },
    // 배송비관리 수정
    updateRegionFee(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_delivery_fee_update", bodyData)
    },
    // 기본 배송비 수정
    updateBasicFee(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_delivery_fee_regi", bodyData)
    },
    // 지역별 배송비 등록
    registerRegionFee(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_delivery_addfee_regi", bodyData)
    },
    // 시군구 리스트
    getSearchArea2(bodyData) {
      return http._sendHttpGetMethod("/adm_search_si_gun_gu", bodyData)
    },

    // 우편번호 범위 검색
    getPostcode(bodyData) {
      return http._sendHttpGetMethod("/adm_search_postcode", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 상품관리
    // 상품관리 조회
    getProductList(bodyData) {
      return http._sendHttpPostMethod("/product/get_adm_product_list", bodyData)
    },
    // 상품관리 엑셀다운 요청
    exportProductList(bodyData) {
      return http._sendHttpPostExportExcel("/product/adm_product_list_excel_down", bodyData)
    },
    // 상품관리 등록
    registerProduct(bodyData) {
      return http._sendHttpPostMethod("/product/adm_product_regi", bodyData)
    },
    //상품관리 상세조회
    getProductDetail(bodyData) {
      return http._sendHttpPostMethod("/product/get_adm_product_detail", bodyData)
    },
    //상품관리 수정
    updateProduct(bodyData) {
      return http._sendHttpPostMethod("/product/adm_product_modify", bodyData)
    },
    // 상품관리 삭제
    deleteProduct(bodyData) {
      return http._sendHttpPostMethod("/product/delete_adm_product", bodyData)
    },
    // 상품관리 할인가 변경
    updateSalePrice(bodyData) {
      return http._sendHttpPostMethod("/product/change_product_sale_price", bodyData)
    },
    // 상품관리 할인율 변경
    updateSaleRate(bodyData) {
      return http._sendHttpPostMethod("/product/change_product_sale_rate", bodyData)
    },
    // 상품관리 상태 변경
    updateSaleStatus(bodyData) {
      return http._sendHttpPostMethod("/product/change_product_sale_status", bodyData)
    },
    // 카테고리 조회
    getCategory() {
      return http._sendHttpGetMethod("/product/get_adm_category_list")
    },
    // 상품 제공고시 조회
    getProductNotificationInfo(bodyData) {
      return http._sendHttpPostMethod("/product/get_adm_product_notification", bodyData)
    },
    // 컬렉션 타입 조회
    getCollectionType() {
      return http._sendHttpGetMethod("/product/get_adm_collection_list")
    },
    // 상품전시관리 조회
    getDisplayProductList(bodyData) {
      return http._sendHttpPostMethod("/product/get_adm_product_list_seq", bodyData)
    },
    // 상품전시관리 순서변경
    updateProductSeq(bodyData) {
      return http._sendHttpPostMethod("/product/update_product_seq", bodyData)
    },
    // 상품할인율 관리 - 조회
    getDiscountList() {
      return http._sendHttpGetMethod("/product/discount/dis_list")
    },
    // 상품할인율 관리 - 등록
    registDiscount(bodyData) {
      return http._sendHttpPostMethod("/product/discount/regi_discount", bodyData)
    },
    // 상품할인율 관리 - 삭제
    deleteDiscount(bodyData) {
      return http._sendHttpPostMethod("/product/discount/del_discount", bodyData)
    },
    // 상품할인율 관리 -수정
    modifyDiscount(bodyData) {
      return http._sendHttpPostMethod("/product/discount/modi_discount", bodyData)
    },
    // 상품 IDX 조회
    getPrdIdx(bodyData) {
      return http._sendHttpPostMethod("/product/get_adm_prd_Idx", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 주문관리_공통
    // 결제정보조회
    getPayInfo(bodyData) {
      return http._sendHttpGetMethod("/order/adm_order_pay_info", bodyData)
    },
    // 메모정보 조회
    getMemoList(bodyData) {
      return http._sendHttpPostMethod("/order/adm_order_memo_list", bodyData)
    },
    // 메모등록 요청
    sendMemoRegistration(bodyData) {
      return http._sendHttpPostMethod("/order/adm_order_memo_regi", bodyData)
    },
    // 택배사목록
    getCourierList() {
      return http._sendHttpGetMethod("/adm_delivery_company_list")
    },
    // 수령자정보 변경요청
    sendRecipientUpdatation(bodyData) {
      return http._sendHttpPostMethod("/order/adm_update_recepient", bodyData)
    },
    // 사유관리 목록 조회
    getReasonMngList(bodyData) {
      return http._sendHttpPostMethod("/reasonmng/reasonmng_List", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 주문관리
    // 주문관리 목록조회
    getOrderList(bodyData) {
      return http._sendHttpPostMethod("/order/adm_order_list", bodyData)
    },
    // 주문관리 엑셀다운 요청
    exportOrderList(bodyData) {
      return http._sendHttpPostExportExcel("/order/adm_order_list_excel_down", bodyData)
    },
    // 주문관리 상세조회
    getOrderDetail(bodyData) {
      return http._sendHttpGetMethod("/order/adm_order_detail", bodyData)
    },
    // 배송관리 목록조회
    getDeliveryList(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_delivery_list", bodyData)
    },
    // 배송관리 목록조회
    exportDeliveryList(bodyData) {
      return http._sendHttpPostExportExcel("/delivery/adm_delivery_list_excel_down", bodyData)
    },
    // 배송관리 배송상태 변경
    changeDeliveryStatus(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_change_delivery_status", bodyData)
    },
    // 배송관리 묶음배송 변경
    changeBundleDelivery(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_bundle_delivery_no", bodyData)
    },
    // 배송관리 운송장 변경
    changeDeliveryNo(bodyData) {
      return http._sendHttpPostMethod("/delivery/adm_change_delivery_no", bodyData)
    },
    // 취소관리 목록조회
    getCancelList(bodyData) {
      return http._sendHttpPostMethod("/claim/adm_cancel_list", bodyData)
    },
    // 취소관리 엑셀다운 요청
    exportCancelList(bodyData) {
      return http._sendHttpPostExportExcel("/claim/adm_cancel_list_excel_down", bodyData)
    },
    // 교환관리 목록조회
    getExchangeList(bodyData) {
      return http._sendHttpPostMethod("/exchange/adm_exchange_list", bodyData)
    },
    // 교환관리 상태변경
    updateExchangeStatus(bodyData) {
      return http._sendHttpPostMethod("/exchange/adm_change_exchange_status", bodyData)
    },
    // 교환관리 엑셀다운로드
    exportExchangeList(bodyData) {
      return http._sendHttpPostExportExcel("/exchange/adm_exchange_list_excel_down", bodyData)
    },
    // 교환관리 운송장 변경
    changeExchangeNo(bodyData) {
      return http._sendHttpPostMethod("/exchange/adm_exchange_delivery_no", bodyData)
    },
    updateExchangePrd(bodyData) {
      return http._sendHttpPostMethod("/exchange/adm_exchange_prd_update", bodyData)
    },
    // 배송관리 묶음배송 변경
    changeExBundleDelivery(bodyData) {
      return http._sendHttpPostMethod("/exchange/adm_exbundle_delivery_no",bodyData)
    },
    // 반품관리 목록조회
    getReturnList(bodyData) {
      return http._sendHttpPostMethod("/return/adm_return_list", bodyData)
    },
    // 반품관리 상태변경
    updateReturnStatus(bodyData) {
      return http._sendHttpPostMethod("/return/adm_change_return_status", bodyData)
    },
    // 반품관리 엑셀다운로드 요청
    exportReturnList(bodyData) {
      return http._sendHttpPostExportExcel("/return/adm_return_list_excel_down", bodyData)
    },
    // 환불관리 목록조회
    getRefundList(bodyData) {
      return http._sendHttpPostMethod("/refund/adm_refund_list", bodyData)
    },
    // 환불관리 엑셀다운 요청
    exportRefundList(bodyData) {
      return http._sendHttpPostExportExcel("/refund/adm_refund_list_excel_down", bodyData)
    },
    // 환불관리 완료 요청
    updateRefundStatus(bodyData) {
      return http._sendHttpPostMethod("/refund/adm_refund_done", bodyData)
    },
    // 클레임 상세 조회
    getClaimDetail(bodyData) {
      return http._sendHttpGetMethod("/claim/adm_claim_detail", bodyData)
    },
    claimRefund(bodyData) {
      return http._sendHttpPostMethod("/refund/adm_claim_refund", bodyData)
    },
    requestClaim(bodyData) {
      return http._sendHttpPostMethod("/claim/adm_request_claim", bodyData)
    },
    // 주문 IDX 조회
    getOrderIdx(bodyData) {
      return http._sendHttpGetMethod("/order/get_adm_order_Idx", bodyData)
    },
    // clmNo로 clmIdx, ordIdx 조회
    getClaimInfo(bodyData) {
      return http._sendHttpGetMethod("/claim/adm_claim_info", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 프로모션 관리
    // 프로모션 목록조회
    getPromotionList(bodyData) {
      return http._sendHttpPostMethod("/prmotion/adm_promotion_list", bodyData)
    },
    // 프로모션 상세조회
    getPromotionDetail(bodyData) {
      return http._sendHttpPostMethod("/prmotion/adm_promotion_detail", bodyData)
    },
    // 프로모션 삭제
    deletePromotion(bodyData) {
      return http._sendHttpPostMethod("/prmotion/adm_promotion_del", bodyData)
    },
    // 프로모션 수정
    updatePromotion(bodyData) {
      return http._sendHttpPostMethod("/prmotion/adm_promotion_update", bodyData)
    },
    // 프로모션 등록
    registerPromotion(bodyData) {
      return http._sendHttpPostMethod("/prmotion/adm_promotion_regi", bodyData)
    },
    // -------------------------------------------------------------------------------------------------- [메인홈개편]
    // 비주얼관리 > 기타노출관리  목록조회
    getMngVisualEtcInfo() {
      return http._sendHttpGetMethod("/visual/get_visual_mng_info/etc");
    },
    // 비주얼관리 > 기타노출관리  저장
    saveMngVisualEtcInfo(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_save/etc", bodyData
      );
    },

    // 홈화면 노출관리 > 1.메인영역  목록조회
    getMngVisualMainInfo () {
      return http._sendHttpGetMethod('/visual/get_visual_mng_info/main')
    },
    // 홈화면 노출관리 > 1.메인영역  저장
    saveMngVisualMain(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_save/main", bodyData);
    },
    // 홈화면 노출관리 > 1.메인영역  선택삭제
    delSelectedMngVisualMain(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_del/main", bodyData);
    },

    // 홈화면 노출관리 > 2.하단동영상 영역  목록조회
    getMngVisualFooterInfo () {
      return http._sendHttpGetMethod("/visual/get_visual_mng_info/footer");
    },
    // 홈화면 노출관리 > 2.하단동영상 영역  저장
    saveVisualMngFooterInfo(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_save/footer", bodyData);
    },

    // 홈화면 노출관리 > 3.일반영역  목록조회
    getMngVisualCommonList () {
      return http._sendHttpGetMethod('/visual/get_visual_mng_info/common')
    },
    // 홈화면 노출관리 > 3.일반영역  저장
    saveMngVisualCommonSeq(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_save/common/seq", bodyData);
    },
    // 홈화면 노출관리 > 3.일반영역  선택삭제
    delSelectedMngVisualCommon(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_del/common", bodyData);
    },

    // 일반영역 (상세)  목록조회
    getMngVisualCommonTemplate(visualIdx) {
      return http._sendHttpGetMethod(`/visual/get_visual_mng_info/common/${visualIdx}`)
    },
    // 일반영역 (상세)  저장
    saveMngVisualCommonTemplate(bodyData) {
      return http._sendHttpPostMethod('/visual/visual_mng_info_save/common', bodyData)
    },
    // 일반영역 (상세)  삭제
    delMngVisualCommonTemplate(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_del/common/all", bodyData);
    },

    // 일반영역 > 프로모션 공통  목록조회
    getMngVisualPrmCommonBack() {
      return http._sendHttpGetMethod('/visual/get_visual_mng_info/common/back')
    },
    // 일반영역 > 프로모션 공통  저장
    saveMngVisualPrmCommonBack(bodyData) {
      return http._sendHttpPostMethod('/visual/visual_mng_info_save/common/back', bodyData)
    },
    // 일반영역 > 프로모션 공통  삭제
    delMngVisualPrmCommonBack(bodyData) {
      return http._sendHttpPostMethod("/visual/visual_mng_info_del/common/promotion", bodyData);
    },


    // ------------------------------------------------------------------------------------------------- 회원관리
    // 회원 목록 조회
    getMemberList(bodyData) {
      return http._sendHttpPostMethod("/member/adm_member_list", bodyData)
    },
    // 회원 등록
    registerMember(bodyData) {
      return http._sendHttpPostMethod("/member/adm_member_regi", bodyData)
    },
    // 회원 정보 수정
    modifyMember(bodyData) {
      return http._sendHttpPostMethod("/member/adm_member_modify", bodyData)
    },
    // 회원 삭제
    deleteMember(bodyData) {
      return http._sendHttpPostMethod("/member/adm_member_delete", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 판매관리
    // 판매내역 리스트 조회
    getSalesHistoryList(bodyData) {
      return http._sendHttpPostMethod("/sales/salesHistory", bodyData)
    },
    // 판매내역 리스트 엑셀
    exportSalesHistoryList(bodyData) {
      return http._sendHttpPostExportExcel("/sales/salesHistory_excel_down", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 사은품
    // 사은품 조회
    getGiftList(bodyData) {
      return http._sendHttpPostMethod("/gift_list", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 사은품
    // 회원 목록 조회
    getOfflineMemberList(bodyData) {
      return http._sendHttpPostMethod("/offline/member/adm_member_list", bodyData)
    },
    // 구매 목록 조회
    getOfflinePurchaseList(bodyData) {
      return http._sendHttpPostMethod("/offline/member/adm_order_list", bodyData)
    },
    // 구매 내역 등록
    getOfflineRegiOrderList(bodyData) {
      return http._sendHttpPostMethod("/offline/member/regi_order", bodyData)
    },
    // 구매 내역 수정
    getOfflineModOrderList(bodyData) {
      return http._sendHttpPostMethod("/offline/member/modify_order", bodyData)
    },
    // 구매 내역 삭제
    getOfflineDelOrderList (bodyData) {
      return http._sendHttpPostMethod('/offline/member/delete_order', bodyData)
    },
    // -------------------------------------------------------------------------------------------------
    // 할인제한 조회
    getDiscountRestrictionList(bodyData) {
      return http._sendHttpPostMethod("/offline/member/nondiscount_list", bodyData);
    },
    // 할인제한 등록
    regDiscountRestriction(bodyData) {
      return http._sendHttpPostMethod("/offline/member/nondiscount_add", bodyData);
    },
    // 할인제한 상세
    getDiscountRestrictionDetail(bodyData) {
      return http._sendHttpPostMethod("/offline/member/nondiscount_view", bodyData);
    },
    // 할인제한 수정
    updDiscountRestriction(bodyData) {
      return http._sendHttpPostMethod("/offline/member/nondiscount_modify", bodyData);
    },
    // 할인제한 삭제
    delDiscountRestriction(bodyData) {
      return http._sendHttpPostMethod("/offline/member/nondiscount_delete", bodyData);
    },
    // ------------------------------------------------------------------------------------------------- 회원관리
    // 회원 목록 조회
    getClientList(bodyData) {
      return http._sendHttpPostMethod('/client/client_list', bodyData)
    },
    // 회원 상세 조회
    getClientDetail(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_detail', bodyData)
    },

    // 회원 정보 수정
    updateClient(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_update', bodyData)
    },

    //회원 배송지 목록 조회
    getClientAddrList(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_addr_list', bodyData)
    },

    //회원 배송지 등록
    regiClientAddr(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_addr_regi', bodyData)
    },

    //회원 배송지 수정
    updateClientAddr(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_addr_update', bodyData)
    },

    //회원 배송지 삭제
    deleteClientAddr(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_addr_del', bodyData)
    },

    // 회원 정보 수정
    withdrawalClient(bodyData) {
      return http._sendHttpPostMethod('/client/adm_client_withdrawal', bodyData)
    },

    // ------------------------------------------------------------------------------------------------- 상품문의
    getPrdQnaList(bodyData) {
      return http._sendHttpPostMethod('/prdqna/adm_prdqna_list', bodyData)
    },
    getPrdQnaDetail(bodyData) {
      return http._sendHttpPostMethod('/prdqna/adm_prdqna_detail', bodyData)
    },
    updatePrdQna(bodyData) {
      return http._sendHttpPostMethod('/prdqna/adm_prdqna_update', bodyData)
    },
    deletePrdQna(bodyData) {
      return http._sendHttpPostMethod('/prdqna/adm_prdqna_delete', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 이벤트관리
    addEvent(bodyData) {
      return http._sendHttpPostMethod('/event/adm_event_regi', bodyData)
    },
    updateEvent(bodyData) {
      return http._sendHttpPostMethod('/event/adm_event_update', bodyData)
    },
    getEventList(bodyData) {
      return http._sendHttpPostMethod('/event/adm_event_list', bodyData)
    },
    getEventDetail(bodyData) {
      return http._sendHttpPostMethod('/event/adm_event_detail', bodyData)
    },
    deleteEvent(bodyData) {
      return http._sendHttpPostMethod('/event/adm_event_del', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 컬렉션관리
    // 목록 조회
    getCollectionTitleList() {
      return http._sendHttpGetMethod('/collection/title_list')
    },
    getColoctTitleList() {
      return http._sendHttpGetMethod('/collection/get_title_list')
    },
    getCollectionMainList(bodyData) {
      return http._sendHttpPostMethod('/collection/list', bodyData)
    },
    getCollectionTopList(idx) {
      return http._sendHttpGetMethod(`/collection/${idx}`)
    },
    getCollectionBodyList(idx) {
      return http._sendHttpGetMethod(`/collection/${idx}`)
    },
    changeDisplayStatus(bodyData) {
      return http._sendHttpPostMethod('/collection/change_display_status', bodyData)
    },
    deleteCollectionMain (bodyData) {
      return http._sendHttpPostMethod(`/collection/delete_main`, bodyData)
    },
    deleteCollection (bodyData) {
      return http._sendHttpPostMethod(`/collection/delete`, bodyData)
    },
    regiCollection(bodyData) {
      return http._sendHttpPostMethod('/collection/regi', bodyData)
    },
    regiCollectionTop(bodyData) {
      return http._sendHttpPostMethod('/collection/regi_top', bodyData)
    },
    regiCollectionMain(bodyData) {
      return http._sendHttpPostMethod('/collection/regi_main', bodyData)
    },
    getColoctList(bodyData) {
      return http._sendHttpPostMethod('/collection/get_colct_list', bodyData)
    },
    regiCollectionBody(bodyData) {
      return http._sendHttpPostMethod('/collection/regi_body', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 블랙리스트관리
    getBlackListList(bodyData) {
      return http._sendHttpPostMethod('/blacklist/adm_blacklist_list', bodyData)
    },
    getBlackDetail(bodyData) {
      return http._sendHttpPostMethod('/blacklist/adm_blacklist_detail', bodyData)
    },
    addBlackList(bodyData) {
      return http._sendHttpPostMethod('/blacklist/adm_blacklist_regi', bodyData)
    },
    updateBlackList(bodyData) {
      return http._sendHttpPostMethod('/blacklist/adm_blacklist_update', bodyData)
    },
    releBlackList(bodyData) {
      return http._sendHttpPostMethod('/blacklist/adm_blacklist_rele', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- FAQ관리
    addFaq(bodyData) {
      return http._sendHttpPostMethod('/faq/adm_faq_regi', bodyData)
    },
    getFaqList(bodyData) {
      return http._sendHttpPostMethod('/faq/adm_faq_list', bodyData)
    },
    getFaqDetail(bodyData) {
      return http._sendHttpPostMethod('/faq/adm_faq_detail', bodyData)
    },
    updateFaq(bodyData) {
      return http._sendHttpPostMethod('/faq/adm_faq_update', bodyData)
    },
    deleteFaq(bodyData) {
      return http._sendHttpPostMethod('/faq/adm_faq_delete', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 금칙어관리
    getProhibitList (bodyData) {
      return http._sendHttpPostMethod('/prohibit/adm_prohibit_list', bodyData)
    },
    getProhibitDetail (bodyData) {
      return http._sendHttpPostMethod('/prohibit/adm_prohibit_detail', bodyData)
    },
    regiProhibit (bodyData) {
      return http._sendHttpPostMethod('/prohibit/adm_prohibit_regi', bodyData)
    },
    updateProhibit (bodyData) {
      return http._sendHttpPostMethod('/prohibit/adm_prohibit_modify', bodyData)
    },
    deleteProhibit (bodyData) {
      return http._sendHttpPostMethod('/prohibit/adm_prohibit_delete', bodyData)
    },
    exportProhibitList (bodyData) {
      return http._sendHttpPostExportExcel('/prohibit/adm_prohibit_excel_down', bodyData)
    },

    // ------------------------------------------------------------------------------------------------- 상품문의
    getPurchaseReviewList(bodyData) {
      return http._sendHttpPostMethod('/purchaseReview/adm_list', bodyData)
    },
    getPurchaseReviewDetail(bodyData) {
      return http._sendHttpPostMethod('/purchaseReview/adm_detail', bodyData)
    },
    updatePurchaseReview(bodyData) {
      return http._sendHttpPostMethod('/purchaseReview/adm_update', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 포인트 관리
    getAdmClientPointDetail(bodyData) {
      return http._sendHttpPostMethod('/point/adm_client_point_detail', bodyData)
    },
    getAdmClientAvailPoint(bodyData) {
      return http._sendHttpPostMethod('/point/adm_client_avail_point', bodyData)
    },
    getAdmClientTotalAvailPoint(bodyData) {
      return http._sendHttpPostMethod('/point/adm_client_total_avail_point', bodyData)
    },
    getAdmClientTotalAvailPointExcelDown(bodyData) {
      return http._sendHttpPostExportExcel('/point/adm_client_total_avail_point_excel_down', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 통계
    getStatOrderList(bodyData) {
      return http._sendHttpPostMethod('/stat/adm_stat_order_list', bodyData)
    },
    exportStatOrderExcelDown(bodyData) {
      return http._sendHttpPostExportExcel('/stat/adm_stat_order_excel_down', bodyData)
    },
    getStatSalesList(bodyData) {
      return http._sendHttpPostMethod('/stat/adm_stat_sales_list', bodyData)
    },
    exportStatSalesExcelDown(bodyData) {
      return http._sendHttpPostExportExcel('/stat/adm_stat_sales_excel_down', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 구매확정취소
    sendPurchaseReset(bodyData) {
      return http._sendHttpPostMethod('/purchase/adm_reset', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 브로슈어관리
    getBrochureList () {
      return http._sendHttpGetMethod('/brochure/get')
    },
    sendBrochureSave (bodyData) {
      return http._sendHttpPostMethod('/brochure/save', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 버전관리
    getVersionList () {
      return http._sendHttpGetMethod('/version/get')
    },
    saveVersion (bodyData) {
      return http._sendHttpPostMethod('/version/save', bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 사내판매 할인율관리
    // 상품할인율 관리 - 조회
    getDiscountInHouseList() {
      return http._sendHttpGetMethod("/inhouse/get")
    },
    // 상품할인율 관리 - 등록
    registDiscountInHouse(bodyData) {
      return http._sendHttpPostMethod("/inhouse/regi", bodyData)
    },
    // 상품할인율 관리 - 삭제
    deleteDiscountInHouse(bodyData) {
      return http._sendHttpPostMethod("/inhouse/del", bodyData)
    },
    // 상품할인율 관리 -수정
    modifyDiscountInHouse(bodyData) {
      return http._sendHttpPostMethod("/inhouse/modi", bodyData)
    },
    // ------------------------------------------------------------------------------------------------- 사내판매 카테고리 할인율관리
    // 사내판매 카테고리 할인율 관리 - 조회
    getDiscountInHouseCatList() {
      return http._sendHttpGetMethod("/inhouseCat/get")
    },
    // 사내판매 카테고리 할인율 관리 - 등록
    registDiscountInHouseCat(bodyData) {
      return http._sendHttpPostMethod("/inhouseCat/regi", bodyData)
    },
    // 사내판매 카테고리 할인율 관리 - 삭제
    deleteDiscountInHouseCat(bodyData) {
      return http._sendHttpPostMethod("/inhouseCat/del", bodyData)
    },
    // 사내판매 카테고리 할인율 관리 - 수정
    modifyDiscountInHouseCat(bodyData) {
      return http._sendHttpPostMethod("/inhouseCat/modi", bodyData)
    },

  }
}
