<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th><input type="checkbox" v-model="chkAll" /></th>
              <th>상품별 주문번호<template v-if="claimType != 'cancel'"><br/>/송장번호</template></th>
              <th>상품번호</th>
              <th>상품명<br/>/옵션</th>
              <th>수량</th>
              <th>배송상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!order.admOrderDetailOptions.length">
              <td class="text-center" colspan="4">선택된 상품이 존재하지 않습니다.</td>
            </tr>
            <tr v-for="(product, index) in order.admOrderDetailOptions">
              <td class="text-center"><input type="checkbox" v-model="chkList" :value="product.opoIdx" :disabled="disList.includes(product.opoIdx)" v-if="checkType(product)"/></td>
              <td class="text-center">
                {{ product.ordNo }}_{{ product.opoIdx }}
                <template v-if="claimType != 'cancel'">
                    <br/>({{ product.deliveryComp }} {{ product.deliveryNo }})
                </template>
              </td>
              <td class="text-center">{{ product.prdNo }}</td>
              <td class="text-center">{{ product.ordProduct }}<br/>/{{ product.opoColName }}<template v-if="product.opoSize">/{{ product.opoSize }}</template></td>
              <td class="text-center">{{ product.quantity | comma }}</td>
              <td class="text-center">{{ (product.opoPurchaseConfirm == 'Y'? '구매확정' : product.odvStatus) | odvStatus }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-100 mr-2" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100" @click="complete">적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'ProductAddPop',
  data () {
    let tmp = this.chkPrdList.map(data => {return data.opoIdx})
    return {
      isOpen: false,
      chkList: tmp,
      disList: tmp
    }
  },
  validations: {
  },
  components: {
  },
  mixins: [
    CommonMixin,
    InterfaceMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    claimType: {
      type: String,
      default: () => {
        return ''
      }
    },
    order: {
        type: Object,
        default: () => {
            return {};
        }
    },
    chkPrdList: {
        type: Array,
        default: () => {
            return [];
        }
    }
  },
  computed: {
    chkAll: {
        get () {
            return this.order.admOrderDetailOptions.length ? this.order.admOrderDetailOptions.length === this.chkList.length : false
        },
        set (value) {
            let selected = this.chkPrdList.map(data => {return data.opoIdx})
            if (value) {
                this.order.admOrderDetailOptions.map((item, idx) => {
                    if(this.checkType(item) && !selected.includes(item.opoIdx)) selected.push(item.opoIdx)
                })
            }
            this.chkList = selected
        }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    onCancle () {
      this.$emit('close')
    },
    complete (result) {
        let selected = this.chkPrdList.map(data => {return data.opoIdx})
        let selecting = this.chkList.filter(data => !selected.includes(data))
        if(selecting.length < 1) {
            this.alert('상품을 선택해주세요.')
            return
        }
        this.$emit('close', this.chkList)
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    checkType(product) {
        if(product.opoPurchaseConfirm != 'Y' && product.quantityAvail > 0) {
            switch(this.claimType) {
                case 'cancel' : return product.odvStatus == 0
                case 'exchange' : return product.odvStatus == 3
                case 'return' : return product.odvStatus == 3
            }
        }
        return false
    }
  }
}

</script>
