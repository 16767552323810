const mutationType = Object.freeze({
  SET_AUTH_TOKEN: 'setAuthToken',
  RESET_AUTH_TOKEN: 'resetAuthToken',
  RESET_USER_DATA: 'resetUserData',
  SET_USER_DATA: 'setUserData'
})

const actionType = Object.freeze({
  SET_USER_DATA: 'setUserData',
  ACTION_LOGIN: 'doLogin',
  ACTION_LOGOUT: 'doLogout'
})

export { mutationType, actionType }
