<template>
  <div class="main-card mb-3 card">
    <div class="card-header font-size-lg">카테고리별 이미지</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row form-group">
            <div class="col-12 m-2">
              <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-100">대분류 선택</h5>
              <select class="custom-select wd-200 mr-1" v-model="cat1Idx" @change="onChangeCat"  :disabled="this.idx !== ''">
                <option value="">선택</option>
                <option v-for="(cat, selectIdx) in cat1List" :value="cat.cat1Idx" :key="selectIdx">{{cat.cat1Name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped">
          <thead>
          <tr>
            <th class="text-center">구분</th>
            <th class="text-center">PC 이미지</th>
            <th class="text-center">Mobile 이미지</th>
          </tr>
          </thead>
          <tbody v-if="cat1Idx === ''">
            <tr>
              <td class="text-center" colspan="3">대분류를 선택 해주세요.</td>
            </tr>
          </tbody>
          <tbody v-if="cat1Idx !== ''">
              <tr v-for="(cat2, cat2Idx) in getCategoryLv2()">
                <td class="text-center">{{ cat2.cat2Name }}</td>
                <td class="text-center">
                  <template v-if="list.find(f => f.cat2Idx === cat2.cat2Idx) && list.find(f => f.cat2Idx === cat2.cat2Idx).pcImg">
                    <img v-if="list.find(f => f.cat2Idx === cat2.cat2Idx).pcImg.filename !== ''" style="width: 120px; height: 120px;" class="images" v-image-error v-viewer
                         :src="getImagePath(list.find(f => f.cat2Idx === cat2.cat2Idx).pcImg.thumb1)" />
                    <upload-photo branch="visual" @changed="changeImage($event, list.find(f => f.cat2Idx === cat2.cat2Idx).pcImg)" />
                  </template>
                </td>
                <td class="text-center">
                  <template v-if="cat2Idx === 0 && list.find(f => f.cat2Idx === cat2.cat2Idx) && list.find(f => f.cat2Idx === cat2.cat2Idx).mobileImg">
                    <img v-if="list.find(f => f.cat2Idx === cat2.cat2Idx).mobileImg.filename !== ''" style="width: 120px; height: 120px;" class="images" v-image-error v-viewer
                         :src="getImagePath(list.find(f => f.cat2Idx === cat2.cat2Idx).mobileImg.thumb1)" />
                    <upload-photo branch="visual" @changed="changeImage($event, list.find(f => f.cat2Idx === cat2.cat2Idx).mobileImg)" />
                  </template>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <div class="col-12 pr-0 pl-0">
        <div class="float-left">
        </div>
        <div class="float-right">
          <button class="btn btn-success ml-1 wd-100" @click="onSave()">저장</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPhoto from "@/components/common/UploadPhoto";
import {CommonMixin} from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";

export default {
  name: 'GeneralCategory',
  components: {UploadPhoto},
  mixins: [CommonMixin, InterfaceMixin],
  props: {
    itemType: {
      type: Number | String,
      default: 0
    },
    idx: {
      type: Number | String,
      default: ''
    },
    cat1: {
      type: Number | String,
      default: 0
    },
  },
  data() {
    return {
      cat1Idx: '',
      visualIdx: '',
      cat1List: [],
      cat2List: [],
      list: []
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getCategoryLv1()
    this.cat1 !== "" ? this.cat1Idx = this.cat1 : this.cat1Idx = ""
    this.cat1Idx = this.cat1

    this.visualIdx = this.idx
    this.getDataList()
  },
  mounted() {
  },
  methods: {
    onChangeCat() {
      // this.visualIdx = ""
      this.getDataList()
    },
    getDataList() {
      if (this.visualIdx === "" || this.cat1Idx !== this.cat1) {
        this.list = this.cat2List.filter(cl => cl.cat1Idx === this.cat1Idx).map(cl => {
          return {
            idx: '',
            cat2Idx: cl.cat2Idx,
            pcImg: { idx: '', filename: '' },
            mobileImg: { idx: '', filename: '', path: '' }
          }
        })
      } else if (this.idx) {
        return this.$api.api.getMngVisualCommonTemplate(this.visualIdx).then(resp => {
          if (resp.status === 200) {
            resp.data.data.categoryList.forEach(f => {
              if (f.pcImg === null) { f.pcImg = { idx: '', filename: '', path: '' } }
              if (f.mobileImg === null) { f.mobileImg = { idx: '', filename: '', path: '' } }
              this.list.push(f)
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    getCategoryLv1() {
      this.$api.api.getCategory().then(resp => {
        if (resp.data.result) {
          this.cat1List = resp.data.data
          this.cat1List.map(cat1 => {
            this.cat2List.push({
              cat1Idx: cat1.cat1Idx,
              cat2Idx: 0,
              cat2Name: "메인"
            })
            cat1.cat2List.map(cat2 => {
              this.cat2List.push({
                cat1Idx: cat1.cat1Idx,
                cat2Idx: cat2.cat2Idx,
                cat2Name: cat2.cat2Name
              })
            })
          })
        } else {
          this.alert("카테고리 조회 실패");
        }
      });
    },
    getCategoryLv2() {
      return this.cat2List.filter(cat => {
        return cat.cat1Idx === this.cat1Idx
      })
    },
    _isInvalidateInputForm() {
      if (this.cat1Idx === '') {
        this.alert("대분류를 선택해 주세요.");
        return false
      }
      if (this.list[0].pcImg.filename === "") {
        this.alert("[PC] 메인 이미지를 등록 해주세요.")
        return false
      }
      if (this.list[0].mobileImg.filename === "") {
        this.alert("[Mobile] 메인 이미지를 등록 해주세요.")
        return false
      }
      return true
    },
    onSave() {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 카테고리별 이미지 등록 실패 입력 FORM 오류')
        return false
      }
      this.confirm("저장 하시겠습니까?", () => {
        let reqBody = {
          idx: this.visualIdx,
          itemType: this.itemType,
          cat1Idx: this.cat1Idx,
          categoryList: []
        }
        this.list.map(item => {
          let tmp = {
            idx: item.idx,
            itemType: item.itemType,
            cat1Idx: item.cat1Idx,
            cat2Idx: item.cat2Idx,
            pcImg: item.pcImg.filename ? {idx: item.pcImg.idx, filename: item.pcImg.filename } : null,
            mobileImg: item.mobileImg ? ( item.mobileImg.filename !== '' ? { idx: item.mobileImg.idx, filename: item.mobileImg.filename } : null ) : null
          }
          console.log("저장 ## ", tmp)
          reqBody.categoryList.push(tmp)
        })
        this.blockUI()
        this.$api.api.saveMngVisualCommonTemplate(reqBody).then(resp => {
          if (resp.status === 200) {
            this.visualIdx = resp.data.data
            this.alert("저장 되었습니다.")
            this.$nextTick(() => {
              this.$router.replace({path: '/home/general_add', query: {idx: this.visualIdx, nowTime: this.nowTime}})
            })
          } else {
            this.alert("저장에 실패하였습니다.")
          }
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.unBlockUI()
        })
      })
    },
    changeImage (file, thisObj) {
      if (thisObj == null) {
        thisObj = { path: '', filename: '' }
      }
      thisObj.path = file.path + '/'
      // thisObj.filename = file.path + '/' + file.name
      thisObj.idx = ''
      thisObj.filename = file.name
      thisObj.thumb1 = file.path + '/' + file.name
      thisObj.width = file.w
      thisObj.height = file.h

      console.log("file ::: ", file)
      console.log("thisObj ::: ", thisObj)
    },
  },
}
</script>
