<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'상품관리'" :nav-list="['맥케이슨관리', '상품관리', '상품관리']"/>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
<!--            <form class="">-->
              <div class="form-row">
                <div class="col-md-2">
                  <div class="position-relative form-group mt-1">
                    <select class="form-control" v-model="search.srchDateType">
                      <option value=0>등록일</option>
                      <option value=1>수정일</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="position-relative form-group">
                    <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 60, unit: 'M'}"/>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-2">
                  <div class="position-relative form-group">
                    <label class="">카테고리</label>
                    <select class="form-control" v-model="search.srchCat1Idx" @change="getCategory2">
                      <option value="" >전체</option>
                      <option v-for="item in cat1List" :value="item.cat1Idx">{{item.cat1Name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2" style="padding-top: 29px;">
                  <div class="position-relative form-group">
                    <select class="form-control" v-model="search.srchCat2Idx">
                      <option value="" >전체</option>
                      <option v-for="item in cat2List" :value="item.cat2Idx" v-if="search.srchCat1Idx == item.cat1Idx">{{item.cat2Name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="position-relative form-group">
                    <label class="">판매상태</label>
                    <select class="form-control" v-model="search.srchIsSale">
                      <option value="">전체</option>
                      <option value="1">판매중</option>
                      <option value="-1">판매중지</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-2">
                  <div class="position-relative">
                    <select class="form-control" v-model="search.srchType">
                      <option value="">전체</option>
                      <option value=1>상품번호</option>
                      <option value=2>상품명</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="position-relative">
                    <input type="text" class="mr-2 form-control" placeholder="Search..." v-model="search.srchKeyword" @keyup.enter="onSearch">
                  </div>
                </div>
              </div>
<!--            </form>-->
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch(1)">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <input type="text" class="wd-200 ml-1 mr-1 form-control" style="display: inline !important;" placeholder="할인가를 입력하세요." v-model="discountPrice">
                <button class="btn btn-light ml-1 mr-1" @click="chgDiscountPrice">선택적용</button>
                <input type="text" class="wd-200 ml-1 mr-1 form-control" style="display: inline !important;" placeholder="할인율을 입력하세요." v-model="discountRate">
                <button class="btn btn-light ml-1 mr-1" @click="chgDiscountRate">선택적용</button>
                <select type="select" class="saleStatus custom-select wd-200 ml-1 mr-1" v-model="status">
                  <option value="">판매상태선택</option>
                  <option v-for="(item, idx) in srchSaleStatusMap" :key="idx" :value="item.value">{{item.text}}</option>
                </select>
                <button class="btn btn-light ml-1 mr-1" @click="chgStatus">선택적용</button>
                <button class="btn btn-success wd-150 ml-1 mr-1" @click="exportExcel">엑셀다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" @click="checkedAll" v-model="allSelected"></th>
                <th class="text-center">번호</th>
                <th class="text-center">대표이미지</th>
                <th class="text-center">카테고리</th>
                <th class="text-center">상품번호</th>
                <th class="text-center">상품명</th>
                <th class="text-center">정가</th>
                <th class="text-center">판매가</th>
                <th class="text-center">할인율</th>
                <th class="text-center">등록일</th>
                <th class="text-center">수정일</th>
                <th class="text-center">판매상태</th>
                <th class="text-center">조회수</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!datas.length">
                <td colspan="13" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in datas" :key="idx" v-else >
                <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" :value="data.prdIdx" :id="data.prdIdx" v-model="selectedList" @click="select"></td>
                <td class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</td>
                <td class="text-center"><img class="image" :src="getImagePath(data.prdMainImgThumb1)" style="width: 100px; height: 100px" v-image-error v-viewer /></td>
                <td class="text-center">{{data.cat1Name}} > {{data.cat2Name}}</td>
                <td class="text-center">{{ data.prdNo }}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="onDetail(data.prdIdx)">{{ data.prdName }}</a></td>
                <td class="text-center">{{ data.prdPriceOrg | comma}}원</td>
                <td class="text-center">{{ data.prdSalePrice | comma}}원</td>
                <td class="text-center">{{ Math.round(data.prdDiscountRate)}}%</td>
                <td class="text-center">{{ dateFormat(data.prdRgdt, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center">{{ dateFormat(data.prdLastUpdDt, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center">{{ data.prdSaleStatus | isSaleCode}}</td>
                <td class="text-center">{{ data.prdViewCnt }}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :per-page="search.srchPageCnt" :pagenum="search.srchPage" :records="totalCnt" for="datas" v-on:vue-pagination::datas="onPage" v-show="datas.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
<!--                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>-->
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar"
import Pagination from "@/page/components/Pagination";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import DatePickerButton from '@/components/common/DatePickerButton'
import {CommonMixin} from "@/components/mixins/CommonMixin";
import moment from "moment";

export default {
  name: "ProductList",
  data () {
    return {
      datas: [],
      search: {
        srchCat1Idx: '',
        srchCat2Idx: '',
        srchDateEnd: '',
        srchDateStart: '',
        srchDateType: 1,
        srchIsSale: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: ''
      },
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      srchSaleStatusMap: [
        { value: 1, text: '판매중'},
        { value: -1, text: '판매중지'}
      ],
      totalCnt: 0,
      allSelected: false,
      selectedList: [],
      cat1List: [],
      cat2List: [],
      collectionType: [],
      status: '',
      discountPrice: '',
      discountRate: ''
    }
  },
  components: {
    NavigationBar,
    DatePickerButton,
    Pagination,
  },
  mixins: [InterfaceMixin, CommonMixin],
  mounted() {
    this.init()
    this.getCategory()

  },
  methods: {
    init () {
      let qs = this.$route.query.search

      if(qs) {
        let search=  this.objectDeserialize(qs)

        console.log(search)
        if(search.srchCat1Idx != undefined) this.search.srchCat1Idx = search.srchCat1Idx
        if(search.srchCat2Idx != undefined) this.search.srchCat2Idx = search.srchCat2Idx
        if(search.srchDateStart != undefined) this.search.srchDateStart = search.srchDateStart
        if(search.srchDateEnd != undefined) this.search.srchDateEnd = search.srchDateEnd
        if(search.srchDateType != undefined) this.search.srchDateType = search.srchDateType
        if(search.srchIsSale != undefined) this.search.srchIsSale = search.srchIsSale
        if(search.srchType != undefined) this.search.srchType = search.srchType
        if(search.srchKeyword != undefined) this.search.srchKeyword = search.srchKeyword
        this.search.srchPage = Number(search.srchPage)
        this.search.srchPageCnt = Number(search.srchPageCnt)
      } else this.onReset()

      this.getItemList()
      // this.getCategory()
    },
    onReset () {
      this.search.srchCat1Idx = ''
      this.search.srchCat2Idx = ''
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      this.search.srchDateType = 1
      this.search.srchIsSale = ''
      this.search.srchKeyword = ''
      this.search.srchType = ''
    },
    onSearch () {
      this.search.srchPage = 1
      this.onList()
    },
    onList() {
      // this.$router.push({path: '/product/list', query: { search: this.objectSerialize(this.search)}})
      this.getItemList()
    },
    getItemList () {
      let params = {
        srchCat1Idx: this.search.srchCat1Idx,
        srchCat2Idx: this.search.srchCat2Idx,
        srchDateEnd: this.search.srchDateEnd,
        srchDateStart: this.search.srchDateStart,
        srchDateType: this.search.srchDateType,
        srchIsSale: this.search.srchIsSale,
        srchKeyword: this.search.srchKeyword,
        srchType: this.search.srchType,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }

      return this.$api.api.getProductList(params).then(resp => {
        if (resp.data.result) {
          this.datas = resp.data.data
          this.totalCnt = resp.data.total
        } else {
          this.alert("목록 조회 실패")
          //this.alert('조회 실패')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    setDateStart (value) {
      this.search.srchDateStart = value
    },
    setDateEnd (value) {
      this.search.srchDateEnd = value
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getCategory() {
      this.$api.api.getCategory().then(resp => {
        if (resp.data.result) {
          this.cat1List = resp.data.data

          let arr = [];
          this.cat1List.map(x => {
            x.cat2List.map(y => {
              y.cat1Idx =  x.cat1Idx
              arr.push(y)
            })
          })

          this.cat2List = arr
        } else {
          this.alert("카테고리 조회 실패")
          //this.alert('카테고리 조회 실패')
        }
      })
    },
    getCategory2: function () {
      this.search.srchCat2Idx = ''
    },
    checkedAll: function () {
      this.selectedList = []
      if (!this.allSelected) {
        for (var i = 0; i < this.datas.length; i++) {
          this.selectedList.push(this.datas[i].prdIdx)
        }
      }
    },
    select: function () {
      this.allSelected = false
    },
    onDetail (prdIdx) {
      this.$router.push({path: '/product/modify', query: { prdIdx: prdIdx, search: this.objectSerialize(this.search)}})
    },
    onDelete: function () {
      if (this.selectedList.length <= 0) {
        this.alert("삭제할 대상을 선택해주세요.")
        // this.alert('삭제할 대상을 선택해주세요.')
        return
      }

      this.confirm('삭제 하시겠습니까?', () => {
        this.$api.api.deleteProduct({ idxList: this.selectedList }).then(resp => {
          this.alert('삭제되었습니다.', () => {
            this.$router.go(0)
          })
        })
      })
    },
    onCreate: function () {
      this.$router.push({path: '/product/modify'})
    },
    chgDiscountPrice() {
      if (this.selectedList.length <= 0) {
        this.alert('변경할 대상을 선택해주세요.')
        return
      }
      if (this.discountPrice === '' || this.discountPrice === 0) {
        this.alert('변경할 할인가를 입력해주세요.')
        return
      }

      let reqBody = {
        idxList: this.selectedList,
        prdSalePrice: this.discountPrice
      }

      this.$api.api.updateSalePrice(reqBody).then(resp => {
        if (resp.data.result) {
          this.alert('판매금액이 ' + this.discountPrice + '원 으로 변경되었습니다', () => {
            this.getItemList()
            this.selectedList = []
          })
        } else {
          this.alert(resp.data.errMsg)
        }
      })
    },
    chgDiscountRate() {
      if (this.selectedList.length <= 0) {
        this.alert('변경할 대상을 선택해주세요.')
        return
      }
      if (this.discountRate === '' || this.discountRate === 0) {
        this.alert('변경할 할인율을 입력해주세요.')
        return
      }

      let reqBody = {
        idxList: this.selectedList,
        prdSaleRate: this.discountRate
      }

      this.$api.api.updateSaleRate(reqBody).then(resp => {
        if (resp.data.result) {
          this.alert('할인율 ' + this.discountRate + '%가 적용되었습니다', () => {
            this.getItemList()
            this.selectedList = []
          })
        } else {
          this.alert(resp.data.errMsg)
        }
      })
    },
    chgStatus() {
      if (this.selectedList.length <= 0) {
        this.alert('변경할 대상을 선택해주세요.')
        return
      }
      if (!this.status) {
        this.alert('변경할 판매상태를 선택해주세요.')
        return
      }
      let status = document.querySelector('.saleStatus');
      let text = status.options[status.selectedIndex].text

      let reqBody = {
        idxList: this.selectedList,
        prdSaleStatus: this.status
      }

      this.$api.api.updateSaleStatus(reqBody).then(resp => {
        if (resp.data.result) {
          this.alert(text + '으로 변경되었습니다', () => {
            this.getItemList()
            this.selectedList = []
          })
        } else {
          this.alert(resp.data.errMsg)
        }
      })
    },
    exportExcel () {
      let params = {
        srchCat1Idx: this.search.srchCat1Idx,
        srchCat2Idx: this.search.srchCat2Idx,
        srchDateEnd: this.search.srchDateEnd,
        srchDateStart: this.search.srchDateStart,
        srchDateType: this.search.srchDateType,
        srchIsSale: this.search.srchIsSale,
        srchKeyword: this.search.srchKeyword,
        srchType: this.search.srchType,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      return this.$api.api.exportProductList(params).then(resp => {
        if (resp.status === 200) {
          this.$toast.success('엑셀 다운로드 성공')
        }
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
