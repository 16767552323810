<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div>
          <vue-daum-postcode style="height: 445px; overflow-y:scroll;" @complete="complete" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDaumPostcode } from 'vue-daum-postcode'

export default {
  name: 'daum-post',
  data () {
    return {
      isOpen: false
    }
  },
  components: {
    VueDaumPostcode
  },
  props: {
    text: {
      type: String,
      default: () => {
        return '우편번호검색'
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    complete (result) {
      this.$emit('close', result)
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    }
  }
}

</script>
