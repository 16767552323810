<template>
  <div>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">매장 할인 {{ type === 'store' ? '등록' : '등록/수정' }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="position-relative row form-group">
            <label class="col-sm-2 col-form-label"><span class="required">*</span>구매일</label>
            <div class="col-sm-10">
              <date-picker v-model="regData.purchaseDate" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" placeholder="YYYY-MM-DD" />
            </div>
          </div>
          <div class="position-relative row form-group">
            <label class="col-sm-2 col-form-label"><span class="required">*</span>상품명</label>
            <div class="col-sm-4">
              <input type="text" v-model="regData.prdName" class="form-control d-inline-block wd-250" placeholder="상품명을 입력해주세요." maxlength="30" />
              <label class="ml-2">30자이내 ({{ regData.prdName.length }}/30)</label>
            </div>
            <label class="col-sm-2 col-form-label"><span class="required">*</span>사이즈</label>
            <div class="col-sm-4">
              <input type="text" v-model="regData.size" class="form-control d-inline-block wd-250" placeholder="사이즈를 입력해주세요." />
            </div>
          </div>
          <div class="position-relative row form-group">
            <label class="col-sm-2 col-form-label"><span class="required">*</span>색상</label>
            <div class="col-sm-4">
              <input type="text" v-model="regData.color" class="form-control d-inline-block wd-250" placeholder="색상을 입력해주세요." maxlength="10" />
              <label class="ml-2">10자이내 ({{ regData.color.length }}/10)</label>
            </div>
            <label class="col-sm-2 col-form-label"><span class="required">*</span>수량</label>
            <div class="col-sm-4">
              <input type="number" v-model.number="regData.cnt" class="form-control d-inline-block wd-250" placeholder="수량을 입력해주세요." @input="setDiscount" />
            </div>
          </div>
          <div class="position-relative row form-group">
            <label class="col-sm-2 col-form-label"><span class="required">*</span>상품금액(단가)</label>
            <div class="col-sm-4">
              <input type="number" v-model.number="regData.productPrice" class="form-control d-inline-block wd-250" placeholder="상품금액을 입력해주세요." @input="setDiscount" v-number-input />
              <label class="ml-2">10,000원 이상</label>
            </div>
            <label class="col-sm-2 col-form-label">할인후 금액</label>
            <div class="col-sm-4">
              <input type="number" v-model.number="regData.discountPrice" class="form-control d-inline-block wd-250" disabled />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="text-center">
              <button class="btn btn-warning wd-100 ml-1" @click="init">초기화</button>
              <button class="btn btn-success wd-100 ml-1" @click="onUpdate" v-if="isUpdate">수정</button>
              <button class="btn btn-success wd-100 ml-1" @click="onSave" v-if="!isUpdate">등록</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-body mb-0 pb-0">
          <h5 class="card-title float-left">전체 : {{totalCnt}}건</h5>
          <h5 class="card-title float-left pl-5">구매한도 : {{purchaseLimit | comma}}원</h5>
          <h5 class="card-title float-left pl-5">잔여한도 : {{remainLimit | comma}}원</h5>
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th>구매일</th>
              <th>상품명</th>
              <th>사이즈</th>
              <th>색상</th>
              <th>수량</th>
              <th>상품금액</th>
              <th>할인후 금액</th>
              <th v-if="type === 'inhouse'">삭제</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!datas.length">
              <td colspan="7" class="text-center" v-if="type === 'store'">내역이 존재하지 않습니다.</td>
              <td colspan="8" class="text-center" v-if="type === 'inhouse'">내역이 존재하지 않습니다.</td>
            </tr>
            <tr v-for="(data, idx) in datas" :key="idx" v-else>
              <td class="text-center">{{ data.buyDay }}</td>
              <td class="text-center" v-if="type === 'store'">{{ data.buyPrdName }}</td>
              <td class="text-center" v-else><a href="javascript:void(0)" @click="setData(data)">{{ data.buyPrdName }}</a></td>
              <td class="text-center">{{ data.buyPrdSize }}</td>
              <td class="text-center">{{ data.buyPrdColor }}</td>
              <td class="text-center">{{ data.buyQuantity }}</td>
              <td class="text-center">{{ data.buyPrice | comma }}</td>
              <td class="text-center">{{ data.buyPriceDis | comma }}</td>
              <td class="text-center" v-if="type === 'inhouse'"><button class="btn btn-danger wd-100" @click="onDelete(data.idx)">삭제</button></td>
            </tr>
            </tbody>
          </table>
          <div class="text-center mt-2 mb-3">
            <pagination :per-page="search.srchPageCnt" :pagenum="search.srchPage" :records="totalCnt" for="datas" v-on:vue-pagination::datas="onPage" v-show="datas.length"></pagination>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import DatePicker from 'vue2-datepicker'
import Pagination from "@/page/components/Pagination";
import CodeMixin from '@/mixins/code-mixin';
import {CommonMixin} from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";
export default {
  name: "regi-store-discount-pop",
  components: {
    Pagination,
    DatePicker
  },
  mixins: [
    InterfaceMixin, CodeMixin, CommonMixin
  ],
  data () {
    return {
      datas: [],
      totalCnt: 0,
      purchaseLimit: 0,
      remainLimit: 0,
      isUpdate: false,
      search: {
        srchPage: 1,
        srchPageCnt: 10
      },
      regData: {
        idx: '',
        purchaseDate: '',
        prdName: '',
        size: '',
        color: '',
        cnt: '',
        productPrice: '',
        discountPrice: ''
      }
    }
  },
  props: {
    memberIdx: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      default: 'store',
      required: true
    }
  },
  mounted() {
    this.getItemList()
  },
  methods: {
    init () {
      this.isUpdate = false;
      this.regData.idx = '';
      this.regData.purchaseDate = '';
      this.regData.prdName = '';
      this.regData.size = '';
      this.regData.color = '';
      this.regData.cnt = '';
      this.regData.productPrice = '';
      this.regData.discountPrice = '';
      this.search.srchPage = 1;
      this.search.srchPageCnt = 10;
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    getItemList () {
      let params = {
        memberIdx: this.memberIdx,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }

      this.$api.api.getOfflinePurchaseList(params).then(resp => {
        this.datas = resp.data.data.orderList
        this.totalCnt = resp.data.total
        this.purchaseLimit = resp.data.data.purchaseLimit;
        this.remainLimit = resp.data.data.remainLimit;
      }).catch(() => {
      })
    },
    onSave () {
      if (!this._isInvalidateInputForm()) return;
      this.confirm('등록 하시겠습니까?', () => {
        let params = {
          buyDay: this.regData.purchaseDate,
          buyPrdName: this.regData.prdName,
          buyPrdSize: this.regData.size,
          buyPrdColor: this.regData.color,
          buyQuantity: this.regData.cnt,
          buyPrice: this.regData.productPrice,
          buyPriceDis: this.regData.discountPrice,
          memberIdx: this.memberIdx
        }

        this.$api.api.getOfflineRegiOrderList(params).then(resp => {
          if (resp.data.result) {
            this.alert('등록되었습니다.');
            this.init();
            this.getItemList();
          } else {
            this.alert('등록에 실패하였습니다.');
            this.init();
            this.getItemList();
          }
        }).catch(() => {
        })
      });
    },
    onUpdate () {
      if (!this._isInvalidateInputForm()) return;
      this.confirm('수정 하시겠습니까?', () => {
        let params = {
          idx: this.regData.idx,
          buyDay: this.regData.purchaseDate,
          buyPrdName: this.regData.prdName,
          buyPrdSize: this.regData.size,
          buyPrdColor: this.regData.color,
          buyQuantity: this.regData.cnt,
          buyPrice: this.regData.productPrice,
          buyPriceDis: this.regData.discountPrice,
          memberIdx: this.memberIdx
        }

        this.$api.api.getOfflineModOrderList(params).then(resp => {
          if (resp.data.result) {
            this.alert('수정되었습니다.');
            this.init();
            this.getItemList();
          } else {
            this.alert('수정에 실패하였습니다.');
            this.init();
            this.getItemList();
          }
        }).catch(() => {
        })
      });
    },
    onDelete (idx) {
      console.log('idx ::: ', idx)
      this.confirm('삭제 하시겠습니까?', () => {
        let params = {
          idx: idx
        }

        this.$api.api.getOfflineDelOrderList(params).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.');
            this.init();
            this.getItemList();
          } else {
            this.alert('삭제에 실패하였습니다.');
            this.init();
            this.getItemList();
          }
        }).catch(() => {
        })
      });
    },
    setDiscount () {
      this.regData.discountPrice = parseInt(this.regData.productPrice * this.regData.cnt * 0.6);
    },
    _isInvalidateInputForm () {
      if (this.regData.purchaseDate === '') {
        this.alert('구매일자를 선택하세요.')
        return false
      }
      if (this.regData.prdName === '') {
        this.alert('상품명을 입력해주세요.')
        return false
      }
      if (this.regData.purchaseDate.length > 30) {
        this.alert('상품명을 30자이내로 입력해주세요.')
        return false
      }
      if (this.regData.size === '') {
        this.alert('사이즈를 입력해주세요.')
        return false
      }
      if (this.regData.color === '') {
        this.alert('색상을 입력해주세요.')
        return false
      }
      if (this.regData.color.length > 10) {
        this.alert('색상을 10자이내로 입력해주세요.')
        return false
      }
      if (this.regData.cnt === '' || this.regData.cnt === 0) {
        this.alert('수량을 입력해주세요.')
        return false
      }
      if (this.regData.cnt < 0) {
        this.alert('수량을 1이상 입력해주세요.')
        return false
      }
      if (!Number.isInteger(this.regData.cnt)) {
        this.alert('수량은 정수를 입력해주세요.')
        return false
      }
      if (this.regData.productPrice === '' || this.regData.productPrice === 0) {
        this.alert('상품금액을 입력해주세요.')
        return false
      }
      if (this.regData.productPrice < 10000) {
        this.alert('상품금액은 최소 10,000원이상 입력해주세요.')
        return false
      }
      if (!Number.isInteger(this.regData.productPrice)) {
        this.alert('상품금액은 정수를 입력해주세요.')
        return false
      }
      return true;
    },
    setData (data) {
      this.isUpdate = true;
      this.regData.idx = data.idx;
      this.regData.purchaseDate = data.buyDay;
      this.regData.prdName = data.buyPrdName;
      this.regData.size = data.buyPrdSize;
      this.regData.color = data.buyPrdColor;
      this.regData.cnt = data.buyQuantity;
      this.regData.productPrice = data.buyPrice;
      this.regData.discountPrice = data.buyPriceDis;
    }
  }
}
</script>

<style scoped>
  .text-center.center {
    vertical-align: middle;
    width: 10%;
  }
</style>
