import Vue from 'vue'

// 금칙어 사용여부
Vue.filter('prhbIsUse', (value) => {
  switch (value) {
    case 0: return '사용'
    case 1: return '미사용'
    default: return value
  }
})

export default {
  name: 'prohibit-filter',
  data () {
    return {
    }
  }
}
