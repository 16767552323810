<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  name: 'AppAmin'
}

</script>

<style scoped>
</style>
