import DefaultLayout from '@/layout/DefaultLayout'

export default {
  path: '/',
  component: DefaultLayout,
  children: [
    {
      path: '/store/member/list',
      name: 'MemberList',
      component: () => import('@/page/store/Member/MemberList')
    }
  ]
}
