var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-editor", {
        attrs: {
          useCustomImageHandler: "",
          "editor-toolbar": _vm.customToolbars,
          editorOptions: _vm.editorSettings
        },
        on: { "image-added": _vm.handleImageAdded },
        model: {
          value: _vm.innerContent,
          callback: function($$v) {
            _vm.innerContent = $$v
          },
          expression: "innerContent"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }