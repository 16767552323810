<template>
  <div>
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <i class="pe-7s-car icon-gradient bg-mean-fruit"></i>
          </div>
          <div>Analytics Dashboard
            <div class="page-title-subheading">This is an example dashboard created using build-in elements and components.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xl-3">
        <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">등록매장현황</div>
              <div class="widget-subheading">Last year expenses</div>
            </div>
            <div class="widget-content-right">
              <div class="widget-numbers text-warning"><span>1896</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">주문현황</div>
              <div class="widget-subheading">Total Clients Profit</div>
            </div>
            <div class="widget-content-right">
              <div class="widget-numbers text-warning"><span>$ 568</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">상품현황</div>
              <div class="widget-subheading">People Interested</div>
            </div>
            <div class="widget-content-right">
              <div class="widget-numbers text-warning"><span>46%</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">주문현황</div>
              <div class="widget-subheading">Revenue streams</div>
            </div>
            <div class="widget-content-right">
              <div class="widget-numbers text-warning"><span>$14M</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">공통</h5>
            <button class="btn btn-primary wd-150" @click="callAddrSearchPop">주소검색</button>
            <button class="btn btn-primary wd-150" @click="callRgbPop">색상선택</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">샘플</h5>
            <template v-for="(item, index) in imageList">
              <img class="images" :src="item.src" v-viewer :key="index" width="100">
            </template>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">주문</h5>
            <button class="btn btn-primary wd-150" @click="callOrderDetailPop(10)">주문정보팝업_ordIdx</button>
            <button class="btn btn-primary wd-150" @click="callPaymentPop(10)">결제정보팝업_ordIdx</button>
            <button class="btn btn-primary wd-150" @click="callMemoPop(10, '123412341234')">메모팝업_ordIdx</button>
            <button class="btn btn-primary wd-150" @click="callRecipientPop">정보수정_receptien</button>
            <button class="btn btn-primary wd-150" @click="callProdSearchPop">상품검색</button>
            <br/><br/>
            <button class="btn btn-primary wd-150" @click="callClaimPop('cancel', 10)">취소신청</button>
            <button class="btn btn-primary wd-150" @click="callClaimPop('exchange', 10)">교환신청</button>
            <button class="btn btn-primary wd-150" @click="callClaimPop('return', 10)">반품신청</button>
            <!--
            <button class="btn btn-primary wd-150" @click="callClaimPop('refund', 10)">환불신청</button>
            -->
            <br/><br/>
            <button class="btn btn-primary wd-150" @click="callClaimDetailPop('cancel', '10', 'C10000')">취소정보</button>
            <button class="btn btn-primary wd-150" @click="callClaimDetailPop('exchange', '10', 'E10000')">교환신청</button>
            <button class="btn btn-primary wd-150" @click="callClaimDetailPop('return', '10', 'R10000')">반품신청</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">에디터</h5>
            {{ content }}
            <html-editor :content="content" @change-content="updateContent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DaumPostalPop from '@/components/popup/DaumPostalPop'
import RgbSearchPop from '@/components/popup/RgbSearchPop'
import OrderDetailPop from '@/page/order/components/OrderDetailPop'
import PaymentPop from '@/page/order/components/PaymentPop'
import MemoPop from '@/page/order/components/MemoPop'
import RecipientPop from '@/page/order/components/RecipientPop'
import ClaimReqPop from '@/page/order/components/ClaimReqPop'
import ClaimDetailPop from '@/page/order/components/ClaimDetailPop'
import HtmlEditor from '@/components/common/HtmlEditor'
import SrchProductPop from '@/page/product/components/SrchProductPop'

export default {
  name: 'Dashboard',
  data () {
    return {
      menuList: [
        {name: 'Home', active: true},
        {name: 'Dashboard', active: false}
      ],
      imageList: [
        { w: 1024, h: 700, src: '/static/img/1.png' },
        { w: 1024, h: 700, src: '/static/img/2.png' },
        { w: 1024, h: 700, src: '/static/img/3.png' },
        { w: 1024, h: 700, src: '/static/img/4.png' },
        { w: 1024, h: 700, src: '/static/img/5.png' }
      ],
      recipient: {
        name: '홍길동',
        phone: '010-4336-1813',
        zonecode: '1234',
        address1: '서울시 강남구 테헤란로 98길',
        address2: '14층',
        comment: '오늘 꼭 배송해주세요.'
      },
      content: ''
    }
  },
  components: {
    DaumPostalPop,
    RgbSearchPop,
    OrderDetailPop,
    PaymentPop,
    MemoPop,
    RecipientPop,
    ClaimReqPop,
    ClaimDetailPop,
    HtmlEditor,
    SrchProductPop
  },
  mounted () {
    this.content = '<p>내용내용</p>'
  },
  methods: {
    // 주소검색 팝업 호출
    callAddrSearchPop () {
      this.$modal.show(
        DaumPostalPop,
        { text: '우편번호검색' },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            let addrObj = event.params
            console.log(addrObj)
          }
        }
      )
    },
    // RGB 색상표 팝업 호출
    callRgbPop () {
      this.$modal.show(
        RgbSearchPop,
        { text: '색상표', dColor: '#00FF00' },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            let colorObj = event.params
            console.log(colorObj)
          }
        }
      )
    },
    // 주문정보조회 팝업
    callOrderDetailPop (ordIdx) {
      this.$modal.show(
          OrderDetailPop,
          { text: '주문정보', ordIdx: ordIdx },
          { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
            }
          }
      )
    },
    // 결제정보 팝업
    callPaymentPop (ordIdx) {
      this.$modal.show(
        PaymentPop,
        { text: '결제정보', ordIdx: ordIdx.toString() },
        { draggable: false, resizable: false, height: 'auto', adaptive: true, maxWidth: 400 },
        {
          'before-close': event => {
            console.log(event)
          }
        }
      )
    },
    // 메모정보 팝업
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            console.log(event)
          }
        }
      )
    },
    // 정보수정 팝업
    callRecipientPop () {
      this.$modal.show(
        RecipientPop,
        { text: '정보수정', recipientInfo: this.recipient, username: this.recipient.name },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            console.log(event.params)
          }
        }
      )
    },
    // 클레임신청 팝업
    callClaimPop (claimType, ordIdx) {
      this.$modal.show(
          ClaimReqPop,
          { text: '신청', claimType: claimType, ordIdx: ordIdx.toString() },
          { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event)
            }
          }
      )
    },
    // 클레임정보 팝업
    callClaimDetailPop (claimType, ordIdx, clmNo) {
      this.$modal.show(
          ClaimDetailPop,
          { text: '정보', claimType: claimType, ordIdx: ordIdx, clmNo: clmNo },
          { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event)
            }
          }
      )
    },
    // 상품검색 팝업
    callProdSearchPop () {
      this.$modal.show(
          SrchProductPop,
          {},
          { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event)
            }
          }
      )
    },
    updateContent (value) {
      this.content = value
    }
  }
}
</script>

<style scoped>

</style>

<style lang="css">
@import '~vue2-editor/dist/vue2-editor.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
