<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'색상관리'" :nav-list="['맥케이슨관리', '기본정보관리', '색상관리']" />
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">색상코드 등록</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="card-title">색상코드(HEX)</div>
                <div class="form-group">
                  <input type="hidden" v-model="form.colIdx" v-if="isUpdate">
                  <input type="text" class="form-control" placeholder="" v-model="form.colCode" maxlength="7">
                </div>
              </div>
              <div class="col-md-1 text-center">
                <div class="card-title">색상</div>
                <div class="form-group center">
                  <p class="color-box" :style="{'background-color': form.colCode }">&nbsp;</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card-title">색상명</div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="" v-model="form.colName">
                </div>
              </div>
              <div class="col-md-3">
                <div class="card-title">약식 색상 코드</div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="" v-model="form.colShortCode">
                </div>
              </div>
            </div>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-secondary wd-200" @click="callRgbPop">색상 코드 찾기</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate" v-show="!isUpdate">등록</button>
                <button class="btn btn-success wd-100" @click="onUpdate" v-show="isUpdate">수정</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="main-card mb-3 card">
          <div class="card-header">색상코드</div>
          <div class="table-responsive">
            <table class="align-middle mb-0 table table-hover table-detail">
              <thead>
                <tr>
  <!--                <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected"/></th>-->
                  <th class="text-center">색상코드</th>
                  <th class="text-center">색상</th>
                  <th class="text-center">색상명</th>
                  <th class="text-center">약식 색상코드</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!datas.length">
                  <td colspan="5" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-else v-for="(data, idx) in datas" :key="idx">
  <!--                <td class="text-center"><input type="checkbox" data-toggle="toggle" :value="data.colIdx" data-onstyle="success" :id="data.colIdx" v-model="selectedList" @click="select"></td>-->
                  <td class="text-center"><a href="javascript:void(0)" @click="setData(data)">{{ data.colCode }}</a></td>
                  <td class="text-center"><p class="color-box" :style="{'background-color':  data.colCode }"></p></td>
                  <td class="text-center">{{ data.colName }}</td>
                  <td class="text-center">{{ data.colShortCode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block text-center card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
<!--                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-card mb-3 card">
      <div class="card-body">
        <div class="position-relative row form-group">
          <label class="col-sm-1 col-form-label">수정일</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="regiPersonData.updt" readonly>
          </div>
          <label class="col-sm-1 col-form-label">수정자</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="regiPersonData.upAdmId" readonly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from '@/page/components/NavigationBar'
import RgbSearchPop from '@/components/popup/RgbSearchPop'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import {required, minLength, maxLength, helpers} from 'vuelidate/lib/validators'
const alphabetRegExp = helpers.regex('alpha',  /^[A-Za-z]*$/)
const hangulRegExp = helpers.regex('hangul', /^[가-힣]+$/)

function hexCheck (hex) {
  let regex = /^#(?:[0-9a-f]{3}){1,2}$/i;
  return regex.test(hex);
}

export default {
  name: 'ColorList',
  data () {
    return {
      isUpdate: false,
      datas: [],
      regiPersonData: {
        upAdmId: '',
        updt: ''
      },
      form: {
        colCode: '',
        colName: '',
        colShortCode: ''
      },
      selectedList: [],
      allSelected: false
    }
  },
  validations: {
    form: {
      colCode: { required, minLength: minLength(7), hexCheck },
      colName: { required, minLength: minLength(2), maxLength: maxLength(6), hangulRegExp },
      colShortCode: { required, minLength: minLength(2), maxLength: maxLength(6), alphabetRegExp },
    }
  },
  components: {
    NavigationBar
  },
  mixins: [InterfaceMixin],
  mounted() {
    this.getList()
  },
  methods: {
    callRgbPop: function () {
      this.$modal.show(
          RgbSearchPop,
          { text: '색상표', dColor: '#00FF00' },
          { draggable: false, resizable: false, height: 'auto' },
          {
            'before-close': event => {
              let colorObj = event.params
              this.form.colCode = colorObj.hex
            }
          }
      )
    },
    select: function () {
      this.allSelected = false
    },
    setData(data) {
      this.isUpdate = true
      this.form.colShortCode = data.colShortCode
      this.form.colCode = data.colCode
      this.form.colName = data.colName
      this.form.colIdx = data.colIdx

    },
    checkedAll: function () {
      this.selectedList = []
      if (!this.allSelected) {
        for (var i = 0; i < this.datas.length; i++) {
          this.selectedList.push(this.datas[i].colIdx)
        }
      }
    },
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (!this.$v.form.colCode.required) {
          this.alert('색상코드를 입력해주세요.')
          return false
        }
        if (!this.$v.form.colCode.minLength) {
          this.alert('색상코드를 올바르게 입력해주세요.')
          return false
        }
        if (!this.$v.form.colCode.hexCheck) {
          this.alert('올바른 헥사코드가 아닙니다.')
          return false
        }
        if (!this.$v.form.colName.required) {
          this.alert('색상명을 입력해주세요.')
          return false
        }
        if (!this.$v.form.colName.minLength) {
          this.alert('색상명을 최소 2자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.form.colName.hangulRegExp) {
          this.alert('색상명은 한글만 입력 가능합니다.')
          return false
        }
        if (!this.$v.form.colName.maxLength) {
          this.alert('색상명은 최대 6자리 이상 입력할 수 없습니다.')
          return false
        }
        if (!this.$v.form.colShortCode.required) {
          this.alert('약식 색상코드를 입력해주세요.')
          return false
        }
        if (!this.$v.form.colShortCode.minLength) {
          this.alert('약식 색상코드를 최소 2자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.form.colShortCode.maxLength) {
          this.alert('약식 색상코드는 최대 6자리 이상 입력할 수 없습니다.')
          return false
        }
        if (!this.$v.form.colShortCode.alphabetRegExp) {
          this.alert('약식 색상코드는 영문만 입력 가능합니다.')
          return false
        }
      }
      return true
    },
    onCreate: function () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 색상 정보 등록 실패 입력 FORM 오류')
        return
      }
      this.confirm('등록 하시겠습니까?', () => {
        this.$api.api.registerColor(this.form).then(resp => {
          if (resp.data.result) {
            this.alert(resp.data.msg)
            this.$router.go(0)
          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    },
    onUpdate: function () {
      this.confirm('수정 하시겠습니까?', () => {
        this.$api.api.updateColor(this.form).then(resp => {
          if (resp.data.result) {
            this.alert('수정되었습니다.', () => {
              this.$router.go(0)
            })
          } else {
            this.alert('수정에 실패 했습니다.')
          }
        })
      })
    },
    onDelete: function () {
      this.confirm('삭제 하시겠습니까?', () => {

        if (this.selectedList.length <= 0) {
          this.alert('삭제할 대상을 선택해주세요.')
          return
        }

        this.$api.api.deleteColor({colIdx: this.selectedList}).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.$router.go(0)
            })
          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    },
    getList: function () {
      this.$api.api.getColorList().then(resp => {
        if(resp.data.result) {
          this.datas = resp.data.data.data
          this.regiPersonData = resp.data.data.regiPersonData ? resp.data.data.regiPersonData : {
            upAdmId: '',
            updt: ''
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
