import { render, staticRenderFns } from "./ReturnList.vue?vue&type=template&id=fb08aef6&scoped=true&"
import script from "./ReturnList.vue?vue&type=script&lang=js&"
export * from "./ReturnList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb08aef6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/MCKAYSON/REAL/MCKAYSON_REAL_ADMIN/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb08aef6')) {
      api.createRecord('fb08aef6', component.options)
    } else {
      api.reload('fb08aef6', component.options)
    }
    module.hot.accept("./ReturnList.vue?vue&type=template&id=fb08aef6&scoped=true&", function () {
      api.rerender('fb08aef6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/order/Return/ReturnList.vue"
export default component.exports