<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'매장관리'" :nav-list="['맥케이슨관리', '기본정보관리', '매장관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="">
              <div class="form-row">
                <div class="col-md-2">
                  <div class="position-relative form-group">
                    <label class="">지역</label>
                    <select class="form-control" v-model="search.areaCode1">
                      <option value="">전체</option>
                      <option v-for="(item, idx) in srchLoc1List" :value="item.areaCode1" :key="idx" >{{ item.areaName1 }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="position-relative form-group" style="padding-top: 29px;">
                    <select class="form-control" v-model="search.areaCode2">
                      <option value="">전체</option>
                      <option v-for="(item, idx) in srchLoc2List" :value="item.areaCode2" :key="idx" v-if="search.areaCode1 == item.areaCode1"> {{ item.areaName2 }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="position-relative form-group">
                    <label class="">노출여부</label>
                    <select class="form-control" v-model="search.isDisplay">
                      <option value="">전체</option>
                      <option v-for="item in srchUseYnList" :value="item.value" :key="item.value">{{ item.text }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-2">
                  <div class="position-relative">
                    <select class="form-control" v-model="search.srchType">
                      <option value="">검색조건</option>
                      <option v-for="item in srchKeywordConditionList" :value="item.value" :key="item.value">{{ item.text }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="position-relative">
                    <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-body">
            <h5 class="card-title float-left mb-0 mt-3">건수 : {{totalCnt}}건</h5>
            <h5 class="card-title float-right">
              <select type="select" class="custom-select" v-model="search.srchPageCnt" @change="onChangeSize">
                <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
              </select>
            </h5>
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" @click="checkedAll" v-model="allSelected"></th>
                <th class="text-center" v-if="isEditSortable">순서</th>
                <th class="text-center">번호</th>
                <th class="text-center">고유번호</th>
                <th class="text-center">매장명</th>
                <th class="text-center">지역1</th>
                <th class="text-center">지역2</th>
                <th class="text-center">주소</th>
                <th class="text-center">연락처</th>
                <th class="text-center">타입</th>
                <th class="text-center">등록일</th>
                <th class="text-center">수정일</th>
                <th class="text-center">노출여부</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!datas.length">
                <td colspan="12" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in datas" :key="idx" v-else>
                <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" :value="data.idx" :id="data.idx" v-model="selectedList" @click="select"></td>
                <th class="text-center" v-if="isEditSortable"><select class="form-control form-control-sm wd-75" v-model="data.seq"><option v-for="sort in totalCnt" :value="sort">{{ sort }}</option></select></th>
                <th class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</th>
                <td class="text-center">{{ data.idx }}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="goDetail(data.idx)">{{ data.shopName }}</a></td>
                <td class="text-center">{{ data.areaName1 }}</td>
                <td class="text-center">{{ data.areaName2 }}</td>
                <td class="text-center">{{ data.shopAddress }} {{ data.shopAddressDetail }}</td>
                <td class="text-center">{{ data.shopPhone | dash }}</td>
                <td class="text-center">{{ data.shopType }}</td>
                <td class="text-center">{{ dateFormat(data.shopRegDate, 'YYYY-MM-DD') }}</td>
                <td class="text-center">{{ dateFormat(data.shopUpdDate, 'YYYY-MM-DD') }}</td>
                <td class="text-center">
                  <div class="custom-radio custom-control custom-control-inline"><input type="radio" :value="1" v-model="data.shopIsDisplay" class="custom-control-input" :id="`radio_yes_${idx}`" @change="chgStatus(data.idx, 1)">
                    <label class="custom-control-label" :for="`radio_yes_${idx}`">노출</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline"><input type="radio" :value="0" v-model="data.shopIsDisplay" class="custom-control-input" :id="`radio_no_${idx}`" @change="chgStatus(data.idx, 0)">
                    <label class="custom-control-label" :for="`radio_no_${idx}`">비노출</label>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :per-page="search.srchPageCnt" :pagenum="search.srchPage" for="itemList" :records="totalCnt" v-on:vue-pagination::itemList="onPage" v-show="datas.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-warning wd-100 mr-2" @click="onUpdateSort" v-if="isEditSortable">순서적용</button>
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import InterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'StoreList',
  data () {
    return {
      datas: [],
      selectedList: [],
      allSelected: false,
      search: {
        areaCode1: '',
        areaCode2: '',
        isDisplay: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: ''
      },
      srchLoc1List: [],
      srchLoc2List: [],
      srchUseYnList: [
        { value: 1, text: '노출' },
        { value: 0, text: '비노출' }
      ],
      srchKeywordConditionList: [
        { value: 1, text: '고유번호' },
        { value: 2, text: '매장명' },
        { value: 3, text: '주소' },
        { value: 4, text: '전화번호' },
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' },
        { value: 100, text: '100개' }
      ],
      totalCnt: 0
    }
  },
  components: {
    NavigationBar,
    Pagination
  },
  mixins: [
    InterfaceMixin, CommonMixin
  ],
  computed: {
    isEditSortable () {
      return this.totalCnt === this.datas.length
    }
  },
  created () {
  },
  mounted () {
    this.init()
    this.getAreaCode()
  },
  watch: {
    'search.areaCode1': function () {
      this.search.areaCode2 = ''
    }
  },
  methods: {
    init () {
      let qs = this.$route.query.search

      if(qs) {
        let search=  this.objectDeserialize(qs)
        if(search.areaCode1 != undefined) this.search.areaCode1 = search.areaCode1
        if(search.areaCode2 != undefined) this.search.areaCode2 = search.areaCode2
        if(search.isDisplay != undefined) this.search.isDisplay = search.isDisplay
        if(search.srchType != undefined) this.search.srchType = search.srchType
        if(search.srchKeyword != undefined) this.search.srchKeyword = search.srchKeyword

        this.search.srchPage = Number(search.srchPage)
        this.search.srchPageCnt = Number(search.srchPageCnt)
      }else this.onReset()

      this.getItemList()
    },
    onReset () {
      this.search.areaCode1 = ''
      this.search.areaCode2 = ''
      this.search.isDisplay = ''
      this.search.srchType = ''
      this.search.srchKeyword = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onList()
    },
    onList() {
      // this.$router.push({path: '/store/list', query: { search: this.objectSerialize(this.search)}})
      this.getItemList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.onList()
    },
    getItemList () {
      this.$api.api.getStoreList(this.search).then(resp => {
        this.datas = resp.data.data
        this.totalCnt = resp.data.total
      }).catch(() => {
        this.isLoad = false
      })
    },
    getAreaCode() {
      this.$api.api.getAreaCode().then(resp=> {
        this.srchLoc1List = resp.data.data

        let arr = [];
        this.srchLoc1List.map(x=> {
          return x.areaList.map(y => {
            y.areaCode1 = x.areaCode1
            arr.push(y)
          })
        })

        this.srchLoc2List = arr
      })
    },
    onCreate () {
      this.$router.push({path: '/store/modify'})
    },
    onDelete () {
      if (this.selectedList.length <= 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }
      this.confirm('삭제 하시겠습니까?', () => {
        this.$api.api.deleteStore({ idxList: this.selectedList }).then(resp => {
          this.alert('삭제되었습니다.', () => {
            this.$router.go(0)
          })
        })
      })
    },
    goDetail (storeIdx) {
      this.$router.push({path: '/store/modify', query: { storeIdx: storeIdx, search: this.objectSerialize(this.search)}})
    },
    checkedAll: function () {
      this.selectedList = []
      if (!this.allSelected) {
        for (var i = 0; i < this.datas.length; i++) {
          this.selectedList.push(this.datas[i].idx)
        }
      }
    },
    select: function () {
      this.allSelected = false
    },
    chgStatus: function (idx, isDisplay) {
      let reqBody = {
        idx: idx,
        isDisplay: isDisplay
      }
      this.$api.api.switchDisplayStatus(reqBody).then(resp => {
        if (resp.data.result) {
          if(isDisplay == 0) this.alert('비노출처리 되었습니다.');
          if(isDisplay == 1) this.alert('노출처리 되었습니다.')
        } else {
          this.alert('수정 실패')
        }
      })
    },
    onUpdateSort () {
      this.confirm('정렬 순서를 적용 하시겠습니까?', () => {
        let reqBody = { seqList: [] }
        this.datas.forEach(f => {
          let tmp = { idx: f.idx, seq: f.seq }
          reqBody.seqList.push(tmp)
        })
        this.$api.api.updateShopSeq(reqBody).then(resp => {
          if (resp.data.result) {
            this.alert('적용 되었습니다.')
            this.getItemList()
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
