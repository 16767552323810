<template>
  <div class="row" v-if="isLoad">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }} 정보 ({{clmNo}})</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div style="max-height: 800px; overflow-y: scroll; width: 100%; margin-bottom: 20px">

          <!-- 결제정보(AdmClaimPayInfo) - 취소정보, 교환정보, 반품정보 -->
          <div class="card-body" v-if="datas.admClaimPayInfo">
            <h5 class="card-title"></h5>
            <table class="mb-0 table table-detail">
              <thead>
                <tr>
                  <th v-if="claimType == 'cancel'">유입경로</th>
                  <th>주문일(결제일)</th>
                  <th>주문번호</th>
                  <th>결제금액</th>
                  <th>결제수단</th>
                  <th>주문자</th>
                  <th>수령자</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center" v-if="claimType == 'cancel'">{{datas.admClaimPayInfo.accessMethod}}</td>
                  <td class="text-center">{{dateFormat(datas.admClaimPayInfo.ordRgdt, 'YYYY-MM-DD HH:mm')}}<br>({{dateFormat(datas.admClaimPayInfo.payDt, 'YYYY-MM-DD HH:mm')}})</td>
                  <td class="text-center">{{datas.admClaimPayInfo.ordNo}}</td>
                  <td class="text-center">{{datas.admClaimPayInfo.payAmount | comma}}원</td>
                  <td class="text-center">{{datas.admClaimPayInfo.payKindStr}}</td>
                  <td class="text-center">{{datas.admClaimPayInfo.userName}}</td>
                  <td class="text-center">{{datas.admClaimPayInfo.recipient}}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(ordIdx, datas.admClaimPayInfo.ordNo)">{{ datas.admClaimPayInfo.memoCnt }}</a></td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 상품정보(AlaimProductList) 취소정보, 반품정보 -->
          <div class="card-body" v-if="datas.claimProductList && (claimType === 'cancel' || claimType === 'return')">
            <div class="col-12 pr-0 pl-0 mb-2">
              <div class="float-left">
                <h5 class="card-title float-left">상품 정보</h5>
              </div>
            </div>
            <div style="height: auto; overflow-y: scroll; width: 100%;">
              <table class="mb-0 table table-detail">
                <thead>
                  <tr>
                    <th class="wd-150">상품별 주문번호</th>
                    <th class="wd-100">상품번호</th>
                    <th>상품명/옵션</th>
                    <th class="wd-100">수량</th>
                    <th class="wd-100">{{ text }}수량</th>
                    <th class="wd-100">판매가</th>
                    <th class="wd-100">상품금액</th>
                    <th class="wd-100">{{claimType | claimTypeCode}}상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!datas.claimProductList.length">
                    <td colspan="7" class="text-center">내역이 존재하지 않습니다.</td>
                  </tr>
                  <tr v-else v-for="(data, idx) in datas.claimProductList" :key="idx">
                    <td class="text-center">{{ `${data.ordNo}_${data.opoIdx}` }}</td>
                    <td class="text-center">{{data.prdNo }}</td>
                    <td class="text-center">
                      <p class="mb-0">{{data.ordProduct}}</p>
                      <template>
                        <span class="mb-0 bg-arielle-smile">{{data.opoColName}} / {{data.opoSize}}</span>
                      </template>
                    </td>
                    <td class="text-center">{{ data.opoQuantity | comma }}</td>
                    <td class="text-center">{{ data.cpoQuantity | comma }}</td>
                    <td class="text-center">{{ data.opoOptPriceSale | comma }}원</td>
                    <td class="text-center" :style="datas.admClaimPayInfo.memIdx? 'color:#B40404': ''">{{ data.opoOnlyPrdAmount | comma }}원</td>
                    <td class="text-center">{{ data.cpoStatusStr }}</td>
                  </tr>
                  <tr v-if="giftList.length > 0">
                    <td class="text-center" colspan="7" style="color:#B40404">
                      [사은품] {{ strGift }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 상품정보(ExchangeProducts) 교환정보 -->
          <div class="card-body" v-if="datas.exchangeProducts">
            <div class="col-12 pr-0 pl-0 mb-2">
              <div class="float-left">
                <h5 class="card-title float-left">상품 정보</h5>
              </div>
            </div>
            <div style="height: auto; overflow-y: scroll; width: 100%;">
              <table class="mb-0 table table-detail">
                <thead>
                <tr>
                  <th class="wd-300">상품별 주문번호 <br>/송장번호</th>
                  <th class="wd-100">상품번호</th>
                  <th>상품명/옵션</th>
                  <th class="wd-100">수량</th>
                  <th class="wd-100">판매가</th>
                  <th class="wd-100">상품금액</th>
                  <th class="wd-100">교환상태</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!datas.exchangeProducts.length">
                  <td colspan="7" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <template v-for="(data, index) in datas.exchangeProducts">
                  <tr>
                    <td class="text-center" :rowspan="data.opts.length+1">
                      <span>{{ `${data.ordNo}_${data.opoIdx}` }}</span> <br>
                      <span v-if="data.cdvDeliNo">({{data.cdvDeliCom}}) {{data.cdvDeliNo}}</span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-center" :rowspan="data.opts.length+1">{{data.prdNo }}</td>
                    <td class="text-center">{{data.ordProduct}} / <span style="color:crimson">{{data.opoColName}} / {{data.opoSize}}</span></td>
                    <td class="text-center">{{ data.cpoQuantity | comma }}</td>
                    <td class="text-center">{{ data.opoOptPriceSale | comma }}원</td>
                    <td class="text-center">{{ data.opoOnlyPrdAmount | comma }}원</td>
                    <td class="text-center">{{ data.cpoStatusStr }}</td>
                  </tr>
                  <tr v-if="claimType === 'exchange' && exChangeAvailBtn && data.cpoStatus == 11">
                    <template v-if="chgOptList[index].exChangeAvail">
                      <div>
                        <select class="custom-select wd-150 mr-1" v-model="chgOptList[index].cpoOptIdx1" @change="changeOpt1($event, data.prdIdx,privatePrdOptMapList[index], chgOptList[index])">
                          <option value=0>선택해주세요.</option>
                          <template v-if="privatePrdOptMapList[index][data.prdIdx]">
                            <option v-for="(opt1) in privatePrdOptMapList[index][data.prdIdx].opt1List" :key="opt1.optIdx" :value="opt1.optIdx">{{ opt1.colName }}</option>
                          </template>
                        </select>
                        <select class="custom-select wd-100 ml-1" v-model="chgOptList[index].cpoOptIdx2">
                          <option value=0>선택해주세요.</option>
                          <template v-if="privatePrdOptMapList[index][data.prdIdx]">
                            <option v-for="opt2 in privatePrdOptMapList[index][data.prdIdx].opt2List" :value="opt2.optIdx">{{ opt2.size }}</option>
                          </template>
                        </select>
                      </div>
                    </template>
                    <template v-else>
                      <td class="text-center"><span>교환 할 옵션 :</span> <span style="color:blue">{{data.opts[0].cpoColName}} / {{data.opts[0].cpoSize}}</span>
                      <td class="text-center">{{ data.opts[0].cpoQuantity | comma }}</td>
                      <td class="text-center">{{ data.opts[0].cpoOptPriceSale | comma }}원</td>
                      <td class="text-center">{{ data.opts[0].cpoOnlyPrdAmount | comma }}원</td>
                      <td class="text-center">{{ data.opts[0].cpoStatusStr }}</td>
                    </template>
                  </tr>
                  <tr v-for="chgOpt in data.opts" v-else>
                    <td class="text-center"><span>교환 할 옵션 :</span> <span style="color:blue">{{chgOpt.cpoColName}} / {{chgOpt.cpoSize}}</span>
                    <td class="text-center">{{ chgOpt.cpoQuantity | comma }}</td>
                    <td class="text-center">{{ chgOpt.cpoOptPriceSale | comma }}원</td>
                    <td class="text-center">{{ chgOpt.cpoOnlyPrdAmount | comma }}원</td>
                    <td class="text-center">{{ chgOpt.cpoStatusStr }}</td>
                  </tr>
                </template>

<!--                <tr v-else v-for="(data, idx) in datas.exchangeProducts" :key="idx">-->
<!--                  <td class="text-center">{{data.ordNo}} <br>-->
<!--                    <span v-if="data.cdvDeliNo">({{data.cdvDeliCom}}) {{data.cdvDeliNo}}</span>-->
<!--                    <span v-else>-</span>-->
<!--                  </td>-->
<!--                  <td class="text-center">{{data.prdNo }}</td>-->
<!--                  <td class="text-center">-->
<!--                    <p class="mb-0 d-inline">{{data.ordProduct}}</p> / <span style="color:crimson">{{data.opoColName}} / {{data.opoSize}}</span><br>-->
<!--                    <template v-for="chgOpt in data.opts">-->
<!--                      <span style="font-weight: bold">교환 할 옵션 :</span> <span style="color:blue">{{chgOpt.cpoColName}} / {{chgOpt.cpoSize}}</span> <br>-->
<!--                    </template>-->
<!--                  </td>-->
<!--                  <td class="text-center">{{ data.cpoQuantity | comma }}</td>-->
<!--                  <td class="text-center">{{ data.opoOptPriceSale | comma }}</td>-->
<!--                  <td class="text-center">{{ data.opoOnlyPrdAmount | comma }}</td>-->
<!--                  <td class="text-center">{{ data.cpoStatusStr }}</td>-->
<!--                </tr>-->
                </tbody>
              </table>
            </div>
          </div>

          <!-- 클레임 사유(AdmClaimDetailInfo) 취소정보, 교환정보, 반품정보 -->
          <div class="card-body mb-0 pb-0">
            <h5 class="card-title">{{claimType | claimTypeCode}} 사유</h5>
            <table class="mb-0 table table-detail">
              <thead>
                <tr>
                  <th>{{claimType | claimTypeCode}} 사유</th>
                  <th>책임선택</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="w-75 text-center">{{datas.admClaimDetailInfo.clmCdStr}} / {{datas.admClaimDetailInfo.clmReason}}</td>
                  <td class="w-25 text-center">{{datas.admClaimDetailInfo.clmChargeStr}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 주소 정보(ChangeAddress, ReturnAddress) 교환정보 -->
          <div class="card-body mb-0 pb-0" v-if="datas.returnAddress">
            <h5 class="card-title">주소 정보</h5>
            <table class="table table-detail">
              <tbody>
              <tr>
                <th class="wd-150" rowspan="2">회수 주소</th>
                <th class="wd-100">성명</th>
                <td class="text-left">
                  <label class="col-form-label mr-1">{{datas.returnAddress.userName}}</label>
                </td>
                <th class="wd-100">연락처</th>
                <td class="wd-300">{{datas.returnAddress.userPhone | dash}}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>
                  ({{datas.returnAddress.postalCode}}) {{datas.returnAddress.addr}}
                </td>
                <th>요청사항</th>
                <td>
                  {{datas.returnAddress.comment}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body mb-0 pb-0" v-if="datas.changeAddress">
            <table class="table table-detail">
              <tbody>
              <tr>
                <th class="wd-150" rowspan="2">교환 배송 주소</th>
                <th class="wd-100">성명</th>
                <td class="text-left">
                  <label class="col-form-label mr-1">{{datas.changeAddress.userName}}</label>
                </td>
                <th class="wd-100">연락처</th>
                <td class="wd-300">{{datas.changeAddress.userPhone | dash}}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>
                  ({{datas.changeAddress.postalCode}}) {{datas.changeAddress.addr}}
                </td>
                <th>요청사항</th>
                <td>
                  {{datas.changeAddress.comment}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- 환불 예상(ExpectedRefund) 취소정보, 반품정보 -->
          <div class="card-body mb-0 pb-0" v-if="datas.expectedRefund">
            <h5 class="card-title">환불 예상 정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th class="wd-150">환불 예정 수단</th>
                <td class="wd-250">{{datas.expectedRefund.refundMethodStr}}</td>
                <th class="wd-150">환불계좌정보</th>
                <td v-if="datas.expectedRefund.refundBankNo">{{datas.expectedRefund.refundBankMaster}} <br>{{datas.expectedRefund.refundBankName}}/{{datas.expectedRefund.refundBankNo}}</td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th class="wd-150">상품 금액 합</th>
                <td colspan="3">{{ datas.expectedRefund.productAmount | comma }}원</td>
              </tr>
              <tr>
                <th class="wd-150">기본배송비</th>
                <td colspan="3">{{ datas.expectedRefund.deliFee | comma }}원</td>
              </tr>
              <tr>
                <th class="wd-150">도서산간 배송비</th>
                <td colspan="3">{{ datas.expectedRefund.deliAddfee | comma }}원</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="wd-150">환불 예상 금액</th>
                <td colspan="3">{{ datas.expectedRefund.refundAmount | comma }}원</td>
              </tr>
              </tfoot>
            </table>
          </div>

          <!-- 환불 정보(RefundInfo) 취소정보, 반품정보 -->
          <div class="card-body mb-0 pb-0" v-if="datas.refundInfo">
            <h5 class="card-title">환불 정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
              <tr>
                <th class="wd-150">환불 예정 수단</th>
                <td class="wd-250">{{datas.refundInfo.refundMethodStr}}</td>
                <th class="wd-150">환불계좌정보</th>
                <td v-if="datas.refundInfo.refundBankNo">{{datas.refundInfo.refundBankMaster}} <br>{{datas.refundInfo.refundBankName}}/{{datas.refundInfo.refundBankNo}}</td>
                <td v-else>-</td>
              </tr>
              <tr>
                <th class="wd-150">상품 금액 합</th>
                <td colspan="3">{{ datas.refundInfo.productAmount | comma }}원</td>
              </tr>
              <tr>
                <th class="wd-150">기본배송비</th>
                <td colspan="3">{{ datas.refundInfo.deliFee | comma }}원</td>
              </tr>
              <tr>
                <th class="wd-150">도서산간 배송비</th>
                <td colspan="3">{{ datas.refundInfo.deliAddfee | comma }}원</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="wd-150">환불 예상 금액</th>
                <td>{{ datas.refundInfo.refundAmount | comma }}원</td>
                <th class="wd-150">일자</th>
                <td>{{datas.refundInfo.refundDate}}</td>
              </tr>
              </tfoot>
            </table>
          </div>


          <!-- 배송비정보 (ChangeAddress) 교환정보-->
<!--          <div class="card-body mb-0 pb-0" v-if="claimType === 'CHANGE'">-->
<!--            <h5 class="card-title">배송비</h5>-->
<!--            <table class="table table-detail">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <th>기본 배송지</th>-->
<!--                <th>도서산간 배송비</th>-->
<!--                <th>배송비 합</th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td class="text-center w-35">{{ "12341234" | comma }}</td>-->
<!--                <td class="text-center w-35">{{ "12341234" | comma }}</td>-->
<!--                <td class="text-center">{{ "12341234" | comma }}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
        </div>

        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-warning wd-125" @click="onCancle">확인</button>
              <button class="btn btn-danger wd-125" v-if="exChangeAvailBtn" @click="onChangeReq">교환상품변경</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import MemoPop from '@/page/order/components/MemoPop'
import _ from "lodash"

export default {
  name: 'ClaimDetailPopup',
  data () {
    return {
      isOpen: false,
      itemList: [],
      datas: {
        admClaimDetailInfo: '',
        admClaimPayInfo: '',
        changeAddress: '',
        claimProductList: [],
        exchangeProducts: [],
        expectedRefund: '',
        refundInfo: '',
        returnAddress: ''
      },
      giftList: [],
      strGift: '',
      prdOptMap: {},
      chgOptList : [],
      isLoad : false,
      exChangeAvailBtn : false,
      privatePrdOptMapList : []
    }
  },
  components: {
    MemoPop
  },
  mixins: [
    CommonMixin
  ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    claimType: {
      type: String,
      default: () => {
        return ''
      }
    },
    ordIdx: {
      type: Number,
      default: (value) => {
        return 0
      }
    },
    clmIdx: {
      type: Number,
      default: (value) => {
        return 0
      }
    },
    clmNo: {
      type: String,
      default: (value) => {
        return ''
      }
    }
  },
  computed: {
  },
  created () {
    this.$bus.$on('memoUpdate', value => {
      this.datas.admClaimPayInfo.memoCnt = value.amount
    })
  },
  mounted () {

    this.setData()
  },
  methods: {
    getEventData () {
      let prdCnt = 0
      let reverseGift = this.giftList.reverse()
      this.datas.claimProductList.forEach(data => {
        if (data.cat2Idx !== 14 && data.cat2Idx !== 19) {
          prdCnt += data.cpoQuantity
        }
      })

      this.strGift = ''
      for (let i = 0; i < prdCnt; i++) {
        if (i === prdCnt -1) {
          this.strGift += reverseGift[i]
        } else {
          this.strGift += reverseGift[i] + ', '
        }
      }
    },
    getGiftList () {
      let params = {
        ordNo: this.datas.admClaimPayInfo.ordNo
      }
      this.$api.api.getGiftList(params).then(resp => {
        if (resp.data.result) {
          this.giftList = resp.data.data.giftList
          this.getEventData()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    setData () {
      this.isLoad = false
      this.$api.api.getClaimDetail({clmIdx: this.clmIdx}).then(resp => {
        if (resp.data.result) {
          this.datas = resp.data.data
          this.getGiftList()
          if(this.claimType == 'exchange'){
            this.getPrdOptMap()
          }else{
            this.isLoad = true
          }
        } else {
          this.alert('클레임 상세조회 실패')
        }
      })
    },
    onCancle () {
      this.$emit('close')
    },
    open () {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close () {
      this.isOpen = false
      this.$destroy(true)
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            // this.getDetail()
          }
        }
      )
    },
    async getPrdOptMap() {
      let optCnt = 0; //옵션의 개수
      for(let i = 0; i<this.datas.exchangeProducts.length; i++) {
        try {
          let excPrd = this.datas.exchangeProducts[i]
          optCnt += excPrd.opts.length
          let prdIdx = excPrd.prdIdx
          let exChangeAvail = false

          if(excPrd.opts[0].cpoColName == null && excPrd.cpoStatus == 11) {
            exChangeAvail = true
            this.exChangeAvailBtn = true
          }
          this.chgOptList.push({cpoIdx :excPrd.cpoIdx , cpoOptIdx1 : 0 , cpoOptIdx2 : 0, exChangeAvail: exChangeAvail})
          if(this.prdOptMap[prdIdx]) continue
          this.prdOptMap[prdIdx] = {opt1List:null, opt2List:null}
          let param = {idx: prdIdx}
          let resp = await this.$api.api.getProductDetail(param);
          console.log('resp',resp)
          this.prdOptMap[resp.data.data.prdIdx].opt1List = resp.data.data.optionList
          this.prdOptMap[resp.data.data.prdIdx].opt2List = []
        }catch(e){
          console.error(e);
        }
      }
      //각 옵션별로 prdOptMap을 생성
      for(let j=0; j<optCnt; j++){
        this.privatePrdOptMapList.push(_.cloneDeep(this.prdOptMap))
      }
      this.isLoad = true
    },
    changeOpt1(event, prdIdx, prdOptMap,chgOpt) {
        chgOpt.cpoOptIdx2 = 0
        if(event.target.selectedIndex) {
            let index = event.target.selectedIndex - 1
            this.$set(
                prdOptMap[prdIdx],
                'opt2List',
                prdOptMap[prdIdx].opt1List[index].opt2List.filter(data => {
                    return data.optIsUse == 1 && data.optStock > 0
                }))
        }else this.$set(prdOptMap[prdIdx], 'opt2List', [])
    },
    onChangeReq(){
      let notValidList = this.chgOptList.filter(item => {
        return item.exChangeAvail && (item.cpoOptIdx1 == 0 || item.cpoOptIdx2 == 0)
      })

      if(notValidList.length){
        this.$alert("교환할 상품을 모두 선택해주세요")
        return
      }

      let req = this.chgOptList
              .filter(item => {
                return item.exChangeAvail && item.cpoOptIdx1 > 0 && item.cpoOptIdx2 > 0
              })
              .map(item2 => {
                return {cpoIdx : item2.cpoIdx , cpoOptIdx1: item2.cpoOptIdx1, cpoOptIdx2 : item2.cpoOptIdx2 }
              })


      this.$api.api.updateExchangePrd( { changePrdList : req}).then(res => {
        if(res.data.result){
          this.$alert("교환상품이 변경되었습니다")
          this.onCancle()
        }
      }).catch(err => {
        console.error("err",err)
      })
    }
  }
}

</script>
