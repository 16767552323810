var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-card mb-3 card" }, [
    _c("div", { staticClass: "card-header font-size-lg" }, [
      _vm._v("프로모션 공통 설정")
    ]),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm._m(0),
          _c("div", { staticClass: "float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary wd-100",
                on: { click: _vm.addData }
              },
              [_c("i", { staticClass: "fa fa-plus mr-2" }), _vm._v("추가")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "col-md-11" },
          [
            !_vm.bgList.length
              ? _c("div", { staticClass: "text-center" }, [
                  _c("p", [_vm._v("등록된 정보가 존재하지 않습니다.")])
                ])
              : _vm._l(_vm.bgList, function(data, dataIdx) {
                  return _c(
                    "div",
                    { staticClass: "d-inline-block text-center p-2" },
                    [
                      data.idx !== ""
                        ? [
                            _c("p", { staticClass: "mt-1 mb-1" }, [
                              _vm._v("배경" + _vm._s(dataIdx + 1))
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "120px",
                                  height: "120px",
                                  "justify-content": "center",
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "image-error",
                                      rawName: "v-image-error"
                                    },
                                    { name: "viewer", rawName: "v-viewer" }
                                  ],
                                  staticClass: "images",
                                  staticStyle: {
                                    width: "120px",
                                    height: "120px"
                                  },
                                  attrs: {
                                    src: _vm.getImagePath(data.image.thumb1)
                                  }
                                })
                              ]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.chkList,
                                  expression: "chkList"
                                }
                              ],
                              staticClass: "mt-2 mb-2",
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: data.idx,
                                checked: Array.isArray(_vm.chkList)
                                  ? _vm._i(_vm.chkList, data.idx) > -1
                                  : _vm.chkList
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.chkList,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = data.idx,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.chkList = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.chkList = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.chkList = $$c
                                  }
                                }
                              }
                            })
                          ]
                        : [
                            _c("p", { staticClass: "mt-1 mb-1" }, [
                              _vm._v("배경" + _vm._s(dataIdx + 1))
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "120px",
                                  height: "120px",
                                  "justify-content": "center",
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                data.image.filename === ""
                                  ? _c("upload-photo", {
                                      attrs: { branch: "visual" },
                                      on: {
                                        changed: function($event) {
                                          return _vm.changeImage(
                                            $event,
                                            data.image
                                          )
                                        }
                                      }
                                    })
                                  : _c("img", {
                                      directives: [
                                        { name: "viewer", rawName: "v-viewer" },
                                        {
                                          name: "image-error",
                                          rawName: "v-image-error"
                                        }
                                      ],
                                      staticClass: "images",
                                      staticStyle: {
                                        width: "120px",
                                        height: "120px"
                                      },
                                      attrs: {
                                        src: _vm.getImagePath(
                                          data.image.path +
                                            "/" +
                                            data.image.filename
                                        )
                                      }
                                    })
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "fa fa-times mb-2 mt-2",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteRow(dataIdx)
                                }
                              }
                            })
                          ]
                    ],
                    2
                  )
                })
          ],
          2
        )
      ])
    ]),
    _c("div", { staticClass: "card-footer" }, [
      _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
        _c("div", { staticClass: "float-left" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger ml-1 wd-100",
              on: { click: _vm.onDelete }
            },
            [_vm._v("선택삭제")]
          )
        ]),
        _c("div", { staticClass: "float-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success ml-1 wd-100",
              on: { click: _vm.onSave }
            },
            [_vm._v("저장")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "float-left" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("PC 배경이미지 선택")]),
      _c("p", { staticClass: "text-danger" }, [
        _vm._v(
          "* 이전 등록 건을 포함한 모든 프로모션 템플릿 항목에 동일하게 적용됩니다."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-1 text-center",
        staticStyle: {
          "justify-content": "center",
          "align-items": "center",
          display: "flex"
        }
      },
      [_c("h5", { staticClass: "card-title" }, [_vm._v("PC")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }