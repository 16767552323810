import DefaultLayout from '@/layout/DefaultLayout'

export default {
  path: '/',
  component: DefaultLayout,
  children: [
    {
      path: '/order/list',
      name: 'OrderList',
      component: () => import(/* webpackChunkName: 'Order' */'@/page/order/Order/OrderList')
    },
    {
      path: '/order/detail/:params?',
      name: 'OrderDetail',
      props: (route) => ({ query: route.query }),
      component: () => import(/* webpackChunkName: 'Order' */'@/page/order/Order/OrderDetail')
    },
    {
      path: '/delivery/list',
      name: 'DeliveryList',
      component: () => import(/* webpackChunkName: 'Delivery' */ '@/page/order/Delivery/DeliveryList')
    },
    {
      path: '/cancel/list',
      name: 'CancelList',
      component: () => import(/* webpackChunkName: 'Cancel' */ '@/page/order/Cancel/CancelList')
    },
    {
      path: '/refund/list',
      name: 'RefundList',
      component: () => import(/* webpackChunkName: 'Refund' */ '@/page/order/Refund/RefundList')
    },
    {
      path: '/return/list',
      name: 'ReturnList',
      component: () => import(/* webpackChunkName: 'Refund' */ '@/page/order/Return/ReturnList')
    },
    {
      path: '/exchange/list',
      name: 'ExchangeList',
      component: () => import(/* webpackChunkName: 'Refund' */ '@/page/order/Exchange/ExchangeList')
    }
  ]
}
