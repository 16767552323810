const files = require.context('.', false, /\.js$/)
const index = [
  {
    path: '*',
    redirect: '/error/404',
    hidden: true
  }
]

files.keys().forEach(key => {
  if (key === './index.js') return
  // if (process.env.NODE_ENV === 'production' && key === './layouts.js') return
  index.push(files(key).default)
})

export default index
