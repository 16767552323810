<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-header font-size-lg"><span>일반 영역</span></div>
                <div class="card-body">
                    <div class="col-12 pr-0 pl-0">
                        <div class="float-left ">
                            <h5 class="card-title float-left mb-0 mt-2 mr-3 mb-2">총 {{ chkList.length }} 건</h5>
                            <select class="displayStatus custom-select wd-200 mr-1" v-model="isDisplay">
                                <option value="">전체</option>
                                <option value="1">전시중</option>
                                <option value="0">전시안함</option>
                            </select>
                            <button class="btn btn-secondary wd-100" @click="onChangeVisible">선택변경</button>
                        </div>
                        <div class="float-right ml-2 mb-0 mb-2">
                            <button class="btn btn-primary wd-100" @click="goGeneralAdd"><i class="fa fa-plus mr-1"></i>추가</button>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <colgroup>
                            <col width="10%" />
                            <col width="20%" />
                            <col width="*" />
                            <col width="20%" />
                        </colgroup>
                        <thead>
                        <tr>
                            <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                            <th class="text-center">순서변경</th>
                            <th class="text-center">항목명</th>
                            <th class="text-center">전시상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!list.length">
                            <td class="text-center" colspan="4">등록된 정보가 존재하지 않습니다.</td>
                        </tr>
                        <tr v-for="(item, itemIdx) in list" :key="itemIdx">
                            <td class="text-center"><input type="checkbox" v-model="chkList" :value="item.idx" /></td>
                            <td class="text-center">
                                <select class="form-control text-center wd-100" style="margin: auto;" v-model="item.seq" @change="onChangeSeq($event, itemIdx)">
                                    <option v-for="sort in list.length" :value="sort">{{ sort }}</option>
                                </select>
                            </td>
                            <td class="text-center"><a href="javascript:void(0)" @click="goGeneralDetail(item.idx)">{{ item.itemName }}</a></td>
                            <td class="text-center">{{ item.displayStatus | displayStatus }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="col-12 pr-0 pl-0">
                        <div class="float-left">
                            <button class="btn btn-danger ml-1 mr-1 wd-100" @click="onDelete">선택삭제</button>
                        </div>
                        <div class="float-right">
                            <button class="btn btn-success wd-100" @click="onSave">저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from '@/components/common/UploadPhoto'

export default {
    name: '',
    data () {
        return {
            list: [],
            chkList: [],
            isDisplay: ''

        }
    },
    components: { UploadPhoto },
    mixins: [ CommonMixin, InterfaceMixin ],
    computed: {
        chkAll: {
            get () {
                return this.list.length ? this.chkList.length === this.list.length : false
            },
            set (value) {
                let selected = []

                if (value) {
                    this.list.map((item) => {
                        selected.push(item.idx)
                    })
                }
                this.chkList = selected
            },
        }
    },
    created () {
        this.getDataList()
    },
    mounted () {
    },
    methods: {
        // 목록조회
        getDataList () {
            return this.$api.api.getMngVisualCommonList().then(resp => {
                if (resp.status === 200) {
                    this.list = resp.data.data.commonInfoList
                }
            }).catch(error => {
                console.error(error)
            })
        },
        // 전시상태 선택변경
        onChangeVisible () {
            if (this.chkList.length <= 0) {
                this.alert("변경할 대상을 선택 해주세요.")
                return
            }
            if (this.isDisplay == null || this.isDisplay === "") {
                this.alert("적용할 전시 상태를 선택 해주세요.")
                return
            }
            this.chkList.map(chk => {
                this.list.find(item => {
                    if (chk === item.idx) {
                        item.displayStatus = Number.parseInt(this.isDisplay)
                    }
                })
            })
            this.chkList = []
        },
        // 저장
        onSave () {
            this.confirm('저장 하시겠습니까?', () => {
                let reqBody = this.list.map(item => {
                    return {
                        idx: item.idx,
                        seq: item.seq,
                        displayStatus: item.displayStatus
                    }
                })
                this.blockUI()
                this.$api.api.saveMngVisualCommonSeq({dataList: reqBody}).then(resp => {
                    if (resp.data.result) {
                        this.alert("저장 되었습니다.", () => {
                        this.getDataList()
                        this.chkList = []
                        })
                    } else {
                        this.alert("저장에 실패하였습니다.")
                    }
                }).finally(() => {
                    this.unBlockUI()
                })
            })
        },
        // 순서 변경
        onChangeSeq (event, thisIdx) {
            let temp = this.list[thisIdx]
            this.list.splice(thisIdx, 1)
            this.list.splice(event.target.value - 1, 0, temp);

            this.list.forEach((item, itemIdx) => {
                item.seq = itemIdx + 1
            })
        },
        // 선택삭제
        onDelete() {
            if (this.chkList.length <= 0) {
                this.alert("삭제할 대상을 선택해 주세요.")
                return
            }
            let reqBody = {
                idxList: this.chkList
            }
            this.confirm("해당 항목에 등록된 모든 정보가 함께 삭제됩니다. 삭제 하시겠습니까?", () => {
                this.$api.api.delSelectedMngVisualCommon(reqBody).then(resp => {
                    if (resp.status === 200) {
                        this.alert("삭제 되었습니다.");
                        this.getDataList();
                        this.chkList = []
                    } else {
                        this.alert("삭제에 실패하였습니다.")
                    }
                })
            })
        },
        // 페이지 이동
        goGeneralAdd () {
            this.$emit('add')
        },
        goGeneralDetail (visualIdx) {
            this.$emit('detail', visualIdx)
        },

    },
}
</script>
