var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "app-page-title" },
        [
          _c("navigation-bar", {
            attrs: {
              "nav-title": "비주얼관리",
              "nav-list": ["맥케이슨관리", "비주얼관리", "홈화면 노출 관리"]
            }
          })
        ],
        1
      ),
      _c("home-top"),
      _c("home-bottom"),
      _c("home-general", {
        on: { add: _vm.goGeneralAdd, detail: _vm.goGeneralDetail }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }