export default {
  imageServer: (url, addUrl) => {
    if (!url) return addUrl || ''
    if (url.indexOf('http') >= 0) return url.replace('https:', '').replace('http:', '')
    if (url.indexOf('//image.mckayson.com/') >= 0) return url
    return '//image.mckayson.com/' + url
  },
}

// export default {
//   imageServer: (url, addUrl) => {
//     if (!url) return addUrl || ''
//     if (url.indexOf('http') >= 0) return url.replace('https:', '').replace('file:', '')
//     if (url.indexOf('__________________') >= 0) return url
//     return '__________________' + url
//   },
// }

// ny
// D:\images
// file:///D:/images/temp/20210929174314696.jpg
// https://image.mckayson.com/temp/20210923094912239.jpg
