<template>
    <div>
        <div class="app-page-title">
            <navigation-bar :nav-title="'브로슈어관리'" :nav-list="['맥케이슨관리', '기본정보관리', '브로슈어관리']" />
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="main-card mb-3 card">

                    <div class="card-header">
                        <div class="col-12">
                            <div class="float-left">
                                <h5 class="card-title float-left mb-0 mt-2">브로슈어</h5>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-primary wd-100" @click="addData"><i class="fa fa-plus mr-2"></i>추가</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-11">
                                <div class="text-center" v-if="!list.length">
                                    <p class="mb-0">등록된 정보가 존재하지 않습니다.</p>
                                </div>
                                <draggable v-model="list" v-else @change="reOrdering">
                                    <div class="d-inline-block text-center p-2" v-for="(data, dataIdx) in list">
                                        <template v-if="data.idx !== ''">
                                            <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                                <img class="images" :src="getImagePath(data.fileName)" style="width: 120px; height: 120px;" v-image-error v-viewer />
                                            </div>
                                            <i class="fa fa-times mb-2 mt-2" style="cursor: pointer;" @click="onDelete(dataIdx)"></i>
                                        </template>
                                        <template v-else>
                                            <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                                <upload-photo v-if="data.fileName === ''" branch="brochure" @changed="changeImage($event, data)"></upload-photo>
                                                <img v-else :src="getImagePath(data.fileName)" class="images" v-viewer v-image-error style="width: 120px; height: 120px;" />
                                            </div>
                                            <i class="fa fa-times mb-2 mt-2" style="cursor: pointer;" @click="deleteRow(dataIdx)"></i>
                                        </template>
                                    </div>
                                </draggable>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer">
                        <div class="col-12 pr-0 pl-0">
                            <div class="float-right">
                                <button class="btn btn-success ml-1 wd-100" @click="onSave">저장</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import NavigationBar from '@/page/components/NavigationBar'
import draggable from "vuedraggable";
import UploadPhoto from '@/components/common/UploadPhoto'

export default {
  name: 'BrochureList',
  components: {
    UploadPhoto,
    NavigationBar,
    draggable
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  data () {
    return {
      list: [],
      chkList: [],
    }
  },
  created () {
    this.getDataList()
  },
  mounted () {
  },
  methods: {
    getDataList () {
      this.$api.api.getBrochureList().then(resp => {
        this.list = resp.data.result
      })

      // this.list = [
      //   {idx: 0, fileName: 'br/test001', seq: 1},
      //   {idx: 1, fileName: 'br/test002', seq: 2},
      //   {idx: 2, fileName: 'br/test003', seq: 3},
      //   {idx: 4, fileName: 'br/test003', seq: 4},
      // ]
    },
    addData () {
      this.list.push({
        idx: '',
        fileName: '',
        seq: this.list.length
      })
      this.reOrdering()
    },
    deleteRow (idx) {
      this.list.splice(idx, 1)
      this.reOrdering()
    },
    reOrdering() {
      this.list.map((item, idx) => {
        item.seq = idx + 1
      });
    },
    changeImage (e, item) {
      item.fileName = e.path + '/' + e.name
    },
    onDelete (idx) {
      this.list.splice(idx, 1)
      this.reOrdering()
    },
    onSave () {
      if (this.list.filter(item => item.fileName === '').length > 0) {
        this.alert('파일을 입력해주세요.')
        return false
      }
      this.confirm('저장 하시겠습니까?', () => {
        let reqBody = {
          imgList: this.list
        }
        this.blockUI()
        this.$api.api.sendBrochureSave(reqBody).then(resp => {
          this.alert('저장 되었습니다.')
          this.getDataList()
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.unBlockUI()
        })
      })
    },
  }
}
</script>
