<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'반품관리'" :nav-list="['맥케이슨관리', '주문관리', '반품관리']" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <ul class="nav nav-tabs nav-justified">
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.returnStatus === 21 ? 'active' : '' ]" @click="onActiveTab(21)">반품접수</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-200" :class="[ search.returnStatus === 22 ? 'active' : '' ]" @click="onActiveTab(22)">반품처리중_수거전</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-200" :class="[ search.returnStatus === 23 ? 'active' : '' ]" @click="onActiveTab(23)">반품처리중_수거완료</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.returnStatus === 26 ? 'active' : '' ]" @click="onActiveTab(26)">반품접수취소</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.returnStatus === 24 ? 'active' : '' ]" @click="onActiveTab(24)">반품불가</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.returnStatus === 25 ? 'active' : '' ]" @click="onActiveTab(25)">반품완료</a></li>
            </ul>

            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 12, unit: 'M'}" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">접수처</label>
                  <select class="form-control" v-model="search.clmWorker">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchClmWorkerMap" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">반품사유</label>
                  <select class="form-control" v-model="search.clmCd">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchClmCdMap" :value="item.rmIdx" :key="idx">{{ item.reasonSubtitle }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">책임여부</label>
                  <select class="form-control" v-model="search.clmCharge">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchClmChargeMap" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">결제수단</label>
                  <select class="form-control" v-model="search.payKind">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchPayKindMap" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">환불예정수단</label>
                  <select class="form-control" v-model="search.refundMethod">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchRefundMethodMap" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1 m-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="returnStatus custom-select wd-200 mr-1" v-model.number="selectStatus" v-if="search.returnStatus == 21 || search.returnStatus == 22 || search.returnStatus == 23">
                  <option value="">선택해주세요</option>
                  <option value="21" v-if="search.returnStatus != 21 && search.returnStatus != 22 && search.returnStatus != 23">반품접수</option>
                  <option value="22" v-if="search.returnStatus != 22 && search.returnStatus != 23">반품처리중[수거전]</option>
                  <option value="23" v-if="search.returnStatus != 23">반품처리중[수거완료]</option>
                  <option value="26" v-if="search.returnStatus != 23">반품접수취소</option>
                  <option value="24" v-if="search.returnStatus != 21">반품불가</option>
                  <option value="25" v-if="search.returnStatus != 21">반품완료</option>
                </select>
                <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="updateReturnDone" v-if="search.returnStatus == 21 || search.returnStatus == 22 || search.returnStatus == 23">변경저장</button>
                <button class="btn btn-success wd-150 ml-1 mr-1" @click="exportExcel">엑셀다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center" v-if="search.returnStatus == 21 || search.returnStatus == 22 || search.returnStatus == 23"><input type="checkbox" @click="checkedAll" v-model="allSelected" /></th>
                <th class="text-center">NO</th>
                <th class="text-center" v-if="search.returnStatus == 21">접수일<br/>/접수처</th>
                <th class="text-center" v-if="search.returnStatus == 22 || search.returnStatus == 23">상태변경일</th>
                <th class="text-center" v-if="search.returnStatus == 26">반품접수 취소일</th>
                <th class="text-center" v-if="search.returnStatus == 24">반품불가일</th>
                <th class="text-center" v-if="search.returnStatus == 25">반품완료일</th>
                <th class="text-center" v-if="search.returnStatus == 25">유입경로</th>
                <th class="text-center">회원명</th>
                <th class="text-center">회원ID</th>
                <th class="text-center">주문번호</th>
                <th class="text-center">클레임번호</th>
                <th class="text-center">주문자</th>
                <th class="text-center">휴대폰번호</th>
                <th class="text-center">상품번호</th>
                <th class="text-center">상품명</th>
                <th class="text-center">반품수량</th>
                <th class="text-center">판매가</th>
                <th class="text-center">상품금액</th>
                <th class="text-center">반품사유</th>
                <th class="text-center">책임여부</th>
                <th class="text-center">결제수단</th>
                <th class="text-center">환불예정수단</th>
                <th class="text-center">환불예정금액</th>
                <th class="text-center">환불정보</th>
                <th class="text-center" v-if="search.returnStatus != 24">반품상태</th>
                <th class="text-center" v-if="search.returnStatus == 25">환불예정일<br/>/환불완료일</th>
                <th class="text-center" v-if="search.returnStatus != 21">접수일<br/>/접수처</th>
                <th class="text-center" v-if="search.returnStatus == 24 || search.returnStatus == 25">담당자</th>
                <th class="text-center">메모</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!itemList.length">
                <td :colspan="search.returnStatus == 22? 21 : search.returnStatus == 25? 22 : 20" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                <td class="text-center" v-if="search.returnStatus == 21 || search.returnStatus == 22 || search.returnStatus == 23">
                  <input type="checkbox" v-model="selectedList" :value="data.cpoIdx" />
                </td>
                <td class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</td>
                <td class="text-center" v-if="search.returnStatus == 21">{{ dateFormat(data.reqDate, 'YYYY-MM-DD HH:mm') }} <br/>{{data.clmWorker}}</td>
                <td class="text-center" v-if="search.returnStatus == 22 || search.returnStatus == 23">{{ dateFormat(data.clmStatusDate, 'YYYY-MM-DD HH:mm')}}</td>
                <td class="text-center" v-if="search.returnStatus == 26">{{ dateFormat(data.clmStatusDate, 'YYYY-MM-DD HH:mm')}}</td>
                <td class="text-center" v-if="search.returnStatus == 24">{{ dateFormat(data.clmStatusDate, 'YYYY-MM-DD HH:mm')}}</td>
                <td class="text-center" v-if="search.returnStatus == 25">{{ dateFormat(data.cplDate, 'YYYY-MM-DD HH:mm')}}</td>
                <td class="text-center" v-if="search.returnStatus == 25">{{data.clmWorker}}</td>
                <td class="text-center">{{ data.cliName}}</td>
                <td class="text-center">{{ data.id}}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="callOrderDetailPop(data.ordIdx)">{{ data.ordNo }}</a></td>
                <td class="text-center"><a href="javascript:void(0)" @click="callClaimDetailPop(data.ordIdx, data.clmIdx, data.clmNo)">{{data.clmNo}}</a></td>
                <td class="text-center">{{ data.userName}}</td>
                <td class="text-center">{{ data.userPhone | dash}}</td>
                <td class="text-center">{{ data.prdNo}}</td>
                <td class="text-left">{{ data.ordProduct }}/{{ data.opoColName }}/{{ data.opoSize }}</td>
                <td class="text-center">{{ data.cpoQuantity}}</td>
                <td class="text-center">{{ data.opoOptPriceSale | comma}}원</td>
                <td class="text-center" :style="data.memIdx? 'color:#B40404': ''">{{ data.opoOnlyPrdAmount | comma}}원</td>
                <td class="text-center">{{ data.clmCdStr}}</td>
                <td class="text-center">{{ data.clmChargeStr }}</td>
                <td class="text-center">{{ data.payKindStr }}</td>
                <td class="text-center">{{ data.refundMethodStr }}</td>
                <td class="text-center">{{ data.expectRefundAmount |comma }}원</td>
                <td class="text-center">
                  <span v-show="data.refundBankMaster">{{data.refundBankMaster}}/</span>
                  <span v-show="data.refundBankName">{{data.refundBankName}}/</span>
                  <span v-show="data.refundBankNo">{{data.refundBankNo}}</span>
                </td>
                <td class="text-center" v-if="search.returnStatus != 24">
                  <select class="form-control" v-model="data.cpoStatus" v-if="search.returnStatus == 21 || search.returnStatus == 22 || search.returnStatus == 23" @change="updateStatus(data.cpoIdx, $event)">
                    <option value=21 v-if="search.returnStatus != 22 && search.returnStatus != 23" data-name="반품접수">반품접수</option>
                    <option value=22 v-if="search.returnStatus != 23" data-name="반품처리중[수거전]">반품처리중[수거전]</option>
                    <option value=23 data-name="반품처리중[수거완료]">반품처리중[수거완료]</option>
                    <option value=26 v-if="search.returnStatus != 23" data-name="반품접수취소">반품접수취소</option>
                    <option value=24 v-if="search.returnStatus != 21" data-name="반품불가">반품불가</option>
                    <option value=25 v-if="search.returnStatus != 21" data-name="반품완료">반품완료</option>
                  </select>
                  <span v-if="search.returnStatus == 26 || search.returnStatus == 25">{{data.cpoStatusStr}}</span>
                </td>
                <td class="text-center" v-if="search.returnStatus == 25">{{ dateFormat(data.expectRefundDate, 'YYYY-MM-DD HH:mm')}}<br/>/{{ dateFormat(data.refundDate, 'YYYY-MM-DD HH:mm')}}</td>
                <td class="text-center" v-if="search.returnStatus != 21">{{ dateFormat(data.reqDate, 'YYYY-MM-DD HH:mm') }} <br/>{{data.clmWorker}}</td>
                <td class="text-center" v-if="search.returnStatus == 24 || search.returnStatus == 25">{{data.admId}}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(data.ordIdx, data.ordNo)"><i class="pe-7s-comment pr-2"></i>{{ data.memoCnt | comma }}</a></td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-if="itemList.length>0"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import DatePickerButton from '@/components/common/DatePickerButton'
import OrderDetailPop from '@/page/order/components/OrderDetailPop'
import ClaimDetailPop from '@/page/order/components/ClaimDetailPop'
import MemoPop from '@/page/order/components/MemoPop'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import moment from "moment";

export default {
  name: 'ReturnList',
  data () {
    return {
      courierList: [],
      search: {
        clmCd: '',
        clmCharge: '',
        clmWorker: '',
        ordStatus: '',
        payKind: '',
        refundMethod: '',
        returnStatus: 21,
        srchDateEnd: '',
        srchDateStart: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: ''
      },
      srchClmWorkerMap: [
        { value: 'PC', text: 'PC'},
        { value: 'MC', text: 'MC'},
        { value: 'CS', text: 'CS'}
      ],
      srchClmCdMap: [],
      srchClmChargeMap: [
        { value: 1, text: '고객책임'},
        { value: 2, text: '판매자책임'}
      ],
      srchPayKindMap: [
        { value: 1, text: '신용카드'},
        { value: 2, text: '가상계좌'},
        { value: 3, text: '휴대폰'}
      ],
      srchRefundMethodMap: [
        { value: 1, text: '결제취소'},
        { value: 2, text: '현금환불'},
      ],
      srchTypeList: [
        { value: 1, text: '주문번호' },
        { value: 2, text: '클레임번호' },
        { value: 3, text: '주문자명' },
        { value: 4, text: '휴대폰번호' },
        { value: 5, text: '상품번호' },
        { value: 6, text: '상품명' },
        { value: 7, text: '예금주' },
        { value: 8, text: '은행명' },
        { value: 9, text: '계좌번호' },
        { value: 10, text: '회원명' },
        { value: 11, text: '회원ID' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' },
        { value: 100, text: '100개' }
      ],
      totalCnt: 0,
      selectStatus: '',
      itemList: [],
      selectedList: [],
      allSelected: false
    }
  },
  components: {
    NavigationBar,
    DatePickerButton,
    Pagination,
    OrderDetailPop,
    ClaimDetailPop,
    MemoPop
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  created () {
    this.$bus.$on('memoUpdate', value => {
      this.itemList.map((element, idx) => {
        if(element.ordIdx == value.ordIdx) {
          this.itemList[idx].memoCnt = value.amount
        }
      })
    })
  },
  mounted () {
    this.init()
    this.getReasonList()
  },
  methods: {
    onActiveTab (tabIdx) {
      this.search.returnStatus = Number(tabIdx)
      this.selectStatus = ''
      this.onReset()
      this.onSearch()
    },
    init () {
      this.search.returnStatus = 21
      this.onReset()
      this.onSearch()
    },
    onReset () {
      this.search.clmCd = ''
      this.search.clmCharge = ''
      this.search.clmWorker = ''
      this.search.ordStatus = ''
      this.search.payKind = ''
      this.search.refundMethod = ''
      // this.search.returnStatus = 21
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      this.search.srchKeyword = ''
      // this.search.srchPage = 1
      // this.search.srchPageCnt = 10
      this.search.srchType = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getReasonList() {
      this.$api.api.getReasonMngList().then(resp => {
        this.srchClmCdMap = resp.data.data.resultReturnData
      })
    },
    getItemList () {
      let params = {
        clmCd: this.search.clmCd,
        clmCharge: this.search.clmCharge,
        clmWorker: this.search.clmWorker,
        ordStatus: this.search.ordStatus,
        payKind: this.search.payKind,
        refundMethod: this.search.refundMethod,
        returnStatus: this.search.returnStatus,

        srchDateStart: this.search.srchDateStart,
        srchDateEnd: this.search.srchDateEnd,
        srchType: this.search.srchType,
        srchKeyword: this.search.srchKeyword,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }

      return this.$api.api.getReturnList(params).then(resp => {
        this.itemList = resp.data.data.admOrderReturns
        this.totalCnt = resp.data.data.seachCount
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail (ordIdx) {
      this.$router.push({path: '/delivery/detail', query: { ordIdx: ordIdx }})
    },
    exportExcel () {
      let params = {
        clmType: this.search.clmType,     // 시작일
        clmWorker: this.search.clmWorker,         // 종료일
        payKind: this.search.payKind,   // 유입경로
        refundMethod: this.search.refundMethod,             // 결제수단
        returnStatus: this.search.returnStatus,         // 결제상태
        srchDateEnd: this.search.srchDateEnd,               // 검색조건
        srchDateStart: this.search.srchDateStart,         // 검색어
        srchKeyword: this.search.srchKeyword,               // 불필요
        srchPage: this.search.srchPage,          // 불필요
        srchPageCnt: this.search.srchPageCnt,          // 불필요
        srchType: this.search.srchType          // 불필요
      }
      return this.$api.api.exportReturnList(params).then(resp => {
        if (resp.status === 200) {
          this.$toast.success('엑셀 다운로드 성공')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    callOrderDetailPop (ordIdx) {
      this.$modal.show(
          OrderDetailPop,
          { text: '주문정보', ordIdx: Number(ordIdx) },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event.params)
            }
          }
      )
    },
    callClaimDetailPop (ordIdx, clmIdx, clmNo) {
      this.$modal.show(
          ClaimDetailPop,
          { text: '반품', ordIdx: Number(ordIdx), clmIdx: clmIdx, clmNo:clmNo, claimType: 'return'},
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
            }
          }
      )
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
          MemoPop,
          { text: '메모', ordIdx: ordIdx, ordNo: ordNo},
          { draggable: false, height: 'auto' },
          {
            'before-close': event => {
              console.log('close modal::: MemoPop')
            }
          }
      )
    },
    checkedAll () {
      this.selectedList = []
      if (!this.allSelected) {
        for (let i = 0; i < this.itemList.length; i++) {
          this.selectedList.push(this.itemList[i].cpoIdx)
        }
      }
    },
    setDateStart (value) {
      this.search.srchDateStart = value
    },
    setDateEnd (value) {
      this.search.srchDateEnd = value
    },
    chgStatus(idx) {
      let reqParam = []
      reqParam.push(idx)

      this.updateRefundDone(reqParam)
    },
    updateStatus(cpoIdx, e) {
      let selectedOptionText = e.target.options[e.target.options.selectedIndex].dataset.name;
      this.$confirm(`선택하신 주문을 ${selectedOptionText} 으로 상태 변경하시겠습니까?`).then(resp => {
        let reqParam = {
          cpoIdxs: [cpoIdx],
          cpoStatus: e.target.value
        }

        this.$api.api.updateReturnStatus(reqParam).then(resp => {
          if (resp.data.result) {
            this.alert('변경되었습니다.', () => {
              this.onActiveTab(Number(e.target.value))
            })
          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    },
    updateReturnDone() {
      if(!this.selectStatus) {
        this.alert('변경할 상태를 선택해주세요')
        return
      }
      if(this.selectedList.length == 0) {
        this.alert('상태 변경할 주문을 선택해주세요')
        return
      }
      let status = document.querySelector('.returnStatus');
      let text = status.options[status.selectedIndex].text
      let msg = '선택하신 주문을 ' + text + '으로 상태 변경하시겠습니까?'

      this.confirm(msg, () => {
        this.$api.api.updateReturnStatus({cpoIdxs: this.selectedList, cpoStatus: this.selectStatus}).then(resp => {
          if (resp.data.result) {
            this.alert(text +'로 변경되었습니다.', () => {
              this.onActiveTab(this.selectStatus)
            })

          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
