<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="clearfix mb-2">
                    <h5 class="card-title float-left mb-0 mt-3 text-left">{{ title }}</h5>
                    <button class="btn btn-primary float-right wd-100" @click="addRow">추가</button>
                </div>
            </div>
            <div class="col-md-12">
                <table class="table table-striped">
                    <colgroup>
                        <col width="50px;" />
                        <col width="150px;" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                            <th class="text-center">순서</th>
                            <th class="text-center"><span class="required">*</span>이미지1</th>
                            <th class="text-center" v-if="list.find(f => f.sectionType === 1)"><span class="required">*</span>이미지2</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!list.length">
                            <td class="text-center" colspan="4">등록된 정보가 존재하지 않습니다.</td>
                        </tr>
                        <tr v-for="(item, itemIdx) in list">
                            <td class="text-center">
                                <input type="checkbox" v-model="chkList" :value="item.idx" v-if="item.idx !== ''" />
                                <i class="fa fa-times" style="cursor: pointer;" @click="deleteRow(itemIdx)" v-else></i>
                            </td>
                            <td class="text-center">
                                <select class="custom-select wd-100" style="margin: auto;" @change="onChangeSeq($event, itemIdx)" v-model="item.seq">
                                    <option v-for="sort in list.length" :value="sort">{{ sort }}</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <upload-photo v-if="item.image1.filename === ''" branch="visual" @changed="changeImage($event, item.image1)" class="mb-2" />
                                <img v-else :src="getImagePath(item.image1.path + '/' + item.image1.filename)" class="images" v-image-error v-viewer style="width: 120px;" />
                            </td>
                            <td class="text-center" v-if="item.sectionType === 1">
                                <upload-photo v-if="item.image2.filename === ''" :file="{src: getImagePath(item.image2.filename), w:100, he:100}" branch="visual" @changed="changeImage($event, item.image2)" class="mb-2" />
                                <img v-else :src="getImagePath(item.image2.path + '/' + item.image2.filename)" class="images" v-image-error v-viewer style="width: 120px;" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <div class="float-left">
                    <button class="btn btn-danger ml-1 wd-100" @click="onDelete">선택삭제</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from '@/components/common/UploadPhoto'

export default {
    name: 'GeneralCollectionTable',
    mixins: [ CommonMixin, InterfaceMixin ],
    components: {
        UploadPhoto,
    },
    data () {
        return {
            chkList: [],
        }
    },
    props: {
        sectionType: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        list: {
            type: Object | Array,
            default: []
        }
    },
    computed: {
        chkAll: {
            get () {
                return this.list.length ? this.chkList.length === this.list.length : false
            },
            set (value) {
                let selected = []

                if (value) {
                    this.list.map(item => {
                        selected.push(item.idx)
                    })
                }
                this.chkList = selected
            }
        }
    },
    created () {
    },
    mounted () {
    },
    methods: {
        // Row 추가
        addRow () {
            let temp = {
                idx: '',
                sectionType: this.sectionType,
                seq: this.list.length + 1,
                image1: {
                    filename: '',
                    path: '',
                    width: 100,
                    height: 100,
                },
                image2: {
                    filename: '',
                    path: '',
                    width: 100,
                    height: 100,
                }
            }
            this.list.push(temp)
        },
        // x 삭제
        deleteRow (idx) {
            this.list.splice(idx, 1)
            this.list.map((item, idx) => {
                item.seq = idx + 1
            })
        },
        onDelete () {
            if (this.chkList.length <= 0) {
                this.alert("삭제할 대상을 선택 해주세요.")
                return false
            }
            // 배준M 요청 (2021-12-15 서버 처리 이슈로 인해 한개 필수)
            if (this.list.length - this.chkList.length <= 0) {
                this.alert('1개 이상 필수로 등록 되어야 합니다.')
                return false
            }
          let reqBody = {
            idxList: this.chkList
          }
          this.confirm("삭제 하시겠습니까?", () => {
            this.$api.api.delMngVisualCommonTemplate(reqBody).then(resp => {
              if (resp.status === 200) {
                this.alert("삭제 되었습니다.")
                this.chkList = []
                this.$emit('reload')
              } else {
                this.alert("삭제에 실패하였습니다.")
              }
            })
          })
        },
        // 순서변경
        onChangeSeq (event, thisIdx) {
            let temp = this.list[thisIdx]
            this.list.splice(thisIdx, 1)
            this.list.splice(event.target.value - 1, 0, temp)

            this.list.forEach((item, itemIdx) => {
                item.seq = itemIdx + 1
            })
        },
        // 이미지 업로드
        changeImage (file, thisObj) {
            thisObj.path = file.path
            thisObj.filename = file.name
            thisObj.width = file.w
            thisObj.height = file.h
        },
    },
}
</script>
