<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'배송관리'" :nav-list="['맥케이슨관리', '주문관리', '배송관리']" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <ul class="nav nav-tabs nav-justified">
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ tabIdx === 1 ? 'active' : '' ]" @click="onActiveTab(1)">배송준비</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ tabIdx === 2 ? 'active' : '' ]" @click="onActiveTab(2)">배송중</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ tabIdx === 3 ? 'active' : '' ]" @click="onActiveTab(3)">배송완료</a></li>
            </ul>

            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 12, unit: 'M'}" />
                </div>
              </div>
            </div>
            <div class="form-row" v-if="tabIdx === 1">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">배송지연</label>
                  <select class="form-control" v-model="search.srchDelay">
                    <option value="0">전체</option>
                    <option v-for="(item, idx) in srchDelayList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-150 mr-1" v-if="tabIdx !== 3" v-model="chgStatus">
                  <option v-if="tabIdx === 1" value="2">배송중</option>
                  <option v-if="tabIdx === 2" value="3">배송완료</option>
                </select>
                <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="onChangeStatus" v-if="tabIdx !== 3">변경저장</button>
                <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="callBundlePop" v-if="tabIdx === 1">묶음배송</button>
                <button class="btn btn-secondary wd-150 ml-1 mr-1" @click="callInvoiceNumberPop" v-if="tabIdx !== 3">송장번호 등록/수정</button>
                <button class="btn btn-success wd-150 ml-1 mr-1" @click="exportExcel">엑셀다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                  <th class="text-center">NO</th>
                  <th class="text-center">주문번호</th>
                  <th class="text-center">회원명</th>
                  <th class="text-center">회원ID</th>
                  <th class="text-center">상품별주문번호<br/>/송장번호</th>
                  <th class="text-center">상품번호</th>
                  <th class="text-center">상품명</th>
                  <th class="text-center">수량</th>
                  <th class="text-center">판매가</th>
                  <th class="text-center">상품금액</th>
                  <th class="text-center wd-100">주문자명</th>
                  <th class="text-center">주문자 연락처</th>
                  <th class="text-center wd-100">수령자명</th>
                  <th class="text-center">수령자 연락처</th>
                  <th class="text-center">배송지</th>
                  <th class="text-center">배송요청사항</th>
                  <th class="text-center">배송상태</th>
                  <th class="text-center" v-if="tabIdx === 1">상태변경일</th>
                  <th class="text-center" v-if="tabIdx === 2">배송시작일</th>
                  <th class="text-center" v-if="tabIdx === 3">배송완료일/배송시작일</th>
                  <th class="text-center">메모</th>
                  <th class="text-center" v-if="tabIdx === 1">배송지연</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!itemList.length">
                  <td :colspan="tabIdx !== 3 ? 19 : 18" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" v-model="chkList" :value="data" /></td>
                  <td class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callOrderDetailPop(data.ordIdx)">{{ data.ordNo }}</a></td>
                  <td class="text-center">{{ data.cliName }}</td>
                  <td class="text-center">{{ data.id }}</td>
                  <td class="text-center">
                    <label class="form-label d-block">{{ data.ordNo }}_{{ data.opoIdx }}</label>
                    <label class="form-label">/{{ data.odvDeliComStr }} {{ data.odvDeliNo }}</label>
                  </td>
                  <td class="text-center">{{ data.prdNo }}</td>
                  <td class="text-left">{{ data.ordProduct }}/{{ data.opoColName }}/{{ data.opoSize }}</td>
                  <td class="text-center">{{ data.opoQuantity | comma }}</td>
                  <td class="text-center">{{ data.opoOptPriceSale | comma }}원</td>
                  <td class="text-center" :style="data.memIdx? 'color:#B40404': ''">{{ data.opoOnlyPrdAmount | comma }}원</td>
                  <td class="text-center">{{ maskName(data.userName) }}</td>
                  <td class="text-center">{{ maskPhone(data.userPhone) | dash }}</td>
                  <td class="text-center">{{ maskName(data.recipient) }}</td>
                  <td class="text-center">{{ maskPhone(data.recipientPhone) | dash}}</td>
                  <td class="text-center">{{ data.address }}</td>
                  <td class="text-center">{{ data.comment }}</td>
                  <td class="text-center">
                    <span v-if="data.odvStatus == 3 && data.opoPurchaseConfirm == 'Y'">
                      <input type="text" class="form-control text-center wd-100" value="구매확정" readonly disabled>
                    </span>
                    <select type="select" class="custom-select wd-100" v-model="data.odvStatus" @change="onChangeStatus(idx,data.odvStatus)" v-else>
                      <option v-if="tabIdx === 1" value="1">배송준비</option>
                      <option v-if="tabIdx === 1" value="2">배송중</option>
                      <option v-if="tabIdx === 2" value="2">배송중</option>
                      <option v-if="tabIdx === 2" value="3">배송완료</option>
                      <option v-if="tabIdx === 3" value="3">배송완료</option>
                    </select>
                  </td>
                  <td class="text-center" v-if="tabIdx === 1">{{ dateFormat(data.odvStatusDate, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center" v-if="tabIdx === 2">{{ dateFormat(data.odvSendDt, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center" v-if="tabIdx === 3">{{ dateFormat(data.odvDeliDt, 'YYYY-MM-DD HH:mm') }}<br/>/{{ dateFormat(data.odvSendDt, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(data.ordIdx, data.ordNo)"><i class="pe-7s-comment pr-2"></i>{{ data.memoCnt | comma }}</a></td>
                  <td class="text-center" v-if="tabIdx === 1">{{ data.isDelay | isDelay }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import DatePickerButton from '@/components/common/DatePickerButton'
import OrderDetailPop from '@/page/order/components/OrderDetailPop'
import PaymentPop from '@/page/order/components/PaymentPop'
import MemoPop from '@/page/order/components/MemoPop'
import BundlePop from '@/page/order/components/BundlePop'
import InvoiceNumberPop from '@/page/order/components/InvoiceNumberPop'
import moment from 'moment'

export default {
  name: 'DeliveryList',
  data () {
    return {
      tabIdx: 1,
      chgStatus: 2,
      search: {
        srchDateStart: '',
        srchDateEnd: '',
        srchDelay: 0,
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10
      },
      srchDelayList: [
        { value: 1, text: '배송지연'},
      ],
      srchTypeList: [
        { value: 1, text: '주문번호' },
        { value: 2, text: '송장번호' },
        { value: 3, text: '상품번호' },
        { value: 4, text: '상품명' },
        { value: 5, text: '주문자명' },
        { value: 6, text: '주문자 연락처' },
        { value: 7, text: '수령자명' },
        { value: 8, text: '수령자 연락처' },
        { value: 9, text: '배송요청사항' },
        { value: 10, text: '회원명' },
        { value: 11, text: '회원ID' },
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' },
        { value: 100, text: '100개' }
      ],
      totalCnt: 0,
      itemList: [],
      chkList: []
    }
  },
  components: {
    NavigationBar,
    DatePickerButton,
    Pagination,
    OrderDetailPop,
    PaymentPop,
    MemoPop,
    BundlePop,
    InvoiceNumberPop
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.itemList.length ? this.chkList.length === this.itemList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.itemList.map((item, idx) => {
            selected.push(item)
          })
        }
        this.chkList = selected
      }
    }
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    onActiveTab (tabIdx) {
      this.tabIdx = tabIdx
      this.chgStatus = tabIdx + 1
      this.onReset()
      this.onSearch()
    },
    init () {
      this.onReset()
      this.onSearch()
    },
    onReset () {
      let toDay = new Date()
      this.search.srchDateEnd = moment(toDay).format('YYYY-MM-DD')
      toDay.setDate(toDay.getDate() - 6)
      this.search.srchDateStart = moment(toDay).format('YYYY-MM-DD')
      //this.search.srchDateStart = '2020-01-01'
      //this.search.srchDateEnd = '2020-12-31'
      this.search.srchDelay = 0
      this.search.srchType = ''
      this.search.srchKeyword = ''
      //this.search.srchPage = 1
      //this.search.srchPageCnt = 10
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getItemList () {
      let params = {
        odvStatus: this.tabIdx,
        srchDateStart: this.search.srchDateStart,
        srchDateEnd: this.search.srchDateEnd,
        isDelay: this.search.srchDelay,
        srchType: this.search.srchType,
        srchKeyword: this.search.srchKeyword,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      return this.$api.api.getDeliveryList(params).then(resp => {
        let opoList = this.chkList.map(data => data.opoIdx)
        this.itemList = resp.data.data.admOrderDeliveries
        this.totalCnt = resp.data.data.seachCount
        this.chkList = this.itemList.filter(data => opoList.includes(data.opoIdx))
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail (ordIdx) {
      this.$router.push({path: '/delivery/detail', query: { ordIdx: ordIdx }})
    },
    onChangeStatus (idx, odvStatus) {
      if(odvStatus) {
        if(odvStatus <= this.tabIdx) return false
      }else if(!this.chkList.length) {
        this.alert('상태 변경할 주문을 선택해주세요.')
        return false
      }
      let status = odvStatus? odvStatus : this.chgStatus
      this.confirm('선택하신 주문을 ' + (status == 2? '배송중으로' : '배송완료로') + ' 상태 변경하시겠습니까?', () => {
          let params = {
            orderIdxs: [] // 배송준비로 변경 시 : ordIdx, 배송중/배송완료로 변경 시 opoIdx
          }
          if(odvStatus) {
            if(this.itemList[idx].odvDeliNo) {
                params.odvStatus = odvStatus
                params.orderIdxs.push(this.itemList[idx].opoIdx)
            }else {
                this.alert('송장번호를 입력해주세요.')
                return false
            }
          }else {
              params.odvStatus = this.chgStatus
              for(let i = 0; i < this.chkList.length; i++) {
                if(this.chkList[i].odvDeliNo) params.orderIdxs.push(this.chkList[i].opoIdx)
                else {
                    this.alert('송장번호를 입력해주세요.')
                    return false
                }
              }
          }

          this.$api.api.changeDeliveryStatus(params).then(resp => {
            if (resp.status === 200) {
              this.alert((status == 2? '배송중으로' : '배송완료로') + ' 변경되었습니다.')
              this.getItemList()
            }
          }).catch(error => {
            console.log(error)
          })
      },() => {
          //console.log('status : ' + this.itemList[idx].odvStatus)
          this.itemList[idx].odvStatus = odvStatus - 1
          //this.itemList = this.itemList.filter(() => true)
      })
    },
    callBundlePop () {
      if (this.chkList.length === 0) {
        this.alert('묶음배송할 주문을 선택해주세요.')
        return false
      }
      for(let i = 0; i < this.chkList.length; i++) {
        if(this.chkList[0].address != this.chkList[i].address) {
            this.alert('수령자 주소가 동일하지 않습니다. 다시 선택해주세요.')
            return false
        }
        if(this.chkList[0].recipientPhone != this.chkList[i].recipientPhone) {
            this.alert('휴대폰번호가 동일하지 않습니다. 다시 선택해주세요.')
            return false
        }
        if(this.chkList[0].recipient != this.chkList[i].recipient) {
            this.alert('수령자 이름이 동일하지 않습니다. 다시 선택해주세요.')
            return false
        }
      }
      this.$modal.show(
          BundlePop,
          { text: '묶음배송', products: this.chkList, type: 'delivery' },
          { draggable: false, height: 'auto', adaptive: true, maxWidth: 600 },
          {
            'before-close': event => {
              //this.alert('묶음배송처리 ㄱㄱ')
              this.getItemList()
            }
          }
      )
    },
    callInvoiceNumberPop () {
      if (!this.chkList.length) {
        this.alert('수정할 주문을 선택해주세요.')
        return false
      }
      this.$modal.show(
          InvoiceNumberPop,
          { text: '송장번호 수정', products: this.chkList, type: 'delivery' },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1000 },
          {
            'before-close': event => {
              //this.alert('송장번호변경ㄱㄱ')
              this.getItemList()
            }
          }
      )
    },
    exportExcel () {
      let params = {
        odvStatus: this.tabIdx,
        srchDateStart: this.search.srchDateStart,
        srchDateEnd: this.search.srchDateEnd,
        isDelay: this.search.srchDelay,
        srchType: this.search.srchType,
        srchKeyword: this.search.srchKeyword,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      return this.$api.api.exportDeliveryList(params).then(resp => {
        if (resp.status === 200) {
          this.$toast.success('엑셀 다운로드 성공')
        }
      }).catch(error => {
        console.log(error)
      })

    },
    callOrderDetailPop (ordIdx) {
      this.$modal.show(
          OrderDetailPop,
          { text: '주문정보', ordIdx: ordIdx },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
            }
          }
      )
    },
    callPaymentPop (ordIdx) {
      this.$modal.show(
        PaymentPop,
        { text: '결제정보', ordIdx: ordIdx },
        { draggable: false, height: 'auto', adaptive: true, maxWidth: 400 },
        {
          'before-close': event => {
          }
        }
      )
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, height: 'auto' },
        {
          'before-close': event => {
            this.getItemList()
          }
        }
      )
    },
    setDateStart (value) {
      this.search.srchDateStart = value
    },
    setDateEnd (value) {
      this.search.srchDateEnd = value
    }
  }
}
</script>

<style scoped>

</style>
