<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'이벤트관리'" :nav-list="['맥케이슨관리', '게시판관리', '이벤트관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setStartDate" @inputEndDate="setEndDate" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="month" :search-period="{amount: 6, unit: 'M'}"/>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">전시여부</label>
                  <select class="form-control" v-model="search.evtIsUse">
                    <option v-for="(item, idx) in srchEvtIsUseList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangePageSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center"><input type="checkbox"  v-model="chkAll"/></th>
                  <th class="text-center">번호</th>
                  <th class="text-center">게시일시</th>
                  <th class="text-center">이벤트명</th>
                  <th class="text-center">전시여부</th>
                  <th class="text-center">이미지1</th>
                  <th class="text-center">이미지2</th>
                  <th class="text-center">이미지3</th>
                </tr>
              </thead>
              <tbody>


                <tr v-if="!resultDataList.length">
                  <td colspan="11" class="text-center">내역이 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(item, idx) in resultDataList" :key="idx" v-else>
                  <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="chkList" :value="item.evtIdx" :id="item.evtIdx"/></td>
                  <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                  <td class="text-center">{{ dateFormat(item.evtSdt, 'YYYY-MM-DD')}} ~ {{ dateFormat(item.evtEdt, 'YYYY-MM-DD')}}</td>
                  <td class="text-center"><a href="javascript:void(0)" @click="onDetail(item.evtIdx)"> {{ item.evtName }} </a> </td>
                  <td class="text-center">{{ item.evtIsUseStr }}</td>
                  <td class="text-center" v-for="(item2,index) in 3">
                    <img class="images" v-if='item.evtImgListData[index]' :src="getImagePath(item.evtImgListData[index].path + '/' + item.evtImgListData[index].thumb1)" style="width: 100px; height: 100px" v-image-error v-viewer />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="resultDataList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from "@/page/components/Pagination"
import DatePickerButton from "@/components/common/DatePickerButton"
import InterfaceMixin from '@/mixins/userInterface-mixin'
import moment from 'moment'


export default {
  name: "EventList",
  data () {
    return {
      search: {
        evtIsUse : null,
        srchDateStart: '',
        srchDateEnd: '',
        srchType: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10
      },
      srchPrmStatusList: [
        { value: 1, text: '진행전' },
        { value: 2, text: '진행중' },
        { value: 3, text: '종료' }
      ],
      srchEvtIsUseList: [
        { value: null , text: '전체'},
        { value: 1, text: '전시' },
        { value: 0, text: '비전시' }
      ],
      srchTypeList: [
        { value: 1, text: '이벤트명' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' }
      ],
      totalCnt: 0,
      resultDataList: [],
      chkList: []
    }
  },
  components: {
    NavigationBar,
    Pagination,
    DatePickerButton,
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
    chkAll: {
      get () {
        return this.resultDataList.length ? this.chkList.length === this.resultDataList.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.resultDataList.map(item => {
            selected.push(item.evtIdx)
          })
        }
        this.chkList = selected
        console.log("[체크리스트]"+this.chkList)
      }
    }
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.initDate()
      this.onSearch()
    },
    initDate(){
      this.search.srchDateStart = moment(new Date()).add(-3, 'M').format('YYYY-MM-DD')
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
    },
    onSearch(){
      this.$api.api.getEventList(this.search).then(res=>{
        let  resultRes = res.data
        if(resultRes.result){
          this.resultDataList = res.data.data
          this.totalCnt = res.data.totalCnt
        }
      })
    },
    onReset () {
      this.initDate()
      this.search.srchType = ''
      this.search.srchKeyword = ''
      this.search.evtIsUse = null
      this.search.srchType = ''
      this.search.srchKeyword = ''
    },
    onDelete(){
      this.$api.api.deleteEvent({ evtIdxList : this.chkList}).then(res=>{
        if(res.data.result){
          this.alert("삭제되었습니다.")
          this.onSearch()
        }
      })
    },
    onDetail(evtIdx = 0){
      this.$router.push({name: 'EventRegistration' , params :{evtIdx}})
    },
    onChangePageSize(){
      this.search.srchPage = 1
      this.onSearch()
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.onSearch()
    },
    onCreate () {
      this.$router.push({name: 'EventRegistration'})
    },
    setStartDate (value) {
      this.search.srchDateStart = value
    },
    setEndDate (value) {
      this.search.srchDateEnd = value
    }
  }
}
</script>

<style scoped>

</style>
