<template>
  <div class="error_page_wrapper">
    <div class="error_wrap">
      <div class="container text-center">
        <div class="error_heading text-center">
          <h3 class="headline font-danger">404</h3>
        </div>
        <h3 class="text-uppercase error-subtitle">Page Not Found !</h3>
        <div class="col-md-8 offset-md-2 text-center">
          <p class="mb-0 mt-0">요청하신 페이지를 찾을 수 없습니다.</p>
          <p class="mt-0">페이지 주소를 올바르게 입력했는지 다시 확인해보세요.</p>
        </div>
        <div class="error_btn text-center">
          <a class="btn btn-danger" href="javascript:void(0)" @click="$router.back()">Back Home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error404',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
