<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">상품검색</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>

        <div class="card-body pt-2">
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th class="text-center center">카테고리</th>
              <td>
                <select class="form-control d-inline w-50" v-model="search.srchCat1Idx" @change="getCategory2">
                  <option value="" >전체</option>
                  <option v-for="item in cat1List" :value="item.cat1Idx">{{item.cat1Name}}</option>
                </select>
                <select class="form-control d-inline w-50" v-model="search.srchCat2Idx">
                  <option value="" >전체</option>
                  <option v-for="item in cat2List" :value="item.cat2Idx">{{item.cat2Name}}</option>
                </select>
              </td>

              <th class="text-center center">판매상태</th>
              <td>
                <select class="form-control d-inline" v-model="search.srchIsSale">
                  <option value="">선택</option>
                  <option value="1">판매중</option>
                  <option value="-1">판매중지</option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="text-center center">검색</th>
              <td class="text-center">
                <select class="form-control d-inline w-25">
                  <option value="0">전체</option>
                  <option value="1">상품번호</option>
                  <option value="2">상품명</option>
                </select>
                <input type="text" class="form-control d-inline w-75" placeholder="Search..." v-model="search.srchKeyword" @keyup.enter="onSearch"/>
              </td>
              <td colspan="2">
                <button class="btn btn-primary d-inline mr-2" @click="onSearch">검색</button>
                <button class="btn btn-light d-inline" @click="init">초기화</button>
              </td>
            </tr>
            </thead>
          </table>
        </div>
        <div class="card-body mb-0 pb-0">
          <h5 class="card-title float-left">접수 : {{totalCnt}}건</h5>
          <table class="mb-0 table table-detail">
            <thead>
            <tr>
              <th><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="selectAll"></th>
              <th>번호</th>
              <th>카테고리</th>
              <th>컬렉션타입</th>
              <th>상품번호</th>
              <th>상품명</th>
              <th>판매가</th>
              <th>판매상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!datas.length">
              <td colspan="8" class="text-center">내역이 존재하지 않습니다.</td>
            </tr>
            <tr v-for="(data, idx) in datas" :key="idx" v-else>
              <td class="text-center"><input type="checkbox" :value="data" v-model="chkList"></td>
              <td class="text-center">{{ data.prdNo }}</td>
              <td class="text-center">{{ data.cat1Name }} > {{ data.cat2Name }}</td>
              <td class="text-center">{{ data.collectionName }}</td>
              <td class="text-center">{{ data.prdNo }}</td>
              <td class="text-center">{{ data.prdName }}</td>
              <td class="text-center">{{ data.prdPriceOrg | comma }}원</td>
              <td class="text-center">{{ data.prdSaleStatus | isSaleCode }}</td>
            </tr>
            </tbody>
          </table>
          <div class="text-center mt-2 mb-3">
            <pagination :per-page="search.srchPageCnt" :pagenum="search.srchPage" :records="totalCnt" for="datas" v-on:vue-pagination::datas="onPage" v-show="datas.length"></pagination>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="text-center">
              <button class="btn btn-warning wd-100 mr-1" @click="onCancle">취소</button>
              <button class="btn btn-success wd-100 ml-1" @click="complete">적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/page/components/Pagination";
import CodeMixin from '@/mixins/code-mixin';
import {CommonMixin} from "@/components/mixins/CommonMixin";
import InterfaceMixin from "@/mixins/userInterface-mixin";
export default {
  name: "srch-product-pop",
  components: {
    Pagination
  },
  mixins: [
    InterfaceMixin, CodeMixin, CommonMixin
  ],
  computed: {
    selectAll: {
      get: function () {
        return this.datas.length ? this.chkList.length === this.datas.length : false;
      },
      set: function (value) {
        const selected = [];
        if (value) {
          this.datas.map((data, idx) => {
            selected.push(data);
          });
        }
        this.chkList = selected;
      },
    },
  },
  data () {
    return {
      datas: [],
      search: {
        srchCat1Idx: '',
        srchCat2Idx: '',
        srchIsSale: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: ''
      },
      totalCnt: 0,
      cat1List: [],
      cat2List: [],
      chkList: [],
    }
  },
  props: {
    quantityLimit: {
      type: Number
    }
  },
  mounted() {
    this.init()
    this.getCategory()
    this.getItemList()
  },
  methods: {
    init() {
      this.search.srchCat1Idx = ''
      this.search.srchCat2Idx = ''
      this.search.srchIsSale = ''
      this.search.srchKeyword = ''
      this.search.srchPage = 1
      this.search.srchPageCnt = 10
      this.search.srchType = ''
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    getItemList () {
      let params = {
        srchCat1Idx: this.search.srchCat1Idx,
        srchCat2Idx: this.search.srchCat2Idx,
        srchDateEnd: this.search.srchDateEnd,
        srchDateStart: this.search.srchDateStart,
        srchDateType: this.search.srchDateType,
        srchIsSale: this.search.srchIsSale,
        srchKeyword: this.search.srchKeyword,
        srchType: this.search.srchType,
        srchPage: this.search.srchPage,
        srchPageCnt: this.search.srchPageCnt
      }
      this.$api.api.getProductList(params).then(resp => {
        this.datas = resp.data.data
        this.totalCnt = resp.data.total
      }).catch(() => {
        this.isLoad = false
      })

      this.chkList = []
    },
    onSearch () {
      this.search.srchPage = 1
      this.getItemList()
    },
    complete () {
      if (this.chkList.length <= 0) {
        this.alert('상품을 선택해주세요.')
        return
      }
      if (this.quantityLimit === 0) {
        this.alert('더이상 상품 추가가 불가능 합니다.')
        return
      }
      if (this.quantityLimit && this.chkList.length > this.quantityLimit) {
        this.alert('최대 '+this.quantityLimit+'개 상품을 선택해주세요.')
        return
      }

      this.$emit('close', this.chkList)
    },
    onCancle () {
      this.$emit('close')
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getCategory() {
      this.$api.api.getCategory().then(resp => {
        if (resp.data.result) {
          this.cat1List = resp.data.data
        } else {
          this.alert('카테고리 조회 실패')
        }
      })
    },
    getCategory2() {
      this.search.srchCat2Idx = ''
      this.cat1List.map(el => {
        if(el.cat1Idx === this.search.srchCat1Idx) {
          this.cat2List = el.cat2List
        }
      })
    },

  }
}
</script>

<style scoped>
  .text-center.center {
    vertical-align: middle;
    width: 10%;
  }
</style>
