<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'FAQ관리'" :nav-list="['맥케이슨관리', '게시판관리', 'FAQ관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">카테고리</label>
                  <select class="form-control" v-model="search.category">
                    <option v-for="(item, idx) in catTypeList" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangePageSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox"  v-model="chkAll"/></th>
                <th class="text-center">번호</th>
                <th class="text-center">카테고리</th>
                <th class="text-center" >질문</th>
                <th class="text-center">답변</th>
                <th class="text-center">등록일시</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!resultDataList.length">
                <td colspan="11" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(item, idx) in resultDataList" :key="idx" v-else>
                <td class="text-center"><input type="checkbox" data-toggle="toggle" data-onstyle="success" v-model="chkList" :value="item.faqIdx" :id="item.faqIdx"/></td>
                <td class="text-center">{{ totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx }}</td>
                <td class="text-center">{{ item.category | categoryStr }}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="onDetail(item.faqIdx)">{{ item.question }} </a> </td>
                <td class="text-center"><div class="p">{{ item.answer }}</div></td>
<!--                <td class="text-center">{{ item.answer }}</td>-->
                <td class="text-center">{{ item.faqRgdt }}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-show="resultDataList.length"></pagination>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <button class="btn btn-danger wd-100" @click="onDelete">삭제</button>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onCreate">등록</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { CommonMixin } from '@/components/mixins/CommonMixin'
  import NavigationBar from '@/page/components/NavigationBar'
  import Pagination from "@/page/components/Pagination"
  import DatePickerButton from "@/components/common/DatePickerButton"
  import InterfaceMixin from '@/mixins/userInterface-mixin'

  export default {
    name: "FaqList",
    data () {
      return {
        search: {
          category : 0,
          srchDateStart: '',
          srchDateEnd: '',
          srchType: '',
          srchKeyword: '',
          srchPage: 1,
          srchPageCnt: 10
        },
        srchTypeList: [
          { value: '', text: '전체' },
          { value: 1, text: '질문' },
          { value: 2, text: '답변' }
        ],
        catTypeList : [
          {text : "전체", value : 0},
          {text : "결제/주문", value : 1},
          {text : "배송", value : 2},
          {text : "취소/교환/반품", value : 3},
          {text : "A/S", value : 4},
          {text : "마일리지", value : 6},
          {text : "기타", value : 5},
        ],
        srchPageSizeList: [
          { value: 10, text: '10개' },
          { value: 20, text: '20개' },
          { value: 30, text: '30개' },
          { value: 40, text: '40개' },
          { value: 50, text: '50개' }
        ],
        totalCnt: 0,
        resultDataList: [],
        chkList: []
      }
    },
    components: {
      NavigationBar,
      Pagination,
      DatePickerButton,
    },
    mixins: [
      CommonMixin, InterfaceMixin
    ],
    computed: {
      chkAll: {
        get () {
          return this.resultDataList.length ? this.chkList.length === this.resultDataList.length : false
        },
        set (value) {
          let selected = []
          if (value) {
            this.resultDataList.map(item => {
              selected.push(item.faqIdx)
            })
          }
          this.chkList = selected
          console.log("[체크리스트]"+this.chkList)
        }
      }
    },
    created () {
    },
    mounted () {
      this.init()
    },
    methods: {
      init () {
        this.onSearch()
      },
      onSearch(){
        this.$api.api.getFaqList(this.search).then(res=>{
          let  resultRes = res.data
          if(resultRes.result){
            this.resultDataList = res.data.data
            this.totalCnt = res.data.totalCnt
          }
        })
      },
      onReset () {
        this.search.srchType = ''
        this.search.srchKeyword = ''
        this.search.category = 0
      },
      onDelete(){
        if (this.chkList.length === 0) {
          this.$alert('삭제할 목록을 선택해주세요.')
          return
        }
        this.$confirm("삭제하시겠습니까?").then(res=>{
          this.$api.api.deleteFaq({faqIdxList : this.chkList}).then(res=>{
            let resData = res.data
            if(resData.result){
              this.onSearch()
            }
          })
        })
      },
      onDetail(faqIdx = 0){
        this.$router.push({name: 'FaqRegistration' , params :{faqIdx}})
      },
      onChangePageSize(){
        this.search.srchPage = 1
        this.onSearch()
      },
      onPage (srchPage) {
        this.search.srchPage = srchPage
        this.onSearch()
      },
      onCreate () {
        this.$router.push({name: 'FaqRegistration'})
      },
    },
    filters: {
      categoryStr (value) {
        switch (value) {
          case 0:
            return '전체'
          case 1:
            return '결제/주문'
          case 2:
            return '배송'
          case 3:
            return '취소/교환/반품'
          case 4:
            return 'A/S'
          case 5:
            return '기타'
          case 6:
            return '마일리지'
        }
      }
    }
  }
</script>

<style scoped>
  .p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px !important;
  }
</style>
