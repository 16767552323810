<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card card">
                <div class="card-header">
                    <div class="card-header-title">{{ text }}</div>
                    <div class="btn-actions-pane-right">
                        <button type="button" class="close float-none" @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>

                <div class="card-body">
                    <div class="col-12 p-0">
                        <button class="btn btn-danger mb-2 mr-2 wd-100" @click="onDelete">선택삭제</button>
                        <button class="btn btn-warning mb-2 mr-2 wd-100" @click="callSearchPopup">상품검색</button>
                    </div>
                    <div class="col-12 p-0">
                        <div style="max-height: 400px; overflow-y: scroll; width: 100%;">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                                        <th class="text-center">상품번호</th>
                                        <th class="text-center">상품명</th>
                                        <th class="text-center">상품명(한글)</th>
                                        <th class="text-center">원가</th>
                                        <th class="text-center">판매가</th>
                                        <th class="text-center">색상</th>
                                        <th class="text-center">카테고리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!list.length">
                                        <td class="text-center" colspan="7" >등록된 정보가 존재하지 않습니다.</td>
                                    </tr>
                                    <tr v-for="(item, itemIdx) in list" :key="itemIdx" v-else>
                                        <td class="text-center"><input type="checkbox" v-model="chkList" :value="item.prdIdx" /></td>
                                        <td class="text-center">{{ item.prdNo }}</td>
                                        <td class="text-center">{{ item.prdName }}</td>
                                        <td class="text-center">{{ item.prdNameKor }}</td>
                                        <td class="text-center">{{ item.prdPriceOrg | comma }}</td>
                                        <td class="text-center">{{ item.prdSalePrice | comma }}</td>
                                        <td class="text-center" v-html="convertColorName(getColorNameList(item.colorList))"></td>
                                        <td class="text-center">{{ item.cat1Name }} &gt; {{ item.cat2Name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="col-12 pr-0 pl-0">
                        <div class="float-left">
                        </div>
                        <div class="float-right">
                            <button class="btn btn-success ml-1 wd-100" @click="onApply">적용</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import SrchProductPop from '@/page/product/components/SrchProductPop'
import _ from 'lodash'

export default{
    name: 'GeneralProductExposeRegistration',
    mixins: [ CommonMixin, InterfaceMixin ],
    components: {
        SrchProductPop,
    },
    data () {
        return {
            addLimit: 2,
            list: [],
            chkList: [],
        }
    },
    props: {
        text: {
            type: String,
            default: '',
        }
    },
    computed: {
        chkAll: {
            get () {
                return this.list.length ? this.chkList.length === this.list.length : false
            },
            set (value) {
                let selected = []

                if (value) {
                    this.list.map((item) => {
                        selected.push(item.prdIdx)
                    })
                }
                this.chkList = selected
            },
        }
    },
    created () {
    },
    mounted () {
    },
    methods: {
        getColorNameList (arr) {
            return arr.map(a => a.colName).join(', ')
        },
        convertColorName (html) {
            // return html.replace(', ', '<br/>')
            return html
        },
        callSearchPopup () {
            this.$modal.show(
                SrchProductPop,
                {quantityLimit: this.addLimit - this.list.length},
                {draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200},
                {
                    'before-close': event => {
                        if (event.params) {
                            console.log('## list::: ', event.params)
                            event.params.map(e => {
                                let tmp = {
                                    prdIdx: e.prdIdx,
                                    prdNo: e.prdNo,
                                    prdName: e.prdName,
                                    prdNameKor: e.prdNameKor,
                                    prdPriceOrg: e.prdPriceOrg,
                                    prdSalePrice: e.prdSalePrice,
                                    cat1Name: e.cat1Name,
                                    cat2Name: e.cat2Name,
                                    colorList: e.colorList,
                                }
                                this.list.push(tmp)
                                this.chkList.push(e.prdIdx)
                            })
                            // 중복 제거
                            this.list = _.uniqBy(this.list, 'prdIdx')
                        }
                    }
                }
            )
        },
        onDelete () {
            if (!this.chkList.length) {
                this.alert('삭제할 상품을 선택해주세요.')
                return false
            }
            this.chkList.map(chk => {
                let idx = this.list.findIndex(item => {
                    return (chk === item.prdIdx)
                })
                this.list.splice(idx, 1)
            })
          this.chkList = []
        },
        onApply () {
            if (!this.chkList.length) {
                this.alert('상품을 선택해주세요.')
                return false
            }
            let array = []
            this.chkList.map(chk => {
                this.list.find(item => {
                    if (chk === item.prdIdx) {
                        array.push(item)
                    }
                })
            })
            console.log('## apply::: ', array)

            this.$emit('close', array)
        },
    },
}
</script>
