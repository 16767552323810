import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    redirect: '/',
    children: [
        {
            path: '/product/list',
            name: 'ProductList',
            component: () => import('@/page/product/ProductList')
        },
        {
            path: '/product/modify/:params?',
            name: 'ProductRegistration',
            props: (route) => ({ query: route.query }),
            component: () => import('@/page/product/ProductRegistration')

        },
        {
            path: '/displayProduct/list',
            name: 'DisplayProductList',
            component: () => import('@/page/product/DisplayProductList')
        },
        {
            path: '/discountProduct/list',
            name: 'DiscountProductList',
            component: () => import('@/page/product/DiscountProductList')
        },
    ]

}
