<template>
  <!--
  <div class="app-wrapper-footer">
    <div class="app-footer">
      <div class="app-footer__inner">
        <div class="app-footer-left">
          <ul class="nav">
            <li class="nav-item">
              <a href="javascript:void(0);" class="nav-link">
                Copyright © SmartScore 2020
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  -->
</template>

<script>
export default{
  name: 'FooterMenu',
  methods: {
  }
}
</script>

<style>
.app-footer {
  height: 100%;
  padding-top: 10px;
}
</style>
