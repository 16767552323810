<template>
    <div class="main-card mb-3 card">
        <div class="card-header font-size-lg">프로모션 공통 설정</div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="float-left">
                        <h5 class="card-title">PC 배경이미지 선택</h5>
                        <p class="text-danger">* 이전 등록 건을 포함한 모든 프로모션 템플릿 항목에 동일하게 적용됩니다.</p>
                    </div>
                    <div class="float-right">
                        <button class="btn btn-primary wd-100" @click="addData"><i class="fa fa-plus mr-2"></i>추가</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1 text-center" style="justify-content: center; align-items: center; display: flex;">
                    <h5 class="card-title">PC</h5>
                </div>
                <div class="col-md-11">
                    <div class="text-center" v-if="!bgList.length">
                        <p>등록된 정보가 존재하지 않습니다.</p>
                    </div>
                    <div class="d-inline-block text-center p-2" v-for="(data, dataIdx) in bgList" v-else>
                        <template v-if="data.idx !== ''">
                            <p class="mt-1 mb-1">배경{{ dataIdx + 1 }}</p>
                            <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                <img class="images" :src="getImagePath(data.image.thumb1)" style="width: 120px; height: 120px;" v-image-error v-viewer />
                            </div>
                            <input type="checkbox" class="mt-2 mb-2" v-model="chkList" :value="data.idx" />
                        </template>
                        <template v-else>
                            <p class="mt-1 mb-1">배경{{ dataIdx + 1 }}</p>
                            <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                <upload-photo v-if="data.image.filename === ''" branch="visual" @changed="changeImage($event, data.image)"></upload-photo>
                                <img v-else :src="getImagePath(data.image.path + '/' + data.image.filename)" class="images" v-viewer v-image-error style="width: 120px; height: 120px;" />
                            </div>
                            <i class="fa fa-times mb-2 mt-2" style="cursor: pointer;" @click="deleteRow(dataIdx)"></i>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
                <div class="float-left">
                    <button class="btn btn-danger ml-1 wd-100" @click="onDelete">선택삭제</button>
                </div>
                <div class="float-right">
                    <button class="btn btn-success ml-1 wd-100" @click="onSave">저장</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from '@/components/common/UploadPhoto'

export default {
    name: 'GeneralPromotionCommon',
    mixins: [ CommonMixin, InterfaceMixin ],
    components: {
        UploadPhoto
    },
    data () {
        return {
            bgList: [],
            chkList: [],
        }
    },
    props: {
    },
    created () {
        this.getDataList()
    },
    mounted () {
    },
    methods: {
        // 목록조회
        getDataList () {
            this.$api.api.getMngVisualPrmCommonBack().then(resp => {
                if(resp.data.result) {
                    this.bgList = []
                    resp.data.data.bgInfoList.map(bg => {
                      bg.image.path = ''
                      this.bgList.push(bg)
                    })
                }
            })
        },
        // 이미지 업로드
        changeImage (file, list) {
            list.path = file.path
            list.filename = file.name
            list.thumb1 = file.thumb1
        },
        // 추가
        addData () {
            if (this.bgList.length >= 10) {
                this.alert('최대 10개 까지만 등록 가능합니다.')
                return false
            }
            this.bgList.push({
                check: false,
                idx: '',
                seq: this.bgList.length + 1,
                image: {
                    idx: '',
                    filename: '',
                    path: ''
                },
            })
        },
        // x 삭제
        deleteRow (idx) {
            this.bgList.splice(idx, 1)
            this.reOrdering(this.bgList)
          },
        reOrdering(list) {
            list.map((item, idx) => {
                item.seq = idx + 1
            });
        },
        // 선택삭제
        onDelete () {
            if (this.chkList.length <= 0) {
                this.alert('삭제할 대상을 선택 해주세요.')
                return false
            }
            let reqBody = {
                idxList: this.chkList
            }
            this.confirm('삭제 하시겠습니까?', () =>{
                this.$api.api.delMngVisualPrmCommonBack(reqBody).then(resp => {
                    if (resp.data.result) {
                      this.alert('삭제 되었습니다.', () => {
                        this.getDataList()
                      })
                    } else {
                        this.alert(resp.data.errMsg)
                    }
                }).finally(() => {
                    this.chkList = []
                })
            })
        },
        // 저장
        onSave () {
            if (this.bgList.filter(item => item.image.filename === '').length > 0) {
              this.alert('배경이미지를 입력 해주세요.')
              return false
            }

            this.confirm('저장 하시겠습니까?', () => {
                let reqBody = this.bgList.filter(item => item.image.filename !== '').map(item => {
                  return {
                    idx: item.idx,
                    seq: item.seq,
                    filename: item.image.filename
                  }
                })
                this.blockUI()
                this.$api.api.saveMngVisualPrmCommonBack({backgroundList: reqBody}).then(resp => {
                    if (resp.status === 200) {
                        this.alert('저장 되었습니다.', () => {
                            this.getDataList()
                        })
                    } else {
                        this.alert('저장에 실패하였습니다')
                    }
                }).catch(error => {
                  console.log(error)
                }).finally(() => {
                    this.unBlockUI()
                })
            })
        },
    },
    watch: {
        bgList: {
            deep: true,
            handler(value) {
                this.$emit('changed', this.bgList)
            }
        }
    }
}
</script>
