<template>
  <div>
    <!-- [라우터뷰]. 여기에 라우터가 컴포넌트를 그린다. -->
    <!-- URL값에 따라 갱신되는 화면 영역 -->
    <router-view/>
    <!-- App.vue 가 루트 컴포넌트이므로 여기에 라우터 뷰를 추가 -->
    <!-- 경로에 따라 컴포넌트를 바꿔치기해서 렌더링. 렌더링해주는 부분이 <router-view> 태그 부분 -->
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'

export default {
  name: 'App',
  mixins: [ CommonMixin ]
}
</script>

<style lang="scss">
</style>
