var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalPages
    ? _c(
        "ul",
        { staticClass: "pagination", class: _vm.className },
        [
          _c(
            "li",
            {
              staticClass: "page-item",
              class: _vm.allowedChunk(-1) ? "" : "disabled",
              on: { click: _vm.prevChunk }
            },
            [_vm._m(0)]
          ),
          _c(
            "li",
            {
              staticClass: "page-item",
              class: _vm.allowedPage(_vm.page - 1) ? "" : "disabled",
              on: { click: _vm.prev }
            },
            [_vm._m(1)]
          ),
          _vm._l(_vm.pages, function(page) {
            return _c(
              "li",
              {
                key: page,
                staticClass: "page-item",
                class: _vm.isActive(page) ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.setPage(page)
                  }
                }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "javascript:void(0);" }
                  },
                  [_vm._v(_vm._s(page))]
                )
              ]
            )
          }),
          _c(
            "li",
            {
              staticClass: "page-item",
              class: _vm.allowedPage(_vm.page + 1) ? "" : "disabled",
              on: { click: _vm.next }
            },
            [_vm._m(2)]
          ),
          _c(
            "li",
            {
              staticClass: "page-item",
              class: _vm.allowedChunk(1) ? "" : "disabled",
              on: { click: _vm.nextChunk }
            },
            [_vm._m(3)]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "page-link",
        attrs: { href: "javascript:void(0);", "aria-label": "First" }
      },
      [
        _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(" << ")]),
        _c("span", { staticClass: "sr-only" }, [_vm._v("처음")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "page-link",
        attrs: { href: "javascript:void(0);", "aria-label": "Previous" }
      },
      [
        _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(" < ")]),
        _c("span", { staticClass: "sr-only" }, [_vm._v("이전")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "page-link",
        attrs: { href: "javascript:void(0);", "aria-label": "Next" }
      },
      [
        _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(" > ")]),
        _c("span", { staticClass: "sr-only" }, [_vm._v("다음")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "page-link",
        attrs: { href: "javascript:void(0);", "aria-label": "Last" }
      },
      [
        _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(" >> ")]),
        _c("span", { staticClass: "sr-only" }, [_vm._v("끝")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }