<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="main-card card">
        <div class="card-header">
          <div class="card-header-title">{{ text }}</div>
          <div class="btn-actions-pane-right">
            <button type="button" class="close float-none" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="position-relative form-group pr-2 float-right">
              <button class="btn btn-danger wd-100 mr-2" @click="onDelete">선택삭제</button>
              <button class="btn btn-warning wd-100" @click="callSearchPrdPopUp(10, 'body')">상품검색</button>
            </div>
            <table class="mb-0 table table-detail">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" v-model="selectAll" /></th>
                <th class="text-center">상품번호</th>
                <th class="text-center">상품명</th>
                <th class="text-center">상품명(한글)</th>
                <th class="text-center">판매가</th>
                <th class="text-center">색상</th>
                <th class="text-center">타이틀</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in form.productOptList" :key="idx">
                <td class="text-center">
                  <input type="checkbox" v-model="chkList" :value="item" />
                </td>
                <td class="text-center">{{ item.prdNo }}</td>
                <td class="text-center">{{ item.prdName }}</td>
                <td class="text-center">{{ item.prdNameKor }}</td>
                <td class="text-center">{{ item.prdSalePrice | comma }}</td>
                <td class="text-center">
                  <template v-for="color in item.colorList">{{ color.colName + " " }}</template>
                </td>
                <td class="text-center">
                  <select class="form-control" v-model="item.titleIdx">
                    <option value=0>선택</option>
                    <option v-for="item in titleTypeList" :value="item.titleIdx">{{ item.titleName }}</option>
                  </select>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <div class="col-12 pr-0 pl-0">
            <div class="float-right">
              <button class="btn btn-success wd-100" @click="onOk()">적용</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CommonMixin} from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from "@/components/common/UploadPhoto";
import SrchProductPop from "@/page/product/components/SrchProductPop";

export default {
  name: 'RegiCollectionPopup',
  data() {
    return {
      chkList: [],
      colctMainList: [],
      titleTypeList: [],
      selectedList: [],
      colorList: [],
      isEdit: false,
      isClicked: false,
      colorDatas: [],
      cat1List: [],
      cat2List: [],
      collectionType: [],
      form: {
        prdIdx: '',
        prdNo: '',
        prdName: '',
        prdNameKor: '',
        prdPrice: 0,
        cat1Idx: '',
        cat2Idx: '',
        colorIdx1: 0,
        colorIdx2: 0,
        colorIdx3: 0,
        colorIdx4: 0,
        imgArea: 0,
        imgType: 0,
        colctType: 0,
        imgInfo: {
          path: '',
          filename: '',
          thumb: '',
        },
        productOptList: [],
        colorOptList: [],
        bodyImgListData: []
      },
      colCodeList: [],
      collImageList: [],
      imageList: [],
      isOpen: false,
      datas: [],
      search: {
        srchCat1Idx: '',
        srchCat2Idx: '',
        srchIsSale: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: ''
      },
      totalCnt: 0,
      selectedColList: [],
      rowspan: 0,
      itemCnt: 0,
    }
  },
  validations: {},
  components: { UploadPhoto, SrchProductPop },
  mixins: [ CommonMixin, InterfaceMixin ],
  props: {
    text: {
      type: String,
      default: () => {
        return ''
      }
    },
    index: {
      type: String,
      default: () => {
        return ''
      }
    },
    info: {
      type: Object,
      default: () => {
        return {
          form: {
            prdIdx: '',
            prdNo: '',
            prdName: '',
            prdNameKor: '',
            prdPrice: 0,
            cat1Idx: '',
            cat2Idx: '',
            colorIdx1: 0,
            colorIdx2: 0,
            colorIdx3: 0,
            colorIdx4: 0,
            imgArea: 0,
            imgType: 0,
            colctType: 0,
            imgInfo: {
              path: '',
              filename: '',
              thumb: '',
            },
            productOptList: [
              {titleIdx: '0'}
            ],
          },
        }
      }
    },
  },
  computed: {
    selectAll: {
      get: function () {
        return this.form.productOptList.length ? this.chkList.length === this.form.productOptList.length : false;
      },
      set: function (value) {
        const selected = [];
        if (value) {
          this.form.productOptList.forEach(function (item) {
            selected.push(item);
          });
        }
        this.chkList = selected;
      },
    },
  },
  created() {
  },
  mounted() {
    this.init()
    if (this.index.order) {
      this.isEdit = true
    }
  },
  methods: {
    init() {
      this.getColctMainList();
      this.getColctTitleList();
    },
    toggle() {
      this.isClicked = !this.isClicked
    },
    getColctMainList() {
      this.$api.api.getCollectionMainList({isDisplay: this.search.srchIsDisplay}).then(resp => {
        if (resp.data.result) {
          this.colctMainList = resp.data.data
          console.log("colctMainList " + this.colctMainList)
        } else {
          this.alert('컬렉션 목록 조회 실패')
        }
      })
    },
    getColctTitleList() {
      this.$api.api.getCollectionTitleList().then(resp => {
        if (resp.data.result) {
          this.titleTypeList = resp.data.data
          console.log("titleTypeList " + this.titleTypeList)
        } else {
          this.alert(' 타이틀 목록 조회 실패')
        }
      })
    },
    callSearchPrdPopUp(cnt, flag) {
      this.$modal.show(
          SrchProductPop,
          {quantityLimit: cnt},
          {draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200},
          {
            'before-close': event => {
              console.log(event)
              if (flag === 'body') {
                event.params.map(
                    el => {
                      this.form.productOptList.map((item, i) => {
                        if (item.prdIdx === el.prdIdx) {
                          this.form.productOptList.splice(i, 1)
                        }
                      })
                      this.form.productOptList.push({titleIdx: 0, ...el})
                    }
                )
                this.checkAll()
              }
            }
          }
      );
    },
    checkAll() {
      // this.chkList = true
      const selected = [];
      this.form.productOptList.forEach(function (item) {
        selected.push(item);
      });
      this.chkList = selected;
    },
    onDelete() {
      if (this.chkList.length <= 0) {
        this.alert('삭제할 대상을 선택해주세요.')
        return
      }
      this.chkList.map(items => {
        this.form.productOptList.map((el, i) => {
          if( items.prdIdx === el.prdIdx) {
            this.form.productOptList.splice(i,  1)
          }
        })
      })
      this.ordering()
      this.chkList = []
    },
    ordering() {
      this.form.productOptList.map ((el, i) => {
        el.order = i+1
      })
    },
    reOrdering (list) {
      list.map((item, index) => {
        item.order = index+1
      })
    },
    reorder() {
      this.form.colorOptList.map((el, i) => {
        el.optOrder = i + 1
      })
    },
    onOk() {
      if (!this.form.productOptList.length) {
        this.alert('상품을 등록 해주세요.')
        return
      }
      if (this.chkList.length <= 0) {
        this.alert('등록하실 상품을 선택해주세요.')
        return
      }
      let ttName = this.chkList.map(data => {
        return data.titleIdx
      })
      console.log("ttName >>>> " + ttName)
      if (ttName.includes(0)) {
        this.alert('해당하는 타이틀을 선택해주세요.')
        return
      }
      if (this.chkList.length > 2) {
        this.alert('최대 2개까지 등록하실 수 있습니다.')
        return
      }
      this.confirm('적용 하시겠습니까?', () => {
        let rtnObj = {
          colctIdx: 0,
          filename: '',
          colctOrder: '',
          colctType: this.$route.query.idx,
          productList: [],
        }
        for (let item of this.chkList) {
          let opt1 = {
            titleIdx: item.titleIdx,
            titleName: this.findTitleName(item.titleIdx),
            prdIdx: item.prdIdx,
            prdNo: item.prdNo,
            prdName: item.prdName,
            prdNameKor: item.prdNameKor,
            prdSalePrice: item.prdSalePrice,
            cat2Name: item.cat2Name,
            colorList:[]
          }
          for(let col of item.colorList) {
            opt1.colorList.push({
              colIdx: col.colIdx,
              colCode: col.colCode,
              colName: col.colName,
              colShortCode: col.colShortCode
            })
          }
          rtnObj.productList.push(opt1)
        }
        this.$emit('close', {
          optList: rtnObj
        });
      })
    },
    findTitleName(idx) {
      let selectTitleObj = this.titleTypeList.find(e => e.titleIdx === idx);
      return selectTitleObj.titleName;
    },
    onUpdate() {
      this.$emit('close', this.regiInfo)
    },
    getCollectionType() {
      this.$api.api.getCollectionType().then(resp => {
        if (resp.data.result) {
          this.collectionType = resp.data.data
          console.log("collectionType" + this.collectionType)
        } else {
          this.alert('컬렉션 타입 조회 실패')
        }
      })
    },
    getCategory() {
      this.$api.api.getCategory().then(resp => {
        if (resp.data.result) {
          this.cat1List = resp.data.data
          let arr = [];
          this.cat1List.map(x => {
            x.cat2List.map(y => {
              y.cat1Idx = x.cat1Idx
              arr.push(y)
            })
          })
          this.cat2List = arr
        } else {
          this.alert("카테고리1 목록 조회 실패")
        }
      })
    },
    getColorList() {
      this.$api.api.getColorList().then(resp => {
        if (resp.data.result) {
          this.colorDatas = resp.data.data.data
        }
      })
    },
    open() {
      return new Promise(resolve => {
        this.isOpen = true
      })
    },
    close() {
      this.isOpen = false
      this.$destroy(true)
    },
  }
}
</script>

<style scoped>
</style>
