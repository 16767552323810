var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-card mb-3 card" }, [
    _c("div", { staticClass: "card-header font-size-lg" }, [_vm._v("컬렉션")]),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "float-left" }, [
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-12 m-2" }, [
                  _vm._m(0),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.collectionIdx,
                          expression: "collectionIdx"
                        }
                      ],
                      staticClass: "custom-select wd-200 mr-1",
                      attrs: { disabled: this.idx !== "" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.collectionIdx = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                      _vm._l(_vm.collectTypeList, function(collect, selectIdx) {
                        return _c(
                          "option",
                          { key: selectIdx, domProps: { value: collect.idx } },
                          [_vm._v(_vm._s(collect.collectionName))]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 m-2" },
                  [
                    _vm._m(1),
                    _vm.pcImg.filename === ""
                      ? _c("upload-photo", {
                          attrs: { branch: "visual" },
                          on: {
                            changed: function($event) {
                              return _vm.changeImage($event, _vm.pcImg)
                            }
                          }
                        })
                      : _c("img", {
                          directives: [
                            { name: "image-error", rawName: "v-image-error" },
                            { name: "viewer", rawName: "v-viewer" }
                          ],
                          staticClass: "images",
                          staticStyle: { width: "120px" },
                          attrs: {
                            src: _vm.getImagePath(
                              _vm.pcImg.path + "/" + _vm.pcImg.filename
                            )
                          }
                        })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("general-collection-table", {
          attrs: {
            title: "상세 이미지(PC)",
            list: _vm.collList1,
            "section-type": 1
          },
          on: {
            "update:list": function($event) {
              _vm.collList1 = $event
            },
            reload: _vm.getDataList
          }
        }),
        _c("general-collection-table", {
          attrs: {
            title: "상세 이미지(Mobile)",
            list: _vm.collList2,
            "section-type": 2
          },
          on: { reload: _vm.getDataList }
        })
      ],
      1
    ),
    _c("div", { staticClass: "card-footer" }, [
      _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
        _c("div", { staticClass: "float-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success ml-1 wd-100",
              on: { click: _vm.onSave }
            },
            [_vm._v("저장")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      {
        staticClass:
          "card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-150"
      },
      [_c("span", { staticClass: "required" }, [_vm._v("*")]), _vm._v("타이틀")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      {
        staticClass:
          "card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-150"
      },
      [
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("PC 대표이미지")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }