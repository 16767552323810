import Vue from 'vue'

// 일반영역 전시상태
Vue.filter('displayStatus', (value) => {
    switch (value) {
        case 0: return '전시안함'
        case 1: return '전시중'
        default: return value
    }
})
