<template>
  <div class="error_page_wrapper">
    <div class="error_wrap">
      <div class="container text-center">
        <div class="error_heading text-center">
          <h3 class="headline font-danger">503</h3>
        </div>
        <h3 class="text-uppercase error-subtitle">Service Unavailable !</h3>
        <div class="col-md-8 offset-md-2 text-center">
          <p class="mb-0 mt-0">요청 처리 중 서버에 오류가 발생했습니다.</p>
          <p class="mt-0">다시 시도 하시기바랍니다.</p>
        </div>
        <div class="error_btn text-center">
          <a class="btn btn-danger" href="javascript:void(0)" @click="$router.push('/login')">Back Home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error503',
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
