var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "card-header-title" }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _c("div", { staticClass: "btn-actions-pane-right" }, [
            _c(
              "button",
              {
                staticClass: "close float-none",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ])
        ]),
        _c(
          "div",
          {
            staticStyle: {
              "max-height": "800px",
              "overflow-y": "scroll",
              width: "100%"
            }
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title" }, [_vm._v("유입경로")]),
              _c("table", { staticClass: "mb-0 table table-detail" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.order.admOrderDetailInfo.accessMethod))
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.dateFormat(
                            _vm.order.admOrderDetailInfo.ordRgdt,
                            "YYYY-MM-DD HH:mm"
                          )
                        )
                      ),
                      _c("br"),
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm.dateFormat(
                              _vm.order.admOrderDetailInfo.payDt,
                              "YYYY-MM-DD HH:mm"
                            )
                          ) +
                          ")"
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.order.admOrderDetailInfo.ordNo))
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(
                            _vm.order.admOrderDetailPayInfo.payAmount
                          )
                        ) + "원"
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("payKind")(
                            _vm.order.admOrderDetailPayInfo.payKind
                          )
                        )
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskName(_vm.order.admOrderDetailOrder.userName)
                        )
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskName(
                            _vm.order.admOrderDetailRecepient.recipient
                          )
                        )
                      )
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.callMemoPop(
                                _vm.order.ordIdx,
                                _vm.order.admOrderDetailInfo.ordNo
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.order.admOrderDetailInfo.memoCnt))]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title" }, [_vm._v("상품정보")]),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "250px",
                    "overflow-y": "scroll",
                    width: "100%"
                  }
                },
                [
                  _c("table", { staticClass: "mb-0 table table-detail" }, [
                    _vm._m(1),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.order.admOrderDetailOptions, function(
                          product,
                          idx
                        ) {
                          return _c("tr", [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(product.ordNo + "_" + product.opoIdx)
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(product.prdNo))
                            ]),
                            _c("td", { staticClass: "text-left" }, [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(product.ordProduct))
                              ]),
                              _c(
                                "span",
                                { staticClass: "mb-0 font-color-blue" },
                                [
                                  _vm._v(
                                    "옵션1 : " + _vm._s(product.opoColName)
                                  ),
                                  product.opoSize
                                    ? [
                                        _vm._v(
                                          " / 옵션2 : " +
                                            _vm._s(product.opoSize)
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm._f("comma")(product.quantity)))
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(_vm._f("comma")(product.optPriceSale)) +
                                  "원"
                              )
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                style: _vm.order.admOrderDetailInfo.memIdx
                                  ? "color:#B40404"
                                  : ""
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("comma")(product.prdAmount)) +
                                    "원"
                                )
                              ]
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(_vm._f("ordStatus")(product.ordStatus))
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("odvStatus")(
                                    product.opoPurchaseConfirm === "Y"
                                      ? "구매확정"
                                      : product.odvStatus
                                  )
                                )
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("cancelStatus")(product.cancelStatus)
                                )
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("exchangeStatus")(
                                    product.exchangeStatus
                                  )
                                )
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("returnStatus")(product.returnStatus)
                                )
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("refundStatus")(product.refundStatus)
                                )
                              )
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              product.opoPurchaseConfirm === "Y"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger wd-75",
                                      on: {
                                        click: function($event) {
                                          return _vm.doPurchaseReset(product)
                                        }
                                      }
                                    },
                                    [_vm._v("적용")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        }),
                        _vm.giftList.length > 0
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { color: "#B40404" },
                                  attrs: { colspan: "13" }
                                },
                                [
                                  _vm._v(
                                    " [사은품] " + _vm._s(_vm.strGift) + " "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title" }, [_vm._v("결제정보")]),
              _c("table", { staticClass: "mb-0 table table-detail" }, [
                _c("tbody", [
                  _vm._m(2),
                  _c("tr", [
                    _c("th", [_vm._v("결제금액")]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(
                            _vm.order.admOrderDetailPayInfo.payAmount
                          )
                        ) + "원"
                      )
                    ]),
                    _c(
                      "th",
                      { staticClass: "text-center", attrs: { rowspan: "3" } },
                      [_vm._v("상품 금액 합")]
                    ),
                    _c(
                      "td",
                      { staticClass: "text-center", attrs: { rowspan: "3" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("comma")(
                              _vm.order.admOrderDetailPayInfo.onlyPrdAmount
                            )
                          ) + "원"
                        )
                      ]
                    ),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("배송비 합")
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(
                            _vm.order.admOrderDetailPayInfo.deliFeeSum
                          )
                        ) + "원"
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("결제수단")]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("payKind")(
                            _vm.order.admOrderDetailPayInfo.payKind
                          )
                        )
                      )
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("기본배송비")
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(
                            _vm.order.admOrderDetailPayInfo.deliFee
                          )
                        ) + "원"
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("세부정보")]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm.order.admOrderDetailPayInfo.accountName) +
                          " / " +
                          _vm._s(_vm.order.admOrderDetailPayInfo.accountNo)
                      )
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v("도서산간")
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(
                            _vm.order.admOrderDetailPayInfo.deliAddfee
                          )
                        ) + "원"
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title" }, [_vm._v("주문자 정보")]),
              _c("table", { staticClass: "mb-0 table table-detail" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "w-15" }, [_vm._v("성명")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskName(_vm.order.admOrderDetailOrder.userName)
                        )
                      )
                    ]),
                    _c("th", { staticClass: "w-15" }, [_vm._v("구매횟수")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("comma")(_vm.order.admOrderDetailOrder.buyCnt)
                        )
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "w-15" }, [_vm._v("전화번호")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dash")(
                            _vm.maskPhone(
                              _vm.order.admOrderDetailOrder.userPhone
                            )
                          )
                        )
                      )
                    ]),
                    _c("th", { staticClass: "w-15" }, [_vm._v("이메일")]),
                    _c("td", { staticClass: "w-13" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskEmail(_vm.order.admOrderDetailOrder.userEmail)
                        )
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title" }, [_vm._v("수령자 정보")]),
              _c("table", { staticClass: "mb-0 table table-detail" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "w-15" }, [_vm._v("성명")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(
                        _vm._s(
                          _vm.maskName(
                            _vm.order.admOrderDetailRecepient.recipient
                          )
                        )
                      )
                    ]),
                    _c("th", { staticClass: "w-15" }, [_vm._v("연락처")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dash")(
                            _vm.maskPhone(
                              _vm.order.admOrderDetailRecepient.recipientPhone
                            )
                          )
                        )
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "w-15" }, [_vm._v("배송지")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(
                        "(" +
                          _vm._s(_vm.order.admOrderDetailRecepient.postalCode) +
                          ") " +
                          _vm._s(_vm.order.admOrderDetailRecepient.addr1) +
                          " " +
                          _vm._s(_vm.order.admOrderDetailRecepient.addr2)
                      )
                    ]),
                    _c("th", { staticClass: "w-15" }, [_vm._v("요청사항")]),
                    _c("td", { staticClass: "w-35" }, [
                      _vm._v(_vm._s(_vm.order.admOrderDetailRecepient.comment))
                    ])
                  ])
                ])
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning wd-100",
                  on: { click: _vm.onCancle }
                },
                [_vm._v("확인")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("유입경로")]),
        _c("th", [_vm._v("주문일(결제일)")]),
        _c("th", [_vm._v("주문번호")]),
        _c("th", [_vm._v("결제금액")]),
        _c("th", [_vm._v("결제수단")]),
        _c("th", [_vm._v("주문자")]),
        _c("th", [_vm._v("수령자")]),
        _c("th", [_vm._v("메모")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("상품별 주문번호")]),
        _c("th", [_vm._v("상품번호")]),
        _c("th", { staticClass: "w-25" }, [_vm._v("상품명/옵션")]),
        _c("th", [_vm._v("수량")]),
        _c("th", [_vm._v("판매가")]),
        _c("th", [_vm._v("상품금액")]),
        _c("th", [_vm._v("결제상태")]),
        _c("th", [_vm._v("배송상태")]),
        _c("th", [_vm._v("취소상태")]),
        _c("th", [_vm._v("교환상태")]),
        _c("th", [_vm._v("반품상태")]),
        _c("th", [_vm._v("환불상태")]),
        _c("th", [_vm._v("확정취소")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("결제정보")]),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("상품정보")]),
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("배송비")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }