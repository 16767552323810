var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "card-header-title" }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _c("div", { staticClass: "btn-actions-pane-right" }, [
            _c(
              "button",
              {
                staticClass: "close float-none",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ])
        ]),
        _c(
          "div",
          [
            _c("vue-daum-postcode", {
              staticStyle: { height: "445px", "overflow-y": "scroll" },
              on: { complete: _vm.complete }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }