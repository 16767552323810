import DefaultLayout from '@/layout/DefaultLayout'

export default {
  path: '/dashboard',
  component: DefaultLayout,
  redirect: '/dashboard',
  children: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: 'Dashboard' */'@/page/dashboard/Dashboard')
    }
  ]
}
