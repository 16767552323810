import LoginLayout from '@/layout/LoginLayout'

export default {
  path: '/login',
  component: LoginLayout,
  redirect: '/login',
  children: [{
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'Login' */'@/page/login/Login')
  }]
}
