var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "상품문의관리",
            "nav-list": ["맥케이슨관리", "게시판관리", "상품문의관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("분류")
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm._v(" " + _vm._s(_vm.request.category.text) + " ")
                ])
              ]),
              _vm.request.category.value === 1
                ? [
                    _c(
                      "div",
                      { staticClass: "position-relative row form-group" },
                      [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 col-form-label" },
                          [_vm._v("상품번호")]
                        ),
                        _c("div", { staticClass: "col-sm-8" }, [
                          _vm._v(" " + _vm._s(_vm.request.prdOrdIdx) + " ")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "position-relative row form-group" },
                      [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 col-form-label" },
                          [_vm._v("상품정보")]
                        ),
                        _c("div", { staticClass: "col-sm-8" }, [
                          _c("img", {
                            directives: [
                              { name: "image-error", rawName: "v-image-error" },
                              { name: "viewer", rawName: "v-viewer" }
                            ],
                            staticClass: "image",
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: _vm.getImagePath(_vm.prdImg) }
                          }),
                          _vm._v(" " + _vm._s(_vm.prdName) + " ")
                        ])
                      ]
                    )
                  ]
                : _vm.request.category.value === 2 ||
                  _vm.request.category.value === 3
                ? [
                    _c(
                      "div",
                      { staticClass: "position-relative row form-group" },
                      [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 col-form-label" },
                          [_vm._v("주문번호")]
                        ),
                        _c("div", { staticClass: "col-sm-8" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.callOrderDetailPop(
                                    _vm.request.prdOrdIdx
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.request.prdOrdIdx))]
                          )
                        ])
                      ]
                    )
                  ]
                : _vm.request.category.value === 4
                ? [
                    _c(
                      "div",
                      { staticClass: "position-relative row form-group" },
                      [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 col-form-label" },
                          [_vm._v("취소번호")]
                        ),
                        _c("div", { staticClass: "col-sm-8" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.callClaimDetailPop(
                                    _vm.request.prdOrdIdx,
                                    "취소",
                                    "cancel"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.request.prdOrdIdx))]
                          )
                        ])
                      ]
                    )
                  ]
                : _vm.request.category.value === 5
                ? [
                    _c(
                      "div",
                      { staticClass: "position-relative row form-group" },
                      [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 col-form-label" },
                          [_vm._v("반품번호")]
                        ),
                        _c("div", { staticClass: "col-sm-8" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.callClaimDetailPop(
                                    _vm.request.prdOrdIdx,
                                    "반품",
                                    "return"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.request.prdOrdIdx))]
                          )
                        ])
                      ]
                    )
                  ]
                : _vm.request.category.value === 6
                ? [
                    _c(
                      "div",
                      { staticClass: "position-relative row form-group" },
                      [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 col-form-label" },
                          [_vm._v("교환번호")]
                        ),
                        _c("div", { staticClass: "col-sm-8" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.callClaimDetailPop(
                                    _vm.request.prdOrdIdx,
                                    "교환",
                                    "exchange"
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.request.prdOrdIdx))]
                          )
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("회원명")
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.request.cliName === ""
                          ? "비회원"
                          : _vm.request.cliName
                      ) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("이메일")
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm._v(" " + _vm._s(_vm.request.email) + " ")
                ])
              ]),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("휴대전화번호")
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm._v(" " + _vm._s(_vm.request.phone) + " ")
                ])
              ]),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("제목")
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _vm._v(" " + _vm._s(_vm.request.title) + " ")
                ])
              ]),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("질문")
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.request.question,
                        expression: "request.question"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "질문을 입력하세요.(최소10자 최대250자)",
                      rows: "5",
                      minlength: "10",
                      maxlength: "250",
                      readonly: ""
                    },
                    domProps: { value: _vm.request.question },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.request, "question", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.request.answer,
                        expression: "request.answer"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "답변을 입력해주세요.(최대500자)",
                      rows: "5",
                      maxlength: "500"
                    },
                    domProps: { value: _vm.request.answer },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.request, "answer", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "position-relative row form-group" }, [
                _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v("이미지")
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning wd-125 mr-1",
                      on: { click: _vm.addImgItem }
                    },
                    [_vm._v("이미지 항목 추가")]
                  )
                ])
              ]),
              _vm._l(_vm.request.imgList, function(item, idx) {
                return _c(
                  "div",
                  { staticClass: "position-relative row form-group" },
                  [
                    _c("label", { staticClass: "col-sm-2 col-form-label" }, [
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      _vm._v("이미지" + _vm._s(idx + 1))
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("upload-photo", {
                          attrs: {
                            file: {
                              src: _vm.getImagePath(item.thumb1),
                              w: 500,
                              h: 500
                            },
                            branch: "qna"
                          },
                          on: {
                            changed: function($event) {
                              return _vm.changedOk($event, idx)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "deleteButton",
                                fn: function() {
                                  return [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary mt-2 ml-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteImg(idx)
                                          }
                                        }
                                      },
                                      [_vm._v("삭제")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 text-center mb-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary wd-100 mr-1",
            on: { click: _vm.onList }
          },
          [_vm._v("목록")]
        ),
        _vm.request.qnaIdx !== ""
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary wd-100 ml-1",
                on: { click: _vm.onUpdate }
              },
              [_vm._v("수정")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("상품문의관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("답변")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }