<template>
  <div>
    <date-picker v-model="startDt" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" :disabled-date="notBeforeToday" />
    <label class="m-1">~</label>
    <date-picker v-model="endDt" value-type="format" format="YYYY-MM-DD" :editable="false" :clearable="false" :disabled-date="notAfterToday" />
    <template v-if="buttonUse">
      <template v-if="buttonType === 'date' || buttonType === 'month' || buttonType === 'year'">
        <button class="btn btn-light wd-100 form-control m-1" @click="setDate('date', 0)">오늘</button>
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('date', 1)">어제</button>
        <!--
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('date', 6)">일주일</button>
        -->
      </template>
      <template v-if="buttonType === 'month' || buttonType === 'year'">
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('month', 1)" v-show="searchPeriod.amount >= 1">1개월</button>
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('month', 3)" v-show="searchPeriod.amount >= 3">3개월</button>
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('month', 6)" v-show="searchPeriod.amount >= 6">6개월</button>

      </template>
      <template v-if="buttonType === 'year'">
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('year', 1)" v-show="searchPeriod.amount >= 12">1년</button>
      </template>
      <template v-if="buttonType === 'year'">
        <button class="btn btn-light wd-100 form-control mr-1" @click="setDate('year', 10)" v-show="searchPeriod.amount >= 120">10년</button>
      </template>
    </template>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'

export default {
  name: 'date-picker-button',
  data () {
    return {
      startDt: '',
      endDt: ''
    }
  },
  components: {
    DatePicker
  },
  props: {
    startDate: {
      type: String,
      default: () => {
        return ''
      }
    },
    endDate: {
      type: String,
      default: () => {
        return ''
      }
    },
    buttonUse: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    buttonType: {
      type: String,
      default: () => {
        return ''
      }
    },
    minDate: {
      type: String,
      default: () => {
        return ''
      }
    },
    searchPeriod: {
      type: Object,
      default: () => {
        return ''
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    setDate (period, value) {
      let st = ''
      let ed = ''
      let now = new Date()
      ed = now

      switch (period) {
        case 'date':
          st = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - value)).format('YYYY-MM-DD')
          break
        case 'month':
          st = moment(new Date(now.getFullYear(), now.getMonth() - value, now.getDate() - 1)).format('YYYY-MM-DD')
          break
        case 'year':
          st = moment(new Date(now.getFullYear() - value, now.getMonth(), now.getDate() - 1)).format('YYYY-MM-DD')
          break
        default:
          break
      }
      this.startDt = st
      this.endDt = moment(ed).format('YYYY-MM-DD')
    },
    notBeforeToday (date) {
      return moment(date).format('YYYY-MM-DD') > moment(this.endDate).format('YYYY-MM-DD')
          || moment(date).format('YYYY-MM-DD') < moment(this.endDate).add(-this.searchPeriod.amount, this.searchPeriod.unit).add(-1, 'd').format('YYYY-MM-DD')
    },
    notAfterToday (date) {
      return moment(date).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD') || moment(date).format('YYYY-MM-DD') < moment(this.startDate).format('YYYY-MM-DD')
    }
  },
  watch: {
    startDate (value) {
      this.startDt = value
    },
    endDate (value) {
      this.endDt = value
    },
    startDt () {
      this.$emit('inputStartDate', this.startDt)
    },
    endDt () {
      this.$emit('inputEndDate', this.endDt)
    }
  }
}

</script>
