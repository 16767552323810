<template>
  <div>
    <div class="app-page-title">
        <navigation-bar :nav-title="'구매후기관리'" :nav-list="['맥케이슨관리', '게시판관리','구매후기관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">구매후기 상세</div>
          </div>
          <div class="card-body" v-if="detail.pcsImgListData.length">
            <div>
              <div class="images" v-viewer>
                <img v-for="(src, index) in detail.pcsImgListData" class="image" :key="index" :src="getImageFullpath(src.path, src.thumb1)">
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">주문번호</th>
                  <td class="w-35 text-left">{{ detail.ordNo }}</td>
                  <th class="w-15">상품명</th>
                  <td class="w-35 text-left">{{ detail.oprPrdName }}<br>{{ detail.opoPrdName }}</td>
                </tr>
                <tr>
                  <th class="w-15">회원명</th>
                  <td class="w-35 text-left">{{ detail.userName }}</td>
                  <th class="w-15">회원ID</th>
                  <td class="w-35 text-left">{{ detail.id }}</td>
                </tr>
                <tr>
                  <th class="w-15">구매후기 점수</th>
                  <td class="w-35 text-left">{{ detail.score }}</td>
                  <th class="w-15">구매후기 등록일</th>
                  <td class="w-35 text-left">{{ detail.ordRgdt }}</td>
                </tr>
                <tr>
                  <th class="w-15">구매후기 내용</th>
                  <td class="w-85 text-left" v-html="detail.contents"></td>
                </tr>
                <tr>
                  <th class="w-15">노출여부</th>
                  <td class="w-35 text-left">
                    <input type="radio" id="isUse1" name="isUse" class="custom-radio" :checked="detail.pcsIsUse === 1" value="1" v-model="detail.pcsIsUse" @change="changeIsUse('1')" /><label class="ml-1" for="isUse1">전시</label>
                    <input type="radio" id="isUse0" name="isUse" class="custom-radio ml-5" :checked="detail.pcsIsUse === 0" value="0" v-model="detail.pcsIsUse" @change="changeIsUse('0')" /><label class="ml-1" for="isUse0">미전시</label>
                  </td>
                  <th class="w-15">포인트 적립</th>
                  <td class="w-35 text-left">
                    <input type="number" class="form-control d-inline-block w-75" placeholder="포인트를 입력해주세요." v-model="detail.point" v-if="orgPoint > 0" disabled />
                    <input type="number" class="form-control d-inline-block w-75" placeholder="포인트를 입력해주세요." v-model="detail.point" v-else />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <button class="btn btn-primary wd-100 ml-1" @click="onUpdate">수정</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { CommonMixin } from '@/components/mixins/CommonMixin'
    import NavigationBar from '@/page/components/NavigationBar'
    import InterfaceMixin from '@/mixins/userInterface-mixin'
    import 'viewerjs/dist/viewer.css'

  export default {
    name: "PurchaseReviewDetail",
    data () {
        return {
          pcsIdx: '',
          detail: {
            id: '',
            score: '',
            contents: '',
            ordIdx: '',
            ordNo: '',
            cliIdx: '',
            userName: '',
            userPhone: '',
            userEmail: '',
            ordRgdt: '',
            oprIdx: '',
            prdIdx: '',
            pcsIsUse: '',
            pcsIsDel: '',
            oprPrdName: '',
            opoPrdName: '',
            opoIdx: '',
            optIdx1: '',
            optIdx2: '',
            opoPurchaseConfirm: '',
            opoPurchaseConfirmDt: '',
            point: 0,
            thumb1: '',
            pcsImgListData: []
          },
          orgPoint: 0,
          images: [
            "https://picsum.photos/200/200",
            "https://picsum.photos/300/200",
            "https://picsum.photos/250/200"
          ]
        }
    },
    components: {
      NavigationBar
    },
    mixins: [
        CommonMixin, InterfaceMixin
    ],
    created () {
      if(this.$route.params.pcsIdx) {
        this.pcsIdx = this.$route.params.pcsIdx
        this.getPurchaseReviewDetail(this.pcsIdx)
      }
    },
    mounted () {
    },
    methods: {
      getPurchaseReviewDetail (pcsIdx) {
        let body = {
          pcsIdx: pcsIdx
        }
        this.$api.api.getPurchaseReviewDetail(body).then(res => {
          if (res.data.result) {
            this.detail.id = res.data.data.id
            this.detail.score = res.data.data.score
            this.detail.contents = res.data.data.contents
            this.detail.ordIdx = res.data.data.ordIdx
            this.detail.ordNo = res.data.data.ordNo
            this.detail.cliIdx = res.data.data.cliIdx
            this.detail.userName = res.data.data.userName
            this.detail.userPhone = res.data.data.userPhone
            this.detail.userEmail = res.data.data.userEmail
            this.detail.ordRgdt = res.data.data.ordRgdt
            this.detail.oprIdx = res.data.data.oprIdx
            this.detail.prdIdx = res.data.data.prdIdx
            this.detail.pcsIsUse = res.data.data.pcsIsUse
            this.detail.pcsIsDel = res.data.data.pcsIsDel
            this.detail.oprPrdName = res.data.data.oprPrdName
            this.detail.opoPrdName = res.data.data.opoPrdName
            this.detail.opoIdx = res.data.data.opoIdx
            this.detail.optIdx1 = res.data.data.optIdx1
            this.detail.optIdx2 = res.data.data.optIdx2
            this.detail.opoPurchaseConfirm = res.data.data.opoPurchaseConfirm
            this.detail.opoPurchaseConfirmDt = res.data.data.opoPurchaseConfirmDt
            this.detail.point = res.data.data.point
            this.detail.thumb1 = res.data.data.thumb1
            this.detail.pcsImgListData = res.data.data.pcsImgListData
            this.orgPoint = res.data.data.point
          } else {
            this.$alert('구매후기 상세 조회에 실패하였습니다.')
          }
        }).catch(() => {
          console.log('getPurchaseReviewDetail err')
        })
      },
      onList () {
        this.$router.push({name: 'PurchaseReviewList'})
      },
      onUpdate () {
        if (this.detail.point < 0) {
          this.$alert('포인트는 양수로 입력해주세요.')
          return
        }
        if (!Number.isInteger(parseInt(this.detail.point))) {
          this.$alert('포인트는 정수로 입력해주세요.')
          return
        }
        if (this.orgPoint > this.detail.point) {
          this.$alert('기존 적립포인트 보다 작은포인트로 수정할 수 없습니다.')
          return
        }

        let body = {
          pcsIdx: this.pcsIdx,
          cliIdx: this.detail.cliIdx,
          pcsIsUse: this.detail.pcsIsUse,
          point: this.detail.point
        }
        console.log('body ::: ', JSON.stringify(body))
        this.$api.api.updatePurchaseReview(body).then(res => {
          if (res.data.result) {
            this.alert("구매후기 수정에 성공하였습니다.")
            this.onList()
          } else {
            this.$alert('구매후기 수정에 실패하였습니다.')
            this.onList()
          }
        }).catch(() => {
          console.log('getPurchaseReviewDetail err')
        })
      },
      changeIsUse (value) {
        this.detail.pcsIsUse = value
      }
    }
  }
</script>

<style scoped>
  .image {
    width: 300px;
    height: 200px;
    cursor: pointer;
    margin: 10px;
    display: inline;
  }
</style>
