<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'교환관리'" :nav-list="['맥케이슨관리', '주문관리', '교환관리']" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <ul class="nav nav-tabs nav-justified">
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.cpoStatus === 11 ? 'active' : '' ]" @click="onActiveTab(11)">교환접수</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-200" :class="[ search.cpoStatus === 12 ? 'active' : '' ]" @click="onActiveTab(12)">교환처리중_수거전</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-200" :class="[ search.cpoStatus === 13 ? 'active' : '' ]" @click="onActiveTab(13)">교환처리중_수거완료</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.cpoStatus === 14 ? 'active' : '' ]" @click="onActiveTab(14)">교환배송중</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.cpoStatus === 17 ? 'active' : '' ]" @click="onActiveTab(17)">교환접수취소</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.cpoStatus === 16 ? 'active' : '' ]" @click="onActiveTab(16)">교환불가</a></li>
              <li class="nav-item"><a href="javascript:void(0)" class="nav-link wd-150" :class="[ search.cpoStatus === 15 ? 'active' : '' ]" @click="onActiveTab(15)">교환완료</a></li>
            </ul>

            <div class="form-row">
              <div class="col-md-12">
                <div class="position-relative form-group">
                  <label class="">기간검색</label>
                  <date-picker-button @inputStartDate="setDateStart" @inputEndDate="setDateEnd" :start-date="search.srchDateStart" :end-date="search.srchDateEnd" :button-use="true" button-type="year" :search-period="{amount: 12, unit: 'M'}" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">접수처</label>
                  <select class="form-control" v-model="search.clmWorker">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchClmWorkerMap" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">교환사유</label>
                  <select class="form-control" v-model="search.clmCd">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchClmCdMap" :value="item.rmIdx" :key="idx">{{ item.reasonSubtitle }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">책임여부</label>
                  <select class="form-control" v-model="search.clmCharge">
                    <option value="">전체</option>
                    <option v-for="(item, idx) in srchClmChargeMap" :value="item.value" :key="idx">{{ item.text }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative">
                  <label class="">검색조건</label>
                  <select class="form-control" v-model="search.srchType">
                    <option value="">전체</option>
                    <option v-for="item in srchTypeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-10">
                <div class="position-relative mt-2">
                  <label class="">&nbsp;</label>
                  <input type="text" v-model="search.srchKeyword" class="mr-2 form-control" placeholder="Search..." @keyup.enter="onSearch">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="onSearch">검색</button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">초기화</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">건수 : {{ totalCnt }} 건</h5>
              </div>
              <div class="float-right">
                <select type="select" class="custom-select wd-175 mr-1 exchangeStatus" v-model.number="selectStatus" v-if="search.cpoStatus != 15 && search.cpoStatus != 17" :disabled="search.cpoStatus === 14 || search.cpoStatus === 16">
                  <option value="">선택해주세요</option>
                  <option value="11" v-if="search.cpoStatus != 11 && search.cpoStatus != 12 && search.cpoStatus != 13 && search.cpoStatus != 14">교환접수</option>
                  <option value="12" v-if="search.cpoStatus != 12 && search.cpoStatus != 13 && search.cpoStatus != 14">교환처리중[수거전]</option>
                  <option value="13" v-if="search.cpoStatus != 13 && search.cpoStatus != 14">교환처리중[수거완료]</option>
                  <option value="14">교환처리중[배송중]</option>
                  <option value="17" v-if="search.cpoStatus != 13 && search.cpoStatus != 14">교환접수취소</option>
                  <option value="16" v-if="search.cpoStatus != 11 && search.cpoStatus != 12 && search.cpoStatus != 14">교환불가</option>
                  <option value="15" v-if="search.cpoStatus != 11 && search.cpoStatus != 12 && search.cpoStatus != 13">교환완료</option>
                </select>
                <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="onChangeStatus" v-if="search.cpoStatus != 15 && search.cpoStatus != 17">변경저장</button>
                <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="onBundlePop" v-if="search.cpoStatus == 11 || search.cpoStatus == 12 || search.cpoStatus == 16">묶음배송</button>
                <button class="btn btn-secondary wd-150 ml-1 mr-1" @click="onInvoiceNumberPop" v-if="search.cpoStatus != 17 && search.cpoStatus != 15">송장번호 등록/수정</button>
                <button class="btn btn-success wd-150 ml-1 mr-1" @click="exportExcel">엑셀다운로드</button>
                <select type="select" class="custom-select wd-100 ml-1" v-model="search.srchPageCnt" @change="onChangeSize">
                  <option v-for="item in srchPageSizeList" :value="item.value" :key="item.value">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="text-center"><input type="checkbox" @click="checkedAll" v-model="allSelected" /></th>
                <th class="text-center">NO</th>
                <th class="text-center">접수일<br/>/접수처</th>
                <th class="text-center">회원명</th>
                <th class="text-center">회원ID</th>
                <th class="text-center">주문번호</th>
                <th class="text-center">클레임번호<br/>/송장번호</th>
                <th class="text-center">상품번호</th>
                <th class="text-center">상품명</th>
                <th class="text-center">교환 상품</th>
                <th class="text-center">교환 수량</th>
                <th class="text-center">신청자명</th>
                <th class="text-center">휴대폰번호</th>
                <th class="text-center">교환사유</th>
                <th class="text-center">책임여부</th>
                <th class="text-center">교환상태</th>
                <th class="text-center" v-if="search.cpoStatus == 12 || search.cpoStatus == 13">상태변경일</th>
                <th class="text-center" v-if="search.cpoStatus == 14">배송 시작일</th>
                <th class="text-center" v-if="search.cpoStatus == 17">교환 접수 취소일</th>
                <th class="text-center" v-if="search.cpoStatus == 16">교환 불가일</th>
                <th class="text-center" v-if="search.cpoStatus == 15">교환 완료일 <br>/배송 시작일</th>
                <th class="text-center">메모</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!itemList.length">
                <td colspan="21" class="text-center">내역이 존재하지 않습니다.</td>
              </tr>
              <tr v-for="(data, idx) in itemList" :key="idx" v-else>
                <td class="text-center"><input type="checkbox" v-model="selectedList" :value="data" :disabled="data.ordProductOpt == null"/></td>
                <td class="text-center">{{totalCnt - ((search.srchPage - 1) * search.srchPageCnt) - idx}}</td>
                <td class="text-center">{{ dateFormat(data.reqDate, 'YYYY-MM-DD HH:mm') }} <br/>/{{data.clmWorker}}</td>
                <td class="text-center">{{ data.cliName }}</td>
                <td class="text-center">{{ data.id }}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="callOrderDetailPop(data.ordIdx)">{{ data.ordNo }}</a></td>
                <td class="text-center">
                  <a href="javascript:void(0)" @click="callClaimDetailPop(data.ordIdx, data.clmIdx, data.clmNo, data.clmType)">{{data.clmNo}}</a>
                  <br>
                  <span v-if="data.cdvDeliNo">{{data.cdvDeliComStr}}/{{data.cdvDeliNo}}</span>
                  <span v-else></span>
                </td>
                <td class="text-center">{{data.prdNo}}</td>
                <td class="text-center">{{ data.ordProduct }}/{{data.opoColName}}/{{data.opoSize}}</td>
                <td class="text-center">{{ data.ordProductOpt }}</td>
                <td class="text-center">{{ data.cpoQuantity }}</td>
                <td class="text-center">{{ data.userName }}</td>
                <td class="text-center">{{ data.userPhone |dash }}</td>
                <td class="text-center">{{ data.clmCdStr }}</td>
                <td class="text-center">{{ data.clmChargeStr }}</td>
                <td class="text-center">
                  <span v-if="search.cpoStatus == 17 || search.cpoStatus == 15 || data.ordProductOpt == null">{{data.cpoStatusStr}}</span>
                  <select class="form-control" v-model="data.cpoStatus" v-else @change="updateStatusRow(data.cpoIdx, $event)">
                    <option value="11" v-if="search.cpoStatus != 12 && search.cpoStatus != 13 && search.cpoStatus != 14 && search.cpoStatus != 16" data-name="교환접수">교환접수</option>
                    <option value="12" v-if="search.cpoStatus != 13 && search.cpoStatus != 14 && search.cpoStatus != 16" data-name="교환처리중[수거전]">교환처리중[수거전]</option>
                    <option value="13" v-if="search.cpoStatus != 14 && search.cpoStatus != 16" data-name="교환처리중[수거완료]">교환처리중[수거완료]</option>
                    <option value="14" data-name="교환처리중[배송중]">교환처리중[배송중]</option>
                    <option value="17" v-if="search.cpoStatus != 13 && search.cpoStatus != 14 && search.cpoStatus != 16" data-name="교환접수취소">교환접수취소</option>
                    <option value="16" v-if="search.cpoStatus != 11 && search.cpoStatus != 12 && search.cpoStatus != 14" data-name="교환불가">교환불가</option>
                    <option value="15" v-if="search.cpoStatus != 11 && search.cpoStatus != 12 && search.cpoStatus != 13 && search.cpoStatus != 16" data-name="교환완료">교환완료</option>
                  </select>

                </td>
                <td class="text-center" v-if="search.cpoStatus == 12 || search.cpoStatus == 13">{{ dateFormat(data.cpoStatusDate, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center" v-if="search.cpoStatus == 14">{{ dateFormat(data.cdvSendDt, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center" v-if="search.cpoStatus == 17">{{ dateFormat(data.cpoStatusDate, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center" v-if="search.cpoStatus == 16">{{ dateFormat(data.cpoStatusDate, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center" v-if="search.cpoStatus == 15">{{ dateFormat(data.cpoStatusDate, 'YYYY-MM-DD HH:mm') }} <br>/{{ dateFormat(data.cdvSendDt, 'YYYY-MM-DD HH:mm') }}</td>
                <td class="text-center"><a href="javascript:void(0)" @click="callMemoPop(data.ordIdx, data.ordNo)"><i class="pe-7s-comment pr-2"></i>{{ data.memoCnt | comma }}</a></td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <pagination :pagenum="search.srchPage" :per-page="search.srchPageCnt" :records="totalCnt" for="itemList" v-on:vue-pagination::itemList="onPage" v-if="itemList.length>0"></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import Pagination from '@/page/components/Pagination'
import DatePickerButton from '@/components/common/DatePickerButton'
import OrderDetailPop from '@/page/order/components/OrderDetailPop'
import ClaimDetailPop from '@/page/order/components/ClaimDetailPop'
import MemoPop from '@/page/order/components/MemoPop'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import moment from "moment";
import InvoiceNumberPop from "@/page/order/components/InvoiceNumberPop";
import BundlePop from "@/page/order/components/BundlePop";

export default {
  name: 'ExchangeList',
  data () {
    return {
      courierList: [],
      search: {
        changeStatus: '',
        clmCd: '',
        clmCharge: '',
        clmWorker: '',
        cpoStatus: '',
        srchDateEnd: '',
        srchDateStart: '',
        srchKeyword: '',
        srchPage: 1,
        srchPageCnt: 10,
        srchType: ''
      },
      srchClmWorkerMap: [
        { value: 'PC', text: 'PC'},
        { value: 'MC', text: 'MC'},
        { value: 'CS', text: 'CS'}
      ],
      srchClmCdMap: [
      ],
      srchClmChargeMap: [
        { value: 1, text: '고객책임'},
        { value: 2, text: '판매자책임'}
      ],
      srchRefundMethodMap: [
        { value: 1, text: '결제취소'},
        { value: 2, text: '현금환불'},
      ],
      srchTypeList: [
        { value: 1, text: '주문번호' },
        { value: 2, text: '클레임번호' },
        { value: 3, text: '송장번호' },
        { value: 4, text: '상품번호' },
        { value: 5, text: '상품명' },
        { value: 6, text: '신청자명' },
        { value: 7, text: '휴대폰번호' },
        { value: 8, text: '회원명' },
        { value: 9, text: '회원ID' }
      ],
      srchPageSizeList: [
        { value: 10, text: '10개' },
        { value: 20, text: '20개' },
        { value: 30, text: '30개' },
        { value: 40, text: '40개' },
        { value: 50, text: '50개' },
        { value: 100, text: '100개' }
      ],
      totalCnt: 0,
      itemList: [],
      selectedList: [],
      selectStatus: '',
      allSelected: false
    }
  },
  components: {
    NavigationBar,
    DatePickerButton,
    Pagination,
    OrderDetailPop,
    ClaimDetailPop,
    MemoPop
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
  },
  created () {
    this.$bus.$on('memoUpdate', value => {
      this.itemList.map((element, idx) => {
        if(element.ordIdx == value.ordIdx) {
          this.itemList[idx].memoCnt = value.amount
        }
      })
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    onActiveTab (tabIdx) {
      this.chgSelectStatus(tabIdx)
      this.search.cpoStatus = tabIdx
      this.onReset()
      this.onSearch()
    },
    init () {
      this.search.cpoStatus = 11
      this.onReset()
      this.onSearch()
      this.getCourierList()
      this.getReasonList()
    },
    chgSelectStatus(tabIdx) {
      this.selectStatus = ''

      switch (tabIdx) {
        case 14: this.selectStatus = 15; break
        case 16: this.selectStatus = 14; break
        default: this.selectStatus = ''; break
      }
    },
    onReset () {
      this.search.changeStatus = ''
      this.search.clmCd = ''
      this.search.clmCharge = ''
      this.search.clmWorker = ''
      this.search.srchDateEnd = moment(new Date()).format('YYYY-MM-DD')
      this.search.srchDateStart = moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')
      this.search.srchKeyword = ''
      //this.search.srchPage = 1
      //this.search.srchPageCnt = 10
      this.search.srchType = ''
      this.selectedList = []
    },
    onPage (srchPage) {
      this.search.srchPage = srchPage
      this.getItemList()
    },
    onChangeSize () {
      this.search.srchPage = 1
      this.getItemList()
    },
    onSearch () {
      this.search.srchPage = 1
      this.getItemList()
    },
    getReasonList() {
      this.$api.api.getReasonMngList().then(resp => {
        this.srchClmCdMap = resp.data.data.resultChangeData
      })
    },
    getItemList () {
      // let params = {
      //   clmCd: this.search.clmCd,
      //   clmCharge: this.search.clmCharge,
      //   clmWorker: this.search.clmWorker,
      //   cpoStatus: this.search.cpoStatus,
      //   changeStatus: this.search.changeStatus,
      //
      //   srchDateEnd: this.search.srchDateEnd,
      //   srchDateStart: this.search.srchDateStart,
      //   srchType: this.search.srchType,
      //   srchKeyword: this.search.srchKeyword,
      //   srchPage: this.search.srchPage,
      //   srchPageCnt: this.search.srchPageCnt
      // }

      this.$api.api.getExchangeList(this.search).then(resp => {
        this.itemList = resp.data.data.admOrderExchanges
        this.totalCnt = resp.data.data.seachCount
      }).catch(error => {
        console.log(error)
      })
    },
    onChangeStatus () {
      if (!this.selectedList.length) {
        this.alert("변경할 주문을 선택해주세요.")
        // this.alert('변경할 주문을 선택해주세요.')
        return false
      }
      if (!this.selectStatus) {
        this.alert('변경할 상태를 선택해주세요.')
        return false
      }

      this.updateStatus()
    },
    exportExcel () {
      let params = {
        clmCd: this.search.clmCd,     // 시작일
        clmCharge: this.search.clmCharge,         // 종료일
        clmWorker: this.search.clmWorker,   // 유입경로
        cpoStatus: this.search.cpoStatus,             // 결제수단
        changeStatus: this.search.changeStatus,         // 결제상태
        srchDateEnd: this.search.srchDateEnd,               // 검색조건
        srchDateStart: this.search.srchDateStart,         // 검색어
        srchKeyword: this.search.srchKeyword,               // 불필요
        srchPage: this.search.srchPage,          // 불필요
        srchPageCnt: this.search.srchPageCnt,          // 불필요
        srchType: this.search.srchType          // 불필요
      }

      return this.$api.api.exportExchangeList(params).then(resp => {
        if (resp.status === 200) {
          this.$toast.success('엑셀 다운로드 성공')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    callOrderDetailPop (ordIdx) {
      this.$modal.show(
          OrderDetailPop,
          { text: '주문정보', ordIdx: Number(ordIdx) },
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event.params)
            }
          }
      )
    },
    callClaimDetailPop (ordIdx, clmIdx, clmNo) {
      this.$modal.show(
          ClaimDetailPop,
          { text: '교환', ordIdx: Number(ordIdx), clmIdx: clmIdx, clmNo: clmNo, claimType: 'exchange'},
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              if(this.search.cpoStatus === 11) {
                this.onSearch()
              }
            }
          }
      )
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
          MemoPop,
          { text: '메모', ordIdx: ordIdx, ordNo: ordNo},
          { draggable: false, height: 'auto' },
          {
            'before-close': event => {
              console.log('close modal::: MemoPop')
            }
          }
      )
    },
    checkedAll () {
      this.selectedList = []
      if (!this.allSelected) {
        for (let i = 0; i < this.itemList.length; i++) {
          if(this.itemList[i].ordProductOpt != null){
            this.selectedList.push(this.itemList[i])
          }
        }
      }
    },
    setDateStart (value) {
      this.search.srchDateStart = value
    },
    setDateEnd (value) {
      this.search.srchDateEnd = value
    },
    getCourierList() {
      return this.$api.api.getCourierList().then(resp => {
        this.courierList = resp.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    onBundlePop() {
      let list = []
      if (this.selectedList.length === 0) {
        this.alert('묶음배송할 주문을 선택해주세요.')
        return false
      }
      for(let i = 0; i < this.selectedList.length; i++) {
        let firstAddress = this.selectedList[0].sndAddr1 + ' ' + this.selectedList[0].sndAddr2
        let secondAddress = this.selectedList[i].sndAddr1 + ' ' + this.selectedList[i].sndAddr2

        if(firstAddress != secondAddress) {
          this.alert('수령자 주소가 동일하지 않습니다. 다시 선택해주세요.')
          return false
        }
        if(this.selectedList[0].sndPhone != this.selectedList[i].sndPhone) {
          this.alert('휴대폰번호가 동일하지 않습니다. 다시 선택해주세요.')
          return false
        }
        if(this.selectedList[0].sndName != this.selectedList[i].sndName) {
          this.alert('수령자 이름이 동일하지 않습니다. 다시 선택해주세요.')
          return false
        }
      }


      this.selectedList.map(el => {
        for (let i=0; i<this.itemList.length; i++) {
          let item = this.itemList[i]
          if(el.cpoIdx == item.cpoIdx) {
            list.push({
              ordNo: item.ordNo,
              opoIdx: item.cpoIdx,
              ordProduct: item.ordProduct,
              opoColName: item.cpoColName,
              opoSize: item.cpoSize
            })
          }
        }
      })
      this.$modal.show(
          BundlePop,
          { text: '묶음 배송', products: list, type: 'exchange'},
          { draggable: false, height: 'auto', adaptive: true, minWidth: 1000 },
          {
            'before-close': event => {
              if(event.params) {
                this.getItemList()
              }
            }
          }
      )
    },
    onInvoiceNumberPop() {
      if (!this.selectedList.length) {
        this.alert('수정할 주문을 선택해주세요.')
        return false
      }

      let list = []
      this.selectedList.map(el => {
        for (let i=0; i<this.itemList.length; i++) {
          let item = this.itemList[i]
          if(el.cpoIdx == item.cpoIdx) {
            list.push({
              ordNo: item.ordNo,
              opoIdx: item.cpoIdx,
              ordProduct: item.ordProduct,
              opoColName: item.cpoColName,
              opoSize: item.cpoSize,
              odvDeliCom: item.cdvDeliCom,
              odvDeliNo: item.cdvDeliNo
            })
          }
        }
      })

      this.$modal.show(
        InvoiceNumberPop,
        { text: '송장번호 수정', products: list, type: 'exchange'},
        { draggable: false, height: 'auto', adaptive: true, minWidth: 1000 },
        {
          'before-close': event => {
            if(event.params) {
              this.getItemList()
            }
          }
        }
      )
    },
    updateStatusRow(idx, e) {
      let selectedOptionText = e.target.options[e.target.options.selectedIndex].dataset.name;

      this.$confirm(`선택하신 주문을 ${selectedOptionText} 으로 상태 변경하시겠습니까?`).then(resp => {
        let cpoIdxs = [idx]
        let reqParam = {
          cpoIdxs: cpoIdxs,
          cpoStatus: e.target.value
        }

        this.$api.api.updateExchangeStatus(reqParam).then(resp => {
          if (resp.data.result) {
            this.alert('변경되었습니다.', () => {
              this.onActiveTab(Number(e.target.value))
            })

          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    },

    updateStatus() {

      let status = document.querySelector('.exchangeStatus');
      let text = status.options[status.selectedIndex].text
      let msg = '선택하신 주문을 ' + text + ' 으로 상태 변경하시겠습니까?'

      let cpoIdxs = this.selectedList.map(element => element.cpoIdx)

      let reqParam = {
        cdvDeliCom: '',
        cdvDeliNo:'',
        cpoIdxs: cpoIdxs,
        cpoStatus: this.selectStatus
      }

      this.confirm(msg, () => {
        this.$api.api.updateExchangeStatus(reqParam).then(resp => {
          if (resp.data.result) {
            this.alert(text+'로 변경되었습니다.', () => {
              this.onActiveTab(this.selectStatus)
            })

          } else {
            this.alert(resp.data.errMsg)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
