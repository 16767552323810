<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'관리자관리'" :nav-list="['맥케이슨관리', '기본정보관리', '관리자관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
<!--        <div class="alert alert-warning fade show" role="alert" v-if="admin.level === 1">-->
<!--          <p class="mb-0">superadmin 계정은 수정이 불가능합니다.</p>-->
<!--        </div>-->

        <div class="main-card mb-3 card">
          <div class="card-body">
            <h5 class="card-title">관리자 정보</h5>
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15"><span class="required" v-if="!isEdit"> * </span>ID</th>
                  <td class="w-35">
                    <input type="text" class="form-control" v-model="admin.userId" :disabled="isEdit" placeholder="영문, 숫자조합 6자리 이상">
                  </td>
                  <th class="w-15"><span class="required" v-if="!isEdit"> * </span>이름</th>
                  <td class="w-35">
                    <input type="text" class="form-control" v-model="admin.userName" :disabled="admin.level === 1" placeholder="">
                  </td>
                </tr>
                <tr>
                  <th class="w-15"><span class="required" v-if="!isEdit"> * </span>휴대폰번호</th>
                  <td class="w-35">
                    <the-mask type="tel" v-model="admin.userPhone" :mask="['###-####-####', '###-###-####']" pattern="\d*" maxlength="13" class="form-control" :disabled="admin.level === 1" placeholder="- 없이 입력해주세요"></the-mask>
                  </td>
                  <th class="w-15"><span class="required" v-if="!isEdit"> * </span>이메일</th>
                  <td class="w-35">
                    <input type="text" class="form-control" v-model="admin.userEmail" :disabled="admin.level === 1" placeholder="">
                  </td>
                </tr>
                <!--
                <tr>
                  <th class="w-15">권한</th>
                  <td class="w-35">
                    <select class="form-control" v-model="admin.level" disabled>
                      <option v-for="item in adminLevelList" :value="item.value" :key="item.value">{{ item.text }}</option>
                    </select>
                  </td>
                  <td class="w-50" colspan="2"></td>
                </tr>
                -->
<!--                <tr v-if="admin.level !== 1">-->
                <tr>
                  <th class="w-15"><span class="required" v-if="!isEdit"> * </span>비밀번호</th>
                  <td class="w-75" colspan="3">
                    <div class="form-group d-inline-block">
                      <label>비밀번호</label>
                      <input type="password" class="form-control wd-300" v-model="plainPassword" placeholder="">
                    </div>
                    <div class="form-group d-inline-block ml-2">
                      <label>비밀번호 확인</label>
                      <input type="password" class="form-control wd-300" v-model="repeatPassword" placeholder="">
                    </div>
                    <label class="d-block"><span class="required"> * </span>숫자/대문자/소문자/특수문자 3가지 이상 조합 8자리 이상 15자리 이하</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 m-1" @click="onList">목록</button>
        <button class="btn btn-success wd-100 m-1" @click="onCreate" v-if="!isEdit">등록</button>
        <button class="btn btn-danger wd-100 m-1" @click="onDelete" v-if="isEdit && admin.level !== 1">삭제</button>
        <button class="btn btn-success wd-100 m-1" @click="onUpdate" v-if="isEdit">수정</button>
      </div>
    </div>

    <div class="row" v-if="isEdit">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">등록일</th>
                  <td class="w-35"><input type="text" class="form-control" v-model="admin.regDate" disabled></td>
                  <th class="w-15">작성자</th>
                  <td class="w-35"><input type="text" class="form-control" v-model="admin.regId" disabled></td>
                </tr>
                <tr>
                  <th class="w-15">수정일</th>
                  <td class="w-35"><input type="text" class="form-control" v-model="admin.upDate" disabled></td>
                  <th class="w-15">작성자</th>
                  <td class="w-13"><input type="text" class="form-control" v-model="admin.upId" disabled></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength, sameAs, helpers } from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import NavigationBar from '@/page/components/NavigationBar'
import CryptoJS from 'crypto-js'
const alphabetRegExp = helpers.regex('alpha',  /^[A-Za-z0-9+]*$/)
const emailRegExp = helpers.regex('email', /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)

export default {
  name: 'OrderList',
  data () {
    return {
      adminLevelList: [
        { value: 0, text: '시스템어드민' },
        { value: 1, text: '슈퍼어드민' },
        { value: 2, text: '어드민'}
      ],
      search: '',
      isEdit: false,
      admin: {
        idx: '',
        userId: '',
        userName: '',
        userPhone: '',
        userEmail: '',
        level: 2
        // regId: '',
        // regDate: '',
        // updId: '',
        // updDate: ''
      },
      plainPassword: '',
      repeatPassword: ''
    }
  },
  validations: {
    admin: {
      idx: { required },
      userId: { required, minLength: minLength(6), maxLength: maxLength(10), alphabetRegExp },
      userName: { required, minLength: minLength(2), maxLength: maxLength(5) },
      userPhone: { required, minLength: minLength(10), maxLength: maxLength(11) },
      userEmail: { required, minLength: minLength(1), maxLength: maxLength(100), emailRegExp },
      level: { required },
      password: { required }
    },
    plainPassword: { required, minLength: minLength(8), maxLength: maxLength(16) },
    repeatPassword: { sameAsPassword: sameAs('plainPassword') }
  },
  components: {
    NavigationBar
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  computed: {
  },
  created () {
    console.log('[MCKAYSON] AdminDetail::: created ', this.$route.query.idx)
    let idx = this.$route.query.idx
    if (idx) {
      this.isEdit = true
      this.admin.idx = idx
      this.getDetail()
    }

    let search = this.$route.query.search
    this.search = search
  },
  mounted () {
  },
  methods: {
    _isInvalidateInputForm () {
      if (this.$v.$invalid) {
        if (!this.$v.admin.userId.required) {
          this.alert('아이디를 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userId.minLength) {
          this.alert('아이디는 최소 6자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userId.maxLength) {
          this.alert('아이디는 최대 10자리 이상 입력할 수 없습니다.')
          return false
        }
        if (!this.$v.admin.userId.alphabetRegExp) {
          this.alert('영문 또는 숫자만 입력 가능합니다.')
          return false
        }
        if (!this.$v.admin.userName.required) {
          this.alert('이름을 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userName.minLength) {
          this.alert('이름은 최소 2자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userName.maxLength) {
          this.alert('이름은 5자리 이상 입력할 수 없습니다.')
          return false
        }
        if (!this.$v.admin.userPhone.required) {
          this.alert('휴대폰번호를 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userPhone.minLength) {
          this.alert('휴대폰번호는 최소 10자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userPhone.maxLength) {
          let phoneNumber = this.admin.userPhone.replace('-', '')

          if(phoneNumber.length < this.$v.admin.userPhone.maxLength) {
            this.alert('휴대폰번호는 최대 11자리 이상 입력할 수 없습니다.')
            return false
          }
        }
        if (!this.$v.admin.userEmail.required) {
          this.alert('이메일을 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userEmail.minLength) {
          this.alert('이메일은 최소 1자리 이상 입력해주세요.')
          return false
        }
        if (!this.$v.admin.userEmail.maxLength) {
          this.alert('이메일은 최대 100자리 이상 입력할 수 없습니다.')
          return false
        }
        if (!this.$v.admin.userEmail.emailRegExp) {
          this.alert('이메일 형식이 유효하지 않습니다.')
          return false
        }
        if (!this.isEdit || (this.isEdit && (this.plainPassword !== '' || this.repeatPassword !== ''))) {
          if (!this.$v.plainPassword.required) {
            this.alert('비밀번호를 입력해주세요.')
            return false
          }
          if (!this.$v.plainPassword.minLength) {
            this.alert('비밀번호는 최소 8자리 이상 입력해주세요.')
            return false
          }
          if (!this.$v.plainPassword.maxLength) {
            this.alert('비밀번호는 최대 16자리 이상 입력할 수 없습니다.')
            return false
          }
          // if (!this.checkPassword(this.plainPassword)) {
          //   this.alert('비밀번호는 영문, 숫자, 특수문자 조합으로 생성해 주세요.')
          //   return false
          // }
          let num = this.plainPassword.search(/[0-9]/g)
          let eng1 = this.plainPassword.search(/[a-z]/g)
          let eng2 = this.plainPassword.search(/[A-Z]/g)
          let spe = this.plainPassword.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi)
          if ((num < 0 && eng1 < 0) || (num < 0 && eng2 < 0) || (num < 0 && spe < 0) || (eng1 < 0 && eng2 < 0) || (eng1 < 0 && spe < 0) || (eng2 < 0 && spe < 0)) {
            this.alert('비밀번호는 숫자/대문자/소문자/특수문자 3가지이상 조합으로 생성해 주세요.')
            return false
          }
          if (!this.$v.repeatPassword.sameAsPassword) {
            this.alert('비밀번호가 일치하지 않습니다.')
            return false
          }
        }
      }
      return true
    },
    getDetail () {
      console.log('[MCKAYSON] getDetail::: ', this.admin.idx)
      let params = {
        idx: this.admin.idx
      }
      return this.$api.api.getAdminDetail(params).then(resp => {
        this.admin = resp.data.data
      }).catch(() => {
      })
    },
    onList () {
      this.$router.push({path: '/admin/list', query: {search: this.search}})
    },
    onCreate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 관리자 정보 등록 실패 입력 FORM 오류')
        return
      }
      this.confirm('등록 하시겠습니까?', () => {
        let params = {
          id: this.admin.userId,
          userEmail: this.admin.userEmail,
          userName: this.admin.userName,
          userPhone: this.admin.userPhone,
          // level: this.admin.level,
          level: 2,
          password: CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(this.repeatPassword))
        }
        this.$api.api.reqAdminRegistration(params).then(resp => {
          if (resp.data.result) {
            this.alert('등록에 성공 했습니다.')
            this.$router.push({path: '/admin/list'})
          } else {
            this.alert(resp.data.errMsg)
            return
          }
        })
      })
    },
    onUpdate () {
      if (!this._isInvalidateInputForm()) {
        console.log('[MCKS] 관리자 정보 수정 실패 입력 FORM 오류')
        return
      }
      this.confirm('수정 하시겠습니까?', () => {
        let params = {
          idx: this.admin.idx,
          id: this.admin.userId,
          userEmail: this.admin.userEmail,
          userName: this.admin.userName,
          userPhone: this.admin.userPhone,
          // level: this.admin.level,
          level: 2,
          password: this.repeatPassword === '' ? '' : CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(this.repeatPassword))
        }
        this.$api.api.reqAdminModification(params).then(resp => {
          if (resp.data.result) {
            this.alert('수정되었습니다.', () => {
              this.$router.push({path: '/admin/list'})
            })
          } else {
            this.alert('수정에 실패 했습니다.')
          }
        })
      })
    },
    onDelete () {
      this.confirm('삭제 하시겠습니까?', () => {
        let params = {
          idxList: []
        }
        params.idxList.push(this.admin.idx)
        this.$api.api.reqAdminDeletation(params).then(resp => {
          if (resp.data.result) {
            this.alert('삭제되었습니다.', () => {
              this.$router.push({path: '/admin/list'})
            })
          } else {
            this.alert('삭제에 실패 했습니다.')
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
