<template>
    <div class="main-card mb-3 card">
        <div class="card-header font-size-lg">상품 노출</div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="float-left">
                        <div class="row form-group">
                            <div class="col-12 m-2">
                                <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-100"><span class="required">*</span>타이틀</h5>
                                <input type="text" placeholder="텍스트를 입력해 주세요." maxlength="20" class="form-control wd-200 d-inline-block" v-model="title">
<!--                                <input type="text" placeholder="텍스트를 입력해 주세요." maxlength="20" class="form-control wd-200 d-inline-block" v-model="title" :disabled="title !== ''">-->
                                <span class="ml-1">({{ title.length }}/20)</span>
                            </div>
                        </div>
                    </div>
                    <div class="float-right">
                        <button class="btn btn-primary wd-100" @click="callAddPopup"><i class="fa fa-plus mr-2"></i>추가</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <table class="table">
                    <colgroup>
                        <col width="50px" />
                        <col width="150px" />
                        <col width="170px" />
                        <col width="170px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                            <th class="text-center">순서변경</th>
                            <th class="text-center"><span class="required">*</span>대표 이미지(PC)</th>
                            <th class="text-center"><span class="required">*</span>대표 이미지(Mobile)</th>
                            <th class="text-center">타이틀</th>
                            <th class="text-center">상품번호</th>
                            <th class="text-center">상품명</th>
                            <th class="text-center">상품명(한글)</th>
                            <th class="text-center">원가</th>
                            <th class="text-center">판매가</th>
                            <th class="text-center">색상</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!list.length">
                            <td class="text-center" colspan="10">등록된 정보가 존재하지 않습니다.</td>
                        </tr>
                        <!-- 여긴가 -->
                        <template v-else  v-for="(item, itemIdx) in list">
                            <tr>
                                <td class="text-center" :rowspan="item.prdList.length + 1">
                                    <input type="checkbox" v-model="chkList" :value="item.idx" v-if="item.idx !== ''" />
                                    <i class="fa fa-times" style="cursor: pointer;" @click="deleteRow(itemIdx)" v-else></i>
                                </td>
                                <td class="text-center" :rowspan="item.prdList.length + 1">
                                    <select class="custom-select wd-100" style="margin: auto;" @change="onChangeSeq($event, itemIdx)" v-model="item.seq">
                                        <option v-for="sort in list.length" :value="sort">{{ sort }}</option>
                                    </select>
                                </td>
                                <td class="text-center" :rowspan="item.prdList.length + 1">
                                    <upload-photo v-if="item.pcImg.filename === ''" branch="visual" @changed="changeImage($event, item.pcImg)" />
                                    <img v-else :src="getImagePath(item.pcImg.path + '/' + item.pcImg.filename)" class="images" v-image-error v-viewer style="width: 120px;" />
                                </td>
                                <td class="text-center" :rowspan="item.prdList.length + 1">
                                    <upload-photo v-if="item.mobileImg.filename === ''" branch="visual" @changed="changeImage($event, item.mobileImg)" />
                                    <img v-else :src="getImagePath(item.mobileImg.path + '/' + item.mobileImg.filename)" class="images" v-image-error v-viewer style="width: 120px;" />
                                </td>
                            </tr>
                            <tr v-for="(prd, prdIdx) in item.prdList">
                                <td class="text-center">{{ prd.cat2Name }}</td>
                                <td class="text-center">{{ prd.prdNo }}</td>
                                <td class="text-center">{{ ellipsis(prd.prdName, 15) }}</td>
                                <td class="text-center">{{ ellipsis(prd.prdNameKor, 15) }}</td>
                                <td class="text-center">{{ prd.prdPriceOrg | comma }}</td>
                                <td class="text-center">{{ prd.prdSalePrice | comma }}</td>
                                <td class="text-center" v-html="convertColorName(getColorNameList(prd.colorList))"></td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
                <div class="float-left">
                    <button class="btn btn-danger ml-1 wd-100" @click="onDelete">선택삭제</button>
                </div>
                <div class="float-right">
                    <button class="btn btn-success ml-1 wd-100" @click="onSave">저장</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from '@/components/common/UploadPhoto'
import GeneralProductExposeRegistration from './GeneralProductExposeRegistration'

export default {
    name: 'GeneralProductExpose',
    mixins: [ CommonMixin, InterfaceMixin ],
    components: {
        UploadPhoto,
    },
    data () {
        return {
            list: [],
            chkList: [],

            visualIdx: '',
            title: '',
        }
    },
    validations: {
        title: {
            required
        },
        list: {
            required,
            $each: {
                pcImg: {
                    filename: {
                        required
                    }
                },
                mobileImg: {
                    filename: {
                        required
                    }
                }
            }
        }
    },
    props: {
        itemType: {
            type: Number | String,
            default: 0
        },
        idx: {
            type: Number | String,
            default: ''
        }
    },
    computed: {
        chkAll: {
        get () {
          return this.list.length ? this.chkList.length === this.list.length : false
        },
        set (value) {
          let selected = []

          if (value) {
            this.list.map((item, itemIdx) => {
              selected.push(item.idx)
            })
          }
          this.chkList = selected
        },
      }
    },
    created () {
        this.visualIdx = this.idx
        if (this.idx) {
            this.getDataList()
        }
    },
    mounted () {
    },
    methods: {
        getColorNameList (arr) {
            return arr.map(a => a.colName).join(', ')
        },
        convertColorName (html) {
            // return html.replace(', ', '<br/>')
            return html
        },
        callAddPopup () {
            this.$modal.show(
                GeneralProductExposeRegistration,
                {text: '상품 등록'},
                { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1000 },
                {
                    'before-close': event => {
                        if (event.params) {
                            let temp = {
                                idx: '',
                                seq: this.list.length + 1,
                                pcImg: { filename: '', path: '' },
                                mobileImg: { filename: '', path: '' },
                                prdList: []
                            }
                            event.params.map(p => {
                                temp.prdList.push(p)
                            })
                            this.list.push(temp)
                        }
                    }
                }
            )
        },
        // 목록조회
        getDataList() {
            return this.$api.api.getMngVisualCommonTemplate(this.visualIdx).then(resp => {
                this.title = resp.data.data.title
                this.list = []
                resp.data.data.prdList.map(p => {
                    p.pcImg.path = ''
                    p.mobileImg.path = ''
                    this.list.push(p)
                })
            }).catch(error => {
                console.error(error)
            })
        },
        _isInvalidateInputForm() {
            if (this.$v.$invalid) {
                if (!this.$v.title.required) {
                    this.alert('타이틀을 입력해주세요.')
                    return false
                }
                for (let key in this.$v.list.$each.$iter) {
                    let tmp = this.$v.list.$each.$iter[key]
                    if (!tmp.pcImg.filename.required) {
                        this.alert('PC 대표 이미지를 등록해주세요.')
                        return false
                    }
                    if (!tmp.mobileImg.filename.required) {
                        this.alert('Mobile 대표 이미지를 등록해주세요.')
                        return false
                    }
                }
            }
            return true
        },
        // 저장
        onSave () {
          console.log("3개 이상 등록 >>>>   ", this.list.length)
            if (!this._isInvalidateInputForm()) {
                console.log('[MCKS] 상품노출 정보 등록 실패 입력 FORM 오류')
                return false
            }
            // 최소 등록 개수 3개 (화면에 3개씩 노출됨)
            if (this.list.length < 3) {
              this.alert('최소 3개 이상 필수로 등록 되어야 합니다.')
              return false
            }

            this.confirm("저장 하시겠습니까?", () => {
                let reqBody = {
                    idx: this.visualIdx,
                    title: this.title,
                    itemType: this.itemType,
                    prdList: []
                }
                this.list.map(i => {
                    let tmp = {
                        idx: i.idx,
                        seq: i.seq,
                        mobileImg: { idx: i.mobileImg.idx, filename: i.mobileImg.filename },
                        pcImg: { idx: i.pcImg.idx, filename: i.pcImg.filename },
                        prdIdxList: i.prdList.map(p => p.prdIdx)
                    }
                    reqBody.prdList.push(tmp)
                })
                console.log('## reqBody::: ', reqBody)

                this.blockUI()
                this.$api.api.saveMngVisualCommonTemplate(reqBody).then(resp => {
                    if (resp.status === 200) {
                        this.visualIdx = resp.data.data
                        this.alert('저장 되었습니다.', () => {
                            this.$nextTick(() => {
                                this.$router.replace({path: '/home/general_add', query: {idx: this.visualIdx, nowTime: this.nowTime}})
                            })
                        })
                    } else {
                        this.alert('저장에 실패하였습니다')
                    }
                }).finally(() => {
                    this.unBlockUI()
                })
            })
        },
        // 선택삭제
        onDelete() {
            if (this.chkList.length <= 0) {
                this.alert("삭제할 대상을 선택해 주세요.")
                return
            }
            // 배준M 요청 (2021-12-15 서버 처리 이슈로 인해 한개 필수)
            // if (this.list.length - this.chkList.length <= 0) {
            //     this.alert('1개 이상 필수로 등록 되어야 합니다.')
            //     return false
            // }
            // 최소 등록 개수 3개 (화면에 3개씩 노출됨)
            if ( (this.list.length - this.chkList.length) < 3 ) {
              this.alert('최소 3개 이상 필수로 등록 되어야 합니다.')
              return false
            }

            let reqBody = {
                idxList: this.chkList
            }
            this.confirm("삭제 하시겠습니까?", () => {
                this.$api.api.delMngVisualCommonTemplate(reqBody).then(resp => {
                    if (resp.status === 200) {
                        this.alert("삭제 되었습니다.")
                        this.getDataList()
                        this.chkList = []
                    } else {
                        this.alert("삭제에 실패하였습니다.")
                    }
                })
            })
        },
        // 순서 변경
        onChangeSeq (event, thisIdx) {
            let temp = this.list[thisIdx]
            this.list.splice(thisIdx, 1)
            this.list.splice(event.target.value - 1, 0, temp)

            this.list.forEach((item, itemIdx) => {
                item.seq = itemIdx + 1
            })
        },
        // 이미지 업로드
        changeImage (file, thisObj) {
            thisObj.path = file.path
            thisObj.filename = file.name
            thisObj.width = file.w
            thisObj.height = file.h
        },
        // 행(x) 삭제
        deleteRow(idx) {
            this.list.splice(idx, 1)
            this.list.map((item, idx) => {
                item.seq = idx + 1
            })
        },
    }
}
</script>
