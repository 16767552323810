<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'주문관리'" :nav-list="['맥케이슨관리', '주문관리', '주문관리']" />
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">주문정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">주문일(결제일)</th>
                  <td class="w-35 text-left">{{ dateFormat(order.admOrderDetailInfo.ordRgdt, "YYYY-MM-DD HH:mm") }} ({{ dateFormat(order.admOrderDetailInfo.payDt, 'YYYY-MM-DD HH:mm') }})</td>
                  <th class="w-15">유입경로</th>
                  <td class="w-35 text-left">{{ order.admOrderDetailInfo.accessMethod }}</td>
                </tr>
                <tr>
                  <th class="w-15">주문번호</th>
                  <td class="w-35 text-left">{{ order.admOrderDetailInfo.ordNo }}</td>
                  <th class="w-15">메모</th>
                  <td class="w-35 text-left"><a href="javascript:void(0)" @click="callMemoPop(order.admOrderDetailInfo.ordIdx, order.admOrderDetailInfo.ordNo)">{{ order.admOrderDetailInfo.memoCnt | comma }}</a></td>
                </tr>
                <tr v-if="giftList.length > 0">
                  <th class="w-15">사은품</th>
                  <td class="w-85 text-left" colspan="3">{{ strGift }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">상품정보</h5>
            <div class="mt-auto ml-auto mb-auto mr-0 right position-relative">
              <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="callClaimReqPop('cancel')" v-if="order.isPartialCancel != -1">취소신청</button>
              <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="callClaimReqPop('exchange')" v-if="getClaimStatus('exchange')">교환신청</button>
              <button class="btn btn-secondary wd-100 ml-1 mr-1" @click="callClaimReqPop('return')" v-if="getClaimStatus('return')">반품신청</button>
            </div>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th><input type="checkbox" v-model="chkPrdAll" /></th>
                  <th>상품별 주문번호</th>
                  <th>상품번호</th>
                  <th class="w-25">상품명/옵션</th>
                  <th>수량</th>
                  <th>판매가</th>
                  <th>상품금액</th>
                  <th>결제상태</th>
                  <th>배송상태</th>
                  <th>취소상태</th>
                  <th>교환상태</th>
                  <th>반품상태</th>
                  <th>환불상태</th>
                </tr>
                <tr v-for="(product, idx) in order.admOrderDetailOptions">
                  <td class="text-center"><input type="checkbox" v-model="chkPrdList" :value="product" v-if="product.opoPurchaseConfirm != 'Y' && product.quantityAvail > 0"/></td>
                  <td class="text-center">{{ `${product.ordNo}_${product.opoIdx}` }}</td>
                  <td class="text-center">{{ product.prdNo }}</td>
                  <td class="text-left">
                    <p class="mb-0">{{ product.ordProduct }}</p>
                    <span class="mb-0 font-color-blue">옵션1 : {{ product.opoColName }}<template v-if="product.opoSize"> / 옵션2 : {{ product.opoSize }}</template></span>
                  </td>
                  <td class="text-center">{{ product.quantity | comma }}</td>
                  <td class="text-center">{{ product.optPriceSale | comma }}원</td>
                  <td class="text-center" :style="order.admOrderDetailInfo.memIdx? 'color:#B40404': ''">{{ product.prdAmount | comma }}원</td>
                  <td class="text-center">{{ product.ordStatus | ordStatus }}</td>
                  <td class="text-center">{{ (product.opoPurchaseConfirm == 'Y'? '구매확정' : product.odvStatus) | odvStatus }}</td>
                  <td class="text-center">{{ product.cancelStatus | cancelStatus }}</td>
                  <td class="text-center">{{ product.exchangeStatus | exchangeStatus }}</td>
                  <td class="text-center">{{ product.returnStatus | returnStatus }}</td>
                  <td class="text-center">{{ product.refundStatus | refundStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">결제정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th colspan="2">결제정보</th>
                  <th colspan="2">상품정보</th>
                  <th colspan="2">배송비</th>
                </tr>
                <tr>
                  <th>마일리지(사용)</th>
                  <td class="text-center"><span style="color: red" v-if="order.admOrderDetailPayInfo.usePoint >0">{{order.admOrderDetailPayInfo.usePoint | comma}}M</span></td>
                  <th class="text-center" rowspan="4">상품 금액 합</th>
                  <td class="text-center" rowspan="4">{{ order.admOrderDetailPayInfo.onlyPrdAmount | comma }}원</td>
                  <th></th>
                </tr>
                <tr>
                  <th><span style="color: red">(실)</span>결제금액</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payAmount | comma }}원</td>
                  <th class="text-center">배송비 합</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.deliFeeSum | comma }}원</td>
                </tr>
                <tr>
                  <th>결제수단</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.payKind | payKind }}</td>
                  <th class="text-center">기본배송비</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.deliFee | comma }}원</td>
                </tr>
                <tr>
                  <th>세부정보</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.accountName }} / {{ order.admOrderDetailPayInfo.accountNo }}</td>
                  <th class="text-center">도서산간</th>
                  <td class="text-center">{{ order.admOrderDetailPayInfo.deliAddfee  | comma }}원</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">주문자 정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">성명</th>
                  <td class="w-35">{{ maskName(order.admOrderDetailOrder.userName) }}</td>
                  <th class="w-15">구매횟수</th>
                  <td class="w-35">{{ order.admOrderDetailOrder.buyCnt | comma }}</td>
                </tr>
                <tr>
                  <th class="w-15">전화번호</th>
                  <td class="w-35">{{ maskPhone(order.admOrderDetailOrder.userPhone) }}</td>
                  <th class="w-15">이메일</th>
                  <td class="w-13">{{ maskEmail(order.admOrderDetailOrder.userEmail) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">수령자 정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <th class="w-15">성명</th>
                  <td class="w-35">
                    {{ maskName(order.admOrderDetailRecepient.recipient) }}
                    <button class="btn btn-primary ml-1" @click="callRecipientPop(order.admOrderDetailRecepient)">정보수정</button>
                  </td>
                  <th class="w-15">연락처</th>
                  <td class="w-35">{{ maskPhone(order.admOrderDetailRecepient.recipientPhone) }}</td>
                </tr>
                <tr>
                  <th class="w-15">배송지</th>
                  <td class="w-35">({{ order.admOrderDetailRecepient.postalCode }}) {{ order.admOrderDetailRecepient.addr1 }} {{ order.admOrderDetailRecepient.addr2 }}</td>
                  <th class="w-15">요청사항</th>
                  <td class="w-35">{{ order.admOrderDetailRecepient.comment }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">
            <h5 class="card-title mb-0">클레임 정보</h5>
          </div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr v-if="!order.admOrderDetailClaimSummaries.length">
                  <td class="text-center" colspan="10">등록된 클레임 정보가 존재하지 않습니다.</td>
                </tr>
                <tr v-for="(claim, index) in order.admOrderDetailClaimSummaries" :key="index">
                  <th>구분</th>
                  <td class="text-center">{{ claim.clmType | claimServerType }}</td>
                  <th>클레임번호</th>
                  <td class="text-center"><a href="javascript:void(0)" @click="callClaimDetailPop(claim.ordIdx, claim.clmIdx, claim.clmNo, claim.clmType)">{{ claim.clmNo }}</a></td>
                  <th>신청일</th>
                  <td class="text-center">{{ dateFormat(claim.reqDate, 'YYYY-MM-DD') }}</td>
                  <th>완료일</th>
                  <td class="text-center">{{ dateFormat(claim.cplDate, 'YYYY-MM-DD') }}</td>
                  <th>환불금액</th>
                  <td class="text-center">{{ claim.refundTotalAmount | comma }}원</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100" @click="onList">목록</button>
      </div>
    </div>

  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import NavigationBar from '@/page/components/NavigationBar'
import MemoPop from '@/page/order/components/MemoPop'
import ClaimReqPop from '@/page/order/components/ClaimReqPop'
import ClaimDetailPop from '@/page/order/components/ClaimDetailPop'
import RecipientPop from '@/page/order/components/RecipientPop'

export default {
  name: 'OrderList',
  data () {
    return {
      order: {
        ordIdx: '',
        admOrderDetailInfo: {},
        admOrderDetailOptions: [],
        admOrderDetailPayInfo: {},
        admOrderDetailOrder: {},
        admOrderDetailRecepient: {},
        admOrderDetailClaimSummaries: [],
        isPartialCancel: 0
      },
      chkPrdList: [],
      search: '',
      giftList: [],
      strGift: ''
    }
  },
  components: {
    NavigationBar,
    MemoPop,
    RecipientPop,
    ClaimReqPop,
    ClaimDetailPop
  },
  mixins: [
    CommonMixin,
    InterfaceMixin
  ],
  computed: {
    chkPrdAll: {
      get () {
        return this.order.admOrderDetailOptions.length ? this.chkPrdList.length === this.order.admOrderDetailOptions.length : false
      },
      set (value) {
        let selected = []
        if (value) {
          this.order.admOrderDetailOptions.map((prd, index) => {
            if(prd.opoPurchaseConfirm != 'Y' && prd.quantityAvail > 0) selected.push(prd)
          })
        }
        this.chkPrdList = selected
      }
    }
  },
  created () {
    console.log('[MCKAYSON] OrderDetail::: created ', this.$route.query.ordIdx)
    let ordIdx = this.$route.query.ordIdx
    if (ordIdx) {
      this.order.ordIdx = ordIdx
      this.getDetail()
    }

    let search = this.$route.query.search
    this.search = search
  },
  mounted () {
  },
  methods: {
    getGiftList () {
      let params = {
        ordNo: this.order.admOrderDetailInfo.ordNo
      }
      this.$api.api.getGiftList(params).then(resp => {
        console.log('resp ::: ', resp)
        if (resp.data.result) {
          this.giftList = resp.data.data.giftList
          this.strGift = ''
          this.giftList.forEach((gift, idx) => {
            if (this.giftList.length -1 === idx) {
              this.strGift += gift
            } else {
              this.strGift += gift + ', '
            }
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getDetail () {
      console.log('[MCKAYSON] getDetail::: ', this.order.ordIdx)
      let params = {
        ordIdx: this.order.ordIdx
      }
      return this.$api.api.getOrderDetail(params).then(resp => {
        if (resp.data.result) {
          this.order.admOrderDetailInfo = resp.data.data.admOrderDetailInfo
          this.order.admOrderDetailOptions = resp.data.data.admOrderDetailOptions
          this.order.admOrderDetailPayInfo = resp.data.data.admOrderDetailPayInfo
          this.order.admOrderDetailOrder = resp.data.data.admOrderDetailOrder
          this.order.admOrderDetailRecepient = resp.data.data.admOrderDetailRecepient
          this.order.admOrderDetailClaimSummaries = resp.data.data.admOrderDetailClaimSummaries
          this.order.isPartialCancel = resp.data.data.isPartialCancel
          let opoarr = this.chkPrdList.map(data => data.opoIdx)
          this.chkPrdList = this.order.admOrderDetailOptions.filter(data => opoarr.includes(data.opoIdx) && data.opoPurchaseConfirm != 'Y' && data.quantityAvail > 0)
        }

        this.getGiftList();
      }).catch(error => {
        console.log(error)
      })
    },
    onList () {
      this.$router.push({path: '/order/list', query: {search: this.search}})
    },
    callMemoPop (ordIdx, ordNo) {
      this.$modal.show(
        MemoPop,
        { text: '메모', ordIdx: ordIdx, ordNo: ordNo },
        { draggable: false, resizable: false, height: 'auto' },
        {
          'before-close': event => {
            this.getDetail()
          }
        }
      )
    },
    // 정보수정 팝업
    callRecipientPop (recipient) {
      this.$modal.show(
          RecipientPop,
          { text: '정보수정', recipientInfo: recipient, username: recipient.recipient },
          { draggable: false, resizable: false, height: 'auto' },
          {
            'before-close': event => {
              if (event.params) {
                let params = {
                  addr1: event.params.address1,
                  addr2: event.params.address2,
                  comment: event.params.comment,
                  ordIdx: this.order.ordIdx,
                  postalCode: event.params.zonecode,
                  recipient: event.params.username,
                  recipientPhone: event.params.phone
                }
                this.blockUI()
                this.$api.api.sendRecipientUpdatation(params).then(resp => {
                  if (resp.data.result) {
                    this.alert('수령자 정보가 변경되었습니다.')
                    this.getDetail()
                    this.unBlockUI()
                  }
                }).catch(error => {
                  console.log(error)
                  this.unBlockUI()
                })
              }
            }
          }
      )
    },
    callClaimReqPop (claimType) {
      let claimName = claimType == 'cancel' ? '취소': claimType == 'exchange' ? '교환': '반품'

      if (this.chkPrdList.length === 0) {
        this.alert(claimName + '할 상품을 선택해주세요.')
        return
      }
      if(claimType == 'cancel' && this.order.isPartialCancel === 0) {
          if(this.chkPrdList.length != this.order.admOrderDetailOptions.length) {
            this.alert('부분 취소가 불가능 합니다. 모두 선택해주세요.')
            return
          }
        }
      this.$modal.show(
        ClaimReqPop,
        {text: '신청', claimType: claimType, order: this.order, chkPrdList: this.chkPrdList, giftList: this.giftList},
        { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200 },
        {
            'before-close': event => {
                console.log(event)
                this.getDetail()
            }
        }
      )
    },
    callClaimDetailPop (ordIdx, clmIdx, clmNo, clmType) {
      let title = clmType=='RETURN'?'반품': clmType == 'CANCEL'? '취소': '교환'

      this.$modal.show(
          ClaimDetailPop,
          { text: title, ordIdx: Number(ordIdx), clmIdx: clmIdx, clmNo: clmNo, claimType: clmType.toLowerCase()},
          { draggable: false, resizable: true, height: 'auto', adaptive: true, minWidth: 1200 },
          {
            'before-close': event => {
              console.log(event)
            }
          }
      )
    },
    getClaimStatus(claimType) {
        if(this.order.admOrderDetailOptions) {
            let itemList = this.order.admOrderDetailOptions
            for(let i = 0; i < itemList.length; i++){
                if(itemList[i].opoPurchaseConfirm == 'Y' || itemList[i].quantityAvail < 1) continue
                switch(claimType) {
                    case 'cancel' : {
                        if((itemList[i].ordStatus == 2 || itemList[i].ordStatus == 3) && itemList[i].odvStatus == 0) return true
                    } break
                    case 'exchange' :
                    case 'return' : {
                        if(itemList[i].odvStatus == 3 && itemList[i].quantityAvail > 0) return true
                    } break
                }
            }//for
        }
        return false
    }
  }
}
</script>

<style scoped>

</style>
