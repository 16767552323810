import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    children: [
        {
            path: '/client/list',
            name: 'ClientList',
            component: () => import('@/page/client/ClientList')
        },
        {
            path: '/client/detail/:cliIdx',
            name: 'ClientDetail',
            component: () => import('@/page/client/ClientDetail')
        }
    ]
}
