<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'버전관리'" :nav-list="['맥케이슨관리', '기본정보관리', '버전관리']" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header">태블릿 버전 관리</div>
          <div class="card-body">
            <table class="mb-0 table table-detail">
              <tbody>
                <tr>
                  <td class="text-left wd-150"><span class="required">*</span>버전</td>
                  <td class="text-center w-auto"><input type="text" class="form-control" v-model="version" placeholder="버전을 입력해주세요." /></td>
                  <td class="text-center wd-125"><button type="button" class="btn btn-success w-100" @click="onSave()">저장</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
              </div>
              <div class="float-right">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import NavigationBar from '@/page/components/NavigationBar'

export default {
  name: "VersionList",
  components: {
    NavigationBar
  },
  mixins: [
    CommonMixin, InterfaceMixin
  ],
  data () {
    return {
      version: ''
    }
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getVersionList()
    },
    getVersionList() {
      return this.$api.api.getVersionList().then(resp => {
        if (resp.status === 200) {
          this.version = resp.data.data.version
        }
      }).catch(e => {
        console.log(e)
      })
    },
    onSave () {
      if (this.version.length === 0) {
        this.alert('버전을 입력해주세요.')
        return false
      }
      this.confirm('저장 하시겠습니까?', () => {
        let params = {
          version: this.version
        }
        return this.$api.api.saveVersion(params).then(resp => {
          if (resp.status === 200) {
            this.alert('저장 되었습니다.')
            this.getVersionList()
          } else {
            this.alert('저장에 실패하였습니다.')
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },
  }
}
</script>

<style scoped>

</style>
