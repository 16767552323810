import DefaultLayout from '@/layout/DefaultLayout'

export default {
    path: '/',
    component: DefaultLayout,
    children: [
        {
            path: '/point/totalList',
            name: 'PointTotalList',
            component: () => import('@/page/point/PointTotalList')
        },
        {
            path: '/point/list',
            name: 'PointList',
            component: () => import('@/page/point/PointList')
        }
    ]
}
