<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="main-card mb-3 card">
                <div class="card-header font-size-lg"><span>하단 동영상</span></div>
                <div class="card-body">
                    <table class="mb-0 table table-detail">
                        <tbody>
                        <tr>
                            <td class="text-left wd-200">PC 동영상 URL</td>
                            <td class="text-center"><input type="text" class="form-control" v-model.trim="homeFooterVideoUrlPc" placeholder="홈화면 하단 동영상(PC) URL을 입력해주세요." /></td>
                            <td class="text-center wd-100"><button type="button" class="btn btn-secondary w-100" @click="callDisplayPop(homeFooterVideoUrlPc)">미리보기</button></td>
                        </tr>
                        <tr>
                            <td class="text-left wd-200">Mobile 동영상 URL</td>
                            <td class="text-center"><input type="text" class="form-control" v-model.trim="homeFooterVideoUrlMobile" placeholder="홈화면 하단 동영상(Mobile) URL을 입력해주세요." /></td>
                            <td class="text-center wd-100"><button type="button" class="btn btn-secondary w-100" @click="callDisplayPop(homeFooterVideoUrlMobile)">미리보기</button></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="col-12 pr-0 pl-0">
                        <div class="float-left">
                        </div>
                        <div class="float-right">
                            <button class="btn btn-success wd-100" @click="onSave">저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from "@/mixins/userInterface-mixin"
import {maxLength, minLength, required} from 'vuelidate/lib/validators'
import DisplayPop from '@/page/visual/display/components/DisplayPop'

export default {
    name: 'HomeBottom',
    data () {
        return {
            idx: '',
            homeFooterVideoUrlPc: '',
            homeFooterVideoUrlMobile: '',
        }
    },
    components: {
        DisplayPop,
    },
    mixins: [
        CommonMixin, InterfaceMixin
    ],
    validations: {
        homeFooterVideoUrlPc: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(1000)
        },
        homeFooterVideoUrlMobile: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(1000)
        },
    },
    created () {
        this.getVisualMngFooterList()
    },
    mounted () {
    },
    methods: {
      // 하단동영상 목록조회
      getVisualMngFooterList() {
        return this.$api.api.getMngVisualFooterInfo().then(resp => {
          if (resp.status === 200) {
            this.idx = resp.data.data.idx,
            this.homeFooterVideoUrlPc = resp.data.data.pcVideoUrl
            this.homeFooterVideoUrlMobile = resp.data.data.mobileVideoUrl
          }
        }).catch(error => {
          console.log(error)
        })
      },
      // 비주얼관리 > 하단동영상 저장
      onSave () {
        if (!this.checkUrl(this.homeFooterVideoUrlPc)) {
          this.alert('[PC] 정확한 URL 형식으로 입력해주세요.')
          return false
        } else if (!this.checkUrl(this.homeFooterVideoUrlMobile)) {
          this.alert('[Mobile] 정확한 URL 형식으로 입력해주세요.')
          return false
        }

        this.confirm('저장 하시겠습니까?', () => {
          let params = {
            idx: this.idx,
            pcVideoUrl: this.homeFooterVideoUrlPc,
            mobileVideoUrl: this.homeFooterVideoUrlMobile
          }
          this.blockUI()
          return this.$api.api.saveVisualMngFooterInfo(params).then(resp => {
            if (resp.status === 200) {
              this.alert('저장 되었습니다.')
              this.getVisualMngFooterList()
            } else {
              this.alert('저장에 실패하였습니다.')
              this.getVisualMngFooterList()
            }
          }).catch(error => {
            console.error(error)
          }).finally(() => {
              this.unBlockUI()
          })
        })
      },
      // 동영상 미리보기 팝업
      callDisplayPop (displayUrl) {
          this.$modal.show(
              DisplayPop,
              { text: '미리보기', displayUrl: displayUrl },
              { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
              {
                  'before-close': event => {
                  }
              }
          )
      },
      // 입력 Url 확인
      checkUrl (strUrl) {
          let expUrl = /^http[s]?\:\/\//i
          return expUrl.test(strUrl)
      },



    },
}
</script>
