<template>
  <div>
    <div class="app-page-title">
      <navigation-bar
        :nav-title="'상품전시관리'"
        :nav-list="['맥케이슨관리', '상품관리', '상품전시관리']"
      />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="form-row">
              <div class="col-md-2">
                <div class="position-relative form-group">
                  <label class="">카테고리</label>
                  <select
                    class="form-control"
                    v-model="search.srchCat1Idx"
                    @change="getCategory2"
                  >
                    <option value="">전체</option>
                    <option v-for="item in cat1List" :value="item.cat1Idx">{{
                      item.cat1Name
                    }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2" style="padding-top: 29px;">
                <div class="position-relative form-group">
                  <select class="form-control" v-model="search.srchCat2Idx">
                    <option value="">전체</option>
                    <option
                      v-for="item in cat2List"
                      :value="item.cat2Idx"
                      v-if="search.srchCat1Idx == item.cat1Idx"
                      >{{ item.cat2Name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-block text-center clearfix">
            <button class="btn btn-primary wd-100 mr-1" @click="getItemList()">
              검색
            </button>
            <button class="btn btn-light wd-100 ml-1" @click="onReset">
              초기화
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card card">
          <div class="card-header">
            <div class="col-12 pr-0 pl-0">
              <div class="float-left">
                <h5 class="card-title float-left mb-0 mt-2">
                  건수 : {{ totalCnt }} 건
                </h5>
              </div>
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onUpdate">
                  순서 변경
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">번호</th>
                  <th class="text-center">전시순서</th>
                  <th class="text-center">대표이미지</th>
                  <th class="text-center">카테고리</th>
                  <th class="text-center">상품번호</th>
                  <th class="text-center">상품명</th>
                  <th class="text-center">정가</th>
                  <th class="text-center">판매가</th>
                  <th class="text-center">할인율</th>
                  <th class="text-center">등록일</th>
                  <th class="text-center">수정일</th>
                  <th class="text-center">판매상태</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!datas.length">
                  <td colspan="13" class="text-center">
                    내역이 존재하지 않습니다.
                  </td>
                </tr>
                <tr v-for="(data, idx) in datas" :key="idx" v-else>
                  <td class="text-center">{{ idx + 1 }}</td>
                  <td class="text-center">
                    <select
                      class="form-control w-100"
                      v-model="data.seq"
                      @change="onSeqChange(datas, idx)"
                    >
                      <option
                        v-for="sequence in datas.length"
                        :value="sequence"
                        >{{ sequence }}</option
                      >
                    </select>
                  </td>
                  <td class="text-center">
                    <img
                      class="images"
                      :src="getImagePath(data.prdMainImgThumb1)"
                      style="width: 100px; height: 100px"
                      v-image-error
                      v-viewer
                    />
                  </td>
                  <td class="text-center">
                    {{ data.cat1Name }} > {{ data.cat2Name }}
                  </td>
                  <td class="text-center">{{ data.prdNo }}</td>
                  <td class="text-center">{{ data.prdName }}</td>
                  <td class="text-center">{{ data.prdPriceOrg | comma }}원</td>
                  <td class="text-center">{{ data.prdSalePrice | comma }}원</td>
                  <td class="text-center">
                    {{ Math.round(data.prdDiscountRate) }}%
                  </td>
                  <td class="text-center">
                    {{ dateFormat(data.prdRgdt, "YYYY-MM-DD HH:mm") }}
                  </td>
                  <td class="text-center">
                    {{ dateFormat(data.prdLastUpdDt, "YYYY-MM-DD HH:mm") }}
                  </td>
                  <td class="text-center">
                    {{ data.prdSaleStatus | isSaleCode }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
              <div class="float-right">
                <button class="btn btn-success wd-100" @click="onUpdate">
                  순서 변경
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar";
import Pagination from "@/page/components/Pagination";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import DatePickerButton from "@/components/common/DatePickerButton";
import { CommonMixin } from "@/components/mixins/CommonMixin";
import moment from "moment";

export default {
  name: "ProductList",
  data() {
    return {
      datas: [],
      search: {
        srchCat1Idx: "",
        srchCat2Idx: ""
      },
      totalCnt: 0,
      cat1List: [],
      cat2List: []
    };
  },
  components: {
    NavigationBar
  },
  mixins: [InterfaceMixin, CommonMixin],
  mounted() {
    this.onReset();
    this.getCategory();
  },
  methods: {
    onReset() {
      this.search.srchCat1Idx = "";
      this.search.srchCat2Idx = "";
    },
    getItemList() {
      if (this.search.srchCat1Idx === "" || this.search.srchCat2Idx === "") {
        this.$toast.warning("카테고리를 선택하세요.");
        return;
      }

      let params = {
        srchCat1Idx: this.search.srchCat1Idx,
        srchCat2Idx: this.search.srchCat2Idx
      };

      return this.$api.api
        .getDisplayProductList(params)
        .then(resp => {
          if (resp.data.result) {
            this.datas = resp.data.data;
            this.totalCnt = resp.data.total;
          } else {
            this.alert("목록 조회 실패");
            //this.alert('조회 실패')
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCategory() {
      this.$api.api.getCategory().then(resp => {
        if (resp.data.result) {
          this.cat1List = resp.data.data;

          let arr = [];
          this.cat1List.map(x => {
            x.cat2List.map(y => {
              y.cat1Idx = x.cat1Idx;
              arr.push(y);
            });
          });

          this.cat2List = arr;
        } else {
          this.alert("카테고리 조회 실패");
          //this.alert('카테고리 조회 실패')
        }
      });
    },
    getCategory2: function() {
      this.search.srchCat2Idx = "";
    },
    onUpdate() {
      if (this.datas.length === 0) {
        this.$toast.warning("검색된 상품을 없습니다.");
        return;
      }
      let updProductSeq = this.datas.map(item => {
        return { prdIdx: item.prdIdx, seq: item.seq };
      });
      let bodyData = {
        seqList: updProductSeq
      };
      return this.$api.api
        .updateProductSeq(bodyData)
        .then(resp => {
          if (resp.data.result) {
            this.alert("순서 변경 성공");
          } else {
            this.alert("순서 변경 실패");
          }
        })
        .catch(error => {
          console.log(error);
          this.alert("순서 변경 서버 에러");
        });
    },
    onSeqChange(list, index) {
      let temp = list[index];
      let target = list[index].seq;
      list.splice(index, 1);
      list.splice(target - 1, 0, temp);
      this.reOrdering(list);
    },
    reOrdering(list) {
      list.map((item, index) => {
        item.seq = index + 1;
      });
    }
  }
};
</script>

<style scoped></style>
