var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.chkSeason === 4
        ? _c("Snowf", {
            attrs: {
              amount: 50,
              size: 5,
              speed: 1.5,
              wind: 0,
              opacity: 0.8,
              swing: 1,
              image: null,
              zIndex: null,
              resize: true,
              color: "#fff"
            }
          })
        : _vm._e(),
      _c("app-main")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }