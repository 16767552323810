<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'상품관리'" :nav-list="['맥케이슨관리', '상품관리', '상품관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">상품정보</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>상품번호</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" :class="{'d-inline-block w-75 mx-input mr-2 mb-2 mt-2': isEdit}" v-model="form.prdNo" minlength="1">
                <button class="btn btn-light wd-100" style="height: 37px;" @click="onCopy" v-if="isEdit">상품복사</button>
              </div>
            </div>

            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>상품명</label>
              <div class="col-sm-10">
                <input type="text" class="form-control d-inline-block w-75 mx-input mr-2 mb-2 mt-2" v-model="form.prdName" minlength="1" maxlength="30">
                <label class="col-form-label">30자 이내 ({{ form.prdName ? form.prdName.length : 0 }}/30)</label>
              </div>
            </div>

            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>상품명 (한글)</label>
              <div class="col-sm-10">
                <input type="text" class="form-control d-inline-block w-75 mx-input mr-2 mb-2 mt-2" v-model="form.prdNameKor" minlength="1" maxlength="30">
                <label class="col-form-label">30자 이내 ({{ form.prdNameKor ? form.prdNameKor.length : 0 }}/30)</label>
              </div>
            </div>


            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>카테고리</label>
              <div class="col-sm-4">
                <div class="col-6 pl-0 pr-0 float-left">
                  <select class="form-control" v-model="form.cat1Idx" @change="form.cat2Idx = ''">
                    <option value="">선택</option>
                    <option v-for="item in cat1List" :value="item.cat1Idx">{{item.cat1Name}}</option>
                  </select>
                </div>
                <div class="col-6 pl-0 pr-0 float-left">
                  <select class="form-control" v-model="form.cat2Idx" @change="chgPrdInfo">
                    <option value="">선택</option>
                    <option v-for="item in cat2List" :value="item.cat2Idx" v-if="form.cat1Idx === item.cat1Idx" :data-name="item.cat2Name">{{item.cat2Name}}</option>
                  </select>
                </div>
              </div>

              <!--
              <label class="col-sm-2 col-form-label"><span class="required">*</span>컬렉션타입</label>
              <div class="col-sm-4">
                <div class="col-6 pl-0 pr-0 float-left">
                  <select class="form-control" v-model="form.collectionType">
                    <option value=0>선택</option>
                    <option v-for="item in collectionType" :value="item.idx">{{item.collectionName}}</option>
                  </select>
                </div>
              </div>
              -->
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>상품설명</label>
              <div class="col-sm-10">
                <html-editor :content="content" :branch="'product'" @change-content="updateContent"/>
              </div>
            </div>

            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>상품정보제공고시</label>
              <div class="col-sm-10" v-if="prdNotiTitle.length == 0"><p>카테고리를 선택해주세요</p></div>
              <div class="col-sm-10" v-else>
                <div class="input-group pb-2" v-for="(keys, idx) in prdNotiTitle">
                  <label class="col-sm-3 col-form-label">{{keys}}</label>
                  <input type="text" class="form-control" v-model="prdNotiValue[idx]">
                </div>
              </div>
            </div>

            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>상품추가정보</label>
              <div class="col-sm-10" v-if="form.cat2Idx === ''"><p>카테고리를 선택해주세요</p></div>
              <div class="col-sm-10" v-else>
                <div class="input-group pb-2">
                  <label class="col-sm-3 col-form-label">핏</label>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="0" id="fit_0" name="fit" v-model="form.addInfo.fit">
                    <label class="custom-control-label" for="fit_0">해당없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="1" id="fit_1" name="fit" v-model="form.addInfo.fit">
                    <label class="custom-control-label" for="fit_1">슬림핏</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="2" id="fit_2" name="fit" v-model="form.addInfo.fit">
                    <label class="custom-control-label" for="fit_2">스탠다드핏</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="3" id="fit_3" name="fit" v-model="form.addInfo.fit">
                    <label class="custom-control-label" for="fit_3">루즈핏</label>
                  </div>
                </div>
                <div class="input-group pb-2">
                  <label class="col-sm-3 col-form-label">신축성</label>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="0" id="flexibility_0" name="flexibility" v-model="form.addInfo.flexibility">
                    <label class="custom-control-label" for="flexibility_0">해당없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="1" id="flexibility_1" name="flexibility" v-model="form.addInfo.flexibility">
                    <label class="custom-control-label" for="flexibility_1">없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="2" id="flexibility_2" name="flexibility" v-model="form.addInfo.flexibility">
                    <label class="custom-control-label" for="flexibility_2">조금 있음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="3" id="flexibility_3" name="flexibility" v-model="form.addInfo.flexibility">
                    <label class="custom-control-label" for="flexibility_3">있음</label>
                  </div>
                </div>
                <div class="input-group pb-2">
                  <label class="col-sm-3 col-form-label">비침</label>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="0" id="seethrough_0" name="seethrough" v-model="form.addInfo.seethrough">
                    <label class="custom-control-label" for="seethrough_0">해당없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="1" id="seethrough_1" name="seethrough" v-model="form.addInfo.seethrough">
                    <label class="custom-control-label" for="seethrough_1">없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="2" id="seethrough_2" name="seethrough" v-model="form.addInfo.seethrough">
                    <label class="custom-control-label" for="seethrough_2">약간</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="3" id="seethrough_3" name="seethrough" v-model="form.addInfo.seethrough">
                    <label class="custom-control-label" for="seethrough_3">있음</label>
                  </div>
                </div>
                <div class="input-group pb-2">
                  <label class="col-sm-3 col-form-label">두께</label>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="0" id="thickness_0" name="thickness" v-model="form.addInfo.thickness">
                    <label class="custom-control-label" for="thickness_0">해당없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="1" id="thickness_1" name="thickness" v-model="form.addInfo.thickness">
                    <label class="custom-control-label" for="thickness_1">얇음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="2" id="thickness_2" name="thickness" v-model="form.addInfo.thickness">
                    <label class="custom-control-label" for="thickness_2">보통</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="3" id="thickness_3" name="thickness" v-model="form.addInfo.thickness">
                    <label class="custom-control-label" for="thickness_3">두꺼움</label>
                  </div>
                </div>
                <div class="input-group pb-2">
                  <label class="col-sm-3 col-form-label">안감</label>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="0" id="lining_0" name="lining" v-model="form.addInfo.lining">
                    <label class="custom-control-label" for="lining_0">해당없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="1" id="lining_1" name="lining" v-model="form.addInfo.lining">
                    <label class="custom-control-label" for="lining_1">없음</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="2" id="lining_2" name="lining" v-model="form.addInfo.lining">
                    <label class="custom-control-label" for="lining_2">부분안감</label>
                  </div>
                  <div class="custom-radio custom-control custom-control-inline">
                    <input type="radio" class="custom-control-input" :value="3" id="lining_3" name="lining" v-model="form.addInfo.lining">
                    <label class="custom-control-label" for="lining_3">전체안감</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="main-card mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title">가격정보</div>
              </div>
              <div class="card-body">
                <div class="position-relative row form-group">
                  <label class="col-sm-1 col-form-label"><span class="required">*</span>정가</label>
                  <div class="col-sm-3">
                    <input type="number" class="form-control" v-model="form.prdPriceOrg" v-number-input min="0">
                  </div>

                  <label class="col-sm-1 col-form-label"><span class="required">*</span>판매가</label>
                  <div class="col-sm-3">
                    <input type="number" class="form-control" v-model="form.prdSalePrice" v-number-input min="0">
                  </div>

                  <label class="col-sm-1 col-form-label">할인율</label>
                  <div class="col-sm-3">
                    <input type="text" class="form-control" readonly v-model="discountRate">
                  </div>
                </div>
                <div class="position-relative row form-group">
                  <label class="col-sm-1 col-form-label"><span class="required">*</span>1회 최대 <br/> 판매가능수량</label>
                  <div class="col-sm-3">
                    <input type="number" class="form-control" v-model="form.maxSaleCnt" v-number-input min="0">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="main-card mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title">옵션설정</div>
              </div>
              <div class="card-body">
                <table class="mb-0 table table-detail">
                  <thead>
                    <tr>
                      <th class="text-center center wd-100" rowspan="2">
                        노출순서
                      </th>
                      <th class="text-center center" rowspan="2">
                        <span class="required">*</span>색상 별 이미지 등록<br />
                        ! 색상별 이미지는 최소 1개 ~ 최대 9개까지 등록
                        가능합니다.
                      </th>
                      <th class="text-center center" colspan="4">
                        <span class="required">*</span>사이즈별 옵션 설정
                      </th>
                    </tr>
                    <tr>
                      <th style="min-width: 60px;">사이즈</th>
                      <th><span class="required">*</span>재고수량</th>
                      <th>판매여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!form.optionList.length">
                      <td colspan="6" class="text-center">내역이 존재하지 않습니다.</td>
                    </tr>
                    <template v-for="(option, i) in form.optionList">
                      <tr v-for="(option2, j) in option.opt2List">
                        <td v-if="j === 0" :rowspan="option.rowspan">
                          <p v-if="i === 0" class="text-center">대표색상</p>
                          <select class="form-control mb-2" v-model="option.optOrder" @change="changeOrderOption(i, $event.target.value)">
                            <option v-for="num in itemCnt" :value="num">{{ num }}</option>
                          </select>
                          <button v-if="i !== 0" class="btn btn-light w-100" @click="deleteOption(i)">삭제</button>
                        </td>
                        <td v-if="j === 0" :rowspan="option.rowspan">
                          <div>
                            <div class="d-inline-block mr-2" style="width: 15px; height: 15px; vertical-align: middle;" :style="{ 'background-color': option.colCode }"></div>
                            <label class="">({{ option.colName }}/{{option.colShortCode }})</label>
                            <!--
                            <button class="btn btn-secondary ml-2 " @click="itemList.imgArrSize >= 5 ? noti('색상별 이미지는 최대 5개까지 등록 가능합니다.') : itemList.imgArrSize+=1">이미지추가</button>
                            -->
                          </div>
                          <div v-if="form.optionList.length > 0">
                            <draggable>
                              <transition-group :class="`${option.colIdx}-wrapper`">
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img0" :id="`${option.colIdx}-0`" v-if="option.img0">
                                  <img v-viewer class="popupImageItem images" :src="option.img0.preview" v-if="option.img0.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div style="cursor: pointer">
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-0`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,0,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 0, 'add')" :id="`add-file-${i}-${j}-0`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img1" :id="`${option.colIdx}-1`" v-if="option.img1">
                                  <img v-viewer class="popupImageItem images" :src="option.img1.preview" v-if="option.img1.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-1`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)"
                                        @click="deleteColorItem($event,i,1,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 1, 'add')" :id="`add-file-${i}-${j}-1`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img2" :id="`${option.colIdx}-2`" v-if="option.img2">
                                  <img v-viewer class="popupImageItem images" :src="option.img2.preview" v-if="option.img2.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-2`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,2,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 2, 'add')" :id="`add-file-${i}-${j}-2`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img3" :id="`${option.colIdx}-3`" v-if="option.img3">
                                  <img v-viewer class="popupImageItem images" :src="option.img3.preview" v-if="option.img3.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-3`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,3,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 3, 'add')" :id="`add-file-${i}-${j}-3`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img4" :id="`${option.colIdx}-4`" v-if="option.img4">
                                  <img v-viewer class="popupImageItem images" :src="option.img4.preview" v-if="option.img4.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-4`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,4,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 4, 'add')" :id="`add-file-${i}-${j}-4`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img5" :id="`${option.colIdx}-5`" v-if="option.img5">
                                  <img v-viewer class="popupImageItem images" :src="option.img5.preview" v-if="option.img5.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-5`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,5,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 5, 'add')" :id="`add-file-${i}-${j}-5`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img6" :id="`${option.colIdx}-6`" v-if="option.img6">
                                  <img v-viewer class="popupImageItem images" :src="option.img6.preview" v-if="option.img6.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-6`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,6,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 6, 'add')" :id="`add-file-${i}-${j}-6`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img7" :id="`${option.colIdx}-7`" v-if="option.img7">
                                  <img v-viewer class="popupImageItem images" :src="option.img7.preview" v-if="option.img7.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-7`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)" @click="deleteColorItem($event,i,7,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 7, 'add')" :id="`add-file-${i}-${j}-7`"/>
                                  </div>
                                </div>
                                <div class="d-inline-block mr-2 mb-2 img-element" key="img8" :id="`${option.colIdx}-8`" v-if="option.img8">
                                  <img v-viewer class="popupImageItem images" :src="option.img8.preview" v-if="option.img8.preview"/>
                                  <img src="@/assets/images/no-image.png" class="popupImageItem" v-else/>
                                  <div>
                                    <span class="float-left pl-3"><a href="javascript:void(0)"><label :for="`add-file-${i}-${j}-8`">첨부</label></a></span>
                                    <span class="float-right pr-3"><a href="javascript:void(0)"
                                        @click="deleteColorItem($event,i,8,'delete')"><label>삭제</label></a></span>
                                    <input type="file" class="form-control-file" hidden accept="image/jpeg, image/jpg, image/png" @change="previewFileImage($event, i, 8, 'add')" :id="`add-file-${i}-${j}-8`"/>
                                  </div>
                                </div>
                              </transition-group>
                            </draggable>
                          </div>
                        </td>
                        <td class="text-center">{{ option2.size }}</td>
                        <td class="text-center">
                          <input type="text" class="form-control" maxlength="4" v-model="option2.optStock" :disabled="option2.optIsUse === 0" v-number-input/>
                        </td>
                        <td class="text-center">
                          <input type="radio" class="custom-radio m-1" :value="1" :name="'radio_' + i + '_' + j" v-model="option2.optIsUse"/>
                          <label>판매중</label>
                          <input type="radio" class="custom-radio m-1" :value="0" :name="'radio_' + i + '_' + j" v-model="option2.optIsUse"/>
                          <label>판매중지</label>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <div class="position-relative row form-group pt-3">
                  <label class="col-sm-1 col-form-label text-center">상품 동영상 URL </label>
                  <div class="col-sm-11">
                    <input type="text" class="form-control w-100" v-model="form.videoUrl" maxlength="250">
                  </div>
                </div>
              </div>
              <div class="d-block text-center card-footer">
                <div class="col-12 pr-0 pl-0">
                  <div class="float-right">
                    <button class="btn btn-success wd-200" @click="callClassifyPop">색상 및 사이즈 구분 선택</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="main-card mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title">배송비 설정</div>
              </div>
              <div class="card-body">
                <div class="position-relative row form-group">
                  <label class="col-sm-1 col-form-label">개별배송여부</label>
                  <div class="col-sm-11 pt-2">
                    <div class="custom-radio custom-control custom-control-inline">
                      <input type="radio" class="custom-control-input" :value="0" v-model="form.prdDeliFeeIsEach" id="prdDeliFeeIsEachRadio_no"><label
                        class="custom-control-label" for="prdDeliFeeIsEachRadio_no">묶음 배송</label>
                    </div>
                    <div class="custom-radio custom-control custom-control-inline">
                      <input type="radio" class="custom-control-input" :value="1" v-model="form.prdDeliFeeIsEach" id="prdDeliFeeIsEachRadio_yes"><label
                        class="custom-control-label" for="prdDeliFeeIsEachRadio_yes">개별 배송</label>
                    </div>
                  </div>
                </div>
                <div class="position-relative row form-group">
                  <label class="col-sm-1 col-form-label"><span class="required">*</span>배송비</label>
                  <div class="col-sm-11">

                    <select class="form-control d-inline wd-100 mr-4" v-model="form.prdDeliFeeIsFree" :disabled="onlyPaid">
                      <option value=0>유료</option>
                      <option value=1>무료</option>
                      <option value=2>조건</option>
                    </select>

                    <div class="custom-radio custom-control custom-control-inline" v-show="form.prdDeliFeeIsFree == 0">
                      <input type="radio" class="custom-control-input d-inline" id="basicFeeRadio" :value="0" v-model="paidFlag" @change="chgPaidFlag">
                      <label class="custom-control-label d-inline" for="basicFeeRadio">기본 배송비</label>
                    </div>
                    <input type="text" class="form-control d-inline wd-100 mr-2" :value="basicFee" v-if="form.prdDeliFeeIsFree == 0" disabled>

                    <div class="custom-radio custom-control custom-control-inline" v-show="form.prdDeliFeeIsFree == 0">
                      <input type="radio" class="custom-control-input d-inline" id="inputFeeRadio" :value="1" v-model="paidFlag" @change="chgPaidFlag">
                      <label class="custom-control-label d-inline" for="inputFeeRadio">직접입력</label>
                    </div>
                    <input type="number" class="form-control d-inline wd-100 mr-2" v-model="inputFee" v-if="form.prdDeliFeeIsFree == 0" v-number-input>

                    <select class="form-control w-25 d-inline" v-if="form.prdDeliFeeIsFree == 2" v-model="form.prdDeliFeeFreeCondi">
                      <option value="30000">30,000</option>
                      <option value="50000">50,000</option>
                      <option value="100000">100,000</option>
                      <option value="150000">150,000</option>
                      <option value="200000">200,000</option>
                      <option value="250000">250,000</option>
                      <option value="300000">300,000</option>
                    </select>
                    <p class="d-inline" v-if="form.prdDeliFeeIsFree == 2">원 이상 무료</p>
                  </div>
                </div>

                <div class="position-relative row form-group">
                  <label class="col-sm-1 col-form-label">도서산간 배송비</label>
                  <div class="col-sm-11 pt-2">
                    <div class="custom-radio custom-control custom-control-inline">
                      <input type="radio" class="custom-control-input" :value="1" v-model="form.prdDeliFeeIsAdd" id="prdDeliFeeIsAddRadio_yes"><label
                        class="custom-control-label" for="prdDeliFeeIsAddRadio_yes">사용</label>
                    </div>
                    <div class="custom-radio custom-control custom-control-inline">
                      <input type="radio" class="custom-control-input" :value="0" v-model="form.prdDeliFeeIsAdd" id="prdDeliFeeIsAddRadio_no"><label
                        class="custom-control-label" for="prdDeliFeeIsAddRadio_no">사용안함</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="main-card mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title">
                  아래의 룩과 함께 (최대 3개)
                </div>
              </div>
              <div class="card-body">
                <table class="mb-0 table table-detail">
                  <thead>
                  <tr>
                    <th class="text-center"></th>
                    <th class="text-center">순서지정</th>
                    <th class="text-center">상품 카테고리</th>
                    <th class="text-center">대표이미지</th>
                    <th class="text-center">상품번호</th>
                    <th class="text-center">상품명</th>
                    <th class="text-center">판매가</th>
                    <th class="text-center">판매여부</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="lookProductList.length <= 0">
                    <td colspan="8" class="text-center">선택된 상품이 없습니다.</td>
                  </tr>
                  <tr v-else v-for="(item, idx) in lookProductList" :key="`look_${idx}`">
                    <td class="text-center"><input type="checkbox" :value="item.prdIdx" v-model="selectedList"></td>
                    <td class="text-center">
                      <select class="text-center form-control" v-model.number="item.order" @change="changeOrderItem(idx, $event.target.value)">
                        <option v-for="i in lookProductList.length" :val="i">{{i}}</option>
                      </select>
                    </td>
                    <td class="text-center">{{item.cat1Name}} > {{item.cat2Name}}</td>
                    <td class="text-center">
                      <img class="images" :src="getImagePath(item.prdMainImgThumb1)" style="width: 100px; height: 100px" v-image-error v-viewer />
                    </td>
                    <td class="text-center">{{item.prdNo}}</td>
                    <td class="text-center">{{item.prdName}}</td>
                    <td class="text-center">{{item.prdSalePrice | comma }}</td>
                    <td class="text-center">{{item.prdSaleStatus  | isSaleKeyMap(item.prdSaleStatus )}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-block text-center card-footer">
                <div class="col-12 pr-0 pl-0">
                  <div class="float-left">
                    <button class="btn btn-danger wd-100" @click="deleteLook">선택삭제</button>
                  </div>
                  <div class="float-right">
                    <button class="btn btn-success wd-100" @click="callProdSearchPop(3, 'look')">상품검색</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="main-card mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title">
                  아래의 아이템과 함께 (최대 1개)
                </div>
              </div>
              <div class="card-body">
                <table class="mb-0 table table-detail">
                  <thead>
                  <tr>
                    <th class="text-center"></th>
                    <th class="text-center">순서지정</th>
                    <th class="text-center">상품 카테고리</th>
                    <th class="text-center">대표이미지</th>
                    <th class="text-center">상품번호</th>
                    <th class="text-center">상품명</th>
                    <th class="text-center">판매가</th>
                    <th class="text-center">판매여부</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="itemProductList.length <= 0">
                    <td colspan="8" class="text-center">선택된 상품이 없습니다.</td>
                  </tr>
                  <tr v-else v-for="(item, idx) in itemProductList" :key="`look_${idx}`">
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td class="text-center">{{item.cat1Name}} > {{item.cat2Name}}</td>
                    <td class="text-center"><img class="images" :src="getImagePath(item.prdMainImgThumb1)" style="width: 100px; height: 100px" v-image-error v-viewer /></td>
                    <td class="text-center">{{item.prdNo}}</td>
                    <td class="text-center">{{item.prdName}}</td>
                    <td class="text-center">{{item.prdSalePrice | comma }}</td>
                    <td class="text-center">{{item.prdSaleStatus  | isSaleKeyMap(item.prdSaleStatus )}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-block text-center card-footer">
                <div class="col-12 pr-0 pl-0">
                  <div class="float-left">
                    <button class="btn btn-danger wd-100" @click="deleteItem">삭제</button>
                  </div>
                  <div class="float-right">
                    <button class="btn btn-success wd-100" @click="callProdSearchPop(1, 'item')">상품검색</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="main-card mb-3 card">
              <div class="card-header-tab card-header">
                <div class="card-header-title">판매설정</div>
              </div>
              <div class="card-body">
                <div class="position-relative row form-group">
                  <label class="col-sm-1 col-form-label">판매여부</label>
                  <div class="col-sm-11 pt-2">
                    <div class="custom-radio custom-control custom-control-inline">
                      <input type="radio" class="custom-control-input" id="prdSaleStatusRadio_yes" :value="1" v-model="form.prdSaleStatus"><label
                        class="custom-control-label" for="prdSaleStatusRadio_yes">판매중</label>
                    </div>
                    <div class="custom-radio custom-control custom-control-inline">
                      <input type="radio" class="custom-control-input" id="prdSaleStatusRadio_no" :value="-1" v-model="form.prdSaleStatus"><label
                        class="custom-control-label" for="prdSaleStatusRadio_no">판매중지</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center m-3">
          <button class="btn btn-lg btn-secondary ml-3 wd-150" @click="onList">목록</button>
          <button class="btn btn-lg btn-primary ml-3 wd-150" @click="onCreate" v-show="!isEdit">등록</button>
          <button class="btn btn-lg btn-primary ml-3 wd-150" @click="onUpdate" v-show="isEdit">수정</button>
        </div>

        <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label">등록일</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled v-model="form.prdRgdt"></div>
              <label class="col-sm-1 col-form-label">작성자</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled v-model="form.prdRgdtAdmId"></div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-1 col-form-label">수정일</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled v-model="form.prdLastUpdDt"></div>
              <label class="col-sm-1 col-form-label">수정자</label>
              <div class="col-sm-5"><input type="text" class="form-control" disabled v-model="form.prdLastUpdId"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/page/components/NavigationBar";
import HtmlEditor from "@/components/common/HtmlEditor";
import UploadPhoto from "@/components/common/UploadPhoto";
import SrchColorSizePop from "@/page/product/components/SrchColorSizePop";
import InterfaceMixin from "@/mixins/userInterface-mixin";
import SrchProductPop from "@/page/product/components/SrchProductPop";
import { CommonMixin } from "@/components/mixins/CommonMixin";
import CodeMixin from "@/mixins/code-mixin";
import draggable from "vuedraggable";

export default {
  name: "ProductRegistration",
  components: {
    NavigationBar,
    HtmlEditor,
    UploadPhoto,
    SrchColorSizePop,
    draggable
  },
  mixins: [InterfaceMixin, CommonMixin, CodeMixin],
  data() {
    return {
      isEdit: false,
      onlyPaid: false,
      rowspan: 0,
      itemCnt: 0,
      form: {
        prdIdx: "",
        prdNo: "",
        prdName: "",
        prdNameKor: "",
        cat1Idx: "",
        cat2Idx: "",
        collectionIdx: "",
        collectionType: 0,
        prdDesc: "",
        prdNotificationInfo: "",
        prdPriceOrg: 0,
        prdSalePrice: 0,
        prdDiscountRate: "",
        maxSaleCnt: 0,
        optionList: [],
        prdRgdt: "",
        prdRgdtAdmId: "",
        prdLastUpdDt: "",
        prdLastUpdId: "",
        prdSaleStatus: 1,
        prdDeliFeeIsFree: 1,
        prdDeliFeeIsEach: 0,
        prdDeliFeeIsAdd: 0,
        prdDeliFee: 0,
        prdDeliFeeFreeCondi: "",
        lookList: [],
        itemList: [],
        addInfo: {
          fit: 0,
          flexibility: 0,
          lining: 0,
          seethrough: 0,
          thickness: 0
        }
      },
      basicFee: "",
      inputFee: "",
      isAccType: false,
      paidFlag: 0,
      datas: [],
      content: "",
      cat1List: [],
      cat2List: [],
      collectionType: [],
      itemProductList: [],
      lookProductList: [],
      selectedList: [],
      prdNotiValue: {},
      prdNotiTitle: [],
      search: "",
      mcksCsInfo: "Mckayson / 1877-7305",
      imgMaxCount: 9
    };
  },
  computed: {
    discountRate: function() {
      if (this.form.prdSalePrice > 0 && this.form.prdPriceOrg > 0) {
        return (
          100 -
          Math.round((this.form.prdSalePrice / this.form.prdPriceOrg) * 100) +
          "%"
        );
      } else return "";
    }
  },
  watch: {
    "form.prdDeliFeeIsEach": function() {
      if (this.form.prdDeliFeeIsEach == 1) {
        this.form.prdDeliFeeIsFree = 0;
        this.onlyPaid = true;
      } else this.onlyPaid = false;
    },
    form: {
      deep: true,
      handler(value) {
        let reg = /[^0-9]/g;
        value.prdPriceOrg = value.prdPriceOrg.toString().replace(reg, "");
        value.prdSalePrice = value.prdSalePrice.toString().replace(reg, "");
        value.maxSaleCnt = value.maxSaleCnt.toString().replace(reg, "");
      }
    }
  },
  created() {
    let prdIdx = this.$route.query.prdIdx;
    if (prdIdx) {
      this.isEdit = true;

      this.form.prdIdx = prdIdx;
      this.setData(prdIdx);
    }

    let search = this.$route.query.search;
    this.search = search;
  },
  mounted() {
    this.getCategory();
    // this.getCollectionType();

    // 배송비 설정 관련
    this.getFeeInfo();
  },
  methods: {
    _isInvalidateInputForm() {
      if (!this.form.prdNo) {
        this.alert("상품번호을 입력해주세요.");
        return false;
      }
      if (!this.form.prdName) {
        this.alert("상품명을 입력해주세요.");
        return false;
      }
      if (!this.form.prdNameKor) {
        this.alert("한글 상품명을 입력해주세요.");
        return false;
      }
      if (!this.form.cat1Idx || !this.form.cat2Idx) {
        this.alert("카테고리를 선택해주세요.");
        return false;
      }

      this.cat2List.forEach(val => {
        let cat1 = this.form.cat1Idx;
        let cat2 = this.form.cat2Idx;

        if (val.cat1Idx == cat1 && val.cat2Idx == cat2) {
          if (val.cat2Name == "ACC") {
            this.isAccType = true;
          }
        }
      });

      /*
      if (!this.isAccType && ! +this.form.collectionType) {
        this.alert('컬렉션타입을 선택해주세요.')
        return false
      }
      */
      if (!this.content) {
        this.alert('상품설명을 입력해주세요.')
        return false
      }
      if (this._validPrdNotification()) {
        this.alert('상품정보제공고시를 입력해주세요.')
        return false
      }
      if (this.form.prdPriceOrg == '' || this.form.prdPriceOrg == 0) {
        this.alert('정가를 입력해주세요.')
        return false
      }
      if (this.form.prdSalePrice == '' || this.form.prdSalePrice == 0) {
        this.alert('판매가를 입력해주세요.')
        return false
      }
      if (this.form.maxSaleCnt === '') {
        this.alert('1회 최대 판매가능수량을 입력해주세요.')
        return false
      }
      if (this.form.optionList.length == 0) {
        this.alert('옵션을 등록해주세요.')
        return false
      }

      if (this.form.optionList.length > 0) {
        for (let i = 0; i < this.form.optionList.length; i++) {
          let el = this.form.optionList[i];
          if (!this.isEdit) {
            let count = 0;

            for (let j = 0; j < this.imgMaxCount; j++) {
              if (el[`img${j}`].files) {
                count++;
              }
            }
            if (count === 0) {
              this.alert(
                `[${el.colName}] 색상 이미지를 최소 1개 이상 등록해주세요.`
              );
              return false;
            }
          } else {
            let count = 0;

            for (let j = 0; j < this.imgMaxCount; j++) {
              if (el[`img${j}`].preview !== "") {
                count++;
              }
            }
            if (count === 0) {
              this.alert(
                `[${el.colName}] 색상 이미지를 최소 1개 이상 등록해주세요.`
              );
              return false;
            }
          }
        }
      }

        // if (this.lookProductList.length == 0) {
        //   this.alert('아래의 룩과 함께를 등록해주세요.')
        //   return false
        // }
        // if (this.itemProductList.length == 0) {
        //   this.alert('아래의 아이템과 함께를 등록해주세요.')
        //   return false
        // }
        if (this.paidFlag == 1 && this.inputFee == 0) {
          this.alert('유료 배송비를 입력해주세요.')
          return false
        }

        return true
      },
      chgPrdInfo(e) {
        this.isAccType = false

      this.getPrdNotification(this.form.cat1Idx, this.form.cat2Idx);

      let selectedCategoryName =
        e.target.options[e.target.options.selectedIndex].dataset.name;
      if (selectedCategoryName && selectedCategoryName.toUpperCase() == "ACC") {
        this.isAccType = true;
      }
    },
    onCopy() {
      this.isEdit = false;
      this.form.prdNo = this.form.prdNo + "_C";
      this.form.prdName = this.form.prdName + "_Copy";
      this.form.prdNameKor = this.form.prdNameKor + "_복사본";
      this.content = this.content;
      this.form.prdSaleStatus = -1;
      this.form.optionList = [];
      this.itemProductList = [];
      this.lookProductList = [];
      this.form.videoUrl = "";

      this.alert("복사되었습니다.");
    },

    getPrdNotification(cat1, cat2) {
      let reqParam = {
        cat1Idx: cat1,
        cat2Idx: cat2
      };

      if (cat2) {
        this.$api.api.getProductNotificationInfo(reqParam).then(resp => {
          if (resp.data.result) {
            this.prdNotiTitle = resp.data.data;

            if (!this.isEdit) {
              this.prdNotiValue = {};
              this.prdNotiTitle.forEach((e, i) => {
                this.prdNotiValue[i] = ''
                if (i == this.prdNotiTitle.length - 1) {
                  this.prdNotiValue[i] = this.mcksCsInfo;
                }
              });
            }
          }
        });
      }
    },
    getFeeInfo() {
      this.$api.api.getFeeList().then(resp => {
        if (resp.data.result) {
          this.basicFee = resp.data.data.feeData.prdDeliFee;
          if (
            (this.basicFee && this.basicFee == this.form.prdDeliFee) ||
            this.form.prdDeliFee == 0
          ) {
            // 기본 배송비와 값이 같으면 기본 배송비로 셋팅
            this.paidFlag = 0;
          } else {
            // 기본 배송비와 다르면 직접 입력으로 셋팅
            this.paidFlag = 1;
            this.inputFee = this.form.prdDeliFee;
          }
        } else {
          this.alert("조회 실패");
        }
      });
    },
    setData(prdIdx) {
      const imgUrl = "//image.mckayson.com/";

      this.$api.api.getProductDetail({ idx: prdIdx }).then(resp => {
        if (resp.data.result) {
          this.form = resp.data.data;

          this.itemCnt = this.form.optionList.length;

          this.form.collectionType = this.form.collectionIdx;
          this.content = this.form.prdDesc;

          if (resp.data.data.addInfo === null) {
            this.form.addInfo = {
              fit: 0,
              flexibility: 0,
              lining: 0,
              seethrough: 0,
              thickness: 0
            }
          }

          // 상품 제공고시 카테고리별 타이틀 가져오기
          this.getPrdNotification(this.form.cat1Idx, this.form.cat2Idx);

          // 상품 제공고시 카테고리별 값 세팅
          let temp = {};
          Object.values(JSON.parse(this.form.prdNotificationInfo)).forEach(
            (value, idx) => {
              temp[idx] = value;
            }
          );
          this.prdNotiValue = temp;

          // 배송비 설정 관련
          this.getFeeInfo();

          // 아래의 룩과 함께/아래의 아이템과 함께 셋팅
          this.itemProductList = this.form.itemList;
          this.lookProductList = this.form.lookList;
          this.lookProductList.map((el, idx) => (el.order = idx + 1));

          // 색상 옵션별 이미지 셋팅
          let options = this.form.optionList.map(el => {
            let ob = {
              rowspan: el.opt2List.length,
              colCode: el.colCode,
              colName: el.colName,
              colShortCode: el.colShortCode,
              colIdx: el.colIdx,
              optOrder: el.optOrder,
              optIdx: el.optIdx,
              opt2List: el.opt2List,
              imgArrSize: el.imageList.length
            };

            let addPath = [];
            for (let i = 0; i < 9; i++) {
              let valName = `img${i}`;
              if (el.imageList[i]) {
                addPath.push(el.imageList[i].filename);

                ob[valName] = {
                  preview: imgUrl + el.imageList[i].filename
                };
              } else {
                ob[valName] = { preview: "" };
              }
            }
            ob.imgList = addPath;
            return ob;
          });

          this.form.optionList = options;
        }
      });
    },
    async getImageConverToFileObj(url) {
      const response = await fetch(url);
      const data = await response.blob();
      const filename = url.split("/").pop();

      return new Promise(resolve => {
        resolve(new File([data], filename, { type: data.type }));
      });
    },
    onList() {
      this.$router.push({
        path: "/product/list",
        query: { search: this.search }
      });
    },
    noti(message) {
      this.alert(message, () => {
        return null;
      });
    },
    updateContent(value) {
      this.content = value;
    },
    chgPaidFlag() {
      if (this.paidFlag == 0) {
        this.form.prdDeliFee = "";
      }
    },
    getCategory() {
      this.$api.api.getCategory().then(resp => {
        if (resp.data.result) {
          this.cat1List = resp.data.data;

          this.cat1List.map(category1 => {
            category1.cat2List.map(category2 => {
              this.cat2List.push({
                cat1Idx: category1.cat1Idx,
                cat2Idx: category2.cat2Idx,
                cat2Name: category2.cat2Name
              });
            });
          });
        } else {
          this.alert("카테고리 조회 실패");
        }
      });
    },
    getCollectionType() {
      this.$api.api.getCollectionType().then(resp => {
        if (resp.data.result) {
          this.collectionType = resp.data.data;
        } else {
          this.alert("컬렉션 타입 조회 실패");
        }
      });
    },

      checkFileExtension(obj) {
        if (obj.value) {
          let file_kind = obj.value.lastIndexOf('.');
          let file_name = obj.value.substring(file_kind + 1, obj.length);
          let file_type = file_name.toUpperCase();
          if (file_type != "JPG" && file_type != "JPEG" && file_type != "PNG") {
            this.alert("이미지는 JPG, JPEG, PNG 파일만 업로드 가능합니다.");
            return false
          }
        }
        return true
      },
      previewFileImage(e, i, cnt, cmd) {
        if (cmd === 'add' && !e.target.files[0]) {
          console.log('없음')
          return
        }
        if (!this.checkFileExtension(e.target)) {
          return false
        }

      let reader = new FileReader();
      let option = this.form.optionList[i];
      let valName = "img" + cnt;

      option[valName] = {};

      if (cmd === "add") {
        option[valName] = {
          index: cnt,
          files: e.target.files[0],
          preview: ""
        };
      }

      let input = e.target;
      if (input.files && input.files[0]) {
        reader.onload = e => {
          let option = this.form.optionList[i];
          let valName = "img" + cnt;
          option[valName].preview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    deleteColorItem(e, i, cnt, cmd) {
      // 첫번째 엘리먼트
      const imgWrapperEl = document.getElementsByClassName(
        `${this.form.optionList[i].colIdx}-wrapper`
      )[0].children[0];
      const elNum = Number(imgWrapperEl.id.split("-")[1]);

      if (elNum === cnt) {
        this.noti("대표이미지는 삭제하실 수 없습니다.");
      } else {
        this.previewFileImage(e, i, cnt, cmd);
        this.form.optionList[i].imgList[cnt] = "";
      }
    },
    callClassifyPop() {
      let selectedSizeClassIdx = "";
      if (this.form.optionList.length) {
        selectedSizeClassIdx = this.form.optionList[0].opt2List[0].sizeClaIdx;
      }

      this.$modal.show(
        SrchColorSizePop,
        { selectedSizeClassIdx: +selectedSizeClassIdx },
        {
          draggable: false,
          resizable: true,
          height: "auto",
          adaptive: true,
          minWidth: 800
        },
        {
          "before-close": event => {
            if (event.params) {
              // 신규등록
              if (this.form.optionList.length == 0) {
                this.form.optionList = event.params.optionList;
                console.log(this.form.optionList);

                // 수정
              } else {
                let newOptions = [];

                // 기존에 등록되어 있는 색상이면 없는 사이즈만 추가한다.
                loop: for (let option of event.params.optionList) {
                  for (let existingOption of this.form.optionList) {
                    let optIdxs = option.opt2List.map(el => el.sizeIdx);
                    let existingOptIdxs = existingOption.opt2List.map(
                      el => el.sizeIdx
                    );

                    let newSizes = optIdxs.filter(
                      x => !existingOptIdxs.includes(x)
                    );

                    if (option.colIdx == existingOption.colIdx) {
                      for (let newSize of newSizes) {
                        option.opt2List.map(e => {
                          if (e.sizeIdx == newSize) {
                            existingOption.rowspan += 1;
                            existingOption.opt2List.push(e);
                          }
                        });
                      }
                      continue loop;
                    }
                  }
                  newOptions.push(option);
                }

                // 새로운 색상은 추가한다.
                newOptions.map(el => {
                  this.form.optionList.push(el);
                });

                this.reorder();
              }

              this.itemCnt = this.form.optionList.length;
            }
          }
        }
      );
    },
    callProdSearchPop(cnt, flag) {
      this.$modal.show(
        SrchProductPop,
        { quantityLimit: cnt },
        {
          draggable: false,
          resizable: true,
          height: "auto",
          adaptive: true,
          minWidth: 1200
        },
        {
          "before-close": event => {
            if (flag === "look") {
              this.form.lookProductList = [];
              if (event.params) {
                this.lookProductList = event.params;
                this.lookProductList.map((el, i) => {
                  el.order = i + 1;
                });
              }
            }
            if (flag === "item") {
              this.form.itemProductList = [];
              if (event.params) {
                this.itemProductList = event.params;
              }
            }
          }
        }
      );
    },
    changeOrderOption(index, order) {
      Array.prototype.swap = function(i, k) {
        let tmp = "";
        tmp = this[i];
        this[i] = this[k];
        this[k] = tmp;
      };

      let arr = this.form.optionList;
      arr.swap(index, order - 1);

      arr.map((el, i) => (el.optOrder = i + 1));

      this.form.optionList = [];
      arr.map(el => {
        this.form.optionList.push(el);
      });
    },
    changeOrderItem(index, order) {
      Array.prototype.swap = function(i, k) {
        let tmp = "";
        tmp = this[i];
        this[i] = this[k];
        this[k] = tmp;
      };

      let arr = this.lookProductList;
      arr.swap(index, order - 1);

      arr.map((el, i) => (el.order = i + 1));

      this.lookProductList = [];
      arr.map(el => {
        this.lookProductList.push(el);
      });
    },
    //NOTE: vue-draggable v-model type이 array인데, 기존 방식이 obj형태라.. 강제로 엘리먼트 트리 가져와서 순서 재조정.
    // array로 변경 시 해당 화면 전체를 새로 다시 만드는게 빠를듯
    async onUploadFile(resolve, reject) {
      const optionCnt = this.itemCnt;

      for (let i = 0; i < optionCnt; i++) {
        if (
          !this.form.optionList[i].img0.files &&
          !this.form.optionList[i].img1.files &&
          !this.form.optionList[i].img2.files &&
          !this.form.optionList[i].img3.files &&
          !this.form.optionList[i].img4.files &&
          !this.form.optionList[i].img5.files &&
          !this.form.optionList[i].img6.files &&
          !this.form.optionList[i].img7.files &&
          !this.form.optionList[i].img8.files
        ) {
          // 새로운 for문으로 imgList 순서 맞추기
          const tmpImgArr = [];
          const imgWrapperEl = document.getElementsByClassName(
            `${this.form.optionList[i].colIdx}-wrapper`
          )[0].children;
          //수정일떄 이미지 리스트 순서 조정 필요
          for (let j = 0; j < imgWrapperEl.length; j++) {
            const elNum = imgWrapperEl[j].id.split("-");
            const fileName = this.form.optionList[i][`img${elNum[1]}`].preview;
            if (fileName) {
              const fileSpliter = fileName.split("//image.mckayson.com/");
              tmpImgArr.push(fileSpliter[fileSpliter.length - 1]);
            }
          }
          this.form.optionList[i].imgList = tmpImgArr;
        } else {
          let fm = new FormData();
          let arr = [];

          // tmp obj 이미지 빈 객체로 초기화
          let imgTmpObj = {};
          for (let i = 0; i < this.imgMaxCount; i++) {
            imgTmpObj[`img${i}`] = {};
          }
          // img wraepper element 가져오기
          const imgWrapperEl = document.getElementsByClassName(
            `${this.form.optionList[i].colIdx}-wrapper`
          )[0].children;
          // 순서 맞추기
          for (let j = 0; j < imgWrapperEl.length; j++) {
            // 1 : option idx    2 : img idx
            const elNum = imgWrapperEl[j].id.split("-");
            if (this.form.optionList[i][`img${elNum[1]}`].files) {
              imgTmpObj[`img${j}`] = this.form.optionList[i][
                `img${Number(elNum[1])}`
              ];
              imgTmpObj[`img${j}`].index = j;
              fm.append("imageFiles", imgTmpObj[`img${j}`].files);
              arr.push(imgTmpObj[`img${j}`].index);
            }
          }

          if (!this.isEdit) {
            this.form.optionList[i].imgList = [];
          } else {
            // 새로운 for문으로 imgList 순서 맞추기
            const tmpImgArr = [];
            const imgWrapperEl = document.getElementsByClassName(
              `${this.form.optionList[i].colIdx}-wrapper`
            )[0].children;
            //수정일떄 이미지 리스트 순서 조정 필요
            for (let j = 0; j < imgWrapperEl.length; j++) {
              const elNum = imgWrapperEl[j].id.split("-");
              const fileName = this.form.optionList[i][`img${elNum[1]}`]
                .preview;
              if (
                fileName &&
                this.form.optionList[i][`img${elNum[1]}`].files === undefined
              ) {
                const fileSpliter = fileName.split("//image.mckayson.com/");
                tmpImgArr[j] = fileSpliter[fileSpliter.length - 1];
              }
            }
            this.form.optionList[i].imgList = tmpImgArr;
          }

          await this.$api.api.uploadFile(fm, "product").then(resp => {
            if (resp.data.result) {
              console.log(resp.data.result);
              resp.data.data.map((el, idx) => {
                this.form.optionList[i].imgList[arr[idx]] = el.filename;
              });
            } else {
              console.log("Fail to upload image files");
              reject();
            }
          });
        }
      }
      resolve();
    },
    onCreate() {
      this.confirm("등록 하시겠습니까?", () => {
        if (!this._isInvalidateInputForm()) return;

        let _this = this;
        this.onUploadFile(
          function() {
            // 옵션
            let newOptionList = [];
            for (let option of _this.form.optionList) {
              let newOpt2List = option.opt2List.map(option2 => {
                return {
                  optIsUse: option2.optIsUse,
                  optStock: +option2.optStock,
                  sizeIdx: option2.sizeIdx
                };
              });

              newOptionList.push({
                colIdx: option.colIdx,
                imgList: option.imgList,
                optOrder: option.optOrder,
                opt2List: newOpt2List
              });
            }

            // 아래의 룩과 함께, 아래의 아이템과 함께
            let newLookProductList = _this.lookProductList.map(e => e.prdIdx);
            let newItemProductList = _this.itemProductList.map(e => e.prdIdx);

            // 상품 제공고시
            let objNotificationInfo = {};
            for (let key of _this.prdNotiTitle) {
              objNotificationInfo[key] = _this.prdNotiValue[
                _this.prdNotiTitle.indexOf(key)
              ]
                ? _this.prdNotiValue[_this.prdNotiTitle.indexOf(key)]
                : "";
            }

            let newPrdNotificationInfo = JSON.stringify(objNotificationInfo);

            // 유료 배송비 기본배송비 검사
            if (_this.paidFlag == 0) {
              _this.form.prdDeliFee = _this.basicFee;
            } else _this.form.prdDeliFee = _this.inputFee;

            let reqParam = {
              cat1Idx: _this.form.cat1Idx,
              cat2Idx: _this.form.cat2Idx,
              // collectionType: _this.form.collectionType,
              collectionType: 0,
              maxSaleCnt: _this.form.maxSaleCnt,
              prdDeliFee: _this.form.prdDeliFee,
              // prdDeliFeeAdd: form.prdDeliFeeAdd,
              prdDeliFeeFreeCondi: _this.form.prdDeliFeeFreeCondi,
              prdDeliFeeIsAdd: _this.form.prdDeliFeeIsAdd,
              prdDeliFeeIsEach: _this.form.prdDeliFeeIsEach,
              prdDeliFeeIsFree: _this.form.prdDeliFeeIsFree,
              prdDesc: _this.content,
              prdSaleStatus: _this.form.prdSaleStatus,
              prdName: _this.form.prdName,
              prdNameKor: _this.form.prdNameKor,
              prdNo: _this.form.prdNo,
              prdPriceOrg: _this.form.prdPriceOrg,
              prdSalePrice: _this.form.prdSalePrice,
              videoUrl: _this.form.videoUrl,
              prdNotificationInfo: newPrdNotificationInfo,
              itemProductList: newItemProductList,
              lookProductList: newLookProductList,
              optionList: newOptionList,
              addInfo: _this.form.addInfo
            };

            _this.$api.api.registerProduct(reqParam).then(resp => {
              if (resp.data.result) {
                _this.alert("등록되었습니다.", () => _this.onList());
              } else {
                _this.alert(resp.data.errMsg);
                return;
              }
            });
          },
          function() {
            this.alert("파일 업로드 실패");
          }
        );
      });
    },
    onUpdate() {
      this.confirm("수정 하시겠습니까?", () => {
        if (!this._isInvalidateInputForm()) return;

        let _this = this;
        this.onUploadFile(
          function() {
            let newOptionList = [];

            for (let option of _this.form.optionList) {
              let newOpt2List = option.opt2List.map(option2 => {
                return {
                  optIdx: option2.optIdx,
                  optIsUse: option2.optIsUse,
                  optStock: +option2.optStock,
                  sizeIdx: option2.sizeIdx
                };
              });

              let imgList = option.imgList.filter(option => {
                return option;
              });

              newOptionList.push({
                optIdx: option.optIdx,
                colIdx: option.colIdx,
                optOrder: option.optOrder,
                opt2List: newOpt2List,
                imgList: imgList
              });
            }

            // 아래의 룩과 함께, 아래의 아이템과 함께
            let newLookProductList = _this.lookProductList.map(e => e.prdIdx);
            let newItemProductList = _this.itemProductList.map(e => e.prdIdx);

            // 상품 제공고시
            let objNotificationInfo = {};
            for (let key of _this.prdNotiTitle) {
              objNotificationInfo[key] = _this.prdNotiValue[
                _this.prdNotiTitle.indexOf(key)
              ]
                ? _this.prdNotiValue[_this.prdNotiTitle.indexOf(key)]
                : "";
            }

            let newPrdNotificationInfo = JSON.stringify(objNotificationInfo);

            // 유료 배송비 기본배송비 검사
            if (_this.paidFlag == 0) {
              _this.form.prdDeliFee = _this.basicFee;
            } else _this.form.prdDeliFee = _this.inputFee;

            let reqParam = {
              prdIdx: _this.form.prdIdx,
              cat1Idx: _this.form.cat1Idx,
              cat2Idx: _this.form.cat2Idx,
              // collectionType: _this.form.collectionType,
              collectionType: 0,
              maxSaleCnt: +_this.form.maxSaleCnt,
              prdDeliFee: _this.form.prdDeliFee,
              // prdDeliFeeAdd: form.prdDeliFeeAdd,
              prdDeliFeeFreeCondi: _this.form.prdDeliFeeFreeCondi,
              prdDeliFeeIsAdd: _this.form.prdDeliFeeIsAdd,
              prdDeliFeeIsEach: _this.form.prdDeliFeeIsEach,
              prdDeliFeeIsFree: _this.form.prdDeliFeeIsFree,
              prdDesc: _this.content,
              prdSaleStatus: _this.form.prdSaleStatus,
              prdName: _this.form.prdName,
              prdNameKor: _this.form.prdNameKor,
              prdNo: _this.form.prdNo,
              prdPriceOrg: _this.form.prdPriceOrg,
              prdSalePrice: _this.form.prdSalePrice,
              optionList: newOptionList,
              itemProductList: newItemProductList,
              lookProductList: newLookProductList,
              prdNotificationInfo: newPrdNotificationInfo,
              videoUrl: _this.form.videoUrl,
              addInfo: _this.form.addInfo
            };

            _this.$api.api.updateProduct(reqParam).then(resp => {
              if (resp.data.result) {
                _this.alert("수정되었습니다.", () => _this.$router.go(0));
              } else {
                _this.alert(resp.data.errMsg);
                return;
              }
            });
          },
          function() {
            this.alert("파일 업로드 실패");
          }
        );
      });
    },

    deleteItem() {
      this.itemProductList = [];
    },
    deleteLook() {
      if (this.selectedList.length <= 0) {
        this.alert("삭제할 대상을 선택해주세요.");
        return;
      }
      this.selectedList.forEach(value => {
        this.lookProductList.map((el, idx) => {
          if (value == el.prdIdx) {
            this.lookProductList.splice(idx, 1);
          }
        });
      });

      this.lookProductList.map((el, idx) => {
        el.order = idx + 1;
      });
      this.selectedList = [];
    },
    reorder() {
      this.form.optionList.map((el, i) => {
        el.optOrder = i + 1;
      });
    },
    deleteOption(row) {
      this.confirm("삭제 하시겠습니까?", () => {
        this.form.optionList.splice(row, 1);
        this.itemCnt -= 1;

          this.reorder()
        })
      },
    _validPrdNotification(){
      let array = Object.values(this.prdNotiValue)
      let notVallid = array.some((item,index) => {
        return item === ''
      })
      return notVallid
    }
    }
  }
</script>

<style scoped>
.text-center.center {
  vertical-align: middle;
}
.popupImageItem {
  width: 136px;
  height: 175px;
}
/*
.jb-wrap {
  margin: 10px auto;
}
.jb-wrap img {
  vertical-align: middle;
}
.jb-text {
  padding-right: 36px;
  padding-left: 22px;
}
*/
</style>
