<template>
  <div>

    <!--
    <input ref="imageInput" type="file" hidden accept=".jpg,.png,.jpeg,.gif,.bmp" @change="chgImage" />
    -->
    <div v-if="innerFile.src">
      <img class="images" :style="`height: ${height}px; width: auto`" ref="imagePreview" :src="innerFile.src" v-image-error v-viewer />
<!--      <button class="btn btn-dark image-remove" @click="delImage"></button>-->
    </div>
    <!--
    <input ref="imageInput" type="file" hidden accept="image/*" @change="chgImage" />
    -->
    <input ref="imageInput" type="file" hidden accept=".jpg, .png, .jpeg, .bmp, .gif" @change="chgImage" />
    <button class="btn btn-primary mt-2" @click="$refs.imageInput.click()"><i class="fa fa-plus mr-2"></i>Select files</button>
    <slot name="deleteButton"></slot>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import UserInterfaceMixin from '@/mixins/userInterface-mixin'

export default {
  name: 'upload-photo',
  data () {
    return {
      innerFile: {
        w: '',
        h: '',
        path:'',
        src: '',
        name: '',
        thumb1: '',
      }
    }
  },
  mixins: [
    CommonMixin,
    UserInterfaceMixin
  ],
  components: {
  },
  props: {
    file: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    branch: {
      type: String,
      default: 'mckayson_img'
    },
    height: {
      type: Number|String,
      default: 150
    }
  },
  created () {
    this.innerFile = this.file
  },
  mounted () {
  },
  methods: {
    checkFileType(file) {
      let file_name = file
      let dot = file_name.lastIndexOf('.');
      let file_ext = file_name.substring(dot + 1, file_name.length);
      let file_type = file_ext.toUpperCase();

      console.log("@@ file_name >>>>> ", file_name)
      console.log("@@ file_type >>>>> ", file_type)

      if (file_type != "JPG" && file_type != "JPEG" && file_type != "PNG" && file_type != "BMP" && file_type != "GIF") {
        this.alert("이미지 파일만 업로드 가능합니다.");
        return false
      } else {
        return true
      }
    },
    changedOk(file, list, index) {
      if (!this.checkFileType(file.name)) {
        return false
      } else {
        let imgData = list[index]
        imgData.filename = file.name
        imgData.path = file.path
      }
    },
    chgImage (e) {
      if (!this.checkFileType(e.target.files[0].name)) {
        return false
      }

      if (!e.target.files[0]) return

      let name = e.target.files[0].name.split('.').slice(0, -1).join('.')
      let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^+<>@\#$%&\\\=\(\'\"]/gi;
      if (regExp.test(name)) {
        this.$refs.imageInput.value = null
        this.alert('제한된 파일명이 포함되어 있습니다.')
        return false
      }

      let formData = new FormData()
      formData.append('imageFiles', e.target.files[0])

      // 이미지 업로드 전 화면 Block처리
      this.blockUI()
      this.$api.api.uploadFile(formData, this.branch).then(resp => {
        if (resp.status === 200) {
          this.innerFile.w = resp.data.data[0].width
          this.innerFile.h = resp.data.data[0].height
          this.innerFile.path = resp.data.data[0].path
          this.innerFile.name = resp.data.data[0].filename
          this.innerFile.thumb1 = resp.data.data[0].thumb1
          this.$emit('changed', this.innerFile)
        }
      }).catch(error => {
        console.log(error)
        this.$refs.imageInput.value = null
        this.alert('업로드 중 오류가 발생했습니다.')
      }).finally(() => {
        // 업로드 완료 후 unBlock 처리
        this.unBlockUI()
      })
    },
    delImage (e) {
      this.innerFile = {}
      this.$emit('removed', this.innerFile)
    },
  },
  watch: {
    file (value) {
      this.innerFile = this.file
    }
  }
}

</script>
