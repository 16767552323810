import Vue from 'vue'

// 진행상태
Vue.filter('prmStatus', (value) => {
  switch (value) {
    case 1: return '진행전'
    case 2: return '진행중'
    case 3: return '종료'
    default: return value
  }
})

// 전시여부
Vue.filter('prmIsUse', (value) => {
  switch (value) {
    case 0: return '비전시'
    case 1: return '전시'
    default: return value
  }
})

export default {
  name: 'promotion-filter',
  data () {
    return {
    }
  }
}
