<template>
    <div class="main-card mb-3 card">
        <div class="card-header font-size-lg">프로모션</div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="float-left">
                        <div class="row form-group">
                            <div class="col-12 m-2">
                                <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-100"><span class="required">*</span>타이틀</h5>
                                <input type="text" placeholder="텍스트를 입력해 주세요." maxlength="20" class="form-control w-25 d-inline-block" v-model="title">
                                <span class="ml-1">({{ title.length }}/20)</span>
                            </div>
                            <div class="col-12 m-2">
                                <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-100">총 {{ chkList.length }} 건</h5>
                                <select class="custom-select wd-200 mr-1" v-model="displayStatus">
                                    <option value="">전체</option>
                                    <option value="0">미전시</option>
                                    <option value="1">전시</option>
                                </select>
                                <button class="btn btn-secondary ml-1 mr-0 wd-100" @click="onChangeStatus">선택변경</button>
                            </div>
                        </div>
                    </div>
                    <div class="float-right">
                        <button class="btn btn-primary wd-100" @click="addData"><i class="fa fa-plus mr-2"></i>추가</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <table class="table">
                    <colgroup>
                        <col width="10%" />
                        <col width="10%" />
                        <col width="*" />
                        <col width="10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="text-center"><input type="checkbox" v-model="chkAll" /></th>
                            <th class="text-center">순서변경</th>
                            <th class="text-center">내용</th>
                            <th class="text-center">전시상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="!list.length">
                            <tr>
                                <td class="text-center" colspan="4">등록된 정보가 존재하지 않습니다.</td>
                            </tr>
                        </template>
                        <template v-for="(item, itemIdx) in list">
                            <tr>
                                <td class="text-center" rowspan="3">
                                    <input type="checkbox" v-model="chkList" :value="item.idx" v-if="item.idx !== ''" />
                                    <i class="fa fa-times" style="cursor: pointer;" @click="deleteRow(itemIdx)" v-else></i>
                                </td>
                                <td class="text-center" rowspan="3">
                                    <select class="custom-select wd-100" style="margin: auto;" @change="onChangeSeq($event, itemIdx)" v-model="item.seq">
                                        <option v-for="sort in list.length" :value="sort">{{ sort }}</option>
                                    </select>
                                </td>
                                <td class="text-center">
                                    <div class="d-inline-block text-center p-2" style="vertical-align: top;">
                                        <p class="mt-1 mb-1">배경 이미지(PC)</p>
                                        <div style="width: 220px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                            <select class="custom-select wd-100 mr-2" v-model="item.backIdx">
                                                <option v-for="(bg, bgIdx) in bgList" :value="bg.idx">배경 {{ bgIdx + 1 }}</option>
                                            </select>
                                            <img v-if="bgList.length" :src="getImagePath(bgList.find(b => b.idx === item.backIdx ).image.filename)" style="width: 120px; height: 120px;" class="images" v-image-error v-viewer />
                                        </div>
                                    </div>
                                    <div class="d-inline-block text-center p-2" style="vertical-align: top;">
                                        <p class="mt-1 mb-1"><span class="required">*</span>메인 이미지(PC)</p>
                                        <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                            <upload-photo v-if="item.pcImg.filename === '' " branch="visual" @changed="changeImage($event, item.pcImg)" />
                                            <img v-else :src="getImagePath(item.pcImg.path + '/' + item.pcImg.filename)" style="width: 120px; height: 120px;" class="images" v-image-error v-viewer />
                                        </div>
                                    </div>
                                    <div class="d-inline-block text-center p-2" style="vertical-align: top;">
                                        <p class="mt-1 mb-1"><span class="required">*</span>메인 이미지(Mobile)</p>
                                        <div style="width: 120px; height: 120px;justify-content: center; display: flex;align-items: center;">
                                            <upload-photo v-if="item.mobileImg.filename === '' " branch="visual" @changed="changeImage($event, item.mobileImg)" />
                                            <img v-else :src="getImagePath(item.pcImg.path + '/' + item.mobileImg.filename)" style="width: 120px; height: 120px;" class="images" v-image-error v-viewer />
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center" rowspan="3">{{ item.displayStatus | displayStatus }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="position-relative">
                                        <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-100"><span class="required">*</span>기간 설정</h5>
                                        <date-picker value-type="format" format="YYYY-MM-DD" placeholder="YYYY-MM-DD" :editable="false" :clearable="true" v-model="item.tempStartDt" class="wd-150" />
                                        <input type="time" class="mx-input wd-150" v-model="item.tempStartTime" />
                                        <label class="m-1">~</label>
                                        <date-picker value-type="format" format="YYYY-MM-DD" placeholder="YYYY-MM-DD" :editable="false" :clearable="true" v-model="item.tempEndDt" class="wd-150" />
                                        <input type="time" class="mx-input wd-150" v-model="item.tempEndTime" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="position-relative">
                                        <h5 class="card-title float-left mb-0 mt-2 mr-2 pr-2 text-left wd-100">링크</h5>
                                        <input type="text" class="form-control form-control-sm wd-300" v-model="item.link" />
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <div class="col-12 pr-0 pl-0">
                <div class="float-left">
                    <button class="btn btn-danger ml-1 wd-100" @click="onDelete">선택삭제</button>
                </div>
                <div class="float-right">
                    <button class="btn btn-success ml-1 wd-100" @click="onSave">저장</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CommonMixin } from '@/components/mixins/CommonMixin'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import UploadPhoto from '@/components/common/UploadPhoto'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import moment from 'moment'

export default {
    name: 'GeneralPromotion',
    mixins: [ CommonMixin, InterfaceMixin ],
    components: {
        UploadPhoto,
        DatePicker
    },
    data () {
        return {
            chkList: [],
            list: [],

            visualIdx: '',
            title: '',
            displayStatus: '',
        }
    },
    validations: {
        title: {
            required
        },
        list: {
            required,
            $each: {
                pcImg: { filename: { required } },
                mobileImg: { filename: { required } },
                displayStrtDt: { required },
            }
        }
    },
    props: {
        itemType: {
            type: Number | String,
            default: 0
        },
        idx: {
            type: Number | String,
            default: ''
        },
        bgList: {
            type: Array,
            default: [],
        },
    },
    computed: {
        chkAll: {
            get () {
                return this.list.length ? this.chkList.length === this.list.length : false
            },
            set (value) {
                let selected = []

                if (value) {
                    this.list.map(item => {
                        selected.push(item.idx)
                    })
                }
                this.chkList = selected
            },
        },
    },
    created () {
        this.visualIdx = this.idx
        if (this.idx) {
            this.getDataList()
        }
    },
    mounted () {
    },
    methods: {
        // 목록조회
        getDataList () {
            return this.$api.api.getMngVisualCommonTemplate(this.visualIdx).then(resp => {
                this.title = resp.data.data.title
                this.list = []
                resp.data.data.promotionList.map(p => {
                    let temp = {
                        idx: p.idx,
                        seq: p.seq,
                        backIdx: p.backIdx,
                        displayEndDt: p.displayEndDt,
                        displayStrtDt: p.displayStrtDt,
                        displayStatus: p.displayStatus,
                        link: p.link,
                        pcImg: {
                            idx: p.pcImg.idx,
                            filename: p.pcImg.filename,
                            path: '',
                            width: 100,
                            height: 100,
                        },
                        mobileImg: {
                            idx: p.idx,
                            filename: p.mobileImg.filename,
                            path: '',
                            width: 100,
                            height: 100,
                        },
                    }
                    if (p.displayStrtDt !== null) {
                        temp.tempStartDt = moment(p.displayStrtDt).format('YYYY-MM-DD')
                        temp.tempStartTime = moment(p.displayStrtDt).format('HH:mm')
                    } else {
                        temp.tempStartTime = '00:00'
                    }
                    if (p.displayEndDt !== null) {
                        temp.tempEndDt = moment(p.displayEndDt).format('YYYY-MM-DD')
                        temp.tempEndTime = moment(p.displayEndDt).format('HH:mm')
                    } else {
                        temp.tempEndTime = '23:59'
                    }
                    this.list.push(temp)
                })
            }).catch(error => {
                console.log(error)
            })
        },
        onChangeStatus () {
            if (this.chkList.length <= 0) {
                this.alert('변경할 대상을 선택 해주세요.')
                return false
            }
            if (this.displayStatus == null || this.displayStatus === '') {
              this.alert('적용할 전시 상태를 선택 해주세요.')
              return false
            }
            this.chkList.map(chk => {
                this.list.find(item => {
                    if (chk === item.idx) {
                        item.displayStatus = Number(this.displayStatus)
                    }
                })
            })
        },
        // 순서변경
        onChangeSeq (event, thisIdx) {
            let temp = this.list[thisIdx]
            this.list.splice(thisIdx, 1)
            this.list.splice(event.target.value - 1, 0, temp)

            this.list.forEach((item, itemIdx) => {
                item.seq = itemIdx + 1
            })
        },
        // 이미지 업로드
        changeImage (file, thisObj) {
            thisObj.path = file.path
            thisObj.filename = file.name
        },
        // 추가
        addData () {
            if (this.bgList.length <= 0) {
                this.alert('배경이미지를 먼저 등록 해주세요.')
                return false
            }
            let temp = {
                idx: '',
                seq: this.list.length + 1,
                backIdx: this.bgList[0].idx,
                displayStrtDt: null,
                displayEndDt: null,
                displayStatus: 1,
                link: '',
                pcImg: { filename: '', path: '', width: 100, height: 100 },
                mobileImg: { filename: '', path: '', width: 100, height: 100 },
                tempStartDt: moment(new Date()).format('YYYY-MM-DD'),
                tempEndDt: '',
                tempStartTime: '00:00',
                tempEndTime: '23:59',
            }
            this.list.push(temp)
        },
        // x 삭제
        deleteRow (idx) {
            this.list.splice(idx, 1)
            this.list.map((item, idx) => {
                item.seq = idx + 1
            })
        },
        // 선택삭제
        onDelete () {
            if (this.chkList.length <= 0) {
                this.alert("삭제할 대상을 선택 해주세요.")
                return false
            }
            // 배준M 요청 (2021-12-15 서버 처리 이슈로 인해 한개 필수)
            if (this.list.length - this.chkList.length <= 0) {
                this.alert('1개 이상 필수로 등록 되어야 합니다.')
                return false
            }

            let reqBody = {
                idxList: this.chkList
            }
            this.confirm("삭제 하시겠습니까?", () => {
                this.$api.api.delMngVisualCommonTemplate(reqBody).then(resp => {
                    if (resp.status === 200) {
                        this.alert("삭제 되었습니다.")
                        this.getDataList()
                        this.chkList = []
                    } else {
                        this.alert("삭제에 실패하였습니다.")
                    }
                })
            })
        },
        _isInvalidateInputForm() {
            let checkFlag = true
            let errMsg = ''
            this.list.map(m => {
                m.displayStrtDt = m.tempStartDt ? m.tempStartDt + ' ' + m.tempStartTime + ':00' : null
                m.displayEndDt = m.tempEndDt ? m.tempEndDt + ' ' + m.tempEndTime + ':59' : null

                if (m.displayStrtDt >= m.displayEndDt) {
                  checkFlag = false
                  errMsg = '시작일보다 종료일이 빠릅니다.'
                  return false
                }
            })
            if (!checkFlag) {
              this.alert(errMsg)
              return false
            }

            if (this.$v.$invalid) {
                if (!this.$v.title.required) {
                    this.alert('타이틀을 입력해주세요.')
                    return false
                }
                for(let key in this.$v.list.$each.$iter) {
                    let tmp = this.$v.list.$each.$iter[key]
                    if (!tmp.pcImg.filename.required) {
                        this.alert('메인 이미지(PC)를 등록 해주세요.')
                        return false
                    }
                    if (!tmp.mobileImg.filename.required) {
                        this.alert('메인 이미지(Mobile)를 등록 해주세요.')
                        return false
                    }
                    if (!tmp.displayStrtDt.required) {
                        this.alert('시작일을 등록 해주세요.')
                        return false
                    }
                }
            }
            if (this.list.length <= 0) {
                this.alert('1개 이상 필수로 등록 되어야 합니다.')
                return false
            }
            return true
        },
        // 저장
        onSave () {
            if (!this._isInvalidateInputForm()) {
                console.log('[MCKS] 프로모션 정보 등록 실패 입력 FORM 오류')
                return false
            }
            this.confirm('저장 하시겠습니까?', () => {
                let reqBody = {
                    idx: this.visualIdx,
                    title: this.title,
                    itemType: this.itemType,
                    promotionList: []
                }
                this.list.map(i => {
                    let tmp = {
                        idx: i.idx,
                        seq: i.seq,
                        backIdx: i.backIdx,
                        displayEndDt: i.displayEndDt,
                        displayStrtDt: i.displayStrtDt,
                        displayStatus: i.displayStatus,
                        link: i.link,
                        mobileImg: { idx: i.mobileImg.idx, filename: i.mobileImg.filename },
                        pcImg: { idx: i.pcImg.idx, filename: i.pcImg.filename },
                    }
                    reqBody.promotionList.push(tmp)
                })
                this.blockUI()
                this.$api.api.saveMngVisualCommonTemplate(reqBody).then(resp => {
                    if (resp.status === 200) {
                        this.visualIdx = resp.data.data
                        this.alert('저장 되었습니다.', () => {
                            // this.getDataList()
                            // this.chkList = []
                            this.$nextTick(() => {
                                this.$router.replace({path: '/home/general_add', query: {idx: this.visualIdx, nowTime: this.nowTime}})
                            })
                        })
                    } else {
                        this.alert('저장에 실패하였습니다')
                    }
                }).finally(() => {
                    this.unBlockUI()
                })
            })
        },
    },
}
</script>
