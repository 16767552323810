var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-page-title" },
      [
        _c("navigation-bar", {
          attrs: {
            "nav-title": "FAQ관리",
            "nav-list": ["맥케이슨관리", "게시판관리", "FAQ관리"]
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "main-card mb-3 card" }, [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.request.category,
                        expression: "request.category"
                      }
                    ],
                    staticClass: "form-control w-15 d-inline",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.request,
                          "category",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.catTypeList, function(item, index) {
                    return _c("option", { domProps: { value: item.value } }, [
                      _vm._v(_vm._s(item.text))
                    ])
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(2),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.request.question,
                      expression: "request.question"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.request.question },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.request, "question", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "position-relative row form-group" }, [
              _vm._m(3),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.request.answer,
                      expression: "request.answer"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.request.answer },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.request, "answer", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 text-center mb-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary wd-100 mr-1",
            on: { click: _vm.onList }
          },
          [_vm._v("목록")]
        ),
        _vm.request.faqIdx > 0
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary wd-100 ml-1",
                on: { click: _vm.onUpdate }
              },
              [_vm._v("수정")]
            )
          : _vm._e(),
        _vm.request.faqIdx == 0
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary wd-100 ml-1",
                on: { click: _vm.onCreate }
              },
              [_vm._v("등록")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-tab card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [_vm._v("FAQ 정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("카테고리")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("Q")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 col-form-label" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("A")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }