<template>
  <div>
    <vue-editor v-model="innerContent" useCustomImageHandler @image-added="handleImageAdded" :editor-toolbar="customToolbars" :editorOptions="editorSettings" />
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default {
  name: 'html-editor',
  data () {
    return {
      innerContent: '',
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      customToolbars: [
        [
          { header: [false, 1, 2, 3, 4, 5, 6] }
        ],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [ "blockquote", "code-block" ],
        [
          { list: "ordered" },
          { list: "bullet" },
          { list: "check" }
        ],
        [
          { indent: "-1" },
          { indent: "+1" }
        ],
        [
          { color: [] },
          { background: [] }
        ],
        [ "link", "image" ] // link, image, video
      ]
    }
  },
  mixins: [
    CommonMixin
  ],
  components: {
    VueEditor
  },
  props: {
    content: {
      type: String,
      default: () => {
        return ''
      }
    },
    branch: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    // 에디터 내용변경
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();

      console.log(this.branch)
      formData.append('branch', this.branch)
      formData.append("imageFiles", file)

      // 이미지 업로드 전 화면 Block 처리
      this.blockUI()

      this.$api.api.uploadEditorImage(formData).then(resp => {
        let url = '//image.mckayson.com/temp/' + resp.data.data[0].filename
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()

        // 업로드 완료 후 unBlock 처리
        this.unBlockUI()
      })
    }
  },
  watch: {
    content (value) {
      this.innerContent = value
    },
    innerContent (value) {
      this.$emit('change-content', this.innerContent)
    }
  }
}

</script>
