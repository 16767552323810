var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "main-card card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "card-header-title" }, [
            _vm._v(_vm._s(_vm.text))
          ]),
          _c("div", { staticClass: "btn-actions-pane-right" }, [
            _c(
              "button",
              {
                staticClass: "close float-none",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("주문번호 : " + _vm._s(_vm.payInfo.ordNo))
          ]),
          _c("table", { staticClass: "mb-0 table table-detail" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "text-center" }, [
                  _vm._v("상품 금액 합")
                ]),
                _c("td", { staticClass: "text-right pr-4" }, [
                  _vm._v(_vm._s(_vm._f("comma")(_vm.payInfo.prdAmount)) + "원")
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-center" }, [
                  _vm._v("기본배송비")
                ]),
                _c("td", { staticClass: "text-right pr-4" }, [
                  _vm._v(_vm._s(_vm._f("comma")(_vm.payInfo.deliFee)) + "원")
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "text-center" }, [
                  _vm._v("도사산간 배송비")
                ]),
                _c("td", { staticClass: "text-right pr-4" }, [
                  _vm._v(_vm._s(_vm._f("comma")(_vm.payInfo.deliAddfee)) + "원")
                ])
              ])
            ]),
            _c("tfoot", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [_vm._v("결제금액")]),
                _c("th", { staticClass: "text-right pr-4" }, [
                  _vm._v(_vm._s(_vm._f("comma")(_vm.payInfo.payAmount)) + "원")
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "col-12 pr-0 pl-0" }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success wd-100",
                  on: { click: _vm.complete }
                },
                [_vm._v("확인")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }