import DefaultLayout from '@/layout/DefaultLayout'

export default {
  path: '/',
  component: DefaultLayout,
  children: [
    {
      path: '/member/list',
      name: 'MemberList',
      component: () => import('@/page/inhouse/Member/MemberList')
    },
    {
      path: '/history/list',
      name: 'HistoryList',
      component: () => import('@/page/inhouse/History/HistoryList')
    },
    {
      path: '/restriction/list',
      name: 'DiscountRestrictionList',
      component: () => import('@/page/inhouse/Restriction/RestrictionList')
    },
    {
      path: '/restriction/modify/:params?',
      name: 'DiscountRestrictionModify',
      component: () => import('@/page/inhouse/Restriction/RestrictionModify')
    },
    {
      path: '/discountInHouse/list',
      name: 'DiscoutInHouseList',
      component: () => import('@/page/inhouse/Product/DiscountInHouseList')
    },
    {
      path: '/discountInHouseCat/list',
      name: 'DiscoutInHouseCatList',
      component: () => import('@/page/inhouse/DiscountInHouseCat/DiscountInHouseCatList')
    }
  ]
}
