<template>
  <div>
    <div class="app-page-title">
      <navigation-bar :nav-title="'상품문의관리'" :nav-list="['맥케이슨관리', '게시판관리', '상품문의관리']"/>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="main-card mb-3 card">
          <div class="card-header-tab card-header">
            <div class="card-header-title">상품문의관리</div>
          </div>
          <div class="card-body">
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">분류</label>
              <div class="col-sm-8">
                {{request.category.text}}
              </div>
            </div>
            <template v-if="request.category.value === 1">
              <div class="position-relative row form-group">
                <label class="col-sm-2 col-form-label">상품번호</label>
                <div class="col-sm-8">
                  {{request.prdOrdIdx}}
                </div>
              </div>
              <div class="position-relative row form-group">
                <label class="col-sm-2 col-form-label">상품정보</label>
                <div class="col-sm-8">
                  <img class="image" :src="getImagePath(prdImg)" style="width: 100px; height: 100px" v-image-error v-viewer />
                  {{prdName}}
                </div>
              </div>
            </template>
            <template v-else-if="request.category.value === 2 || request.category.value === 3">
              <div class="position-relative row form-group">
                <label class="col-sm-2 col-form-label">주문번호</label>
                <div class="col-sm-8">
                  <a href="javascript:void(0)" @click="callOrderDetailPop(request.prdOrdIdx)">{{request.prdOrdIdx}}</a>
                </div>
              </div>
            </template>
            <template v-else-if="request.category.value === 4">
              <div class="position-relative row form-group">
                <label class="col-sm-2 col-form-label">취소번호</label>
                <div class="col-sm-8">
                  <a href="javascript:void(0)" @click="callClaimDetailPop(request.prdOrdIdx, '취소', 'cancel')">{{request.prdOrdIdx}}</a>
                </div>
              </div>
            </template>
            <template v-else-if="request.category.value === 5">
              <div class="position-relative row form-group">
                <label class="col-sm-2 col-form-label">반품번호</label>
                <div class="col-sm-8">
                  <a href="javascript:void(0)" @click="callClaimDetailPop(request.prdOrdIdx, '반품', 'return')">{{request.prdOrdIdx}}</a>
                </div>
              </div>
            </template>
            <template v-else-if="request.category.value === 6">
              <div class="position-relative row form-group">
                <label class="col-sm-2 col-form-label">교환번호</label>
                <div class="col-sm-8">
                  <a href="javascript:void(0)" @click="callClaimDetailPop(request.prdOrdIdx, '교환', 'exchange')">{{request.prdOrdIdx}}</a>
                </div>
              </div>
            </template>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">회원명</label>
              <div class="col-sm-8">
                {{request.cliName === '' ? '비회원' : request.cliName}}
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">이메일</label>
              <div class="col-sm-8">
                {{request.email}}
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">휴대전화번호</label>
              <div class="col-sm-8">
                {{request.phone}}
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">제목</label>
              <div class="col-sm-8">
                {{request.title}}
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">질문</label>
              <div class="col-sm-8">
                <textarea class="form-control" placeholder="질문을 입력하세요.(최소10자 최대250자)" rows="5" v-model="request.question" minlength="10" maxlength="250" readonly></textarea>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>답변</label>
              <div class="col-sm-8">
                <textarea class="form-control" placeholder="답변을 입력해주세요.(최대500자)" rows="5" v-model="request.answer" maxlength="500"></textarea>
              </div>
            </div>
            <div class="position-relative row form-group">
              <label class="col-sm-2 col-form-label">이미지</label>
              <div class="col-sm-10">
                <button class="btn btn-warning wd-125 mr-1" @click="addImgItem">이미지 항목 추가</button>
              </div>
            </div>
            <div class="position-relative row form-group" v-for="(item,idx) in request.imgList">
              <label class="col-sm-2 col-form-label"><span class="required">*</span>이미지{{ idx + 1  }}</label>
              <div class="col-sm-10">
                <upload-photo :file="{src: getImagePath(item.thumb1), w: 500, h:500}" branch="qna" @changed="changedOk($event , idx)" v-slot:deleteButton>
                    <button class="btn btn-primary mt-2 ml-2" @click="deleteImg(idx)">삭제</button>
                </upload-photo>
<!--                <img v-else :src="getImagePath(getImgSrc(item))" class="preview-img-item wd-150" v-image-error @click="viewImage(image)" />-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mb-4">
        <button class="btn btn-primary wd-100 mr-1" @click="onList">목록</button>
        <button class="btn btn-primary wd-100 ml-1" v-if="request.qnaIdx !== '' " @click="onUpdate">수정</button>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonMixin } from '@/components/mixins/CommonMixin'
import NavigationBar from '@/page/components/NavigationBar'
import InterfaceMixin from '@/mixins/userInterface-mixin'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko.js'
import moment from 'moment'
import CodeMixin from "@/mixins/code-mixin";
import UploadPhoto from '@/components/common/UploadPhoto'
import OrderDetailPop from "@/page/order/components/OrderDetailPop";
import ClaimDetailPop from "@/page/order/components/ClaimDetailPop";

export default {
  name: "PrdQnaDetail",
  data () {
    return {
      request: {
        qnaIdx : '',
        cliIdx : '',
        cliName : '',
        category : { value: 0, text: '' },
        prdOrdIdx : '',
        email : '',
        emailReceiveNoti: '',
        phone : '',
        phoneReceiveNoti: '',
        title : '',
        question : '',
        answer : '',
        imgList : []
      },
      categoryList: [
        { value: 1, text: '상품' },
        { value: 2, text: '주문' },
        { value: 3, text: '배송' },
        { value: 4, text: '취소' },
        { value: 5, text: '반품' },
        { value: 6, text: '교환' },
        { value: 7, text: '기타' }
      ],
      prdImg: '',
      prdName: ''
    }
  },
  components: {
    UploadPhoto,
    NavigationBar
  },
  mixins: [
    CommonMixin,
    InterfaceMixin,
    CodeMixin
  ],
  computed: {

  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init(){
      if(this.$route.params.qnaIdx){
        this.request.qnaIdx =  this.$route.params.qnaIdx
        this.onSearch()
      }
    },
    onSearch(){
      this.$api.api.getPrdQnaDetail({qnaIdx : this.request.qnaIdx} ).then(res=>{
        if(res.data.result){
          this.request.qnaIdx = res.data.data.qnaIdx
          this.request.cliName = res.data.data.cliName
          this.request.email = res.data.data.email
          let tempCtgr = this.categoryList.filter(tmp => {
            return tmp.value === res.data.data.category
          })
          this.request.category = tempCtgr[0]
          this.request.emailReceiveNoti = res.data.data.emailReceiveNoti
          this.request.phone = res.data.data.phone
          this.request.phoneReceiveNoti = res.data.data.phoneReceiveNoti
          this.request.prdOrdIdx = res.data.data.prdOrdIdx
          this.request.title = res.data.data.title
          this.request.question = res.data.data.question === null ? '' : res.data.data.question.replaceAll('<br>', '\r\n')
          this.request.answer = res.data.data.answer === null ? '' : res.data.data.answer.replaceAll('<br>', '\r\n')
          this.request.imgList = res.data.data.qnaImgListData

          if (this.request.category.value === 1) {
            this.getPrdDetail(this.request.prdOrdIdx)
          }
        } else {
          this.$alert(res.errMsg)
        }
      })
    },
    onList () {
      this.$router.push({name: 'PrdQnaList'})
    },
    onUpdate () {
      if (!this.checkValid()) {
        console.log('[MCKS] 상품문의 수정 입력 FORM 오류')
        return
      }

      let tempImgList = this.request.imgList.filter(item=> item.filename !== '')
      let temp = tempImgList.map(img => {
        return img.filename
      })

      let bodyData = {
        qnaIdx: this.request.qnaIdx,
        cliName: this.request.cliName,
        email: this.request.email,
        emailReceiveNoti: this.request.emailReceiveNoti,
        phone: this.request.phone,
        phoneReceiveNoti: this.request.phoneReceiveNoti,
        question: this.request.question.replace(/(\n|\r\n)/g, '<br>'),
        answer: this.request.answer.replace(/(\n|\r\n)/g, '<br>'),
        qnaImgList: temp
      }

      this.$api.api.updatePrdQna(bodyData).then(res => {
        console.log('res ::: ', res)
        if(res.data.result){
          this.alert("수정되었습니다.")
          this.onList()
        }else{
          this.alert("수정에 실패했습니다.")
        }
      })
    },
    deleteImg(idx){
      this.alert("삭제되었습니다.")
      this.request.imgList.splice(idx,1)
    },
    addImgItem(){
      if (this.request.imgList.length >= 4) {
        this.$alert('이미지는 최대 4개까지 등록 가능합니다.')
        return
      }
      let imgData = {
        qnaIdx: this.request.qnaIdx,
        imgIdx: 0,
        mngIdx: 0,
        filename: '',
        // path: '',
        thumb1: ''
      }
      this.request.imgList.push(imgData)
    },
    changedOk (file,idx) {
      let imgData = this.request.imgList[idx]
      imgData.filename = file.name
      imgData.thumb1 = file.path+'/'+file.name
    },
    checkValid(){
      // if(!this.request.category.value === 0){
      //   this.alert('분류를 선택해주세요.')
      //   return false
      // }
      // if(!this.request.email){
      //   this.alert('이메일을 입력해주세요.')
      //   return false
      // }
      // if(!this.isEmail(this.request.email)){
      //   this.alert('이메일을 양식을 확인해주세요.')
      //   return false
      // }
      // if(this.request.phone === ''){
      //   this.alert('휴대전화번호를 입력해주세요.')
      //   return false
      // }
      // if (this.request.category.value === 1 && this.request.prdOrdIdx === '') {
      //   this.$alert('상품번호를 입력해주세요.')
      //   return false
      // }
      // if ((this.request.category.value === 2 || this.request.category.value === 3) && this.request.prdOrdIdx === '') {
      //   this.$alert('주문번호를 입력해주세요.')
      //   return false
      // }
      // if (this.request.category.value === 4 && this.request.prdOrdIdx === '') {
      //   this.$alert('취소번호를 입력해주세요.')
      //   return false
      // }
      // if (this.request.category.value === 5 && this.request.prdOrdIdx === '') {
      //   this.$alert('반품번호를 입력해주세요.')
      //   return false
      // }
      // if (this.request.category.value === 6 && this.request.prdOrdIdx === '') {
      //   this.$alert('교환번호를 입력해주세요.')
      //   return false
      // }
      // if(this.request.title === ''){
      //   this.alert('제목을 입력해주세요.')
      //   return false
      // }
      // if(this.request.question === ''){
      //   this.alert('질문내용을 입력해주세요.')
      //   return false
      // }
      // if(this.request.question.length < 10){
      //   this.alert('질문내용을 최소 10자이상 입력해주세요.')
      //   return false
      // }
      if(this.request.answer.trim() === ''){
        this.alert('답변내용을 입력해주세요.')
        return false
      }
      if (this.request.answer.length < 10) {
        this.alert('답변내용을 최소 10자이상 입력해주세요.')
        return false
      }
      return true
    },
    getPrdDetail (prdNo) {
      let body = {
        prdNo: prdNo
      }
      this.$api.api.getPrdIdx(body).then(res => {
        if (res.data.result) {
          this.$api.api.getProductDetail({ idx: res.data.data }).then(resp => {
            if (resp.data.result) {
              this.prdName = resp.data.data.prdNameKor
              if (resp.data.data.optionList[0].imageList.length > 0) this.prdImg = resp.data.data.optionList[0].imageList[0].thumb1
            }
          })
        } else {
          this.alert('상품번호를 확인해주세요.')
        }
      })
    },
    callOrderDetailPop (ordNo) {
      let ordIdx
      let param = {
        ordNo: ordNo
      }
      this.$api.api.getOrderIdx(param).then(res => {
        if (res.data.result) {
          ordIdx = res.data.data
          this.$modal.show(
              OrderDetailPop,
              { text: '주문정보', ordIdx: ordIdx },
              { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
              {
                'before-close': event => {
                  console.log(event.params)
                }
              }
          )
        } else {
          this.alert('상품번호를 확인해주세요.')
        }
      })
    },
    callClaimDetailPop (clmNo, title, type) {
      let ordIdx
      let clmIdx
      let param = {
        clmNo: clmNo
      }
      this.$api.api.getClaimInfo(param).then(res => {
        if (res.data.result) {
          ordIdx = res.data.claim.ordIdx
          clmIdx = res.data.claim.clmIdx

          this.$modal.show(
              ClaimDetailPop,
              { text: title, ordIdx: Number(ordIdx), clmIdx: clmIdx, clmNo: clmNo, claimType: type},
              { draggable: false, height: 'auto', adaptive: true, minWidth: 1200 },
              {
                'before-close': event => {
                }
              }
          )
        } else {
          this.alert('상품번호를 확인해주세요.')
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
