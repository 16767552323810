<template>
  <div>
    <Snowf v-if="chkSeason === 4" :amount="50" :size="5" :speed="1.5" :wind="0" :opacity="0.8" :swing="1" :image="null" :zIndex="null" :resize="true" color="#000" />
    <div class="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
    <!--
    <div class="app-container body-tabs-shadow fixed-sidebar fixed-header">
    -->
      <top-menu></top-menu>
      <div class="app-main">
        <side-menu></side-menu>
          <div class="app-main__outer">
            <div class="app-main__inner">
              <transition name="fade">
              <app-main></app-main>
              </transition>
            </div>
            <footer-menu></footer-menu>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppMain from './common/AppMain'
import TopMenu from '@/layout/common/TopMenu'
import SideMenu from '@/layout/common/SideMenu'
import FooterMenu from '@/layout/common/FooterMenu'
import Snowf from 'vue-snowf'
import moment from 'moment'

export default {
  name: 'DefaultLayout',
  data () {
    return {
    }
  },
  components: {
    Snowf,
    AppMain,
    TopMenu,
    SideMenu,
    FooterMenu
  },
  computed: {
    chkQuarter () {
      let nowMonth = moment(new Date()).format('MM')
      let quarter = Math.ceil( nowMonth / 3.0 )
      return quarter
    },
    chkSeason () {
      let nowMonth = moment(new Date()).format('MM')
      switch (nowMonth) {
        case '12':
        case '01':
        case '02':
          return 4
        case '03':
        case '04':
        case '05':
          return 1
        case '06':
        case '07':
        case '08':
          return 2
        case '09':
        case '10':
        case '11':
          return 3
      }
    }
  },
  methods: {
  }
}
</script>
